/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactPaginate from "react-paginate";
import { paginationProps } from "../../utils/interfaces";
import "./pagination.css";

function PaginationComponent({
  totalItems,
  activeItem,
  setActiveItem,
}: paginationProps): JSX.Element {
  const items: number = totalItems;
  const pagination_array: number[] = Array.from(
    { length: items },
    (_, index) => index
  );

  const handlePageChange = (event: any) => {
    setActiveItem(event?.selected);
  };

  return (
    <>
      <div className="d-none d-md-block d-lg-block">
        {totalItems > 0 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={<button className="page-link">Next</button>}
            onPageChange={handlePageChange}
            marginPagesDisplayed={2}
            forcePage={activeItem}
            pageRangeDisplayed={5}
            pageCount={totalItems}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            previousLabel={
              <button className="page-link" tabIndex={-1}>
                Previous
              </button>
            }
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item cursor-style"
            pageLinkClassName="page-link btn"
            activeClassName={"page-item active cursor-style"}
          />
        )}
      </div>

      <nav aria-label="... " className="d-sm-block d-md-none d-lg-none">
        <ul className="pagination">
          <li
            className="page-item disabled cursor-style"
            onClick={() =>
              setActiveItem((prev: any) => {
                if (prev <= 0) {
                  return 0;
                } else {
                  return prev - 1;
                }
              })
            }
          >
            <button className="page-link" tabIndex={-1}>
              Previous
            </button>
          </li>
          {/* SM && MD SCREENS */}
          {pagination_array.map((i, index) => {
            return (
              <li
                className={
                  i === activeItem
                    ? "page-item active cursor-style d-sm-block d-md-none d-lg-none"
                    : "page-item cursor-style d-none"
                }
                key={i + index}
                onClick={() => setActiveItem(i)}
              >
                <button className="page-link btn">{i + 1}</button>
              </li>
            );
          })}
          {/* {pages.map((i, index) => {
            return (
              <li
                className={
                  i === activeItem
                    ? "page-item active cursor-style d-none d-md-block d-lg-block"
                    : "page-item cursor-style d-none d-md-block d-lg-block"
                }
                key={i + index}
                onClick={() => setActiveItem(i)}
              >
                <button className="page-link btn">{i + 1}</button>
              </li>
            );
          })} */}
          {/* {totalItems > 5 && (
            <Pagination.Ellipsis
              className="d-none d-md-block d-lg-block"
              onClick={() =>
                setActiveItem((prev: any) => {
                  return prev + 1;
                })
              }
            />
          )} */}

          <li className={`page-item cursor-style`}>
            <button
              className="page-link"
              disabled={activeItem === totalItems - 1 ? true : false}
              onClick={() =>
                setActiveItem((prev: any) => {
                  return prev + 1;
                })
              }
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default PaginationComponent;
