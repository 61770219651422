import React, { useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { payouts_txn } from "../../configs/dataTable.config";
import { formatAmount } from "../../helpers/index.helper";
import { useGetPayoutTransactions } from "../../hooks/payouts/payouts.hook";
import useResize from "../../hooks/resize.hook";
import { IPayoutTransaction } from "../../utils/interfaces";
import CreatePayoutAcc from "../modal/CreatePayoutAcc";
import PaginationComponent from "../pagination/pagination";
import Table from "../table/Table";

function PayoutCard(): JSX.Element {
  const { size } = useResize();
  const [activeItem, setActiveItem] = useState<number>(0);
  const [reference, setReference] = useState("");

  const { data, isFetching, refetch } = useGetPayoutTransactions(activeItem);
  const payoutData: IPayoutTransaction[] = data?.data?.data?.items;
  const totalItems = data?.data?.data?.totalPages;
  const columns: any = useMemo(() => [...payouts_txn()], []);

  const refreshData = async () => {
    setActiveItem(0);
    refetch();
  };

  React.useEffect(() => {
    refreshData();
  }, []);

  return (
    <>
      <CreatePayoutAcc />
      {size?.[0] <= 998 && (
        <div className="d-flex flex-row gap-3 mb-3 mt-2 mb-2">
          <input
            type="text"
            className="js-form-search form-control border border-secondary icon w-75"
            placeholder="Search..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setReference(e.target.value)
            }
            style={{ paddingLeft: "50px !important" }}
          />
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className="btn btn-light rounded btn-icon icon-btn"
            >
              <i className="bi-three-dots" style={{ color: "#000" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={"button"}
                type="button"
                // onClick={() => setShow(true)}
              >
                <i className="bi bi-filter me-2"></i> Filter
              </Dropdown.Item>
              <Dropdown.Item
                as={"button"}
                type="button"
                // onClick={() => {
                //   handleExports();
                //   moengage.track_event("DOWNLOAD_ACCOUNT", {
                //     success_status: true,
                //   });
                // }}
              >
                <i className="bi bi-download me-2"></i>
                Download
              </Dropdown.Item>

              <Dropdown.Item
                as={"button"}
                type="button"
                onClick={() => {
                  refreshData();
                }}
              >
                <i className="bi bi-arrow-clockwise me-2"></i> Refresh
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <div className="card">
        {size?.[0] <= 998 ? (
          <div className="d-flex flex-column">
            <ul className="list-group list-group-numberedd">
              <li className="list-group-item d-flex align-items-center justify-content-between">
                <h4 className="card-header-title">Payouts History</h4>
                {/* <button
                  type="button"
                  className="btn btn-soft-primary"
                  aria-expanded="false"
                  onClick={() => {
                    refreshData();
                  }}
                >
                  <i className="bi bi-arrow-clockwise me-2"></i> Refresh
                </button> */}
              </li>

              {isFetching ? (
                <li className="list-group-item">
                  {[1, 2, 3, 4, 5, 6].map(() => {
                    return <Skeleton height={30} />;
                  })}
                </li>
              ) : (
                <>
                  {payoutData?.map((txn_data: any) => {
                    return (
                      <>
                        <li
                          className="list-group-item d-flex justify-content-between align-items-start"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="ms-2 me-auto d-flex flex-column">
                            <div
                              className="fw-semibold text-truncate"
                              style={{ maxWidth: "150px" }}
                            >
                              {txn_data?.target_name} -{" "}
                            </div>
                            <small>{txn_data?.target_number}</small>
                            <small>{txn_data?.createdAt}</small>
                          </div>

                          <span className="d-flex flex-column">
                            {txn_data?.in_transit && (
                              <span className="badge bg-soft-warning text-warning">
                                <span className="legend-indicator bg-warning"></span>
                                PENDING
                              </span>
                            )}
                            {txn_data?.completed &&
                              !txn_data?.in_transit &&
                              txn_data?.failed && (
                                <span className="badge bg-soft-danger text-danger">
                                  <span className="legend-indicator bg-danger"></span>
                                  FAILED
                                </span>
                              )}
                            {txn_data?.completed && (
                              <span className="badge bg-soft-success text-success">
                                <span className="legend-indicator bg-success"></span>
                                COMPLETED
                              </span>
                            )}
                            {formatAmount(txn_data?.amount)}
                          </span>
                        </li>
                      </>
                    );
                  })}
                </>
              )}
            </ul>
          </div>
        ) : (
          <>
            <div className="card-header card-header-content-sm-between">
              <div className="mb-2 mb-sm-0">
                <h4 className="card-header-title">Payouts History</h4>
                {/* <p className="card-text fs-5">processed payouts</p> */}
              </div>

              <div className="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
                <div id="datatableCounterInfo">
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-white btn-sm w-100"
                      aria-expanded="false"
                      onClick={() => {
                        refreshData();
                      }}
                    >
                      <i className="bi bi-arrow-clockwise me-2"></i> Refresh
                    </button>
                    <span className="fs-6 me-3" style={{ display: "none" }}>
                      <span id="datatableCounter">0</span>
                      Selected
                    </span>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      style={{ display: "none" }}
                    >
                      <i className="bi-trash"></i> Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive datatable-custom">
              <Table
                columns={columns}
                data={payoutData !== undefined ? payoutData : []}
                isFetching={isFetching}
              />
            </div>

            <div className="card-footer">
              <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                <div className="col-sm mb-2 mb-sm-0"></div>

                <div className="col-sm-auto">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    {payoutData?.length > 0 && (
                      <PaginationComponent
                        totalItems={totalItems}
                        activeItem={activeItem}
                        setActiveItem={setActiveItem}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {size?.[0] <= 998 && (
        <div className="col-sm-auto mt-2">
          <div className="d-flex justify-content-center justify-content-sm-end">
            {payoutData?.length > 0 && (
              <PaginationComponent
                totalItems={totalItems}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            )}
          </div>
        </div>
      )}

      {size?.[0] < 768 && (
        <div
          className="dropup position-absolute bottom-0 end-0 rounded-circle m-5"
          hidden
        >
          <button
            type="button"
            className="btn btn-primary btn-lg dropdown-toggle hide-toggle"
            data-bs-toggle="modal"
            data-bs-target="#createAKIKeyModal"
          >
            <i className="bi-plus me-1"></i>
            <span className="">Initiate Payout</span>
          </button>
        </div>
      )}
    </>
  );
}

export default PayoutCard;
