import moengage from "@moengage/web-sdk";
import React, { FormEvent, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Empty } from "../../assets/empty.svg";
import { formatAmount } from "../../helpers/index.helper";
import useDebounce from "../../hooks/debounce.hook";
import {
  useTranactionExports,
  useUnsettledTransactions,
} from "../../hooks/transactions/index.hook";
import { ITransactions, sessionInterface } from "../../utils/interfaces";
import EmptyResponse from "../EmptyResponse";
import FilterModal from "../modal/filterModal";
import PaginationComponent from "../pagination/pagination";
import SpinnerComponent from "../spinner/Spinner";

function UnsettledSm({
  dateRange,
  merchant_data,
  deviceInfo,
}: {
  merchant_data?: sessionInterface;
  deviceInfo?: any;
  reference?: string;
  dateRange?: any;
}) {
  const navigate = useNavigate();
  const isAdmin = window.location.pathname.includes("/admin");

  const [transactions, setTransactions] = useState<ITransactions>();
  const [reference, setReference] = useState("");
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [filter, setFilter] = useState({
    reference: "",
    start_date: "",
    end_date: "",
  });
  const [activeItem, setActiveItem] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const [downloadResult, setDownload] = useState(true);

  const debouncedInputValue = useDebounce<string>(reference, 2000);
  const { export_transactions } = useTranactionExports();
  const { data, refetch, isFetching } = useUnsettledTransactions(
    {
      reference: debouncedInputValue,
      start_date: filter.start_date,
      end_date: filter.end_date,
    },
    activeItem
  );

  const totalItems = data?.data?.data?.totalPages;

  const handleRefFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  const filterData = () => {
    setLoader(true);
    if (dates?.start_date === "" && dates?.end_date === "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "all",
        })
        .then((data: any) => {
          setLoader(false);
          setShow(false);
          // const url = data?.data?.data;
          // const link: any = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "file.xlsx");
          // document.body.appendChild(link);
          // link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          setLoader(false);
          return error;
        });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          setShow(false);
          setLoader(false);
          // const url = data?.data?.data;
          // const link: any = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "file.xlsx");
          // document.body.appendChild(link);
          // link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          setLoader(false);
          return error;
        });
    }
    setLoader(false);
  };

  const handleExports = () => {
    if (dates?.start_date === "" && dates?.end_date === "") {
      // setFilter({
      //   reference,
      //   ...dates,
      // });
      // export_transactions
      //   .mutateAsync({
      //     start_date: dates.start_date,
      //     end_date: dates.end_date,
      //     download: "all",
      //   })
      //   .then((data: any) => {
      //     const url = data?.data?.data;
      //     const link: any = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     link.parentNode.removeChild(link);
      //     moengage.track_event("DOWNLOAD_TRANSACTIONS", {
      //       success_status: true,
      //       firstName: merchant_data?.firstname,
      //       lastName: merchant_data?.lastname,
      //       email: merchant_data?.email,
      //       mobile: merchant_data?.mobile_number,
      //       userName: merchant_data?.email,
      //       uniqueUserId: merchant_data?.email,
      //       phone_number: merchant_data?.mobile_number,
      //       terms_agreement: merchant_data?.agreed_terms,
      //       address: merchant_data?.address,
      //       business_category: merchant_data?.business_category,
      //       business_type: merchant_data?.business_type,
      //       platform: deviceInfo?.userAgent,
      //     });
      //   })
      //   .catch((error: any) => {
      //     return error;
      //   });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          const url = data?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          moengage.track_event("DOWNLOAD_TRANSACTIONS", {
            success_status: true,
            firstName: merchant_data?.firstname,
            lastName: merchant_data?.lastname,
            email: merchant_data?.email,
            mobile: merchant_data?.mobile_number,
            userName: merchant_data?.email,
            uniqueUserId: merchant_data?.email,
            phone_number: merchant_data?.mobile_number,
            terms_agreement: merchant_data?.agreed_terms,
            address: merchant_data?.address,
            business_category: merchant_data?.business_category,
            business_type: merchant_data?.business_type,
            platform: deviceInfo?.userAgent,
          });
        })
        .catch((error: any) => {
          return error;
        });
    }
  };

  const refreshData = () => {
    setReference("");
    setDates({
      start_date: "",
      end_date: "",
    });
    setFilter({
      reference,
      ...dates,
    });
    refetch();
    window.location.reload();
  };

  useEffect(() => {
    if (data) {
      setTransactions(data?.data?.data);
    }
  }, [data]);

  const handleClose = () => {
    setShow(false);
    refreshData();
  };

  return (
    <>
      <FilterModal show={show} handleClose={handleClose}>
        <div className="row mb-4">
          <div className="col tom-select-custom mb-3">
            <label>Start date</label>
            <input
              type="date"
              name="start_date"
              onChange={handleDatesFilter}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
          <div className="col tom-select-custom mb-3">
            <label>End date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleDatesFilter}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-white btn-sm" onClick={handleClose}>
            Close
          </button>
          {downloadResult ? (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                handleExports();
                moengage.track_event("DOWNLOAD_ACCOUNT", {
                  success_status: true,
                });
              }}
              style={{ cursor: "pointer" }}
            >
              Export
              {loader && <SpinnerComponent size="sm" variant="light" />}
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                filterData();
                moengage.track_event("FILTER_ACCOUNT", {
                  platform: deviceInfo?.userAgent,
                  success_status: true,
                });
              }}
              style={{ cursor: "pointer" }}
            >
              Apply filter{" "}
              {loader && <SpinnerComponent size="sm" variant="light" />}
            </button>
          )}
        </div>
      </FilterModal>
      <div className="d-flex flex-column mt-4">
        <div className="d-flex flex-row gap-3 mb-3">
          <input
            type="text"
            className="js-form-search form-control border border-secondary icon w-75"
            placeholder="Search..."
            value={reference}
            onChange={handleRefFilter}
          />

          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className="btn btn-light rounded btn-icon icon-btn"
            >
              <i className="bi-three-dots" style={{ color: "#000" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={"button"}
                type="button"
                onClick={() => setShow(true)}
              >
                <i className="bi bi-filter me-2"></i> Filter
              </Dropdown.Item>
              <Dropdown.Item
                as={"button"}
                type="button"
                onClick={() => {
                  setShow(true);
                  setDownload(true);
                }}
              >
                <i className="bi bi-download me-2"></i> Download
              </Dropdown.Item>
              <Dropdown.Item
                as={"button"}
                type="button"
                onClick={() => {
                  refreshData();
                  refetch();
                }}
              >
                <i className="bi bi-arrow-clockwise me-2"></i> Refresh
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="card h-100">
        <ul className="list-group list-group-numberedd">
          {isFetching ? (
            <li className="list-group-item">
              {[1, 2, 3, 4, 5, 6].map(() => {
                return <Skeleton height={30} />;
              })}
            </li>
          ) : (
            <>
              {transactions?.items?.length === 0 ? (
                <>
                  <EmptyResponse title="No record found" image={<Empty />} />
                </>
              ) : (
                <>
                  {transactions?.items?.map((txn_data: any) => {
                    return (
                      <>
                        <li
                          className="list-group-item d-flex justify-content-between align-items-start"
                          onClick={() =>
                            navigate(
                              `/merchant/transactions/unsettled/${txn_data.id}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {txn_data?.transaction_type === "transfer" ||
                          txn_data?.transaction_type === "payout" ? (
                            <i className="bi bi-arrow-up-right-circle text-danger"></i>
                          ) : null}

                          {txn_data?.transaction_type === "collection" && (
                            <i
                              className="bi bi-arrow-up-left-circle text-success"
                              style={{ rotate: "270deg" }}
                            ></i>
                          )}

                          {txn_data?.transaction_type === "collection" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.sender_details?.account_number ||
                                  txn_data?.sender_details?.account_no}{" "}
                                -{" "}
                                {txn_data?.sender_details?.name ||
                                  txn_data?.sender_details?.account_name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "transfer" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number ||
                                  txn_data?.reciever_details?.account_no}{" "}
                                -{" "}
                                {txn_data?.reciever_details?.name ||
                                  txn_data?.reciever_details?.account_name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "intra-transfer" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number} -{" "}
                                {txn_data?.reciever_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "wallet charge" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                Pooler - N/A
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "payout" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number ||
                                  txn_data?.reciever_details?.account_no}{" "}
                                -{" "}
                                {txn_data?.reciever_details?.name ||
                                  txn_data?.reciever_details?.account_name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          <span className="">
                            {formatAmount(txn_data?.amount)}
                          </span>
                        </li>
                      </>
                    );
                  })}
                </>
              )}
            </>
          )}
        </ul>

        <div className="col-sm-auto mt-3">
          <div className="d-flex justify-content-center justify-content-sm-end">
            {totalItems > 1 && (
              <PaginationComponent
                totalItems={totalItems}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UnsettledSm;
