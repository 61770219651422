import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DashboardTour } from "../../assets/dashboardtour.svg";
import { PATH_NAMES } from "../../constants/index.constant";
import { useClickContext } from "../../context/tourContext";
import { encryptKyc, signatureHarsh } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import { useCheckBuisnessRequirements } from "../../hooks/kyc/kyc.hook";
import { quick, reg_biz, unreg_biz } from "../../utils/data";
import { BUISNESS_TYPE } from "../../utils/enums";
import { sessionInterface } from "../../utils/interfaces";

function GetStarted() {
  const { onClick } = useClickContext();
  const navigate = useNavigate();
  const { data } = useSession();
  const merchant_data: sessionInterface = data?.data?.data;
  const BizCategory =
    merchant_data?.business_category || localStorage.getItem("business_cat");
  const objectMapper = BizCategory?.toLowerCase().includes("unregi")
    ? unreg_biz
    : reg_biz;
  const merchantId: any = localStorage.getItem("merchant_compliance_id");
  const message = merchantId && JSON.stringify({ id: merchantId });
  const x_signature = encryptKyc(message, signatureHarsh);
  const BUSINESS_TYPE: string | any = BizCategory?.toLowerCase().includes(
    "unregi"
  )
    ? BUISNESS_TYPE.UN_REGISTERED
    : BUISNESS_TYPE.REGISTERED;

  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );

  const requirementData: any = requiredData?.data?.data;
  const general: any = requirementData?.general;
  const approvedLevelOne = requirementData?.approved_level_one;
  const approvedLevelTwo = requirementData?.approved_level_two;
  const approvedLevelThree = requirementData?.approved_level_three;
  const levelOne: any = requirementData?.level_one;
  const levelTwo: any = requirementData?.level_two;
  const levelThree: any = requirementData?.level_three;

  const handleComplianceURL = (): string | any => {
    if (BizCategory?.toLowerCase().includes("unregi")) {
      return levelOne?.submit === true && levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
        : levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`;
    } else if (BizCategory?.toLowerCase().includes("regi")) {
      return levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`;
    }
  };

  const requirementKeys: Record<string, any> = {
    general,
    levelOne,
    levelTwo,
    levelThree,
    approvedLevelOne,
    approvedLevelTwo,
    approvedLevelThree,
  };

  const stop = BizCategory?.toLowerCase().includes("unregi")
    ? approvedLevelThree
    : approvedLevelTwo;
  const stopSubmit = BizCategory?.toLowerCase().includes("unregi")
    ? levelThree?.submit
    : levelTwo?.submit;

  return (
    <main className="px-3">
      {/* PAGE HEADER */}
      <div className="mb-5" hidden>
        <div className="row align-items-center mb-5 pb-5">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Get Started</h1>
          </div>

          <div className="col-sm-auto"></div>
        </div>
      </div>

      {/* ACCOUNTS */}
      <div className="">
        <div className="row">
          <div className="col-lg-8  mb-3">
            <div className="card h-100 card-kyb hard-shadow card_bg_img">
              <div className="card-body">
                <p className="mb-1 complete-kyb pt-1">
                  {approvedLevelOne && (
                    <div
                      style={{
                        width: "auto",
                        height: "30px",
                        padding: 8,
                        background: "#2C1DFF",
                        borderRadius: 8,
                        overflow: "hidden",
                        gap: 8,
                        marginRight: 10,
                      }}
                      className="mb-2 d-inline-flex justify-content-center align-items-center"
                    >
                      <div
                        style={{
                          width: 6,
                          height: 6,
                          background: "#E9F2FF",
                          borderRadius: 9999,
                        }}
                      />
                      <div
                        style={{
                          color: "#E9F2FF",
                          fontSize: 14,
                          fontWeight: "400",
                          lineHeight: 20,
                          wordWrap: "break-word",
                          paddingRight: 7,
                        }}
                      >
                        Tier{" "}
                        {!approvedLevelTwo &&
                        !approvedLevelThree &&
                        approvedLevelOne
                          ? "1"
                          : !approvedLevelThree &&
                            approvedLevelOne & approvedLevelTwo
                          ? "2"
                          : approvedLevelThree &&
                            approvedLevelOne & approvedLevelTwo &&
                            "3"}
                      </div>
                    </div>
                  )}
                  {!stop && (
                    <div
                      style={{
                        height: 32,
                        borderRadius: 8,
                        border: "1px #D5B5FF solid",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                      onClick={() => navigate(handleComplianceURL())}
                    >
                      <div
                        style={{
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderRadius: 8,
                          justifyContent: "flex-start",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            color: "#2C1DFF",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          {stopSubmit && !stop
                            ? "Your KYC details are currently being reviewed"
                            : stopSubmit && stop
                            ? "View KYC details"
                            : "Upgrade your business"}{" "}
                          <i className="bi bi-arrow-right small ms-1"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </p>
                <p className="text-body mb-1">
                  Submit your compliance (KYC) details in minutes to boost your
                  transaction limits.
                </p>

                <div className="col-lg-10 mt-5 pt-5">
                  <div className="row gap-y-3">
                    <div className="col-lg-6 col-md-5 col-sm-12">
                      {[...objectMapper].slice(0, 2).map((i, index) => (
                        <div key={index} className="w-100 pb-3">
                          <div className="d-flex flex-row align-items-center w-100">
                            <span
                              className={`${
                                (
                                  i.icon === "1"
                                    ? requirementKeys[i.submittedKey]?.proceed
                                    : requirementKeys[i.submittedKey]?.submit
                                )
                                  ? "icon icon-xs icon-success icon-circle me-1"
                                  : "icon icon-xs icon-light icon-circle me-1 border border-secondary"
                              }`}
                              style={{
                                fontSize: (
                                  i.icon === "1"
                                    ? requirementKeys[i.submittedKey]?.proceed
                                    : requirementKeys[i.submittedKey]?.submit
                                )
                                  ? "22px"
                                  : "14px",
                                height: "20px",
                                width: "20px",
                                background: (
                                  i.icon === "1"
                                    ? requirementKeys[i.submittedKey]?.proceed
                                    : requirementKeys[i.submittedKey]?.submit
                                )
                                  ? "#128100"
                                  : "",
                                paddingTop: (
                                  i.icon === "1"
                                    ? requirementKeys[i.submittedKey]?.proceed
                                    : requirementKeys[i.submittedKey]?.submit
                                )
                                  ? "2px"
                                  : "0px",
                              }}
                            >
                              {(
                                i.icon === "1"
                                  ? requirementKeys[i.submittedKey]?.proceed
                                  : requirementKeys[i.submittedKey]?.submit
                              ) ? (
                                <i className="bi bi-check"></i>
                              ) : (
                                i.icon
                              )}
                            </span>

                            <span className="ms-1 grid-text w-100">
                              {i.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-12">
                      {[...objectMapper].slice(2, 4).map((i, index) => (
                        <div key={index} className="w-100 pb-3">
                          <div className="d-flex flex-row align-items-center w-100">
                            <span
                              className={`${
                                requirementKeys[i.submittedKey]?.submit
                                  ? "icon icon-xs icon-success icon-circle me-1"
                                  : "icon icon-xs icon-light icon-circle me-1 border border-secondary"
                              }`}
                              style={{
                                fontSize: requirementKeys[i.submittedKey]
                                  ?.submit
                                  ? "22px"
                                  : "14px",
                                height: "20px",
                                width: "20px",
                                background: requirementKeys[i.submittedKey]
                                  ?.submit
                                  ? "#128100"
                                  : "",
                                paddingTop: requirementKeys[i.submittedKey]
                                  ?.submit
                                  ? "2px"
                                  : "0px",
                              }}
                            >
                              {requirementKeys[i.submittedKey]?.submit ? (
                                <i className="bi bi-check"></i>
                              ) : (
                                i.icon
                              )}
                            </span>

                            <span className="ms-1 grid-text w-100">
                              {i.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4  mb-3">
            <div className="card h-100 card-left hard-shadow">
              <div className="card-body">
                <p className="icon icon-lg icon-bg icon-circle">
                  <DashboardTour />
                </p>

                <div className="pt-3 pb-4">
                  <p className="mb-1 complete-kyb">Dashboard tour</p>
                  <p className="text-body mb-1">
                    Get familiar with your dashboard
                  </p>
                </div>

                <button
                  type="button"
                  className="tour-btn d-flex flex-row align-items-center"
                  onClick={onClick}
                >
                  Take a tour
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* QUICK ACTIONS */}
      <div className="mt-3 pt-2">
        <div className="col-sm mb-2 pb-2 mb-sm-0">
          <h3 className="">Quick actions</h3>{" "}
        </div>

        <div className="row">
          {[...quick].map((i, index) => (
            <div key={index} className="col-lg-4 my-2">
              <div className="card h-100 card-left hard-shadow mb-3 get-started-body">
                <div className="card-body my-3">
                  <div className="w-75 mx-auto text-center ">
                    <p className="icon icon-lg icon-bg icon-circle">
                      <i
                        className={i.icon}
                        style={{
                          fontSize:
                            i.title === "Make transfer" ? "23px" : "31px",
                          color: "#2C1DFF",
                        }}
                      ></i>
                    </p>

                    <div className="pt-3 pb-4 text-center mx-auto d-flex flex-column justify-content-center">
                      <p className="text-body mb-1 text-center mx-auto">
                        {i.subtitle}
                        {i.subtitleLine2 && (
                          <>
                            <br /> {i.subtitleLine2}
                          </>
                        )}
                      </p>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary d-flex flex-row align-items-center mx-auto get-started-btn"
                      onClick={() => navigate(i.link)}
                    >
                      {i.title}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* support & resources */}
      <div className="pt-4 mb-3">
        <div className="col-sm mb-2 mb-sm-0">
          <h3 className="">Support & resources</h3>{" "}
          <p className="text-body mb-1">
            Get quick access to support and useful resources.
          </p>
        </div>
        <div className="d-flex flex-wrap mb-3 mt-4" style={{ gap: "10px" }}>
          <div className="my-2">
            <a
              className="card-link support-links p-2 ps-3 pe-3"
              href="/merchant/profile#id-whitelist"
              rel="noreferrer"
            >
              Whitelist an IP <i className="bi bi-arrow-right small ms-1"></i>
            </a>
          </div>

          <div className="my-2">
            <a
              className="card-link support-links p-2 ps-3 pe-3"
              href="mailto:support@poolerapp.com"
            >
              Support center <i className="bi bi-arrow-right small ms-1"></i>
            </a>
          </div>

          <div className="my-2">
            <a
              className="card-link support-links p-2 ps-3 pe-3"
              href="https://pooler.helpscoutdocs.com/"
            >
              Knowledge base <i className="bi bi-arrow-right small ms-1"></i>
            </a>
          </div>

          <div className="my-2">
            <a
              className="card-link support-links p-2 ps-3 pe-3"
              href="https://docs.poolerapp.com/"
              target="_blank"
              rel="noreferrer"
            >
              API docs <i className="bi bi-arrow-right small ms-1"></i>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default GetStarted;
