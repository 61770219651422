import { memo, useEffect, useState } from "react";

const Timer = () => {
  const [time, setTime] = useState<string>(`0${5}:00`);

  useEffect(() => {
    let seconds: number = 5 * 60;
    const intervalId = setInterval(() => {
      const minutesRemaining: number = Math.floor(seconds / 60);
      const secondsRemaining: number = seconds % 60;
      const formattedMinutes: string = String(minutesRemaining).padStart(
        2,
        "0"
      );
      const formattedSeconds: string = String(secondsRemaining).padStart(
        2,
        "0"
      );
      setTime(`${formattedMinutes}:${formattedSeconds}`);
      if (seconds === 0) {
        clearInterval(intervalId); // Stop the interval
      } else {
        if (seconds === 1) {
          clearInterval(intervalId);
          setTime("00:00");
        }
        seconds--;
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    time.length > 0 && (
      <div className="row center-content">
        <div className="timer">
          <span>
            <span>Expires in</span> {time}
          </span>
        </div>
      </div>
    )
  );
};
// @ts-ignore
export default memo(Timer);
