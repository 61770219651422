import { useEffect, useState } from "react";
import Select from "react-select";
import { formatAmount } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import {
  useGetBanks,
  useResolveAccount,
} from "../../hooks/payouts/payouts.hook";
import useResize from "../../hooks/resize.hook";
import { bankInterface, sessionInterface } from "../../utils/interfaces";
import CurrencyInputField from "../CurrencyInput/CurrencyInput";
import SpinnerComponent from "../spinner/Spinner";
import "./modal.css";

const NewBenificiary = ({
  account,
  limit,
  handleAddAccount,
  handleContinue,
}: {
  account: any;
  limit: number | string;
  handleAddAccount: (data: any) => void;
  handleContinue: (stage: string) => void;
}) => {
  const { size } = useResize();
  const [account_number, setAccountNumber] = useState<string>(
    account?.beneficiary_account_no
  );
  const [amount, setAmount] = useState<string>(account?.amount || "");
  const [narration, setNarration] = useState<string>(account?.narration || "");
  const [saveBenificiary, setSaveBenificiary] = useState<boolean>(
    account?.save_beneficiary || false
  );
  const [bank, setBank] = useState<any>(
    {
      bank_name: account?.beneficiary_bank_name,
      bank_code: account?.beneficiary_bank,
    } || {}
  );
  const [hidden, setHidden] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const { resolve_account } = useResolveAccount();
  const { bankData, isLoading: bankLoading } = useGetBanks();
  const session = useSession();
  const merchant_data: sessionInterface = session?.data?.data?.data;
  const banks: bankInterface[] = bankData?.data?.data;
  const account_name = resolve_account?.data?.data?.data?.account_name;
  const handleAccountDetails = () => {
    let data = {};
    if (narration) {
      data = {
        beneficiary_account_name: account_name,
        amount: parseFloat(amount),
        narration: narration,
        save_beneficiary: saveBenificiary,
        beneficiary_account_no: resolve_account?.data?.data?.data?.account_no,
        beneficiary_bank_name: bank?.bank_name,
        beneficiary_bank: bank?.bank_code,
      };
    } else {
      data = {
        beneficiary_account_name: account_name,
        amount: parseFloat(amount),
        save_beneficiary: saveBenificiary,
        beneficiary_account_no: resolve_account?.data?.data?.data?.account_no,
        beneficiary_bank_name: bank?.bank_name,
        beneficiary_bank: bank?.bank_code,
      };
    }
    handleAddAccount(data);
  };
  useEffect(() => {
    async function ConfirmAcc() {
      try {
        const response: any = await resolve_account.mutateAsync({
          account_no: account?.beneficiary_account_no,
          bank_code: account?.beneficiary_bank,
        });
        if (response?.status === 200) {
          setHidden(false);
          setError({
            account_number: false,
          });
        }
      } catch (error) {
        setError({
          account_number: true,
        });
        return error;
      }
    }
    if (account?.beneficiary_bank) ConfirmAcc();
  }, [account?.selectedBenificiary]);

  return (
    <div className="my-2">
      <div className="row mb-3">
        <div className="col-12">
          <label htmlFor="amount" className="mb-2 card-subtitle">
            Amount
          </label>
          <CurrencyInputField
            id="amount"
            name="amount"
            value={amount}
            onAmountChange={(value) => setAmount(value)}
            className="form-control border border-secondary"
            aria-label="amount"
          />
          {merchant_data?.charge_type === "flat" ? (
            <div id="amountHelp" className="form-text mt-1">
              You will be charged{" "}
              {formatAmount(parseFloat(merchant_data?.transfer_fee))} for this
              transaction.
            </div>
          ) : (
            <div id="amountHelp" className="form-text mt-1">
              You will be charged {merchant_data?.charge_percentage}% of the
              amount for this transaction capped at NGN 200.
            </div>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="form-label card-subtitle" htmlFor="account_number">
            Account number
          </label>
          <input
            type="text"
            className={`form-control border border-secondary mb-3 ${
              error?.account_number === false &&
              bank?.bank_code &&
              account_name &&
              account_number?.length === 10
                ? "is-valid"
                : ""
            }`}
            placeholder="Enter beneficiary’s account number"
            name="account_number"
            maxLength={10}
            pattern="\d*"
            value={account_number}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              const onlyNum = e?.target?.value?.replace(/[^0-9]/gi, "");
              setAccountNumber(onlyNum);
              setHidden(true);
              if (onlyNum?.length === 10 && bank?.bank_code) {
                try {
                  const response: any = await resolve_account.mutateAsync({
                    account_no: e?.target?.value,
                    bank_code: bank?.bank_code,
                  });
                  if (response.status === 200) {
                    setHidden(false);
                    setError({
                      account_number: false,
                    });
                  }
                } catch (error) {
                  setHidden(false);
                  setError({
                    account_number: true,
                  });
                  return error;
                }
              } else {
                setBank({});
              }
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="form-label card-subtitle" htmlFor="bank">
            Bank
          </label>
          <Select
            options={banks}
            isLoading={bankLoading}
            getOptionLabel={(option) => option.bank_name}
            getOptionValue={(option) => option.bank_code}
            isSearchable
            isClearable
            value={bank}
            menuPlacement="bottom"
            placeholder="Search..."
            className=" border-secondary rounded"
            onChange={async (option) => {
              setBank(option);
              if (account_number && option?.bank_code) {
                try {
                  const response: any = await resolve_account.mutateAsync({
                    account_no: account_number,
                    bank_code: option?.bank_code,
                  });
                  if (response.status === 200) {
                    setHidden(false);
                    setError({
                      account_number: false,
                    });
                  }
                } catch (error) {
                  setHidden(false);
                  setError({
                    account_number: true,
                  });
                  return error;
                }
              }
            }}
          />
          <span className="invalid-feedback">Please select a bank.</span>
        </div>
      </div>
      {!bank?.bank_code ||
      account_number?.length !== 10 ||
      account_name?.length < 1 ? (
        <div />
      ) : bank && account_name ? (
        <div className="row mb-3">
          <div className="col-12">
            <div
              className="text-center alert alert-primary --bor-pri rounded py-1 text-black fw-bold"
              style={{ backgroundColor: "#EAEDF7" }}
            >
              {account_name}
            </div>
          </div>
        </div>
      ) : resolve_account.isLoading ? (
        <div className="d-flex justify-content-center">
          <SpinnerComponent />
        </div>
      ) : !resolve_account.isError ? (
        <div />
      ) : (
        <div className="row mb-3">
          <div className="col-12">
            <div
              className="text-center alert alert-warning --bor-pri rounded py-1 fw-bold"
              style={{ backgroundColor: "#FFF5E5", color: "#FF9900" }}
            >
              Could not find beneficiary
            </div>
          </div>
        </div>
      )}

      <div className="row mb-3">
        <div className="col-12">
          <label htmlFor="amount" className="mb-2 card-subtitle">
            Narration
          </label>
          <textarea
            id="narration"
            name="narration"
            aria-label="narration"
            className="form-control border border-secondary"
            value={narration}
            rows={4}
            onChange={(e) => setNarration(e.target.value)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="form-check">
            <input
              className="form-check-input border border-secondary"
              type="checkbox"
              id="save"
              checked={saveBenificiary}
              onChange={(e) => setSaveBenificiary(e.target.checked)}
            />
            <label className="form-label card-subtitle " htmlFor="save">
              Save beneficiary’s details
            </label>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="d-flex flex-column align-items-center">
        {/* <button
          disabled={hidden}
          className="btn btn-outline --bor-pri my-2"
          onClick={() => handleAccountDetails()}
        >
          Add another transfer <i className="bi bi-plus-lg"></i>
        </button> */}
        <button
          disabled={
            hidden ||
            account_number?.length !== 10 ||
            parseFloat(amount) < 1 ||
            (typeof limit === "number"
              ? parseFloat(amount) > Number(limit)
              : false)
          }
          className="btn btn-primary btn-block w-100 --bor-pri my-2 card-subtitle"
          style={{ color: "white" }}
          onClick={() => {
            handleAccountDetails();
            handleContinue("summary");
          }}
        >
          Continue
        </button>

        {typeof limit === "number" && (
          <div id="continueHelp" className="form-text mt-1">
            Your transaction limit is {formatAmount(limit)}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewBenificiary;
