import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PATH_NAMES } from "../../constants/index.constant";
import {
  mode,
  validateEmail,
  validatePassword,
  validateText,
  validateUrl,
} from "../../helpers/index.helper";
import { useAuthValidator, useRegister } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import useResize from "../../hooks/resize.hook";
import { registerInterface } from "../../utils/interfaces";
import SpinnerComponent from "../spinner/Spinner";
import AccountInfo from "./AccountInfo";
import BuisnessInfo from "./BuisnessInfo";

type Props = {
  redirect_url: string | undefined | any;
};

function AuthForm({ redirect_url }: Props) {
  const { register_user } = useRegister();
  const { validator } = useAuthValidator();
  const { deviceInfo } = useUserDeviceInfo();
  const { size } = useResize();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<registerInterface>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    address: "",
    mobile_number: "",
    agreed_terms: false,
    developer: false,
    primary_bank: "",
    merchant_name: "",
    merchant_website: "",
  });
  const [error, setError] = useState({
    firstname: false,
    lastname: false,
    email: false,
    password: false,
    address: false,
    mobile_number: false,
    agreed_terms: false,
    developer: false,
    primary_bank: false,
    business_type: false,
    merchant_name: false,
    merchant_website: false,
    business_category: false,
  });
  const [valid, setIsValid] = useState<any>(null);
  const [isFirstNameValid, setValidateFirstName] = useState<any>(null);
  const [isLastNameValid, setValidateLastName] = useState<any>(null);
  const [isEmailValid, setValidateEmail] = useState<any>(null);
  const [isWebsite, setIsWebsite] = useState<any>(null);
  const [systemCheck, setSystemCheck] = useState({
    email: false,
    merchant_name: false,
    mobile_number: false,
  });

  const steps: string[] = ["Account Information", "Business Information"];

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name === "password") {
      setIsValid(validatePassword(value));
    }
    if (name === "email") {
      setValidateEmail(validateEmail(value));
    }

    if (name === "firstname") {
      setValidateFirstName(validateText(value));
    }

    if (name === "lastname") {
      setValidateLastName(validateText(value));
    }

    if (name === "merchant_website") {
      setIsWebsite(validateUrl(value));
    }
  };

  const handleSubmit = async (registerData: registerInterface) => {
    setIsLoading(true);
    try {
      const response = await register_user.mutateAsync(registerData);
      if (response.status === 200) {
        moengage.add_email(registerData?.email);
        moengage.add_first_name(registerData?.firstname);
        moengage.add_last_name(registerData?.lastname);
        moengage.add_mobile(registerData?.mobile_number);
        moengage.add_user_name(registerData?.email);
        moengage.add_unique_user_id(registerData?.email);

        moengage.track_event("SIGN_UP", {
          firstName: registerData?.firstname,
          lastName: registerData?.lastname,
          email: registerData?.email,
          mobile: registerData?.mobile_number,
          userName: registerData?.email,
          uniqueUserId: registerData?.email,
          phone_number: registerData?.mobile_number,
          terms_agreement: registerData?.agreed_terms,
          address: registerData?.address,
          primary_bank: registerData?.primary_bank,
          business_name: registerData?.merchant_name,
          business_category: registerData?.business_category,
          business_type: registerData?.business_type,
          platform: deviceInfo?.userAgent,
        });

        navigate(`/auth/merchant${PATH_NAMES.verify}`, {
          state: {
            email: response?.data?.data?.email,
            QR_CODE: response?.data?.data?.authImage,
            authSecret: response?.data?.data?.authSecret,
            firstname: registerData?.firstname,
            lastname: registerData?.lastname,
            primary_bank: registerData?.primary_bank,
            mobile_number: registerData?.mobile_number,
            business_name: registerData?.merchant_name,
          },
        });
        // navigate(`/auth${PATH_NAMES.signin}`)
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          address: "",
          mobile_number: "",
          agreed_terms: false,
          developer: false,
          primary_bank: "",
          business_type: "",
          merchant_name: "",
          business_category: "",
        });
        setError({
          firstname: false,
          lastname: false,
          email: false,
          password: false,
          address: false,
          mobile_number: false,
          agreed_terms: false,
          developer: false,
          primary_bank: false,
          business_type: false,
          merchant_name: false,
          merchant_website: false,
          business_category: false,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const formDisplay = () => {
    if (step === 0) {
      return (
        <AccountInfo
          formData={formData}
          handleChange={handleChange}
          error={error}
          isValid={valid}
          isEmailValid={isEmailValid}
          isFirstNameValid={isFirstNameValid}
          isLastNameValid={isLastNameValid}
          systemCheck={systemCheck}
        />
      );
    } else if (step === 1) {
      return (
        <BuisnessInfo
          formData={formData}
          handleChange={handleChange}
          error={error}
        />
      );
    }
  };

  const handleNextClick = () => {
    let isValid = true;
    if (formData.firstname.trim() === "" || isFirstNameValid === false) {
      setError((prev) => ({ ...prev, firstname: true }));
      isValid = false;
    }
    if (formData.lastname.trim() === "" || isLastNameValid === false) {
      setError((prev) => ({ ...prev, lastname: true }));
      isValid = false;
    }
    // ADD REG EX EMAIL TEST TO HANDLE EMAILS
    if (formData.email.trim() === "" || isEmailValid === false) {
      setError((prev) => ({ ...prev, email: true }));
      isValid = false;
    }
    if (formData.mobile_number.trim() === "") {
      setError((prev) => ({ ...prev, mobile_number: true }));
      isValid = false;
    }
    if (formData.password.trim() === "" || valid === false) {
      setError((prev) => ({ ...prev, password: true }));
      isValid = false;
    }

    if (formData.merchant_website?.trim() === "" || isWebsite === false) {
      setError((prev) => ({ ...prev, merchant_website: true }));
    }

    if (
      isValid &&
      valid === true &&
      isEmailValid === true &&
      isFirstNameValid === true &&
      isLastNameValid === true
    ) {
      setStep((prev) => prev + 1);
      setError({
        firstname: false,
        lastname: false,
        email: false,
        password: false,
        address: false,
        mobile_number: false,
        agreed_terms: false,
        developer: false,
        primary_bank: false,
        business_type: false,
        merchant_name: false,
        merchant_website: false,
        business_category: false,
      });
    }
  };

  const validateBeforeSubmit = async () => {
    setIsLoading(true);
    let isValid = true;
    if (formData.merchant_name.trim() === "") {
      setError((prev) => ({ ...prev, merchant_name: true }));
      const passed = await performSytemCheck({
        query: "merchant_name",
        value: formData.merchant_name,
      });
      passed !== "PASSED"
        ? setSystemCheck((prev) => ({ ...prev, merchant_name: true }))
        : setSystemCheck((prev) => ({ ...prev, merchant_name: false }));
      isValid = false;
    }

    if (formData.primary_bank === "") {
      setError((prev) => ({ ...prev, primary_bank: true }));
      isValid = false;
    }
    
    if (formData.business_type === "") {
      setError((prev) => ({ ...prev, business_type: true }));
      isValid = false;
    }
    if (formData.business_category === "") {
      setError((prev) => ({ ...prev, business_category: true }));
      isValid = false;
    }

    if (formData.agreed_terms === false) {
      setError((prev) => ({ ...prev, agreed_terms: true }));
      isValid = false;
    }

    if (formData.mobile_number.trim() !== "") {
      const passed = await performSytemCheck({
        query: "mobile_number",
        value: formData.mobile_number,
      });
      passed !== "PASSED"
        ? setSystemCheck((prev) => ({ ...prev, mobile_number: true }))
        : setSystemCheck((prev) => ({ ...prev, mobile_number: false }));
    }

    if (formData.email.trim() !== "") {
      const passed = await performSytemCheck({
        query: "email",
        value: formData.email,
      });
      passed !== "PASSED"
        ? setSystemCheck((prev) => ({ ...prev, email: true }))
        : setSystemCheck((prev) => ({ ...prev, email: false }));
    }

    if (systemCheck.email === true) {
      isValid = false;
    }

    if (systemCheck.mobile_number === true) {
      isValid = false;
    }

    if (systemCheck.merchant_name === true) {
      isValid = false;
    }

    if (isValid) {
      handleSubmit(formData);
    }

    if (!isValid) {
      setIsLoading(false);
    }
  };

  const performSytemCheck = async (data: { query: string; value: string }) => {
    try {
      const response = await validator.mutateAsync(data);
      if (response.status === 200) {
        const passed = response?.data?.data;
        return passed;
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <form
      className="js-validate needs-validation overflow-hidden"
      noValidate
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
    >
      <div className="text-center">
        <div className="mb-5">
          <h1 className="display-5">Create an account</h1>
        </div>

        <span className="divider-center text-muted mb-4">{steps[step]}</span>
      </div>
      {formDisplay()}
      {step === 0 ? (
        <div className="d-grid px-4 px-lg-0">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => handleNextClick()}
            disabled={
              valid === false ||
              isEmailValid === false ||
              isFirstNameValid === false ||
              isLastNameValid === false
                ? true
                : false
            }
          >
            <span className="pe-1">Continue</span>{" "}
            <i className="bi bi-arrow-right"></i>
          </button>
          <div className="form-check mb-4 mx-auto mt-3 text-center">
            <label className="form-check-label" htmlFor="termsCheckbox">
              <span className="d-flex justify-content-between align-items-center">
                <span>Already have an account?</span>
                &nbsp;&nbsp;
                <Link className="form-label-link mb-0" to={redirect_url}>
                  Login
                </Link>
              </span>
            </label>
          </div>
        </div>
      ) : (
        <div className="px-4 px-lg-0 w-100">
          <div className="row">
            <div className="col-6 col-md-6 col-lg-6 my-1 my-lg-0">
              <button
                type="button"
                disabled={isLoading}
                className="btn btn-outline-secondary btn-lg w-100 border border-secondary"
                onClick={() =>
                  setStep((prev: any): any => {
                    if (prev > 0) {
                      return prev - 1;
                    } else {
                      return 0;
                    }
                  })
                }
              >
                Back
              </button>
            </div>
            <div className="col-6 col-md-6 col-lg-6 my-1 my-lg-0">
              {size?.[0] < 998 ? (
                <button
                  type="button"
                  disabled={isLoading}
                  className="btn btn-primary btn-lg w-100"
                  onClick={() => validateBeforeSubmit()}
                >
                  {isLoading ? "Creating..." : "Create account"}
                </button>
              ) : (
                <button
                  type="button"
                  disabled={isLoading}
                  className="btn btn-primary btn-lg w-100"
                  onClick={() => validateBeforeSubmit()}
                >
                  Create account &nbsp;{" "}
                  <span className="align-middle">
                    {isLoading && <SpinnerComponent size="sm" />}
                  </span>
                </button>
              )}
            </div>
            <div className="form-check mb-4 mx-auto mt-3 text-center">
              <label className="form-check-label" htmlFor="termsCheckbox">
                <span className="d-flex justify-content-between align-items-center">
                  <span>Already have an account?</span>
                  &nbsp;&nbsp;
                  <Link className="form-label-link mb-0" to={redirect_url}>
                    Login
                  </Link>
                </span>
              </label>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

// col-lg-6 my-2 my-lg-0 px-4 px-lg-0

export default AuthForm;
