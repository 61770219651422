import React from "react";
import Avatar from "../../assets/svg/illustrations/oc-browse.svg";

type Props = {
  fileName?: any;
  onFileExport?: (file: File) => void;
  componentId?: string;
  uploadProgress?: React.ReactNode;
  imageUrl?: string;
  isDisabled?: boolean;
};
function DragAndDrop({
  onFileExport,
  fileName,
  componentId,
  uploadProgress,
  imageUrl,
  isDisabled,
}: Props) {
  const handleFileChange = (event: any) => {
    const file: any | undefined = event?.target?.files[0];
    if (onFileExport && file) {
      onFileExport(file);
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const file: any | undefined = event?.dataTransfer?.files[0];
    if (onFileExport && file) {
      onFileExport(file);
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="px-5 d-none d-md-block d-lg-block w-100">
        <div
          id="basicExampleDropzone"
          className="js-dropzone row dz-dropzone dz-dropzone-card border border-secondary"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div className="dz-message">
            {imageUrl ? (
              <img
                className="avatar avatar-xl avatar-4x3 mb-3"
                src={imageUrl}
                alt="Description"
              />
            ) : (
              <img
                className="avatar avatar-xl avatar-4x3 mb-3"
                src={Avatar}
                alt="Description"
              />
            )}

            <h5>Drag and drop your file here</h5>

            <p className="mb-2">or</p>

            <label htmlFor={componentId} className="btn btn-white btn-sm" hidden={isDisabled}>
              Browse files (supported file include png, jpeg, jpg and pdf)
            </label>

            <p className="form-label pt-3">{fileName}</p>
            {fileName && <>{uploadProgress}</>}
            <input
              type="file"
              id={componentId}
              // accept="image/*"
              accept=".png, .jpg, .jpeg, .pdf, .docx"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>

      <div className="d-sm-block d-md-none d-lg-none">
        <div
          id="basicExampleDropzone"
          className="js-dropzone row dz-dropzone dz-dropzone-carddd py-4"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            background: "transparent !important",
            border: "none !important",
          }}
        >
          <div className="dz-message">
          {imageUrl ? (
              <img
                className="avatar avatar-xl avatar-4x3 mb-3"
                src={imageUrl}
                alt="Description"
              />
            ) : (
              <img
                className="avatar avatar-xl avatar-4x3 mb-3"
                src={Avatar}
                alt="Description"
              />
            )}

            <h5 style={{ fontSize: "0.6rem" }}>Drag and drop your file here</h5>

            <p className="mb-2">or</p>

            <label htmlFor={componentId} className="btn btn-white btn-sm">
              Browse files
            </label>

            <p className="form-label pt-3">{fileName}</p>
            {fileName && <>{uploadProgress}</>}
            <input
              type="file"
              id={componentId}
              // accept="image/*"
              accept=".png, .jpg, .jpeg, .pdf, .docx"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DragAndDrop;
