import React, { useState } from "react";
import Select from "react-select";
import { formatAmount, shortenString } from "../../helpers/index.helper";
import { useResolveAccount } from "../../hooks/payouts/payouts.hook";
import { useBenificiaries } from "../../hooks/transfer/index.hook";
import { benificiaryProps, sessionInterface } from "../../utils/interfaces";
import CurrencyInputField from "../CurrencyInput/CurrencyInput";
import MakeTransferModalMobile from "../modal/MakeTransferModalMobile";

function SavedBeneficiaryMobile({
  merchant_data,
}: {
  merchant_data?: sessionInterface;
}): JSX.Element {
  const [amount, setAmount] = useState<string>("");
  const [narration, setNarration] = useState<string>("");

  const [hidden, setHidden] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [show, setShow] = React.useState<boolean>(false);
  const [accountData, setAccountData] = useState<any>({});
  const [selectedBenificiary, setSelectedBenificiary] = useState<any>({});

  const handleClose = () => setShow(false);

  const { beneficiaryData, isLoading } = useBenificiaries();
  const beneficiaries: benificiaryProps[] = beneficiaryData?.data?.data?.items;

  const { resolve_account } = useResolveAccount();

  const limit = merchant_data?.account_limit?.daily_withdrawal;

  return (
    <>
      <MakeTransferModalMobile
        show={show}
        handleClose={handleClose}
        data={accountData}
        mobileStage="initiate"
      />
      <div className="my-2">
        <div className="row mb-3">
          <div className="col-12">
            <label htmlFor="amount" className="mb-2 card-subtitle">
              Amount
            </label>
            <CurrencyInputField
              id="amount"
              name="amount"
              value={amount}
              onAmountChange={(value) => setAmount(value)}
              className="form-control border border-secondary"
              aria-label="amount"
            />
            {merchant_data?.charge_type === "flat" ? (
              <div id="amountHelp" className="form-text mt-1">
                You will be charged{" "}
                {formatAmount(parseFloat(merchant_data?.transfer_fee))} for this
                transaction.
              </div>
            ) : (
              <div id="amountHelp" className="form-text mt-1">
                You will be charged {merchant_data?.charge_percentage}% of the
                amount for this transaction capped at NGN 200.
              </div>
            )}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label className="form-label card-subtitle" htmlFor="bank">
              Saved Beneficiary
            </label>
            <div>
              <Select
                options={beneficiaries}
                isLoading={isLoading}
                formatOptionLabel={(option: benificiaryProps, meta: any) => {
                  if (meta?.context === "value") {
                    return (
                      <>
                        <strong>
                          {option?.account_name} - {option?.bank_name}
                        </strong>
                      </>
                    );
                  }
                  return (
                    <>
                      <strong>
                        {shortenString(option?.account_name, 15)} -{" "}
                        {option?.bank_name}
                      </strong>
                      <br />
                      <span>{option?.account_number}</span>
                    </>
                  );
                }}
                className=" border-secondary rounded card-subtitle"
                getOptionValue={(option) => option?.id?.toString()}
                getOptionLabel={(option) =>
                  `${option.bank_name} ${option?.account_name}`
                }
                isSearchable
                isClearable
                menuPlacement="bottom"
                placeholder="Search..."
                value={selectedBenificiary}
                onChange={async (option) => {
                  setSelectedBenificiary(option);
                  try {
                    const response: any = await resolve_account.mutateAsync({
                      account_no: option?.account_number,
                      bank_code: option?.bank_code,
                    });
                    if (response?.status === 200) {
                      setHidden(false);
                      setError({
                        account_number: false,
                      });
                    }
                  } catch (error) {
                    setError({
                      account_number: true,
                    });
                    return error;
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <label htmlFor="amount" className="mb-2 card-subtitle">
              Narration
            </label>
            <textarea
              id="narration"
              name="narration"
              aria-label="narration"
              className="form-control border border-secondary"
              value={narration}
              rows={4}
              onChange={(e) => setNarration(e.target.value)}
            />
          </div>
        </div>

        <br />
        <br />
        <br />
        <div className="d-flex flex-column align-items-center">
          <button
            disabled={
              hidden ||
              parseFloat(amount) < 1 ||
              (typeof limit === "number"
                ? parseFloat(amount) > Number(limit)
                : false)
            }
            className="btn btn-primary btn-block w-100 --bor-pri my-2 card-subtitle"
            style={{ color: "white" }}
            onClick={() => {
              setShow(true);
              if (narration) {
                setAccountData({
                  beneficiary_account_name: selectedBenificiary?.account_name,
                  amount: parseFloat(amount),
                  narration: narration,
                  save_beneficiary: true,
                  beneficiary_account_no:
                    resolve_account?.data?.data?.data?.account_no,
                  beneficiary_bank_name: selectedBenificiary?.bank_name,
                  beneficiary_bank: selectedBenificiary?.bank_code,
                  selectedBenificiary,
                });
              } else {
                setAccountData({
                  beneficiary_account_name: selectedBenificiary?.account_name,
                  amount: parseFloat(amount),
                  save_beneficiary: true,
                  beneficiary_account_no:
                    resolve_account?.data?.data?.data?.account_no,
                  beneficiary_bank_name: selectedBenificiary?.bank_name,
                  beneficiary_bank: selectedBenificiary?.bank_code,
                  selectedBenificiary,
                });
              }
            }}
          >
            Continue
          </button>

          {typeof limit === "number" && (
            <div id="continueHelp" className="form-text mt-1">
              Your transaction limit is {formatAmount(limit)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SavedBeneficiaryMobile;
