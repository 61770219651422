import React, { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as Empty } from "../../assets/empty.svg";
import { formatAmount, shortenString } from "../../helpers/index.helper";
import useDebounce from "../../hooks/debounce.hook";
import {
  useUnsettledTranactionExports,
  useUnsettledTransactions,
} from "../../hooks/transactions/index.hook";
import { ITransaction, ITransactions } from "../../utils/interfaces";
import EmptyResponse from "../EmptyResponse";
import PaginationComponent from "../pagination/pagination";

function Unsettled(): JSX.Element {
  const navigate = useNavigate();

  const isAdmin = window.location.pathname.includes("/admin");

  const [transactions, setTransactions] = useState<ITransactions>();
  const [reference, setReference] = useState("");
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [filter, setFilter] = useState({
    reference: "",
    start_date: "",
    end_date: "",
  });
  const [activeItem, setActiveItem] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const debouncedInputValue = useDebounce<string>(reference, 2000);
  const { export_transactions } = useUnsettledTranactionExports();
  const { data, refetch, isLoading, isFetching } = useUnsettledTransactions(
    {
      reference: debouncedInputValue,
      start_date: filter.start_date,
      end_date: filter.end_date,
    },
    activeItem
  );

  const totalItems = data?.data?.data?.totalPages;

  const handleRefFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    refetch();
  };

  const filterData = () => {
    if (dates?.start_date === "" && dates?.end_date === "") {
      // setFilter({
      //   reference,
      //   ...dates,
      // });
      // export_transactions
      //   .mutateAsync({
      //     start_date: dates.start_date,
      //     end_date: dates.end_date,
      //     download: "all",
      //   })
      //   .then((data: any) => {
      //     const url = data?.data?.data;
      //     const link: any = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     link.parentNode.removeChild(link);
      //   })
      //   .catch((error: any) => {
      //     return error;
      //   });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          const url = data?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          return error;
        });
    }
  };

  const refreshData = () => {
    setReference("");
    setDates({
      start_date: "",
      end_date: "",
    });
    setFilter({
      reference,
      ...dates,
    });
    refetch();
  };

  useEffect(() => {
    if (data) {
      setTransactions(data?.data?.data);
    }
  }, [data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="card">
      <div className="card-header card-header-content-md-between">
        <div className="mb-2 mb-md-0 w-50">
          <form onSubmit={handleSearch}>
            <div className="input-group input-group-merge input-group-flush w-75">
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                id="datatableSearch"
                type="search"
                className="form-control"
                value={reference}
                onChange={handleRefFilter}
                placeholder="Search by account number, amount, reference, session ID"
                aria-label="Search by reference"
              />
            </div>
          </form>
        </div>

        <div className="d-grid d-sm-flex gap-2">
          {/* {transactions?.items !== undefined &&
          transactions?.items?.length > 0 ? (
            <>
              <DropDownWrapper
                action={
                  <button
                    ref={buttonRef}
                    type="button"
                    className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                  >
                    <i className="bi bi-filter"></i> Filter
                  </button>
                }
              >
                <div className="p-3">
                  <label className="text-body">Date Range</label>
                  <div className="row mb-4">
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="start_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="end_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="btn btn-white btn-sm"
                      onClick={() => {
                        handleClose();
                        buttonRef?.current?.click();
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={filterData}
                      style={{ cursor: "pointer" }}
                    >
                      Apply filter
                    </button>
                  </div>
                </div>
              </DropDownWrapper>

              <DropDownWrapper
                action={
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    ref={downloadRef}
                  >
                    <span style={{ color: "#128100 !important" }}>
                      Download
                    </span>
                    <DownloadIcon className="ms-2 text-success" />
                  </button>
                }
              >
                <div className="p-3">
                  <label className="text-body">Date Range</label>
                  <div className="row mb-4">
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="start_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="end_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="btn btn-white btn-sm"
                      onClick={() => {
                        downloadRef?.current?.click();
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={filterData}
                      style={{ cursor: "pointer" }}
                    >
                      Exports
                    </button>
                  </div>
                </div>
              </DropDownWrapper>
            </>
          ) : (
            ""
          )} */}
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-white btn-sm w-100"
              aria-expanded="false"
              onClick={() => {
                refreshData();
                refetch();
              }}
            >
              <i className="bi bi-arrow-clockwise me-2"></i> Refresh
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive datatable-custom">
        <table
          id="datatable"
          className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
          style={{ width: "100%" }}
        >
          <thead className="thead-light">
            <tr>
              <th>Name</th>
              <th>Account No</th>
              <th>Amount</th>
              <th>Transaction Type</th>
              <th>Status</th>
              <th>Transaction Date</th>
              {/* <th>Settlement</th> */}
              <th>Reference</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>

          {isLoading || isFetching ? (
            <tbody>
              {[...Array(5)].map((i, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    {/* <td>
                      <Skeleton height={50} />
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {transactions?.items?.length === 0 ? (
                <>
                  <tr>
                    <td colSpan={8}>
                      <EmptyResponse
                        title="No record found"
                        image={<Empty />}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {transactions?.items?.map((transaction: ITransaction) => {
                    return (
                      <tr
                        key={transaction?.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            isAdmin
                              ? `/admin/transactions/unsettled/${transaction?.id}`
                              : `/merchant/transactions/unsettled/${transaction?.id}`
                          );
                        }}
                      >
                        <td>
                          {transaction?.transaction_type === "collection" && (
                            <>
                              <span className="text-capitalize">
                                {transaction?.sender_details?.account_name ||
                                  transaction?.sender_details?.name}
                              </span>
                            </>
                          )}
                          {transaction?.transaction_type === "transfer" && (
                            <>
                              <span className="">
                                {transaction?.reciever_details?.name}
                              </span>
                            </>
                          )}
                          {transaction?.transaction_type ===
                            "intra-transfer" && (
                            <>
                              <span className="">
                                {transaction?.reciever_details?.name}
                              </span>
                            </>
                          )}
                          {transaction?.transaction_type === "payout" && (
                            <>
                              <span className="">
                                {transaction?.reciever_details?.name}
                              </span>
                            </>
                          )}
                        </td>
                        <td>
                          {transaction?.transaction_type === "collection" && (
                            <>
                              <span className="text-capitalize">
                                {transaction?.sender_details?.account_no ||
                                  transaction?.sender_details?.account_number}
                              </span>
                            </>
                          )}
                          {transaction?.transaction_type === "transfer" && (
                            <>
                              <span className="">
                                {transaction?.reciever_details?.account_no}
                              </span>
                            </>
                          )}
                          {transaction?.transaction_type ===
                            "intra-transfer" && (
                            <>
                              <span className="">
                                {transaction?.reciever_details?.account_no}
                              </span>
                            </>
                          )}
                          {transaction?.transaction_type === "payout" && (
                            <>
                              <span className="">
                                {transaction?.reciever_details?.account_no}
                              </span>
                            </>
                          )}
                        </td>
                        <td>
                          {formatAmount(
                            +transaction?.amount,
                            transaction?.currency
                          )}
                        </td>
                        {transaction?.transaction_type === "collection" && (
                          <td className="">
                            <span className="badge bg-soft-info text-info">
                              COLLECTION
                            </span>
                          </td>
                        )}
                        {transaction?.transaction_type === "transfer" && (
                          <td className="">
                            <span className="badge bg-soft-secondary text-secondary">
                              TRANSFER
                            </span>
                          </td>
                        )}
                        {transaction?.transaction_type === "intra-transfer" && (
                          <td className="">
                            <span className="badge bg-soft-secondary text-secondary">
                              TRANSFER
                            </span>
                          </td>
                        )}
                        {transaction?.transaction_type === "payout" && (
                          <td className="">
                            <span className="badge bg-soft-secondary text-secondary">
                              PAYOUT
                            </span>
                          </td>
                        )}
                        <td>
                          {transaction?.settlement_complete === true ? (
                            <span className="badge bg-soft-success text-success">
                              COMPLETED
                            </span>
                          ) : (
                            ""
                          )}
                          {transaction?.settlement_complete === false ? (
                            <span className="badge bg-soft-warning text-warning">
                              PENDING
                            </span>
                          ) : (
                            ""
                          )}
                          {transaction?.payout?.failed === true ? (
                            <span className="badge bg-soft-danger text-danger">
                              FAILED
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{transaction?.created_at}</td>

                        <td>{shortenString(transaction?.reference, 15)}</td>
                        {/* <td>
                          <div className="btn-group" role="group">
                            <Link
                              className="btn btn-white btn-sm"
                              to={
                                isAdmin
                                  ? `/admin/transactions/unsettled/${transaction?.id}`
                                  : `/merchant/transactions/unsettled/${transaction?.id}`
                              }
                            >
                              <i className="bi-eye"></i> View
                            </Link>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          )}
        </table>
      </div>

      <div className="card-footer">
        <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
          <div className="col-sm mb-2 mb-sm-0"></div>

          <div className="col-sm-auto">
            <div className="d-flex justify-content-center justify-content-sm-end">
              {totalItems > 1 && (
                <PaginationComponent
                  totalItems={totalItems}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unsettled;
