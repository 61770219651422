import React, { useState } from "react";
import NewBeneficiaryMobile from "../../component/transfer/NewBeneficiaryMobile";
import { useSession } from "../../hooks/auth/auth.hook";

import ProgressBar from "react-bootstrap/ProgressBar";
import SavedBeneficiaryMobile from "../../component/transfer/SavedBeneficiaryMobile";
import { formatAmount } from "../../helpers/index.helper";
import useResize from "../../hooks/resize.hook";
import { useTransferDayUsage } from "../../hooks/transfer/index.hook";
import { sessionInterface } from "../../utils/interfaces";

function MakeTransferMobile(): JSX.Element {
  const [checked, setChecked] = useState<string>("new");
  const dailyUsage = useTransferDayUsage();
  const { sessionData } = useSession();
  const merchant_data: sessionInterface = sessionData?.data?.data;

  const totalOutflow = dailyUsage?.data?.data?.data?.transferValueToday;

  const percentage = merchant_data?.account_limit?.daily_withdrawal
    ? (totalOutflow /
        parseInt(merchant_data?.account_limit?.daily_withdrawal)) *
      100
    : 0;

  const { size } = useResize();

  return (
    <>
      <div
        className={`d-flex flex-column ${
          size?.[0] <= 998 ? "w-100" : "w-50 mx-auto"
        }`}
      >
        <div className="d-flex flex-column gap-4">
          <div
            className="btn-group btn-group-segment"
            role="group"
            aria-label="Basic segment styles example"
          >
            <input
              type="radio"
              className="btn-check"
              name="btnGroupSegmentRadio"
              id="btnGroupSegmentRadioOption2"
              autoComplete="off"
              onClick={() => setChecked("new")}
              checked={checked === "new" ? true : false}
            />
            <label
              className="btn btn-sm"
              htmlFor="btnGroupSegmentRadioOption2"
              style={{ cursor: "pointer" }}
            >
              New beneficiary
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnGroupSegmentRadio"
              id="btnGroupSegmentRadioOption3"
              autoComplete="off"
              onClick={() => setChecked("saved")}
              checked={checked === "saved" ? true : false}
            />
            <label
              className="btn btn-sm"
              htmlFor="btnGroupSegmentRadioOption3"
              style={{ cursor: "pointer" }}
            >
              Saved beneficiary
            </label>
          </div>
          <div className="d-flex flex-column">
            <small>DAILY WITHDRAWAL LIMIT</small>
            {percentage ? (
              <ProgressBar now={percentage} />
            ) : (
              <ProgressBar now={percentage} />
            )}
            <div className="d-flex flex-row align-items-center justify-content-between">
              <small>NGN{totalOutflow ? totalOutflow : 0}</small>
              {merchant_data?.account_limit?.daily_withdrawal && (
                <small>
                  {merchant_data?.account_limit?.daily_withdrawal ===
                  "unlimited"
                    ? "Unlimited"
                    : formatAmount(
                        parseFloat(
                          merchant_data?.account_limit?.daily_withdrawal
                        )
                      )}
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          {checked === "new" && (
            <>
              <NewBeneficiaryMobile merchant_data={merchant_data} />
            </>
          )}

          {checked === "saved" && (
            <>
              <SavedBeneficiaryMobile merchant_data={merchant_data} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MakeTransferMobile;
