import React from "react";
import { Modal } from "react-bootstrap";

function FilterModal({
  show,
  handleClose,
  children,
  title,
}: {
  show: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  title?: string;
}): JSX.Element {
  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
      size="sm"
    >
      {title ? (
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
      ) : null}

      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default FilterModal;
