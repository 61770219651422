import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import Kyc from "./Kyc";
import DirectorFields from "./director/DirectorFields";
import { useKyc, useSession, useSubmitKyc } from "../../hooks/auth/auth.hook";
import { useNavigate } from "react-router-dom";
import { kycInterface, sessionInterface } from "../../utils/interfaces";
import SpinnerComponent from "../../component/spinner/Spinner";
import API_SERVICE from "../../services/services";
import { PATH_NAMES } from "../../constants/index.constant";

function Documents(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, refetch } = useSession();
  const { provide_kyc } = useKyc();
  const { submit_kyc } = useSubmitKyc();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [exportedFields, setExportedFields] = useState([]);

  const merchant_data: sessionInterface = data?.data?.data;

  const handleExportFields = (fields: any) => {
    setExportedFields(fields);
  };

  const business_information: any =
    merchant_data?.kycDetails?.business_information;
  const documents: any = merchant_data?.kycDetails?.documents;
  const personal_information: any =
    merchant_data?.kycDetails?.personal_information;
  const director_information =
    merchant_data?.kycDetails?.business_information?.director_information
      ?.director_information;

  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());
  }, [queryClient]);

  const mapExportToJson =
    exportedFields.length > 0 &&
    exportedFields.map((item: any) => {
      const { document, showNextOfKin, ...rest } = item;
      const { imageName, ...documentWithoutImageName } = document;
      return {
        ...rest,
        document: documentWithoutImageName,
      };
    });

  const SaveDirectorInformation = async (payload: kycInterface) => {
    setIsLoading(true);
    try {
      const response: any = await provide_kyc.mutateAsync(payload);
      if (response.status === 200) {
        refetch();
        setIsLoading(false);
        setIsSaved(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const HandleSaveDirectorOnSm = async (payload: kycInterface) => {
    setIsLoading(true);
    try {
      const response: any = await provide_kyc.mutateAsync(payload);
      if (response.status === 200) {
        SubmitKYCDetails();
        refetch();
        setIsLoading(false);
        setIsSaved(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const SubmitKYCDetails = async () => {
    setLoading(true);
    try {
      const response = await submit_kyc.mutateAsync();
      if (response.status === 200) {
        setLoading(false);
        navigate(`/merchant`);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  return (
    <Kyc activeTab="licence">
      <div
        id="basicVerStepDetails"
        className="card card-body active"
        style={{ minHeight: "15rem" }}
      >
        <div className="row align-items-center mb-5 pb-2 mt-2">
          <div className="col-sm mb-2 mb-sm-0">
            <h2 className="page-header-title">Director’s information</h2>
          </div>
        </div>

        <DirectorFields
          onFileExport={handleExportFields}
          data={director_information}
        />

        {/* LG && MD SCREEN */}
        <div className="d-none d-md-block d-lg-block">
          <div className="row align-items-center justify-content-end  pb-2 mt-2">
            <div className="col-sm-auto">
              <button
                type="button"
                disabled={isLoading}
                className="btn btn-primary"
                data-hs-step-form-next-options='{
                        "targetSelector": "#basicVerStepTerms"
                      }'
                onClick={() =>
                  SaveDirectorInformation({
                    personal_information: { ...personal_information },
                    business_information: {
                      ...business_information,
                      director_information: {
                        director_information: mapExportToJson,
                      },
                    },
                    documents: { ...documents },
                  })
                }
              >
                Save changes{" "}
                {isLoading && (
                  <>
                    &nbsp;{" "}
                    <span className="align-middle">
                      <SpinnerComponent size="sm" />
                    </span>
                  </>
                )}
              </button>
            </div>
            <div className="col-sm-auto">
              <button
                type="button"
                disabled={isSaved}
                className="btn btn-primary"
                onClick={SubmitKYCDetails}
              >
                Submit
                {loading && (
                  <>
                    &nbsp;{" "}
                    <span className="align-middle">
                      <SpinnerComponent size="sm" />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* SM SCREENS */}
        <div className="d-sm-block d-md-none d-lg-none">
          <div className="d-flex align-items-center justify-content-end  pb-2 mt-2">
            {/* <div className="ms-auto">
              <button
                type="button"
                onClick={() => navigate(`/merchant/kyc/personal_information`)}
                className="btn btn-ghost-secondary"
              >
                <i className="bi bi-chevron-left"></i>
                &nbsp;Back
              </button>
            </div> */}
            <div className="col-sm-auto">
              <button
                type="button"
                onClick={() => navigate(`/merchant/kyc/personal_information`)}
                className="btn btn-ghost-secondary"
              >
                <i className="bi bi-chevron-left"></i>
                &nbsp;Back
              </button>

              {/* <button
                type="button"
                disabled={isSaved}
                className="btn btn-ghost-success"
                onClick={SubmitKYCDetails}
              >
                Submit
                {loading && (
                  <>
                    &nbsp;{" "}
                    <span className="align-middle">
                      <SpinnerComponent size="sm" />
                    </span>
                  </>
                )}
              </button> */}
            </div>
            <div className="col-sm-auto">
              <button
                type="button"
                disabled={isLoading}
                className="btn btn-ghost-primary"
                data-hs-step-form-next-options='{
                        "targetSelector": "#basicVerStepTerms"
                      }'
                onClick={() =>
                  HandleSaveDirectorOnSm({
                    personal_information: { ...personal_information },
                    business_information: {
                      ...business_information,
                      director_information: {
                        director_information: mapExportToJson,
                      },
                    },
                    documents: { ...documents },
                  })
                }
              >
                Save changes
                {isLoading && (
                  <>
                    &nbsp;{" "}
                    <span className="align-middle">
                      <SpinnerComponent size="sm" />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Kyc>
  );
}

export default Documents;
