import { useMutation, useQuery } from "react-query";
import API_SERVICE from "../../services/services";
import { initQueryOptions, parseResponse } from "../../helpers/query.helper";

export const useAirtimeBulk = (page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["bulk", "airtime", page],
      () => API_SERVICE._getAirtimeBulkTransaction(page),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useDataBulk = (page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["bulk", "data", page],
      () => API_SERVICE._getDataBulkTransaction(page),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useAirtimeBulkByID = (id: string, page?: number ) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["bulk", "airtime", page, id],
      () => API_SERVICE._getAirtimeBulkTransactionBYRef(page, id),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useDataBulkByID = (id: string, page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["bulk", "data", page, id],
      () => API_SERVICE._getDataBulkTransactionBYRef(page, id),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useAirtimeNumberInfo = (id?: string | number) => {
  const { data, error, isLoading, isSuccess, isError } = useQuery(
    ["airtime", "number", id],
    () => API_SERVICE._getNumberInfo(id),
    {
      ...initQueryOptions(),
      enabled: !!id,
    }
  );
  return { data, error, isError, isLoading, isSuccess };
};

export const useAirtime = (page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["airtime", page],
      () => API_SERVICE._getAirtimeTransaction(page),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useDataNumberInfo = (id?: string | number) => {
  const { data, error, isLoading, isSuccess, isError } = useQuery(
    ["data", "number", id],
    () => API_SERVICE._getNumberDataInfo(id),
    {
      ...initQueryOptions(),
      enabled: !!id,
    }
  );
  return { data, error, isError, isLoading, isSuccess };
};

export const useData = (page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["data", page],
      () => API_SERVICE._getDataTransaction(page),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const usePrepareAirtimePurchase = () => {
  const resolve_prepare_purchase_airtime: any = useMutation(
    (payload: {
      mobile_number: string;
      purchase_id: string;
      amount: string;
    }): any => API_SERVICE._postPrepareAirtimePurchase(payload),
    {
      ...initQueryOptions(),
    }
  );
  return { resolve_prepare_purchase_airtime };
};

export const usePrepareDataPurchase = () => {
  const resolve_prepare_purchase_data: any = useMutation(
    (payload: {
      mobile_number: string;
      purchase_id: string;
      amount: string;
      description: string;
    }): any => API_SERVICE._postPrepareDataPurchase(payload),
    {
      ...initQueryOptions(),
    }
  );
  return { resolve_prepare_purchase_data };
};

export const useConfirmAirtimePurchase = () => {
  const resolve_confirm_purchase_airtime: any = useMutation(
    ({ id, transaction_pin }: { id: string; transaction_pin: any }): any =>
      API_SERVICE._postConfirmAirtimePurchase({ transaction_pin }, id),
    {
      ...initQueryOptions(),
      ...parseResponse(),
    }
  );
  return { resolve_confirm_purchase_airtime };
};

export const useConfirmDataPurchase = () => {
  const resolve_confirm_purchase_data: any = useMutation(
    ({ id, transaction_pin }: { id: string; transaction_pin: any }): any =>
      API_SERVICE._postConfirmDataPurchase({ transaction_pin }, id),
    {
      ...initQueryOptions(),
      ...parseResponse(),
    }
  );
  return { resolve_confirm_purchase_data };
};

export const useBillPaymentBeneficiary = (page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["airtime", "Beneficiaries", page],
      () => API_SERVICE._getBeneficiaries(page),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useBillPaymentBeneficiaryByID = (id: string) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["airtime", "Beneficiaries", id],
      () => API_SERVICE._getBeneficiaryByID(id),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useCreateBeneficiary = () => {
  const resolve_create_beneficiary: any = useMutation(
    (payload: any): any => API_SERVICE._postCreateBeneficiary(payload),
    {
      ...initQueryOptions(),
      ...parseResponse(),
    }
  );
  return { resolve_create_beneficiary };
};

export const useDeleteBeneficiary = () => {
  const resolve_delete_beneficiary: any = useMutation(
    (payload: any): any => API_SERVICE._deleteBeneficiaryByID(payload),
    {
      ...initQueryOptions(),
      ...parseResponse(),
    }
  );
  return { resolve_delete_beneficiary };
};
