import { useMutation } from "react-query";
import { instance } from "../../utils/instance";
import { RequestMethod } from "../../utils/enums";
import { initQueryOptions } from "../../helpers/query.helper";

export const useStateManager = () => {
  const api_env = useMutation(
    () => {
      return instance({
        url: `/merchant/state`,
        method: RequestMethod.POST,
      });
    },
    {
      ...initQueryOptions(),
    }
  );
  return { api_env };
};
