import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { PATH_NAMES } from "../../constants/index.constant";
import { formatAmount } from "../../helpers/index.helper";

import useUserDeviceInfo from "../../hooks/device.hook";

import useDebounce from "../../hooks/debounce.hook";
import { useTranactionExports } from "../../hooks/transactions/index.hook";
import { sessionInterface } from "../../utils/interfaces";
import FilterModal from "../modal/filterModal";
import SpinnerComponent from "../spinner/Spinner";
import SettledSm from "./SettledSm";
import UnsettledSm from "./UnsettledSm";

function TransactionsTableMobile({
  merchant_data,
}: {
  merchant_data?: sessionInterface;
}): JSX.Element {
  const { export_transactions } = useTranactionExports();
  const { deviceInfo } = useUserDeviceInfo();
  const [checked, setChecked] = useState<string>("settled");

  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [filter, setFilter] = useState({
    reference: "",
    start_date: "",
    end_date: "",
  });

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div
          className="btn-group btn-group-segment"
          role="group"
          aria-label="Basic segment styles example"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnGroupSegmentRadio"
            id="btnGroupSegmentRadioOption2"
            autoComplete="off"
            onClick={() => {
              setChecked("settled");
              moengage.track_event("SETTLED_TRANSACTIONS", {
                success_status: true,
                firstName: merchant_data?.firstname,
                lastName: merchant_data?.lastname,
                email: merchant_data?.email,
                mobile: merchant_data?.mobile_number,
                userName: merchant_data?.email,
                uniqueUserId: merchant_data?.email,
                phone_number: merchant_data?.mobile_number,
                terms_agreement: merchant_data?.agreed_terms,
                address: merchant_data?.address,
                business_category: merchant_data?.business_category,
                business_type: merchant_data?.business_type,
                platform: deviceInfo?.userAgent,
              });
            }}
            checked={checked === "settled" ? true : false}
          />
          <label
            className="btn btn-sm"
            htmlFor="btnGroupSegmentRadioOption2"
            style={{ cursor: "pointer" }}
          >
            Settled
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnGroupSegmentRadio"
            id="btnGroupSegmentRadioOption3"
            autoComplete="off"
            onClick={() => {
              setChecked("unsettled");
              moengage.track_event("UNSETTLED_TRANSACTIONS", {
                success_status: true,
                firstName: merchant_data?.firstname,
                lastName: merchant_data?.lastname,
                email: merchant_data?.email,
                mobile: merchant_data?.mobile_number,
                userName: merchant_data?.email,
                uniqueUserId: merchant_data?.email,
                phone_number: merchant_data?.mobile_number,
                terms_agreement: merchant_data?.agreed_terms,
                address: merchant_data?.address,
                business_category: merchant_data?.business_category,
                business_type: merchant_data?.business_type,
                platform: deviceInfo?.userAgent,
              });
            }}
            checked={checked === "unsettled" ? true : false}
          />
          <label
            className="btn btn-sm"
            htmlFor="btnGroupSegmentRadioOption3"
            style={{ cursor: "pointer" }}
          >
            Unsettled
          </label>
        </div>
      </div>

      {checked === "settled" ? (
        <>
          <SettledSm merchant_data={merchant_data} deviceInfo={deviceInfo} />
        </>
      ) : (
        <>
          <UnsettledSm
            merchant_data={merchant_data}
            deviceInfo={deviceInfo}
            dateRange={dates}
          />
        </>
      )}
    </>
  );
}

export default TransactionsTableMobile;
