import React from "react";
import "./auth.css";

function Spinner(): JSX.Element {
  return (
    <div className="d-flex justify-content-center align-items-center loader-container">
      <div className="loadingio-spinner-spinner-zefk90pf4qd">
        <div className="ldio-we0kgaonmr">
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
          <div style={{ background: "#fff" }}></div>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
