function PoolerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9901 6.81351L13.1728 5.95249C12.9838 5.85252 12.7878 5.76568 12.5861 5.69267C12.0404 5.4893 11.4606 5.38541 10.8758 5.38624H5.54199L6.38509 15.0724H9.03823V14.6178H10.8758C11.5023 14.6178 12.1226 14.4982 12.7014 14.2662C13.2802 14.0341 13.806 13.6939 14.2489 13.265C14.6918 12.8362 15.0431 12.327 15.2827 11.7667C15.5223 11.2064 15.6456 10.6059 15.6453 9.99951V9.97953C15.6357 9.04722 15.2678 8.15182 14.6129 7.46803L13.9901 6.81351Z"
        fill="#52A1FF"
      />
      <path
        d="M14.4389 8.77188C14.4392 9.37843 14.3159 9.97907 14.0762 10.5395C13.8365 11.0999 13.4851 11.6091 13.042 12.038C12.5989 12.4669 12.0728 12.807 11.4939 13.0391C10.9149 13.2711 10.2944 13.3904 9.66776 13.3902H7.83188V15.069H4.34082V4.15528H9.67463C10.5106 4.15422 11.332 4.36679 12.0559 4.77148L13.176 5.95062L13.3739 6.16046C14.0515 6.86436 14.4327 7.78814 14.4424 8.75023L14.4389 8.77188Z"
        fill="#1F7AFF"
      />
      <path
        d="M13.0014 7.56973C13.0019 8.79355 12.5003 9.96747 11.6069 10.8335C10.7136 11.6995 9.50148 12.1867 8.23714 12.188H6.39267V15.0692H2.90332V2.95312H8.23714C8.96466 2.95342 9.68242 3.1151 10.3353 3.42575C10.9882 3.73639 11.5589 4.18774 12.0035 4.74514C12.651 5.55583 13.002 6.55169 13.0014 7.5764V7.56973Z"
        fill="#2C1DFF"
      />
    </svg>
  );
}

export default PoolerIcon;
