const MerchantStats = () => {
	return (
		<div className="card card-body mb-3 mb-lg-5">
			<div className="row col-lg-divider gx-lg-6">
				<div className="col-lg-3">
					<div className="d-flex">
						<div className="flex-grow-1">
							<h6 className="card-subtitle mb-3">
								Total collection value
								<i
									className="bi-question-circle text-body ms-1"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
								></i>
							</h6>
							<h3 className="card-title">$7,820.75</h3>
						</div>

						<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
							<i className="bi-shop"></i>
						</span>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="d-flex">
						<div className="flex-grow-1">
							<h6 className="card-subtitle mb-3">
								total collection volume
								<i
									className="bi-question-circle text-body ms-1"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
								></i>
							</h6>
							<h3 className="card-title">$985,937.45</h3>
						</div>

						<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
							<i className="bi-layout-text-window-reverse"></i>
						</span>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="d-flex">
						<div className="flex-grow-1">
							<h6 className="card-subtitle mb-3">
								total transfer volume
								<i
									className="bi-question-circle text-body ms-1"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
								></i>
							</h6>
							<h3 className="card-title">$15,503.00</h3>
						</div>

						<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
							<i className="bi-percent"></i>
						</span>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="d-flex">
						<div className="flex-grow-1">
							<h6 className="card-subtitle mb-3">
								total transfer value
								<i
									className="bi-question-circle text-body ms-1"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
								></i>
							</h6>
							<h3 className="card-title">$3,982.53</h3>
						</div>

						<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
							<i className="bi-bag"></i>
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MerchantStats