import { useEffect, useState } from "react";
import { useSession } from "../../hooks/auth/auth.hook";
import Otp from "../../pages/auth/otp";
import { sessionInterface } from "../../utils/interfaces";
import OtpFields from "../otp/OtpFields";
import SpinnerComponent from "../spinner/Spinner";
import { SuccessMessage } from "./Notice";
import SetPin from "./SetPin";
import "./modal.css";

const PinSetup = ({
  data = [],
  sendOTP,
  resolve_transfer,
  handlePay = () => {},
  setUpPinAction,
  setPin,
  pin,
  showButton = true,
  setShow = () => {},
  show = false,
}: {
  data?: any;
  sendOTP: () => void;
  resolve_transfer: any;
  handlePay?: (data: any) => void;
  setUpPinAction: () => void;
  setPin: (data: any) => void;
  pin: string;
  showButton?: boolean;
  setShow?: (data: any) => void;
  show?: boolean;
}) => {
  const [stage, setStage] = useState<string>("pin");
  const session = useSession();
  const merchant_data: sessionInterface = session?.data?.data?.data;

  useEffect(() => {
    session.refetch();
  }, [stage]);

  return (
    <>
      {show ? (
        <>
          {stage === "success" && (
            <SuccessMessage
              handleStage={() => {
                setShow(false);
                setStage("pin");
              }}
              text="Payout"
            />
          )}
          {stage === "pin" && <SetPin handleStage={setStage} />}
        </>
      ) : (
        <div className="d-flex flex-column align-items-center">
          {merchant_data?.state?.transaction_pin_set ? (
            resolve_transfer?.isLoading ? (
              <SpinnerComponent />
            ) : (
              <>
                <div className="d-flex pt-4 flex-column align-items-center">
                  <p className="card-subtitle">
                    Enter transaction pin to confirm
                  </p>
                  <OtpFields
                    numInputs={4}
                    secret
                    value={pin}
                    onOtpInput={(value: string) => {
                      setPin(value);
                    }}
                    inputMode="number"
                    style={{ padding: "10px", width: "100%" }}
                    inputStyles={{
                      borderColor: "#CBD1EC",
                      padding: "0.6125rem 1rem",
                      margin: "0px 6px",
                    }}
                    readOnly={false}
                    serectDelay={2800}
                  />
                </div>
                {showButton && (
                  <>
                    <br />
                    <br />
                    <br />
                    <button
                      onClick={async () => {
                        await handlePay(data[0]);
                        setPin("");
                      }}
                      disabled={pin.length !== 4}
                      className="btn btn-primary btn-block w-100 --bor-pri my-2"
                    >
                      Continue
                    </button>
                  </>
                )}
              </>
            )
          ) : (
            <>
              <button
                onClick={() => {
                  if (setUpPinAction) {
                    setUpPinAction();
                  } else {
                    setStage("pin");
                  }
                  sendOTP();
                }}
                className="btn btn-primary btn-block w-100 --bor-pri my-2"
              >
                Set up PIN
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PinSetup;
