import { Route, Routes, Navigate } from "react-router-dom";
import { CASHIER_STRING, PATH_NAMES } from "../../constants/index.constant";
import CashierDashboard from "../../pages/cashier/CashierDashboard";
import Layout from "../../component/layout/Layout";

const CashierRoute = () => {
  const token = window.localStorage.getItem(CASHIER_STRING);

  if (token === null || token === undefined) {
    return <Navigate to={`/auth/cashier${PATH_NAMES.signin}`} />;
  }

  return (
    <Layout>
      <Routes>
        <Route path={`dashboard`} element={<CashierDashboard />} />
      </Routes>
    </Layout>
  );
};

export default CashierRoute;
