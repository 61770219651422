import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

type Props = {
  onOtpInput: any;
  readOnly: boolean;
  numInputs?: 4 | 6;
  secret?: boolean;
  inputStyles?: React.CSSProperties;
  inputMode?: string;
  style?: React.CSSProperties;
  error?: boolean;
  value?: any;
  serectDelay?: number;
};

function OtpFields({
  onOtpInput,
  readOnly,
  numInputs = 6,
  secret = false,
  inputStyles,
  style,
  error = false,
  inputMode,
  value,
  serectDelay = 3000
}: Props): JSX.Element {
  const [otp, setOtp] = useState<string>("");
  const [secretType, setSecretType] = useState<string>("text");
  useEffect(() => {
    if(secret) {
      setTimeout(() => {
        setSecretType("password");
      }, serectDelay);
    }
  })
  return (
    <>
      <OtpInput
        onChange={(otp) => {
          onOtpInput(otp, otp.length - 1);
          setOtp(otp);
        }}
        value={typeof value === "undefined" ? otp : value}
        inputStyle="form-control form-control-single-number spin-button-one border border-secondary"
        numInputs={numInputs}
        renderSeparator={false}
        containerStyle={style}
        renderInput={(props) => {
          return (
            <div className="col">
              <div className="mb-4 d-flex justify-content-center">
                <input
                  {...props}
                  className={`form-control form-control-single-number spin-button-one border ${error ? "border-danger" : "border-secondary"}`}
                  style={{ ...inputStyles, width: "3rem" }}
                  readOnly={readOnly}
                  type={secret ? secretType : inputMode || props.type}
                  onFocus={props.onFocus}
                  onBlur={props.onBlur}
                  disabled={readOnly}
                  autoComplete="off"
                />
              </div>
            </div>
          );
        }}
        inputType="tel"
        shouldAutoFocus
      />
    </>
  );
}

export default OtpFields;
