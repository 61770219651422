import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Dropzone from "../../../component/drag-and-drop/Dropzone";
import { splitAwsString } from "../../../helpers/index.helper";
import { IDENTITY } from "../../../utils/data";
import { formprops } from "../../../utils/interfaces";

function ValidIdUnregisteredFields({
  formValues,
  handleChange,
  data,
  isDisabled,
  setFormData,
}: formprops): JSX.Element {
  const [select, setSelect] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [imageURL, setImageUrl] = useState("");
  const [issueDate, setIssueDate] = useState<any>("");
  const [expiryDate, setExpiryDate] = useState<any>("");
  const [passportIssue, setPassportIssue] = useState("");
  const [passportExpire, setPassportExpire] = useState("");

  useEffect(() => {
    if (
      data !== undefined &&
      data?.valid_means_of_identification === "driver_license"
    ) {
      setSelect("Drivers license");
      setImageUrl(data?.driver_license?.upload);
      setIssueDate(data?.driver_license?.issue_date);
      setExpiryDate(data?.driver_license?.expiry_date);
      setOpen(true);
    } else if (
      data !== undefined &&
      data?.valid_means_of_identification === "nin"
    ) {
      setSelect("National Identity card");
      setImageUrl(data?.nin?.upload);
      setOpen(true);
    } else if (
      data !== undefined &&
      data?.valid_means_of_identification === "passport"
    ) {
      setSelect("International Passport");
      setImageUrl(data?.passport?.upload);
      setPassportIssue(data?.passport?.issue_date);
      setPassportExpire(data?.passport?.expiry_date);
      setOpen(true);
    } else if (
      data !== undefined &&
      data?.valid_means_of_identification === "voter_card"
    ) {
      setSelect("Voter's card");
      setImageUrl(data?.voter_card?.upload);
      setOpen(true);
    }
  }, [data]);

  return (
    <div
      className="w-100 w-md-75 w-lg-75 content-space-t-0 content-space-t-lg-1 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      {/* 1 */}
      <div className="row mb-4">
        <div className="col">
          <label className="form-label">Valid means of ID*</label>
          {data ? (
            <select
              className={`form-select form-control  ${
                data?.valid_means_of_identification &&
                data?.valid_means_of_identification?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                formValues?.valid_means_of_identification &&
                formValues?.valid_means_of_identification?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-seocondary"
              } ${
                formValues?.valid_means_of_identification &&
                data?.valid_means_of_identification &&
                isDisabled
                  ? "border border-secondary"
                  : "border border-seocondary"
              } `}
              id="valid_means_of_identification"
              name="valid_means_of_identification"
              onChange={(e) => {
                handleChange(e);
                if (e.target.value === "nin") {
                  setSelect("National Identity card");
                  setOpen(true);
                } else if (e.target.value === "passport") {
                  setSelect("International Passport");
                  setOpen(true);
                } else if (e.target.value === "driver_license") {
                  setSelect("Drivers license");
                  setOpen(true);
                } else if (e.target.value === "voter_card") {
                  setSelect("Voter's card");
                  setOpen(true);
                } else {
                  setSelect("");
                  setOpen(false);
                }
              }}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              defaultValue={formValues?.valid_means_of_identification}
              value={formValues?.valid_means_of_identification}
              disabled={isDisabled}
            >
              <option value="">Select identification</option>
              {IDENTITY.map((identification) => {
                return (
                  <option
                    value={identification.value}
                    key={identification.value}
                  >
                    {identification.label}
                  </option>
                );
              })}
            </select>
          ) : (
            <select
              className={`form-select form-control ${
                data?.valid_means_of_identification &&
                data?.valid_means_of_identification?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                formValues?.valid_means_of_identification &&
                formValues?.valid_means_of_identification?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-seocondary"
              } ${
                formValues?.valid_means_of_identification &&
                data?.valid_means_of_identification?.length > 0 &&
                isDisabled
                  ? "border border-secondary"
                  : "border border-seocondary"
              } `}
              id="valid_means_of_identification"
              name="valid_means_of_identification"
              onChange={(e) => {
                handleChange(e);
                if (e.target.value === "nin") {
                  setSelect("National Identity card");
                  setOpen(true);
                } else if (e.target.value === "passport") {
                  setSelect("International Passport");
                  setOpen(true);
                } else if (e.target.value === "driver_license") {
                  setSelect("Drivers license");
                  setOpen(true);
                } else if (e.target.value === "voter_card") {
                  setSelect("Voter's card");
                  setOpen(true);
                } else {
                  setSelect("");
                  setOpen(false);
                }
              }}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              defaultValue={formValues?.valid_means_of_identification}
              // value={formValues?.valid_means_of_identification}
              disabled={isDisabled}
            >
              <option value="">Select identification</option>
              {IDENTITY.map((identification) => {
                return (
                  <option
                    value={identification?.value}
                    key={identification?.value}
                  >
                    {identification?.label}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      </div>

      {/* 2 */}

      {select === "National Identity card" ? (
        <Collapse in={open}>
          <div id="example-collapse-text">
            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="idno">
                  {select === "National Identity card"
                    ? "NIN Number*"
                    : select === "Drivers license"
                    ? "License Number*"
                    : ""}
                </label>
                {data ? (
                  <input
                    type="text"
                    className={`form-control  ${
                      formValues?.driver_license?.number &&
                      formValues?.driver_license?.number?.length > 0
                        ? "border border-secondary "
                        : "border border-secondary"
                    } ${
                      formValues?.nin?.nin_number &&
                      formValues?.nin?.nin_number?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    }  ${
                      data?.driver_license?.number &&
                      data?.driver_license?.number?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    }  ${
                      formValues?.nin?.nin_number &&
                      data?.nin?.nin_number &&
                      data?.nin?.nin_number?.length > 0 &&
                      isDisabled
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      formValues?.voter_card?.voter_card_number &&
                      data?.voter_card?.voter_card_number &&
                      data?.voter_card?.voter_card_number?.length > 0 &&
                      isDisabled
                        ? "border border-secondary"
                        : "border border-secondary"
                    } `}
                    name={
                      select === "National Identity card"
                        ? "nin_number"
                        : "number"
                    }
                    id={
                      select === "National Identity card"
                        ? "nin_number"
                        : "number"
                    }
                    tabIndex={1}
                    placeholder={
                      select === "National Identity card"
                        ? "NIN Number"
                        : select === "Drivers license"
                        ? "License Number"
                        : select === "Voter's card"
                        ? "Voter Identification Number"
                        : ""
                    }
                    aria-label="idno"
                    onChange={(e) => handleChange(e)}
                    value={
                      select === "National Identity card"
                        ? formValues?.nin?.nin_number
                        : formValues?.driver_license?.number
                    }
                    defaultValue={
                      data?.driver_license?.number || data?.nin?.nin_number
                    }
                    required
                    readOnly={isDisabled}
                  />
                ) : (
                  <input
                    type="text"
                    className={`form-control ${
                      formValues?.nin?.nin_number === "" ||
                      data?.nin?.nin_number === "" ||
                      formValues?.nin?.nin_number === undefined ||
                      data?.nin?.nin_number === undefined ||
                      formValues?.driver_license?.number === "" ||
                      formValues?.driver_license?.number === undefined ||
                      data?.driver_license?.number === "" ||
                      data?.driver_license?.number === undefined
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      formValues?.driver_license?.number &&
                      formValues?.driver_license?.number?.length > 0
                        ? "border border-secondary "
                        : "border border-secondary"
                    } ${
                      formValues?.nin?.nin_number &&
                      formValues?.nin?.nin_number?.length > 0
                        ? "border border-secondary "
                        : "border border-secondary"
                    }  ${
                      data?.driver_license?.number &&
                      data?.driver_license?.number?.length > 0
                        ? "border border-secondary "
                        : "border border-secondary"
                    }  ${
                      data?.nin?.nin_number && data?.nin?.nin_number?.length > 0
                        ? "border border-secondary "
                        : "border border-secondary"
                    }  ${
                      formValues?.voter_card?.voter_card_number &&
                      data?.voter_card?.voter_card_number &&
                      data?.voter_card?.voter_card_number?.length > 0 &&
                      isDisabled
                        ? "border border-secondary"
                        : "border border-secondary"
                    }`}
                    name={
                      select === "National Identity card"
                        ? "nin_number"
                        : "number"
                    }
                    id={
                      select === "National Identity card"
                        ? "nin_number"
                        : "number"
                    }
                    tabIndex={1}
                    placeholder={
                      select === "National Identity card"
                        ? "NIN Number"
                        : select === "Drivers license"
                        ? "License Number"
                        : select === "Voter's card"
                        ? "Voter Identification Number"
                        : ""
                    }
                    aria-label="idno"
                    onChange={(e) => handleChange(e)}
                    value={
                      select === "National Identity card"
                        ? formValues?.nin?.nin_number
                        : formValues?.driver_license?.number
                    }
                    defaultValue={
                      data?.driver_license?.number || data?.nin?.nin_number
                    }
                    required
                    readOnly={isDisabled}
                  />
                )}
              </div>
            </div>
          </div>
        </Collapse>
      ) : (
        ""
      )}

      {select === "Drivers license" && (
        <Collapse in={open}>
          <div id="example-collapse-text">
            <div className="row mb-2 mb-md-4 mb-lg-4">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                <label className="form-label" htmlFor="idno">
                  License Number*
                </label>
                {data ? (
                  <input
                    type="text"
                    className={`form-control ${
                      formValues?.driver_license?.number &&
                      formValues?.driver_license?.number?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    }  ${
                      data?.driver_license?.number &&
                      data?.driver_license?.number?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    }${
                      data?.driver_license?.number &&
                      formValues?.driver_license?.number &&
                      isDisabled
                        ? "border border-secondary"
                        : "border border-secondary"
                    } `}
                    name={"number"}
                    id={"number"}
                    tabIndex={1}
                    aria-label="idno"
                    onChange={(e) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        driver_license: {
                          ...prevData["driver_license"],
                          number: e.target.value,
                        },
                      }));
                    }}
                    defaultValue={data?.driver_license?.number}
                    required
                    readOnly={isDisabled}
                  />
                ) : (
                  <input
                    type="text"
                    className={`form-control ${
                      formValues?.driver_license?.number &&
                      formValues?.driver_license?.number?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    }  ${
                      data?.driver_license?.number &&
                      data?.driver_license?.number?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    } ${
                      data?.driver_license?.number &&
                      formValues?.driver_license?.number &&
                      isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    } `}
                    name={"number"}
                    id={"number"}
                    tabIndex={1}
                    aria-label="idno"
                    onChange={(e) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        driver_license: {
                          ...prevData["driver_license"],
                          number: e.target.value,
                        },
                      }));
                    }}
                    defaultValue={data?.driver_license?.number}
                    required
                    readOnly={isDisabled}
                  />
                )}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                <label className="form-label" htmlFor="issue_date">
                  Issued date*
                </label>
                {data ? (
                  <input
                    type="date"
                    name="issue_date"
                    id="issue_date"
                    defaultValue={data?.driver_license?.issue_date}
                    onChange={(e) => {
                      setIssueDate(e.target.value);
                      setFormData((prevData: any) => ({
                        ...prevData,
                        driver_license: {
                          ...prevData["driver_license"],
                          issue_date: e.target.value,
                        },
                      }));
                    }}
                    className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                      formValues?.driver_license?.issue_date &&
                      formValues?.driver_license?.issue_date?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : ""
                    } ${
                      data?.driver_license?.issue_date &&
                      data?.driver_license?.issue_date?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : ""
                    } ${
                      formValues?.driver_license?.expiry_date?.length > 0 &&
                      formValues?.driver_license?.expiry_date <=
                        formValues?.driver_license?.issue_date &&
                      !isDisabled
                        ? "border border-danger "
                        : "border border-secondary"
                    } ${
                      formValues?.driver_license?.issue_date &&
                      data?.driver_license?.issue_date &&
                      isDisabled
                        ? "border border-secondary"
                        : "border border-secondary"
                    }`}
                    readOnly={isDisabled}
                  />
                ) : (
                  <input
                    type="date"
                    name="issue_date"
                    id="issue_date"
                    defaultValue={data?.driver_license?.issue_date}
                    onChange={(e) => {
                      setIssueDate(e.target.value);
                      setFormData((prevData: any) => ({
                        ...prevData,
                        driver_license: {
                          ...prevData["driver_license"],
                          issue_date: e.target.value,
                        },
                      }));
                    }}
                    className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                      formValues?.driver_license?.issue_date &&
                      formValues?.driver_license?.issue_date?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : ""
                    } ${
                      data?.driver_license?.issue_date &&
                      data?.driver_license?.issue_date?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : ""
                    } ${
                      formValues?.driver_license?.expiry_date?.length > 0 &&
                      formValues?.driver_license?.expiry_date <=
                        formValues?.driver_license?.issue_date &&
                      !isDisabled
                        ? "border border-danger "
                        : "border border-secondary"
                    } ${
                      formValues?.driver_license?.issue_date &&
                      data?.driver_license?.issue_date &&
                      isDisabled
                        ? "border border-secondary"
                        : "border border-secondary"
                    }`}
                    readOnly={isDisabled}
                  />
                )}
              </div>
            </div>

            <div className="row mb-3 mb-md-4 mb-lg-4">
              <div className="col">
                <label className="form-label" htmlFor="expiry_date">
                  Expiry date *
                </label>
                <input
                  type="date"
                  name="expiry_date"
                  id="expiry_date"
                  defaultValue={data?.driver_license?.expiry_date}
                  onChange={(e) => {
                    setExpiryDate(e.target.value);
                    setFormData((prevData: any) => ({
                      ...prevData,
                      driver_license: {
                        ...prevData["driver_license"],
                        expiry_date: e.target.value,
                      },
                    }));
                    // handleChange(e);
                  }}
                  className={`js-daterangepicker form-control daterangepicker-custom-input ${
                    formValues?.driver_license?.expiry_date &&
                    formValues?.driver_license?.expiry_date?.length > 0 &&
                    !isDisabled
                      ? "border border-secondary "
                      : "border border-secondary"
                  } ${
                    data?.driver_license?.expiry_date &&
                    data?.driver_license?.expiry_date?.length > 0 &&
                    !isDisabled
                      ? "border border-secondary "
                      : "border border-secondary"
                  } ${
                    formValues?.driver_license?.expiry_date?.length > 0 &&
                    formValues?.driver_license?.expiry_date <=
                      formValues?.driver_license?.issue_date &&
                    !isDisabled
                      ? "border border-danger "
                      : "border border-secondary"
                  } ${
                    formValues?.driver_license?.expiry_date &&
                    data?.driver_license?.expiry_date &&
                    isDisabled
                      ? "border border-secondary"
                      : "border border-secondary"
                  }`}
                  readOnly={isDisabled}
                />
                <span className="form-text invalid-feedback">
                  Expiry date should not be earlier than or the same as issue
                  date.
                </span>
              </div>
            </div>
          </div>
        </Collapse>
      )}

      {select === "International Passport" && (
        <>
          <Collapse in={open}>
            <div id="example-collapse-text">
              <div className="row mb-3 mb-md-4 mb-lg-4">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-2 mb-md-3 mb-lg-0">
                  <label className="form-label" htmlFor="idno">
                    Passport number*
                  </label>
                  {data ? (
                    <input
                      type="text"
                      className={`form-control ${
                        formValues?.passport?.passport_number &&
                        formValues?.passport?.passport_number?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        data?.passport?.passport_number &&
                        data?.passport?.passport_number?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        data?.passport?.passport_number &&
                        formValues?.passport?.passport_number &&
                        isDisabled
                          ? "border border-secondary"
                          : "border border-secondary"
                      }`}
                      name="passport_number"
                      id="passport_number"
                      tabIndex={1}
                      placeholder=""
                      aria-label="passport_number"
                      onChange={(e) => {
                        setFormData((prevData: any) => ({
                          ...prevData,
                          passport: {
                            ...prevData["passport"],
                            passport_number: e.target.value,
                          },
                        }));
                        // handleChange(e);
                      }}
                      defaultValue={data?.passport?.passport_number}
                      required
                      readOnly={isDisabled}
                    />
                  ) : (
                    <input
                      type="text"
                      className={`form-control ${
                        formValues?.passport?.passport_number &&
                        formValues?.passport?.passport_number?.length > 0
                          ? "border border-secondary "
                          : ""
                      } ${
                        data?.passport?.passport_number &&
                        data?.passport?.passport_number?.length > 0
                          ? "border border-secondary "
                          : ""
                      }`}
                      name="passport_number"
                      id="passport_number"
                      tabIndex={1}
                      placeholder=""
                      aria-label="passport_number"
                      onChange={(e) => {
                        setFormData((prevData: any) => ({
                          ...prevData,
                          passport: {
                            ...prevData["passport"],
                            passport_number: e.target.value,
                          },
                        }));
                        // handleChange(e);
                      }}
                      defaultValue={data?.passport?.passport_number}
                      required
                      readOnly={isDisabled}
                    />
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-2 mb-md-3 mb-lg-0">
                  <label className="form-label" htmlFor="issue_date">
                    Issued date*
                  </label>
                  <input
                    type="date"
                    name="issue_date"
                    id="issue_date"
                    defaultValue={data?.passport?.issue_date}
                    onChange={(e) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        passport: {
                          ...prevData["passport"],
                          issue_date: e.target.value,
                        },
                      }));
                    }}
                    className={`js-daterangepicker form-control daterangepicker-custom-input ${
                      formValues?.passport?.issue_date &&
                      formValues?.passport?.issue_date?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    } ${
                      data?.passport?.issue_date &&
                      data?.passport?.issue_date?.length > 0 &&
                      !isDisabled
                        ? "border border-secondary "
                        : "border border-secondary"
                    } ${
                      formValues?.passport?.expiry_date?.length > 0 &&
                      formValues?.passport?.expiry_date <=
                        formValues?.passport?.issue_date &&
                      !isDisabled
                        ? "border border-danger "
                        : "border border-secondary"
                    } ${
                      data?.passport?.issue_date &&
                      formValues?.passport?.issue_date &&
                      isDisabled
                        ? "border border-secondary"
                        : "border border-secondary"
                    } `}
                    readOnly={isDisabled}
                  />
                </div>
              </div>

              <div className="row mb-2 mb-md-4 mb-lg-4">
                <div className="col">
                  <label className="form-label" htmlFor="expiry_date">
                    Expiry date *
                  </label>
                  {data ? (
                    <input
                      type="date"
                      name="expiry_date"
                      id="expiry_date"
                      defaultValue={data?.passport?.expiry_date}
                      onChange={(e) => {
                        setFormData((prevData: any) => ({
                          ...prevData,
                          passport: {
                            ...prevData["passport"],
                            expiry_date: e.target.value,
                          },
                        }));
                      }}
                      className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                        formValues?.passport?.expiry_date &&
                        formValues?.passport?.expiry_date?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        data?.passport?.expiry_date &&
                        data?.passport?.expiry_date?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        formValues?.passport?.expiry_date?.length > 0 &&
                        formValues?.passport?.expiry_date <=
                          formValues?.passport?.issue_date &&
                        !isDisabled
                          ? "border border-danger "
                          : "border border-secondary"
                      }  ${
                        formValues?.passport?.expiry_date &&
                        data?.passport?.expiry_date &&
                        isDisabled
                          ? "border border-secondary"
                          : "border border-secondary"
                      }`}
                      readOnly={isDisabled}
                    />
                  ) : (
                    <input
                      type="date"
                      name="expiry_date"
                      id="expiry_date"
                      defaultValue={data?.passport?.expiry_date}
                      onChange={(e) => {
                        setFormData((prevData: any) => ({
                          ...prevData,
                          passport: {
                            ...prevData["passport"],
                            expiry_date: e.target.value,
                          },
                        }));
                      }}
                      className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                        formValues?.passport?.expiry_date &&
                        formValues?.passport?.expiry_date?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        data?.passport?.expiry_date &&
                        data?.passport?.expiry_date?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        formValues?.passport?.expiry_date?.length > 0 &&
                        formValues?.passport?.expiry_date <=
                          formValues?.passport?.issue_date &&
                        !isDisabled
                          ? "border border-danger "
                          : "border border-secondary"
                      } ${
                        formValues?.passport?.expiry_date &&
                        data?.passport?.expiry_date &&
                        isDisabled
                          ? "border border-secondary"
                          : "border border-secondary"
                      }`}
                      readOnly={isDisabled}
                    />
                  )}
                  <span className="form-text invalid-feedback">
                    Expiry date should not be earlier than or the same as issue
                    date.
                  </span>
                </div>
              </div>
            </div>
          </Collapse>
        </>
      )}

      {select === "Voter's card" && (
        <>
          <Collapse in={open}>
            <div id="example-collapse-text">
              <div className="row mb-4">
                <div className="col">
                  <label className="form-label" htmlFor="idno">
                    Voter Identification Number (VIN)*
                  </label>
                  {data ? (
                    <input
                      type="text"
                      className={`form-control ${
                        formValues?.voter_card?.voter_card_number &&
                        formValues?.voter_card?.voter_card_number?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        data?.voter_card?.voter_card_number &&
                        data?.voter_card?.voter_card_number?.length > 0 &&
                        !isDisabled
                          ? "border border-secondary "
                          : "border border-secondary"
                      } ${
                        data?.voter_card?.voter_card_number &&
                        formValues?.voter_card?.voter_card_number &&
                        isDisabled
                          ? "border border-secondary"
                          : "border border-secondary"
                      } border border-secondary`}
                      name="voter_card_number"
                      id="voter_card_number"
                      tabIndex={1}
                      placeholder=""
                      aria-label="voter_card_number"
                      onChange={(e) => {
                        setFormData((prevData: any) => ({
                          ...prevData,
                          voter_card: {
                            ...prevData["voter_card"],
                            voter_card_number: e.target.value,
                          },
                        }));
                        // handleChange(e);
                      }}
                      defaultValue={data?.voter_card?.voter_card_number}
                      required
                      readOnly={isDisabled}
                    />
                  ) : (
                    <input
                      type="text"
                      className={`form-control ${
                        formValues?.voter_card?.voter_card_number &&
                        formValues?.voter_card?.voter_card_number?.length > 0
                          ? "border border-secondary "
                          : ""
                      } ${
                        data?.voter_card?.voter_card_number &&
                        data?.voter_card?.voter_card_number?.length > 0
                          ? "border border-secondary "
                          : ""
                      } border border-secondary`}
                      name="voter_card_number"
                      id="voter_card_number"
                      tabIndex={1}
                      placeholder=""
                      aria-label="voter_card_number"
                      onChange={(e) => {
                        setFormData((prevData: any) => ({
                          ...prevData,
                          voter_card: {
                            ...prevData["voter_card"],
                            voter_card_number: e.target.value,
                          },
                        }));
                        // handleChange(e);
                      }}
                      defaultValue={data?.voter_card?.voter_card_number}
                      required
                      readOnly={isDisabled}
                    />
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </>
      )}

      {select === "Drivers license" && (
        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="firstname">
              {isDisabled === true ? select : `Upload ${select}*`}
            </label>
            {data ? (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      driver_license: {
                        ...prevData["driver_license"],
                        upload: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.driver_license?.upload)}
              />
            ) : (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      driver_license: {
                        ...prevData["driver_license"],
                        upload: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.driver_license?.upload)}
              />
            )}
          </div>
        </div>
      )}

      {select === "National Identity card" && (
        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="firstname">
              {isDisabled === true ? select : `Upload ${select}*`}
            </label>
            {data ? (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      nin: {
                        ...prevData["nin"],
                        upload: url,
                        nin_image: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.nin?.upload)}
              />
            ) : (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      nin: {
                        ...prevData["nin"],
                        upload: url,
                        nin_image: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.nin?.upload)}
              />
            )}
          </div>
        </div>
      )}

      {select === "International Passport" && (
        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="">
              {isDisabled === true ? select : `Upload ${select}*`}
            </label>
            {data ? (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      passport: {
                        ...prevData["passport"],
                        upload: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.passport?.upload)}
              />
            ) : (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      passport: {
                        ...prevData["passport"],
                        upload: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.passport?.upload)}
              />
            )}
          </div>
        </div>
      )}

      {select === "Voter's card" && (
        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="">
              {isDisabled === true ? select : `Upload ${select}*`}
            </label>
            {data ? (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      voter_card: {
                        ...prevData["voter_card"],
                        upload: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.voter_card?.upload)}
              />
            ) : (
              <Dropzone
                onFileExport={async (url: any) => {
                  if (url) {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      voter_card: {
                        ...prevData["voter_card"],
                        upload: url,
                      },
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(data?.voter_card?.upload)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ValidIdUnregisteredFields;
