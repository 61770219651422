import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PATH_NAMES, SUPER_ADMIN_STRING } from "../../constants/index.constant";
import AdminLogin from "../../pages/admin/auth/admin_signin";

export default function SuperAdminAuthRoutes() {
  const token = window.localStorage.getItem(SUPER_ADMIN_STRING);

  if (token) {
    return <Navigate to={`/admin`} replace={true} />;
  }

  return (
    <Routes>
      <Route path={PATH_NAMES.signin} element={<AdminLogin />} />
    </Routes>
  );
}
