import { useMutation, useQuery } from "react-query";
import { initQueryOptions, parseResponse } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import {
  CreateCashier,
  EditCashier,
  ICreateCashier,
  ISetupCashier,
} from "../../utils/interfaces";

export const useCashiers = (page?: number, query?: string) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["cashiers", page, query],
      () => API_SERVICE._getAllCashiers(page, query),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useCashiersStats = () => {
  const { data, error, isLoading, isSuccess, isError, refetch } = useQuery(
    ["cashiersStats"],
    () => API_SERVICE._getCashiersStats(),
    initQueryOptions()
  );
  return { data, error, isError, isLoading, isSuccess, refetch };
};

export const useCashier = (account_number?: string | any) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["cashiers", "cashier", account_number],
      () => API_SERVICE._getSingleCashier(account_number),
      initQueryOptions({
        enabled: !!account_number,
      })
    );
  const cashierRefetch = refetch;
  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
    isFetching,
    cashierRefetch,
  };
};

export const useCreateCashier = () => {
  const createHandler = useMutation(
    (cashier: ICreateCashier) => API_SERVICE._createCashier(cashier),
    parseResponse()
  );
  return { createHandler };
};

export const useEditCashier = () => {
  const updateHandler = useMutation(
    (data: { cashier: EditCashier; cashier_id: string | any }) =>
      API_SERVICE._editCashier(data.cashier, data.cashier_id),
    parseResponse()
  );
  return { updateHandler };
};

export const useCreateNewCashier = () => {
  const createHandler = useMutation(
    (cashier: CreateCashier) => API_SERVICE._createNewCashier(cashier),
    parseResponse()
  );
  return { createHandler };
};

export const useManageCashier = () => {
  const manageHandler = useMutation(
    (cashier: { id: string | any; action: string }) =>
      API_SERVICE._manageCashier(cashier.id, cashier.action),
    parseResponse()
  );
  return { manageHandler };
};

export const useSetupCashier = () => {
  const setupCashier = useMutation(
    (cashier: ISetupCashier) => API_SERVICE._setupCashier(cashier),
    parseResponse()
  );
  return { setupCashier };
};

export const useCashierStats = (
  account_number?: string | any,
  dateRange?: { start_date: string; end_date: string } | any
) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["cashier-statistics", account_number, dateRange],
      () => API_SERVICE._getCashierStats(account_number, dateRange),
      initQueryOptions({
        enabled: !!account_number,
      })
    );
  const stats = data;
  const statsLoading = isLoading;
  const statsFetching = isFetching;
  const statsRefetch = refetch;
  return {
    data,
    error,
    isError,
    statsLoading,
    isSuccess,
    refetch,
    stats,
    statsFetching,
    statsRefetch,
  };
};

export const useCashiersSettlement = (
  page?: number,
  cashier_id?: string,
  query?: string,
  dateRange?: { start_date: string; end_date: string },
  download?: boolean
) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["cashier-settlements", page, cashier_id, query, dateRange, download],
      () =>
        API_SERVICE._getCashierSettlements(
          page,
          cashier_id,
          query,
          dateRange,
          download
        ),
      initQueryOptions()
    );
  const settlement = data;
  const settlementLoading = isLoading;
  const settlementFetching = isFetching;
  const settlementRefetch = refetch;
  return {
    data,
    error,
    isError,
    settlement,
    settlementLoading,
    isSuccess,
    settlementFetching,
    settlementRefetch,
  };
};

export const useCashiersCollection = (
  page?: number,
  accountNumber?: number,
  query?: string,
  dateRange?: { start_date: string; end_date: string },
  download?: boolean
) => {
  const { data, error, isLoading, isFetching, isSuccess, isError, refetch } =
    useQuery(
      ["cashier-collections", page, accountNumber, query, dateRange, download],
      () =>
        API_SERVICE._getCashierCollections(
          page,
          accountNumber,
          query,
          dateRange,
          download
        ),
      initQueryOptions()
    );
  const collectionData = data;
  const collectionLoading = isLoading;
  const collectionFetching = isFetching;
  const collectionRefetch = refetch;
  return {
    data,
    error,
    isError,
    collectionData,
    collectionLoading,
    collectionFetching,
    isSuccess,
    collectionRefetch,
  };
};

export const useCashierPayback = () => {
  const paybackHandler = useMutation(
    (data: { amount: string; account_no: string }) =>
      API_SERVICE._cashierPayback({
        amount: data.amount,
        account_no: data.account_no,
      }),
    parseResponse()
  );
  return { paybackHandler };
};
