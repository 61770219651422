import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { mode } from "../../helpers/index.helper";
import { useSupportedBanks } from "../../hooks/auth/auth.hook";
import { BUISNESS_CAT, BUISNESS_TYPE } from "../../utils/data";
import { formprops } from "../../utils/interfaces";
import ScrollableModal from "../modal/ScrollableModal";

function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="#8F9BB2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        clipPath="url(#clip0_7471_61060)"
      >
        <path d="M7.667 1a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.333zM7.667 10.334V7.667M7.667 5.002h-.008"></path>
      </g>
      <defs>
        <clipPath id="clip0_7471_61060">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="rotate(-180 8 8)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default function BuisnessInfo({
  formData,
  handleChange,
  error,
  systemCheck,
}: formprops) {
  const { supported_banks = [] } = useSupportedBanks();
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {
        <style>
          {`
          .tooltip-inner {
            background-color: white !important;
            color: var(--bs-tooltip-bg);
            box-shadow: 14.62px 21.93px 27.41px 0px #A3A3A317;
          }
          .bs-tooltip-top .tooltip-arrow::before {
            border-top-color: var(--bs-tooltip-color);
          }
          `}
        </style>
      }
      The chosen bank will be used to create your business account and all your
      transactions will be processed through this bank.
    </Tooltip>
  );
  return (
    <>
      <ScrollableModal />
      <div className="px-4 px-lg-0 overflow-hidden">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="mb-4 overflow-hidden">
              <label className="form-label" htmlFor="merchant_name">
                Business Name*
              </label>
              <input
                type="text"
                className={`form-control form-control-lg ${
                  error.merchant_name
                    ? "border border-danger"
                    : "border border-secondary"
                } ${
                  !error.merchant_name && formData?.merchant_name !== ""
                    ? "border border-secondary"
                    : "border border-secondary"
                } ${
                  systemCheck?.merchant_name === true
                    ? "border border-danger"
                    : "border border-secondary"
                }  input-fix`}
                name="merchant_name"
                id="merchant_name"
                tabIndex={1}
                value={formData?.merchant_name}
                placeholder="Business Name"
                aria-label="business name"
                onChange={handleChange}
                required
                style={{
                  whiteSpace: "normal",
                  boxSizing: "border-box",
                  overflow: "hidden !important",
                }}
              />
              <span className="invalid-feedback">
                {systemCheck?.merchant_name === true
                  ? "Business With This Name Already Exist"
                  : "Please enter a business name."}
              </span>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="mb-4 overflow-hidden">
              <label className="form-label" htmlFor="business_type">
                Business Category*
              </label>
              <select
                className={`form-select form-control-lg ${
                  error.business_type
                    ? "border border-danger"
                    : "border border-secondary"
                } ${
                  formData?.business_type
                    ? "border border-secondary"
                    : "border border-secondary"
                }`}
                id="validationValidSelect1"
                value={formData?.business_type}
                name="business_type"
                onChange={handleChange}
                defaultValue={formData?.business_type}
              >
                <option>Select</option>
                {BUISNESS_TYPE.map((i: string) => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                })}
              </select>
              <span className="invalid-feedback">
                Please select a business type.
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="mb-4 overflow-hidden">
              <label className="form-label" htmlFor="business_type">
                Business Type*
              </label>
              <select
                className={`form-select form-control-lg ${
                  error.business_category
                    ? "border border-danger"
                    : "border border-secondary"
                } ${
                  formData?.business_category
                    ? " border border-secondary"
                    : "border border-secondary"
                }`}
                id="validationValidSelect1"
                value={formData?.business_category}
                name="business_category"
                onChange={handleChange}
                defaultValue={formData?.business_category}
              >
                <option>Select</option>
                {BUISNESS_CAT.map((i: string) => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                })}
              </select>
              <span className="invalid-feedback">
                Please select a business category.
              </span>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="mb-4 overflow-hidden">
              <label className="form-label" htmlFor="signinSrEmail">
                Business Website
              </label>
              <input
                type="text"
                className={`form-control form-control-lg ${
                  error.merchant_website
                    ? " border border-danger"
                    : "border border-secondary"
                } ${
                  formData?.merchant_website !== ""
                    ? " border border-secondary"
                    : "border border-secondary"
                } input-fix`}
                name="merchant_website"
                id="merchant_website"
                tabIndex={1}
                value={formData?.merchant_website}
                placeholder="https://xyz.com"
                aria-label="merchant_website"
                onChange={handleChange}
                style={{
                  whiteSpace: "normal",
                  boxSizing: "border-box",
                  overflow: "hidden !important",
                }}
              />
              <span className="invalid-feedback">Please enter an address.</span>
            </div>
          </div>
        </div>

        <div className="mb-4 overflow-hidden">
          <label className="form-label" htmlFor="signinSrEmail">
            Business Address
          </label>
          <input
            type="text"
            className={`form-control form-control-lg ${
              error.address ? "border border-danger" : "border border-secondary"
            } ${
              !error.address && formData?.address !== ""
                ? "border border-secondary"
                : "border border-secondary"
            } input-fix`}
            name="address"
            id="address"
            tabIndex={1}
            value={formData?.address}
            placeholder="Business Address"
            aria-label="address"
            onChange={handleChange}
            required
            style={{
              whiteSpace: "normal",
              boxSizing: "border-box",
              overflow: "hidden !important",
            }}
          />
          <span className="invalid-feedback">Please enter an address.</span>
        </div>

        <div className="mb-4 overflow-hidden">
          <label className="form-label d-flex" htmlFor="business_type">
            Select Primary Bank*&emsp;
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip}
              trigger={["hover", "focus"]}
            >
              <div style={{ width: "auto" }}>
                <InfoIcon />
              </div>
            </OverlayTrigger>
          </label>
          <select
            className={`form-select form-control-lg ${
              error.primary_bank
                ? "border border-danger"
                : "border border-secondary"
            } ${
              formData?.primary_bank
                ? " border border-secondary"
                : "border border-secondary"
            }`}
            id="validationValidSelect1"
            value={formData?.primary_bank}
            name="primary_bank"
            onChange={handleChange}
            defaultValue={formData?.primary_bank}
          >
            <option>Select</option>
            {supported_banks?.map((i: any) => {
              return (
                <option key={i.bank_code} value={i.name}>
                  {i.name}
                </option>
              );
            })}
          </select>
          <span className="invalid-feedback">
            Please select a primary bank.
          </span>
        </div>
        <div className="mb-4 overflow-hidden">
          <label className="form-label" htmlFor="signinSrEmail">
            Referral code{" "}
          </label>
          <input
            type="text"
            className={`form-control form-control-lg ${
              error?.agent_referral_code
                ? " border border-danger"
                : "border border-secondary"
            } ${
              formData?.agent_referral_code !== ""
                ? " border border-secondary"
                : "border border-secondary"
            } input-fix`}
            name="agent_referral_code"
            id="agent_referral_code"
            tabIndex={1}
            value={formData?.agent_referral_code}
            placeholder="Referral code"
            aria-label="merchant_website"
            onChange={handleChange}
            style={{
              whiteSpace: "normal",
              boxSizing: "border-box",
              overflow: "hidden !important",
            }}
          />
        </div>

        <div className="form-check mb-4">
          <input
            className={`form-check-input ${
              error.agreed_terms ? "border border-danger" : ""
            } ${
              formData?.agreed_terms
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            type="checkbox"
            checked={formData?.agreed_terms}
            onChange={handleChange}
            id="agreed_terms"
            name="agreed_terms"
          />
          <label
            className={`form-check-label`}
            htmlFor="termsCheckbox"
            // onClick={() => handleDownload()}
          >
            By clicking this box, you affirm that you have read, understood and
            consent to our{" "}
            <span
              className={`link link-primary ${
                error.agreed_terms ? "link link-danger" : ""
              } ${
                formData?.agreed_terms
                  ? "link link-primary"
                  : "link link-primary"
              }`}
              onClick={(e) => e.preventDefault()}
              data-bs-toggle="modal"
              data-bs-target="#exampleModalScrollable"
              style={{ cursor: "pointer" }}
            >
              terms of use
            </span>{" "}
            and{" "}
            <a
              href="https://www.poolerapp.com/privacy-policy"
              target="_blank"
              rel="nonreferrer noreferrer"
              style={{ cursor: "pointer" }}
              className={`link link-primary ${
                error.agreed_terms ? "link link-danger" : ""
              } ${
                formData?.agreed_terms
                  ? "link link-primary"
                  : "link link-primary"
              }`}
            >
              privacy notice
            </a>
          </label>
        </div>
      </div>
    </>
  );
}
