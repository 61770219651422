import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as TransferIcon } from "../../assets/transfer-icon.svg";
import { ReactComponent as TransferReciept } from "../../assets/transfer-reciept.svg";
import {
  formatAmount,
  formatAmountWithDecimal,
} from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import {
  useResolveOTPForPin,
  useSendTransfer,
  useTransferDayUsage,
} from "../../hooks/transfer/index.hook";
import { sessionInterface } from "../../utils/interfaces";
import NewBenificiary from "./NewBenificiary";
import { FailedMessage, ProcessMessage, SuccessMessage } from "./Notice";
import SavedBenificiary from "./SavedBenificiary";
import SetPin from "./SetPin";
import Summary from "./Summary";
import "./modal.css";

function MakeTransferModal({
  show,
  handleClose,
  refetch,
}: {
  show: boolean;
  handleClose: () => void;
  refetch?: () => void | any;
}) {
  const [newBen, setNewBen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [stage, setStage] = useState<string>("account");
  const [account, setAccount] = useState<Array<any>>([]);
  const session = useSession();
  const merchant_data: sessionInterface = session?.data?.data?.data;
  const dailyUsage = useTransferDayUsage();
  const { resolve_otp_pin } = useResolveOTPForPin();
  const sendOTP = async () => {
    try {
      await resolve_otp_pin.mutateAsync();
    } catch (error) {
      return error;
    }
  };
  const handleAddAccount = (data: any) => {
    setAccount([data]);
  };
  const handleStage = (newStage: string, message?: string) => {
    setStage(newStage);
    if (message) setMessage(message);
  };
  const handleResetStage = () => {
    setStage("account");
  };
  const totalOutflow = dailyUsage?.data?.data?.data?.transferValueToday;
  const percentage =
    typeof merchant_data?.account_limit?.daily_withdrawal === "number"
      ? (totalOutflow / merchant_data?.account_limit?.daily_withdrawal) * 100
      : 0;
  const handleModalClose = () => {
    handleResetStage();
    setAccount([]);
    // refetch();
    dailyUsage?.refetch();
    handleClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100 d-flex align-items-center">
          {(stage === "summary" ||
            stage === "PIN" ||
            stage === "Verification") && (
            <button
              onClick={handleResetStage}
              className="btn text-start fs-2 fw-bold btn-link text-black"
            >
              <i className="bi bi-arrow-left" />
            </button>
          )}
          <p
            className={`text-center w-${
              stage === "summary" || stage === "PIN" || stage === "Verification"
                ? "75"
                : "100"
            } mb-0`}
            style={{ fontSize: "16px" }}
          >
            {stage === "account" && (
              <div className="text-center">
                <TransferIcon width={100} height={100} />
                <br />
                <br />
                <h6 className="card-subtitle">Perform Transfer</h6>
              </div>
            )}{" "}
            {stage === "summary" && (
              <div className="text-center">
                <TransferReciept width={100} height={100} />
                <br />
                <br />
                <h6 className="card-subtitle">
                  Provide transaction Pin to complete
                </h6>
              </div>
            )}
            {stage === "PIN" && "Set up PIN"}
            {stage === "Verification" && "Account verification"}
          </p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {stage === "account" && (
          <div className="account">
            {merchant_data?.state?.active_domain !== "test" && (
              <div className="mb-2 card-subtitle">
                <p>Daily withdrawal limit</p>
                <div>
                  <ProgressBar now={percentage} />
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{formatAmount(totalOutflow)}</span>
                    <span>
                      {merchant_data?.account_limit?.daily_withdrawal !==
                      "unlimited"
                        ? `NGN ${formatAmountWithDecimal(
                            merchant_data?.account_limit?.daily_withdrawal
                          )}`
                        : merchant_data?.account_limit?.daily_withdrawal}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* {account.map((_item, index) => (
              <AccountCard item={_item} key={index} />
            ))} */}
            <div className="mb-3">
              <button
                className="btn btn-link px-0 card-subtitle"
                style={{ color: "#2c1dff" }}
                onClick={() => setNewBen(!newBen)}
              >
                Transfer to {newBen ? "saved" : "new"} beneficiary{" "}
                <i className="bi bi-arrow-right"></i>
              </button>
            </div>

            {newBen ? (
              <NewBenificiary
                handleAddAccount={handleAddAccount}
                handleContinue={handleStage}
                account={account[0]}
                limit={merchant_data?.account_limit?.daily_withdrawal}
              />
            ) : (
              <SavedBenificiary
                handleAddAccount={handleAddAccount}
                handleContinue={handleStage}
                account={account[0]}
                limit={merchant_data?.account_limit?.daily_withdrawal}
              />
            )}
          </div>
        )}
        {stage === "summary" && (
          <Summary handleStage={handleStage} data={account} sendOTP={sendOTP} />
        )}

        {stage === "PIN" && <SetPin handleStage={handleStage} />}
        {stage === "success" && <SuccessMessage handleStage={handleStage} />}
        {stage === "Processing" && (
          <ProcessMessage
            handleReset={() => {
              handleResetStage();
              setAccount([]);
            }}
            counts={account.length > 1 ? true : false}
          />
        )}
        {stage === "Failed" && (
          <FailedMessage
            message={message}
            handleReset={() => handleStage("account")}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default MakeTransferModal;
