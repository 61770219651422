/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FormEvent, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Empty } from "../../assets/empty.svg";
import { formatAmount, shortenString } from "../../helpers/index.helper";
import { useGetBanks } from "../../hooks/payouts/payouts.hook";
import {
  ITransaction,
  ITransactions,
  bankInterface,
} from "../../utils/interfaces";
import EmptyResponse from "../EmptyResponse";

const TransferTable = ({
  data,
  refetch,
  isLoading,
  isFetching,
  reference,
  setFilter,
  setReference,
}: {
  data: any;
  refetch: () => void;
  isLoading: boolean;
  isFetching: boolean;
  reference: any;
  setFilter: any;
  setReference: any;
}): JSX.Element => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<ITransactions>();
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const { bankData } = useGetBanks();
  const banks: bankInterface[] = bankData?.data?.data;
  const handleRefFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };
  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    refetch();
  };
  const refreshData = () => {
    setReference("");
    setDates({
      start_date: "",
      end_date: "",
    });
    setFilter({
      reference,
      ...dates,
    });
    refetch();
  };

  useEffect(() => {
    if (data) {
      setTransactions(data?.data?.data);
    }
  }, [data]);

  return (
    <div className="card">
      <div className="card-header card-header-content-md-between">
        <div className="mb-2 mb-md-0">
          <form onSubmit={handleSearch}>
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                id="datatableSearch"
                type="search"
                className="form-control"
                value={reference}
                onChange={handleRefFilter}
                placeholder="Search by reference"
                aria-label="Search by reference"
              />
            </div>
          </form>
        </div>

        <div className="d-grid d-sm-flex gap-2 align-items-center">
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-white btn-sm w-100"
              aria-expanded="false"
              onClick={() => {
                refreshData();
                refetch();
              }}
            >
              <i className="bi bi-arrow-clockwise me-2"></i> Refresh
            </button>
          </div>
          <div>
            <Link className="fw-bold" to={"/merchant/transfer/all"}>
              See all <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className="table-responsive datatable-custom">
        <table
          id="datatable"
          className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
          style={{ width: "100%" }}
        >
          <thead className="thead-light">
            <tr>
              <th>Reference</th>
              <th>Date / Time</th>
              <th>Beneficiary name</th>
              <th>Account no</th>
              <th>Beneficiary bank</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>

          {isLoading || isFetching ? (
            <tbody>
              {[...Array(5)].map((i, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {transactions?.items?.length === 0 ? (
                <>
                  <tr>
                    <td colSpan={8}>
                      <EmptyResponse
                        title="No record found"
                        image={<Empty />}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {transactions?.items?.map((transaction: ITransaction) => {
                    const receiverBankName: any =
                      banks?.length > 0 &&
                      banks?.filter(
                        (bank) =>
                          bank.bank_code ===
                          transaction?.payout?.target_bank_code
                      );
                    return (
                      <tr
                        key={transaction?.id}
                        onClick={() =>
                          navigate(
                            `/merchant/transfer/details/${transaction?.id}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td>{shortenString(transaction?.reference, 15)}</td>
                        <td>{transaction?.created_at}</td>
                        <td>
                          {shortenString(transaction?.payout?.target_name, 15)}
                        </td>
                        <td>{transaction?.reciever_details?.account_number}</td>
                        <td>{receiverBankName?.[0]?.bank_name}</td>
                        <td>
                          {formatAmount(
                            +transaction?.amount,
                            transaction?.currency
                          )}
                        </td>
                        <td>
                          {transaction?.payout?.completed ||
                          transaction?.completed === true ? (
                            <span className="badge bg-soft-success text-success">
                              COMPLETED
                            </span>
                          ) : (
                            ""
                          )}
                          {transaction?.payout?.in_transit === true ? (
                            <span className="badge bg-soft-warning text-warning">
                              PENDING
                            </span>
                          ) : (
                            ""
                          )}
                          {transaction?.payout?.failed === true ? (
                            <span className="badge bg-soft-danger text-danger">
                              FAILED
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default TransferTable;
