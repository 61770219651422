import React, { useState, useEffect } from "react";
import Kyc from "./Kyc";
import PersonalFields from "./personal/PersonalFields";
import { useNavigate } from "react-router-dom";
import { PATH_NAMES } from "../../constants/index.constant";
import { useKyc, useSession } from "../../hooks/auth/auth.hook";
import { kycInterface, sessionInterface } from "../../utils/interfaces";
import SpinnerComponent from "../../component/spinner/Spinner";
import API_SERVICE from "../../services/services";
import { useQueryClient } from "react-query";

function Personal(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { provide_kyc } = useKyc();
  const { data, refetch } = useSession();
  const merchant_data: sessionInterface = data?.data?.data;

  const business_information: any =
    merchant_data?.kycDetails?.business_information;
  const documents: any = merchant_data?.kycDetails?.documents;
  const personal_information: any =
    merchant_data?.kycDetails?.personal_information;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    full_name: "",
    designation: "",
    email: "",
    mobile_number: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const SavePersonalDetails = async (payload: kycInterface) => {
    setIsLoading(false);
    try {
      const response: any = await provide_kyc.mutateAsync(payload);
      if (response.status === 200) {
        refetch();
        setIsLoading(false);
        setFormData({
          full_name: "",
          designation: "",
          email: "",
          mobile_number: "",
        });
        navigate(
          `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/director_information`
        );
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());

    setFormData((prevFormData) => ({
      ...prevFormData,
      full_name: personal_information?.personal_contact?.full_name,
      designation: personal_information?.personal_contact?.designation,
      email: personal_information?.personal_contact?.email,
      mobile_number: personal_information?.personal_contact?.mobile_number,
    }));
  }, [
    personal_information?.personal_contact?.designation,
    personal_information?.personal_contact?.email,
    personal_information?.personal_contact?.full_name,
    personal_information?.personal_contact?.mobile_number,
    queryClient,
  ]);

  return (
    <Kyc activeTab="personal_information">
      <div
        id="basicVerStepDetails"
        className="card card-body active"
        style={{ minHeight: "15rem" }}
      >
        <div className="row align-items-center mb-5 pb-2 mt-2">
          <div className="col-sm mb-2 mb-sm-0">
            <h2 className="page-header-title">Primary contact</h2>
          </div>

          <div className="col-sm-auto">
            <button
              disabled={isLoading}
              type="button"
              className="btn btn-primary"
              data-hs-step-form-next-options='{
                        "targetSelector": "#basicVerStepTerms"
                      }'
              onClick={() =>
                SavePersonalDetails({
                  personal_information: {
                    ...personal_information,
                    personal_contact: { ...formData },
                  },
                  business_information: { ...business_information },
                  documents: { ...documents },
                })
              }
            >
              Save changes
              {isLoading && (
                <>
                  &nbsp;
                  <span className="align-middle">
                    <SpinnerComponent size="sm" />
                  </span>
                </>
              )}
            </button>
          </div>
        </div>

        <PersonalFields formValues={formData} handleChange={handleChange} />

        <div className="d-none d-md-block d-lg-block">
          <div className="d-flex align-items-center mt-auto">
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  SavePersonalDetails({
                    personal_information: {
                      ...personal_information,
                      personal_contact: { ...formData },
                    },
                    business_information: { ...business_information },
                    documents: { ...documents },
                  });
                }}
              >
                Next <i className="bi-chevron-right small"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="d-sm-block d-md-none d-lg-none">
          <div className="d-flex align-items-center justify-content-space-between mt-auto">
            <div className="ms-auto">
              <button
                type="button"
                onClick={() =>
                  navigate(
                    `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/buisness_information?stage=2`
                  )
                }
                className="btn btn-ghost-secondary"
              >
                <i className="bi bi-chevron-left"></i>
                &nbsp;Back
              </button>
              <button
                type="button"
                className="btn btn-ghost-primary"
                onClick={() => {
                  SavePersonalDetails({
                    personal_information: {
                      ...personal_information,
                      personal_contact: { ...formData },
                    },
                    business_information: { ...business_information },
                    documents: { ...documents },
                  });
                }}
              >
                Next <i className="bi-chevron-right small"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Kyc>
  );
}

export default Personal;
