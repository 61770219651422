import React from "react";
import { CardProps } from "../../utils/interfaces";

function CardFooter({  pagination}: CardProps) {
  
  return (
    <div className="card-footer">
      <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
        <div className="col-sm mb-2 mb-sm-0">
          <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
            {/* <span className="me-2">Showing:</span>

            <div className="tom-select-custom">
              <select
                id="datatableEntries"
                className="js-select form-select form-select-borderless w-auto"
                autoComplete="off"
                data-hs-tom-select-options='{
                            "searchInDropdown": false,
                            "hideSearch": true
                          }'
              >
                <option value="12" selected>
                  12
                </option>
                <option value="14">14</option>
                <option value="16">16</option>
                <option value="18">18</option>
              </select>
            </div>

            <span className="text-secondary me-2">of</span>

            <span id="datatableWithPaginationInfoTotalQty"></span> */}
          </div>
        </div>

        <div className="col-sm-auto">
          <div className="d-flex justify-content-center justify-content-sm-end">
            {/* <nav
              id="datatablePagination"
              aria-label="Activity pagination"
            ></nav> */}
            {pagination}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardFooter;
