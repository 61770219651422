import moengage from "@moengage/web-sdk";
import React, { ChangeEvent, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../../component/back-btn";
import SpinnerComponent from "../../component/spinner/Spinner";
import { validateText } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import { useEditCashier } from "../../hooks/cashier/index.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import { EditCashier, sessionInterface } from "../../utils/interfaces";

function CashierUpdate(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { deviceInfo } = useUserDeviceInfo();

  const cashier_id = location?.state?.id;
  const { updateHandler } = useEditCashier();
  const { sessionData } = useSession();
  const merchantData: sessionInterface = sessionData?.data?.data;
  const business_name = merchantData?.business_name;

  const path = location?.pathname;

  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [errorData] = useState<any>({});
  const [cashierName, setCashierName] = useState<string>("");
  const [emailChannel, setEmailChannel] = useState<boolean>(true);
  const [whatsappChannel, setWhatsappChannel] = useState<boolean>(true);
  const [disable, setDisabled] = useState<boolean>(false);

  const Update = async (data: {
    cashier: EditCashier;
    cashier_id: string | any;
    path: string | any;
  }) => {
    setDisabled(true);
    try {
      setLoading(true);
      const response = await updateHandler.mutateAsync({
        cashier: data.cashier,
        cashier_id: data.cashier_id,
      });
      if (response?.status === 200) {
        moengage.track_event("EDIT_CASHIER", {
          ...data?.cashier,
          platform: deviceInfo?.userAgent,
          success_status: true,
        });
        navigate(`/merchant/business/cashier`);
      }
      setDisabled(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
    setDisabled(false);
    setLoading(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const Info = ({ text }: { text?: string }) => {
    return (
      <>
        <OverlayTrigger
          key={"top"}
          placement={"top"}
          overlay={<Tooltip id={text}>{text}</Tooltip>}
        >
          <i
            className="bi bi-info-circle-fill d-nonee"
            style={{ cursor: "pointer" }}
          ></i>
        </OverlayTrigger>
      </>
    );
  };

  useEffect(() => {
    if (location && location?.state?.cashier_name) {
      const originalCashierName = location?.state?.cashier_name;
      const parts = originalCashierName && originalCashierName?.split("/");
      const result = parts && parts[1]?.trim();
      setCashierName(
        originalCashierName?.includes("/") ? result : originalCashierName
      );
    }

    if (location && location?.state?.notification_channel === "whatsapp") {
      setWhatsappChannel(true);
    }

    if (location && location?.state?.notification_channel === "sms") {
      setEmailChannel(true);
    }
  }, [location]);

  return (
    <main>
      <div className="page-header mb-0 col-lg-7 mx-auto">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">
              <BackBtn />
              Edit Cashier
            </h1>{" "}
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-3 d-none d-lg-none">
          <div className="navbar-expand-lg navbar-vertical mb-3 mb-lg-5">
            <div className="d-grid">
              <button
                type="button"
                className="navbar-toggler btn btn-white mb-3"
                data-bs-toggle="collapse"
                data-bs-target="#navbarVerticalNavMenu"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navbarVerticalNavMenu"
              >
                <span className="d-flex justify-content-between align-items-center">
                  <span className="text-dark">Menu</span>

                  <span className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </span>

                  <span className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </span>
                </span>
              </button>
            </div>

            <div
              id="navbarVerticalNavMenu"
              className="collapse navbar-collapse"
            >
              <ul
                id="navbarSettings"
                className="js-sticky-block js-scrollspy card card-navbar-nav nav nav-tabs nav-lg nav-vertical"
                data-hs-sticky-block-options='{
             "parentSelector": "#navbarVerticalNavMenu",
             "targetSelector": "#header",
             "breakpoint": "lg",
             "startPoint": "#navbarVerticalNavMenu",
             "endPoint": "#stickyBlockEndPoint",
             "stickyOffsetTop": 20
           }'
              >
                <li className="nav-item">
                  <a
                    className={`nav-link active ps-5`}
                    href={"#Update_Cashier"}
                  >
                    Update Cashier
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-7 mx-auto" id="#create">
          <div className="card p-0 p-md-4 p-lg-4" id="basicVerStepFormContent">
            <div className="w-100 w-md-75 w-lg-75 mx-auto d-grid gap-4 p-4">
              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="cashier_name">
                    Cashier till name*{" "}
                    <Info text="Enter a unique name for the cashier till. This name will be used to identify the cashier in reports and will also serve as the account name." />
                  </label>

                  <input
                    name="cashier_name"
                    id="cashier_name"
                    tabIndex={1}
                    type="text"
                    className={`form-control`}
                    defaultValue={cashierName}
                    placeholder="e.g Till one"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setCashierName(e.target.value);
                      setFormData((prevFormData: any) => ({
                        ...prevFormData,
                        [e.target.name]: `${business_name} / ${e.target.value}`,
                      }));
                    }}
                    aria-label="cashier_name"
                    disabled
                    required
                  />

                  <span className={`form-text`}>
                    The account name is going to be “{business_name}/
                    {cashierName}”
                  </span>
                  <span className="invalid-feedback">
                    Please enter cashier till name.
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="last_name">
                    Cashier point address*{" "}
                    <Info text="Enter the physical address of the cashier point. This information is useful if you have physical locations." />
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formData?.address && formData?.address?.length > 0
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      errorData && errorData.address === true
                        ? "border border-danger"
                        : "border border-secondary"
                    } ${
                      location && location?.state?.address
                        ? "border border-secondary"
                        : "border border-secondary"
                    }`}
                    name="address"
                    id="address"
                    tabIndex={1}
                    placeholder="Enter address"
                    aria-label="address"
                    onChange={handleChange}
                    defaultValue={location?.state?.address}
                    required
                  />
                  <span className="invalid-feedback">
                    Please enter cashier address.
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="last_name">
                    Activate automatic daily clear out{" "}
                    <Info text="Check the box below to indicate if you want to automatically clear out the cashier's balance at the end of the day. This happens at 7 pm GMT +1 daily." />
                  </label>
                  <div className="d-flex align-items-center justify-content-space-between mt-auto">
                    <div className="form-check">
                      <input
                        className={`form-check-input ${
                          formData?.auto_settlement
                            ? "border border-secondary"
                            : "border border-secondary"
                        } ${
                          errorData && errorData.auto_settlement === true
                            ? "border border-danger"
                            : "border border-secondary"
                        } ${
                          location && location?.state?.auto_settlement
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                        type="checkbox"
                        id="auto_settlement"
                        name="auto_settlement"
                        defaultChecked={location?.state?.auto_settlement}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFormData((prevFormData: any) => ({
                            ...prevFormData,
                            auto_settlement: e.target.checked,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="last_name">
                    Do you want a physical signage{" "}
                    <Info text="Check the box below to indicate if you want a physical signage for the cashier. If you check the box, a physical signage will be delivered to the cashier's point address." />
                  </label>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${
                        formData?.banner && formData?.banner
                          ? "border border-secondary"
                          : "border border-secondary"
                      } ${
                        location && location?.state?.banner
                          ? "border border-success"
                          : "border border-seondary"
                      }`}
                      type="checkbox"
                      id="banner"
                      name="banner"
                      defaultChecked={location?.state?.banner}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFormData((prevFormData: any) => ({
                          ...prevFormData,
                          banner: e.target.checked,
                        }));
                      }}
                    />
                    <label
                      className={`form-check-label`}
                      htmlFor="termsCheckbox"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalScrollable"
                      // onClick={() => handleDownload()}
                    ></label>
                  </div>
                </div>
              </div>

              <ul
                id="basicStepFormProgress"
                className="js-step-progress step step-sm step-icon-sm step-inline step-item-between d-none d-md-block d-lg-block"
              >
                <li className="step-item">
                  <div
                    className="step-content-wrapper"
                    data-hs-step-form-next-options='{
          "targetSelector": "#basicStepDetails"
        }'
                  >
                    <div className="step-content pt-1">
                      <span
                        className="step-title pt-1 active"
                        style={{ color: "#1F7AFF" }}
                      >
                        Cashier
                      </span>
                    </div>
                  </div>
                </li>

                <li className="step-item">
                  <div
                    className="step-content-wrapper"
                    data-hs-step-form-next-options='{
           "targetSelector": "#basicStepTerms"
         }'
                  ></div>
                </li>
              </ul>

              <span
                className="step-title pt-1 active d-sm-block d-md-none d-lg-none"
                style={{ color: "#1F7AFF" }}
              >
                Cashier
              </span>

              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="firstname">
                    First name*
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formData?.firstname && formData?.firstname?.length > 0
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      formData?.firstname &&
                      formData?.firstname?.length > 0 &&
                      validateText(formData?.firstname) === true
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      formData?.firstname &&
                      formData?.firstname?.length > 0 &&
                      validateText(formData?.firstname) === false
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      errorData && errorData.firstname === true
                        ? "border border-danger"
                        : "border border-secondary"
                    } ${
                      location && location?.state?.firstname
                        ? "border border-secondary"
                        : "border border-secondary"
                    }`}
                    name="firstname"
                    id="firstname"
                    tabIndex={1}
                    placeholder="Enter first name"
                    aria-label="firstname"
                    defaultValue={location?.state?.firstname}
                    onChange={handleChange}
                    required
                  />
                  <span className="invalid-feedback">
                    {validateText(formData?.firstname) === false
                      ? "First name should be a text"
                      : "Please enter valid first name."}
                  </span>
                </div>
                <div className="col">
                  <label className="form-label" htmlFor="lastname">
                    Last name*
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formData?.lastname && formData?.lastname?.length > 0
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      formData?.lastname &&
                      formData?.lastname?.length > 0 &&
                      validateText(formData?.lastname) === true
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                      formData?.lastname &&
                      formData?.lastname?.length > 0 &&
                      validateText(formData?.lastname) === false
                        ? "border border-danger"
                        : "border border-secondary"
                    } ${
                      errorData && errorData.lastname === true
                        ? "border border-danger"
                        : "border border-secondary"
                    } ${
                      location && location?.state?.lastname
                        ? "border border-secondary"
                        : "border border-secondary"
                    }`}
                    name="lastname"
                    id="lastname"
                    tabIndex={1}
                    placeholder="Enter last name"
                    aria-label="lastname"
                    defaultValue={location?.state?.lastname}
                    onChange={handleChange}
                    required
                  />
                  <span className="invalid-feedback">
                    {validateText(formData?.lastname) === false
                      ? "Last name should be a text"
                      : "Please enter valid last name."}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label" htmlFor="email">
                    Email*
                  </label>
                  <input
                    type="email"
                    className={`form-control`}
                    name="email"
                    id="email"
                    tabIndex={1}
                    placeholder="e.g michael@email.com"
                    aria-label="email@address.com"
                    autoComplete="off"
                    onChange={handleChange}
                    defaultValue={location?.state?.email}
                    disabled
                    required
                  />
                  <span className="invalid-feedback">
                    Please enter a valid email address.
                  </span>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-block">
                <div className="row">
                  <div className="col">
                    <label className="form-label" htmlFor="phone_number">
                      Phone number*
                    </label>
                    <PhoneInput
                      country={"ng"}
                      disabled
                      value={location?.state?.mobile_number}
                      onChange={(phoneNumber: any) => {
                        setFormData((prevFormData: any) => ({
                          ...prevFormData,
                          mobile_number: phoneNumber,
                        }));
                      }}
                      inputClass={`form-control form-control-lg w-100 
                       `}
                    />
                    <span className="invalid-feedback">
                      Please enter cashier's phone number.
                    </span>
                    {/* <div className={`form-text`} hidden>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${
                          formData.is_whatsapp_number
                            ? "is-valid border border-success"
                            : "border border-secondary"
                        } ${
                          location && location?.state?.is_whatsapp_number
                            ? "is-valid border border-success"
                            : "border border-secondary"
                        }`}
                        type="checkbox"
                        id="is_whatsapp_number"
                        name="is_whatsapp_number"
                        defaultChecked={
                          location?.state?.mobile_number ===
                          location?.state?.whatsapp_number
                            ? true
                            : false
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked === true) {
                            setFormData((prevFormData: any) => ({
                              ...prevFormData,
                              whatsapp_number: formData?.mobile_number,
                              is_whatsapp_number: e.target.checked,
                            }));
                          } else {
                            setFormData((prevFormData: any) => ({
                              ...prevFormData,
                              is_whatsapp_number: false,
                            }));
                          }
                        }}
                      />
                      <label
                        className={`form-check-label pt-1`}
                        htmlFor="termsCheckbox"
                      >
                        Same as Whatsapp number
                      </label>
                    </div>
                  </div> */}
                  </div>
                  {/* <div className="col">
                    <label className="form-label" htmlFor="phone_number">
                      Whatsapp number*{" "}
                      <Info text="Enter the cashier's WhatsApp number if you want the cashier to receive payment notifications via Whatsapp." />
                    </label>
                    <PhoneInput
                      country={"ng"}
                      value={location?.state?.whatsapp_number}
                      onChange={(phoneNumber: any) => {
                        setFormData((prevFormData: any) => ({
                          ...prevFormData,
                          whatsapp_number: phoneNumber,
                        }));
                      }}
                      inputClass={`form-control form-control-lg w-100 
                    ${
                      formData?.whatsapp_number &&
                      formData?.whatsapp_number?.length > 0
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                        errorData && errorData.whatsapp_number === true
                          ? "border border-danger"
                          : "border border-secondary"
                      } ${
                        location && location?.state?.whatsapp_number
                          ? "border border-secondary"
                          : "border border-secondary"
                      }`}
                    />
                  </div> */}
                </div>
              </div>

              <div className="d-sm-block d-md-none d-lg-none">
                <div className="row mb-3">
                  <div className="col">
                    <label className="form-label" htmlFor="phone_number">
                      Phone number*
                    </label>
                    <PhoneInput
                      country={"ng"}
                      disabled
                      value={location?.state?.mobile_number}
                      onChange={(phoneNumber: any) => {
                        setFormData((prevFormData: any) => ({
                          ...prevFormData,
                          mobile_number: phoneNumber,
                        }));
                      }}
                      inputClass={`form-control form-control-lg w-100 
                       `}
                    />
                    <span className="invalid-feedback">
                      Please enter cashier's phone number.
                    </span>
                    {/* <div className={`form-text`} hidden>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${
                          formData.is_whatsapp_number
                            ? "is-valid border border-success"
                            : "border border-secondary"
                        } ${
                          location && location?.state?.is_whatsapp_number
                            ? "is-valid border border-success"
                            : "border border-secondary"
                        }`}
                        type="checkbox"
                        id="is_whatsapp_number"
                        name="is_whatsapp_number"
                        defaultChecked={
                          location?.state?.mobile_number ===
                          location?.state?.whatsapp_number
                            ? true
                            : false
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked === true) {
                            setFormData((prevFormData: any) => ({
                              ...prevFormData,
                              whatsapp_number: formData?.mobile_number,
                              is_whatsapp_number: e.target.checked,
                            }));
                          } else {
                            setFormData((prevFormData: any) => ({
                              ...prevFormData,
                              is_whatsapp_number: false,
                            }));
                          }
                        }}
                      />
                      <label
                        className={`form-check-label pt-1`}
                        htmlFor="termsCheckbox"
                      >
                        Same as Whatsapp number
                      </label>
                    </div>
                  </div> */}
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col">
                    <label className="form-label" htmlFor="phone_number">
                      Whatsapp number*{" "}
                      <Info text="Enter the cashier's WhatsApp number if you want the cashier to receive payment notifications via Whatsapp." />
                    </label>
                    <PhoneInput
                      country={"ng"}
                      value={location?.state?.whatsapp_number}
                      onChange={(phoneNumber: any) => {
                        setFormData((prevFormData: any) => ({
                          ...prevFormData,
                          whatsapp_number: phoneNumber,
                        }));
                      }}
                      inputClass={`form-control form-control-lg w-100 
                    ${
                      formData?.whatsapp_number &&
                      formData?.whatsapp_number?.length > 0
                        ? "border border-secondary"
                        : "border border-secondary"
                    } ${
                        errorData && errorData.whatsapp_number === true
                          ? "border border-danger"
                          : "border border-secondary"
                      } ${
                        location && location?.state?.whatsapp_number
                          ? "border border-secondary"
                          : "border border-secondary"
                      }`}
                    />
                  </div>
                </div> */}
              </div>

              <div className="d-none d-md-block d-lg-block">
                <div className="row">
                  <div className="col">
                    <label
                      className="form-label"
                      htmlFor="notification_channel"
                    >
                      Notification channel*{" "}
                      <Info text="Select a preferred notification channel for the cashier." />
                    </label>
                    <div className="d-flex gap-5">
                      <div className="form-check">
                        <input
                          className={`form-check-input border border-secondary`}
                          type="checkbox"
                          id="emailChannel"
                          name="emailChannel"
                          checked={emailChannel}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setEmailChannel(e.target.checked);
                            setWhatsappChannel(!e.target.checked);
                          }}
                          disabled
                        />
                        <label className={`form-label`} htmlFor="termsCheckbox">
                          sms
                        </label>
                      </div>

                      {/* <div className="form-check">
                        <input
                          className={`form-check-input ${
                            formData.is_whatsapp_number
                              ? "is-valid border border-success"
                              : "border border-secondary"
                          }`}
                          type="checkbox"
                          id="whatsappChannel"
                          name="whatsappChannel"
                          checked={whatsappChannel}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setWhatsappChannel(e.target.checked);
                            setEmailChannel(!e.target.checked);
                          }}
                        />
                        <label className={`form-label`} htmlFor="termsCheckbox">
                          Whatsapp
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-sm-block d-md-none d-lg-none">
                <div className="row">
                  <div className="col">
                    <label
                      className="form-label"
                      htmlFor="notification_channel"
                    >
                      Notification channel*{" "}
                      <Info text="Select a preferred notification channel for the cashier." />
                    </label>
                    <div className="d-flex flex-column">
                      <div className="form-check">
                        <input
                          className={`form-check-input border border-secondary`}
                          type="checkbox"
                          id="emailChannel"
                          name="emailChannel"
                          checked={emailChannel}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setEmailChannel(e.target.checked);
                            setWhatsappChannel(!e.target.checked);
                          }}
                          disabled
                        />
                        <label className={`form-label`} htmlFor="termsCheckbox">
                          sms
                        </label>
                      </div>

                      {/* <div className="form-check">
                        <input
                          className={`form-check-input ${
                            formData.is_whatsapp_number
                              ? "is-valid border border-success"
                              : "border border-secondary"
                          }`}
                          type="checkbox"
                          id="whatsappChannel"
                          name="whatsappChannel"
                          checked={whatsappChannel}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setWhatsappChannel(e.target.checked);
                            setEmailChannel(!e.target.checked);
                          }}
                        />
                        <label className={`form-label`} htmlFor="termsCheckbox">
                          Whatsapp
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-sm-block d-md-block d-lg-none d-xl-none">
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  disabled={disable}
                  onClick={() =>
                    Update({
                      cashier: {
                        ...formData,
                        notification_channel: emailChannel
                          ? "sms"
                          : whatsappChannel
                          ? "whatsapp"
                          : location?.state?.notification_channel,
                      },
                      cashier_id: cashier_id,
                      path: path,
                    })
                  }
                >
                  Save changes
                  {loading && (
                    <>
                      &nbsp;{" "}
                      <span className="align-middle">
                        <SpinnerComponent size="sm" />
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-auto mb-2">
              <div className="d-none d-lg-block d-xl-block">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={disable}
                  onClick={() =>
                    Update({
                      cashier: {
                        ...formData,
                        notification_channel: emailChannel
                          ? "sms"
                          : whatsappChannel
                          ? "whatsapp"
                          : location?.state?.notification_channel,
                      },
                      cashier_id: cashier_id,
                      path: path,
                    })
                  }
                >
                  Save changes
                  {loading && (
                    <>
                      &nbsp;{" "}
                      <span className="align-middle">
                        <SpinnerComponent size="sm" />
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CashierUpdate;
