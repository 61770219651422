import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { initQueryOptions } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import { RequestMethod } from "../../utils/enums";
import { instance } from "../../utils/instance";

// const queryClient = new QueryClient();

export const useAccounts = (page: number, config?: any) => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["accounts", page, config],
    () => API_SERVICE._getAccounts(page, config),
    initQueryOptions()
  );
  const account_data: any = data;
  return { account_data, isLoading, isError, isFetching, refetch };
};

export const useAccountExports = () => {
  const export_account: any = useMutation(
    (config: {
      start_date: string;
      end_date: string;
      download?: string;
    }): any => {
      console.log(config?.download, "download");
      let queryString;
      if (config?.download === "date_range") {
        queryString = `?start_date=${config.start_date}&end_date=${config.end_date}&download=true`;
        return instance({
          url: `/merchant/accounts${queryString}`,
          method: RequestMethod.GET,
        });
      } else if (config?.download === "all") {
        queryString = `?download=true`;
        return instance({
          url: `/merchant/accounts${queryString}`,
          method: RequestMethod.GET,
        });
      }
    },
    {
      ...initQueryOptions(),
    }
  );
  return { export_account };
};

export const useAccountFilter = () => {
  const account_filter: any = useMutation(
    (config: {
      start_date: string;
      end_date: string;
      download?: string;
    }): any => {
      let queryString;
      if (config?.download === "date_range") {
        queryString = `?start_date=${config.start_date}&end_date=${config.end_date}`;
        return instance({
          url: `/merchant/accounts${queryString}`,
          method: RequestMethod.GET,
        });
      }
    },
    {
      ...initQueryOptions(),
    }
  );
  return { account_filter };
};

export const useAccount = (id: string) => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["accounts", id],
    () => API_SERVICE._getAccount(id),
    initQueryOptions()
  );
  const account_data: any = data;
  return { account_data, isLoading, isError, isFetching, refetch };
};

export const useAccountTxn = (account_no: string) => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["accounts", account_no],
    () => API_SERVICE._getAccountTransactions(account_no),
    initQueryOptions()
  );
  const account_txn_data: any = data;
  return { account_txn_data, isLoading, isError, isFetching, refetch };
};

export const useGetAccountTransaction = (account_no: string) => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["account-txns", account_no],
    () => API_SERVICE._getAccountTransactionFromTransactions(account_no),
    initQueryOptions()
  );
  const account_txn: any = data;
  return { account_txn, isLoading, isError, isFetching, refetch };
};

export const useCreateAccount = () => {
  const createAccount = useMutation(
    (formData: any): any => {
      return instance({
        url: `/merchant/account`,
        method: RequestMethod.POST,
        data: { ...formData },
      });
    },
    {
      onSuccess: async (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { createAccount };
};
