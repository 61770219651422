import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PATH_NAMES } from "../../constants/index.constant";
import { encryptKyc, signatureHarsh } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import { useCheckBuisnessRequirements } from "../../hooks/kyc/kyc.hook";
import { useResolveOTPForPin } from "../../hooks/transfer/index.hook";
import { BUISNESS_TYPE } from "../../utils/enums";
import { sessionInterface } from "../../utils/interfaces";
import MakeTransferModalMobile from "../modal/MakeTransferModalMobile";
import ResetPinModal from "../modal/RestPin";
import SpinnerComponent from "../spinner/Spinner";

function ProfileSideBar(): JSX.Element {
  const location = useLocation();
  const { data, refetch } = useSession();
  const { resolve_otp_pin } = useResolveOTPForPin();
  const merchant_data: sessionInterface = data?.data?.data;
  const business_type = merchant_data?.business_category;
  const merchantId: any = localStorage.getItem("merchant_compliance_id");
  const transactionPinSet: boolean | any =
    merchant_data?.state?.transaction_pin_set;

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const message = merchantId && JSON.stringify({ id: merchantId });
  const x_signature = encryptKyc(message, signatureHarsh);

  const BUSINESS_TYPE: string | any =
    localStorage.getItem("business_cat") === "Registered"
      ? BUISNESS_TYPE.REGISTERED
      : BUISNESS_TYPE.UN_REGISTERED;

  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );

  const requirementsData: any = requiredData?.data?.data;
  // const general: any = requirementsData?.general;
  const levelOne: any = requirementsData?.level_one;
  const levelTwo: any = requirementsData?.level_two;
  // const levelThree: any = requirementsData?.level_three;

  const handleComplianceURL = (): string | any => {
    if (business_type === "Registered") {
      return levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`;
    } else if (business_type === "Unregistered") {
      return levelOne?.submit === true && levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
        : levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`;
    }
  };

  const sendOTP = async () => {
    setLoading(true);
    try {
      const response = await resolve_otp_pin.mutateAsync();
      if (response.status === 200) {
        setOpen(true);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      return error;
    }
  };

  return (
    <Fragment>
      <ResetPinModal show={show} handleClose={handleClose} />
      <MakeTransferModalMobile
        show={open}
        mobileStage="set-pin"
        handleClose={() => {
          setOpen(false);
          refetch();
        }}
        refetch={() => {
          setOpen(false);
          refetch();
        }}
        from="profile"
      />
      <div className="col-lg-3">
        <div className="navbar-expand-lg navbar-vertical mb-3 mb-lg-5">
          <div className="d-grid">
            <button
              type="button"
              className="navbar-toggler btn btn-white mb-3"
              data-bs-toggle="collapse"
              data-bs-target="#navbarVerticalNavMenu"
              aria-label="Toggle navigation"
              aria-expanded="false"
              aria-controls="navbarVerticalNavMenu"
            >
              <span className="d-flex justify-content-between align-items-center">
                <span className="text-dark">Menu</span>

                <span className="navbar-toggler-default">
                  <i className="bi-list"></i>
                </span>

                <span className="navbar-toggler-toggled">
                  <i className="bi-x"></i>
                </span>
              </span>
            </button>
          </div>

          <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
            <ul
              id="navbarSettings"
              className="js-sticky-block js-scrollspy card card-navbar-nav nav nav-tabs nav-lg nav-vertical"
              data-hs-sticky-block-options='{
             "parentSelector": "#navbarVerticalNavMenu",
             "targetSelector": "#header",
             "breakpoint": "lg",
             "startPoint": "#navbarVerticalNavMenu",
             "endPoint": "#stickyBlockEndPoint",
             "stickyOffsetTop": 20
           }'
            >
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location?.hash === "#content" ? "active" : ""
                  } `}
                  href="#content"
                >
                  <i className="bi-person nav-icon"></i> Basic information
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location?.hash === "#developers" ? "active" : ""
                  } `}
                  href="#developers"
                >
                  <i className="bi bi-file-code-fill nav-icon"></i> Developers
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location?.hash === "#2fa" ? "active" : ""
                  } `}
                  href="#2fa"
                >
                  <i className="bi bi-shield-lock nav-icon"></i> Security
                </a>
              </li>

              {transactionPinSet ? (
                <li className="nav-item" onClick={handleShow}>
                  <a
                    className={`nav-link ${
                      location?.hash === "#reset-pin" ? "active" : ""
                    } `}
                    href="#reset-pin"
                  >
                    <i className="bi bi-gear nav-icon"></i> Reset PIN
                  </a>
                </li>
              ) : (
                <li className="nav-item" onClick={sendOTP}>
                  <a
                    className={`nav-link ${
                      location?.hash === "#reset-pin" ? "active" : ""
                    } `}
                    href="#reset-pin"
                  >
                    <i className="bi bi-gear nav-icon"></i>
                    Set up PIN
                  </a>
                </li>
              )}

              <li className="nav-item" id="compliance">
                <Link
                  className={`nav-link ${
                    location?.hash === "#compliance" ? "active" : ""
                  } `}
                  to={handleComplianceURL()}
                >
                  <i className="bi bi-file-earmark-text nav-icon"></i>{" "}
                  Compliance
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProfileSideBar;
