import React from "react";

type Props = {
  className?: string;
  message?: string | React.ReactNode;
  onClick?: () => void;
  icon?: React.ReactNode;
};

function Alert({ className, message, onClick, icon }: Props): JSX.Element {
  // const navigate = useNavigate();
  return (
    <div className={className} role="alert" id="kyc-tour">
      <div
        className="d-flex"
        onClick={onClick}
        style={{ cursor: "pointer" }}
        id="kyc-alert"
      >
        <div className="flex-shrink-0">{icon}</div>
        <div className="flex-grow-1 ms-2">{message}</div>
      </div>
    </div>
  );
}

export default Alert;
