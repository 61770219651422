import moengage from "@moengage/web-sdk";
import React, { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSession } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  useCreatePayoutAccount,
  useGetBanks,
  useResolveAccount,
} from "../../hooks/payouts/payouts.hook";
import { bankInterface } from "../../utils/interfaces";
import SpinnerComponent from "../spinner/Spinner";

function CreatePayoutAcc() {
  const queryClient = useQueryClient();
  const closeButton = useRef<HTMLDivElement | null>(null);
  const { refetch } = useSession();
  const { deviceInfo } = useUserDeviceInfo();
  const { data, isLoading } = useGetBanks();
  const { resolve_account } = useResolveAccount();
  const { createPayoutAcc } = useCreatePayoutAccount();
  const bankData: bankInterface[] = data?.data?.data;

  const [hidden, setHidden] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [account_number, setAccountNumber] = useState<string>("");
  const [account_name, setAccountName] = useState<string>("");
  const [checkName, setCheckName] = useState<boolean>(false);

  const [bank, setBank] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    createPayoutAcc
      .mutateAsync({
        account_name: account_name,
        account_number: account_number,
        bank_code: bank?.bank_code,
        bank_name: bank?.bank_name,
      })
      .then(() => {
        toast.success("account created successfully");
        moengage.track_event("SET_PAYOUT_ACC", {
          account_number: account_number,
          bank_name: bank?.bank_name,
          success_status: true,
          platform: deviceInfo?.userAgent,
        });
        queryClient.refetchQueries();
      })
      .catch((error: any) => {
        return error;
      })
      .finally(() => {
        setLoading(false);
        closeButton.current?.click();
        refetch();
        queryClient.refetchQueries();
      });
  };

  const resolveBankAcc = async (data: any) => {
    setCheckName(true);
    try {
      const response: any = await resolve_account.mutateAsync({
        account_no: data?.account_number,
        bank_code: data?.bank_code,
      });

      if (response.status === 200) {
        setCheckName(false);
        setAccountName(response?.data?.data?.account_name);
        setHidden(false);
        setError({
          account_name: false,
          account_number: false,
          bank_name: false,
        });
      }
    } catch (error) {
      setCheckName(false);
      return error;
    }
  };

  return (
    <div
      className="modal fade"
      id="createCashierModal"
      tabIndex={-1}
      aria-labelledby="createCashierModalLabel"
      role="dialog"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="modal-title card-subtitle"
              id="createCashierModalLabel"
            >
              Create Account
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setAccountNumber("");
                setBank({});
                setAccountName("");
              }}
            ></button>
          </div>

          <div className="modal-body">
            <div className="row">
              {/* ACCOUNT NUMBER */}
              <div className="col">
                <label
                  className="form-label card-subtitle"
                  htmlFor="account_number"
                >
                  Account Number
                </label>
                <input
                  type="text"
                  className={`form-control mb-3  ${
                    error?.account_number === false
                      ? " border border-secondary"
                      : ""
                  }
                  ${
                    account_number.length === 10
                      ? " border border-secondary"
                      : "border border-secondary"
                  }
                  `}
                  placeholder=""
                  maxLength={10}
                  name="account_number"
                  value={account_number}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAccountNumber(e.target.value)
                  }
                />
                <span className="invalid-feedback">
                  account must not me greater or less than 10 characters
                </span>
              </div>
            </div>

            {/* ACCOUNT NAME */}
            {checkName ? (
              <div className="row">
                <div className="col">
                  <div className="col mx-auto text-center pt-2">
                    <SpinnerComponent animation="border" variant="dark" />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {account_name && (
                  <div className="row">
                    <div className="col">
                      <div className="col">
                        <label
                          className="form-label card-subtitle"
                          htmlFor="account_name"
                        >
                          Account Name
                        </label>
                        <input
                          type="text"
                          className={`form-control border border-secondary mb-3 ${
                            account_name ? "" : ""
                          }`}
                          placeholder=""
                          value={account_name}
                          name="account_name"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="row">
              <div className="col">
                <label className="form-label card-subtitle" htmlFor="bank">
                  Bank
                </label>
                <Select
                  options={bankData}
                  isLoading={isLoading}
                  getOptionLabel={(option) => option.bank_name}
                  getOptionValue={(option) => option.bank_code}
                  isSearchable
                  isClearable
                  menuPlacement="bottom"
                  placeholder="Search..."
                  onChange={async (option) => {
                    setBank(option);
                    if (account_number.length === 10) {
                      await resolveBankAcc({
                        account_number: account_number,
                        bank_code: option?.bank_code,
                      });
                    }
                  }}
                />
                <span className="invalid-feedback">Please select a bank.</span>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <div className="row align-items-sm-center flex-grow-1 mx-n2">
              <div className="col-sm mb-2 mb-sm-0"></div>

              <div className="col-sm-auto">
                <div className="d-flex gap-3">
                  <div
                    className="btn btn-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButton}
                    onClick={() => {
                      setAccountNumber("");
                      setBank({});
                      setAccountName("");
                    }}
                  >
                    Cancel
                  </div>
                  <button
                    disabled={hidden}
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-primary"
                  >
                    Submit{" "}
                    {loading && (
                      <>
                        &nbsp; <SpinnerComponent size="sm" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePayoutAcc;
//     border-color: #00c9a7;
