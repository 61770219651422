import moengage from "@moengage/web-sdk";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import Lottie from "lottie-react";
import React, {
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { useQueryClient } from "react-query";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Kredi } from "../../assets/Kredi logo.svg";
import { ReactComponent as Pyramid } from "../../assets/Pyramid logo.svg";
import PoolerLoader from "../../assets/lottie/Pooler-loader.json";
import Alert from "../../component/alert/Alert";
import { PATH_NAMES } from "../../constants/index.constant";
import { useClickContext } from "../../context/tourContext";
import { encryptKyc, signatureHarsh } from "../../helpers/index.helper";
import {
  useAuthFirstTimeLogin,
  useLogout,
  useSession,
} from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import { useCheckBuisnessRequirements } from "../../hooks/kyc/kyc.hook";
import { useStateManager } from "../../hooks/state-manager/state.hook";
import API_SERVICE from "../../services/services";
import { BUISNESS_TYPE } from "../../utils/enums";
import { LayoutProps, sessionInterface } from "../../utils/interfaces";
import Header from "../Header";
import HeadwayWidgetClass from "../headway/headwayClass";
import SEO from "../seo/seo";
import SpinnerComponent from "../spinner/Spinner";
import ButtonMailto from "../support/support";
import SideBar from "./Sidebar";
import "./layout.css";

export const CustomToggle = forwardRef<
  HTMLDivElement,
  {
    onClick: MouseEventHandler<HTMLDivElement>;
    children: ReactNode;
  }
>(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick}>
    {children}
  </div>
));

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#E0E4F4" rx="16"></rect>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 16H8m0 0l6 6m-6-6l6-6"
      ></path>
    </svg>
  );
}

function Layout({ children }: LayoutProps) {
  const { onClick, isModal } = useClickContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { data } = useSession();
  const { api_env } = useStateManager();
  const { firstTime } = useAuthFirstTimeLogin();
  const { deviceInfo } = useUserDeviceInfo();
  const { invalidateToken } = useLogout();

  const [loading, setLoading] = useState(true);
  const [showPartners, setShowPartners] = useState<boolean>(false);

  const merchant_data: sessionInterface = data?.data?.data;
  const is_live = merchant_data?.state?.is_live;
  const active_domain = merchant_data?.state?.active_domain;
  const first_time_login = merchant_data?.state?.first_time_login;
  const merchantId: any = localStorage.getItem("merchant_compliance_id");
  const message = merchantId && JSON.stringify({ id: merchantId });
  const x_signature = encryptKyc(message, signatureHarsh);
  const BizCategory =
    merchant_data?.business_category || localStorage.getItem("business_cat");
  const BUSINESS_TYPE: string | any = BizCategory?.toLowerCase().includes(
    "unregi"
  )
    ? BUISNESS_TYPE.UN_REGISTERED
    : BUISNESS_TYPE.REGISTERED;

  const { requiredData, isLoading: kycLoading } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );

  const business_type = merchant_data?.business_category;
  const requirementData: any = requiredData?.data?.data;
  const approvedLevelOne = requirementData?.approved_level_one;
  const approvedLevelTwo = requirementData?.approved_level_two;
  const approvedLevelThree = requirementData?.approved_level_three;
  const levelOne: any = requirementData?.level_one;
  const levelTwo: any = requirementData?.level_two;
  const levelThree: any = requirementData?.level_three;
  const stop = business_type?.toLowerCase().includes("unregi")
    ? approvedLevelThree && levelThree?.submit
    : approvedLevelTwo && levelTwo?.submit;

  const handleComplianceURL = (): string | any => {
    if (business_type?.toLowerCase().includes("unregi")) {
      return levelOne?.submit === true && levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
        : levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`;
    } else if (business_type?.toLowerCase().includes("regi")) {
      return levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`;
    }
  };

  const [isTourOpened, setIsTourOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [size, setSize] = useState([0, 0]);
  const [switchOn, setSwitchOn] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUnRegisterdKycAlert = () => {
    if (
      approvedLevelOne === true &&
      approvedLevelTwo === false &&
      approvedLevelThree === false
    ) {
      return (
        <Alert
          className={"alert alert-success"}
          icon={<i className="bi bi-person-check"></i>}
          message={
            <span>
              You are currently on tier 1. Upgrade to tier 2 to enjoy more
              benefits of Pooler!.
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
                  );
                }}
                hidden
              >
                Click here to provide your tier 2 details
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
            );
          }}
        />
      );
    } else if (approvedLevelTwo === true && approvedLevelThree === false) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-person-check"></i>}
          message={
            <span>
              You are currently on tier 2 Upgrade to tier 3 to enjoy more
              benefits of Pooler!.{" "}
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
                  );
                }}
                hidden
              >
                Click here to provide your tier 3 details
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
            );
          }}
        />
      );
    } else if (
      approvedLevelOne === true &&
      approvedLevelTwo === true &&
      approvedLevelThree === true
    ) {
      return (
        <Alert
          className={"alert alert-success"}
          icon={<i className="bi bi-person-check"></i>}
          message={
            <span>
              Your KYC documents have been approved.{" "}
              <span className="">
                Upgrade to a registered business to enjoy more benefits of
                Pooler!
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
            );
          }}
        />
      );
    } else if (
      levelOne?.submit === true &&
      levelTwo?.submit === true &&
      levelThree?.submit === true
    ) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={"Your KYC details for tier 3 are currently being reviewed"}
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`
            );
          }}
        />
      );
    } else if (levelOne?.submit === true && levelTwo?.submit === true) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            <span>
              Your KYC details for tier 2 are currently being reviewed.{" "}
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
                  );
                }}
              >
                Click here to provide your tier 3 details
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
            );
          }}
        />
      );
    } else if (levelOne?.submit === true) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            <span>
              Your KYC details for tier 1 are currently being reviewed.
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
                  );
                }}
              >
                Click here to provide your tier 2 details
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
            );
          }}
        />
      );
    } else if (
      levelOne?.submit === false &&
      levelTwo?.submit === false &&
      levelThree?.submit === false
    ) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            "Provide your KYC details to access all the features and benefits of Pooler!"
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`
            );
          }}
        />
      );
    } else if (levelOne?.submit === true && levelTwo?.submit === true) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            <span>
              Your KYC details for tier 2 are currently being reviewed.{" "}
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
                  );
                }}
              >
                Click here to provide your tier 3 details
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
            );
          }}
        />
      );
    } else if (levelTwo?.submit === true && approvedLevelOne === true) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            <span>
              Your KYC details for tier 2 are currently being reviewed.{" "}
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
                  );
                }}
              >
                Click here to provide your tier 3 details
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
            );
          }}
        />
      );
    } else if (
      requirementData === null ||
      levelOne === null ||
      levelTwo === null ||
      levelThree === null
    ) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            "Provide your KYC details to access all the features and benefits of Pooler!"
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`
            );
          }}
        />
      );
    }
  };

  const handleRegisterdKycAlert = () => {
    if (approvedLevelOne === true && approvedLevelTwo === false) {
      return (
        <Alert
          className={"alert alert-success"}
          icon={<i className="bi bi-person-check"></i>}
          message={
            <span>
              You are currently on tier 1. Upgrade to tier 2 to enjoy more
              benefits of Pooler.{" "}
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    "/merchant/kyc/registered_business/proof_of_address"
                  );
                }}
                hidden
              >
                Click here to provide your details for tier 2
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
            );
          }}
        />
      );
    } else if (approvedLevelOne === true && approvedLevelTwo === true) {
      return null;
    } else if (levelOne?.submit === true && levelTwo?.submit === true) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={"Your KYC details for tier 2 are currently being reviewed"}
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
            );
          }}
        />
      );
    } else if (levelOne?.submit === true) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            <span>
              Your KYC details for tier 1 are currently being reviewed.{" "}
              <span
                className="text-decoration-underline"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigate(
                    "/merchant/kyc/registered_business/proof_of_address"
                  );
                }}
              >
                Click here to provide your tier 2 details
              </span>
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
            );
          }}
        />
      );
    } else if (levelOne?.submit === false && levelTwo?.submit === false) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            <span
              className="text-decoration-underline"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                navigate(
                  `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
                );
              }}
            >
              Provide your KYC details to access all the features and benefits
              of Pooler!.
            </span>
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
            );
          }}
        />
      );
    } else if (
      requirementData === null ||
      levelOne === null ||
      levelTwo === null
    ) {
      return (
        <Alert
          className={"alert alert-warning"}
          icon={<i className="bi bi-info-circle-fill"></i>}
          message={
            "Provide your KYC details to access all the features and benefits of Pooler!"
          }
          onClick={() => {
            navigate(
              `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
            );
          }}
        />
      );
    }
  };

  const handleFirstTime = () => {
    firstTime
      .mutateAsync()
      .then(() => {
        setIsTourOpened(false);
      })
      .catch()
      .finally(() => {
        setIsTourOpened(false);
        if (window.innerWidth <= 1024) {
          driver().destroy();
        }
      });
  };

  const handleLiveToggleLg = () => {
    if (is_live === true) {
      return (
        <li className="nav-item list-unstyled" id="x-test-tour">
          <div className="d-flex justify-content-center">
            <div
              className="form-check form-switch form-switch-between d-flex flex-row align-items-end"
              // style={{ backgroundColor: `${switchOn && "#ed4c78"}` }}
            >
              {switchOn === true ? (
                <>
                  <input
                    type="checkbox"
                    className={`form-check-input is-invalid `}
                    defaultChecked={switchOn}
                    style={{ height: "18px" }}
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    checked
                  />

                  <label className="form-check-label is-invalid text-danger">
                    Test
                  </label>
                </>
              ) : (
                <>
                  <input
                    type="checkbox"
                    className={`form-check-input is-invalid `}
                    defaultChecked={switchOn}
                    style={{ height: "18px" }}
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    checked
                  />

                  <label className="form-check-label is-invalid text-danger">
                    Test
                  </label>
                </>
              )}
            </div>
          </div>
        </li>
      );
    } else if (is_live === false) {
      return (
        <li className="nav-item list-unstyled" id="x-test-tour">
          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
              <input
                type="checkbox"
                className="form-check-input is-invalid"
                data-bs-toggle={"modal"}
                data-bs-target={"#exampleModal"}
                defaultChecked
                disabled
                readOnly
                style={{ height: "18px" }}
              />
              <label className="form-check-label is-invalid text-danger">
                Test
              </label>
            </div>
          </div>
        </li>
      );
    }
  };

  const driverObj = driver({
    popoverClass: "driverjs-theme",
    prevBtnText: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
  </svg> Back`,
    nextBtnText: `Next <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
          </svg>`,
    doneBtnText: "End tour",
    onNextClick: () => {
      driverObj.moveNext();
      moengage.track_event("GUIDED_TOUR", {
        continues_tour: true,
        platform: deviceInfo?.userAgent,
      });
      if (driverObj.isLastStep()) {
        moengage.track_event("GUIDED_TOUR", {
          completes_tour: true,
          platform: deviceInfo?.userAgent,
        });
      }
    },
    onPrevClick: () => {
      driverObj.movePrevious();
      moengage.track_event("GUIDED_TOUR", {
        continues_tour: true,
        platform: deviceInfo?.userAgent,
      });
    },
    onPopoverRender: (popover, { config, state }) => {
      if (state?.isInitialized) {
        moengage.track_event("GUIDED_TOUR", {
          initiates_tour: true,
          platform: deviceInfo?.userAgent,
        });
      }
      setIsTourOpened(false);
      const closeButton = document.querySelector(".driver-popover-close-btn");
      const prevIcon = document.querySelector(".bi-arrow-left");
      const nextIcon = document.querySelector(".bi-arrow-right");
      if (nextIcon) {
        nextIcon?.addEventListener("click", () => {
          setIsTourOpened(false);
          driverObj.moveNext();
          if (window.innerWidth <= 1024) {
            driverObj.destroy();
          }
        });
      }
      if (prevIcon) {
        prevIcon?.addEventListener("click", () => {
          setIsTourOpened(false);
          driverObj.movePrevious();
        });
      }
      if (closeButton) {
        closeButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>`;
      }
      closeButton?.addEventListener("click", () => {
        driverObj.destroy();
        moengage.track_event("GUIDED_TOUR", {
          exits_tour: true,
          platform: deviceInfo?.userAgent,
        });
      });
    },

    steps:
      location?.pathname === "/merchant"
        ? [
            {
              element: "#kyc-tour",
              popover: {
                title: "",
                description:
                  "Complete your KYC verification to get started and start receiving payments.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#dashboard-tour",
              popover: {
                title: "",
                description:
                  "Get insights into your business performance with real-time metrics and charts.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#view-account-tour",
              popover: {
                title: "",
                description: "View your Pooler account details.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#fund-account-tour",
              popover: {
                title: "",
                description: "Simulate collections on the test environment.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#virtual-accounts-tour",
              popover: {
                title: "",
                description:
                  "Create and manage virtual accounts issued by your business.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#payout-tour",
              popover: {
                title: "",
                description:
                  "Withdraw money from your Pooler account and deposit it into your personal bank account.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#transfer-tour",
              popover: {
                title: "",
                description:
                  "Send money from your Pooler account to any other bank account.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#transactions-tour",
              popover: {
                title: "",
                description:
                  "Manage all transactions including collections, transfers, and payouts.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#business-tour",
              popover: {
                title: "",
                description:
                  "Streamline your payment collection with Pooler's Business tools.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#utility-tour",
              popover: {
                title: "",
                description:
                  "Manage your account information and security settings.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: `${
                active_domain === "test" ? "#x-test-tour" : "#x-live-tour"
              }`,
              popover: {
                title: "",
                description: `${
                  active_domain === "test"
                    ? "This indicates that you are currently in the Test environment."
                    : "This indicates that you are currently in the Live environment."
                }`,
                side: "bottom",
                align: "start",
              },
            },
          ]
        : [
            {
              element: "#kyc-tour",
              popover: {
                title: "",
                description:
                  "Complete your KYC verification to get started and start receiving payments.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#dashboard-tour",
              popover: {
                title: "",
                description:
                  "Get insights into your business performance with real-time metrics and charts.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#virtual-accounts-tour",
              popover: {
                title: "",
                description:
                  "Create and manage virtual accounts issued by your business.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#payout-tour",
              popover: {
                title: "",
                description:
                  "Withdraw money from your Pooler account and deposit it into your personal bank account.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#transfer-tour",
              popover: {
                title: "",
                description:
                  "Send money from your Pooler account to any other bank account.",
                side: "bottom",
                align: "start",
              },
            },

            {
              element: "#transactions-tour",
              popover: {
                title: "",
                description:
                  "Manage all transactions including collections, transfers, and payouts.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#business-tour",
              popover: {
                title: "",
                description:
                  "Streamline your payment collection with Pooler's Business tools.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: "#utility-tour",
              popover: {
                title: "",
                description:
                  "Manage your account information and security settings.",
                side: "bottom",
                align: "start",
              },
            },
            {
              element: `${
                active_domain === "test" ? "#x-test-tour" : "#x-live-tour"
              }`,
              popover: {
                title: "",
                description: `${
                  active_domain === "test"
                    ? "This indicates that you are currently in the Test environment."
                    : "This indicates that you are currently in the Live environment."
                }`,
                side: "bottom",
                align: "start",
              },
            },
          ],
    animate: true,
  });

  const onSwitchToLiveEnv = () => {
    window.localStorage.removeItem("domain");
    setIsLoading(true);
    api_env
      .mutateAsync()
      .then((response) => {
        const domain = response?.data?.data?.active_domain;
        window.localStorage.setItem("domain", domain);
        if (domain === "test") {
          moengage.track_event("TEST_ENV", {
            switch_to_test: true,
            success_status: true,
            firstName: merchant_data?.firstname,
            lastName: merchant_data?.lastname,
            email: merchant_data?.email,
            mobile: merchant_data?.mobile_number,
            userName: merchant_data?.email,
            uniqueUserId: merchant_data?.email,
            phone_number: merchant_data?.mobile_number,
            terms_agreement: merchant_data?.agreed_terms,
            address: merchant_data?.address,
            business_category: merchant_data?.business_category,
            business_type: merchant_data?.business_type,
            platform: deviceInfo?.userAgent,
          });
          setIsLoading(false);

          handleClose();
          setIsModalOpen(false);

          toast.success("You are now in test mode");
          queryClient.refetchQueries();
          navigate(`/merchant`, { replace: true });
        } else {
          setIsLoading(false);
          moengage.track_event("LIVE_ENV", {
            switch_to_live: true,
            success_status: true,
            firstName: merchant_data?.firstname,
            lastName: merchant_data?.lastname,
            email: merchant_data?.email,
            mobile: merchant_data?.mobile_number,
            userName: merchant_data?.email,
            uniqueUserId: merchant_data?.email,
            phone_number: merchant_data?.mobile_number,
            terms_agreement: merchant_data?.agreed_terms,
            address: merchant_data?.address,
            business_category: merchant_data?.business_category,
            business_type: merchant_data?.business_type,
            platform: deviceInfo?.userAgent,
          });
          handleClose();
          setIsModalOpen(false);
          toast.success("You are now in live mode");
          navigate(`/merchant`, { replace: true });
          queryClient.refetchQueries();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        moengage.add_user_attribute("success", false);
        return e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ModalForSwitch = () => {
    return (
      <>
        <Modal
          show={isModalOpen}
          onHide={() => {
            setIsModalOpen(false);
            setSwitchOn(false);
          }}
          onExit={() => {
            setIsModalOpen(false);
            setSwitchOn(false);
          }}
          centered
          size="sm"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ display: "contents" }}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gx-2 gx-sm-3 text-center">
              <h5 className="h2">Switch to Live mode</h5>
              <p className="w-100 text-center mx-auto py-2 fw-normal">
                You are about to switch to Live mode. This means that you will
                start processing real transactions and actual payments from your
                customers.
              </p>
              <div className="d-flex flex-row justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    onSwitchToLiveEnv();
                    moengage.track_event("LIVE_ENV", {
                      switch_to_live: true,
                      firstName: merchant_data?.firstname,
                      lastName: merchant_data?.lastname,
                      email: merchant_data?.email,
                      mobile: merchant_data?.mobile_number,
                      userName: merchant_data?.email,
                      uniqueUserId: merchant_data?.email,
                      phone_number: merchant_data?.mobile_number,
                      terms_agreement: merchant_data?.agreed_terms,
                      address: merchant_data?.address,
                      business_category: merchant_data?.business_category,
                      business_type: merchant_data?.business_type,
                      platform: deviceInfo?.userAgent,
                    });
                  }}
                >
                  Switch to Live mode{" "}
                  {isLoading ? (
                    <SpinnerComponent size="sm" />
                  ) : (
                    <i className="bi bi-arrow-right"></i>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          onExit={() => {
            setIsModalOpen(false);
            setSwitchOn(true);
          }}
          centered
          size="sm"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ display: "contents" }}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gx-2 gx-sm-3 text-center">
              <h5 className="h2">Switch to Test mode</h5>
              <p className="w-100 text-center mx-auto py-2 fw-normal">
                You are about to switch to test mode. This means that all
                transactions and payments carried out are not real
              </p>
              <div className="d-flex flex-row justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => {
                    onSwitchToLiveEnv();
                    moengage.track_event("TEST_ENV", {
                      switch_to_test: true,
                      firstName: merchant_data?.firstname,
                      lastName: merchant_data?.lastname,
                      email: merchant_data?.email,
                      mobile: merchant_data?.mobile_number,
                      userName: merchant_data?.email,
                      uniqueUserId: merchant_data?.email,
                      phone_number: merchant_data?.mobile_number,
                      terms_agreement: merchant_data?.agreed_terms,
                      address: merchant_data?.address,
                      business_category: merchant_data?.business_category,
                      business_type: merchant_data?.business_type,
                      platform: deviceInfo?.userAgent,
                    });
                  }}
                >
                  Switch to test mode{" "}
                  {isLoading ? (
                    <SpinnerComponent size="sm" />
                  ) : (
                    <i className="bi bi-arrow-right"></i>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());
  }, [queryClient]);

  useEffect(() => {
    if (!kycLoading && requirementData === null) {
      setLoading(false);
    }
    if (size?.[0] > 996 && typeof stop === "boolean") {
      if (location.pathname === "/merchant/get_started") {
        if (stop) {
          navigate("/merchant");
          setTimeout(() => setLoading(false), 1000);
        } else {
          setTimeout(() => setLoading(false), 1000);
        }
      }
    } else if (size?.[0] < 996 && typeof stop === "boolean") {
      if (location.pathname === "/merchant/get_started") {
        navigate("/merchant");
        setTimeout(() => setLoading(false), 1000);
      } else {
        setTimeout(() => setLoading(false), 1000);
      }
    } else if (location.pathname !== "/merchant/get_started") {
      setLoading(false);
    }
  }, [stop, size, kycLoading, requirementData]);

  useEffect(() => {
    // if (first_time_login) {
    //   setIsTourOpened(true);
    // }
  }, [first_time_login, setIsTourOpened]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    moengage.initialize({ app_id: process.env.REACT_APP_MOENGAGE_APPID });

    if (merchant_data) {
      moengage.add_first_name(merchant_data?.firstname);
      moengage.add_last_name(merchant_data?.lastname);
      moengage.add_email(merchant_data?.email);
      moengage.add_mobile(merchant_data?.mobile_number);
      moengage.add_user_name(merchant_data?.email);
      moengage.add_unique_user_id(merchant_data?.email);
      moengage.add_user_attribute(
        "business_name",
        merchant_data?.business_name
      );
    }
  }, [merchant_data]);

  const handleLogout = () => {
    invalidateToken
      .mutateAsync()
      .then()
      .catch()
      .finally(() => {
        localStorage.clear();
        moengage.track_event("LOGOUT", {
          success_status: true,
          firstName: merchant_data?.firstname,
          lastName: merchant_data?.lastname,
          email: merchant_data?.email,
          mobile: merchant_data?.mobile_number,
          userName: merchant_data?.email,
          uniqueUserId: merchant_data?.email,
          phone_number: merchant_data?.mobile_number,
          terms_agreement: merchant_data?.agreed_terms,
          address: merchant_data?.address,
          business_category: merchant_data?.business_category,
          business_type: merchant_data?.business_type,
          platform: deviceInfo?.userAgent,
        });
        window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
      });
  };

  return (
    <>
      <ModalForSwitch />
      {size?.[0] > 1024 && (
        <Modal
          show={isTourOpened || isModal}
          onHide={() => {
            setIsTourOpened(false);
            onClick();
          }}
          centered
          keyboard={false}
          onExit={() => handleFirstTime()}
          className="d-none d-md-none d-lg-block"
        >
          <Modal.Header
            closeButton={false}
            className="modal-header-bg"
            style={{ height: "190px" }}
          >
            <Modal.Title style={{ display: "contents" }}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gx-2 gx-sm-3">
              <h5 className="h2">Welcome to Pooler!</h5>
              {active_domain === "test" && (
                <p className="d-flex flex-column">
                  <span className="w-100  py-2 fw-normal">
                    You are currently in test mode, meaning you can explore the
                    features of Pooler without making live transactions.
                  </span>
                  <br />
                  <span className="w-100 fw-normal">
                    This is a great opportunity to learn how Pooler works and
                    how it can help you manage your business.
                  </span>
                </p>
              )}
              {active_domain === "live" && (
                <p className="d-flex flex-column">
                  <span className="w-100 fw-normal">
                    You are currently in live mode, meaning you can experience
                    real transactions in action.
                  </span>
                  <br />
                  <span className="w-100 fw-normal">
                    This is a great opportunity to learn how Pooler works and
                    how it can help you manage your business.
                  </span>
                </p>
              )}
            </div>
            <div className="">
              {false ? (
                <button
                  type="button"
                  className="btn btn-primary btn-sm d-flex flex-row align-items-center"
                  style={{ width: "100px", float: "right" }}
                  onClick={() => {
                    if (window.location.pathname === "/merchant/get_started")
                      navigate("/merchant");
                    function Init() {
                      setIsTourOpened(false);
                      handleFirstTime();
                      if (size?.[0] <= 1024) {
                        driverObj.destroy();
                        setIsTourOpened(false);
                        onClick();
                      } else {
                        driverObj.drive();
                        setIsTourOpened(false);
                        onClick();
                        moengage.track_event("GUIDED_TOUR", {
                          initiates_tour: true,
                          platform: deviceInfo?.userAgent,
                        });
                      }
                    }
                    setTimeout(() => Init(), 600);
                  }}
                >
                  <span>Let's go</span> <i className="bi bi-arrow-right"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-sm d-flex flex-row align-items-center"
                  style={{ width: "100px", float: "right" }}
                  onClick={() => {
                    if (window.location.pathname === "/merchant/get_started")
                      navigate("/merchant");
                    function Init() {
                      setIsTourOpened(false);
                      if (
                        approvedLevelOne === true &&
                        approvedLevelTwo === true &&
                        merchant_data?.business_category === "Registered"
                      ) {
                        setIsTourOpened(false);
                        onClick();
                        driverObj.drive(1);
                        moengage.track_event("GUIDED_TOUR", {
                          initiates_tour: true,
                          platform: deviceInfo?.userAgent,
                        });
                      } else {
                        setIsTourOpened(false);
                        onClick();
                        driverObj.drive();
                        moengage.track_event("GUIDED_TOUR", {
                          initiates_tour: true,
                          platform: deviceInfo?.userAgent,
                        });
                      }
                    }
                    setTimeout(() => Init(), 600);
                  }}
                >
                  <span>Let's go</span> <i className="bi bi-arrow-right"></i>
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={showPartners}
        onHide={() => {
          setIsModalOpen(false);
          setSwitchOn(false);
          setShowPartners(false);
        }}
        onExit={() => {
          setIsModalOpen(false);
          setSwitchOn(false);
          setShowPartners(false);
        }}
        centered
        size="sm"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ display: "contents" }}>PARTNERS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list-group list-group-flush list-group-no-gutters">
            <div className="list-group-item">
              <div
                className="d-flex align-items-center gap-2
              "
              >
                <Kredi className="" />
                <span
                  className=" fw-bold text-cap"
                  style={{ fontSize: "12px", marginBottom: "0" }}
                >
                  Kredi Money Microfinance Bank
                </span>
              </div>
            </div>
            <div className="list-group-item">
              <div className="d-flex align-items-center gap-2">
                <Pyramid />
                <span
                  className=" fw-bold text-cap"
                  style={{ fontSize: "12px", marginBottom: "0" }}
                >
                  Pyramid Microfinance Bank
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SEO
        title="Pooler - Collecting Safely"
        description="Use Pooler to collect and settle payments seamless and faster by transfer and avoid chargeback"
        type="website"
      />
      {size?.[0] > 996 && loading && (
        <div id="set-up" className="overflow-hidden">
          <div className="set-up-body">
            <Lottie
              animationData={PoolerLoader}
              style={{
                scale: "4",
                width: "350px",
              }}
              aria-aria-labelledby="pooler loader"
            />
          </div>
        </div>
      )}
      <div className="has-navbar-vertical-aside navbar-vertical-aside-show-xl">
        <Header />
        <SideBar stop={stop} />
        <main
          id="content"
          role="main"
          className="main"
          style={{ background: "#fff !important" }}
        >
          {active_domain === "test" && (
            <div
              className={`alert alert-soft-danger text-danger d-flex d-none d-lg-block d-xl-block`}
              role="alert"
              style={{ borderRadius: "0px" }}
            >
              <span className="text-center mx-auto d-flex flex-row align-items-center justify-content-center">
                <i className="bi bi-exclamation-triangle-fill"></i> You are
                currently in test mode. Transactions performed here are not
                real.
              </span>
            </div>
          )}

          {location.pathname ===
          "/merchant/get_started" ? null : location.pathname.includes(
              "/merchant/topup/bulk"
            ) ? null : (
            <div
              className="page-header px-5 d-none d-md-none d-lg-block"
              style={{
                marginBottom: "0px",
                paddingBottom: "10px",
              }}
            >
              <div className="row align-items-center justify-content-end px-5">
                <div
                  className="d-flex flex-row float-end"
                  style={{ justifyContent: "end", alignItems: "center" }}
                >
                  <div
                    className="dropdown dropup me-3"
                    id={
                      active_domain === "test" ? "x-test-tour" : "x-live-tour"
                    }
                  >
                    <Form>
                      {active_domain === "test" &&
                      active_domain !== undefined ? (
                        <>{handleLiveToggleLg()}</>
                      ) : (
                        ""
                      )}

                      {active_domain === "live" &&
                      active_domain !== undefined ? (
                        <div
                          className="d-flex justify-content-center"
                          id="x-live-tour xx-live"
                        >
                          <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
                            <input
                              type="checkbox"
                              className="form-check-input is-valid"
                              onClick={handleShow}
                              defaultChecked
                              style={{ height: "18px" }}
                              checked
                            />
                            <label className="form-check-label text-success">
                              Live
                            </label>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Form>
                  </div>
                  {size?.[0] > 1100 && <HeadwayWidgetClass />}
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <span
                        className="ms-2"
                        style={{ fontSize: "20px", color: "#71869d" }}
                      >
                        <i className="bi bi-person-circle"></i>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <NavLink
                          className={"link-dark"}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                        >
                          <i className="bi-person"></i> &emsp;Profile &amp;
                          account
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <NavLink
                          className={"link-dark"}
                          to={handleComplianceURL()}
                        >
                          <i className="bi-person"></i> &emsp;Compliance
                        </NavLink>
                      </Dropdown.Item>

                      <Dropdown.Item
                        as="button"
                        onClick={() => {
                          setIsTourOpened(true);
                          onClick(() => {
                            setIsTourOpened(true);
                          });
                        }}
                        hidden={false}
                      >
                        <i className="bi bi-signpost"></i>&emsp;Take a tour
                      </Dropdown.Item>

                      {/* <span className="dropdown-header">Contact</span> */}
                      <Dropdown.Item href={`https://docs.poolerapp.com/`}>
                        <a
                          href={`https://docs.poolerapp.com/`}
                          target="_blank"
                          rel="noreferrer"
                          className="link-dark"
                        >
                          <i className="bi-journals"></i> &emsp;API Docs &nbsp;
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <ButtonMailto mailto="mailto:support@poolerapp.com" />
                      </Dropdown.Item>
                      {/* <span className="dropdown-header">deauthenticate</span> */}
                      <Dropdown.Item
                        as={"button"}
                        onClick={() => {
                          handleLogout();
                        }}
                      >
                        <i className="bi bi-shield-lock"></i> &emsp;Sign out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          )}

          {location.pathname === "/merchant/get_started" && (
            <>
              <div
                className="page-header get-started-header d-none d-md-none d-lg-block"
                style={{
                  marginBottom: "0px",
                  paddingBottom: "10px",
                }}
              >
                <div className="row align-items-center justify-content-between px-3">
                  <div
                    className="d-flex flex-row float-end"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-sm mb-2 mb-sm-0">
                      <h1 className="page-header-title">Get Started</h1>
                    </div>
                    <div
                      className="dropdown dropup me-3"
                      id={
                        active_domain === "test" ? "x-test-tour" : "x-live-tour"
                      }
                    >
                      <Form>
                        {active_domain === "test" &&
                        active_domain !== undefined ? (
                          <>{handleLiveToggleLg()}</>
                        ) : (
                          ""
                        )}

                        {active_domain === "live" &&
                        active_domain !== undefined ? (
                          <div
                            className="d-flex justify-content-center"
                            id="x-live-tour xx-live"
                          >
                            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
                              <input
                                type="checkbox"
                                className="form-check-input is-valid"
                                onClick={handleShow}
                                defaultChecked
                                style={{ height: "18px" }}
                                checked
                              />
                              <label className="form-check-label text-success">
                                Live
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Form>
                    </div>
                    {size?.[0] > 1100 && <HeadwayWidgetClass />}
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <span
                          className="ms-2"
                          style={{ fontSize: "20px", color: "#71869d" }}
                        >
                          <i className="bi bi-person-circle"></i>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink
                            className={"link-dark"}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                          >
                            <i className="bi-person"></i> &emsp;Profile &amp;
                            account
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink
                            className={"link-dark"}
                            to={handleComplianceURL()}
                          >
                            <i className="bi-person"></i> &emsp;Compliance
                          </NavLink>
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            setIsTourOpened(true);
                            onClick(() => {
                              setIsTourOpened(true);
                            });
                          }}
                          hidden={false}
                        >
                          <i className="bi bi-signpost"></i>&emsp;Take a tour
                        </Dropdown.Item>

                        {/* <span className="dropdown-header">Contact</span> */}
                        <Dropdown.Item href={`https://docs.poolerapp.com/`}>
                          <a
                            href={`https://docs.poolerapp.com/`}
                            target="_blank"
                            rel="noreferrer"
                            className="link-dark"
                          >
                            <i className="bi-journals"></i> &emsp;API Docs
                            &nbsp;
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <ButtonMailto mailto="mailto:support@poolerapp.com" />
                        </Dropdown.Item>
                        {/* <span className="dropdown-header">deauthenticate</span> */}
                        <Dropdown.Item
                          onClick={() => {
                            handleLogout();
                          }}
                        >
                          <i className="bi bi-shield-lock"></i> &emsp;Sign out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </>
          )}
          {location.pathname === "/merchant/topup/bulk" && (
            <>
              <div
                className="page-header get-started-header d-none d-md-none d-lg-block"
                style={{
                  marginBottom: "0px",
                  paddingBottom: "10px",
                }}
              >
                <div className="row align-items-center justify-content-between px-3">
                  <div
                    className="d-flex flex-row float-end"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-sm mb-0 mt-2 mb-sm-0">
                      <h1
                        className="page-header-title py-2 d-flex align-items-center text-capitalize"
                        style={{ gap: 8 }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            searchParams?.get("preview")
                              ? navigate(-1)
                              : navigate("/merchant/topup")
                          }
                        >
                          <BackIcon />
                        </div>
                        Bulk {searchParams?.get("type")} Purchase
                      </h1>
                    </div>
                    <div
                      className="dropdown dropup me-3"
                      id={
                        active_domain === "test" ? "x-test-tour" : "x-live-tour"
                      }
                    >
                      <Form>
                        {active_domain === "test" &&
                        active_domain !== undefined ? (
                          <>{handleLiveToggleLg()}</>
                        ) : (
                          ""
                        )}

                        {active_domain === "live" &&
                        active_domain !== undefined ? (
                          <div
                            className="d-flex justify-content-center"
                            id="x-live-tour xx-live"
                          >
                            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
                              <input
                                type="checkbox"
                                className="form-check-input is-valid"
                                onClick={handleShow}
                                defaultChecked
                                style={{ height: "18px" }}
                                checked
                              />
                              <label className="form-check-label text-success">
                                Live
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Form>
                    </div>
                    {size?.[0] > 1100 && <HeadwayWidgetClass />}
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <span
                          className="ms-2"
                          style={{ fontSize: "20px", color: "#71869d" }}
                        >
                          <i className="bi bi-person-circle"></i>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink
                            className={"link-dark"}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                          >
                            <i className="bi-person"></i> &emsp;Profile &amp;
                            account
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink
                            className={"link-dark"}
                            to={handleComplianceURL()}
                          >
                            <i className="bi-person"></i> &emsp;Compliance
                          </NavLink>
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            setIsTourOpened(true);
                            onClick(() => {
                              setIsTourOpened(true);
                            });
                          }}
                          hidden={false}
                        >
                          <i className="bi bi-signpost"></i>&emsp;Take a tour
                        </Dropdown.Item>

                        {/* <span className="dropdown-header">Contact</span> */}
                        <Dropdown.Item href={`https://docs.poolerapp.com/`}>
                          <a
                            href={`https://docs.poolerapp.com/`}
                            target="_blank"
                            rel="noreferrer"
                            className="link-dark"
                          >
                            <i className="bi-journals"></i> &emsp;API Docs
                            &nbsp;
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <ButtonMailto mailto="mailto:support@poolerapp.com" />
                        </Dropdown.Item>
                        {/* <span className="dropdown-header">deauthenticate</span> */}
                        <Dropdown.Item
                          onClick={() => {
                            localStorage.clear();
                            window.location.replace(
                              `/auth/merchant${PATH_NAMES.signin}`
                            );
                          }}
                        >
                          <i className="bi bi-shield-lock"></i> &emsp;Sign out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </>
          )}
          {location.pathname.includes("/merchant/topup/bulk/") && (
            <>
              <div
                className="page-header get-started-header d-none d-md-none d-lg-block"
                style={{
                  marginBottom: "0px",
                  paddingBottom: "10px",
                }}
              >
                <div className="row align-items-center justify-content-between px-3">
                  <div
                    className="d-flex flex-row float-end"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-sm mb-0 mt-2 mb-sm-0">
                      <h1
                        className="page-header-title py-2 d-flex align-items-center text-capitalize"
                        style={{ gap: 8 }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(-1)}
                        >
                          <BackIcon />
                        </div>
                        {searchParams?.get("filename")}
                      </h1>
                    </div>
                    <div
                      className="dropdown dropup me-3"
                      id={
                        active_domain === "test" ? "x-test-tour" : "x-live-tour"
                      }
                    >
                      <Form>
                        {active_domain === "test" &&
                        active_domain !== undefined ? (
                          <>{handleLiveToggleLg()}</>
                        ) : (
                          ""
                        )}

                        {active_domain === "live" &&
                        active_domain !== undefined ? (
                          <div
                            className="d-flex justify-content-center"
                            id="x-live-tour xx-live"
                          >
                            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
                              <input
                                type="checkbox"
                                className="form-check-input is-valid"
                                onClick={handleShow}
                                defaultChecked
                                style={{ height: "18px" }}
                                checked
                              />
                              <label className="form-check-label text-success">
                                Live
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Form>
                    </div>
                    {size?.[0] > 1100 && <HeadwayWidgetClass />}
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <span
                          className="ms-2"
                          style={{ fontSize: "20px", color: "#71869d" }}
                        >
                          <i className="bi bi-person-circle"></i>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink
                            className={"link-dark"}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                          >
                            <i className="bi-person"></i> &emsp;Profile &amp;
                            account
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink
                            className={"link-dark"}
                            to={handleComplianceURL()}
                          >
                            <i className="bi-person"></i> &emsp;Compliance
                          </NavLink>
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            setIsTourOpened(true);
                            onClick(() => {
                              setIsTourOpened(true);
                            });
                          }}
                          hidden={false}
                        >
                          <i className="bi bi-signpost"></i>&emsp;Take a tour
                        </Dropdown.Item>

                        {/* <span className="dropdown-header">Contact</span> */}
                        <Dropdown.Item href={`https://docs.poolerapp.com/`}>
                          <a
                            href={`https://docs.poolerapp.com/`}
                            target="_blank"
                            rel="noreferrer"
                            className="link-dark"
                          >
                            <i className="bi-journals"></i> &emsp;API Docs
                            &nbsp;
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <ButtonMailto mailto="mailto:support@poolerapp.com" />
                        </Dropdown.Item>
                        {/* <span className="dropdown-header">deauthenticate</span> */}
                        <Dropdown.Item
                          onClick={() => {
                            localStorage.clear();
                            window.location.replace(
                              `/auth/merchant${PATH_NAMES.signin}`
                            );
                          }}
                        >
                          <i className="bi bi-shield-lock"></i> &emsp;Sign out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="content container" style={{ minHeight: "100vh" }}>
            <div className="row justify-content-lg-center">
              {location.pathname === "/merchant/get_started" ? (
                <div className="col-md-12">
                  <>{children}</>
                </div>
              ) : (
                <div className="col-md-12">
                  <div className="d-sm-block d-md-block d-lg-none d-xl-none">
                    {merchant_data?.business_category === "Unregistered"
                      ? handleUnRegisterdKycAlert()
                      : handleRegisterdKycAlert()}
                  </div>

                  <>{children}</>
                </div>
              )}
            </div>
          </div>

          <div
            className={`footer-get-started d-flex flex-row align-items-center justify-content-center ${
              location.pathname === "/merchant/get_started" ? "py-4" : "py-4"
            }`}
            role="alert"
            style={{ borderRadius: "0px" }}
          >
            <span className="text-center mx-auto">
              Pooler is a financial infrastructure and not a bank. Banking
              services are provided by{" "}
              <span
                className="link link-primary"
                style={{ cursor: "pointer" }}
                onClick={() => setShowPartners(true)}
              >
                our partner banks
              </span>{" "}
              who are duly licensed by the Central Bank of Nigeria.
            </span>
          </div>
        </main>
      </div>
    </>
  );
}

export default Layout;
