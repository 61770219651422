import * as CryptoJS from "crypto-js";
import formatter from "format-number";
import moment from "moment";
import pako from "pako";
import { Currency } from "../utils/enums";

/**
 * Converts an ISO date string to a more readable date format.
 *
 * @param {string} isoString - ISO date string to be formatted.
 * @param {string=} format - Expected format (optional).
 *
 * @return {string} - Formatted date in the format 'Nov 13, 2022'.
 */
export const formatDate = (
  isoString: string,
  format: string | undefined = "MMM DD, YYYY"
): string => {
  return moment(isoString).format(format);
};

/**
 * Converts an ISO date string to a more readable time format.
 *
 * @param {string} isoString - ISO date string to be formatted.
 * @param {string=} format - Expected format (optional).
 *
 * @return {string} - Formatted time, e.g., '4.23 PM'.
 */
export const formatTime = (
  isoString: string,
  format: string = "h:mm A [GMT]Z"
): string => {
  return moment(isoString).format(format);
};

export const formatPhoneNumber = (number: string): string => {
  return `${number.slice(0, 4)} ${number.slice(4, 7)} ${number.slice(7)}`;
};

/**
 * Extracts only the ISO date format of a given date.
 *
 * @param {Date=} date - ISO date to be formatted (optional, defaults to the current date).
 *
 * @return {string} - Extracted date, e.g., '2023-11-13'.
 */
export const extractDate = (date: Date = new Date()): string => {
  return date.toISOString().slice(0, 10);
};

/**
 * Returns a single number with leading zeros.
 *
 * @param {number} num - Number to be padded.
 * @param {number} totalLength - Total length of the number after padding.
 *
 * @return {string} - Number with leading zeros.
 */
export const addLeadingZeros = (num: number, totalLength: number): string => {
  return String(num).padStart(totalLength, "0");
};

/**
 * Formats the amount with a prefix using the provided formatter function.
 *
 * @param {string} prefix - Prefix to be included in the formatted amount. Default: 'NGN'.
 * @param {number} amount - The amount to be formatted.
 *
 * @returns {string} - Formatted amount as a string.
 */
export const formatAmount = (
  amount: number,
  prefix: Currency = Currency.NGN
): string => {
  return formatter({ prefix: `${prefix} `, round: 2 })(amount);
};

/**
 * Formats the amount by add comma as the amount increases.
 *
 * @param {string} value - amount to be formatted.
 * @returns {string} - Formatted amount as a string.
 */
export const formatCurrency = (value: string | any): string | any => {
  if (value !== undefined) {
    // Remove non-numeric characters and leading zeros
    const sanitizedValue = value
      ?.replace(/[^\d.-]/g, "")
      ?.replace(/^0+/, "")
      ?.replace(/--+/g, "");

    // Handle negative numbers
    const isNegative = sanitizedValue.charAt(0) === "-";
    const sign = isNegative ? "-" : "";
    const absoluteValue = isNegative ? sanitizedValue.slice(1) : sanitizedValue;

    // Split into integer and decimal parts
    const [integerPart, decimalPart] = absoluteValue.split(".");

    // Add commas to the integer part
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Concatenate the formatted parts
    let formattedValue = `${sign}${formattedInteger}`;

    // Add decimal part if it exists
    if (decimalPart) {
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue;
  }
};

/**
 * tests password
 * @param password - password to be formatted
 * @returns {boolean} - true if password meets the rules and false if otherwise
 * rule --> password must contain Min of eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
 */
export const validatePassword = (password: string | any) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s]).{8,}$/;
  return passwordRegex.test(password);
};

/**
 * @param text - text to be formatted
 * @returns {boolean} - true if text contains only alphabets and false if other wise
 */
export const validateText = (text: string): boolean => {
  const textRegex = /^[a-zA-Z]+$/;
  return textRegex.test(text);
};

/**
 *@param email - email to be formatted
 *@returns {boolean} - true if email is valid and false if other wise
 */
export const validateEmail = (email: string): boolean => {
  const EmailRegexp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return EmailRegexp.test(email);
};

/**
 * @param {string} text
 * @returns {boolean} - true if text is valid, false otherwise
 */
export const validateAccountNumber = (text: string): boolean => {
  const numberRegex = /^[0-9]+$/;
  return numberRegex.test(text);
};

/**
 * @param {string} url
 * @returns {boolean} - true if url is valid, false if otherwise
 */
export const validateUrl = (url: string): boolean => {
  var urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
  return urlPattern.test(url);
};

/**
 * @param {string} - textOrUrl
 * @returns {boolean}
 */
export function copyToClipboard(textOrUrl: string): boolean {
  const textField = document.createElement("textarea");
  textField.value = textOrUrl;
  document.body.appendChild(textField);
  textField.select();

  try {
    const success = document.execCommand("copy");
    document.body.removeChild(textField);
    return success;
  } catch (error) {
    document.body.removeChild(textField);
    return false;
  }
}

export const joinClasses = (...classes: (string | false | undefined)[]) =>
  classes
    .filter((c) => !!c)
    .join(" ")
    .replace(/ +/g, " ");

export const getBorderColor = (color: string, opacity?: number) => {
  // Calculate the border color based on the text color
  const borderColorOpacity = opacity || 0.2;
  const colorHex = color.startsWith("#") ? color : `#${color}`;
  const rgbColor = colorHex
    .match(/[A-Za-z0-9]{2}/g)
    ?.map((hex) => parseInt(hex, 16));
  if (rgbColor && rgbColor.length === 3) {
    const [r, g, b] = rgbColor;
    return `rgba(${r}, ${g}, ${b}, ${borderColorOpacity})`;
  }
  return "";
};

export const getBackgroundColor = (color: string, opacity?: number) => {
  // Calculate the background color based on the text color
  const backgroundColorOpacity = opacity || 0.05;
  const colorHex = color.startsWith("#") ? color : `#${color}`;
  const rgbColor = colorHex
    .match(/[A-Za-z0-9]{2}/g)
    ?.map((hex) => parseInt(hex, 16));
  if (rgbColor && rgbColor.length === 3) {
    const [r, g, b] = rgbColor;
    return `rgba(${r}, ${g}, ${b}, ${backgroundColorOpacity})`;
  }
  return "";
};

/**
 * returns string
 * @returns {string}
 */
export const generateUniqueId = (): string => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2);
  return `${timestamp}${random}`;
};

// ENCRYPT SIGNATURE FOR PUSHER
export const hmacSHA512 = (message: string, key: string): string | any => {
  if (message !== undefined && key !== undefined) {
    const hash = CryptoJS.HmacSHA512(message, key);
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    return hashHex;
  }
};

export const formatAmountWithDecimal = (value: string): string | any => {
  if (value !== undefined) {
    const sanitizedValue = value
      ?.replace(/[^\d.-]/g, "")
      ?.replace(/^0+/, "")
      ?.replace(/--+/g, "");
    const isNegative = sanitizedValue.charAt(0) === "-";
    const sign = isNegative ? "-" : "";
    const absoluteValue = isNegative ? sanitizedValue.slice(1) : sanitizedValue;
    const [integerPart, decimalPart] = absoluteValue.split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let formattedValue = `${sign}${formattedInteger}`;
    if (decimalPart) {
      formattedValue += `.${decimalPart}`;
    }
    return formattedValue;
  }
};

/**
 * @param {string} - input string
 * @param {number} - maximum length
 * returns string
 * @returns {string}
 */
export const shortenString = (inputString: string, maxLength: number) => {
  if (inputString?.length <= maxLength) {
    return inputString;
  } else {
    const shortenedString = inputString?.slice(0, maxLength - 3) + "...";
    return shortenedString;
  }
};

/**
 * @param {Array} --> array of fields
 *
 * @returns {boolean}
 */

export const isFieldArrayValid = (arr: any) => {
  return arr.every(
    (item: any) => item.fieldName !== "" && item.fieldValue !== ""
  );
};

export const checkCloudinary = (urlString: string) => {
  if (urlString !== undefined && urlString.includes(".pdf")) {
    const modifiedString = urlString.replace(".pdf", ".png");
    return modifiedString;
  } else {
    return urlString;
  }
};

export const filterEmptyValues = (payload: any) => {
  const filteredPayload: any = {};

  for (const key in payload) {
    if (payload.hasOwnProperty(key) && payload[key] !== "") {
      filteredPayload[key] = payload[key];
    }
  }

  return filteredPayload;
};

export const removePathSegments = (originalURL: string) => {
  var url = new URL(originalURL);
  var pathSegments = url.pathname.split("/");

  return pathSegments;
};

const host = window.location.host;
export const mode =
  host.includes("localhost") ||
  host.includes("netlify") ||
  host.includes("app.swim")
    ? "internal.test"
    : "customer.test";

export const signatureHarsh: any =
  host.includes("localhost") ||
  host.includes("netlify") ||
  host.includes("app.swim")
    ? process.env.REACT_APP_POOLER_KYC_SIGNATURE_ENCRYPTION_KEY_INTERNAL_ENV
    : process.env.REACT_APP_POOLER_KYC_SIGNATURE_ENCRYPTION_KEY_CUSTOMER_ENV;

export function capitalizeString(str: string) {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const output = arr.join(" ");
  return output;
}

// ECRYPTION FOR KYC
export const encryptKyc = (message: string, key: string): string | any => {
  if (message !== undefined && key !== undefined) {
    const hash = CryptoJS.HmacSHA256(message, key);
    const hashHex = hash.toString(CryptoJS.enc.Hex);

    return hashHex;
  }
};

export const MaskNum = (str: string = "") => str.replace(/\d(?=\d{7})/g, "*");

// SPLITS AWS STRING
export const splitAwsString = (url: string | any) => {
  if (url) {
    const parts = url.split("/");
    const identifier = parts[parts.length - 1];
    return identifier;
  }
};

// NORMALIZEFILE NAME
export const normalizeFileName = (filename: any) => {
  /**
   * This function normalizes file name that contains special characters into underscores "_"
   * This is done because the existent of some special characters could make the file being upload to s3 inaccessible
   */
  return encodeURIComponent(filename.replace(/[- )(]/g, "_"));
};

export const isEmptyObject = (obj: any) => {
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      if (!isEmptyObject(obj[key])) {
        return false;
      }
    } else if (typeof obj[key] === "string" && obj[key].trim() !== "") {
      return false;
    }
  }
  return true;
};

export const convertToBase64 = (file: any) => {
  if (file !== undefined) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader?.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
};

// Function to compress a file using pako.deflate
export const compressFile = async (file: File): Promise<File> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      if (event.target?.result instanceof ArrayBuffer) {
        // Compress the data using pako.deflate
        const compressedData = pako.deflate(
          new Uint8Array(event.target.result)
        );

        // Create a compressed Blob
        const compressedBlob = new Blob([compressedData], {
          type: file.type,
        });

        const base64: any = await convertToBase64(compressedBlob);

        // Create a compressed File
        const compressedFile = new File([base64], file.name, {
          type: file.type,
        });

        // Resolve with the compressed File
        resolve(compressedFile);
      }
    };

    // Read the file as ArrayBuffer
    reader.readAsArrayBuffer(file);
  });
};

export const formatDateTimeWithAMPM = (
  dateTimeString: string | any
): { formattedDate: string; formattedTime: string } => {
  var dateObject = new Date(dateTimeString);

  var year = dateObject.getFullYear();
  var month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  var day = dateObject.getDate().toString().padStart(2, "0");

  var hours = dateObject.getHours();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format
  var minutes = dateObject.getMinutes().toString().padStart(2, "0");
  var seconds = dateObject.getSeconds().toString().padStart(2, "0");

  var formattedDate = `${year}-${month}-${day}`;
  var formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`;

  return { formattedDate, formattedTime };
};

export function formatDateString(inputDateString: string): string {
  // Define months array
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Use regular expression to extract day, month, and year
  var match = inputDateString.match(
    /(\d+).*?(\w+).*?(\d{4}), (\d+:\d+:\d+ [APMapm]+)$/
  );

  if (!match) {
    // Handle invalid input
    return "Invalid Date";
  }

  var day: any = match[1];
  var monthStr = match[2];
  var year = match[3];

  // Convert month string to index
  var monthIndex = months.indexOf(monthStr);

  if (monthIndex === -1) {
    // Handle invalid month
    return "Invalid Date";
  }

  // Format the date in the desired format
  var formattedDate =
    (day < 10 ? 0 : "") + day + "-" + months[monthIndex] + "-" + year;

  // Return the formatted date
  return formattedDate;
}

export function convertToInternationalFormat(nationalNumber: string): string {
  // Remove any non-numeric characters
  const cleanedNumber = nationalNumber.replace(/\D/g, "");

  // Check if the number starts with a leading zero
  if (cleanedNumber.startsWith("0")) {
    // Replace the leading zero with the international code
    return `234${cleanedNumber.slice(1)}`;
  } else {
    // If there's no leading zero, assume it's already in international format
    return `234${cleanedNumber}`;
  }
}

export const isValidNigeriaPhoneNumber = (phoneNumbers: string[]): boolean => {
  const nigeriaNationalRegex = /^(0|\+234|234)([789]\d{9})$/; // Updated national format regex
  const nigeriaInternationalRegex = /^\+234([789]\d{9})$/; // International format regex

  for (const phoneNumber of phoneNumbers) {
    if (
      !(
        nigeriaNationalRegex.test(phoneNumber) ||
        nigeriaInternationalRegex.test(phoneNumber)
      )
    ) {
      // If the phone number does not match either national or international format
      return false;
    }
  }

  // All phone numbers are valid
  return true;
};

export const truncate = (str: string, num: number = 20) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const airtime_purchase_id_map: Record<string, string> = {
  "MFIN-5-OR": "MTN",
  "MFIN-1-OR": "Airtel",
  "MFIN-6-OR": "Globacom",
  "MFIN-2-OR": "9mobile",
};

export function calculateTotalAmount(data: any[], variableName: string) {
  // Use the reduce function to iterate over the array and accumulate the total
  const totalAmount = data?.reduce((acc: any, obj: any) => {
    // Check if the variableName exists in the current object
    if (obj?.hasOwnProperty(variableName)) {
      // Add the value of the variable to the accumulator
      acc += parseFloat(obj?.[variableName]) || 0; // Convert to float and handle NaN
    }
    return acc;
  }, 0);

  return totalAmount;
}

export function paginateData(
  data: any[],
  pageNumber: number,
  pageSize: number
) {
  const startIndex = pageNumber * pageSize;
  const endIndex = startIndex + pageSize;

  // Slice the data based on the calculated start and end indices
  const paginatedData = data.slice(startIndex, endIndex);

  return paginatedData;
}

export const base64ToBlob = (base64String: any) =>
  fetch(base64String).then((res) => res.blob());
