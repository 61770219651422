import React from "react";
import { formprops } from "../../../utils/interfaces";
import {
  BUISNESS_CATEGORY,
  BUISNESS_TYPE,
  COUNTRIES,
  NO_OF_DIRECTORS,
  USE_CASE,
} from "../../../utils/data";

function BuisnessDetails({ formValues, handleChange, data }: formprops) {
  return (
    <div
      className="w-100 content-space-t-0 content-space-t-lg-2 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="firstname">
            Business category
          </label>
          <select
            className="form-select  form-control  border border-secondary"
            id="validationValidSelect1"
            value={formValues?.buisness_category}
            name="buisness_category"
            onChange={handleChange}
          >
            <option>Select category</option>
            {BUISNESS_CATEGORY.map((i: string) => {
              return (
                <option key={i} value={i}>
                  {i}
                </option>
              );
            })}
          </select>
          <span className="invalid-feedback">Please select a category</span>
        </div>
        <div className="col">
          <label className="form-label" htmlFor="signinSrEmail">
            Business name
          </label>
          <input
            type="text"
            className="form-control  border border-secondary"
            name="buisness_name"
            id="buisness_name"
            tabIndex={1}
            value={formValues?.buisness_name}
            placeholder="business name"
            aria-label="buisness_name"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please enter business name.</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="signinSrEmail">
            RC number *
          </label>
          <input
            type="text"
            className="form-control  border border-secondary"
            name="rc_no"
            id="rc_no"
            tabIndex={1}
            value={formValues?.rc_no}
            placeholder="RC number"
            aria-label="rc_no"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please enter a RC number.</span>
        </div>
        <div className="col">
          <label className="form-label" htmlFor="firstname">
            Business type
          </label>
          <select
            className="form-select  form-control  border border-secondary"
            id="validationValidSelect1"
            name="buisness_type"
            value={formValues?.buisness_type}
            onChange={handleChange}
          >
            <option>Select category</option>
            {BUISNESS_TYPE.map((i: string) => {
              return (
                <option key={i} value={i}>
                  {i}
                </option>
              );
            })}
          </select>
          <span className="invalid-feedback">Please select a business type</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="website_url">
            Company’s website *
          </label>
          <input
            type={"url"}
            className="form-control  border border-secondary"
            name="website_url"
            id="website_url"
            tabIndex={1}
            value={formValues?.website_url}
            placeholder="company website"
            aria-label="website_url"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please enter a business.</span>
        </div>
        <div className="col">
          <label className="form-label" htmlFor="firstname">
            Country *
          </label>
          <select
            className="form-select  form-control  border border-secondary"
            id="validationValidSelect1"
            name="country"
            value={formValues?.country}
            onChange={handleChange}
          >
            <option>Select category</option>
            {COUNTRIES.map((i: any) => {
              return (
                <option key={i?.code} value={i?.name}>
                  {i?.name}
                </option>
              );
            })}
          </select>
          <span className="invalid-feedback">Please select a country</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="signinSrEmail">
            Describe what your business does *
          </label>
          <input
            type="text"
            className="form-control  border border-secondary"
            name="buisness_nature"
            id="buisness_nature"
            tabIndex={1}
            value={formValues?.buisness_nature}
            placeholder="describe what your business does"
            aria-label="buisness_nature"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">
            Please enter what your business does
          </span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="api_uses">
            Describe what the APIs will do for you *
          </label>
          <input
            type="text"
            className="form-control  border border-secondary"
            name="api_uses"
            id="api_uses"
            tabIndex={1}
            value={formValues?.api_uses}
            placeholder="description"
            aria-label="api_uses"
            onChange={handleChange}
            required
          />
          {/* <span className="invalid-feedback">Please enter a buissness.</span> */}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="firstname">
            Use case *
          </label>
          <select
            className="form-select  form-control  border border-secondary"
            id="validationValidSelect1"
            name="buisness_use_case"
            value={formValues?.buisness_use_case}
            onChange={handleChange}
          >
            <option>Select category</option>
            {USE_CASE.map((i: string) => {
              return (
                <option key={i} value={i}>
                  {i}
                </option>
              );
            })}
          </select>
          <span className="invalid-feedback">Please select a category</span>
        </div>
        <div className="col">
          <label className="form-label" htmlFor="firstname">
            No of directors *
          </label>
          <select
            className="form-select  form-control  border border-secondary"
            id="validationValidSelect1"
            name="number_of_directors"
            value={formValues?.number_of_directors}
            onChange={handleChange}
          >
            <option>Select category</option>
            {NO_OF_DIRECTORS.map((i: string) => {
              return (
                <option key={i} value={i}>
                  {i}
                </option>
              );
            })}
          </select>
          <span className="invalid-feedback">Please select an option</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="user_interactions">
            Describe how your users will interact with the services *
          </label>
          <input
            type="text"
            className="form-control  border border-secondary"
            name="user_interactions"
            id="user_interactions"
            tabIndex={1}
            value={formValues?.user_interactions}
            placeholder="Describe how your users will interact with the services"
            aria-label="user_interactions"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback"></span>
        </div>
      </div>
    </div>
  );
}

export default BuisnessDetails;
