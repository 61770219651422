import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { ReactComponent as SuccessIcon } from "../../assets/success-icon.svg";
import SpinnerComponent from "../../component/spinner/Spinner";
import { useCreateBeneficiary } from "../../hooks/top-up/index.hook";

const SaveBeneficiaryModal = ({
  show,
  handleClose,
  beneficiary,
  size,
}: {
  size: any;
  show: boolean;
  handleClose: () => void;
  beneficiary: any;
}) => {
  const queryClient = useQueryClient();
  const { resolve_create_beneficiary } = useCreateBeneficiary();
  const [saved, setSaved] = useState(false);
  const [formData, setFormData] = React.useState({
    mobile_number: beneficiary?.mobile_number?.replace("234", "0"),
    name: "",
    telco: beneficiary.network_provider,
    type: beneficiary.type,
    purchase_id: beneficiary.purchase_id,
  });

  const handleSaveBeneficiary = async () => {
    try {
      const res = await resolve_create_beneficiary.mutateAsync(formData);
      setFormData({
        mobile_number: "",
        name: "",
        telco: "",
        type: "",
        purchase_id: "",
      });
      queryClient.invalidateQueries({
        queryKey: ["airtime", "Beneficiaries", 0],
      });
      setSaved(true);
    } catch (e) {
      return e;
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered  contentClassName="top-up-modal">
      {!saved && (
        <Modal.Header closeButton>
          <Modal.Title>Saved beneficiaries</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        {saved ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="text-black"
              style={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Beneficiary added successfully!
            </div>
            <div
              style={{
                display: "flex",
                width: "60px",
                height: "60px",
                padding: "14px",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
                borderRadius: "48px",
                background: "#E0FAE1",
                marginBottom: "26px",
                marginTop: "52px",
              }}
            >
              <SuccessIcon />
            </div>
            <div
              className="w-100"
              style={{
                marginBottom: "2px",
                marginTop: "14px",
              }}
            >
              <button
                type={"button"}
                className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                disabled={false}
                onClick={() => {
                  setSaved(false);
                  handleClose();
                }}
                style={{
                  borderRadius: "8px",
                  background: "#2C1DFF",
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4 overflow-hidden d-flex flex-column">
              <label className="form-label fw-semibold" htmlFor="mobile_number">
                Phone number*
              </label>
              <div className="d-flex w-100">
                <select
                  className="form-select"
                  style={{
                    border: "1px solid #CBD1EC",
                    borderRadius: "8px",
                    height: "42px",
                    width: "27%",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  // Add proper id, name, value, and onChange handler
                >
                  <option>+234</option>
                </select>
                <input
                  className="w-100 fw-normal"
                  id="mobile_number"
                  value={formData.mobile_number}
                  onChange={(e) =>
                    setFormData({ ...formData, mobile_number: e.target.value })
                  }
                  disabled
                  style={{
                    height: "42px",
                    border: "1px solid #CBD1EC",
                    borderRadius: "8px",
                    borderLeft: "none",
                    padding: "8px",
                    fontSize: "16px",
                    color: "#000000",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    lineHeight: "24px",
                    letterSpacing: "-0.16px",
                  }}
                />
              </div>
            </div>
            <div className="mb-4  d-flex w-100 flex-column">
              <label className="form-label fw-semibold" htmlFor="mobile_number">
                Beneficiary name
              </label>
              <input
                className="w-100 fw-normal"
                id="name"
                value={formData.name}
                placeholder="Beneficiary name"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                style={{
                  height: "42px",
                  border: "1px solid #CBD1EC",
                  borderRadius: "8px",
                  padding: "8px",
                  fontSize: "16px",
                  color: "#000000",
                  lineHeight: "24px",
                  letterSpacing: "-0.16px",
                }}
              />
            </div>
            <div
              className="w-100"
              style={{
                marginBottom: "2px",
                marginTop: "14px",
              }}
            >
              <button
                type={"button"}
                className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                onClick={() => {
                  handleSaveBeneficiary();
                }}
                disabled={
                  resolve_create_beneficiary.isLoading ||
                  !formData.name ||
                  !formData.mobile_number
                }
                style={{
                  borderRadius: "8px",
                  background: "#2C1DFF",
                }}
              >
                Add to beneficiary&emsp;
                <span className="align-middle ml-2">
                  {resolve_create_beneficiary.isLoading && (
                    <SpinnerComponent size="sm" />
                  )}
                </span>
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SaveBeneficiaryModal;
