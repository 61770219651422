import React, { useEffect } from "react";
import { useRenderHTML } from "../../hooks/auth/auth.hook";
import { useQueryClient } from "react-query";
import API_SERVICE from "../../services/services";

function ScrollableModal() {
  const queryClient = useQueryClient();
  const { data } = useRenderHTML();
  const htmlContent = data?.data;

  useEffect(() => {
    queryClient.fetchQuery(["t&c"], () => API_SERVICE._renderHTML());
  }, [queryClient]);

  return (
    <div
      className="modal fade"
      id="exampleModalScrollable"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalScrollableTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5
              className="modal-title text-primary"
              id="exampleModalScrollableTitle"
            >
              POOLER TERMS OF SERVICE
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div> */}
          <div className="modal-body">
            <div
              dangerouslySetInnerHTML={{
                __html: htmlContent,
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScrollableModal;
