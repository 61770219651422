import React from "react";
import { Helmet } from "react-helmet-async";
export default function SEO({ title, description, type }) {
  const image =
    "https://res.cloudinary.com/woodcore/image/upload/v1689937192/Pooler_banner_1_vbr6u1.png";
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={image} />
      <meta content="1200" property="og:image:width" />
      <meta content="630" property="og:image:height" />

      <meta content="https://poolerapp.com/" property="og:url" />
      <meta
        content="https://www.instagram.com/poolerapp"
        property="og:see_also"
      />
      <meta
        content="https://www.linkedin.com/company/poolerapp/"
        property="og:see_also"
      />

      {/* End standard metadata tags */}
      {/* Facebook tags */}

      <meta content="https://poolerapp.com/" property="og:url" />
      <meta
        name="keywords"
        content="payments, africa, nigeria, ghana, fintech, tech in africa, ussd, mobile money, pos, pooler, woodocore"
      />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="Pooler - Seamless Collection For Merchants By Transfer!"
      />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta content="https://twitter.com/poolerapp" property="og:see_also" />
      <meta name="twitter:creator" content="@poolerapp" />
      <meta name="twitter:card" content="@poolerapp" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image:src" content={image} />

      <meta
        name="twitter:description"
        content="Pooler - Seamless Collection For Merchants By Transfer!"
      />
      {/* End Twitter tags */}
    </Helmet>
  );
}
