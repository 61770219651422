import React from "react";
import { authLayoutProps } from "../../utils/interfaces";
import AuthIllustration from "../../assets/svg/logos/Pooler-sign-up-image.png";
import PoolerIpad from "../../assets/svg/logos/ipad-pooler.svg";
import PoolerLogoWhite from "../../assets/svg/logos/Pooler_logo_white.svg";

function AuthLayout({ children }: authLayoutProps) {
  return (
    <div className="row">
      <div
        style={{ backgroundImage: `url(${AuthIllustration})` }}
        className="col-lg-6 overflow-hidden d-none --bg-auth d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
      >
        <div id="banner-conc">
          <img src={PoolerLogoWhite} alt="pooler-logo" />
          <h1>
            The Easier Way to
            <br />
            Receive Payments.
          </h1>
          <p>Experience world-class banking-as-a-service</p>
        </div>
        <img src={PoolerIpad} alt="pooler-ipad" />
      </div>

      <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
        <div
          className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
          style={{ maxWidth: "25rem" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
