import React, { useState } from "react";
import AuthLayout from "../../../component/auth-layout/AuthLayout";
import { PATH_NAMES } from "../../../constants/index.constant";
import { useSetupCashier } from "../../../hooks/cashier/index.hook";
import { ISetupCashier } from "../../../utils/interfaces";
import SpinnerComponent from "../../../component/spinner/Spinner";
import { useLocation, useNavigate } from "react-router-dom";

export default function CashierSetup() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setupCashier } = useSetupCashier();
  const queryParams = new URLSearchParams(location?.search);
  const queryString: string | any = queryParams.get("invite_code");
  const [formData, setFormData] = useState<any>({
    email: "",
    password: "",
    invite_code: queryString !== "" ? queryString : "",
  });
  const [error, setError] = useState<any>({
    email: false,
    password: false,
    invite_code: false,
  });

  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleVisibility = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const ValidateBeforeSubmit = () => {
    let isValid = true;
    if (formData.email.trim() === "") {
      setError((prev: any) => ({ ...prev, email: true }));
      isValid = false;
    }
    if (formData.password.trim() === "") {
      setError((prev: any) => ({ ...prev, password: true }));
      isValid = false;
    }
    if (formData.invite_code.trim() === "") {
      setError((prev: any) => ({ ...prev, invite_code: true }));
      isValid = false;
    }
    if (isValid) {
      onSubmit(formData);
    }
  };

  const onSubmit = async (payload: ISetupCashier) => {
    setIsLoading(true);
    try {
      const response = await setupCashier.mutateAsync(payload);
      if (response.status === 200) {
        
        navigate(`/auth${PATH_NAMES.verify_otp}?action=complete2fa`, {
          state: {
            account_no: response?.data?.data?.account_no,
            QR_CODE: response?.data?.data?.authImage,
          },
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };



  return (
    <AuthLayout>
      <form
        className="js-validate needs-validation"
        noValidate
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
      >
        <div className="text-center">
          <div className="mb-5">
            <h1 className="display-5">Set up your Account</h1>
          </div>
        </div>

        <div className="mb-4">
          <label className="form-label" htmlFor="email">
            Your email
          </label>
          <input
            type="email"
            className={`form-control form-control-lg ${
              error.email ? "is-invalid" : ""
            } ${!error.email && formData?.email !== "" ? "is-valid" : ""}`}
            name="email"
            id="email"
            tabIndex={1}
            value={formData?.email}
            placeholder="email@address.com"
            aria-label="email@address.com"
            onChange={handleChange}
            autoComplete="off"
            required
          />
          <span className="invalid-feedback">
            Please enter a valid email address.
          </span>
        </div>

        <div className="mb-4">
          <label className="form-label" htmlFor="email">
            Invite Code
          </label>
          <input
            type="email"
            className={`form-control form-control-lg ${
              error.invite_code ? "is-invalid" : ""
            } ${
              !error.invite_code &&
              formData?.invite_code !== "" &&
              formData?.invite_code !== null &&
              formData?.invite_code !== undefined
                ? "is-valid"
                : ""
            }`}
            name="invite_code"
            id="invite_code"
            tabIndex={1}
            value={formData?.invite_code}
            placeholder="invite code"
            aria-label="invite_code"
            onChange={handleChange}
            autoComplete="off"
            required
          />
          <span className="invalid-feedback">Please enter a invite code.</span>
        </div>

        <div className="mb-4">
          <label className="form-label w-100" htmlFor="password" tabIndex={0}>
            <span className="d-flex justify-content-between align-items-center">
              <span>Password</span>
            </span>
          </label>

          <div
            className="input-group input-group-merge"
            data-hs-validation-validate-class
          >
            <input
              className={`js-toggle-password form-control form-control-lg ${
                error.password ? "is-invalid" : ""
              } ${
                !error.password && formData?.password !== "" ? "is-valid" : ""
              }`}
              type={passwordOpen ? "text" : "password"}
              name="password"
              id="password"
              placeholder="8+ characters required"
              aria-label="8+ characters required"
              required
              minLength={8}
              onChange={handleChange}
              value={formData?.password}
              autoComplete="off"
            />
            <button
              id="changePassTarget"
              className="input-group-append input-group-text"
            >
              {!eyeOpen ? (
                <i
                  id="changePassIcon"
                  className="bi-eye"
                  onClick={() => {
                    toggleVisibility();
                    setEyeOpen(!eyeOpen);
                  }}
                />
              ) : (
                <i
                  className="bi bi-eye-slash"
                  onClick={() => {
                    toggleVisibility();
                    setEyeOpen(!eyeOpen);
                  }}
                />
              )}
            </button>
          </div>

          {error.password && (
            <span className="invalid-feedback">
              Please enter a valid password.
            </span>
          )}
        </div>
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => ValidateBeforeSubmit()}
          >
            Submit{" "}
            <span className="align-middle">
              {isLoading && (
                <>
                  &nbsp;
                  <SpinnerComponent size="sm" />
                </>
              )}
            </span>
          </button>
        </div>
      </form>
    </AuthLayout>
  );
}
