import { Route, Routes, Navigate } from "react-router-dom";
import Admin from "../../pages/admin";
import Layout from "../../component/layout/Layout";
import { PATH_NAMES, TOKEN_STRING } from "../../constants/index.constant";
import Transactions from "../../pages/transactions/Transactions";
import TransactionDetails from "../../pages/transactions/TransactionDetails";
import Merchants from "../../pages/admin/merchants";
import MerchantDetails from "../../pages/admin/merchants/MerchantDetails";

export default function SuperAdminRoutes() {
  const token = window.localStorage.getItem(TOKEN_STRING);

  if (token === null || token === undefined) {
    return <Navigate to={`/auth/admin/signin`} replace={true} />;
  }

  return (
    <Layout>
      <Routes>
        <Route path={PATH_NAMES.admin_home} element={<Admin />} />
        <Route path={PATH_NAMES.merchants} element={<Merchants />} />
        <Route
          path={PATH_NAMES.merchantDetails}
          element={<MerchantDetails />}
        />
        <Route path={PATH_NAMES.transactions} element={<Transactions />} />
        <Route
          path={PATH_NAMES.transactionDetails}
          element={<TransactionDetails />}
        />
      </Routes>
    </Layout>
  );
}
