import React, { useState } from "react";
import DragAndDrop from "../../../component/drag-and-drop/DragAndDrop";
import axios from "axios";
import { formprops } from "../../../utils/interfaces";
import { checkCloudinary } from "../../../helpers/index.helper";

function BuisnessDocuments({
  formValues,
  handleChange,
  onObjectExport,
  onProofExport,
  onMoaexport,
}: formprops): JSX.Element {
  const [licenceImage, setLicenceImage] = useState<any>({});
  const [poaImage, setPoaimage] = useState<any>({});
  const [moaImage, setMoaimage] = useState<any>({});

  const preset: any = process.env.REACT_APP_CLOUDINARY_PRESET;
  const cloud_name: any = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const cloud_api: any = process.env.REACT_APP_CLOUDINARY_API_URL;

  // ${cloud_api}/${cloud_name}/image/upload

  const handleExportLicenceImage = async (file: any) => {
    setLicenceImage(file);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", preset);
      await axios
        .post(`${cloud_api}/${cloud_name}/auto/upload`, formData)
        .then((response) => {
          const urlString: string | any =
            response?.data?.secure_url || response?.data?.url;
          const observeString = checkCloudinary(urlString);
          const licence = {
            licence_image: observeString,
          };
          onObjectExport(licence);
        })
        .catch((error: any) => {
          return error?.response;
        });
    }
  };

  const handleExportPoaImage = async (file: any) => {
    setPoaimage(file);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", preset);
      await axios
        .post(`${cloud_api}/${cloud_name}/auto/upload`, formData)
        .then((response) => {
          const urlString: string | any =
            response?.data?.secure_url || response?.data?.url;
          const observeString = checkCloudinary(urlString);
          const licence = {
            proof_of_address: observeString,
          };
          onProofExport(licence);
        })
        .catch((error) => {
          return error?.response;
        });
    }
  };

  const handleExportMoaImage = async (file: any) => {
    setMoaimage(file);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", preset);
      await axios
        .post(`${cloud_api}/${cloud_name}/auto/upload`, formData)
        .then((response) => {
          const urlString: string | any =
            response?.data?.secure_url || response?.data?.url;
          const observeString = checkCloudinary(urlString);
          const licence = {
            moa: observeString,
          };
          onMoaexport(licence);
        })
        .catch((error) => {
          return error?.response;
        });
    }
  };

  return (
    <div
      className="w-100 content-space-t-0 content-space-t-lg-2 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="signinSrEmail">
            List the operational licenses/certifications you have *
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            name="operational_licence"
            id="operational_licence"
            tabIndex={1}
            value={formValues?.operational_licence}
            placeholder="List the operational licenses/certifications you have"
            aria-label="operational_licence"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please list your licenses.</span>
        </div>
      </div>

      <div className="row mb-4">
        <label className="form-label" htmlFor="licence">
          Please provide the licenses you might have *
        </label>
        <div
          className={`col max-auto w-100 ${
            formValues?.licence ? "d-flex" : ""
          }`}
        >
          {formValues?.licence ? (
            <img
              src={formValues?.licence}
              alt="licence"
              className="w-75 mx-auto py-4"
            />
          ) : (
            <DragAndDrop
              onFileExport={handleExportLicenceImage}
              fileName={licenceImage?.name}
              componentId="licence"
            />
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="address">
            Business address *
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            name="address"
            id="address"
            tabIndex={1}
            value={formValues?.address}
            placeholder="business address"
            aria-label="address"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please enter an address.</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="signinSrEmail">
            CAC
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            name="cac"
            id="cac"
            tabIndex={1}
            value={formValues?.cac}
            placeholder="cac"
            aria-label="cac"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please enter a CAC.</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="d-none d-md-block d-lg-block text-start text-left">
          <label className="form-label" htmlFor="poa">
            Upload proof of address *
          </label>
        </div>
        <div className="d-sm-block d-md-none d-lg-none text-center mx-auto">
          <label className="form-label text-center mx-auto" htmlFor="poa">
            Upload proof of address *
          </label>
        </div>
        <div
          className={`col max-auto w-100 ${
            formValues?.proof_of_address ? "d-flex" : ""
          }`}
        >
          {formValues?.proof_of_address ? (
            <img
              src={formValues?.proof_of_address}
              alt="proof_of_address"
              className="w-75 mx-auto py-4"
            />
          ) : (
            <DragAndDrop
              onFileExport={handleExportPoaImage}
              fileName={poaImage?.name}
              componentId="poa"
            />
          )}
        </div>
      </div>

      <div className="row mb-4">
        {/* MD && LG SCREENS ONLY */}
        <div className="d-none d-md-block d-lg-block text-start text-left">
          <label className="form-label" htmlFor="poa">
            Memorandum of article *
          </label>
        </div>
        {/* SM SCREENS ONLY */}
        <div className="d-sm-block d-md-none d-lg-none text-center mx-auto">
          <label className="form-label text-center mx-auto" htmlFor="poa">
            Memorandum of article *
          </label>
        </div>

        <div
          className={`col max-auto w-100 ${formValues?.moa ? "d-flex" : ""}`}
        >
          {formValues?.moa ? (
            <img
              src={formValues?.moa}
              alt="memorandum of article"
              className="w-75 mx-auto py-4"
            />
          ) : (
            <DragAndDrop
              onFileExport={handleExportMoaImage}
              fileName={moaImage?.name}
              componentId="moa"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BuisnessDocuments;
