import moengage from "@moengage/web-sdk";
import React, { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { formatCurrency } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  useGetPayoutTransactions,
  useInitiatePayout,
} from "../../hooks/payouts/payouts.hook";
import { useResolveOTPForPin } from "../../hooks/transfer/index.hook";
import { sessionInterface } from "../../utils/interfaces";
import OtpFields from "../otp/OtpFields";
import SpinnerComponent from "../spinner/Spinner";
import { FailedMessage, ProcessMessage } from "./Notice";
import PinSetup from "./PinSetup";

function InitiatePayout() {
  const queryClient = useQueryClient();
  const { deviceInfo } = useUserDeviceInfo();
  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const closeRef: any = useRef<any>(null);
  const { initiate_payout } = useInitiatePayout();
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stage, setStage] = useState(1);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const { refetch } = useGetPayoutTransactions(0);
  const { data } = useSession();
  const { resolve_otp_pin } = useResolveOTPForPin();
  const sendOTP = async () => {
    try {
      await resolve_otp_pin.mutateAsync();
    } catch (error) {
      return error;
    }
  };
  const merchant_data: sessionInterface = data?.data?.data;
  const two_fa_enabled: boolean | undefined | any =
    merchant_data?.state?.two_fa_enabled;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currency = formatCurrency(event.target.value);
    setAmount(currency);
    setValue(event.target.value);
  };

  const handleInitiatePayout = (payload: {
    amount: number;
    auth_code?: string;
  }) => {
    let req: any = { amount: payload.amount, transaction_pin: pin };
    if (payload.auth_code) {
      req.auth_code = payload.auth_code;
    }
    setIsLoading(true);
    initiate_payout
      .mutateAsync(req)
      .then(() => {
        setOtp("");
        toast.success("Operation is accepted", { position: "top-right" });
        // moengage.track_event("INITIATE_PAYOUT", {
        //   amount: payload.amount,
        //   success_status: true,
        //   platform: deviceInfo?.userAgent,
        // });
        setStage(3);
        setPin("");
        setAmount("");
        refetch();
      })
      .catch((e: any) => {
        if (e?.response?.status !== 403) {
          setStage(4);
        }
        setPin("");
        toast.error(e?.response?.data?.data);
        setMessage(e?.response?.data?.data);
        setIsLoading(false);
      })
      .finally(() => {
        setOtp("");
        setIsLoading(false);
        queryClient.refetchQueries();
        setAmount("");
      });
  };

  const handleOtpChange = (otp: any) => {
    if (otp.length === 6) {
      setReadOnly(true);
      setOtp(otp);
    }
  };

  return (
    <div
      className="modal fade"
      id="createAKIKeyModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="createAKIKeyModalLabel"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        {stage === 3 ? (
          <div className="modal-content pb-5 px-5 pt-2">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={() => {
                  setIsLoading(false);
                  setShow(false);
                  setPin("");
                  setStage(1);
                  setReadOnly(false);
                  setAmount("");
                  setOtp("");
                }}
              ></button>
            </div>
            <ProcessMessage
              counts={false}
              handleReset={() => setStage(1)}
              handleClose={() => {
                buttonRef.current?.click();
                closeRef?.current?.click();
              }}
              link={"/merchant/business/payouts"}
              text="payout"
            />
          </div>
        ) : stage === 4 ? (
          <div className="modal-content pb-5 px-5 pt-2">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={() => {
                  setIsLoading(false);
                  setShow(false);
                  setPin("");
                  setStage(1);
                  setReadOnly(false);
                  setAmount("");
                  setOtp("");
                }}
              ></button>
            </div>
            <FailedMessage
              handleReset={() => setStage(1)}
              message={message}
              text="Payout"
            />
          </div>
        ) : (
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-cap"
                style={{ marginBottom: "0" }}
                id="createAKIKeyModalLabel"
              >
                {!show ? "Initiate Payout" : ""}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={() => {
                  setIsLoading(false);
                  setShow(false);
                  setPin("");
                  setStage(1);
                  setReadOnly(false);
                  setAmount("");
                  setOtp("");
                }}
              ></button>
            </div>
            {stage === 1 ? (
              <div className="modal-body">
                <div className="row gx-2 gx-sm-3">
                  {!show && (
                    <>
                      <label
                        className="form-label card-subtitle"
                        htmlFor="amount"
                      >
                        Amount
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3 border border-secondary"
                        placeholder="How much?"
                        name="amount"
                        value={amount}
                        onChange={handleChange}
                      />
                    </>
                  )}
                  <PinSetup
                    pin={pin}
                    setPin={setPin}
                    sendOTP={sendOTP}
                    resolve_transfer={initiate_payout}
                    setUpPinAction={() => setShow(true)}
                    showButton={false}
                    show={show}
                    setShow={(state) => setShow(state)}
                  />
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <div className="text-center">
                  <p>
                    Enter the code from the authenticator app in the field
                    below.
                  </p>
                </div>

                <div className="row gx-2 gx-sm-3 mt-4">
                  <OtpFields onOtpInput={handleOtpChange} readOnly={readOnly} />
                </div>
              </div>
            )}
            <div className="modal-footer">
              <div className="row align-items-sm-center flex-grow-1 mx-n2">
                <div className="col-sm mb-2 mb-sm-0"></div>

                {show ? null : merchant_data?.state?.transaction_pin_set ===
                  false ? null : ( //two_fa_enabled === false ? (
                  <div className="col-sm-auto">
                    <button
                      onClick={() => {
                        moengage.track_event("INITIATE_PAYOUT", {
                          amount: parseInt(value.replace(/\D/g, "")),
                          success_status: true,
                          platform: deviceInfo?.userAgent,
                        });
                        handleInitiatePayout({
                          amount: parseInt(value.replace(/\D/g, "")),
                          // auth_code: "123455",
                        });
                      }}
                      disabled={pin.length !== 4 || isLoading === true}
                      type="button"
                      className="btn btn-primary"
                    >
                      Initiate Payout{" "}
                      {isLoading && (
                        <>
                          {" "}
                          &nbsp;
                          <SpinnerComponent size="sm" />
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InitiatePayout;
