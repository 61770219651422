import { useMutation, useQuery } from "react-query";
import { initQueryOptions } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import { RequestMethod } from "../../utils/enums";
import { instance } from "../../utils/instance";

export const useTransactionInfo = (id?: string | number) => {
  const { data, error, isLoading, isSuccess, isError } = useQuery(
    ["transactions", "transaction", id],
    () => API_SERVICE._getTransferInfo(id),
    initQueryOptions()
  );
  return { data, error, isError, isLoading, isSuccess };
};

export const useTransactions = (config?: Record<any, any>, page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      [
        "transactions",
        config?.reference,
        config?.start_date && config?.end_date,
        page,
      ],
      () => API_SERVICE._getTransactions(config, page),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useUnsettledTransactions = (
  config?: Record<any, any>,
  page?: number
) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      [
        "un-settled-transactions",
        config?.reference,
        config?.start_date && config?.end_date,
        page,
      ],
      () => API_SERVICE._getUnsettledTransactions(config, page),
      initQueryOptions()
    );

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  };
};

export const useUnsettledTransactionInfo = (id?: string | number) => {
  const { data, error, isLoading, isSuccess, isError } = useQuery(
    ["un-settled-transactions", "un-settled-transaction", id],
    () => API_SERVICE._getUnsettledInfo(id),
    initQueryOptions()
  );
  return { data, error, isError, isLoading, isSuccess };
};

export const useTranactionExports = () => {
  const export_transactions: any = useMutation(
    (config: {
      start_date: string;
      end_date: string;
      download?: string;
    }): any => {
      let queryString;
      if (config?.download === "date_range") {
        queryString = `?start_date=${config.start_date}&end_date=${config.end_date}&download=true`;
        return instance({
          url: `/merchant/transactions${queryString}`,
          method: RequestMethod.GET,
        });
      } else if (config?.download === "all") {
        queryString = `?download=true`;
        return instance({
          url: `/merchant/transactions${queryString}`,
          method: RequestMethod.GET,
        });
      }
    },
    {
      ...initQueryOptions(),
    }
  );
  return { export_transactions };
};

export const useUnsettledTranactionExports = () => {
  const export_transactions: any = useMutation(
    (config: {
      start_date: string;
      end_date: string;
      download?: string;
    }): any => {
      let queryString;
      if (config?.download === "date_range") {
        queryString = `?start_date=${config.start_date}&end_date=${config.end_date}&download=true`;
        return instance({
          url: `/merchant/transactions/unsettled${queryString}`,
          method: RequestMethod.GET,
        });
      } else if (config?.download === "all") {
        queryString = `?download=true`;
        return instance({
          url: `/merchant/transactions/unsettled${queryString}`,
          method: RequestMethod.GET,
        });
      }
    },
    {
      ...initQueryOptions(),
    }
  );
  return { export_transactions };
};
