import { AUTH_BASE_PATH } from "../constants/index.constant";
import { RequestMethod } from "../utils/enums";
import {
  billPaymentInstance,
  cashierInstance,
  instance,
  kycInstance,
} from "../utils/instance";
import {
  CreateCashier,
  EditCashier,
  ICreateCashier,
  ICreatePayLink,
  ISetupCashier,
  loginInterface,
  registerInterface,
  setPinProps,
} from "../utils/interfaces";

export default class API_SERVICE {
  static async _login(data: loginInterface) {
    return await instance({
      url: `${AUTH_BASE_PATH}/login`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _registerUser(data: registerInterface) {
    return await instance({
      url: `${AUTH_BASE_PATH}`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _verifyOtp(data: any, actionType: string) {
    // complete2fa or twofactor
    return await instance({
      url: `${AUTH_BASE_PATH}/${actionType}`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _invalidateToken() {
    return await instance({
      url: `${AUTH_BASE_PATH}/logout`,
      method: RequestMethod.POST,
    });
  }

  static async _getSession() {
    return await instance({
      url: `${AUTH_BASE_PATH}/session`,
      method: RequestMethod.GET,
    });
  }

  static async _getDashboardReports(config: any) {
    return await instance({
      url: `${AUTH_BASE_PATH}/dashboard${
        config?.start_date ? `?start_date=${config?.start_date}` : ""
      }${config?.end_date ? `&end_date=${config?.end_date}` : ""}`,
      method: RequestMethod.GET,
    });
  }

  static async _getKeys() {
    return await instance({
      url: `${AUTH_BASE_PATH}/security`,
      method: RequestMethod.GET,
    });
  }

  static async _initiateTwoFa() {
    return await instance({
      url: `${AUTH_BASE_PATH}/initiate2fa`,
      method: RequestMethod.GET,
    });
  }

  static async _getTransactionInfo(id?: string | number) {
    return await instance({
      url: `/merchant/transactions/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _getSecurityQuestions() {
    return await instance({
      url: `${AUTH_BASE_PATH}/securityquestion`,
      method: RequestMethod.GET,
    });
  }

  static async _getTransactions(config?: any, page?: number) {
    const queryParams = new URLSearchParams();
    if (config?.reference) {
      queryParams.append("reference", config?.reference);
      return await instance({
        url: `/merchant/transactions?search=${config?.reference}`,
        method: RequestMethod.GET,
      });
    } else if (config?.start_date && config?.end_date) {
      queryParams.append("start_date", config?.start_date);
      queryParams.append("end_date", config?.end_date);
      return await instance({
        url: `/merchant/transactions?start_date=${config?.start_date}&end_date=${config?.end_date}`,
        method: RequestMethod.GET,
      });
    } else {
      return await instance({
        url: `/merchant/transactions?page=${page}&perPage=${10}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getUnsettledTransactions(config?: any, page?: number) {
    const queryParams = new URLSearchParams();
    if (config?.reference) {
      queryParams.append("reference", config?.reference);
      return await instance({
        url: `/merchant/transactions/unsettled?search=${config?.reference}`,
        method: RequestMethod.GET,
      });
    } else if (config?.start_date && config?.end_date) {
      queryParams.append("start_date", config?.start_date);
      queryParams.append("end_date", config?.end_date);
      return await instance({
        url: `/merchant/transactions/unsettled?start_date=${config?.start_date}&end_date=${config?.end_date}`,
        method: RequestMethod.GET,
      });
    } else {
      return await instance({
        url: `/merchant/transactions/unsettled?page=${page}&perPage=${10}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getTransferDayUsage() {
    return await instance({
      url: `/merchant/auth/transfervaluetoday`,
      method: RequestMethod.GET,
    });
  }

  static async _postSendTransfer(data: any) {
    // complete2fa or twofactor
    return await instance({
      url: `/merchant/transfer/initiate`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _getTransferInfo(id?: string | number) {
    return await instance({
      url: `/merchant/transactions/${id}?timeline=true`,
      method: RequestMethod.GET,
    });
  }

  static async _getUnsettledInfo(id?: string | number) {
    return await instance({
      url: `/merchant/transactions/unsettled/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _postSendOTPForPin() {
    return await instance({
      url: `/merchant/auth/transactionpinotp`,
      method: RequestMethod.POST,
    });
  }

  static async _postVerifyOTPForPin(data: { auth_code: string }) {
    return await instance({
      url: `/merchant/auth/transactionpinotp/verify`,
      method: RequestMethod.POST,
      data,
    });
  }

  static async _postSetPin(data: setPinProps) {
    // complete2fa or twofactor
    return await instance({
      url: `/merchant/auth/settransactionpin`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _getBenificiaries() {
    return await instance({
      url: `/merchant/payouts/beneficiaries?page=0&perPage=1000`,
      method: RequestMethod.GET,
    });
  }

  static async _getTransfers(config?: any, page?: number) {
    const queryParams = new URLSearchParams();
    queryParams.append("transaction_type", "transfer");
    if (config?.reference) {
      queryParams.append("reference", config?.reference);
      return await instance({
        url: `/merchant/transactions?transaction_type=transfer&reference=${config?.reference}`,
        method: RequestMethod.GET,
      });
    } else if (config?.start_date && config?.end_date) {
      queryParams.append("start_date", config?.start_date);
      queryParams.append("end_date", config?.end_date);
      return await instance({
        url: `/merchant/transactions?transaction_type=transfer&start_date=${config?.start_date}&end_date=${config?.end_date}`,
        method: RequestMethod.GET,
      });
    } else {
      return await instance({
        url: `/merchant/transactions?transaction_type=transfer&page=${page}&perPage=${10}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getAccounts(page: number, config?: any) {
    let queryParams = new URLSearchParams();
    let queryString: string = "";
    if (config?.start_date && config?.end_date) {
      queryParams.append("start_date", config.start_date);
      queryParams.append("end_date", config.end_date);
      queryString = `?start_date=${config.start_date}&end_date=${config.end_date}`;
      return await instance({
        url: `/merchant/accounts${queryString}`,
        method: RequestMethod.GET,
      });
    } else if (config?.account_no) {
      queryParams.append("account_no", config.account_no);
      queryString = `?account_no=${config?.account_no}`;
      return await instance({
        url: `/merchant/accounts${queryString}`,
        method: RequestMethod.GET,
      });
    } else if (config?.downlaod === "all") {
      queryString = `?download=true`;
      return await instance({
        url: `/merchant/accounts${queryString}`,
        method: RequestMethod.GET,
      });
    } else if (config?.downlaod === "date_range") {
      queryString = `?start_date=${config.start_date}&end_date=${config.end_date}&download=true`;
      return await instance({
        url: `/merchant/accounts${queryString}`,
        method: RequestMethod.GET,
      });
    } else {
      return await instance({
        url: `/merchant/accounts?page=${page}&perPage=${10}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getSupportedBanks() {
    return await instance({
      url: `/merchant/auth/supportedbanks`,
      method: RequestMethod.GET,
    });
  }

  static async _postSecondaryBanks(data: any) {
    return await instance({
      url: `/merchant/secondarybanks`,
      method: RequestMethod.POST,
      data,
    });
  }

  static async _getAccount(id: string) {
    return await instance({
      url: `/merchant/accounts/account/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _getLatestTransactions() {
    return await instance({
      url: `${AUTH_BASE_PATH}/latesttransaction`,
      method: RequestMethod.GET,
    });
  }

  // GET PAYMENT LINKS
  static async _getPayLinks(
    type?: string,
    page?: number,
    payment_link_name?: string
  ) {
    if (type === "all" && payment_link_name === "") {
      return await instance({
        url: `/merchant/payment?page=${page}&perPage=${10}`,
        method: RequestMethod.GET,
      });
    } else if (type !== "all" && payment_link_name === "") {
      return await instance({
        url: `/merchant/payment?type=${type}&page=${page}&perPage=${10}`,
        method: RequestMethod.GET,
      });
    } else if (payment_link_name !== "") {
      return await instance({
        url: `/merchant/payment?payment_link_name=${payment_link_name}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getPayLinkInfo(id?: string | number) {
    return await instance({
      url: `/merchant/payment/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _createPayLink(data?: ICreatePayLink) {
    return await instance({
      url: "/merchant/payment",
      method: RequestMethod.POST,
      data,
    });
  }

  static async _expirePayLink(id?: string | number) {
    return await instance({
      url: `/merchant/payment/expire/expired/${id}`,
      method: RequestMethod.POST,
    });
  }

  static async _getAllCashiers(page?: number, query?: string) {
    if (query) {
      return await cashierInstance({
        url: `/cashier/?query=${query}`,
        method: RequestMethod.GET,
      });
    } else {
      return await cashierInstance({
        url: `/cashier/?page=${page}&perPage=10`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getSingleCashier(account_number: string | any) {
    return await cashierInstance({
      url: `/cashier/${account_number}`,
      method: RequestMethod.GET,
    });
  }

  static async _getCashierInfo(id?: string | number) {
    return await instance({
      url: `/merchant/cashier/single/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _getCashiersStats() {
    return await cashierInstance({
      url: "/cashier/",
      method: RequestMethod.GET,
    });
  }

  static async _createCashier(cashier: ICreateCashier) {
    return await instance({
      url: "/merchant/cashier/invite/",
      method: RequestMethod.POST,
      data: cashier,
    });
  }

  // CASHIER INSTANCES --> BEGIN
  static async _createNewCashier(cashier: CreateCashier) {
    return await cashierInstance({
      url: `/cashier/`,
      method: RequestMethod.POST,
      data: cashier,
    });
  }

  static async _editCashier(cashier: EditCashier, cashier_id: string | any) {
    return await cashierInstance({
      url: `/cashier/edit/${cashier_id}`,
      method: RequestMethod.PUT,
      data: cashier,
    });
  }

  static async _manageCashier(id: string | any, action: string) {
    return await cashierInstance({
      url: `/cashier/manage/${id}?action=${action}`,
      method: RequestMethod.POST,
    });
  }

  static async _getCashierSettlements(
    page?: number,
    cashier_id?: string,
    query?: string,
    dateRange?: { start_date: string; end_date: string },
    download?: boolean
  ) {
    if (
      dateRange &&
      dateRange?.start_date !== "" &&
      dateRange?.end_date !== ""
    ) {
      return await cashierInstance({
        url: `/cashier/settlements/list?page=${page}&perPage=10&cashier_id=${cashier_id}&start_date=${dateRange?.start_date}&end_date=${dateRange?.end_date}`,
        method: RequestMethod.GET,
      });
    } else if (query) {
      return await cashierInstance({
        url: `/cashier/settlements/list?cashier_id=${cashier_id}&query=${query}`,
        method: RequestMethod.GET,
      });
    } else if (download) {
      return await cashierInstance({
        url: `/cashier/settlements/list?download=${download}&cashier_id=${cashier_id}`,
        method: RequestMethod.GET,
      });
    } else {
      return await cashierInstance({
        url: `/cashier/settlements/list?page=${page}&perPage=10&cashier_id=${cashier_id}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getCashierCollections(
    page?: number,
    accountNumber?: number,
    query?: string,
    dateRange?: { start_date: string; end_date: string },
    download?: boolean
  ) {
    if (
      dateRange &&
      dateRange?.start_date !== "" &&
      dateRange?.end_date !== ""
    ) {
      return await cashierInstance({
        url: `/cashier/payments/transactions?page=${page}&perPage=10&transaction_type=collection&account_no=${accountNumber}&start_date=${dateRange?.start_date}&end_date=${dateRange?.end_date}`,
        method: RequestMethod.GET,
      });
    } else if (query) {
      return await cashierInstance({
        url: `/cashier/payments/transactions?page=${page}&perPage=10&transaction_type=collection&query=${query}`,
        method: RequestMethod.GET,
      });
    } else if (download) {
      return await cashierInstance({
        url: `/cashier/payments/transactions?page=${page}&perPage=10&transaction_type=collection&download=${download}`,
        method: RequestMethod.GET,
      });
    } else {
      return await cashierInstance({
        url: `/cashier/payments/transactions?page=${page}&perPage=10&transaction_type=collection&account_no=${accountNumber}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _getCashierStats(
    accountNumber?: number,
    dateRange?: { start_date: string; end_date: string }
  ) {
    if (dateRange && dateRange?.start_date && dateRange?.end_date) {
      return await cashierInstance({
        url: `/cashier/metric/${accountNumber}?start_date=${dateRange?.start_date}&end_date=${dateRange?.end_date}`,
        method: RequestMethod.GET,
      });
    } else {
      return await cashierInstance({
        url: `/cashier/metric/${accountNumber}`,
        method: RequestMethod.GET,
      });
    }
  }

  static async _cashierPayback(data: { amount: string; account_no: string }) {
    return await cashierInstance({
      url: `/cashier/settlements`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  // CASHIER INSTANCES --> END

  static async _getAccountTransactions(account_no: string) {
    return await instance({
      url: `/merchant/accounts/${account_no}`,
      method: RequestMethod.GET,
    });
  }

  static async _setupCashier(data: ISetupCashier) {
    return await instance({
      url: `/merchant/cashier/setup`,
      method: RequestMethod.POST,
      data: data,
    });
  }
  static async _viewKeys(auth_code: string) {
    return await instance({
      url: `/merchant/auth/security?auth_code=${auth_code}`,
      method: RequestMethod.GET,
    });
  }
  // PAYOUTS SERVICES
  static async _getBanks() {
    return await instance({
      url: `/merchant/payouts/banks`,
      method: RequestMethod.GET,
    });
  }

  static async _resolveAccount(account_no: string, bank_code: string) {
    return await instance({
      url: `/merchant/payouts/resolve?account_no=${account_no}&bank_code=${bank_code}`,
      method: RequestMethod.GET,
    });
  }

  static async _getPayoutAccount() {
    return await instance({
      url: `/merchant/payouts/account`,
      method: RequestMethod.GET,
    });
  }

  static async _getPayoutTransactions(page?: number) {
    return await instance({
      url: `/merchant/payouts/transactions?page=${page}&perPage=${10}`,
      method: RequestMethod.GET,
    });
  }

  static async _getAccountTransactionFromTransactions(account_no: string) {
    return await instance({
      url: `/merchant/transactions?account_no=${account_no}`,
      method: RequestMethod.GET,
    });
  }

  static async _renderHTML() {
    return await instance({
      url: `https://res.cloudinary.com/woodcore/raw/upload/v1689164603/Terms%20and%20condition/Pooler_Ts_Cs_1_my2ho7.html`,
      method: RequestMethod.GET,
    });
  }
  static async _getPaymentTemplate(page: number, limit?: number) {
    return await instance({
      url: `/merchant/payment?page=${page}&perPage=${limit}&template=true`,
      method: RequestMethod.GET,
    });
  }
  static async _getSinglePayLinkTransaction(
    id?: string | number,
    page?: number
  ) {
    return await instance({
      url: `/merchant/payment/${id}/transactions?page=${page}&perPage=${10}`,
      method: RequestMethod.GET,
    });
  }

  static async _complianceRequirements(x_signature?: string) {
    return await kycInstance({
      url: `/requiremnts`,
      method: RequestMethod.GET,
      headers: { "x-compliance-signature": x_signature },
    });
  }

  static async _checkBuisnessComplianceRequirements(
    businessType: string,
    merchantId: string,
    x_signature?: string | any
  ) {
    return await kycInstance({
      url: `/${businessType}/${merchantId}`,
      method: RequestMethod.GET,
      headers: { "x-compliance-signature": x_signature },
    });
  }
  // AIRTIME
  static async _getAirtimeBulkTransaction(page: any) {
    return await billPaymentInstance({
      url: `/airtime/bulk?page=${page}`,
      method: RequestMethod.GET,
    });
  }

  static async _getAirtimeTransaction(page: any) {
    return await billPaymentInstance({
      url: `/airtime?page=${page}`,
      method: RequestMethod.GET,
    });
  }

  static async _getAirtimeBulkTransactionBYRef(page: any, id: string) {
    return await billPaymentInstance({
      url: `/airtime/bulk/${id}?page=${page}&perPage=15`,
      method: RequestMethod.GET,
    });
  }

  static async _getNumberInfo(id?: string | number) {
    return await billPaymentInstance({
      url: `/airtime/info/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _postPrepareAirtimePurchase(data: any) {
    return await billPaymentInstance({
      url: `/airtime/buy`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _postConfirmAirtimePurchase(
    data: { transaction_pin: string },
    id: string | number
  ) {
    return await billPaymentInstance({
      url: `/airtime/confirm/${id}`,
      method: RequestMethod.POST,
      data,
    });
  }
  // Data
  static async _getDataBulkTransaction(page: any) {
    return await billPaymentInstance({
      url: `/data/bulk?page=${page}`,
      method: RequestMethod.GET,
    });
  }

  static async _getDataTransaction(page: any) {
    return await billPaymentInstance({
      url: `/data?page=${page}`,
      method: RequestMethod.GET,
    });
  }

  static async _getDataBulkTransactionBYRef(page: any, id: string) {
    return await billPaymentInstance({
      url: `/data/bulk/${id}?page=${page}&perPage=15`,
      method: RequestMethod.GET,
    });
  }

  static async _getNumberDataInfo(id?: string | number) {
    return await billPaymentInstance({
      url: `/data/info/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _postPrepareDataPurchase(data: any) {
    return await billPaymentInstance({
      url: `/data/buy`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _postConfirmDataPurchase(
    data: { transaction_pin: string },
    id: string | number
  ) {
    return await billPaymentInstance({
      url: `/data/confirm/${id}`,
      method: RequestMethod.POST,
      data,
    });
  }

  // Beneficiary
  static async _getBeneficiaries(page: any) {
    return await billPaymentInstance({
      url: `/beneficiary?page=${page}&perPage=20`,
      method: RequestMethod.GET,
    });
  }

  static async _postCreateBeneficiary(data: any) {
    return await billPaymentInstance({
      url: `/beneficiary`,
      method: RequestMethod.POST,
      data: data,
    });
  }

  static async _getBeneficiaryByID(id: string) {
    return await billPaymentInstance({
      url: `/beneficiary/${id}`,
      method: RequestMethod.GET,
    });
  }

  static async _deleteBeneficiaryByID(id: string) {
    return await billPaymentInstance({
      url: `/beneficiary/${id}`,
      method: RequestMethod.DELETE,
    });
  }
}
