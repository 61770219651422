import React from "react";
import Modal from "react-bootstrap/Modal";
import { formatAmount } from "../../helpers/index.helper";
import CopyButton from "../Copy";

function PaymentReceived({
  show,
  handleClose,
  paymentLinkDetails,
}: {
  show: boolean;
  handleClose: () => void;
  paymentLinkDetails?: any;
}) {
  const paymentLinkInfo = paymentLinkDetails?.payment_link_info;
  const extraFieldsValue = paymentLinkInfo?.extra_fields_value;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payments Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Customer Name</p>
          <p className="text-sm-end ms-sm-auto">
            {paymentLinkInfo?.payer_firstname}&nbsp;
            {paymentLinkInfo?.payer_lastname}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Email</p>
          <p className="text-sm-end ms-sm-auto">
            {paymentLinkInfo?.payer_email}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Amount</p>
          <p className="text-sm-end ms-sm-auto">
            {formatAmount(paymentLinkDetails?.amount)}
            <CopyButton text={formatAmount(paymentLinkDetails?.amount)} />
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Date</p>
          <p className="text-sm-end ms-sm-auto">{paymentLinkInfo?.createdAt}</p>
        </div>

        {extraFieldsValue && (
          <>
            {Object?.keys(extraFieldsValue)?.map((key) => (
              <>
                <div
                  className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2"
                  key={key}
                >
                  <p className="text-body mb-0 text-capitalize">{key}</p>
                  <p className="text-sm-end ms-sm-auto">
                    {extraFieldsValue[key]}
                  </p>
                </div>
              </>
            ))}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default PaymentReceived;
