import React, { useState, ChangeEvent } from "react";
import { Button } from "../button";
import SpinnerComponent from "../spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ICreateCashier } from "../../utils/interfaces";
import { useCreateCashier } from "../../hooks/cashier/index.hook";

function CreateCashierModal({
  show,
  handleClose,
  id,
  cashierData,
}: {
  show: boolean;
  handleClose: () => void;
  id?: string | any;
  cashierData?: any;
}): JSX.Element {
  const [cashierDetails, setCashierDetails] = useState<ICreateCashier>({
    email: "",
    firstname: "",
    lastname: "",
    mobile_number: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createHandler } = useCreateCashier();

  const handleSubmit = () => {
    setIsLoading(true);
    createHandler
      .mutateAsync(cashierDetails)
      .then(() => {
        setCashierDetails({
          email: "",
          firstname: "",
          lastname: "",
          mobile_number: "",
          cashier_name: "",
        });
        setIsLoading(false);
        handleClose();
      })
      .catch((e) => {
        setIsLoading(false);
        return e;
      })
      .finally(() => {
        handleClose();
        setIsLoading(false);
      });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = event.target;
    setCashierDetails((cashier) => ({
      ...cashier,
      [name]: value,
    }));
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="">
          {id ? (
            <h4 className="modal-title" id="exampleModalLabel">
              Edit cashier
            </h4>
          ) : (
            <h4 className="modal-title" id="exampleModalLabel">
              Create cashier
            </h4>
          )}
        </Modal.Title>
      </Modal.Header>
      {id ? (
        <>
          <Modal.Body>
            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  Cashier first name
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder=""
                  value={cashierDetails.firstname}
                  name="firstname"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="lastname">
                  Cashier last name
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder=""
                  name="lastname"
                  value={cashierDetails.lastname}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* <div className="row">
          <div className="col">
            <label className="form-label" htmlFor="cashier_name">
              Cashier user name
            </label>
            <input
              type="text"
              className="form-control mb-3"
              placeholder=""
              name="cashier_name"
              value={cashierDetails.cashier_name}
              onChange={handleChange}
            />
          </div>
        </div> */}
            <div className="row">
              {/* PHONE NUMBER */}
              <div className="col">
                <label className="form-label" htmlFor="mobile_number">
                  Cashier phone number
                </label>
                <PhoneInput
                  country={"ng"}
                  value={cashierDetails.mobile_number}
                  onChange={(phoneNumber: any) =>
                    handleChange({
                      target: { name: "mobile_number", value: phoneNumber },
                    })
                  }
                  inputClass={`form-control form-control-lg w-100`}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <label className="form-label" htmlFor="email">
                  Cashier email
                </label>
                <input
                  type="email"
                  className="form-control mb-3"
                  placeholder=""
                  name="email"
                  value={cashierDetails.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="email">
                  Branch
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder=""
                  name="email"
                  value={cashierDetails.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-primary w-100"
                >
                  Save changes{" "}
                  {isLoading && (
                    <>
                      {" "}
                      &nbsp;
                      <SpinnerComponent size="sm" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </>
      ) : (
        <Modal.Body>
          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="firstname">
                Cashier first name
              </label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder=""
                value={cashierDetails.firstname}
                name="firstname"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="lastname">
                Cashier last name
              </label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder=""
                name="lastname"
                value={cashierDetails.lastname}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* <div className="row">
          <div className="col">
            <label className="form-label" htmlFor="cashier_name">
              Cashier user name
            </label>
            <input
              type="text"
              className="form-control mb-3"
              placeholder=""
              name="cashier_name"
              value={cashierDetails.cashier_name}
              onChange={handleChange}
            />
          </div>
        </div> */}
          <div className="row">
            {/* PHONE NUMBER */}
            <div className="col">
              <label className="form-label" htmlFor="mobile_number">
                Cashier phone number
              </label>
              <PhoneInput
                country={"ng"}
                value={cashierDetails.mobile_number}
                onChange={(phoneNumber: any) =>
                  handleChange({
                    target: { name: "mobile_number", value: phoneNumber },
                  })
                }
                inputClass={`form-control form-control-lg w-100`}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <label className="form-label" htmlFor="email">
                Cashier email
              </label>
              <input
                type="email"
                className="form-control mb-3"
                placeholder=""
                name="email"
                value={cashierDetails.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="email">
                Branch
              </label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder=""
                name="email"
                value={cashierDetails.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary w-100"
              >
                Create cashier{" "}
                {isLoading && (
                  <>
                    {" "}
                    &nbsp;
                    <SpinnerComponent size="sm" />
                  </>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default CreateCashierModal;
