import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import { PATH_NAMES, TOKEN_STRING } from "../../constants/index.constant";
import Accounts from "../../pages/accounts/Accounts";
import CreateVirtualAccount from "../../pages/accounts/create-account/CreateVirtualAccount";
import AccountDetails from "../../pages/accounts/details/AccountDetails";
import Cashier from "../../pages/cashier";
import CashierCollections from "../../pages/cashier/CashierCollections";
import CashierDetails from "../../pages/cashier/CashierDetails";
import CashierReg from "../../pages/cashier/CashierReg";
import CashierUpdate from "../../pages/cashier/CashierUpdate";
import Customers from "../../pages/customers/Customer";
import CustomerDetails from "../../pages/customers/CustomerDetails";
import GetStarted from "../../pages/get-started/GetStarted";
import Home from "../../pages/home/Home";
import CreateInvoice from "../../pages/invoices/CreateInvoice";
import InvoiceDetails from "../../pages/invoices/InvoiceDetails";
import ReviewInvoice from "../../pages/invoices/ReviewInvoice";
import Invoices from "../../pages/invoices/invoice";
import Buisness from "../../pages/kyc/Buisness";
import Documents from "../../pages/kyc/Director";
import Personal from "../../pages/kyc/Personal";
import BuisnessDetailsRegistered from "../../pages/kyc/registered/BuisnessDetailsRegistered";
import MandatoryRegistered from "../../pages/kyc/registered/MandatoryRegistered";
import ProofOfAddressRegistered from "../../pages/kyc/registered/ProofOfAddressRegistered";
import BuisnessDetailsUnregistered from "../../pages/kyc/unregistered/BuisnessDetailsUnregistered";
import MandatoryUnregistered from "../../pages/kyc/unregistered/MandatoryUnregistered";
import ProofOfAddressUnregistered from "../../pages/kyc/unregistered/ProofOfAddressUnregistered";
import ValidIdUnregistered from "../../pages/kyc/unregistered/ValidIdUnregistered";
import PaymentLink from "../../pages/payment-link";
import CreatePaymentLink from "../../pages/payment-link/create-payment-link";
import PaymentDetails from "../../pages/payment-link/payment-details";
import Templates from "../../pages/payment-link/templates";
import Payouts from "../../pages/payouts/Payouts";
import Products from "../../pages/product/product";
import Profile from "../../pages/profile/Profile";
import TransactionDetails from "../../pages/transactions/TransactionDetails";
import Transactions from "../../pages/transactions/Transactions";
import UnsettledTransactionsDetails from "../../pages/transactions/UnsettledTransactionDetails";
import MakeTransferMobile from "../../pages/transfer/MakeTransferMobile";
import Transfer from "../../pages/transfer/Transfer";
import TransferHistory from "../../pages/transfer/TransferHistory";
import TransferTransactionDetails from "../../pages/transfer/TransferTransactionDetails";
import ToUp from "../../pages/top-up/ToUp"
import Bulk from "../../pages/top-up/Bulk";
import BulkTransactionDetail from "../../pages/top-up/Bulk/TransactionDetails";

function ClientRoutes() {
  const token = window.localStorage.getItem(TOKEN_STRING);
  const navigate = useNavigate();

  useEffect((): any => {
    if (token === null || token === undefined) {
      navigate(`/auth${PATH_NAMES.signin}`);
    }
  }, [navigate, token]);

  return (
    <Layout>
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={PATH_NAMES.accounts} element={<Accounts />} />
        <Route path={PATH_NAMES.topup} element={<ToUp />} />
        <Route path={PATH_NAMES.bulkTopup} element={<Bulk />} />
        <Route path={`${PATH_NAMES.bulkTopup}/:type/:id`} element={<BulkTransactionDetail />} />
        <Route
          path={PATH_NAMES.virtual_accounts}
          element={<CreateVirtualAccount />}
        />
        <Route path={PATH_NAMES.transfer} element={<Transfer />} />
        <Route path={PATH_NAMES.allTransfer} element={<TransferHistory />} />
        <Route
          path={PATH_NAMES.make_transferred}
          element={<MakeTransferMobile />}
        />
        <Route
          path={PATH_NAMES.transferDetails}
          element={<TransferTransactionDetails />}
        />
        <Route path={PATH_NAMES.transactions} element={<Transactions />} />
        <Route
          path={PATH_NAMES.transactionDetails}
          element={<TransactionDetails />}
        />
        <Route
          path={PATH_NAMES.usettled_transactionDetails}
          element={<UnsettledTransactionsDetails />}
        />
        <Route path={PATH_NAMES.payLink} element={<PaymentLink />} />
        <Route path={PATH_NAMES.createLink} element={<CreatePaymentLink />} />
        <Route
          path={PATH_NAMES.createRecurringLink}
          element={<CreatePaymentLink recurring />}
          
        />
        <Route path={PATH_NAMES.payLinkDetails} element={<PaymentDetails />} />
        <Route path={PATH_NAMES.payouts} element={<Payouts />} />
        <Route path={PATH_NAMES.cashier} element={<Cashier />} />
        <Route
          path={`${PATH_NAMES.cashier}/:id`}
          element={<CashierDetails />}
        />
        <Route
          path={`${PATH_NAMES.cashier}/:id/collection/:collectionId`}
          element={<CashierCollections />}
        />
        <Route path={PATH_NAMES.get_started} element={<GetStarted />} />

        <Route
          path={`${PATH_NAMES.cashier}/create_cashier`}
          element={<CashierReg />}
        />

        <Route
          path={`${PATH_NAMES.cashier}/update_cashier/:id`}
          element={<CashierUpdate />}
        />

        <Route path={PATH_NAMES.profile} element={<Profile />} />
        <Route path={PATH_NAMES.accountDetails} element={<AccountDetails />} />
        <Route
          path={`/${PATH_NAMES.kyc}`}
          element={
            <Navigate
              to={`/merchant/${PATH_NAMES.kyc}/buisness_information?stage=1`}
            />
          }
        />
        <Route
          path={`${PATH_NAMES.kyc}/buisness_information`}
          element={<Buisness />}
        />
        <Route
          path={`${PATH_NAMES.kyc}/buisness_documents`}
          element={<Buisness />}
        />
        <Route
          path={`${PATH_NAMES.kyc}/personal_information`}
          element={<Personal />}
        />
        <Route
          path={`${PATH_NAMES.kyc}/director_information`}
          element={<Documents />}
        />
        <Route path={`${PATH_NAMES.invoices}`} element={<Invoices />} />
        <Route
          path={`${PATH_NAMES.invoices}/details/:id`}
          element={<InvoiceDetails />}
        />
        <Route
          path={`${PATH_NAMES.invoices}/create_invoice`}
          element={<CreateInvoice />}
        />
        <Route
          path={`${PATH_NAMES.invoices}/review_invoice`}
          element={<ReviewInvoice />}
        />
        <Route path={`${PATH_NAMES.customers}`} element={<Customers />} />
        <Route
          path={`${PATH_NAMES.customers}/details/:id`}
          element={<CustomerDetails />}
        />
        <Route path={`${PATH_NAMES.products}`} element={<Products />} />
        <Route path={`${PATH_NAMES.template}`} element={<Templates />} />
        <Route
          path={`/${PATH_NAMES.kyc}/unregistered_business/business_details`}
          element={<BuisnessDetailsUnregistered />}
        />
        <Route
          path={`/${PATH_NAMES.kyc}/registered_business/business_details`}
          element={<BuisnessDetailsRegistered />}
        />
        <Route
          path={`/${PATH_NAMES.kyc}/unregistered_business/mandatory_details`}
          element={<MandatoryUnregistered />}
        />
        <Route
          path={`/${PATH_NAMES.kyc}/registered_business/mandatory_details`}
          element={<MandatoryRegistered />}
        />
        <Route
          path={`/${PATH_NAMES.kyc}/unregistered_business/valid_id`}
          element={<ValidIdUnregistered />}
        />
        <Route
          path={`/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`}
          element={<ProofOfAddressUnregistered />}
        />
        <Route
          path={`/${PATH_NAMES.kyc}/registered_business/proof_of_address`}
          element={<ProofOfAddressRegistered />}
        />
      </Routes>
    </Layout>
  );
}

export default ClientRoutes;
