import { IPaymentLink } from "../../../pages/payment-link/create-payment-link";
import Input from "../../input/Input";

function CustomizeLink({
  formData,
  handleEditChange,
}: {
  formData: IPaymentLink;
  handleEditChange: (field: keyof IPaymentLink, value: string | number) => void;
}) {
  return (
    <div className="d-grid gap-4">
      {/* <Input
        name="link"
        label="Customize your link"
        value={formData.link}
        type="url"
        placeholder="your url"
        prefix={
          <span className="border-0 me-1 border-end input-group-text">
            https://pooler
          </span>
        }
        onChange={({target}) => handleEditChange("link", target.value)}
      /> */}
      <Input
        name="success_message"
        label="Success message"
        value={formData.success_message}
        onChange={({ target }) =>
          handleEditChange("success_message", target.value)
        }
      />
      <Input
        name="failure_message"
        label="Failure message"
        value={formData.failure_message}
        onChange={({ target }) =>
          handleEditChange("failure_message", target.value)
        }
      />
      <Input
        name="redirect_link"
        label="Redirect after payment"
        type="url"
        value={formData.redirect_link}
        onChange={({ target }) =>
          handleEditChange("redirect_link", target.value)
        }
        placeholder="https://redirect-link"
      />
      <Input
        name="trx_notification_email"
        label="Provide email address to recieve transaction notification"
        type="email"
        value={formData.trx_notification_email}
        onChange={({ target }) =>
          handleEditChange("trx_notification_email", target.value)
        }
      />
    </div>
  );
}

export default CustomizeLink;
