import React from "react";
import Modal from "react-bootstrap/Modal";
import { formatAmount } from "../../helpers/index.helper";

function CashierSettlement({
  show,
  handleClose,
  settlementDetails,
}: {
  show: boolean;
  handleClose: () => void;
  settlementDetails?: any;
}): JSX.Element {
  
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Settlement Details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Cashier Name</p>
          <p className="text-sm-end ms-sm-auto">
            {settlementDetails?.cashier_name}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Settlement Type</p>
          <p className="text-sm-end ms-sm-auto">
            {settlementDetails?.auto_settlement ? "Automated" : "Manual"}
          </p>
        </div>


        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Amount</p>
          <p className="text-sm-end ms-sm-auto">
            {formatAmount(settlementDetails?.amount_settled)}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">References</p>
          <p className="text-sm-end ms-sm-auto">
            {settlementDetails?.references}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">System Reference</p>
          <p className="text-sm-end ms-sm-auto">
            {settlementDetails?.system_reference}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Status</p>
          <p className="text-sm-end ms-sm-auto">
            {settlementDetails?.completed ? (
              <span className="badge bg-soft-success text-success ms-2">
                <span className="legend-indicator bg-success"></span>
                SUCCESSFUL
              </span>
            ) : (
              <span className="badge bg-soft-danger text-danger ms-2">
                <span className="legend-indicator bg-danger"></span>FAILED
              </span>
            )}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Creation Date</p>
          <p className="text-sm-end ms-sm-auto">
            {settlementDetails?.createdAt}
          </p>
        </div>

        <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
          <p className="text-body mb-0">Narration</p>
          <p className="text-sm-end ms-sm-auto w-50">
            {settlementDetails?.narration}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CashierSettlement;
