import {To, useNavigate} from "react-router-dom";

function BackBtn({goTo = -1 as To}: {goTo?: To}) {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(goTo)}
      style={{background: "#F0F1F9"}}
      className="rounded-circle border-0 align-items-center justify-content-center w-[32px] h-[32px] me-2"
    >
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 7H1M1 7L7 13M1 7L7 1"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}

export default BackBtn;
