import React from "react";
import { kycProps } from "../../utils/interfaces";

function KycContent({ children }: kycProps) {
  return (
    <div className="col-lg-8">
      <div id="basicVerStepFormContent">{children}</div>
    </div>
  );
}

export default KycContent;
