import { useParams } from "react-router-dom";
import { useCashier } from "../../hooks/cashier/index.hook";
import { useRef } from "react";

const CashierDashboard = () => {
  const { id } = useParams();
  const { data } = useCashier(id);

  const textRef = useRef<HTMLInputElement>(null);

  const handleCopyToClipboard = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
    }
  };

  return (
    <>
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">
              {data?.data?.data?.account_name}
            </h1>
          </div>

          <div className="col-sm-6 col-md-5 col-lg-4">
            <small className="text-cap">Your invite reference:</small>

            <div className="input-group">
              <input
                id="referralCode"
                type="text"
                className="form-control"
                readOnly
                ref={textRef}
                value={data?.data?.data?.invite_reference}
              />
              <div className="input-group-append">
                <button
                  onClick={handleCopyToClipboard}
                  className="js-clipboard btn btn-white"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Copy to clipboard"
                  data-hs-clipboard-options='{
                    "type": "tooltip",
                    "successText": "Copied!",
                    "contentTarget": "#referralCode",
                    "classChangeTarget": "#referralCodeIcon",
                    "defaultClass": "bi-clipboard",
                    "successClass": "bi-check"
                   }'
                >
                  <i id="referralCodeIcon" className="bi-clipboard"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-lg-divider">
        <div className="col-lg-4">
          <div className="text-center">
            <img
              className="avatar avatar-xl avatar-4x3 mb-4"
              src="../../assets/svg/illustrations/oc-megaphone.svg"
              alt="Description"
              data-hs-theme-appearance="default"
              style={{ minHeight: "6rem" }}
            />
            <img
              className="avatar avatar-xl avatar-4x3 mb-4"
              src="../../assets/svg/illustrations-light/oc-megaphone.svg"
              alt="Description"
              data-hs-theme-appearance="dark"
              style={{ minHeight: "6rem" }}
            />
            <span className="text-cap text-body">Number of referrals</span>
            <span className="d-block display-4 text-dark mb-2">150</span>

            <div className="row col-divider">
              <div className="col text-end">
                <span className="d-block fw-semibold text-success">
                  <i className="bi-graph-up"></i> 12%
                </span>
                <span className="d-block">change</span>
              </div>

              <div className="col text-start">
                <span className="d-block fw-semibold text-dark">25</span>
                <span className="d-block">last week</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="text-center">
            <img
              className="avatar avatar-xl avatar-4x3 mb-4"
              src="../../assets/svg/illustrations/oc-money-profits.svg"
              alt="Description"
              data-hs-theme-appearance="default"
              style={{ minHeight: "6rem" }}
            />
            <img
              className="avatar avatar-xl avatar-4x3 mb-4"
              src="../../assets/svg/illustrations-light/oc-money-profits.svg"
              alt="Description"
              data-hs-theme-appearance="dark"
              style={{ minHeight: "6rem" }}
            />
            <span className="text-cap text-body">Amount earned</span>
            <span className="d-block display-4 text-dark mb-2">$7,253.00</span>

            <div className="row col-divider">
              <div className="col text-end">
                <span className="d-block fw-semibold text-success">
                  <i className="bi-graph-up"></i> 5.6%
                </span>
                <span className="d-block">change</span>
              </div>
              <div className="col text-start">
                <span className="d-block fw-semibold text-dark">$582.00</span>
                <span className="d-block">last week</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="text-center">
            <img
              className="avatar avatar-xl avatar-4x3 mb-4"
              src="../../assets/svg/illustrations/oc-growing.svg"
              alt="Description"
              data-hs-theme-appearance="default"
              style={{ minHeight: "6rem" }}
            />
            <img
              className="avatar avatar-xl avatar-4x3 mb-4"
              src="../../assets/svg/illustrations-light/oc-growing.svg"
              alt="Description"
              data-hs-theme-appearance="dark"
              style={{ minHeight: "6rem" }}
            />
            <span className="text-cap text-body">Referral completed</span>
            <span className="d-block display-4 text-dark mb-2">25</span>

            <div className="row col-divider">
              <div className="col text-end">
                <span className="d-block fw-semibold text-danger">
                  <i className="bi-graph-down"></i> 2.3%
                </span>
                <span className="d-block">change</span>
              </div>
              <div className="col text-start">
                <span className="d-block fw-semibold text-dark">7</span>
                <span className="d-block">last week</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5">
        <p className="text-muted">
          <i className="bi-exclamation-octagon"></i> Last referral: August 25,
          2020.
        </p>
      </div>

      <div className="row">
        <div className="col-lg-8 mb-3 mb-lg-5">
          <div className="card h-100">
            <div className="card-header card-header-content-sm-between">
              <h4 className="card-header-title mb-2 mb-sm-0">
                Total sales earnings
              </h4>

              <button
                id="js-daterangepicker-predefined"
                className="btn btn-ghost-secondary btn-sm dropdown-toggle"
              >
                <i className="tio-date-range"></i>
                <span className="js-daterangepicker-predefined-preview ms-1"></span>
              </button>
            </div>

            <div className="card-body">
              <div className="chartjs-custom"></div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 mb-3 mb-lg-5">
          <div className="card h-100">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">
                Collect Cash
                <i
                  className="bi-patch-check-fill text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="This report is based on 100% of sessions."
                ></i>
              </h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-4">
                    <label htmlFor="SKULabel" className="form-label">
                      Amount
                      <i
                        className="bi-question-circle text-body ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Products are the goods or services you sell."
                      ></i>
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">NGN</span>
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-4">
                    <label htmlFor="SKULabel" className="form-label">
                      Description
                      <i
                        className="bi-question-circle text-body ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Products are the goods or services you sell."
                      ></i>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end gap-3">
                <button type="button" className="btn btn-primary">
                  Collect
                  <i className="bi-send ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Referral users</h4>
        </div>

        <div className="table-responsive datatable-custom">
          <table
            id="datatable"
            className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
            style={{ width: "100%" }}
          >
            <thead className="thead-light">
              <tr>
                <th>Reference</th>
                <th>Account</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Transaction Date</th>
                <th>Settlement</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>THFH_JJJ</td>
                <td>5667567667</td>
                <td>NGN 5,000</td>
                <td>
                  <span className="badge bg-soft-success text-success">
                    <span className="legend-indicator bg-success"></span>
                    completed
                  </span>
                </td>

                <td>Aug 17, 2020, 5:48 (ET)</td>
                <td>
                  <span className="badge bg-soft-success text-success">
                    <span className="legend-indicator bg-success"></span>success
                  </span>
                </td>
                <td>
                  <div className="btn-group" role="group">
                    <button
                      className="btn btn-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#accountInvoiceReceiptModal"
                    >
                      <i className="bi-eye"></i> View
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="card-footer">
          <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
            <div className="col-sm mb-2 mb-sm-0">
              <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                <span className="me-2">Showing:</span>

                <div className="tom-select-custom">
                  <select
                    id="datatableEntries"
                    className="js-select form-select form-select-borderless w-auto"
                    autoComplete="off"
                    data-hs-tom-select-options='{
                            "searchInDropdown": false,
                            "hideSearch": true
                          }'
                  >
                    <option value="10">10</option>
                    <option value="15" selected>
                      15
                    </option>
                    <option value="20">20</option>
                  </select>
                </div>

                <span className="text-secondary me-2">of</span>

                <span id="datatableWithPaginationInfoTotalQty"></span>
              </div>
            </div>

            <div className="col-sm-auto">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <nav
                  id="datatablePagination"
                  aria-label="Activity pagination"
                ></nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashierDashboard;
