import React from "react";
import "./style.css";

type Step = {
  title: string;
  description: string;
};

type StepperProps = {
  steps: Step[];
  orientation: "vertical" | "horizontal";
  activeStep: number;
  activeColor?: string;
};

const Stepper: React.FC<StepperProps> = ({
  steps,
  orientation,
  activeStep,
  activeColor = "#007bff",
}) => {
  return (
    <>
      <ul
        id="basicVerStepFormProgress"
        className="js-step-progress custom-stepper step step-icon-sm mb-7"
      >
        {steps.map((step, index) => (
          <li
            className={`step-item ${
              index + 1 === activeStep ? "active step-active" : ""
            } ${index + 1 <= activeStep ? "active" : ""}`}
            key={index}
            style={{ cursor: "pointer"}}
          >
            <div
              className="step-content-wrapper"
              data-hs-step-form-next-options={`{
                "targetSelector": "#basicVerStepDetails"
              }`}
              style={{ cursor: "pointer"}}
            >
              <span className="step-icon step-icon-soft-dark">{index + 1}</span>
              <div className="step-content pb-5">
                <span className="step-title">{step.title}</span>
                <span className="step-description d-block">
                  {step.description}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Stepper;
