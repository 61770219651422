import moengage from "@moengage/web-sdk";
import React, { ChangeEvent, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useResendEmail,
  useResendSms,
  useResetEmailOtp,
  useResetSms,
  useResetTrxnPin,
  useSecurityQuestion,
  useSession,
  useVerifySecurityAnswer,
} from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import { IVerifyAnswer, sessionInterface } from "../../utils/interfaces";
import Timer from "../Timer/Timer";
import OtpFields from "../otp/OtpFields";
import SpinnerComponent from "../spinner/Spinner";
import "./modal.css";

function ResetPinModal({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}): JSX.Element {
  const { data } = useSession();
  const { securityQuestions } = useSecurityQuestion();
  const { verify_answer } = useVerifySecurityAnswer();
  const { smsHandler } = useResetSms();
  const { emailOtpHandler } = useResetEmailOtp();
  const { resetPinHandler } = useResetTrxnPin();
  const { smsResendHandler } = useResendSms();
  const { emailResendHandler } = useResendEmail();
  const { deviceInfo } = useUserDeviceInfo();

  const [answer, setAnswer] = useState<string>("");
  const [hasOtp, setHasOtp] = useState<boolean>(false);
  const [isOtpValid, setOtpValid] = useState<boolean>(false);
  const [smsOtp, setSmsOtp] = useState<boolean>(false);
  const [seconds, setSeconds] = useState(300);
  const [showResendButton, setShowResendButton] = useState(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resend, setResend] = useState<boolean>(false);

  // SMS AND EMAIL OTP STATES
  const [otp, setOtp] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [checkPin, setCheckPin] = useState<boolean>(false);

  const merchant_data: sessionInterface = data?.data?.data;
  const merchantEmail = merchant_data?.email;
  const merchantPhone = merchant_data?.mobile_number;

  // VERIFIES SECURITY Q&A
  const handleSecurityAnswerVerification = async (payload: IVerifyAnswer) => {
    setLoading(true);
    try {
      const response = await verify_answer.mutateAsync(payload);
      if (response.status === 200) {
        setSmsOtp(true);
        setLoading(false);
        setShowResendButton(false);
      }
    } catch (error) {
      setLoading(false);
      setShowResendButton(false);
      return error;
    }
    setLoading(false);
    setShowResendButton(false);
  };

  // VERIFIES SMS OTP
  const handleSmsOtpVerification = async (params: {
    sms_auth_code: string;
  }) => {
    setLoading(true);
    try {
      const response = await smsHandler.mutateAsync(params);
      if (response.status === 200) {
        setHasOtp(true);
        setSmsOtp(false);
        setLoading(false);
        setOtp("");
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  // VERIFIES EMAIL OTP
  const handleEmailOtpVerification = async (params: { auth_code: string }) => {
    setLoading(true);
    try {
      const response = await emailOtpHandler.mutateAsync(params);
      if (response.status === 200) {
        setOtpValid(true);
        setLoading(false);
        setOtp(params?.auth_code);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  // RESET TRANSACTION PIN
  const handleResetPin = async (params: {
    auth_code: string;
    transaction_pin: string;
  }) => {
    setLoading(true);
    try {
      const response = await resetPinHandler.mutateAsync({
        auth_code: params?.auth_code,
        transaction_pin: params?.transaction_pin,
      });
      if (response?.status === 200) {
        moengage.track_event("RESET_PIN", {
          security_question: securityQuestions?.data?.data,
          sms_otp: smsOtp,
          email_otp: otp,
          PIN: params?.transaction_pin,
          success_status: true,
          platform: deviceInfo?.userAgent,
        });

        handleClose();
        setShowResendButton(false);
        setHasOtp(false);
        setSeconds(0);
        setOtpValid(false);
        setReadOnly(false);
        setSmsOtp(false);
        setLoading(false);
        setOtp("");
        setConfirm("");
        setSeconds(300);
        setAnswer("");
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  // RESEND SMS OTP
  const handleResendSmsOtp = async () => {
    setIsLoading(true);
    try {
      const response = await smsResendHandler.mutateAsync();
      if (response.status === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  // RESEND EMAIL OTP
  const handleResendEmailOtp = async () => {
    setResend(true);
    try {
      const response = await emailResendHandler.mutateAsync();
      if (response.status === 200) {
        setResend(false);
      }
    } catch (error) {
      setResend(false);
      return error;
    }
    setResend(false);
  };

  // OTP INPUT CHANGE
  const handleOtpChange = (otp: any) => {
    if (otp.length === 6) {
      setOtp(otp);
    }
  };

  // PIN INPUT CHANGE
  const handlePinChange = (otp: any) => {
    if (otp.length === 4) {
      setPin(otp);
    }
  };

  const question: string | any = securityQuestions?.data?.data;

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setShowResendButton(true);
    }
  }, [seconds]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setShowResendButton(false);
        setHasOtp(false);
        setSeconds(0);
        setOtpValid(false);
        setReadOnly(false);
        setSmsOtp(false);
        setLoading(false);
        setOtp("");
        setConfirm("");
        setSeconds(300);
        setAnswer("");
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{isOtpValid ? "Reset PIN" : "Verify account"}</Modal.Title>
      </Modal.Header>
      {isOtpValid ? (
        <Modal.Body>
          <div className="col mb-4 mx-auto text-center">
            <label className="form-label" htmlFor="firstname">
              Enter New PIN
            </label>

            <div className="d-flex pt-4 flex-column align-items-start">
              <OtpFields
                numInputs={4}
                secret
                onOtpInput={handlePinChange}
                inputMode="number"
                style={{ width: "50%", margin: "auto" }}
                inputStyles={{
                  border: `1px solid ${
                    pin !== "" && confirm !== "" && pin !== confirm
                      ? "#72253a"
                      : "#CBD1EC"
                  } !important`,
                  padding: "0.6125rem 1rem",
                  margin: "0px 6px",
                }}
                readOnly={false}
                serectDelay={2800}
              />
              {pin !== confirm && (
                <span
                  className="form-text text-danger text-center mx-auto"
                  hidden
                >
                  pins do not match
                </span>
              )}
            </div>
          </div>

          <div className="col mb-4 mx-auto text-center">
            <label className="form-label text-center" htmlFor="firstname">
              Confirm New PIN
            </label>

            <div className="d-flex pt-4 flex-column align-items-start">
              <OtpFields
                numInputs={4}
                secret
                onOtpInput={(otp: string) => {
                  if (otp.length === 4 && otp === pin) {
                    setCheckPin(false);
                    setConfirm(otp);
                  } else if (otp.length === 4 && otp !== pin) {
                    toast.warn("PINs do not match");
                  }
                }}
                inputMode="number"
                style={{ width: "50%", margin: "auto" }}
                inputStyles={{
                  border: `1px solid ${
                    pin !== "" && confirm !== "" && pin !== confirm
                      ? "#72253a"
                      : "#CBD1EC"
                  } !important`,
                  padding: "0.6125rem 1rem",
                  margin: "0px 6px",
                }}
                readOnly={false}
                serectDelay={2800}
              />
              {/* #72253a */}
              {checkPin && (
                <span
                  className="form-text text-danger text-center mx-auto"
                  hidden
                >
                  PINs do not match
                </span>
              )}
            </div>
          </div>

          <button
            className="w-100 btn btn-primary mt-3"
            type="button"
            disabled={
              checkPin === true || pin === "" || confirm === "" ? true : false
            }
            onClick={() => {
              if (pin === confirm) {
                handleResetPin({ auth_code: otp, transaction_pin: pin });
              } else {
                setCheckPin(true);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            Reset PIN{" "}
            {loading && (
              <>
                &nbsp;
                <SpinnerComponent size="sm" variant="light" />
              </>
            )}
          </button>
        </Modal.Body>
      ) : (
        <Modal.Body>
          {/* smsOtp && !hasOtp */}
          {smsOtp && !hasOtp && (
            <>
              <h4 className="text-center reset-title mb-4">
                An OTP was sent to{" "}
                <strong className="link link-dark fw-bold">
                  {merchantPhone}
                </strong>
              </h4>
              <p className="text-center link link-dark pt-3 pb-3">Enter OTP</p>
              <OtpFields
                onOtpInput={handleOtpChange}
                readOnly={readOnly}
                inputMode="number"
              />
              <p className="text-center">
                <Timer />
              </p>

              {showResendButton && (
                <p className="text-center">
                  <span>Didn’t receieve OTP?</span>&nbsp;
                  <span
                    className="link link-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // handleResendSmsOtp();
                      setSmsOtp(false);
                      setHasOtp(false);
                    }}
                  >
                    Resend OTP{" "}
                    {isLoading ? (
                      <SpinnerComponent variant="primary" size="sm" />
                    ) : (
                      <i className="bi bi-arrow-right"></i>
                    )}
                  </span>
                </p>
              )}

              <button
                className="w-100 btn btn-primary mt-3"
                type="button"
                onClick={() => {
                  handleSmsOtpVerification({ sms_auth_code: otp });
                }}
                style={{ cursor: "pointer" }}
              >
                Verify OTP{" "}
                {loading && (
                  <>
                    &nbsp;
                    <SpinnerComponent size="sm" variant="light" />
                  </>
                )}
              </button>
            </>
          )}
          {hasOtp && !smsOtp && (
            <>
              <h4 className="text-center reset-title mb-4">
                An OTP was sent to{" "}
                <strong className="link link-dark fw-bold">
                  {merchantEmail}
                </strong>
              </h4>
              <p className="text-center link link-dark pt-3 pb-3">Enter OTP</p>
              <OtpFields
                onOtpInput={handleOtpChange}
                readOnly={readOnly}
                inputMode="number"
              />
              <p className="text-center" hidden>{`00: ${seconds}`}</p>
              <p className="text-center">
                <span>Didn’t receieve OTP?</span>&nbsp;
                <span
                  className="link link-primary"
                  style={{ cursor: "pointer" }}
                  onClick={handleResendEmailOtp}
                >
                  Resend OTP{" "}
                  {resend ? (
                    <SpinnerComponent size="sm" variant="primary" />
                  ) : (
                    <i className="bi bi-arrow-right"></i>
                  )}
                </span>
              </p>
              <button
                className="w-100 btn btn-primary mt-3"
                type="button"
                onClick={() => handleEmailOtpVerification({ auth_code: otp })}
              >
                Verify OTP{" "}
                {loading && (
                  <>
                    &nbsp;
                    <SpinnerComponent size="sm" variant="light" />
                  </>
                )}
              </button>
            </>
          )}
          {!smsOtp && !hasOtp && (
            <>
              <h4 className="text-center reset-title mb-4">
                Please enter the answer to your security question
              </h4>
              <div className="col mb-4">
                <label className="form-label" htmlFor="firstname">
                  Security question
                </label>
                {question && (
                  <input
                    type="text"
                    className={`form-control border-secondary overflow-hidden input-fix`}
                    name="firstname"
                    id="firstname"
                    value={question}
                    tabIndex={1}
                    placeholder="First Name"
                    aria-label="first name"
                    autoComplete="off"
                    required
                    readOnly
                    style={{
                      whiteSpace: "normal",
                      boxSizing: "border-box",
                      overflow: "hidden !important",
                    }}
                  />
                )}
              </div>

              <div className="col mb-3">
                <label className="form-label" htmlFor="firstname">
                  Answer*
                </label>
                <input
                  type="text"
                  className={`form-control border-secondary overflow-hidden input-fix`}
                  name="answer"
                  id="answer"
                  value={answer}
                  tabIndex={1}
                  placeholder=""
                  aria-label=""
                  autoComplete="off"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAnswer(e.target.value)
                  }
                  required
                  style={{
                    whiteSpace: "normal",
                    boxSizing: "border-box",
                    overflow: "hidden !important",
                  }}
                />
              </div>

              <p className="text-center pb-4">
                <span>Forgotten your answer?</span>&nbsp;
                <NavLink
                  to="#"
                  onClick={(e) => {
                    window.location.href = "mailto:support@poolerapp.com";
                    e.preventDefault();
                  }}
                  className="link link-primary"
                  style={{ cursor: "pointer" }}
                >
                  Contact support <i className="bi bi-arrow-right"></i>
                </NavLink>
              </p>

              <button
                className="w-100 btn btn-primary"
                type="button"
                onClick={() => {
                  handleSecurityAnswerVerification({
                    security_question: question,
                    security_answer: answer?.trim(),
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                Continue{" "}
                {loading && (
                  <>
                    &nbsp;
                    <SpinnerComponent size="sm" variant="light" />
                  </>
                )}
              </button>
            </>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
}

export default ResetPinModal;
