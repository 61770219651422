import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MaskNum, formatAmountWithDecimal } from "../../helpers/index.helper";
import { useTransactionInfo } from "../../hooks/transactions/index.hook";
import { ITransaction, bankInterface } from "../../utils/interfaces";
// import { PATH_NAMES } from "../../constants/index.constant";
import Skeleton from "react-loading-skeleton";
import { useGetBanks } from "../../hooks/payouts/payouts.hook";
// import { sessionInterface } from "../../utils/interfaces";
import moengage from "@moengage/web-sdk";
import CopyButton from "../../component/Copy";
import Timeline from "../../component/Timeline/Timeline";
import useUserDeviceInfo from "../../hooks/device.hook";
import "./transfer.css";

const TransferTransactionDetails = () => {
  const navigate = useNavigate();
  const { deviceInfo } = useUserDeviceInfo();
  const { id } = useParams();
  const { data, isLoading } = useTransactionInfo(id);
  const { bankData } = useGetBanks();

  const banks: bankInterface[] = bankData?.data?.data;

  const transaction: ITransaction = data?.data?.data;
  const reference = transaction?.reference;
  const [senderBank, setSenderBank] = useState<any>();
  const [recBank, setRecBank] = useState<any>();

  const reciever_bankcode = transaction?.reciever_details?.bank_code;
  const sender_bankcode = transaction?.sender_details?.bank_code;
  const backUpTimeline = [
    {
      event_name: "Initiate Transfer",
      event_time: transaction?.created_at,
    },
    {
      event_name: transaction?.completed
        ? "Transfer Completed"
        : transaction?.payout?.in_transit
        ? "Transfer Processing"
        : "Transfer Failed",
      event_time: transaction?.modified_at,
    },
  ];

  useEffect(() => {
    if (sender_bankcode) {
      const senderBankName: any =
        banks?.length > 0 &&
        banks?.filter((bank) => bank.bank_code === sender_bankcode);
      setSenderBank(senderBankName[0]);
    }
    if (reciever_bankcode) {
      const receiverBankName: any =
        banks?.length > 0 &&
        banks?.filter((bank) => bank.bank_code === reciever_bankcode);
      setRecBank(receiverBankName[0]);
    }
  }, [banks, reciever_bankcode, sender_bankcode]);

  useEffect(() => {
    moengage.track_event("VIEW_TRANSFER", {
      transaction_id: id,
      merchant_id: transaction?.merchant_id,
      platform: deviceInfo?.userAgent,
    });
  }, [deviceInfo?.userAgent, id, transaction?.merchant_id]);

  return (
    <>
      <main>
        <div className="">
          <div className="page-header d-print-none">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <h1
                  className="page-header-title d-flex"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {/* <BackBtn /> */}
                  <i
                    className="bi bi-arrow-left me-2"
                    style={{ fontSize: "#000", cursor: "pointer" }}
                  ></i>
                  {/* {transaction?.payout?.reference || transaction?.reference} */}
                  <span className="d-none d-lg-block">
                    {transaction?.reference}{" "}
                  </span>
                  {/* VISIBLE ONLY ON SM SCREENS */}
                  {reference?.length > 15 ? (
                    <span className="d-sm-block d-md-none d-lg-none">
                      {transaction?.reference}{" "}
                    </span>
                  ) : (
                    <span className="d-sm-block d-md-none d-lg-none">
                      {transaction?.reference}
                    </span>
                  )}
                  {/* VISIBLE ONLY ON MD SCREENS */}
                  <span className="d-none d-md-block d-lg-none">
                    {transaction?.reference}{" "}
                  </span>
                </h1>{" "}
              </div>
            </div>
          </div>

          {isLoading ? (
            <div />
          ) : (
            <Timeline
              timelineItems={
                transaction?.timeline?.length > 0
                  ? transaction?.timeline.filter(
                      (item) =>
                        !item?.event_name?.toLowerCase()?.includes("tsq")
                    )
                  : backUpTimeline
              }
            />
          )}

          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title tranx-headerdd link link-dark text-cap">
                    Sender Details
                  </h4>
                </div>
                <div className="card-body">
                  <div className="list-group list-group-flush list-group-no-gutters">
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Account Number
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton
                                      text={
                                        transaction?.sender_details
                                          ?.account_number
                                      }
                                    />
                                    &emsp;
                                    {
                                      transaction?.sender_details
                                        ?.account_number
                                    }{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Account Name
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.sender_details?.name?.toUpperCase()}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Processing bank
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton text={senderBank?.bank_name} />
                                    &emsp;{senderBank?.bank_name}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Amount
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton
                                      text={formatAmountWithDecimal(
                                        transaction?.settled_amount
                                      )}
                                    />
                                    &emsp;{transaction?.currency}{" "}
                                    {formatAmountWithDecimal(
                                      transaction?.settled_amount
                                    )}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">BVN</span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {MaskNum(transaction?.sender_details?.bvn)}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Total withdrawn
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.currency}{" "}
                                    {formatAmountWithDecimal(
                                      transaction?.amount
                                    )}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Created at
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.created_at}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Modified at
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.modified_at}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Reference
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <div className="col-auto">
                                    <h5 className="d-none d-lg-block link link-dark">
                                      {transaction?.reference}{" "}
                                    </h5>
                                    {/* VISIBLE ONLY ON SM SCREENS */}
                                    {reference?.length > 15 ? (
                                      <h5 className="d-sm-block d-md-none d-lg-none link link-dark">
                                        {transaction?.reference}{" "}
                                      </h5>
                                    ) : (
                                      <h5 className="d-sm-block d-md-none d-lg-none link link-dark w-75">
                                        {transaction?.reference}
                                      </h5>
                                    )}
                                    {/* VISIBLE ONLY ON MD SCREENS */}
                                    <h5 className="d-none d-md-block d-lg-none link link-dark">
                                      {transaction?.reference}{" "}
                                    </h5>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Resolve reference
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      {transaction?.sender_details?.email}{" "}
                                    </h5>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Status
                                  </span>
                                </div>

                                <div className="col-auto">
                                  {transaction?.completed === true && (
                                    <h3 className="badge bg-soft-success text-success">
                                      COMPLETED
                                    </h3>
                                  )}
                                  {transaction?.completed === false && (
                                    <h5 className="badge bg-soft-danger text-danger">
                                      FAILED
                                    </h5>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Narration
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.narration}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Transfer ID
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton text={transaction?.id} />
                                    &emsp;{transaction?.id}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-header">
                  <h4 className="card-header-title tranx-headerff link link-dark text-cap">
                    Beneficiary’s Details
                  </h4>
                </div>

                <div className="card-body">
                  <div className="list-group list-group-flush list-group-no-gutters">
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Account Number
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton
                                      text={
                                        transaction?.reciever_details
                                          ?.account_number
                                      }
                                    />
                                    &emsp;
                                    {
                                      transaction?.reciever_details
                                        ?.account_number
                                    }{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Account Name
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.reciever_details?.name?.toUpperCase()}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">Bank</span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton text={recBank?.bank_name} />
                                    &emsp;{recBank?.bank_name}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Currency
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.currency}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">BVN</span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.reciever_details?.bvn
                                      ? MaskNum(
                                          transaction?.reciever_details?.bvn
                                        )
                                      : "N/A"}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Bank code
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton
                                      text={
                                        transaction?.reciever_details?.bank_code
                                      }
                                    />
                                    &emsp;
                                    {
                                      transaction?.reciever_details?.bank_code
                                    }{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TransferTransactionDetails;
