import moengage from "@moengage/web-sdk";
import { useEffect, useMemo, useRef, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import DropDownWrapper from "../../component/DropDownWrapper";
import BackBtn from "../../component/back-btn";
import { Button } from "../../component/button";
import PaymentReceived from "../../component/modal/PaymentReceived";
import PaginationComponent from "../../component/pagination/pagination";
import DeleteLinkModal from "../../component/payment-link/delete-link-modal";
import SpinnerComponent from "../../component/spinner/Spinner";
import Table from "../../component/table/Table";
import { payment_received_column } from "../../configs/dataTable.config";
import { formatAmount } from "../../helpers/index.helper";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  useGetSinglePayLinkTransaction,
  usePayLinkInfo,
  usePayLinksMutation,
} from "../../hooks/payment-link/index.hook";
import useCopyToClipboard from "../../hooks/useCopy.hook";

const PaymentDetails = () => {
  const { deviceInfo } = useUserDeviceInfo();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [paymentData, setPaymentData] = useState<any>({});
  const [activeItem, setActiveItem] = useState<number>(1);
  const paymentButtonRef: any = useRef<HTMLDivElement | null>(null);
  const [filter, setFilter] = useState({
    start_date: "",
    end_date: "",
    download: "all",
  });
  const [paymentLoading, setIsLoading] = useState<boolean>(false);

  const { data, isLoading } = usePayLinkInfo(params?.id);
  const { link_tx, loading, refetch } = useGetSinglePayLinkTransaction(
    params?.id,
    activeItem
  );
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const paymentLinkDetails = data?.data?.data;
  const transactions = link_tx?.data?.data?.items;
  const totalItems = link_tx?.data?.data?.totalPages;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const columns = useMemo(
    () => [
      ...payment_received_column,
      {
        Header: "",
        columnId: 6,
        accessor: "actions",
        Cell: ({ row }: any): JSX.Element => {
          const details = row?.original;
          return (
            <>
              <i
                className="bi bi-eye"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleShow();
                  setPaymentData(details);
                }}
              ></i>
            </>
          );
        },
      },
    ],
    []
  );

  const extraFields = paymentLinkDetails?.extra_fields?.extra_fields;

  const { filter_links } = usePayLinksMutation();

  const handleFilterChange = (name: string, value: string) => {
    setFilter((filter) => ({
      ...filter,
      [name]: value,
      download: "all",
    }));
  };

  const filterAndDownload = (data: any) => {
    setIsLoading(true);
    filter_links
      .mutateAsync({ ...data, id: params?.id })
      .then((data: any) => {
        const url = data?.data?.data;
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payment_link.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setFilter((filter) => ({
          ...filter,
          start_date: "",
          end_date: "",
          download: "all",
        }));
        toast.success("Report exported successfully.");
      })
      .catch((e) => {
        toast.error(e.response.data.data || e.response.data.message);
        setIsLoading(false);
        return e;
      })
      .finally(() => {
        setFilter((filter) => ({
          ...filter,
          start_date: "",
          end_date: "",
          download: "all",
        }));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    moengage.track_event("VIEW_PAYMENT_LINK", {
      success_status: true,
      paymentlink_id: params?.id,
      merchant_id: paymentLinkDetails?.merchant_id,
      platform: deviceInfo?.userAgent,
    });
  }, [deviceInfo?.userAgent, params?.id, paymentLinkDetails?.merchant_id]);

  return (
    <>
      <PaymentReceived
        show={show}
        handleClose={handleClose}
        paymentLinkDetails={paymentData}
      />
      <main className="bg-grey-500">
        <div className="page-header border-bottom-0 mb-0">
          <div className="row align-items-end">
            <div className="col-sm mb-2 mb-sm-0">
              <h1 className="page-header-title">
                <BackBtn />
                Payment link details
              </h1>{" "}
            </div>

            <div className="col-sm-auto">
              {paymentLinkDetails?.expired === true ? (
                ""
              ) : (
                <Button
                  variant="danger"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-link-modal"
                >
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-circle-half me-1"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#ffffff"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      style={{
                        rotate: "-40deg",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                      <path d="M12 3v18" />
                    </svg>
                    <span className="align-middle">Cancel Payment Link</span>
                  </>
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-5 mb-3 mb-sm-0">
            {/* CARD TOP */}
            <div className="card hard-shadow mb-3">
              <div className="card-body">
                <p className="text-body mb-0 mb-1">Page name</p>
                {isLoading ? (
                  <div className="pt-2 pb-2">
                    <Skeleton />
                  </div>
                ) : (
                  <h5 className="pb-2">
                    {paymentLinkDetails?.payment_link_name}
                  </h5>
                )}
                {isLoading ? (
                  <div>
                    <Skeleton />
                  </div>
                ) : (
                  <>
                    <div className="input-group input-group-sm input-group-merge table-input-group">
                      <input
                        id="referralsKeyCode1"
                        type="text"
                        className="form-control"
                        readOnly
                        value={paymentLinkDetails?.link || ""}
                      />
                      <OverlayTrigger
                        key={"top"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={isCopied ? "Copied" : "Copy Link"}>
                            {isCopied ? "Copied" : "Copy Link"}
                          </Tooltip>
                        }
                      >
                        <button className="js-clipboard input-group-append input-group-text position-relative">
                          <>
                            {isCopied ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-all text-success"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-copy"
                                viewBox="0 0 16 16"
                                onClick={() => {
                                  copyToClipboard(
                                    paymentLinkDetails?.link || ""
                                  );
                                  moengage.track_event("COPY_PAYMENT_LINK", {
                                    success_status: true,
                                    paymentlink_id: params?.id,
                                    merchant_id:
                                      paymentLinkDetails?.merchant_id,
                                    platform: deviceInfo?.userAgent,
                                  });
                                }}
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                />
                              </svg>
                            )}
                          </>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </>
                )}

                <div className="pt-2">
                  {isLoading ? (
                    <div className="pt-2">
                      <Skeleton />
                    </div>
                  ) : (
                    <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                      <p className="text-body mb-0">Description</p>
                      <p className="text-sm-end ms-sm-auto">
                        {paymentLinkDetails?.note}
                      </p>
                    </div>
                  )}

                  {isLoading ? (
                    <div className="pt-2">
                      <Skeleton />
                    </div>
                  ) : (
                    <div
                      className={`hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2 ${
                        paymentLinkDetails?.amount !== null ? "" : "d-none"
                      }`}
                    >
                      <p className="text-body mb-0">Amount</p>
                      <p className="text-sm-end ms-sm-auto">
                        {paymentLinkDetails?.currency}{" "}
                        {paymentLinkDetails?.amount}
                      </p>
                    </div>
                  )}

                  {isLoading ? (
                    <div className="pt-3">
                      <Skeleton />
                    </div>
                  ) : (
                    <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
                      <p className="text-body mb-0">Charges</p>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <p className="text-sm-end ms-sm-auto">
                          {paymentLinkDetails?.charge_bearer}
                        </p>
                      )}
                    </div>
                  )}

                  {isLoading ? (
                    <div className="pt-2">
                      <Skeleton />
                    </div>
                  ) : (
                    <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2 pb-2">
                      <p className="text-body mb-0">Status</p>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <p className="text-sm-end fw-bold ms-sm-auto">
                          {paymentLinkDetails?.expired === true ? (
                            <span className="badge bg-soft-danger text-danger">
                              <span className="legend-indicator bg-danger"></span>
                              EXPIRED
                            </span>
                          ) : (
                            <span className="badge bg-soft-success text-success">
                              <span className="legend-indicator bg-success"></span>
                              ACTIVE
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* ADDITIONAL INFO */}

            <div
              className="card hard-shadow mb-3"
              hidden={extraFields?.length > 0 ? false : true}
            >
              <div className="card-body">
                <p className="text-body mb-0 mb-1">Additional Information</p>
                <div
                  className="d-flex flex-row align-items-center w-100 gap-2"
                  style={{ flexWrap: "wrap" }}
                >
                  {Array.isArray(extraFields) && (
                    <>
                      {extraFields.map((item, index) => (
                        <span
                          key={index + item.fieldName}
                          className={`badge bg-secondary me-1 text-capitalize fw-normal`}
                          style={{ fontSize: "14px" }}
                        >
                          {item.fieldName}
                        </span>
                      ))}
                    </>
                  )}

                  {extraFields &&
                    typeof extraFields === "object" &&
                    extraFields.extra_fields && (
                      <>
                        {extraFields.extra_fields.map(
                          (item: any, index: any) => (
                            <span
                              key={index + item.fieldName}
                              className={`badge bg-secondary me-1 text-capitalize fw-normal`}
                              style={{ fontSize: "14px" }}
                            >
                              {item.fieldName}
                            </span>
                          )
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>

            {/* CARD BOTTOM */}
            <div className="card hard-shadow">
              <div className="card-body">
                {isLoading ? (
                  <div className="pt-2">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
                    <p className="text-body mb-0">Success message</p>
                    <p className="text-sm-end ms-sm-auto">
                      {paymentLinkDetails?.success_message}
                    </p>
                  </div>
                )}

                {isLoading ? (
                  <div className="pt-2">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
                    <p className="text-body mb-0">Failure message</p>
                    <p className="text-sm-end ms-sm-auto">
                      {paymentLinkDetails?.failure_message}
                    </p>
                  </div>
                )}

                {!paymentLinkDetails?.redirect_link ? null : isLoading ? (
                  <div className="pt-3">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
                    <p className="text-body mb-0">Redirect Link</p>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <p className="text-sm-end ms-sm-auto">
                        {paymentLinkDetails?.redirect_link}
                      </p>
                    )}
                  </div>
                )}

                {isLoading ? (
                  <div className="pt-2">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2">
                    <p className="text-body mb-0">Created on</p>
                    <p className="text-sm-end ms-sm-auto">
                      {paymentLinkDetails?.createdAt}
                    </p>
                  </div>
                )}

                {isLoading ? (
                  <div className="pt-2">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-2 pb-2">
                    <p className="text-body mb-0">Notification</p>
                    <p className="text-sm-end ms-sm-auto">
                      {paymentLinkDetails?.trx_notification_email}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="card hard-shadow h-auto mb-lg-5">
                <div className="card-header text-green-500 card-header-content-between">
                  <h4>Payments received</h4>
                  {paymentLinkDetails?.is_recurring && (
                    <DropDownWrapper
                      action={
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm w-100"
                          aria-expanded="false"
                          ref={paymentButtonRef}
                        >
                          <DownloadIcon className="ms-2 text-success" /> Export
                          Report
                        </button>
                      }
                    >
                      <div className="p-3">
                        <label className="text-body">Date Range</label>
                        <div className="row mb-4">
                          <div className="col tom-select-custom">
                            <input
                              type="date"
                              name="start_date"
                              value={filter?.start_date}
                              onChange={({ target }) =>
                                handleFilterChange(target.name, target.value)
                              }
                              className="js-daterangepicker form-control daterangepicker-custom-input"
                            />
                          </div>
                          <div className="col tom-select-custom">
                            <input
                              type="date"
                              name="end_date"
                              value={filter?.end_date}
                              onChange={({ target }) =>
                                handleFilterChange(target.name, target.value)
                              }
                              className="js-daterangepicker form-control daterangepicker-custom-input"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="btn btn-white btn-sm"
                            onClick={() => {
                              paymentButtonRef.current?.click();
                              setFilter((filter) => ({
                                ...filter,
                                start_date: "",
                                end_date: "",
                                download: "all",
                              }));
                              refetch();
                            }}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              filterAndDownload({
                                start_date: filter?.start_date,
                                end_date: filter?.end_date,
                                download: "all",
                              });
                            }}
                          >
                            Export Report{" "}
                            {paymentLoading && (
                              <>
                                &nbsp;
                                <SpinnerComponent size="sm" />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </DropDownWrapper>
                  )}
                </div>
                <Table
                  isFetching={loading}
                  columns={columns}
                  data={transactions !== undefined ? transactions : []}
                />
                <div className="card-footer">
                  <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                    <div className="col-sm mb-2 mb-sm-0"></div>

                    <div className="col-sm-auto">
                      <div className="d-flex justify-content-center justify-content-sm-end">
                        <PaginationComponent
                          totalItems={totalItems}
                          activeItem={activeItem}
                          setActiveItem={setActiveItem}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-block d-md-block d-lg-none d-xl-none">
              <div className="mt-3 card">
                <ul className="list-group">
                  <li className="list-group-item d-flex align-items-center justify-content-between">
                    <h4 className="card-header-title">Recent transactions</h4>
                    {paymentLinkDetails?.is_recurring &&
                      transactions?.length !== 0 && (
                        <DropDownWrapper
                          action={
                            <button
                              type="button"
                              className="btn btn-outline-success btn-sm w-100"
                              aria-expanded="false"
                              ref={paymentButtonRef}
                            >
                              <DownloadIcon className="ms-2 text-success" />{" "}
                              Export Report
                            </button>
                          }
                        >
                          <div className="p-3">
                            <label className="text-body">Date Range</label>
                            <div className="row mb-4">
                              <div className="col tom-select-custom">
                                <input
                                  type="date"
                                  name="start_date"
                                  value={filter?.start_date}
                                  onChange={({ target }) =>
                                    handleFilterChange(
                                      target.name,
                                      target.value
                                    )
                                  }
                                  className="js-daterangepicker form-control daterangepicker-custom-input"
                                />
                              </div>
                              <div className="col tom-select-custom">
                                <input
                                  type="date"
                                  name="end_date"
                                  value={filter?.end_date}
                                  onChange={({ target }) =>
                                    handleFilterChange(
                                      target.name,
                                      target.value
                                    )
                                  }
                                  className="js-daterangepicker form-control daterangepicker-custom-input"
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <button
                                className="btn btn-white btn-sm"
                                onClick={() => {
                                  paymentButtonRef.current?.click();
                                  setFilter((filter) => ({
                                    ...filter,
                                    start_date: "",
                                    end_date: "",
                                    download: "all",
                                  }));
                                  refetch();
                                }}
                              >
                                Reset
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  filterAndDownload({
                                    start_date: filter?.start_date,
                                    end_date: filter?.end_date,
                                    download: "all",
                                  });
                                }}
                              >
                                Export Report{" "}
                                {paymentLoading && (
                                  <>
                                    &nbsp;
                                    <SpinnerComponent size="sm" />
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </DropDownWrapper>
                      )}
                  </li>
                  {loading ? (
                    <>
                      {[1, 2, 3, 4, 5, 6].map(() => {
                        return (
                          <li className="list-group-item">
                            <Skeleton height={30} />
                          </li>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {transactions?.map((txn_data: any, index: any) => {
                        return (
                          <>
                            <li
                              className={`${
                                index === 0 ||
                                index === transactions?.length - 1
                                  ? "list-group-item"
                                  : "list-group-item"
                              } d-flex flex-row justify-content-between align-items-start`}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="ms-2 me-auto">
                                <div className="fw-semibold text-truncate">
                                  {txn_data?.payment_link_info?.payer_firstname}
                                  &emsp;
                                  {txn_data?.payment_link_info?.payer_lastname}
                                </div>
                                <div className="text-truncate d-flex">
                                  {txn_data?.createdAt}
                                </div>
                              </div>

                              <div className="d-flex flex-column gap-3">
                                {txn_data?.amount === null ? (
                                  <span className="">N/A</span>
                                ) : (
                                  <span className="">
                                    {formatAmount(
                                      txn_data?.amount,
                                      txn_data?.currency
                                    )}
                                  </span>
                                )}
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <DeleteLinkModal id={params?.id} />
      </main>
    </>
  );
};

export default PaymentDetails;
