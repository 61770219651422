import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { initQueryOptions } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import { KYC_LEVELS, RequestMethod } from "../../utils/enums";
import { kycInstance } from "../../utils/instance";

export const useBuisnessCompliance = () => {
  const compliance = useMutation(
    (payload: {
      KYC_TYPE: string;
      KYC_DATA: any;
      KYC_MERCHANT_ID: string | any;
      KYC_LEVEL: string;
      x_signature?: string;
    }) => {
      if (payload.KYC_LEVEL === KYC_LEVELS.GENERAL) {
        return kycInstance({
          url: `/${payload.KYC_TYPE}/${KYC_LEVELS.GENERAL}`,
          method: RequestMethod.POST,
          data: payload.KYC_DATA,
          headers: { "x-compliance-signature": payload.x_signature },
        });
      } else {
        return kycInstance({
          url: `/${payload.KYC_TYPE}/${payload.KYC_LEVEL}/${payload.KYC_MERCHANT_ID}`,
          method: RequestMethod.POST,
          data: payload.KYC_DATA,
          headers: { "x-compliance-signature": payload.x_signature },
        });
      }
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { compliance };
};

export const useGetComplianceRequirements = (x_signature?: string) => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["compliance-check", x_signature],
    () => API_SERVICE._complianceRequirements(x_signature),
    initQueryOptions()
  );
  const complianceData = data;
  return { data, isLoading, isError, isFetching, refetch, complianceData };
};

export const useCheckBuisnessRequirements = (
  businessType: string,
  merchantId: any,
  x_signature?: string | any
) => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["business-requirements-check", businessType, merchantId, x_signature],
    () =>
      API_SERVICE._checkBuisnessComplianceRequirements(
        businessType,
        merchantId,
        x_signature
      ),
    initQueryOptions()
  );
  const requiredData: any = data;
  return { data, isLoading, isError, isFetching, refetch, requiredData };
};

export const useComplianceFileUpload = () => {
  const complianceFile = useMutation(
    (payload: {
      KYC_MERCHANT_ID: string | any;
      KYC_LEVEL: string;
      x_signature?: string;
      KycFile?: File | null;
    }) => {
      return kycInstance({
        url: `/upload/${payload.KYC_MERCHANT_ID}`,
        method: RequestMethod.POST,
        data: payload.KycFile,
        headers: { "x-compliance-signature": payload.x_signature },
      });
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { complianceFile };
};
