import dayjs from 'dayjs';

export const decode_jwt = (data: any) => {
  if (data !== null && data !== undefined) {
    const base64Url: any = data?.split(".")[1];
    const base64: any = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload: any = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c: any): any => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const sessionObject: any = JSON?.parse(jsonPayload);
    const { userId, roles, email } = sessionObject;
    return { userid: userId, roles: roles, email: email };
  }
};

export const convertDateFormatNoTime = (inputDate: string): string => {
  const formattedDate = dayjs(inputDate).format('ddd, MMM D YYYY');
  return formattedDate;
};

export const convertDateFormat = (inputDate: string): string => {
  const formattedDate = dayjs(inputDate).format('ddd, MMM D YYYY, h:mm:ss A');
  return formattedDate;
};

export const convertShortDateFormat = (inputDate: string): string => {
  const formattedDate = dayjs(inputDate).format('DD-MMM-YYYY');
  return formattedDate;
};

export const allowOnlyNumericValues = (input: string): string => {
  return input.replace(/[^0-9]/g, '');
};

export const convertToNationalFormat = (phoneNumber: string): string => {
  // Remove any non-numeric characters
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the number starts with the international code for Nigeria
  if (numericPhoneNumber.startsWith('234')) {
    // Remove the international code and add the national code '0'
    return `0${numericPhoneNumber.slice(3)}`;
  }

  // If the number doesn't start with the international code, it's already in national format
  return numericPhoneNumber;
};