import React from "react";
import { Modal } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { ReactComponent as MtnIcon } from "../../assets/mtn.svg";
import OtpFields from "../../../component/otp/OtpFields";
import SpinnerComponent from "../../../component/spinner/Spinner";
import { useState } from "react";
import { formatAmount } from "../../../helpers/index.helper";
import { useSession } from "../../../hooks/auth/auth.hook";
import { sessionInterface } from "../../../utils/interfaces";
import { useResolveOTPForPin } from "../../../hooks/transfer/index.hook";
import MakeTransferModalMobile from "../../../component/modal/MakeTransferModalMobile";

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 18l-6-6 6-6"
      ></path>
    </svg>
  );
}

const KeyPadPin = ({
  size,
  handleOtpChange,
  processor,
  handleProceed,
  isDisabled,
  value,
}: any) => {
  const { data, refetch } = useSession();
  const { resolve_otp_pin } = useResolveOTPForPin();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const merchant_data: sessionInterface = data?.data?.data;
  const transactionPinSet: boolean | any =
    merchant_data?.state?.transaction_pin_set;
  const sendOTP = async () => {
    setLoading(true);
    try {
      const response = await resolve_otp_pin.mutateAsync();
      if (response.status === 200) {
        setOpen(true);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      return error;
    }
  };
  return (
    <>
      <MakeTransferModalMobile
        show={open}
        mobileStage="set-pin"
        handleClose={() => {
          refetch();
          setOpen(false);
        }}
        refetch={() => {
          refetch();
          setOpen(false);
        }}
        from="profile"
      />
      {transactionPinSet && (
        <div
          className="d-flex flex-column w-100"
          style={{
            marginTop: size?.[0] <= 998 ? "33px" : "48px",
            marginBottom: "40px",
            alignItems: "center",
          }}
        >
          <OtpFields
            numInputs={4}
            secret
            onOtpInput={handleOtpChange}
            style={{
              padding: "0px",
              width: "70%",
              marginTop: "8px",
            }}
            inputMode="number"
            readOnly={false}
            inputStyles={{
              border: "1px solid #CBD1EC",
              width: "57px",
            }}
          />
        </div>
      )}
      {transactionPinSet ? (
        <div
          className="w-100"
          style={{
            marginBottom: "33px",
          }}
        >
          <button
            type={"button"}
            className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
            disabled={processor.isLoading || isDisabled}
            onClick={() => {
              handleProceed();
            }}
            style={{
              borderRadius: "8px",
              background: "#2C1DFF",
            }}
          >
            Pay {value}&emsp;
            {(processor.isLoading || isDisabled) && (
              <span className="align-middle ml-2">
                &emsp;
                <SpinnerComponent size="sm" />
              </span>
            )}
          </button>
        </div>
      ) : (
        <div
          className="w-100"
          style={{
            marginBottom: "33px",
            marginTop: "33px",
          }}
        >
          <button
            type={"button"}
            className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
            disabled={loading}
            onClick={() => {
              sendOTP();
            }}
            style={{
              borderRadius: "8px",
              background: "#2C1DFF",
            }}
          >
            Set Up PIN
          </button>
        </div>
      )}
    </>
  );
};

const PurchaseModal = ({
  show,
  handleClose,
  handlePay,
  processor,
  noModal,
  size,
  title = "airtime",
  isDisabled,
  value,
  length,
}: {
  title?: string;
  show: boolean;
  noModal: boolean;
  size: any;
  handleClose: () => void;
  handlePay: (transaction_pin: string) => void;
  processor: any;
  isDisabled: boolean;
  value?: any;
  length?: any;
}) => {
  const [showPin, setShowPin] = useState(false);
  const [transaction_pin, setTransactionPin] = useState("");
  const handleOtpChange = (otp: any) => {
    setTransactionPin(otp);
  };
  const handleProceed = async () => {
    try {
      await handlePay(transaction_pin);
    } catch (e) {
      return e;
    }
  };

  if (noModal) {
    return (
      <div className="d-flex flex-column justify-content-start align-items-center">
        <div className="d-grid flex-row justify-content-start py-3 align-items-center w-100 position-relative">
          <div
            className="position-absolute left-0"
            style={{ cursor: "pointer" }}
            onClick={!showPin ? handleClose : () => setShowPin(false)}
          >
            <BackIcon />
          </div>
          <p className="text-center mb-0">Complete Transaction</p>
        </div>
        <div
          style={{ maxHeight: "60vh", overflowY: "auto" }}
          className="d-flex flex-column justify-content-center align-items-center w-100"
        >
          <div className="text-center w-100 text-black">
            You are about to purchase {formatAmount(value)} airtime for {length}{" "}
            Phone numbers
          </div>
        </div>

        <KeyPadPin
          {...{
            size,
            handleOtpChange,
            processor,
            handleProceed,
            isDisabled,
            value: formatAmount(value),
          }}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        contentClassName="top-up-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              className="text-black"
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                letterSpacing: "-0.32px",
              }}
            >
              Complete {title} purchase
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column justify-content-start align-items-center">
            <div
              style={{ maxHeight: "60vh", overflowY: "auto" }}
              className="d-flex flex-column justify-content-center align-items-center w-100"
            >
              <div className="text-center w-100 text-black">
                You are about to purchase {formatAmount(value)} airtime for{" "}
                {length} Phone numbers
              </div>
            </div>

            <KeyPadPin
              {...{
                size,
                handleOtpChange,
                processor,
                handleProceed,
                isDisabled,
                value: formatAmount(value),
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default PurchaseModal;
