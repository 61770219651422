import moengage from "@moengage/web-sdk";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KycLevels from "../../../component/modal/KycLevels";
import { PATH_NAMES } from "../../../constants/index.constant";
import {
  encryptKyc,
  mode,
  signatureHarsh,
} from "../../../helpers/index.helper";
import { useSession } from "../../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../../hooks/device.hook";
import {
  useBuisnessCompliance,
  useCheckBuisnessRequirements,
  useGetComplianceRequirements,
} from "../../../hooks/kyc/kyc.hook";
import API_SERVICE from "../../../services/services";
import { BUISNESS_TYPE, KYC_LEVELS } from "../../../utils/enums";
import { sessionInterface } from "../../../utils/interfaces";
import Kyc from "../Kyc";
import ValidIdUnregisteredFields from "./ValidIdUnregisteredFields";

function ValidIdUnregistered(): JSX.Element {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionData } = useSession();
  const { compliance } = useBuisnessCompliance();
  const { complianceData } = useGetComplianceRequirements();
  const { deviceInfo } = useUserDeviceInfo();
  const merchantData: sessionInterface = sessionData?.data?.data;
  const requirementsData =
    complianceData?.data?.data?.unRegistered?.level_two?.account_limits;
  const single_withdrawal = requirementsData?.single_withdrawal;
  const daily_withdrawal = requirementsData?.daily_withdrawal;
  const collection = requirementsData?.collection;

  const merchantId: string | any = localStorage.getItem(
    "merchant_compliance_id"
  );
  const BUSINESS_TYPE: string = BUISNESS_TYPE.UN_REGISTERED;
  const message = merchantId && JSON.stringify({ id: merchantId });
  const x_signature = encryptKyc(message, signatureHarsh);
  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );
  const requirementData: any = requiredData?.data?.data;
  const levelOne: any = requirementData?.level_one;
  const levelTwo: any = requirementData?.level_two;
  const levelThree: any = requirementData?.level_three;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    valid_means_of_identification: "",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value, id, upload } = e.target;
    if (name === "valid_means_of_identification") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (id === "nin_number" || id === "nin_image") {
      setFormData((prevData: any) => ({
        ...prevData,
        nin: {
          ...prevData["nin"],
          [name]: value,
          nin_image: upload,
          upload: upload,
        },
      }));
    } else if (
      id === "number" ||
      id === "upload" ||
      id === "issue_date" ||
      id === "expiry_date"
    ) {
      setFormData((prevData: any) => ({
        ...prevData,
        driver_license: {
          ...prevData["driver_license"],
          [name]: value,
          upload: e?.target?.upload,
        },
      }));
    } else if (
      id === "passport_number" ||
      id === "issue_date" ||
      id === "expiry_date" ||
      id === "passport"
    ) {
      setFormData((prevData: any) => ({
        ...prevData,
        passport: {
          ...prevData["passport"],
          [name]: value,
          upload: e?.target?.upload,
        },
      }));
    }
  };

  const Submit = async (
    formData: any,
    payloadSign: any,
    url?: string,
    proceed?: boolean
  ) => {
    setIsLoading(true);

    const payload =
      proceed === true
        ? {
            KYC_TYPE: BUISNESS_TYPE.UN_REGISTERED,
            KYC_DATA: {
              ...formData,
              proceed: true,
            },
            KYC_MERCHANT_ID: merchantData?.merchant_compliance_id,
            KYC_LEVEL: KYC_LEVELS.LEVEL_TWO,
            x_signature: payloadSign,
          }
        : {
            KYC_TYPE: BUISNESS_TYPE.UN_REGISTERED,
            KYC_DATA: {
              ...formData,
            },
            KYC_MERCHANT_ID: merchantData?.merchant_compliance_id,
            KYC_LEVEL: KYC_LEVELS.LEVEL_TWO,
            x_signature: payloadSign,
          };

    try {
      const response = await compliance.mutateAsync(payload);
      if (response?.status === 200 && formData?.submit === true && url !== "") {
        queryClient.refetchQueries();
        setIsLoading(false);
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.UN_REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.UN_REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        handleShow();
      } else if (
        response?.status === 200 &&
        formData?.submit === false &&
        url !== ""
      ) {
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.UN_REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.UN_REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        navigate(
          `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        );
        setIsLoading(false);
      } else {
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.UN_REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.UN_REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const removeEmpty = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        removeEmpty(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      } else if (obj[key] === "") {
        delete obj[key];
      }
    }
    return obj;
  };

  function validateObject(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (!value) {
          toast.info(
            `${
              key === "nin_image"
                ? "NIN Image"
                : key === "upload"
                ? "document"
                : ""
            } is empty.`
          );
          return false;
        }
        if (typeof value === "object") {
          if (!validateObject(value)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  const checkPayloadBeforeSubmit = (myObject: any) => {
    let result: any;

    // INTL PASSPORT VALIDATIONS
    if (myObject["valid_means_of_identification"] === "passport") {
      if (!myObject["passport"]) {
        toast.info("Kindly fill Intl passport fields and upload document");
        return false;
      } else {
        const passport = myObject["passport"];
        const passport_Keys = Object?.keys(passport);
        if (
          passport_Keys?.length !== 4 ||
          !passport["passport_number"] ||
          !passport["issue_date"] ||
          !passport["expiry_date"]
        ) {
          toast.info("Kindly fill Intl passport fields and upload document");
          result = false;
        }
        if (
          myObject["valid_means_of_identification"] === "passport" &&
          passport_Keys?.length === 4 &&
          passport["passport_number"] &&
          passport["issue_date"] &&
          passport["expiry_date"]
        ) {
          myObject["nin"] && delete myObject["nin"];
          myObject["voter_card"] && delete myObject["voter_card"];
          myObject["driver_license"] && delete myObject["driver_license"];
          result = true;
        }
      }
    }

    // NIN VALIDATIONS
    if (myObject["valid_means_of_identification"] === "nin") {
      if (!myObject["nin"]) {
        toast.info("Kindly fill NIN fields and upload document");
        return false;
      } else {
        const nin = myObject["nin"];
        const nin_Keys = Object?.keys(nin);
        if (
          nin_Keys?.length !== 3 ||
          !nin["nin_number"] ||
          !nin["nin_image"] ||
          !nin["upload"]
        ) {
          toast.info("Kindly fill NIN fields and upload document");
          return false;
        }

        if (
          myObject["valid_means_of_identification"] === "nin" &&
          nin_Keys?.length === 3 &&
          nin["nin_number"] &&
          nin["nin_image"] &&
          nin["upload"]
        ) {
          myObject["passport"] && delete myObject["passport"];
          myObject["voter_card"] && delete myObject["voter_card"];
          myObject["driver_license"] && delete myObject["driver_license"];
          result = true;
        }
      }
    }

    // VOTER CARD VALIDATIONS
    if (myObject["valid_means_of_identification"] === "voter_card") {
      if (!myObject["voter_card"]) {
        toast.info("Kindly fill Voter card fields and upload document");
        result = false;
      } else {
        const voter_card = myObject["voter_card"];
        const voter_card_Keys = Object?.keys(voter_card);
        if (
          voter_card_Keys?.length !== 2 ||
          !voter_card["voter_card_number"] ||
          !voter_card["upload"]
        ) {
          toast.info("Kindly fill Voter card fields and upload document");
          result = false;
        }
        if (
          voter_card_Keys?.length === 2 &&
          myObject["voter_card"]["voter_card_number"] &&
          myObject["voter_card"]["upload"]
        ) {
          myObject["passport"] && delete myObject["passport"];
          myObject["nin"] && delete myObject["nin"];
          myObject["driver_license"] && delete myObject["driver_license"];
          result = true;
        }
      }
    }

    // DRIVER LICENSE VALIDATIONS
    if (myObject["valid_means_of_identification"] === "driver_license") {
      if (!myObject["driver_license"]) {
        toast.info("Kindly fill Driver license fields and upload document");
        result = false;
      } else {
        const driver_license = myObject["driver_license"];
        const driver_license_Keys = Object?.keys(driver_license);
        if (driver_license_Keys?.length !== 4) {
          toast.info("Kindly fill Driver license fields and upload document");
          return false;
        }

        if (
          myObject["valid_means_of_identification"] === "driver_license" &&
          driver_license_Keys?.length === 4 &&
          driver_license["number"] &&
          driver_license["issue_date"] &&
          driver_license["expiry_date"] &&
          driver_license["upload"]
        ) {
          myObject["nin"] && delete myObject["nin"];
          myObject["voter_card"] && delete myObject["voter_card"];
          myObject["passport"] && delete myObject["passport"];
          result = true;
        }
      }
    }

    if (typeof result === "boolean" && result === false) {
      return false;
    }

    if (typeof result === "boolean" && result === true) {
      return myObject;
    }
  };

  const ValidateBeforeSubmit = (
    formData: any,
    mode: string,
    submit: boolean,
    url?: string,
    proceed?: boolean
  ) => {
    const result: any = removeEmpty(formData);
    if (result && validateObject(result) === true) {
      let x: any = formData;
      if (proceed === true) {
        x.proceed = true;
      }
      const payloadSign = encryptKyc(
        JSON.stringify({
          ...x,
          submit: submit,
        }),
        signatureHarsh
      );

      const payload = {
        ...result,
        submit: submit,
      };

      Submit(payload, payloadSign, url, proceed);
    } else {
      // toast.error("Kindly fill all required fields");
    }
  };

  useEffect(() => {
    if (merchantId !== undefined && BUSINESS_TYPE !== undefined) {
      const Id: any = localStorage.getItem("merchant_compliance_id");
      queryClient.fetchQuery(
        ["business-requirements-check", Id, BUSINESS_TYPE, x_signature],
        () =>
          API_SERVICE._checkBuisnessComplianceRequirements(
            BUSINESS_TYPE,
            Id,
            x_signature
          )
      );
      if (levelTwo?.valid_means_of_identification) {
        setFormData((prevData: any) => ({
          ...prevData,
          valid_means_of_identification:
            levelTwo?.valid_means_of_identification,
        }));
      }
      if (levelTwo?.valid_means_of_identification === "nin") {
        setFormData((prevData: any) => ({
          ...prevData,
          nin: {
            ...prevData["nin"],
            nin_image: levelTwo?.nin?.nin_image,
            upload: levelTwo?.nin?.upload,
            nin_number: levelTwo?.nin?.nin_number,
          },
        }));
      }
      if (levelTwo?.valid_means_of_identification === "passport") {
        setFormData((prevData: any) => ({
          ...prevData,
          passport: {
            ...prevData["passport"],
            issue_date: levelTwo?.passport?.issue_date,
            expiry_date: levelTwo?.passport?.expiry_date,
            passport_number: levelTwo?.passport?.passport_number,
            upload: levelTwo?.passport?.upload,
          },
        }));
      }
      if (levelTwo?.valid_means_of_identification === "driver_license") {
        setFormData((prevData: any) => ({
          ...prevData,
          driver_license: {
            ...prevData["driver_license"],
            issue_date: levelTwo?.driver_license?.issue_date,
            expiry_date: levelTwo?.driver_license?.expiry_date,
            number: levelTwo?.driver_license?.number,
            upload: levelTwo?.driver_license?.upload,
          },
        }));
      }
      if (levelTwo?.valid_means_of_identification === "voter_card") {
        setFormData((prevData: any) => ({
          ...prevData,
          voter_card: {
            ...prevData["voter_card"],
            voter_card_number: levelTwo?.voter_card?.voter_card_number,
            upload: levelTwo?.voter_card?.upload,
          },
        }));
      }
    }
  }, [BUSINESS_TYPE, merchantId, queryClient, x_signature, levelTwo]);

  const SaveEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier2_saved: true,
      platform: deviceInfo?.userAgent,
    });
  };

  const SubmitEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier2_submitted: true,
      platform: deviceInfo?.userAgent,
    });
  };

  return (
    <>
      <KycLevels
        show={show}
        handleClose={handleClose}
        onClick={() =>
          navigate(
            `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
          )
        }
        buttonLabel={"Proceed to tier 3"}
      />
      <Kyc activeTab="valid_id" activeMenu="unregistered">
        <div
          id="basicVerStepDetails"
          className="card"
          style={{ minHeight: "15rem" }}
        >
          <div className="card kyc-card-top">
            <div className="row align-items-start mb-0 pb-2 mt-2">
              <div className="col-sm mb-2 mb-sm-0">
                <h2 className="page-header-title text-white pb-3">Tier 2</h2>
                {/* <i class="bi bi-circle"></i> */}
              </div>

              <div className="col-sm-auto">
                {levelTwo?.submit === true ? (
                  <>
                    {/* <button type="button" className="btn  kyc-save-btn" hidden>
                      Request Edit
                    </button> */}
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn  kyc-save-btn"
                    onClick={() => {
                      const payloadSign = encryptKyc(
                        JSON.stringify({
                          ...formData,
                          submit: false,
                        }),
                        signatureHarsh
                      );
                      const payload = {
                        ...formData,
                        submit: false,
                      };
                      Submit(payload, payloadSign, "");
                      SaveEvent("KYC_SAVE_UNREG_BIZ_TIER2");
                    }}
                  >
                    Save changes{" "}
                  </button>
                )}
              </div>
            </div>
            <ul className="ps-0 w-100 w-md-75 w-lg-100 list-unstyled pe-lg-5 me-lg-5">
              {/* SM SCREENS */}
              <li className="text-white listStyleDisc w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Withdrawal limit
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "30%",
                    textAlign: "left",
                  }}
                >
                  &#8358;{single_withdrawal}
                </span>
              </li>
              {/* LG SCREENS */}
              <li className="text-white listStyleDisc w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Withdrawal limit
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  &#8358;{single_withdrawal}
                </span>
              </li>

              {/* SM SCREENS */}
              <li className="text-white listStyleDisc py-1 w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Daily withdrawal limit{" "}
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "30%",
                    textAlign: "left",
                  }}
                >
                  &#8358;{daily_withdrawal}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc py-1 w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Daily withdrawal limit{" "}
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  &#8358;{daily_withdrawal}
                </span>
              </li>

              {/* SM SCREENS */}
              <li className="text-white listStyleDisc w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Maximum Deposit limit{" "}
                </span>
                <span
                  className="float-end text-capitalize"
                  style={{
                    fontSize: "13px",
                    width: "30%",
                    textAlign: "left",
                  }}
                >
                  {collection}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Maximum Deposit limit{" "}
                </span>
                <span
                  className="float-end text-capitalize"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  {collection}
                </span>
              </li>
            </ul>
          </div>
          <div className="card-body active">
            {levelTwo ? (
              <ValidIdUnregisteredFields
                handleChange={handleChange}
                formValues={formData}
                data={levelTwo}
                isDisabled={levelTwo?.submit === true ? true : false}
                setFormData={setFormData}
              />
            ) : (
              <ValidIdUnregisteredFields
                handleChange={handleChange}
                formValues={formData}
                setFormData={setFormData}
              />
            )}
            {/* LG SCREENS */}
            {levelTwo?.submit === true ? null : (
              <>
                <div className="d-none d-md-block d-lg-block pb-3">
                  <div className="d-flex align-items-center justify-content-end mt-auto">
                    <button
                      type="button"
                      className="btn kyc-save-btn"
                      onClick={() => {
                        const finedTunedValue =
                          checkPayloadBeforeSubmit(formData);

                        if (finedTunedValue) {
                          ValidateBeforeSubmit(
                            finedTunedValue,
                            mode,
                            false,
                            "xg",
                            true
                          );
                          SaveEvent("KYC_PROCEED_UNREG_BIZ_TIER2");
                        }
                      }}
                    >
                      Proceed to tier 3{" "}
                      <i className="bi bi-arrow-right small"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        const finedTunedValue =
                          checkPayloadBeforeSubmit(formData);
                        if (finedTunedValue) {
                          ValidateBeforeSubmit(
                            finedTunedValue,
                            mode,
                            true,
                            "cf",
                            true
                          );
                          SubmitEvent("KYC_SUBMIT_UNREG_BIZ_TIER2");
                        }
                      }}
                    >
                      Submit <i className="bi bi-check2 text-white small"></i>
                    </button>
                  </div>
                </div>
                {/* SM SCREENS */}
                <div className="d-sm-block d-md-none d-lg-none">
                  <div className="d-flex align-items-center justify-content-end mt-auto">
                    <button
                      type="button"
                      className="btn kyc-save-btn"
                      onClick={() => {
                        const finedTunedValue =
                          checkPayloadBeforeSubmit(formData);
                        if (finedTunedValue) {
                          ValidateBeforeSubmit(
                            finedTunedValue,
                            mode,
                            false,
                            "d",
                            true
                          );
                          SaveEvent("KYC_PROCEED_UNREG_BIZ_TIER2");
                        }
                      }}
                    >
                      Proceed to tier 3{" "}
                      <i className="bi bi-arrow-right small"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        const finedTunedValue =
                          checkPayloadBeforeSubmit(formData);
                        if (finedTunedValue) {
                          ValidateBeforeSubmit(
                            finedTunedValue,
                            mode,
                            true,
                            "c",
                            true
                          );
                          SubmitEvent("KYC_SUBMIT_UNREG_BIZ_TIER2");
                        }
                      }}
                    >
                      Submit <i className="bi bi-check2 text-white small"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Kyc>
    </>
  );
}

export default ValidIdUnregistered;
