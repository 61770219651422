const TransactionsHeader = () => {
  return (
    <div className="page-header">
      <div className="row align-items-center mb-3">
        <div className="col-sm">
          <h1 className="page-header-title">
            Transactions
            {/* <span className="badge bg-soft-dark text-dark ms-2">
              {isLoading ? <Skeleton width={200} /> : totalItems}
            </span> */}
          </h1>
        </div>
      </div>

      {/* <div className="js-nav-scroller hs-nav-scroller-horizontal">
        <span
          className="hs-nav-scroller-arrow-prev"
          style={{ display: "none" }}
        >
          <a className="hs-nav-scroller-arrow-link" href="javascript:;">
            <i className="bi-chevron-left"></i>
          </a>
        </span>

        <span
          className="hs-nav-scroller-arrow-next"
          style={{ display: "none" }}
        >
          <a className="hs-nav-scroller-arrow-link" href="javascript:;">
            <i className="bi-chevron-right"></i>
          </a>
        </span>

        <ul className="nav nav-tabs page-header-tabs">
          <li className="nav-item">
            <a className="nav-link active" href="#">
              All transaction
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default TransactionsHeader;

// total collection volume
//  total collection value
// totol transaction volume
// total transfer value