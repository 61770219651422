import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  useAirtimeBulkByID,
  useDataBulkByID,
} from "../../../../hooks/top-up/index.hook";
import {
  airtime_purchase_id_map,
  formatAmount,
} from "../../../../helpers/index.helper";
import PaginationComponent from "../../../../component/pagination/pagination";
import { Link } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import "../index.css";
import Table from "../../../../component/table/Table";
import { Bulk_purchase_details_column } from "../../../../configs/dataTable.config";

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 18l-6-6 6-6"
      ></path>
    </svg>
  );
}

const TransactionDetails = () => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const [preview, setPreview] = useState(false);
  const [size, setSize] = React.useState([0, 0]);
  const location = useLocation();
  const navigate = useNavigate();
  const params: any = useParams();
  const searchParams = new URLSearchParams(location.search);
  const { data: DataTransaction, isFetching: dataLoading } = useDataBulkByID(
    params?.id,
    activeItem
  );
  const { data: AirtimeTransaction, isFetching: airtimeLoading } =
    useAirtimeBulkByID(params?.id, activeItem);
  const workingData =
    params.type === "data"
      ? DataTransaction?.data?.data
      : AirtimeTransaction?.data?.data;
  const isLoadingData = params.type === "data" ? dataLoading : airtimeLoading;
  const columns: any = React.useMemo(
    () => [...Bulk_purchase_details_column],
    []
  );

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div>
      {size?.[0] <= 998 && (
        <div className="d-grid flex-row justify-content-center py-3 align-items-center w-100 position-relative">
          <div
            className="position-absolute left-0"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackIcon />
          </div>
          <p className="text-center mb-0 text-capitalize">{searchParams?.get("filename")}</p>
        </div>
      )}
      <div className="w-100 py-4 px-3 position-relative">
        {size?.[0] > 998 && (
          <div
            style={{
              border: "1px solid #CBD1EC",
              borderRadius: 8,
              overflow: "hidden",
            }}
            className="my-5"
          >
            <div className="card-header card-header-content-sm-between py-4 px-4">
              <div className="mb-2 mb-sm-0">
                <h4 className="card-header-title">Recipient details</h4>
              </div>
            </div>

            <div className="table-responsive datatable-custom bg-white">
              <Table
                columns={columns}
                data={workingData !== undefined ? workingData?.items : []}
                isFetching={isLoadingData}
              />
            </div>

            <div className="card-footer bg-white">
              <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                <div className="col-sm mb-2 mb-sm-0"></div>

                <div className="col-sm-auto">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    {workingData?.items?.length > 0 && (
                      <PaginationComponent
                        totalItems={workingData?.totalPages}
                        activeItem={activeItem}
                        setActiveItem={setActiveItem}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {size?.[0] <= 998 && (
          <>
            <div>
              {isLoadingData &&
                ["", "", "", "", "", "", "", "", "", ""].map(
                  (item: any, index: number) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-start pointer"
                      style={{
                        borderBottom: "1px solid #dee2e6",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column p-2">
                          <p className="text-capitalize mb-1 fw-bold">
                            <Skeleton height={30} width={100} />
                          </p>
                          <span
                            className="text-capitalize"
                            style={{ color: "#788088" }}
                          >
                            <Skeleton height={30} width={100} />
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
              {!isLoadingData &&
                workingData?.items?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between bg-white p-2 align-items-start pointer"
                      style={{
                        borderRadius: 8,
                        marginBottom: 20,
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column p-2">
                          <span
                            className="text-capitalize mb-1 fw-semibold"
                            style={{ color: "#000", fontSize: 14 }}
                          >
                            {(item?.network as string) ??
                              (airtime_purchase_id_map[
                                item?.purchase_id
                              ] as string)}{" "}
                            - {item?.mobile_number} &bull; {item?.reference}
                          </span>
                          <span
                            className={`text-capitalize mb-1 fw-bold ${
                              item.status === "pending" &&
                              "badge bg-soft-info text-info"
                            } ${
                              item.status === "processing" &&
                              "badge bg-soft-info text-info"
                            } ${
                              item.status === "successful" &&
                              "badge bg-soft-success text-success"
                            } ${
                              item.status === "failed" &&
                              "badge bg-soft-danger text-danger"
                            }  ${
                              item?.status?.toLowerCase() === "reversed" &&
                              "badge bg-soft-danger text-danger"
                            }`}
                            style={{ fontSize: 14, maxWidth: 100 }}
                          >
                            {item?.status}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {!isLoadingData && workingData?.items.length > 0 && (
              <div className="col-sm-auto mt-2 w-100">
                <div className="d-flex py-4 w-100 justify-content-center justify-content-sm-end">
                  {workingData?.items?.length > 0 && (
                    <PaginationComponent
                      totalItems={workingData?.totalPages}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                    />
                  )}
                </div>
              </div>
            )}
            {!isLoadingData &&
              (workingData?.items.length === 0 || !workingData?.items) && (
                <div className="d-flex justify-content-center align-items-center h-100">
                  You&apos;ve made no Bulk Upload yet.
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionDetails;
