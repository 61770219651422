import React, { Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import CopyButton from "../../component/Copy";
import Tabs from "../../component/Tab/Tab";
import BackBtn from "../../component/back-btn";
import CustomerAccounts from "../../component/customers/CustomerAccounts";
import CustomerInvoice from "../../component/customers/CustomerInvoice";
import CustomerTransactions from "../../component/customers/CustomerTransactions";
import CustomerWallets from "../../component/customers/CustomerWallets";
import UpdateCustomerModal from "../../component/modal/UpdateCustomerModal";

function CustomerDetails(): JSX.Element {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>("invoice");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = location.state;
  return (
    <Fragment>
      <UpdateCustomerModal show={show} handleClose={handleClose} />
      <div className="page-header mb-5">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            {false ? (
              <Skeleton width={200} />
            ) : (
              <h1 className="page-header-title">
                <BackBtn />
                {data?.name}
                &nbsp;
              </h1>
            )}
          </div>
        </div>
      </div>

      {/* CUSTOMER INFORMATION */}
      <div className="card">
        <div className="card-header card-header-content-md-between">
          <div className="mb-2 mb-md-0">
            <h1 className="page-header-title">{data?.name}</h1>
          </div>

          <div className="d-grid d-sm-flex gap-2">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-sm w-100 border border-secondary"
                aria-expanded="false"
                onClick={handleShow}
              >
                Edit customer <i className="bi bi-pencil"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <h4 className="trx-key">Date added</h4>
              <p>23-09-2023</p>{" "}
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4">
              <h4 className="trx-key">Email</h4>
              <p>
                Jerryoneofone@mail,com{" "}
                <CopyButton text={"Jerryoneofone@mail.com"} className="ms-1" />
              </p>{" "}
            </div>

            <div className="col-md-3">
              <h4 className="trx-key">Phone number</h4>
              <p>
                +234 7042420241
                <CopyButton text={"+234 7042420241"} className="ms-1" />
              </p>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 pt-3">
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            {
              label: "Invoice",
              key: "invoice",
            },
            {
              label: "Transactions",
              key: "transactions",
            },
            {
              label: "Accounts",
              key: "accounts",
            },
            {
              label: "Wallets",
              key: "wallets",
            },
          ]}
        />
        {activeTab === "invoice" && (
          <>
            <CustomerInvoice />
          </>
        )}
        {activeTab === "transactions" && (
          <>
            <CustomerTransactions />
          </>
        )}
        {activeTab === "accounts" && (
          <>
            <CustomerAccounts />
          </>
        )}

        {activeTab === "wallets" && (
          <>
            <CustomerWallets />
          </>
        )}
      </div>
    </Fragment>
  );
}

export default CustomerDetails;
