/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
class HeadwayWidgetClass extends Component {
  constructor(props) {
    super(props);
    this.state = { unSeenCount: 0 };
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);
    var config = {
      selector: ".headway-badge",
      account: "7XKZbx",
    };
    script.onload = function () {
      window.Headway.init(config);
    };
  }
  render() {
    return (
      <div className="position-relative">
        <span
          className="bi bi-bell headway-badge float-end"
          id="headway-badge"
          style={{ fontSize: "20px" }}
        ></span>
      </div>
    );
  }
}

export default HeadwayWidgetClass;
