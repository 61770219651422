import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { formprops } from "../../utils/interfaces";


export default function AccountInfo({
  formData,
  handleChange,
  error,
  isValid,
  isEmailValid,
  isFirstNameValid,
  isLastNameValid,
  systemCheck,
}: formprops) {
  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);

  const toggleVisibility = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };

  return (
    <div className="px-4 px-lg-0 overflow-hidden">
      <div className="d-none d-md-block d-lg-block d-xl-block">
        <div className="row mb-4 overflow-hidden">
          <div className="col overflow-hidden">
            <label className="form-label" htmlFor="firstname">
              First Name*
            </label>
            <input
              type="text"
              className={`form-control form-control-lg ${
                error.firstname || isFirstNameValid === false
                  ? "border border-danger"
                  : ""
              } ${
                !error.firstname && formData?.firstname !== ""
                  ? "border border-secondary"
                  : "border border-secondary"
              } overflow-hidden input-fix`}
              name="firstname"
              id="firstname"
              value={formData?.firstname}
              tabIndex={1}
              placeholder="First Name"
              aria-label="first name"
              onChange={handleChange}
              autoComplete="off"
              required
              style={{
                whiteSpace: "normal",
                boxSizing: "border-box",
                overflow: "hidden !important",
              }}
            />
            <span className="invalid-feedback">Please enter first name.</span>
          </div>
          <div className="col overflow-hidden">
            <label className="form-label" htmlFor="signinSrEmail">
              Last Name*
            </label>
            <input
              type="text"
              className={`form-control form-control-lg ${
                error.lastname || isLastNameValid === false
                  ? "border border-danger"
                  : "border border-secondary"
              } ${
                !error.lastname && formData?.lastname !== ""
                  ? "border border-secondary"
                  : "border border-secondary"
              } overflow-hidden input-fix`}
              name="lastname"
              id="lastname"
              tabIndex={1}
              value={formData?.lastname}
              placeholder="Last Name"
              aria-label="last name"
              onChange={handleChange}
              autoComplete="off"
              required
              style={{
                whiteSpace: "normal",
                boxSizing: "border-box",
                overflow: "hidden !important",
              }}
            />
            <span className="invalid-feedback">Please enter last name.</span>
          </div>
        </div>
      </div>

      <div className="mb-4 overflow-hidden d-sm-block d-md-none d-lg-none">
        <label className="form-label" htmlFor="firstname">
          First Name*
        </label>
        <input
          type="text"
          className={`form-control form-control-lg ${
            error.firstname || isFirstNameValid === false ? "is-invalid" : ""
          } ${
            !error.firstname && formData?.firstname !== ""
              ? "border border-secondary"
              : "border border-secondary"
          } overflow-hidden input-fix`}
          name="firstname"
          id="firstname"
          value={formData?.firstname}
          tabIndex={1}
          placeholder="First Name"
          aria-label="first name"
          onChange={handleChange}
          autoComplete="off"
          required
          style={{
            whiteSpace: "normal",
            boxSizing: "border-box",
            overflow: "hidden !important",
          }}
        />
        <span className="invalid-feedback">Please enter first name.</span>
      </div>

      <div className="mb-4 overflow-hidden d-sm-block d-md-none d-lg-none">
        <label className="form-label" htmlFor="signinSrEmail">
          Last Name*
        </label>
        <input
          type="text"
          className={`form-control form-control-lg ${
            error.lastname || isLastNameValid === false
              ? "border border-danger"
              : "border border-secondary"
          } ${
            !error.lastname && formData?.lastname !== ""
              ? "border border-secondary"
              : "border border-secondary"
          } overflow-hidden input-fix`}
          name="lastname"
          id="lastname"
          tabIndex={1}
          value={formData?.lastname}
          placeholder="Last Name"
          aria-label="last name"
          onChange={handleChange}
          autoComplete="off"
          required
          style={{
            whiteSpace: "normal",
            boxSizing: "border-box",
            overflow: "hidden !important",
          }}
        />
        <span className="invalid-feedback">Please enter last name.</span>
      </div>

      <div className="mb-4 overflow-hidden">
        <label className="form-label" htmlFor="email">
          Email*
        </label>
        <input
          type="email"
          className={`form-control form-control-lg ${
            error.email || isEmailValid === false || systemCheck?.email === true
              ? "border border-danger"
              : "border border-secondary"
          } ${
            !error.email && formData?.email !== ""
              ? "border border-secondary"
              : "border border-secondary"
          } overflow-hidden input-fix`}
          name="email"
          id="email"
          tabIndex={1}
          value={formData?.email}
          placeholder="email@address.com"
          aria-label="email@address.com"
          onChange={handleChange}
          autoComplete="off"
          required
          style={{
            whiteSpace: "normal",
            boxSizing: "border-box",
            overflow: "hidden !important",
          }}
        />
        <span className="invalid-feedback">
          {systemCheck?.email === true
            ? "Email Already Exist"
            : "Please enter a valid email address."}
        </span>
      </div>

      <div className="mb-4 overflow-hidden">
        <label className="form-label" htmlFor="mobile_number">
          Phone Number*
        </label>
        <PhoneInput
          country={"ng"}
          value={formData?.mobile_number}
          placeholder="Phone Number"
          onChange={(phoneNumber: any) =>
            handleChange({
              target: { name: "mobile_number", value: phoneNumber },
            })
          }
          inputClass={`form-control form-control-lg w-100 ${
            error.mobile_number || systemCheck?.mobile_number === true
              ? "border border-danger"
              : "border border-secondary"
          } ${
            !error.mobile_number && formData?.mobile_number !== ""
              ? "border border-secondary"
              : "border border-secondary"
          }`}
          inputStyle={{ fontFamily: "BricolageGrotesque" }}
          containerStyle={{ fontFamily: "BricolageGrotesque" }}
        />
        <span className="invalid-feedback">
          {systemCheck?.mobile_number === true
            ? "Mobile Number Already Exist"
            : "Please enter a valid phone number."}
        </span>
      </div>

      <div className="mb-4 overflow-hidden">
        <label className="form-label w-100" htmlFor="password" tabIndex={0}>
          Password*
        </label>
        <div
          className="input-group input-group-merge"
          data-hs-validation-validate-class
        >
          <input
            className={`js-toggle-password form-control form-control-lg ${
              error.password || isValid === false
                ? "border border-danger"
                : "border border-secondary"
            } ${
              !error.password && formData?.password !== "" && isValid === true
                ? "border border-secondary"
                : "border border-secondary"
            } overflow-hidden input-fix`}
            type={passwordOpen ? "text" : "password"}
            name="password"
            id="password"
            placeholder="8+ characters required"
            aria-label="8+ characters required"
            required
            minLength={8}
            onChange={handleChange}
            value={formData?.password}
            autoComplete="off"
            style={{
              whiteSpace: "normal",
              boxSizing: "border-box",
              overflow: "hidden !important",
            }}
          />
          <button
            id="changePassTarget"
            className="input-group-append input-group-text"
          >
            {!eyeOpen ? (
              <i
                id="changePassIcon"
                className="bi-eye"
                onClick={() => {
                  toggleVisibility();
                  setEyeOpen(!eyeOpen);
                }}
              />
            ) : (
              <i
                className="bi bi-eye-slash"
                onClick={() => {
                  toggleVisibility();
                  setEyeOpen(!eyeOpen);
                }}
              />
            )}
          </button>
        </div>

        {error.password && isValid === false ? (
          <span className="invalid-feedback">
            Please enter a valid password.
          </span>
        ) : (
          ""
        )}
        <span
          className={`form-text ${isValid === true ? "text-success" : ""} ${
            isValid === false ? "text-danger" : ""
          }`}
        >
          Your password must be 8-20 characters long, atleast one uppercase
          letter, one lowercase letter, one number and one special character.
        </span>
      </div>
    </div>
  );
}
