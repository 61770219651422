import { useMutation, useQuery } from "react-query";
import { initQueryOptions } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import { RequestMethod } from "../../utils/enums";
import { instance } from "../../utils/instance";
import { payoutAccountInterface } from "../../utils/interfaces";

export const useGetBanks = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["banks"],
    () => API_SERVICE._getBanks(),
    initQueryOptions()
  );
  const bankData = data;
  return { data, isLoading, isError, isFetching, refetch, bankData };
};

export const useResolveAccount = () => {
  const resolve_account: any = useMutation(
    (data: { account_no: string; bank_code: string }): any => {
      return instance({
        url: `/merchant/payouts/resolve?account_no=${data.account_no}&bank_code=${data.bank_code}`,
        method: RequestMethod.POST,
      });
    },
    {
      ...initQueryOptions(),
    }
  );
  return { resolve_account };
};

export const useCreatePayoutAccount = () => {
  const createPayoutAcc = useMutation(
    (data: payoutAccountInterface): any => {
      return instance({
        url: `/merchant/payouts/create`,
        method: RequestMethod.POST,
        data: data,
      });
    },
    {
      ...initQueryOptions(),
    }
  );
  return { createPayoutAcc };
};

export const useGetPayoutAccount = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["payout-account"],
    () => API_SERVICE._getPayoutAccount(),
    initQueryOptions()
  );
  return { data, isLoading, isError, isFetching, refetch };
};

export const useInitiatePayout = () => {
  const initiate_payout = useMutation(
    (data: { amount: number; auth_code?: string }): any => {
      return instance({
        url: `/merchant/payouts/process`,
        method: RequestMethod.POST,
        data: data,
      });
    },
    {
      ...initQueryOptions(),
    }
  );
  return { initiate_payout };
};

export const useGetPayoutTransactions = (page: number) => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["payout-transactions", page],
    () => API_SERVICE._getPayoutTransactions(page),
    initQueryOptions()
  );
  const payoutInfo = data;
  return { data, isLoading, isError, isFetching, refetch, payoutInfo };
};
