import moengage from "@moengage/web-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import { ReactComponent as Logo } from "../../assets/svg/logos/Account-illustration.svg";
import { useSession } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  useCreatePayoutAccount,
  useGetBanks,
  useGetPayoutAccount,
  useResolveAccount,
} from "../../hooks/payouts/payouts.hook";
import { Iaccount, bankInterface } from "../../utils/interfaces";
import CopyButton from "../Copy";
import SpinnerComponent from "../spinner/Spinner";

type Props = {
  location?: string;
  merchant_data?: any;
};

function ViewAccountModal({ location, merchant_data }: Props) {
  const { data } = useGetPayoutAccount();
  const { deviceInfo } = useUserDeviceInfo();
  const [update, setUpdate] = useState<boolean>(false);
  const accountDetails: Iaccount = data?.data?.data;

  // UPDATE FUNCTIONALITY
  const queryClient = useQueryClient();
  const closeButton = useRef<HTMLDivElement | null>(null);
  const cancelButton = useRef<HTMLDivElement | null | any>(null);
  const { refetch } = useSession();
  const { bankData, isLoading } = useGetBanks();
  const { resolve_account } = useResolveAccount();
  const { createPayoutAcc } = useCreatePayoutAccount();
  const banks: bankInterface[] = bankData?.data?.data;

  const [hidden, setHidden] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [account_number, setAccountNumber] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [checkName, setCheckName] = useState<boolean>(false);

  const [bank, setBank] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    createPayoutAcc
      .mutateAsync({
        account_name: accountName,
        account_number: account_number,
        bank_code: bank?.bank_code,
        bank_name: bank?.bank_name,
      })
      .then(() => {
        toast.success("account updated successfully");
        moengage.track_event("UPDATE_PAYOUT_ACC", {
          account_number: account_number,
          bank_name: bank?.bank_name,
          success_status: true,
          platform: deviceInfo?.userAgent,
        });
        queryClient.refetchQueries();
        setUpdate(false);
      })
      .catch((error: any) => {
        return error;
      })
      .finally(() => {
        setLoading(false);
        closeButton.current?.click();
        setBank("");
        setAccountNumber("");
        setAccountName("");
        refetch();
        queryClient.refetchQueries();
      });
  };

  useEffect(() => {
    moengage.track_event("PAYOUT_MODULE", {
      account_id: accountDetails?.account_number,
      platform: deviceInfo?.userAgent,
    });
  }, [accountDetails?.account_number, deviceInfo?.userAgent]);

  return (
    <div
      className="modal fade"
      id="welcomeMessageModal"
      tabIndex={-1}
      aria-hidden="true"
    >
      {update === false ? (
        <div className="modal-dialog modal-l modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-close">
              <button
                type="button"
                className="btn btn-ghost-secondary btn-icon btn-sm"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi-x-lg"></i>
              </button>
            </div>

            {location === "home" ? (
              <div className="modal-body p-sm-5">
                <div className="text-center">
                  <Logo style={{ width: "100px", height: "100px" }} />
                </div>
                <div className="card-body">
                  <div className="list-group list-group-flush list-group-no-gutters">
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="card-subtitle">
                                Account Name
                              </span>
                              <span
                                className=" fw-bold text-cap"
                                style={{ fontSize: "11px", marginBottom: "0" }}
                              >
                                <i className="bi-person dropdown-item-icon"></i>
                                {merchant_data?.business_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="card-subtitle">
                                Account Number
                              </span>
                              <span
                                className=" fw-bold text-cap"
                                style={{ fontSize: "11px", marginBottom: "0" }}
                              >
                                <i className="bi bi-patch-minus dropdown-item-icon"></i>
                                {merchant_data?.account_no}
                                <CopyButton text={merchant_data?.account_no} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="card-subtitle">Bank Name</span>
                              <span
                                className=" fw-bold text-cap"
                                style={{ fontSize: "11px", marginBottom: "0" }}
                              >
                                {merchant_data?.primary_bank?.name}
                                <CopyButton
                                  text={merchant_data?.primary_bank?.name}
                                />
                                &nbsp;
                                <span style={{ color: "#2C1DFF" }}>
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="10"
                                      height="10"
                                      rx="5"
                                      fill="#2C1DFF"
                                    />
                                  </svg>
                                  &emsp;Primary Bank
                                </span>
                              </span>
                              {merchant_data?.secondary_banks?.map((i: any) => (
                                <span
                                  className=" fw-bold mt-1 text-cap"
                                  key={i?.bank_code}
                                  style={{
                                    fontSize: "10px",
                                    marginBottom: "0",
                                  }}
                                >
                                  {i?.name}
                                  <CopyButton text={i?.name} />
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-body p-sm-5">
                <div className="text-center">
                  <Logo style={{ width: "100px", height: "100px" }} />
                </div>
                <div className="card-body">
                  <div className="list-group list-group-flush list-group-no-gutters">
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="card-subtitle">Bank Name</span>
                              <span
                                className=" fw-bold text-cap"
                                style={{ fontSize: "11px", marginBottom: "0" }}
                              >
                                <i className="bi-building dropdown-item-icon"></i>
                                {accountDetails?.bank_name}
                                <CopyButton text={accountDetails?.bank_name} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="card-subtitle">
                                Account Number
                              </span>
                              <span
                                className=" fw-bold text-cap"
                                style={{ fontSize: "11px", marginBottom: "0" }}
                              >
                                <i className="bi bi-patch-minus dropdown-item-icon"></i>
                                {accountDetails?.account_number}
                                <CopyButton
                                  text={accountDetails?.account_number}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="card-subtitle">
                                Account Name
                              </span>
                              <span
                                className=" fw-bold text-cap"
                                style={{ fontSize: "11px" }}
                              >
                                <i className="bi-person dropdown-item-icon"></i>
                                {accountDetails?.account_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <button
                      onClick={() => {
                        setUpdate(true);
                      }}
                      type="button"
                      className="btn btn-primary"
                    >
                      Update Account
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title card-subtitle"
                id="createCashierModalLabel"
              >
                Update Account
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={cancelButton}
                onClick={() => {
                  setUpdate(false);
                  setBank("");
                  setAccountNumber("");
                  setAccountName("");
                }}
              ></button>
            </div>

            <div className="modal-body">
              <div className="row">
                {/* ACCOUNT NUMBER */}
                <div className="col">
                  <label
                    className="form-label card-subtitle"
                    htmlFor="account_number"
                  >
                    Account Number
                  </label>
                  <input
                    type="text"
                    className={`form-control mb-3 ${
                      error?.account_number === false ? "is-valid" : ""
                    } border border-secondary`}
                    placeholder=""
                    name="account_number"
                    value={account_number}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAccountNumber(e.target.value)
                    }
                  />
                </div>
              </div>

              {/* ACCOUNT NAME */}

              {checkName ? (
                <div className="row">
                  <div className="col">
                    <div className="col mx-auto text-center pt-2">
                      <SpinnerComponent animation="border" variant="dark" />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {accountName !== "" ? (
                    <div className="row">
                      <div className="col">
                        <div className="col">
                          <label
                            className="form-label card-subtitle"
                            htmlFor="account_name"
                          >
                            Account Name
                          </label>
                          <input
                            type="text"
                            className={`form-control mb-3 ${
                              accountName ? "is-valid" : ""
                            } border border-secondary`}
                            placeholder=""
                            value={accountName}
                            name="account_name"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}

              <div className="row">
                <div className="col">
                  <label className="form-label card-subtitle" htmlFor="bank">
                    Bank
                  </label>
                  <Select
                    options={banks}
                    isLoading={isLoading}
                    getOptionLabel={(option) => option.bank_name}
                    getOptionValue={(option) => option.bank_code}
                    isSearchable
                    isClearable
                    menuPlacement="bottom"
                    placeholder="Search..."
                    onChange={async (option) => {
                      setBank(option);
                      setCheckName(true);
                      try {
                        const response: any = await resolve_account.mutateAsync(
                          {
                            account_no: account_number,
                            bank_code: option?.bank_code,
                          }
                        );
                        if (response.status === 200) {
                          setCheckName(false);
                          const account_name =
                            response?.data?.data?.account_name;
                          setAccountName(account_name);
                          setHidden(false);
                          setError({
                            account_name: false,
                            account_number: false,
                            bank_name: false,
                          });
                        }
                      } catch (error) {
                        setCheckName(false);
                        return error;
                      }
                    }}
                  />
                  <span className="invalid-feedback">
                    Please select a bank.
                  </span>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row align-items-sm-center flex-grow-1 mx-n2">
                <div className="col-sm mb-2 mb-sm-0"></div>

                <div className="col-sm-auto">
                  <div className="d-flex gap-3">
                    <div
                      className="btn btn-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ref={closeButton}
                      onClick={() => {
                        cancelButton.current?.click();
                        setBank("");
                        setAccountNumber("");
                        setAccountName("");
                        setUpdate(false);
                      }}
                    >
                      Cancel
                    </div>
                    <button
                      disabled={hidden}
                      onClick={handleSubmit}
                      type="button"
                      className="btn btn-primary"
                    >
                      Submit{" "}
                      {loading && (
                        <>
                          &nbsp; <SpinnerComponent size="sm" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewAccountModal;
