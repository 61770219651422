export const PATH_NAMES = {
  root: "/",
  accounts: "accounts",
  accountDetails: "accounts/details/:id",
  topup: 'topup',
  bulkTopup: 'topup/bulk',
  transfer: "transfer",
  allTransfer: "transfer/all",
  transferDetails: "transfer/details/:id",
  transactions: "transactions",
  transactionDetails: "transactions/details/:id",
  usettled_transactionDetails: "transactions/unsettled/:id",
  payLink: "business/pay-link",
  template: "business/pay-link/templates",
  createLink: "business/one-time-link",
  createRecurringLink: "business/recurring-link",
  payLinkDetails: "business/pay-link/details/:id",
  cashier: "business/cashier",
  payouts: "business/payouts",
  profile: "profile",
  kyc: "kyc",
  signup: "/signup",
  signin: "/signin",
  verify_otp: "/verify_otp",
  otp: "/otp",
  auth: "/auth/*",
  preview: "/payment-link/preview",
  verify: "/verifyOtp",
  verify_email: "/verify_email",
  get_started: "get_started",
  virtual_accounts: "virtual-accounts",
  make_transferred: "make_transfer",

  merchants: "/merchants",
  merchantDetails: "/merchant/details/:id",
  adminTransactions: "/admin/transactions",
  adminTransactionDetails: "/admin/transactions/details/:id",
  client: "/merchant/*",
  client_home: "/merchant",
  client_auth: "/auth/merchant/*",
  forgot_password: "/forgot_password",
  reset_password: "/reset_password",
  // CASHIER DASHBOARD AND ROUTES
  cashierDashboard: "/cashier/*",
  cashier_setup: "/cashier/setup",
  cashier_auth: "/auth/cashier/*",
  // SUPER ADMIN ROUTES
  admin_home: "/admin",
  admin: "/admin/*",
  admin_auth: "/auth/admin/*",
  invoices: "invoices",
  customers: "customers",
  products: "products",
};

export const TOKEN_STRING = "merchant_token";
export const CASHIER_STRING = "cashier_token";
export const SUPER_ADMIN_STRING = "super_admin_token";
export const AUTH_BASE_PATH = "/merchant/auth";
export const MERCHANT_BASE_PATH = "/merchant";
