import React from "react";
import "./loader.css";

function Loader(): JSX.Element {
  return (
    <div className="d-flex justify-content-center align-items-center loader-container">
      <div className="loadingio-spinner-spinner-zefk90pf4qd">
        <div className="ldio-we0kgaonmrr">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
