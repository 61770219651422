import moengage from "@moengage/web-sdk";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Tabs from "../../component/Tab/Tab";
import PaginationComponent from "../../component/pagination/pagination";
import { formatAmount, mode } from "../../helpers/index.helper";
import useUserDeviceInfo from "../../hooks/device.hook";
import { useAirtime, useData } from "../../hooks/top-up/index.hook";
import {
  convertDateFormat,
  convertShortDateFormat,
  convertToNationalFormat,
} from "../../utils/decode";
import { AccountBalance } from "../home/Home";
import Airtime from "./Airtime";
import Data, { providers } from "./Data";

function MoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <g filter="url(#filter0_d_239_15359)">
        <rect width="32" height="32" x="12" y="10" fill="#fff" rx="16"></rect>
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M28 26.75a.75.75 0 100-1.5.75.75 0 000 1.5zM33.25 26.75a.75.75 0 100-1.5.75.75 0 000 1.5zM22.75 26.75a.75.75 0 100-1.5.75.75 0 000 1.5z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_239_15359"
          width="56"
          height="56"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_239_15359"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_239_15359"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 18l-6-6 6-6"
      ></path>
    </svg>
  );
}

const ToUp = () => {
  const { deviceInfo } = useUserDeviceInfo();
  const [activeTab, setActiveTab] = React.useState<string>("airtime");
  const [show, setShow] = React.useState(false);
  const [pages, setPage] = React.useState(1);
  const [stage, setStage] = React.useState<string>("init");
  const [size, setSize] = React.useState([0, 0]);
  const {
    data: DataTransaction,
    isFetching: dataLoading,
    refetch: refetchData,
  } = useData(pages);
  const {
    data: AirtimeTransaction,
    isFetching: airtimeLoading,
    refetch: refetchAirtime,
  } = useAirtime(pages);

  const TransactionsItems =
    activeTab === "airtime"
      ? AirtimeTransaction?.data?.data
      : DataTransaction?.data?.data;

  React.useEffect(() => {
    setPage(0);
  }, [activeTab]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleRefetch = () => {
    refetchData();
    refetchAirtime();
    moengage.track_event("REFRESH_TOP_HISTORY", {
      platform: deviceInfo?.userAgent,
      success_status: true,
    });
  };

  return (
    <React.Fragment>
      {size?.[0] <= 998 ? (
        <React.Fragment>
          {stage !== "pay" && stage !== "history" && (
            <>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  padding: "0px 0px 12px",
                }}
                className="w-100 d-flex flex-row justify-content-between"
              >
                <div
                  style={{
                    color: "black",
                    fontSize: 24,
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  Top up
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShow(!show)}
                >
                  <MoreIcon />
                </div>
                {show && (
                  <div
                    className="shadow bg-white position-absolute"
                    style={{
                      height: "fit-content",
                      top: "50px",
                      right: "26px",
                      cursor: "pointer",
                      zIndex: "9999",
                      borderRadius: "8px",
                    }}
                  >
                    <>
                      <Link
                        className="d-flex align-items-center p-2 justify-content-start"
                        to={`/merchant/topup/bulk?type=${activeTab}`}
                      >
                        <i className="bi bi-calendar text-dark"></i>
                        <p className="mb-0 px-2">Bulk {activeTab} purchase</p>
                      </Link>
                      <hr className="my-0" />
                    </>
                    <div
                      className="d-flex align-items-center p-2 justify-content-start"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setStage("history");
                        setShow(false);
                      }}
                    >
                      <i className="bi bi-arrow-down-up"></i>
                      <p className="mb-0 px-2">Top up history</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-center align-items-center pt-2 position-relative">
                <div
                  className="w-100 border-bottom border-2"
                  style={{
                    position: "absolute",
                    bottom: "9px",
                    left: 0,
                    borderBottom: "1px solid #CBD1EC",
                  }}
                />
                <Tabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  tabs={[
                    {
                      label: "Airtime",
                      key: "airtime",
                      onTabClick: () => {
                        moengage.track_event("AIRTIME_TAB", {
                          success_status: true,
                          platform: deviceInfo?.userAgent,
                        });
                      },
                    },
                    {
                      label: "",
                      key: activeTab,
                      empty: true,
                    },
                    {
                      label: "Data",
                      key: "data",
                      onTabClick: () => {
                        moengage.track_event("AIRTIME_TAB", {
                          success_status: true,
                          platform: deviceInfo?.userAgent,
                        });
                      },
                    },
                  ]}
                />
              </div>
              <div className="pt-5">
                <AccountBalance />
              </div>
            </>
          )}
          <div className="mx-auto pt-3" style={{ width: "95%" }}>
            {activeTab === "airtime" && (
              <Airtime {...{ stage, size, setSize, setStage, handleRefetch }} />
            )}
            {activeTab === "data" && (
              <Data {...{ stage, size, setSize, setStage, handleRefetch }} />
            )}
          </div>

          {stage === "history" && (
            <>
              <div className="d-grid flex-row justify-content-center py-3 align-items-center w-100 position-relative">
                <div
                  className="position-absolute left-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => setStage("init")}
                >
                  <BackIcon />
                </div>
                <p className="text-center mb-0">Top up history</p>
              </div>
              <div>
                {(dataLoading || airtimeLoading) &&
                  ["", "", "", "", "", "", "", "", "", ""].map(
                    (item: any, index: number) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-center pointer"
                        style={{
                          borderBottom: "1px solid #dee2e6",
                          cursor: "pointer",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex flex-column p-2"
                            style={{ width: 150 }}
                          >
                            <p className="text-capitalize mb-1 fw-bold">
                              <Skeleton height={30} />
                            </p>
                            <span
                              className="text-capitalize"
                              style={{ color: "#788088" }}
                            >
                              <Skeleton height={30} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="d-flex flex-column p-2"
                          style={{ width: 150 }}
                        >
                          <p
                            className="text-capitalize mb-1 fw-bold"
                            style={{ textAlign: "right" }}
                          >
                            <Skeleton height={30} />
                          </p>
                          <span
                            className="text-capitalize"
                            style={{ color: "#788088" }}
                          >
                            <Skeleton height={30} />
                          </span>
                        </div>
                      </div>
                    )
                  )}
                {(!dataLoading || !airtimeLoading) &&
                  TransactionsItems?.items?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center pointer"
                      style={{
                        borderBottom: "1px solid #dee2e6",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            providers.filter(
                              (provider) => item?.telco === provider?.name
                            )?.[0]?.icon
                          }
                          alt={
                            providers.filter(
                              (provider) => item?.telco === provider?.name
                            )?.[0]?.name
                          }
                          style={{ height: 24, width: 24, marginLeft: "5px" }}
                        />
                        <div className="d-flex flex-column p-2">
                          <p className="text-capitalize mb-1 fw-bold">
                            {convertToNationalFormat(item.mobile_number)} &bull;{" "}
                            {item.reference}
                          </p>
                          <span
                            className="text-capitalize"
                            style={{ color: "#788088" }}
                          >
                            {activeTab} &bull;{" "}
                            {convertShortDateFormat(item.created_at)}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column p-2">
                        <p
                          className="text-capitalize mb-1 fw-bold"
                          style={{ textAlign: "right" }}
                        >
                          {formatAmount(item.amount ?? item.topup_amount)}
                        </p>
                        <span
                          className={`text-capitalize ${
                            item.status === "pending" &&
                            "badge bg-soft-info text-info"
                          } ${
                            item.status === "processing" &&
                            "badge bg-soft-info border-info border rounded-pill text-info"
                          } ${
                            item.status === "successful" &&
                            "badge bg-soft-success border-success border rounded-pill text-success"
                          } ${
                            item.status === "failed" &&
                            "badge bg-soft-danger border-danger border rounded-pill text-danger"
                          }  ${
                            item?.status?.toLowerCase() === "reversed" &&
                            "badge bg-soft-danger border-danger border rounded-pill text-danger"
                          }`}
                        >
                          {item.status}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              {(!dataLoading || !airtimeLoading) &&
                TransactionsItems?.items.length > 0 && (
                  <div className="col-sm-auto mt-2 w-100">
                    <div className="d-flex py-4 w-100 justify-content-center justify-content-sm-end">
                      {TransactionsItems?.items?.length > 0 && (
                        <PaginationComponent
                          totalItems={TransactionsItems?.totalPages}
                          activeItem={pages}
                          setActiveItem={setPage}
                        />
                      )}
                    </div>
                  </div>
                )}
              {(!dataLoading || !airtimeLoading) &&
                (TransactionsItems?.items.length === 0 ||
                  !TransactionsItems) && (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    You&apos;ve made no Top up transaction yet.
                  </div>
                )}
            </>
          )}
        </React.Fragment>
      ) : (
        <div className="container-fluid">
          <div className="d-flex justify-content-end">
            <Link
              className="btn btn-primary mb-4 mt-2"
              to={`/merchant/topup/bulk?type=${activeTab}`}
            >
              Bulk {activeTab} purchase
            </Link>
          </div>
          <div className="d-flex justify-content-between h-100 mx-auto">
            {/* top-up actions */}
            <div className="flex-grow-1 w-50 bg-body">
              <div className="d-flex justify-content-center align-items-center pt-4 position-relative">
                <div
                  className="w-100 border-bottom border-2"
                  style={{
                    position: "absolute",
                    bottom: "9px",
                    left: 0,
                    borderBottom: "1px solid #CBD1EC",
                  }}
                />
                <Tabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  tabs={[
                    {
                      label: "Airtime",
                      key: "airtime",
                      onTabClick: () => {
                        moengage.track_event("AIRTIME_TAB", {
                          success_status: true,
                          platform: deviceInfo?.userAgent,
                        });
                      },
                    },
                    {
                      label: "Data",
                      key: "data",
                      onTabClick: () => {
                        moengage.track_event("DATA_TAB", {
                          success_status: true,
                          platform: deviceInfo?.userAgent,
                        });
                      },
                    },
                  ]}
                />
              </div>
              {/* content */}
              <div
                className="mx-auto pt-5 h-100 position-relative"
                style={{
                  width: "100%",
                  minHeight: "70vh",
                  overflowY: "auto",
                }}
              >
                {activeTab === "airtime" && (
                  <Airtime
                    {...{ stage, size, setSize, setStage, handleRefetch }}
                  />
                )}
                {activeTab === "data" && (
                  <Data
                    {...{ stage, size, setSize, setStage, handleRefetch }}
                  />
                )}
              </div>
            </div>

            {/* Transacton history */}
            <div className="flex-grow-1 w-50 bg-light">
              <div
                className="d-flex justify-content-between align-items-center  border-bottom border-2"
                style={{
                  borderBottom: "1px solid #CBD1EC",
                  paddingBottom: "7.9px",
                }}
              >
                <h4 className="text-dark px-5 pt-4">Top up history</h4>

                <div className="dropdown d-flex">
                  <button
                    type="button"
                    className="btn btn-white btn-sm w-100"
                    aria-expanded="false"
                    onClick={() => {
                      handleRefetch();
                    }}
                  >
                    <i className="bi bi-arrow-clockwise me-2"></i> Refresh
                  </button>
                  &emsp;
                </div>
              </div>
              <div
                style={{
                  maxWidth: "630px",
                  overflowX: "scroll",
                }}
              >
                <div
                  style={{
                    maxWidth: "530px",
                    overflowX: "scroll",
                    display: "table",
                  }}
                >
                  {(dataLoading || airtimeLoading) &&
                    ["", "", "", "", "", "", "", "", "", ""].map(
                      (item: any, index: number) => (
                        <div
                          style={{ borderBottom: "1px #CBD1EC solid" }}
                          className="d-flex flex-row w-100 py-3 px-4"
                        >
                          <div
                            style={{
                              textAlign: "left",
                              color: "#677788",
                              fontSize: 16,
                              fontWeight: "400",
                              wordWrap: "break-word",
                              width: "150px",
                            }}
                          >
                            <Skeleton height={30} />
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              color: "#677788",
                              fontSize: 16,
                              fontWeight: "400",
                              wordWrap: "break-word",
                              width: "100px",
                            }}
                          >
                            <Skeleton height={30} />
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              color: "#677788",
                              fontSize: 16,
                              fontWeight: "400",
                              wordWrap: "break-word",
                              width: "100px",
                            }}
                          >
                            <Skeleton height={30} />
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              color: "#677788",
                              fontSize: 16,
                              fontWeight: "400",
                              wordWrap: "break-word",
                              width: "130px",
                            }}
                          >
                            <Skeleton height={30} />
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              color: "#677788",
                              fontSize: 16,
                              fontWeight: "400",
                              wordWrap: "break-word",
                              width: "250px",
                            }}
                          >
                            <Skeleton height={30} />
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              color: "#677788",
                              fontSize: 16,
                              fontWeight: "400",
                              wordWrap: "break-word",
                              width: "250px",
                            }}
                          >
                            <Skeleton height={30} />
                          </div>
                        </div>
                      )
                    )}
                  {!(dataLoading || airtimeLoading) &&
                    TransactionsItems?.items.length > 0 && (
                      <div
                        className="d-flex flex-row  w-100 py-3 px-4"
                        style={{
                          background: "#EAEDF7",
                          borderBottom: "1px #CBD1EC solid",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            color: "#8F9BB2",
                            fontSize: 14,
                            fontWeight: "600",
                            wordWrap: "break-word",
                            width: "150px",
                          }}
                        >
                          Phone number
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#8F9BB2",
                            fontSize: 14,
                            fontWeight: "600",
                            wordWrap: "break-word",
                            width: "100px",
                          }}
                        >
                          Network
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#8F9BB2",
                            fontSize: 14,
                            fontWeight: "600",
                            wordWrap: "break-word",
                            width: "100px",
                          }}
                        >
                          Status
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#8F9BB2",
                            fontSize: 14,
                            fontWeight: "600",
                            wordWrap: "break-word",
                            width: "130px",
                          }}
                        >
                          Amount
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#8F9BB2",
                            fontSize: 14,
                            fontWeight: "600",
                            wordWrap: "break-word",
                            width: "250px",
                          }}
                        >
                          Date
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#8F9BB2",
                            fontSize: 14,
                            fontWeight: "600",
                            wordWrap: "break-word",
                            width: "200px",
                          }}
                        >
                          Reference
                        </div>
                      </div>
                    )}
                  {(!dataLoading || !airtimeLoading) &&
                    TransactionsItems?.items?.map((item: any) => (
                      <div
                        style={{ borderBottom: "1px #CBD1EC solid" }}
                        className="d-flex flex-row w-100 py-3 px-4"
                      >
                        <div
                          style={{
                            textAlign: "left",
                            color: "#677788",
                            fontSize: 16,
                            fontWeight: "400",
                            wordWrap: "break-word",
                            width: "150px",
                          }}
                        >
                          {convertToNationalFormat(item.mobile_number)}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#677788",
                            fontSize: 16,
                            fontWeight: "400",
                            wordWrap: "break-word",
                            width: "100px",
                          }}
                        >
                          {item.telco}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#677788",
                            fontSize: 16,
                            fontWeight: "400",
                            wordWrap: "break-word",
                            width: "100px",
                          }}
                        >
                          <div
                            className={`text-capitalize ${
                              item.status === "pending" &&
                              "badge bg-soft-info text-info"
                            } ${
                              item.status === "processing" &&
                              "badge bg-soft-info text-info"
                            } ${
                              item.status === "successful" &&
                              "badge bg-soft-success text-success"
                            } ${
                              item.status === "failed" &&
                              "badge bg-soft-danger text-danger"
                            }  ${
                              item?.status?.toLowerCase() === "reversed" &&
                              "badge bg-soft-danger text-danger"
                            }`}
                          >
                            {item.status}
                          </div>
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#677788",
                            fontSize: 16,
                            fontWeight: "400",
                            wordWrap: "break-word",
                            width: "130px",
                          }}
                        >
                          {formatAmount(item.amount ?? item.topup_amount)}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#677788",
                            fontSize: 16,
                            fontWeight: "400",
                            wordWrap: "break-word",
                            width: "250px",
                          }}
                        >
                          {convertDateFormat(item.created_at)}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#677788",
                            fontSize: 16,
                            fontWeight: "400",
                            wordWrap: "break-word",
                            width: "200px",
                          }}
                        >
                          {item.reference}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {TransactionsItems?.items.length > 0 && (
                <div className="col-sm-auto mt-2">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    {TransactionsItems?.items?.length > 0 && (
                      <PaginationComponent
                        totalItems={TransactionsItems?.totalPages}
                        activeItem={pages}
                        setActiveItem={setPage}
                      />
                    )}
                  </div>
                </div>
              )}

              {(TransactionsItems?.items.length === 0 ||
                !TransactionsItems) && (
                <div className="d-flex justify-content-center align-items-center h-100">
                  You&apos;ve made no Top up transaction yet.
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ToUp;
