import React, { useEffect, useLayoutEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { PATH_NAMES } from "../../constants/index.constant";
import { kycProps } from "../../utils/interfaces";
// import { useSession } from "../../hooks/auth/auth.hook";
import { encryptKyc, signatureHarsh } from "../../helpers/index.helper";
import { useCheckBuisnessRequirements } from "../../hooks/kyc/kyc.hook";
import API_SERVICE from "../../services/services";
import { BUISNESS_TYPE } from "../../utils/enums";

function KycRegisteredBusinessSideBar({
  activeTab,
  isFilled,
}: kycProps): JSX.Element {
  const queryClient = useQueryClient();
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const merchantId: string | any = localStorage.getItem(
    "merchant_compliance_id"
  );
  const BUSINESS_TYPE: string = BUISNESS_TYPE.REGISTERED;

  const message = merchantId && JSON.stringify({ id: merchantId });

  const x_signature = encryptKyc(message, signatureHarsh);

  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );

  const requirementsData: any = requiredData?.data?.data;
  const general: any = requirementsData?.general;
  const levelOne: any = requirementsData?.level_one;
  const levelTwo: any = requirementsData?.level_two;

  const approvedLevelOne = requirementsData?.approved_level_one;
  const approvedLevelTwo = requirementsData?.approved_level_two;

  useEffect(() => {
    if (merchantId !== undefined && BUSINESS_TYPE !== undefined) {
      queryClient.fetchQuery(
        ["business-requirements-check", merchantId, BUSINESS_TYPE, x_signature],
        () =>
          API_SERVICE._checkBuisnessComplianceRequirements(
            BUSINESS_TYPE,
            merchantId,
            x_signature
          )
      );
    }
  }, [BUSINESS_TYPE, merchantId, queryClient, x_signature]);

  const handleBusinessSwitch = () => {
    if (approvedLevelOne === true || approvedLevelTwo === true) {
      return (
        <span
          className={`badge bg-soft-success text-success ${
            size?.[0] === 1024 ? "ms-0" : "ms-2"
          }`}
        >
          <span className="legend-indicator bg-success"></span>
          Approved
        </span>
      );
    } else if (levelOne?.submit === true || levelTwo?.submit === true) {
      return (
        <span
          className={`badge bg-soft-warning text-warning ${
            size?.[0] === 1024 ? "ms-0" : "ms-2"
          }`}
        >
          <span className="legend-indicator bg-warning"></span>In review
        </span>
      );
    } else if (general) {
      return (
        <i
          className={`bi bi-check2 active ${
            activeTab === "business_details" ? "active" : ""
          } ms-2`}
        ></i>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="col-lg-4">
      <div className="navbar-expand-lg navbar-vertical mb-3 mb-lg-5">
        <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
          <ul
            id="navbarSettings"
            className="js-sticky-block js-scrollspy card card-navbar-nav nav nav-tabs nav-lg nav-vertical"
            data-hs-sticky-block-options='{
             "parentSelector": "#navbarVerticalNavMenu",
             "targetSelector": "#header",
             "breakpoint": "lg",
             "startPoint": "#navbarVerticalNavMenu",
             "endPoint": "#stickyBlockEndPoint",
             "stickyOffsetTop": 20
           }'
          >
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeTab === "business_details" ? "active" : ""
                } ${size?.[0] === 1024 ? "d-flex flex-wrap" : ""}`}
                to={`/merchant/${PATH_NAMES.kyc}/registered_business/business_details`}
                style={{ cursor: "pointer" }}
              >
                Business Details
                <>{handleBusinessSwitch()}</>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeTab === "mandatory_details" ? "active" : ""
                } ${size?.[0] === 1024 ? "d-flex flex-wrap" : ""}`}
                to={
                  general
                    ? `/merchant/${PATH_NAMES.kyc}/registered_business/mandatory_details`
                    : general && general?.proceed === true
                    ? `/merchant/${PATH_NAMES.kyc}/registered_business/mandatory_details`
                    : levelOne && levelOne?.submit === true
                    ? `/merchant/${PATH_NAMES.kyc}/registered_business/mandatory_details`
                    : ``
                }
              >
                Mandatory details (Tier 1)
                {approvedLevelOne === true && (
                  <span
                    className={`badge bg-soft-success text-success ${
                      size?.[0] === 1024 ? "ms-0" : "ms-2"
                    }`}
                  >
                    <span className="legend-indicator bg-success"></span>
                    Approved
                  </span>
                )}
                {levelOne?.submit === false && (
                  <i
                    className={`bi bi-check2 active ${
                      activeTab === "mandatory_details" ? "active" : ""
                    } ms-2`}
                  ></i>
                )}
                {levelOne?.submit === true && (
                  <span
                    className={`ms-2 badge bg-soft-warning text-warning ${
                      size?.[0] === 1024 ? "d-none" : ""
                    }`}
                    hidden={approvedLevelOne === true ? true : false}
                  >
                    <span className="legend-indicator bg-warning"></span>In
                    Review
                  </span>
                )}
                {levelOne?.submit === true && size?.[0] === 1024 && (
                  <span
                    className={`badge bg-soft-warning text-warning ${
                      size?.[0] === 1024 ? "" : ""
                    }`}
                    hidden={approvedLevelOne === true ? true : false}
                  >
                    <span className="legend-indicator bg-warning"></span>In
                    Review
                  </span>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeTab === "proof_of_address" ? "active" : ""
                } ${size?.[0] === 1024 ? "d-flex flex-wrap" : ""}`}
                to={
                  levelOne && levelOne?.proceed === true
                    ? `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
                    : levelOne && levelOne?.submit === true
                    ? `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
                    : levelTwo && levelTwo?.submit === true
                    ? `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
                    : ``
                }
              >
                Proof of address (Tier 2)
                {approvedLevelTwo === true && (
                  <span
                    className={`badge bg-soft-success text-success ${
                      size?.[0] === 1024 ? "ms-0" : "ms-2"
                    }`}
                  >
                    <span className="legend-indicator bg-success"></span>
                    Approved
                  </span>
                )}
                {levelTwo?.submit === false && (
                  <i
                    className={`bi bi-check2 ${
                      activeTab === "valid_id" ? "active" : ""
                    } ms-2`}
                  ></i>
                )}
                {levelTwo?.submit === true && (
                  <span
                    className={`ms-2 badge bg-soft-warning text-warning ${
                      size?.[0] === 1024 ? "d-none" : ""
                    }`}
                    hidden={approvedLevelTwo === true ? true : false}
                  >
                    <span className="legend-indicator bg-warning"></span>In
                    Review
                  </span>
                )}
                {levelTwo?.submit === true && size?.[0] === 1024 && (
                  <span
                    className={`badge bg-soft-warning text-warning ${
                      size?.[0] === 1024 ? "" : ""
                    }`}
                    hidden={approvedLevelOne === true ? true : false}
                  >
                    <span className="legend-indicator bg-warning"></span>In
                    Review
                  </span>
                )}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default KycRegisteredBusinessSideBar;
