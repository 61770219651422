/* eslint-disable @typescript-eslint/no-unused-vars */
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "react-loading-skeleton/dist/skeleton.css";
import { useQueryClient } from "react-query";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PoolerLogo from "../../assets/svg/logos/poolerlogo.svg";
import { ReactComponent as TransferIcon } from "../../assets/transfer.svg";
import { ReactComponent as TopupIcon } from "../../assets/topup.svg";
import { PATH_NAMES } from "../../constants/index.constant";
import { mode } from "../../helpers/index.helper";
import { useAuthFirstTimeLogin, useSession } from "../../hooks/auth/auth.hook";
import { useStateManager } from "../../hooks/state-manager/state.hook";
import API_SERVICE from "../../services/services";
import { sessionInterface } from "../../utils/interfaces";
import SpinnerComponent from "../spinner/Spinner";
import ButtonMailto from "../support/support";
import "./layout.css";
import { BUISNESS_TYPE } from "../../utils/enums";

function LiveToggle({ startTour }: { startTour?: any }) {
  const queryClient = useQueryClient();
  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const profileRef: any = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const { data, refetch } = useSession();
  const { api_env } = useStateManager();
  const merchant_data: sessionInterface = data?.data?.data;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const business_name = merchant_data?.business_name?.split(" ");
  const firstLetter = business_name ? business_name[0]?.charAt(0) : "";
  const lastLetter = business_name ? business_name[1]?.charAt(0) : "";

  const is_live = merchant_data?.state?.is_live;
  const active_domain = merchant_data?.state?.active_domain;

  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());
  }, [queryClient]);

  const onSwitchToLiveEnv = () => {
    window.localStorage.removeItem("domain");
    setIsLoading(true);
    api_env
      .mutateAsync()
      .then((response) => {
        const domain = response?.data?.data?.active_domain;
        window.localStorage.setItem("domain", domain);
        if (domain === "test") {
          setIsLoading(false);
          refetch();
          handleClose();
          setIsModalOpen(false);
          buttonRef.current?.click();
          toast.success("You are now in test mode");
          queryClient.refetchQueries();
          navigate(`/merchant`, { replace: true });
        } else {
          setIsLoading(false);
          refetch();
          buttonRef.current?.click();
          handleClose();
          setIsModalOpen(false);
          toast.success("You are now in live mode");
          navigate(`/merchant`, { replace: true });
          queryClient.refetchQueries();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        return e;
      })
      .finally(() => {
        buttonRef.current?.click();
        refetch();
        setIsLoading(false);
      });
  };

  const handleLiveToggleLg = () => {
    if (is_live === true) {
      return (
        <li className="nav-item">
          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
              <input
                type="checkbox"
                className="form-check-input is-invalid"
                defaultChecked
                style={{ height: "18px" }}
                onClick={() => setIsModalOpen(true)}
              />
              <label className="form-check-label is-invalid text-danger">
                Test
              </label>
            </div>
          </div>
        </li>
      );
    } else if (is_live === false) {
      return (
        <li className="nav-item">
          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
              <input
                type="checkbox"
                className="form-check-input is-invalid"
                data-bs-toggle={"modal"}
                data-bs-target={"#exampleModal"}
                defaultChecked
                disabled
                readOnly
                style={{ height: "18px" }}
              />
              <label className="form-check-label is-invalid text-danger">
                Test
              </label>
            </div>
          </div>
        </li>
      );
    }
  };

  const ModalForSwitch = () => {
    return (
      <>
        <Modal
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          centered
          size="sm"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ display: "contents" }}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gx-2 gx-sm-3 text-center">
              <h5 className="h2">Switch to Live mode</h5>
              <p className="w-100 text-center mx-auto py-2 fw-normal">
                You are about to switch to Live mode. This means that you will
                start processing real transactions and actual payments from your
                customers.
              </p>
              <div className="d-flex flex-row justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onSwitchToLiveEnv}
                >
                  Switch to Live mode{" "}
                  {isLoading ? (
                    <SpinnerComponent size="sm" />
                  ) : (
                    <i className="bi bi-arrow-right"></i>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          centered
          size="sm"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ display: "contents" }}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gx-2 gx-sm-3 text-center">
              <h5 className="h2">Switch to Test mode</h5>
              <p className="w-100 text-center mx-auto py-2 fw-normal">
                You are about to switch to test mode. This means that all
                transactions and payments carried out are not real
              </p>
              <div className="d-flex flex-row justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={onSwitchToLiveEnv}
                >
                  Switch to test mode{" "}
                  {isLoading ? (
                    <SpinnerComponent size="sm" />
                  ) : (
                    <i className="bi bi-arrow-right"></i>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <>
      <ModalForSwitch />
      <div hidden>
        <ul className="navbar-vertical-footer-list list-unstyled">
          <li className="avbar-vertical-footer-list-item list-unstyled">
            <div className="dropdown dropup">
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <i
                    className="bi-person"
                    style={{
                      color: "aliceblue",
                      fontSize: "medium",
                    }}
                  ></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <NavLink
                      className={"link-dark"}
                      to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                    >
                      <i className="bi-person"></i> &emsp;Profile &amp; account
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      className={"link-dark"}
                      to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#profile`}
                    >
                      <i className="bi-person"></i> &emsp;Compliance
                    </NavLink>
                  </Dropdown.Item>

                  <Dropdown.Item as="button" hidden={false}>
                    <i className="bi bi-signpost"></i>&emsp;Take a tour
                  </Dropdown.Item>

                  {/* <span className="dropdown-header">Contact</span> */}
                  <Dropdown.Item href={`https://docs.poolerapp.com/`}>
                    <a
                      href={`https://docs.poolerapp.com/`}
                      target="_blank"
                      rel="noreferrer"
                      className="link-dark"
                    >
                      <i className="bi-journals"></i> &emsp;API Docs &nbsp;
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <ButtonMailto mailto="mailto:no-reply@example.com" />
                  </Dropdown.Item>
                  {/* <span className="dropdown-header">deauthenticate</span> */}
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.clear();
                      window.location.replace(
                        `/auth/merchant${PATH_NAMES.signin}`
                      );
                    }}
                  >
                    <i className="bi bi-shield-lock"></i> &emsp;Sign out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>

          {/* <li className="navbar-vertical-footer-list-item">
            <div className="dropdown dropup">
              <HeadwayWidget />
            </div>
          </li> */}
          <li className="avbar-vertical-footer-list-item">
            <div className="dropdown dropup">
              <Form>
                {active_domain === "test" && active_domain !== undefined ? (
                  <>{handleLiveToggleLg()}</>
                ) : (
                  ""
                )}

                {active_domain === "live" && active_domain !== undefined ? (
                  <div className="d-flex justify-content-center">
                    <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
                      <input
                        type="checkbox"
                        className="form-check-input is-valid"
                        onClick={handleShow}
                        defaultChecked
                        style={{ height: "18px", width: "30px" }}
                      />
                      <label className="form-check-label text-success">
                        Live
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Form>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

function BankNoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      fill="none"
      viewBox="0 0 20 15"
    >
      <path
        stroke="#2C1DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.6 6.688v3.25m10.8-4.876v3.25M14.5 1c2.204 0 3.396.304 3.989.54a.646.646 0 01.232.146c.069.059.193.232.225.312A.785.785 0 0119 2.35v8.733c0 .738 0 1.108-.123 1.297-.124.193-.245.283-.478.357-.23.073-.693-.007-1.62-.168a13.362 13.362 0 00-2.279-.195c-2.7 0-5.4 1.625-9 1.625-2.204 0-3.396-.305-3.989-.54-.079-.032-.118-.048-.232-.146a1.184 1.184 0 01-.225-.312C1 12.868 1 12.795 1 12.65V3.916c0-.738 0-1.108.123-1.297.124-.193.245-.283.478-.357.23-.073.693.007 1.62.168a13.36 13.36 0 002.279.195c2.7 0 5.4-1.625 9-1.625zm-2.25 6.5c0 1.122-1.007 2.031-2.25 2.031S7.75 8.621 7.75 7.5c0-1.122 1.007-2.031 2.25-2.031s2.25.91 2.25 2.031z"
      />
    </svg>
  );
}

function CashierIcon() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 15.5C8.83002 15.5 6.01089 17.0306 4.21609 19.406C3.8298 19.9172 3.63665 20.1728 3.64297 20.5183C3.64785 20.7852 3.81546 21.1219 4.02546 21.2867C4.29728 21.5 4.67396 21.5 5.42733 21.5H18.5729C19.3262 21.5 19.7029 21.5 19.9747 21.2867C20.1847 21.1219 20.3523 20.7852 20.3572 20.5183C20.3635 20.1728 20.1704 19.9172 19.7841 19.406C17.9893 17.0306 15.1702 15.5 12.0001 15.5Z"
        stroke="#2C1DFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0001 12.5C14.4854 12.5 16.5001 10.4853 16.5001 8C16.5001 5.51472 14.4854 3.5 12.0001 3.5C9.51481 3.5 7.5001 5.51472 7.5001 8C7.5001 10.4853 9.51481 12.5 12.0001 12.5Z"
        stroke="#2C1DFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function SideBar({ stop }: { stop: boolean }) {
  const location = useLocation();

  const [isTourOpened, setIsTourOpened] = useState<boolean>(false);

  const isAdmin = window.location.pathname.includes("/admin");
  const isCashier = window.location.pathname.includes("/cashier/dashboard");
  const merchantId: any = localStorage.getItem("merchant_compliance_id");
  const { data } = useSession();
  const merchant_data: sessionInterface = data?.data?.data;
  const active_domain = merchant_data?.state?.active_domain;
  const is_pin_set = merchant_data?.state?.transaction_pin_set;

  return (
    <>
      <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-md navbar-dark bg-dark">
        <div className="navbar-vertical-container">
          <div
            className="navbar-vertical-footer-offset"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="navbar-brand-wrapper justify-content-between">
              <a
                className="navbar-brand"
                style={{ float: "left" }}
                href="/merchant"
                aria-label="Pooler"
              >
                <img
                  className="navbar-brand-logo"
                  src={PoolerLogo}
                  alt="Logo"
                />
              </a>
            </div>

            {/* <!-- Content --> */}
            {!isCashier && (
              <div className="navbar-vertical-content">
                <div
                  id="navbarVerticalMenu"
                  className="nav nav-pills nav-vertical card-navbar-nav"
                >
                  {/* <!-- Collapse --> */}

                  {isAdmin ? (
                    <>
                      <div className="nav-item">
                        <Link
                          className="nav-link"
                          to="/admin"
                          data-placement="left"
                        >
                          <i className="bi-house-door dropdown-item-icon"></i>
                          &nbsp; Dashboard
                        </Link>
                      </div>

                      <div className="nav-item">
                        <Link
                          className="nav-link"
                          to="/admin/merchants"
                          data-placement="left"
                        >
                          <i className="bi-files-alt dropdown-item-icon"></i>
                          &nbsp; Merchants
                        </Link>
                      </div>
                      <div className="nav-item">
                        <Link
                          className="nav-link"
                          to={PATH_NAMES.adminTransactions}
                          data-placement="left"
                        >
                          <i className="bi-files-alt dropdown-item-icon"></i>
                          &nbsp; Transactions
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      {!stop && (
                        <div className="nav-item" id="get-started">
                          <Link
                            className={`nav-link ${
                              location.pathname === "/merchant/get_started"
                                ? "active"
                                : ""
                            }`}
                            to="/merchant/get_started"
                            data-placement="left"
                          >
                            <i className="bi bi-grid"></i>
                            &emsp;Get started
                          </Link>
                        </div>
                      )}
                      <div className="nav-item" id="dashboard-tour">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/merchant" ? "active" : ""
                          }`}
                          to="/merchant"
                          data-placement="left"
                        >
                          <i className="bi-house-door "></i>
                          &emsp;Dashboard
                        </Link>
                      </div>
                      <span className="dropdown-header mt-4" id="accounts-tour">
                        Accounts
                      </span>
                      <small className="bi-three-dots nav-subtitle-replacer"></small>
                      <div className="nav-item" id="virtual-accounts-tour">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/merchant/accounts"
                              ? "active"
                              : ""
                          }`}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.accounts}`}
                          data-placement="left"
                        >
                          <i className="bi bi-people"></i>&emsp;Virtual accounts
                        </Link>
                      </div>

                      {mode === "internal.test" && (
                        <div className="nav-item" id="">
                          <Link
                            className={`nav-link ${
                              location.pathname === "/merchant/customers"
                                ? "active"
                                : ""
                            }`}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.customers}`}
                            data-placement="left"
                          >
                            <i className="bi bi-people-fill"></i>{" "}
                            &emsp;Customers
                          </Link>
                        </div>
                      )}

                      <span className="dropdown-header mt-4" id="transactions">
                        Transactions
                      </span>
                      <small className="bi-three-dots nav-subtitle-replacer"></small>
                      <div className="nav-item" id="payout-tour">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/merchant/business/payouts"
                              ? "active"
                              : ""
                          }`}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.payouts}`}
                        >
                          <i className="bi-layers "></i>
                          &emsp;Payout
                        </Link>
                      </div>
                      <div className="nav-item" id="transfer-tour">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/merchant/transfer"
                              ? "active"
                              : ""
                          }`}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.transfer}`}
                        >
                          <TransferIcon width={20} height={20} />
                          &nbsp; Transfer{" "}
                        </Link>
                      </div>
                      {active_domain === "live" && (
                        <div className="nav-item" id="topup-tour">
                          <Link
                            className={`nav-link ${
                              location.pathname === "/merchant/topup" ||
                              location.pathname === "/merchant/topup/bulk"
                                ? "active"
                                : ""
                            }`}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.topup}`}
                          >
                            <TopupIcon width={20} height={20} />
                            &nbsp; Top up{" "}
                          </Link>
                        </div>
                      )}
                      <div className="nav-item" id="transactions-tour">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/merchant/transactions"
                              ? "active"
                              : ""
                          }`}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.transactions}`}
                          data-placement="left"
                        >
                          <i className="bi bi-grid"></i> &emsp;Transactions
                        </Link>
                      </div>

                      <span className="dropdown-header mt-4" id="business-tour">
                        Business
                      </span>
                      <small className="bi-three-dots nav-subtitle-replacer"></small>

                      <div className="nav-item" id="cashier-tour">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/merchant/business/cashier"
                              ? "active"
                              : ""
                          }`}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.cashier}`}
                          data-placement="left"
                        >
                          <i className="bi-people "></i>
                          &emsp;Cashier
                        </Link>
                      </div>

                      <div className="nav-item" id="payment-links-tour">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/merchant/business/pay-link"
                              ? "active"
                              : ""
                          }`}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.payLink}`}
                          data-placement="left"
                        >
                          <i className="bi bi-app-indicator"></i>&emsp;Payment
                          Links{" "}
                          <span
                            className="badge bg-soft-success text-success"
                            hidden
                          >
                            beta
                          </span>
                        </Link>
                      </div>

                      {mode === "internal.test" && (
                        <div className="nav-item" id="">
                          <Link
                            className={`nav-link ${
                              location.pathname === "/merchant/invoices"
                                ? "active"
                                : ""
                            }`}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.invoices}`}
                            data-placement="left"
                          >
                            <i className="bi bi-receipt"></i> &emsp;Invoice
                          </Link>
                        </div>
                      )}

                      <span className="dropdown-header mt-4" id="utility-tour">
                        utility & settings
                      </span>
                      <small className="bi-three-dots nav-subtitle-replacer"></small>
                      <Link
                        className={`nav-link ${
                          location?.hash === "#developers" ? "active" : ""
                        }`}
                        to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#developers`}
                        id="developer-tour"
                      >
                        <i className="bi-code-slash"></i> &emsp;Developers
                      </Link>
                      <div className="nav-item">
                        <Link
                          className={`nav-link ${
                            location?.hash === "#2fa" ? "active" : ""
                          }`}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#2fa`}
                          id="security-tour"
                        >
                          <i className="bi bi-shield"></i> &emsp;Security
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            {/* <!-- Footer --> */}
            <div className="navbar-vertical-footer" hidden>
              <LiveToggle />
            </div>
            {/* <!-- End Footer --> */}
          </div>
        </div>
      </aside>
    </>
  );
}

export default SideBar;
