import React, { useLayoutEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { page_header_props } from "../../utils/interfaces";
import ViewAccountModal from "../modal/viewAccountModal";
import { ReactComponent as FilterIcon } from "../../assets/filter-icon.svg";
import "./pageHeader.css";
import DropDownWrapper from "../DropDownWrapper";
import FilterModal from "../modal/filterModal";
import { convertDateFormatNoTime } from "../../utils/decode";

function PageHeader({ merchant_data, isLoading, filterBy, setFilterBy }: any) {
  const activeDomain = merchant_data?.state?.active_domain;

  const [size, setSize] = useState([0, 0]);
  const [open, setOpen] = useState(false);
  const [openFilter, setFilter] = useState(false);
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter: any) => ({
      ...filter,
      [name]: value,
    }));
  };

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleFilter = (value: string) => {
    if (filterBy?.label === value) {
      setFilterBy({
        label: "",
        start_date: "",
        end_date: "",
      });
      return setFilter(false);
    }
    if (value === "Today") {
      setFilterBy({
        label: "Today",
        start_date: dayjs().format("YYYY-MM-DD"),
        end_date: dayjs().format("YYYY-MM-DD"),
      });
    } else if (value === "Last 7 days") {
      setFilterBy({
        label: "Last 7 days",
        start_date: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        end_date: dayjs().format("YYYY-MM-DD"),
      });
    } else if (value === "Last 30 days") {
      setFilterBy({
        label: "Last 30 days",
        start_date: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
        end_date: dayjs().format("YYYY-MM-DD"),
      });
    } else if (value === "Custom") {
      setFilterBy({
        label: "Custom",
        start_date: dates.start_date,
        end_date: dates.end_date,
      });
      setOpen(false);
    }
    setFilter(false);
  };

  return (
    <>
      {" "}
      <ViewAccountModal location="home" merchant_data={merchant_data} />
      <FilterModal show={open} handleClose={() => setOpen(false)}>
        <div className="col mb-4">
          <div className="col tom-select-custom mb-3">
            <label>Start date</label>
            <input
              type="date"
              name="start_date"
              onChange={handleDatesFilter}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
          <div className="col tom-select-custom mb-3">
            <label>End date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleDatesFilter}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-white btn-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleFilter("Custom")}
            style={{ cursor: "pointer" }}
          >
            Apply filter{" "}
          </button>
        </div>
      </FilterModal>
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">
              Hi{" "}
              {isLoading ? (
                <Skeleton width={200} className="page-header-title" />
              ) : (
                <span className="">{merchant_data?.firstname},</span>
              )}
            </h1>
            <p className="page-header-text">
              Here's what's happening with your account today.
            </p>
          </div>
        </div>
        <br />
        <div className="row align-items-center justify-content-between">
          <div
            className="col-sm-auto position-relative d-flex align-items-center"
            id="dashboard-date-filter"
            style={{ gap: "16px" }}
          >
            <button
              className="btn fw-bolder position-relative"
              onClick={() => {
                setFilter(!openFilter);
              }}
              style={{
                color: "black",
                border: "1px solid #CBD1EC",
                maxWidth: "140px",
                height: "32px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "14px",
                borderRadius: "8px",
                gap: "4px",
                boxShadow: "2px 2px 0px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <span>{filterBy?.label ? filterBy?.label : "Filter"}</span>
              <FilterIcon style={{ width: 15, height: 15 }} />
            </button>
            {filterBy?.label === "Custom" && (
              <div
                style={{
                  color: "black",
                  height: "32px",
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "14px",
                  borderRadius: "8px",
                  backgroundColor: "#F5F6FB",
                  gap: "4px",
                  fontWeight: "bold",
                }}
              >
                <span>{convertDateFormatNoTime(filterBy?.start_date)}</span> -{" "}
                <span>{convertDateFormatNoTime(filterBy?.end_date)}</span>
              </div>
            )}
            {openFilter && (
              <div
                className="shadow bg-white position-absolute p-2"
                style={{
                  height: "fit-content",
                  top: "40px",
                  left: "10px",
                  cursor: "pointer",
                  zIndex: "99",
                  borderRadius: "8px",
                  width: "150px",
                  boxShadow: "0px 15px 25px 0px #00000026",
                }}
              >
                <div
                  className="d-flex align-items-center p-2 justify-content-start w-100 filter-item"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      filterBy?.label === "Today" ? "#F5F6FB" : "transparent",
                  }}
                  onClick={() => handleFilter("Today")}
                >
                  <p className="mb-0 px-2">Today</p>
                </div>
                <div
                  className="d-flex align-items-center p-2 justify-content-start w-100 filter-item"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      filterBy?.label === "Last 7 days"
                        ? "#F5F6FB"
                        : "transparent",
                  }}
                  onClick={() => handleFilter("Last 7 days")}
                >
                  <p className="mb-0 px-2">Last 7 days</p>
                </div>
                <div
                  className="d-flex align-items-center p-2 justify-content-start w-100 filter-item"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      filterBy?.label === "Last 30 days"
                        ? "#F5F6FB"
                        : "transparent",
                  }}
                  onClick={() => handleFilter("Last 30 days")}
                >
                  <p className="mb-0 px-2">Last 30 days</p>
                </div>
                <div
                  className="d-flex align-items-center p-2 justify-content-start w-100 filter-item"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      filterBy?.label === "Custom" ? "#F5F6FB" : "transparent",
                  }}
                  onClick={() =>
                    filterBy?.label === "Custom"
                      ? handleFilter("Custom")
                      : setOpen(true)
                  }
                >
                  <p className="mb-0 px-2">Custom</p>
                </div>
              </div>
            )}
          </div>
          {size?.[0] <= 998 ? null : (
            <div className="col-sm-auto">
              {/* return a pop up modal */}
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#welcomeMessageModal"
                className="btn btn-primary"
                id="view-account-tour"
              >
                View account
              </button>
              {activeDomain === "test" && (
                <>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link
                    className="btn btn-success"
                    id="fund-account-tour"
                    to={"https://demobank.poolerapp.com/"}
                    target="_blank"
                  >
                    Fund account
                  </Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PageHeader;
