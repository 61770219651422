import moengage from "@moengage/web-sdk";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import FilterModal from "../../../component/modal/filterModal";
// import PageTitle from "../../../component/page-header/PageTitle";
import PaginationComponent from "../../../component/pagination/pagination";
import SpinnerComponent from "../../../component/spinner/Spinner";
import {
  useAccountExports,
  useAccountFilter,
  useAccounts,
} from "../../../hooks/accounts/account.hook";
import useDebounce from "../../../hooks/debounce.hook";
import useUserDeviceInfo from "../../../hooks/device.hook";

function VirtualAccountsSm(): JSX.Element {
  const navigate = useNavigate();
  const { export_account } = useAccountExports();
  const { account_filter } = useAccountFilter();
  const { deviceInfo } = useUserDeviceInfo();

  const [show, setShow] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [activeItem, setActiveItem] = useState<number>(0);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [downloadResult, setDownload] = useState(true);

  const { account_data, isFetching, refetch } = useAccounts(activeItem, query);

  const handleExports = () => {
    if (dates?.start_date === "" && dates?.end_date === "") {
      // export_account
      //   .mutateAsync({
      //     start_date: dates.start_date,
      //     end_date: dates.end_date,
      //     download: "all",
      //   })
      //   .then((data: any) => {
      //     const url = data?.data?.data;
      //     const link: any = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     link.parentNode.removeChild(link);
      //   })
      //   .catch((error: any) => {
      //     return error;
      //   });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      export_account
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          const url = data?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          return error;
        });
    }
  };

  const handleFilter = () => {
    setLoading(true);
    if (dates?.start_date === "" && dates?.end_date === "") {
      account_filter
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "all",
        })
        .then((data: any) => {
          setAccounts(data?.data?.data?.items);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          return error;
        })
        .finally(() => setLoading(true));
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      account_filter
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          setAccounts(data?.data?.data?.items);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          return error;
        })
        .finally(() => setLoading(false));
    }
    setLoading(false);
  };

  const debouncedInputValue = useDebounce<string>(inputValue, 2000);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (e.target.value === "") {
      setQuery({});
      setInputValue("");
      refetch();
    }
  };

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (debouncedInputValue && inputValue !== "") {
      setQuery({ ...query, account_no: debouncedInputValue });
    }
    return () => {};
  }, [debouncedInputValue, query, inputValue]);

  useEffect(() => {
    if (account_data) {
      setAccounts(account_data?.data?.data?.items);
    }
  }, [account_data]);

  const totalItems: number = account_data?.data?.data?.totalPages;

  const handleClose = () => setShow(false);

  return (
    <>
      <FilterModal show={show} handleClose={handleClose}>
        <div className="row mb-4">
          <div className="col tom-select-custom mb-3">
            <label>Start date</label>
            <input
              type="date"
              name="start_date"
              onChange={handleDatesFilter}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
          <div className="col tom-select-custom mb-3">
            <label>End date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleDatesFilter}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-white btn-sm" onClick={handleClose}>
            Close
          </button>
          {downloadResult ? (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                handleExports();
                moengage.track_event("DOWNLOAD_ACCOUNT", {
                  success_status: true,
                });
              }}
              style={{ cursor: "pointer" }}
            >
              Export
              {loading && <SpinnerComponent variant="light" size="sm" />}
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                handleFilter();
                moengage.track_event("FILTER_ACCOUNT", {
                  platform: deviceInfo?.userAgent,
                  success_status: true,
                });
              }}
              style={{ cursor: "pointer" }}
            >
              Apply filter{" "}
              {loading && <SpinnerComponent variant="light" size="sm" />}
            </button>
          )}
        </div>
      </FilterModal>

      <div className="d-flex flex-column">
        <div className="d-flex flex-row gap-3 mb-3">
          <input
            type="text"
            className="js-form-search form-control border border-secondary icon w-75"
            placeholder="Search..."
            onChange={handleInputChange}
          />

          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className="btn btn-light rounded btn-icon icon-btn"
            >
              <i className="bi-three-dots" style={{ color: "#000" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={"button"}
                type="button"
                onClick={() => setShow(true)}
              >
                <i className="bi bi-filter me-2"></i> Filter
              </Dropdown.Item>
              <Dropdown.Item
                as={"button"}
                type="button"
                onClick={() => {
                  setShow(true);
                  setDownload(true);
                  moengage.track_event("DOWNLOAD_ACCOUNT", {
                    success_status: true,
                  });
                }}
              >
                <i className="bi bi-download me-2"></i> Download
              </Dropdown.Item>

              <Dropdown.Item
                as={"button"}
                type="button"
                onClick={() => {
                  refetch();
                  window.location.reload();
                }}
              >
                <i className="bi bi-arrow-clockwise me-2"></i> Refresh
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="mt-3">
          <ul className="list-group">
            {isFetching ? (
              <li className="mb-2">
                {[1, 2, 3, 4, 5, 6].map(() => {
                  return <Skeleton height={30} />;
                })}
              </li>
            ) : (
              <>
                {accounts?.map((txn_data: any, index: any) => {
                  return (
                    <>
                      <li
                        className={`${
                          index === 0 || index === accounts?.length - 1
                            ? "list-group-item"
                            : "list-group-item"
                        } d-flex flex-row justify-content-between align-items-start card mb-3`}
                        onClick={() => {
                          navigate(
                            `/merchant/accounts/details/${txn_data.id}`,
                            {
                              state: { ...txn_data },
                            }
                          );
                          moengage.track_event("VIEW_ACCOUNT", {
                            success_status: true,
                            account_name: txn_data?.account_name,
                            business_name: txn_data?.business_name,
                            account_type: txn_data?.client_type,
                            platform: deviceInfo?.userAgent,
                            account_id: txn_data?.id,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-semibold text-truncate">
                            {txn_data?.account_name}
                          </div>
                          <small>{txn_data?.account_no}</small>
                        </div>

                        {txn_data?.active === true ? (
                          <span className="badge bg-soft-success text-success">
                            <span className="legend-indicator bg-success"></span>
                            ACTIVE
                          </span>
                        ) : (
                          ""
                        )}
                      </li>
                    </>
                  );
                })}
              </>
            )}
          </ul>

          <div className="col-sm-auto">
            <div className="d-flex justify-content-center justify-content-sm-end">
              {totalItems > 1 && (
                <PaginationComponent
                  totalItems={totalItems}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VirtualAccountsSm;
