/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FormEvent, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { formatAmount, shortenString } from "../../helpers/index.helper";
import useDebounce from "../../hooks/debounce.hook";
import { useGetBanks } from "../../hooks/payouts/payouts.hook";
import { useTranactionExports } from "../../hooks/transactions/index.hook";
import { useTransfers } from "../../hooks/transfer/index.hook";
import {
  ITransaction,
  ITransactions,
  bankInterface,
} from "../../utils/interfaces";
import DropDownWrapper from "../DropDownWrapper";
import PaginationComponent from "../pagination/pagination";

const TransferHistoryTable = (): JSX.Element => {
  const closeRef: any = useRef(null);
  const startRef: any = useRef(null);
  const buttonRef: any = useRef(null);

  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<ITransactions>();
  const [reference, setReference] = useState("");
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [filter, setFilter] = useState({
    reference: "",
    start_date: "",
    end_date: "",
  });
  const [activeItem, setActiveItem] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const debouncedInputValue = useDebounce<string>(reference, 2000);
  const { export_transactions } = useTranactionExports();
  const { data, refetch, isLoading, isFetching } = useTransfers(
    {
      reference: debouncedInputValue,
      start_date: filter.start_date,
      end_date: filter.end_date,
    },
    activeItem
  );
  const { bankData } = useGetBanks();
  const banks: bankInterface[] = bankData?.data?.data;

  const totalItems = data?.data?.data?.totalPages;

  const handleRefFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    refetch();
  };

  const filterData = () => {
    if (dates?.start_date === "" && dates?.end_date === "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "all",
        })
        .then((data: any) => {
          // const url = data?.data?.data;
          // const link: any = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "file.xlsx");
          // document.body.appendChild(link);
          // link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          return error;
        });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          // const url = data?.data?.data;
          // const link: any = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "file.xlsx");
          // document.body.appendChild(link);
          // link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          return error;
        });
    }
  };

  const handleExports = () => {
    if (dates?.start_date === "" && dates?.end_date === "") {
      // setFilter({
      //   reference,
      //   ...dates,
      // });
      // export_transactions
      //   .mutateAsync({
      //     start_date: dates.start_date,
      //     end_date: dates.end_date,
      //     download: "all",
      //   })
      //   .then((data: any) => {
      //     const url = data?.data?.data;
      //     const link: any = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     link.parentNode.removeChild(link);
      //   })
      //   .catch((error: any) => {
      //     return error;
      //   });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          const url = data?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          return error;
        });
    }
  };

  const refreshData = () => {
    setReference("");
    setDates({
      start_date: "",
      end_date: "",
    });
    setFilter({
      reference,
      ...dates,
    });
    refetch();
  };

  useEffect(() => {
    if (data) {
      setTransactions(data?.data?.data);
    }
  }, [data]);

  useEffect(() => {
    refreshData();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    closeRef?.current?.click();
    setDates((prev) => ({
      ...prev,
      start_date: "mm/dd/yyyy",
      end_date: "mm/dd/yyyy",
    }));
    if (startRef.current) {
      startRef.current.value = "mm/dd/yyyy";
      setDates((prev) => ({
        ...prev,
        start_date: "mm/dd/yyyy",
        end_date: "mm/dd/yyyy",
      }));
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="card">
      <div className="card-header card-header-content-md-between">
        <div className="mb-2 mb-md-0">
          <form onSubmit={handleSearch}>
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                id="datatableSearch"
                type="search"
                className="form-control"
                value={reference}
                onChange={handleRefFilter}
                placeholder="Search by reference"
                aria-label="Search by reference"
              />
            </div>
          </form>
        </div>

        <div className="d-grid d-sm-flex gap-2 align-items-center">
          {transactions?.items !== undefined &&
          transactions?.items?.length > 0 ? (
            <>
              <DropDownWrapper
                action={
                  <button
                    type="button"
                    className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                    ref={closeRef}
                  >
                    <i className="bi bi-filter"></i> Filter
                  </button>
                }
              >
                <div className="p-3">
                  <label className="text-body">Date Range</label>
                  <div className="row mb-4">
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="start_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="end_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="btn btn-white btn-sm"
                      onClick={() => closeRef.current.click()}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={filterData}
                      style={{ cursor: "pointer" }}
                    >
                      Apply filter
                    </button>
                  </div>
                </div>
              </DropDownWrapper>

              <DropDownWrapper
                action={
                  <button
                    ref={buttonRef}
                    type="button"
                    className="btn btn-outline-success btn-sm"
                  >
                    <span style={{ color: "#128100 !important" }}>
                      Download
                    </span>
                    <DownloadIcon className="ms-2 text-success" />
                  </button>
                }
              >
                <div className="p-3">
                  <label className="text-body">Date Range</label>
                  <div className="row mb-4">
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="start_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="end_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="btn btn-white btn-sm"
                      onClick={() => buttonRef.current.click()}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleExports}
                      style={{ cursor: "pointer" }}
                    >
                      Export
                    </button>
                  </div>
                </div>
              </DropDownWrapper>
            </>
          ) : (
            ""
          )}
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-white btn-sm w-100"
              aria-expanded="false"
              onClick={() => {
                refreshData();
                refetch();
                window.location.reload();
              }}
            >
              <i className="bi bi-arrow-clockwise me-2"></i> Refresh
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive datatable-custom">
        <table
          id="datatable"
          className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
          style={{ width: "100%" }}
        >
          <thead className="thead-light">
            <tr>
              <th>Reference</th>
              <th>Date / Time</th>
              <th>Beneficiary name</th>
              <th>Account no</th>
              <th>Beneficiary bank</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>

          {isLoading || isFetching ? (
            <tbody>
              {[...Array(5)].map((i, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                    <td>
                      <Skeleton height={50} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {transactions?.items?.map((transaction: ITransaction) => {
                const reciverBank: any =
                  banks?.length > 0 &&
                  banks?.filter(
                    (bank) =>
                      bank.bank_code === transaction?.payout?.target_bank_code
                  );
                return (
                  <tr
                    key={transaction?.id}
                    onClick={() =>
                      navigate(`/merchant/transfer/details/${transaction?.id}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>{shortenString(transaction?.reference, 15)}</td>
                    <td>{transaction?.created_at}</td>
                    <td>
                      {shortenString(transaction?.payout?.target_name, 15)}
                    </td>
                    <td>{transaction?.reciever_details?.account_number}</td>
                    <td>{reciverBank?.[0]?.bank_name}</td>
                    <td>
                      {formatAmount(
                        +transaction?.amount,
                        transaction?.currency
                      )}
                    </td>
                    <td>
                      {transaction?.payout?.completed ||
                      transaction?.completed === true ? (
                        <span className="badge bg-soft-success text-success">
                          COMPLETED
                        </span>
                      ) : (
                        ""
                      )}
                      {transaction?.payout?.in_transit === true ? (
                        <span className="badge bg-soft-warning text-warning">
                          PENDING
                        </span>
                      ) : (
                        ""
                      )}
                      {transaction?.payout?.failed === true ? (
                        <span className="badge bg-soft-danger text-danger">
                          FAILED
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>

      <div className="card-footer">
        <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
          <div className="col-sm mb-2 mb-sm-0" />

          <div className="col-sm-auto">
            <div className="d-flex justify-content-center justify-content-sm-end">
              {totalItems > 1 && (
                <PaginationComponent
                  totalItems={totalItems}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferHistoryTable;
