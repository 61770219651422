import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function Info({ text }: { text?: string }) {
  return (
    <OverlayTrigger
      key={"top"}
      placement={"top"}
      overlay={<Tooltip id={text}>{text}</Tooltip>}
    >
      <i
        className="bi bi-info-circle-fill d-nonee"
        style={{ cursor: "pointer" }}
      ></i>
    </OverlayTrigger>
  );
}

export default Info;
