import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useQueryClient } from "react-query";
import { useManageCashier } from "../../hooks/cashier/index.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import API_SERVICE from "../../services/services";
import { Button } from "../button";
import SpinnerComponent from "../spinner/Spinner";

function DeactivateCashierModal({
  show,
  handleClose,
  id,
  actionType,
  cashierData,
}: {
  show: boolean;
  handleClose: () => void;
  id: string | any;
  actionType?: string | any;
  cashierData?: any;
}): JSX.Element {
  const { manageHandler } = useManageCashier();
  const queryClient = useQueryClient();
  const { deviceInfo } = useUserDeviceInfo();

  const handleEvent = () => {
    moengage.track_event(
      actionType === "block" ? "SUSPEND_CASHIER" : "UNSUSPEND_CASHIER",
      {
        cashier_id: id,
        platform: deviceInfo?.userAgent,
        success_status: true,
      }
    );
  };

  const [loading, setLoading] = useState<boolean>(false);

  const originalCashierName = cashierData?.cashier_name;
  const parts = originalCashierName && originalCashierName?.split("/");
  const result = parts && parts[1]?.trim();

  const handleCancelLink = async () => {
    setLoading(true);
    try {
      const response = await manageHandler.mutateAsync({
        id: id,
        action: actionType,
      });
      if (response.status === 200) {
        handleEvent();
        await queryClient.fetchQuery(["cashiers", "cashier", id], () =>
          API_SERVICE._getSingleCashier(id)
        );
        handleClose();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="">
          {actionType === "unblock" ? (
            <h4 className="modal-title" id="exampleModalLabel">
              Unsuspend cashier
            </h4>
          ) : (
            <h4 className="modal-title" id="exampleModalLabel">
              Suspend cashier
            </h4>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="text-center">
          <p>
            Are sure you want to{" "}
            {actionType === "unblock" ? "unsuspend" : "suspend"}
            &nbsp;
            {cashierData?.cashier_name?.includes("/")
              ? result
              : cashierData?.cashier_name}
            ?
          </p>
        </div>
        <Button
          variant="white"
          className="mt-3 cashier-button"
          fullwidth
          onClick={handleCancelLink}
        >
          {actionType === "unblock" ? "Unsuspend" : "Suspend"} cashier{" "}
          {loading && (
            <>
              &nbsp;
              <SpinnerComponent size="sm" variant="dark" />
            </>
          )}
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default DeactivateCashierModal;
