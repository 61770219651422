import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthIllustration from "../../assets/svg/logos/Pooler-sign-up-image-small.png";
import PoolerLogoWhite from "../../assets/svg/logos/Pooler_logo_white.svg";
import PoolerIpad from "../../assets/svg/logos/ipad-pooler.svg";
import PoolerLogo from "../../assets/svg/logos/poolerdarklogo.svg";
import SEO from "../../component/seo/seo";
import SpinnerComponent from "../../component/spinner/Spinner";
import { PATH_NAMES } from "../../constants/index.constant";
import { validateEmail, validatePassword } from "../../helpers/index.helper";
import { useLogin, useTwoFactor } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import useResize from "../../hooks/resize.hook";
import { loginInterface } from "../../utils/interfaces";

function Signin(): JSX.Element {
  const queryClient = useQueryClient();
  const location = useLocation();
  const { size } = useResize();
  const { deviceInfo } = useUserDeviceInfo();
  const account_no: string | any = location?.state?.account_no;
  const { login_user } = useLogin();
  const { verify_otp } = useTwoFactor("twofactor");
  const navigate = useNavigate();
  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [accountNo, setAccountNo] = useState<string>(
    account_no ? account_no : ""
  );
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState({ accountNo: false, password: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAccountValid, setIsAccountValid] = useState<any>(null);
  const [isPasswordValid, setIsPasswordValid] = useState<any>(null);

  const toggleVisibility = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };

  const Verify = async (password: any | string, email: string) => {
    setIsLoading(true);
    try {
      const response: any = await verify_otp.mutateAsync({
        email: email,
        password: password,
      });
      if (response.status === 200) {
        moengage.add_email(email);
        moengage.add_user_name(email);
        moengage.add_unique_user_id(email);
        moengage.track_event("LOGIN", {
          email: email,
          user_name: email,
          user_id: email,
          first_time_user_otp: true,
          platform: deviceInfo?.userAgent,
          login_channel: size?.[0] <= 998 ? "mobile" : "desktop",
        });
        queryClient.refetchQueries();
        navigate(
          window.innerWidth > 996
            ? `${PATH_NAMES.client_home}/get_started`
            : `${PATH_NAMES.client_home}/`,
          { replace: true }
        );
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const onSubmit = async (data: loginInterface) => {
    setIsLoading(true);
    try {
      const response = await login_user.mutateAsync(data);
      if (response.status === 200) {
        const isEmailVerified = response?.data?.data?.is_email_verified;
        const requireTwofactor = response?.data?.data?.require_twofactor;
        if (isEmailVerified === false && requireTwofactor === false) {
          const actions = {
            customer_id: data?.email,
            actions: [
              {
                action: "sign_in",
                attributes: {
                  email: data?.email,
                  merchant_id: data?.email,
                  success_status: true,
                  login_channel: size?.[0] <= 998 ? "mobile" : "desktop",
                  first_time_user_otp: true,
                },
                platform: deviceInfo?.userAgent,
              },
            ],
          };

          navigate(`/auth/merchant/verify_email`, {
            state: {
              email: data?.email,
            },
          });
        } else if (isEmailVerified === true && requireTwofactor === false) {
          Verify(data.password, data.email);
          setIsLoading(false);
        } else if (isEmailVerified === true && requireTwofactor === true) {
          navigate(`${PATH_NAMES.verify_otp}?action=twofactor`, {
            state: {
              email: data?.email,
              password: data?.password,
              pathname: location?.pathname,
            },
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const validateBeforesubmit = () => {
    setIsLoading(true);
    let isValid = true;
    if (accountNo.trim() === "" || isAccountValid === false) {
      setError((prev) => ({ ...prev, accountNo: true }));
      isValid = false;
    }
    if (password.trim() === "" || isPasswordValid === false) {
      setError((prev) => ({ ...prev, password: true }));
      isValid = false;
    }
    if (isValid && isAccountValid === true && isPasswordValid === true) {
      onSubmit({ email: accountNo, password: password });
    }
  };

  const handleUrl = (): string | any => {
    const isMerchant = location.pathname.includes("/auth/merchant");
    const isCashier = location.pathname.includes("/auth/cashier");
    if (isMerchant) {
      return "/auth/merchant/signup";
    } else if (isCashier) {
      return "/auth/cashier/setup";
    }
  };

  return (
    <>
      <SEO
        title="Pooler - Collecting Safely"
        description="Use Pooler to collect and settle payments seamless and faster by transfer and avoid chargeback"
        type="website"
      />
      <div className="row overflow-hidden">
        <div
          style={{ backgroundImage: `url(${AuthIllustration})` }}
          className="col-lg-6 overflow-hidden d-none --bg-auth d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
        >
          <div id="banner-conc">
            <img src={PoolerLogoWhite} alt="pooler-logo" />
            <h1>
              The Easier Way to
              <br />
              Receive Payments.
            </h1>
            <p>Experience world-class banking-as-a-service</p>
          </div>
          <img src={PoolerIpad} alt="pooler-ipad" />
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100 position-relative overflow-hidden">
          <div
            className="w-100 content-space-t-1 content-space-t-lg-2 content-space-b-1 px-4 px-lg-0 overflow-hidden"
            style={{ maxWidth: "25rem" }}
          >
            <div className="text-center d-sm-block d-md-block d-lg-none mx-auto">
              <img
                src={PoolerLogo}
                alt="pooler"
                className="navbar-brand-logo d-sm-block d-md-block d-lg-none mx-auto"
                style={{ paddingBottom: "15%" }}
              />
            </div>
            <div className="js-validate needs-validation">
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-5">Sign in</h1>
                </div>

                <span className="divider-center text-muted mb-4 d-none">
                  OR
                </span>
              </div>

              <div className="mb-4">
                <label className="form-label w-100" htmlFor="account_no">
                  Email
                </label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    error.accountNo || isAccountValid === false
                      ? " border border-danger"
                      : "border border-secondary"
                  } ${
                    !error.accountNo && accountNo !== ""
                      ? " border border-secondary"
                      : "border border-secondary"
                  }`}
                  name="account_no"
                  id="account_no"
                  tabIndex={1}
                  placeholder="email@address.com"
                  aria-label="email@address.com"
                  autoComplete="off"
                  value={accountNo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAccountNo(e.target.value);
                    setIsAccountValid(validateEmail(e.target.value));
                  }}
                />
                <span className="invalid-feedback">
                  Please enter a valid email.
                </span>
              </div>

              <div className="mb-4">
                <label
                  className="form-label w-100"
                  htmlFor="signupSrPassword"
                  tabIndex={0}
                >
                  <span className="d-flex justify-content-between align-items-center">
                    <span>Password</span>
                    <Link
                      className="form-label-link mb-0"
                      to={`${PATH_NAMES.reset_password}`}
                    >
                      Forgot Password?
                    </Link>
                  </span>
                </label>

                <div
                  className="input-group input-group-merge"
                  data-hs-validation-validate-class
                >
                  <input
                    type={passwordOpen ? "text" : "password"}
                    className={`js-toggle-password form-control form-control-lg ${
                      error.password === true || isPasswordValid === false
                        ? "border border-danger"
                        : "border border-secondary"
                    } ${
                      error.password === false && isPasswordValid === true
                        ? "border border-secondary"
                        : "border border-secondary"
                    }`}
                    name="password"
                    id="signupSrPassword"
                    placeholder="Password"
                    aria-label="password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(e.target.value);
                      setIsPasswordValid(validatePassword(e.target.value));
                    }}
                  />
                  <button
                    id="changePassTarget"
                    className="input-group-append input-group-text"
                    type={"button"}
                    disabled={
                      error.accountNo === true || error.password === true
                        ? true
                        : false
                    }
                  >
                    {!eyeOpen ? (
                      <i
                        id="changePassIcon"
                        className="bi-eye"
                        onClick={() => {
                          toggleVisibility();
                          setEyeOpen(!eyeOpen);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash"
                        onClick={() => {
                          toggleVisibility();
                          setEyeOpen(!eyeOpen);
                        }}
                      />
                    )}
                  </button>
                </div>

                <span className="invalid-feedback">
                  Please enter a valid password.
                </span>
              </div>

              <div className="d-grid">
                <button
                  type={"button"}
                  className="btn btn-primary btn-lg"
                  disabled={
                    isLoading ||
                    accountNo === "" ||
                    password === "" ||
                    isPasswordValid === false ||
                    isAccountValid === false
                      ? true
                      : false
                  }
                  onClick={() => validateBeforesubmit()}
                >
                  Sign in &nbsp;{" "}
                  <span className="align-middle">
                    {isLoading && <SpinnerComponent size="sm" />}
                  </span>
                </button>
                <div className="form-check mb-4 mx-auto mt-3">
                  <label className="form-check-label" htmlFor="termsCheckbox">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>Don't have an account?</span>
                      &nbsp;&nbsp;
                      <Link className="form-label-link mb-0" to={handleUrl()}>
                        Create account
                      </Link>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
