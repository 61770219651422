import React, { useState, useRef } from "react";
import SpinnerComponent from "../spinner/Spinner";
import { useGenerateKeys, useViewKeys } from "../../hooks/auth/auth.hook";
import { apiKeyInterface } from "../../utils/interfaces";
import { toast } from "react-toastify";
import OtpFields from "../otp/OtpFields";

type Props = {
  data?: { title: string; type: string; label?: string; action?: string };
  webhook_url?: string;
  onDataExport?: any;
};

function SecretModal({ data, webhook_url, onDataExport }: Props) {
  const { generate_key } = useGenerateKeys();
  const { view_keys } = useViewKeys()
  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const submitRef: any = useRef<HTMLDivElement | null>(null);
  const viewRef: any = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const handleGenerateKey = (payload: apiKeyInterface) => {
    setIsLoading(true);
    generate_key
      .mutateAsync(payload)
      .then(() => {})
      .catch((e: any) => {
        toast.error(e?.response?.data?.data);
        buttonRef.current?.click();
      })
      .finally(() => {
        setIsLoading(false);
        setReadOnly(false);
        buttonRef.current?.click();
      });
  };

  const handleApiCall = (otp: any) => {
    if (data?.type === "api_key") {
      handleGenerateKey({
        auth_code: otp,
        api_key: true,
      });
    } else if (data?.type === "webhook_secret") {
      handleGenerateKey({
        auth_code: otp,
        webhook_secret: true,
      });
    } else if (data?.type === "webhook") {
      handleGenerateKey({
        auth_code: otp,
        webhook_url: webhook_url,
      });
    }
  };

  const handleView = (auth_code: string) => {
    setIsLoading(true);
    view_keys
      .mutateAsync(auth_code)
      .then((data) => {
        onDataExport(data?.data?.data);
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.data);
        buttonRef.current?.click();
      })
      .finally(() => {
        setIsLoading(false);
        buttonRef.current?.click();
      });
  };

  const handleViewsecurityKey = (otp: any) => {
    const auth_code = otp;
    if (data?.type === "view") {
      handleView(auth_code);
    }
  };

  const handleOtpChange = (otp: any) => {
    if (otp.length === 6) {
      data?.type === "view" ? handleViewsecurityKey(otp) : handleApiCall(otp);
    }
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      aria-hidden="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            {data?.action === "view" ? (
              <h4 className="modal-title" id="exampleModalLabel">
                Validate OTP
              </h4>
            ) : (
              <h4 className="modal-title" id="exampleModalLabel">
                {data?.title}
              </h4>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="text-left">
              <p>
                Enter the code from the authenticator app in the field below.
              </p>
            </div>
            <form>
              <div className="row gx-2 gx-sm-3 mt-4">
                {/* {otp.map((value, index) => (
                  <div className="col" key={index}>
                    <div className="mb-4">
                      <input
                        type="text"
                        className="form-control form-control-single-number"
                        name="code1"
                        placeholder=""
                        aria-label=""
                        maxLength={1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOtpChange(e, index)
                        }
                        value={value}
                        onPaste={handleOtpPaste}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        onPasteCapture={handleOtpPaste}
                      />
                    </div>
                  </div>
                ))} */}
                <OtpFields onOtpInput={handleOtpChange} readOnly={readOnly} />
              </div>
            </form>
          </div>

          <div className="modal-footer d-none">
            <div className="row align-items-sm-center flex-grow-1 mx-n2 justify-content-end">
              <div className="col-sm-auto">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={buttonRef}
                  >
                    Cancel
                  </button>
                  {data?.type === "view" ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      ref={viewRef}
                      // onClick={() => handleViewsecurityKey(otp)}
                    >
                      Approve
                      {isLoading && (
                        <>
                          {" "}
                          &nbsp;
                          <SpinnerComponent size="sm" />
                        </>
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      ref={submitRef}
                      // onClick={() => handleApiCall(otp)}
                    >
                      Generate
                      {isLoading && (
                        <>
                          {" "}
                          &nbsp;
                          <SpinnerComponent size="sm" />
                        </>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecretModal;
