import React from "react";

function ProfileHeader() {
  return (
    <div className="page-header">
      <div className="row align-items-end">
        <div className="col-sm mb-2 mb-sm-0">
          <h1 className="page-header-title">Profile</h1>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
