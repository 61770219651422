/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { ReactComponent as People } from "../../assets/vpeople.svg";
import Card from "../../component/card/Card";
import PageTitle from "../../component/page-header/PageTitle";
import PaginationComponent from "../../component/pagination/pagination";
// import SEO from "../../component/seo/seo";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../../component/spinner/Spinner";
import Table from "../../component/table/Table";
import { accounts_column } from "../../configs/dataTable.config";
import {
  useAccountExports,
  useAccountFilter,
  useAccounts,
} from "../../hooks/accounts/account.hook";
import useDebounce from "../../hooks/debounce.hook";
import useResize from "../../hooks/resize.hook";
import VirtualAccountsSm from "./vitual-account/VirtualAccountsSm";

function Accounts(): JSX.Element {
  const navigate = useNavigate();
  const { size } = useResize();
  const { export_account } = useAccountExports();
  const { account_filter } = useAccountFilter();
  const [activeItem, setActiveItem] = useState<number>(0);
  const [inputValue, setInputValue] = useState("");
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [query, setQuery] = useState<any>({});
  const [accounts, setAccounts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { account_data, isFetching, refetch } = useAccounts(activeItem, query);

  // const data: any = account_data?.data?.data?.items;
  const columns: any = React.useMemo(
    () => [
      ...accounts_column(),
      // {
      //   Header: "Action",
      //   columnId: 8,
      //   accessor: "actions",
      //   Cell: ({ row }: any): JSX.Element => {
      //     const id = row?.original?.account_no;
      //     const locationObject = row?.original;
      //     return (
      //       <div className="btn-group" role="group">
      //         <button
      //           className="btn btn-white btn-sm"
      //           onClick={() =>
      //             navigate(`/merchant/accounts/details/${id}`, {
      //               state: { ...locationObject },
      //             })
      //           }
      //         >
      //           <i className="bi-eye"></i> View
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  const totalItems: number = account_data?.data?.data?.totalPages;

  const handleExports = () => {
    if (dates?.start_date === "" && dates?.end_date === "") {
      // export_account
      //   .mutateAsync({
      //     start_date: dates.start_date,
      //     end_date: dates.end_date,
      //     download: "all",
      //   })
      //   .then((data: any) => {
      //     const url = data?.data?.data;
      //     const link: any = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     link.parentNode.removeChild(link);
      //   })
      //   .catch((error: any) => {
      //     return error;
      //   });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      export_account
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          const url = data?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          return error;
        });
    }
  };

  const handleFilter = () => {
    setLoading(true);
    if (dates?.start_date === "" && dates?.end_date === "") {
      account_filter
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "all",
        })
        .then((data: any) => {
          setAccounts(data?.data?.data?.items);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          return error;
        })
        .finally(() => setLoading(true));
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      account_filter
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          setAccounts(data?.data?.data?.items);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          return error;
        })
        .finally(() => setLoading(false));
    }
    setLoading(false);
  };

  const debouncedInputValue = useDebounce<string>(inputValue, 2000);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (e.target.value === "") {
      setQuery({});
      setInputValue("");
      refetch();
    }
  };

  useEffect(() => {
    if (debouncedInputValue && inputValue !== "") {
      setQuery({ ...query, account_no: debouncedInputValue });
    }
    return () => {};
  }, [debouncedInputValue, query, inputValue]);

  useEffect(() => {
    if (account_data) {
      setAccounts(account_data?.data?.data?.items);
    }
  }, [account_data]);

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  return (
    <>
      <PageTitle
        title="Virtual accounts"
        subTitle="All accounts"
        headerData={accounts}
      />
      {size?.[0] <= 998 && accounts ? (
        <>
          {isFetching ? (
            <div className="d-flex flex-row align-items-center justify-content-center">
              <SpinnerComponent variant="primary" />
            </div>
          ) : (
            <>
              {accounts?.length !== 0 ? (
                <>
                  <VirtualAccountsSm />
                </>
              ) : (
                <>
                  <div
                    className="d-flex flex-column align-items-center justify-content-center mx-auto text-center"
                    style={{ height: "60vh" }}
                  >
                    <div>
                      <People />

                      <p>You’ve not created any accounts yet</p>

                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => navigate("/merchant/virtual-accounts")}
                      >
                        <i className="bi-people-fille me-1"></i> Create account
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Card
          pagination={
            <>
              {totalItems > 1 && (
                <PaginationComponent
                  totalItems={totalItems}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              )}
            </>
          }
          refresh={() => {
            setDates((prev) => ({ ...prev, start_date: "", end_date: "" }));
            refetch();
          }}
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          dates={dates}
          handleDatesFilter={handleDatesFilter}
          handleExports={handleExports}
          handleFilter={handleFilter}
          data={accounts}
          loading={loading}
          setDates={setDates}
        >
          <Table
            columns={columns}
            data={accounts ? accounts : []}
            isFetching={isFetching}
            navigateTo={`/merchant/accounts/details/`}
            eventName={"VIEW_ACCOUNT"}
          />
        </Card>
      )}
    </>
  );
}

export default Accounts;
