import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaginationComponent from "../../../component/pagination/pagination";
import {
  airtime_purchase_id_map,
  calculateTotalAmount,
  formatAmount,
  paginateData,
} from "../../../helpers/index.helper";
import {
  useAirtimeBulk,
  useConfirmAirtimePurchase,
  useConfirmDataPurchase,
  useDataBulk,
} from "../../../hooks/top-up/index.hook";
import { convertDateFormat } from "../../../utils/decode";

import SpinnerComponent from "../../../component/spinner/Spinner";
import Table from "../../../component/table/Table";
import {
  Bulk_purchase_preview_column,
  Bulk_purchase_table_column,
} from "../../../configs/dataTable.config";
import { billPaymentInstance } from "../../../utils/instance";
import ErrorUIModal from "./ErrorUIModal";
import "./index.css";
import PurchaseModal from "./PurchaseModal";
import SuccessfulPurchaseModal from "./SuccessfulPurchaseModal";
import Upload from "./Upload";

const ExcelJS = require("exceljs");

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 18l-6-6 6-6"
      ></path>
    </svg>
  );
}

const Bulk = () => {
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [files, setFiles] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [pages, setPages] = useState<any>(0);
  const [progress, setProgress] = useState<any>(0);
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showProcessing, setProcessing] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [size, setSize] = React.useState([0, 0]);
  const [queryParamsValue, setQueryParamsValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: DataTransaction,
    isFetching: dataLoading,
    refetch: refetchData,
  } = useDataBulk(pages);
  const {
    data: AirtimeTransaction,
    isFetching: airtimeLoading,
    refetch: refetchAirtime,
  } = useAirtimeBulk(pages);
  const workingData =
    queryParamsValue === "data"
      ? DataTransaction?.data?.data
      : AirtimeTransaction?.data?.data;
  const isLoadingData =
    queryParamsValue === "data" ? dataLoading : airtimeLoading;
  const refreshData = () => {
    setPages(0);
    refetchData();
    refetchAirtime();
  };
  const { resolve_confirm_purchase_data } = useConfirmDataPurchase();
  const { resolve_confirm_purchase_airtime } = useConfirmAirtimePurchase();
  const columns: any = React.useMemo(
    () => [...Bulk_purchase_preview_column],
    []
  );
  const workingColumns: any = React.useMemo(
    () => [...Bulk_purchase_table_column],
    []
  );
  const [activeItem, setActiveItem] = useState<number>(0);

  React.useEffect(() => {
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const valueFromQueryParam = queryParams.get("type");
    const previewQuery = queryParams.get("preview");

    // Update the state with the value from the query parameter
    if (valueFromQueryParam !== null) {
      setQueryParamsValue(valueFromQueryParam);
    } else {
      setQueryParamsValue("");
    }
    if (previewQuery !== null) {
      setPreview(Boolean(previewQuery));
    } else {
      setPreview(false);
    }
  }, [location.search]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateQueryParams = (key: string, value: string | null) => {
    const searchParams = new URLSearchParams(location.search);

    if (value !== null) {
      // Append or replace the query parameter with the provided key and value
      searchParams.set(key, value);
    } else {
      // Remove the query parameter with the provided key
      searchParams.delete(key);
    }

    // Construct the new search string with updated query parameters
    const searchString = searchParams.toString();
    const newLocation =
      location.pathname + (searchString ? `?${searchString}` : "");
    // Replace the current URL with the updated search string
    navigate(newLocation);
  };

  const parseExcelFile = async (file: any) => {
    setIsFetching(true);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const buffer = reader.result as any;
        try {
          const workbook = new ExcelJS.Workbook();
          const load = await workbook.xlsx.load(buffer).then((value: any) => {
            const data: any[] = [];
            switch (queryParamsValue) {
              case "airtime": {
                const worksheet = value.getWorksheet(1);
                worksheet.eachRow((row: any, rowNumber: any) => {
                  if (
                    rowNumber > 1 &&
                    row.getCell(1).value != null &&
                    row.getCell(2).value != null &&
                    row.getCell(4).result != null
                  ) {
                    const mobile_number = `0${row.getCell(1).value}`;
                    const amount = row.getCell(2).value;
                    const purchase_id = row.getCell(4).result;
                    data.push({ mobile_number, amount, purchase_id });
                  }
                });
                break;
              }
              case "data": {
                for (let i = 1; i <= value.worksheets.length - 1; i++) {
                  const worksheet = value.getWorksheet(i);
                  worksheet.eachRow((row: any, rowNumber: any) => {
                    if (
                      rowNumber > 1 &&
                      row.getCell(1).value != null &&
                      row.getCell(3).value != null &&
                      row.getCell(4).result != null
                    ) {
                      const mobile_number = `0${row.getCell(1).value}`;
                      const amount = row.getCell(4).result;
                      const network = worksheet?._name;
                      const purchase_id = row.getCell(3).result;
                      data.push({
                        mobile_number,
                        amount,
                        purchase_id,
                        network,
                      });
                    }
                  });
                }
                break;
              }
              default: {
                break;
              }
            }
            return data;
          });
          setIsFetching(false);
          if (load.length < 2) {
            updateQueryParams("preview", null);
            setErrorMsg(
              `You have uploaded a file containing only one recipient. Kindly use the single ${queryParamsValue} purchase feature for this transaction.`
            );
            setShow(true);
            throw `You have uploaded a file containing only one recipient. Kindly use the single ${queryParamsValue} purchase feature for this transaction.`;
          }
          setPreviewData(load);
          setErrorMsg("");
          setShow(false);
        } catch (e) {
          setIsFetching(false);
          console.log(e);
        }
      };
    });
  };

  const countCheckExcelFile = async (file: any, callback: () => void) => {
    setIsFetching(true);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const buffer = reader.result as any;
        try {
          const workbook = new ExcelJS.Workbook();
          const load = await workbook.xlsx.load(buffer).then((value: any) => {
            const data: any[] = [];
            switch (queryParamsValue) {
              case "airtime": {
                const worksheet = value.getWorksheet(1);
                worksheet.eachRow((row: any, rowNumber: any) => {
                  if (
                    rowNumber > 1 &&
                    row.getCell(1).value != null &&
                    row.getCell(2).value != null &&
                    row.getCell(4).result != null
                  ) {
                    const mobile_number = `0${row.getCell(1).value}`;
                    const amount = row.getCell(2).value;
                    const purchase_id = row.getCell(4).result;
                    data.push({ mobile_number, amount, purchase_id });
                    if (data.length === 2) return callback();
                  }
                });
                break;
              }
              case "data": {
                for (let i = 1; i <= value.worksheets.length - 1; i++) {
                  const worksheet = value.getWorksheet(i);
                  worksheet.eachRow((row: any, rowNumber: any) => {
                    if (
                      rowNumber > 1 &&
                      row.getCell(1).value != null &&
                      row.getCell(3).value != null &&
                      row.getCell(4).result != null
                    ) {
                      const mobile_number = `0${row.getCell(1).value}`;
                      const amount = row.getCell(4).result;
                      const network = worksheet?._name;
                      const purchase_id = row.getCell(3).result;
                      data.push({
                        mobile_number,
                        amount,
                        purchase_id,
                        network,
                      });
                      if (data.length === 2) return callback();
                    }
                  });
                }
                break;
              }
              default: {
                break;
              }
            }
            return data;
          });
          setIsFetching(false);
          if (load.length < 2) {
            updateQueryParams("preview", null);
            setErrorMsg(
              `You have uploaded a file containing only one recipient. Kindly use the single ${queryParamsValue} purchase feature for this transaction.`
            );
            setShow(true);
            throw `You have uploaded a file containing only one recipient. Kindly use the single ${queryParamsValue} purchase feature for this transaction.`;
          }
          setPreviewData(load);
          setErrorMsg("");
          setShow(false);
        } catch (e) {
          setIsFetching(false);
          console.log(e);
        }
      };
    });
  };
  const UploadToAPI = async (transaction_pin: string) => {
    try {
      setIsDisabled(false);
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("transaction_pin", transaction_pin);
      const res = await billPaymentInstance.post(
        `/${queryParamsValue}/buy`,
        formData,
        {
          onUploadProgress: (progressEvent: any) => {
            const progressUpdate =
              (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progressUpdate);
          },
        }
      );
      const id = res?.data?.data?.batch_id ?? res?.data?.data?.[0]?.reference;
      if (queryParamsValue === "data") {
        await resolve_confirm_purchase_data.mutateAsync({
          transaction_pin,
          id,
        });
      }
      if (queryParamsValue === "airtime") {
        await resolve_confirm_purchase_airtime.mutateAsync({
          transaction_pin,
          id,
        });
      }
      setProcessing(true);
      setIsDisabled(true);
      setShowPin(false);
      setIsUploading(false);
      setProgress(0);
      setFile(null);
    } catch (e: any) {
      setIsUploading(false);
      toast.error(
        e.response.data.data || e.response.data.message || e.response.message
      );
      throw e;
    }
  };
  const processor =
    queryParamsValue === "data"
      ? resolve_confirm_purchase_data
      : resolve_confirm_purchase_airtime;
  const handleReset = () => {
    setFiles([]);
    setFile(null);
    setProgress(0);
    refreshData();
    setPreview(false);
    updateQueryParams("preview", null);
    setIsFetching(false);
    setIsDisabled(true);
    setProcessing(false);
    setShowPin(false);
  };

  return (
    <div>
      {show && (
        <ErrorUIModal
          show={show}
          handleClose={() => setShow(false)}
          body={errorMsg}
        />
      )}
      {showPin && (
        <PurchaseModal
          show={showPin}
          handleClose={() => setShowPin(false)}
          handlePay={UploadToAPI}
          processor={processor}
          noModal={false}
          size={size}
          title={queryParamsValue}
          isDisabled={processor.isLoading || isUploading}
          value={calculateTotalAmount(previewData, "amount")}
          length={previewData?.length}
        />
      )}
      <SuccessfulPurchaseModal
        show={showProcessing}
        handleClose={() => {
          setProcessing(false);
          handleReset();
        }}
        size={size}
        title={queryParamsValue}
      />

      {size?.[0] <= 998 && (
        <div className="d-grid flex-row justify-content-center py-3 align-items-center w-100 position-relative">
          {preview ? (
            <div
              className="position-absolute left-0"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPreview(false);
                updateQueryParams("preview", null);
              }}
            >
              <BackIcon />
            </div>
          ) : (
            <Link className="position-absolute left-0" to={`/merchant/topup`}>
              <BackIcon />
            </Link>
          )}
          <p className="text-center mb-0 text-capitalize">
            {!preview
              ? `Bulk ${queryParamsValue} upload`
              : `Preview bulk ${queryParamsValue} upload`}
          </p>
        </div>
      )}
      {!preview ? (
        <div className="bg-white w-100 py-4 px-3 position-relative">
          {size?.[0] > 998 && (
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary mb-4 mt-2"
                onClick={() =>
                  window.open(
                    `${
                      queryParamsValue === "data"
                        ? process.env.REACT_APP_DATA
                        : process.env.REACT_APP_AIRTIME
                    }`,
                    "_blank"
                  )
                }
              >
                <i className="bi bi-download"></i>&emsp; Download Template
              </button>
            </div>
          )}
          <div className="p-2 dropbox">
            <Upload
              isDisabled={isDisabled}
              {...{
                progress,
                UploadToAPI,
                file,
                setFile,
                files,
                setFiles,
                size,
              }}
            />
          </div>
          {file && (
            <div
              className="d-flex justify-content-center align-items-center mt-3"
              style={{ gap: 20 }}
            >
              <button
                className="btn fw-bolder"
                onClick={async () => {
                  updateQueryParams("preview", "yes");
                  setPreview(true);
                  await parseExcelFile(file);
                }}
                style={{
                  color: "black",
                  border: "1px solid #CBD1EC",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  borderRadius: "8px",
                  boxShadow: "2px 2px 0px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                Preview
              </button>
              <button
                className="btn btn-primary"
                onClick={async () => {
                  try {
                    await countCheckExcelFile(file, () => setShowPin(true));
                  } catch (e) {
                    console.log("err", e);
                  }
                }}
                disabled={isFetching}
                style={{
                  height: "32px",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Continue
                {isFetching && (
                  <span className="align-middle ml-2">
                    &emsp;
                    <SpinnerComponent size="sm" />
                  </span>
                )}
              </button>
            </div>
          )}
          {size?.[0] > 998 && (
            <div
              style={{
                border: "1px solid #CBD1EC",
                borderRadius: 8,
                overflow: "hidden",
              }}
              className="my-5"
            >
              <div className="card-header card-header-content-sm-between py-4 px-4">
                <div className="mb-2 mb-sm-0">
                  <h4 className="card-header-title">Recent bulk purchases</h4>
                </div>

                <div className="d-grid d-sm-flex justify-content-sm-between align-items-sm-center gap-2">
                  <div id="datatableCounterInfo">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-white btn-sm w-100"
                        aria-expanded="false"
                        onClick={() => {
                          refreshData();
                        }}
                      >
                        <i className="bi bi-arrow-clockwise me-2"></i> Refresh
                      </button>
                      <span className="fs-6 me-3" style={{ display: "none" }}>
                        <span id="datatableCounter">0</span>
                        Selected
                      </span>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        style={{ display: "none" }}
                      >
                        <i className="bi-trash"></i> Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive datatable-custom">
                <Table
                  columns={workingColumns}
                  data={workingData !== undefined ? workingData?.items : []}
                  isFetching={isLoadingData}
                  navigateTo={`/merchant/topup/bulk/${queryParamsValue}/`}
                  queryKey="filename"
                  queryValue="filename"
                />
              </div>

              <div className="card-footer">
                <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                  <div className="col-sm mb-2 mb-sm-0"></div>

                  <div className="col-sm-auto">
                    <div className="d-flex justify-content-center justify-content-sm-end">
                      {workingData?.items?.length > 0 && (
                        <PaginationComponent
                          totalItems={workingData?.totalPages}
                          activeItem={pages}
                          setActiveItem={setPages}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {size?.[0] <= 998 && (
            <>
              <div className="mt-5">
                <hr />
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm "
                    aria-expanded="false"
                    onClick={() => {
                      refreshData();
                    }}
                  >
                    <i className="bi bi-arrow-clockwise me-2 text-white"></i>{" "}
                    Refresh
                  </button>
                </div>
                <br />
                {isLoadingData &&
                  ["", "", "", "", "", "", "", "", "", ""].map(
                    (item: any, index: number) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-start pointer"
                        style={{
                          borderBottom: "1px solid #dee2e6",
                          cursor: "pointer",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="d-flex flex-column p-2">
                            <p className="text-capitalize mb-1 fw-bold">
                              <Skeleton height={30} width={100} />
                            </p>
                            <span
                              className="text-capitalize"
                              style={{ color: "#788088" }}
                            >
                              <Skeleton height={30} width={100} />
                            </span>
                            <span
                              className="text-capitalize"
                              style={{ color: "#788088" }}
                            >
                              <Skeleton height={30} width={100} />
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column p-2">
                          <p
                            className="text-capitalize mb-1 fw-bold"
                            style={{ textAlign: "right" }}
                          >
                            <Skeleton height={30} width={100} />
                          </p>
                          <span
                            className="text-capitalize"
                            style={{ color: "#788088" }}
                          >
                            <Skeleton height={30} width={100} />
                          </span>
                        </div>
                      </div>
                    )
                  )}
                {!isLoadingData &&
                  workingData?.items?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-start pointer"
                      onClick={() =>
                        navigate(
                          `/merchant/topup/bulk/${queryParamsValue}/${item?.reference}?filename=${item?.filename}`
                        )
                      }
                      style={{
                        borderBottom: "1px solid #dee2e6",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column p-2">
                          <span
                            className="text-capitalize mb-1 fw-bold"
                            style={{ color: "#788088", fontSize: 14 }}
                          >
                            {item?.filename} &bull; {item?.reference}
                          </span>
                          <span
                            className="text-capitalize mb-1 fw-semibold"
                            style={{ color: "#000", fontSize: 14 }}
                          >
                            {formatAmount(item?.total_amount)}
                          </span>
                          <span
                            className="text-capitalize"
                            style={{ color: "#788088", fontSize: 14 }}
                          >
                            {convertDateFormat(item?.created_at)}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column p-2">
                        <p
                          className="text-capitalize mb-1 fw-bold"
                          style={{ textAlign: "right" }}
                        >
                          count: {item?.total}
                        </p>
                        <span
                          className="badge bg-soft-success text-success ms-2"
                          style={{ fontSize: 12 }}
                        >
                          Successful: {item?.completed}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              {!isLoadingData && workingData?.items.length > 0 && (
                <div className="col-sm-auto mt-2 w-100">
                  <div className="d-flex py-4 w-100 justify-content-center justify-content-sm-end">
                    {workingData?.items?.length > 0 && (
                      <PaginationComponent
                        totalItems={workingData?.totalPages}
                        activeItem={pages}
                        setActiveItem={setPages}
                      />
                    )}
                  </div>
                </div>
              )}
              {!isLoadingData &&
                (workingData?.items.length === 0 || !workingData) && (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    You&apos;ve made no Bulk Upload yet.
                  </div>
                )}
              <div
                style={{
                  width: "100%",
                  boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.08)",
                  gap: 14,
                  left: 0,
                  right: 0,
                  padding: "22px 27px 25px",
                  zIndex: "99999",
                }}
                className="d-flex flex-column bottom-0 position-fixed bg-white"
              >
                <div className="d-flex flex-row" style={{ gap: 14 }}>
                  <button
                    className="btn btn-primary mb-4 mt-2 w-100"
                    onClick={() =>
                      window.open(
                        `${
                          queryParamsValue === "data"
                            ? process.env.REACT_APP_DATA
                            : process.env.REACT_APP_AIRTIME
                        }`,
                        "_blank"
                      )
                    }
                  >
                    <i className="bi bi-download"></i>&emsp; Download Template
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="w-100 py-4 px-3 position-relative">
          <div
            className={`card mb-3 mb-lg-5 ${
              size?.[0] <= 998 ? "col-12 px-2 py-4" : "col-6 card-body"
            }`}
          >
            <div className="row col-lg-divider gx-lg-6 col-12">
              <div className="col-lg-6">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="card-subtitle mb-3">Total amount</h6>
                    {isFetching ? (
                      <Skeleton height={20} width={200} />
                    ) : (
                      <h3 className="card-title">
                        {formatAmount(
                          calculateTotalAmount(previewData, "amount")
                        )}
                      </h3>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="card-subtitle mb-3">Total count</h6>
                    {isFetching ? (
                      <Skeleton height={20} width={200} />
                    ) : (
                      <h3 className="card-title">{previewData.length}</h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`d-flex align-items-center ${
              size?.[0] <= 998
                ? "justify-content-center"
                : "justify-content-between"
            } py-3`}
          >
            <span style={{ color: "#8F9BB2", fontSize: "16px" }}>
              Please preview the details of the upload
            </span>
            {size?.[0] > 998 && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ gap: 20 }}
              >
                <button
                  className="btn fw-bolder"
                  onClick={() => {
                    setPreview(false);
                    updateQueryParams("preview", null);
                  }}
                  style={{
                    color: "black",
                    border: "1px solid #CBD1EC",
                    height: "32px",
                    display: "flex",
                    backgroundColor: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    borderRadius: "8px",
                    boxShadow: "2px 2px 0px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  Upload again
                </button>
                <button
                  className="btn btn-primary"
                  onClick={async () => {
                    setShowPin(true);
                  }}
                  style={{
                    height: "32px",
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Continue
                </button>
              </div>
            )}
          </div>

          {size?.[0] > 998 && (
            <div
              style={{
                border: "1px solid #CBD1EC",
                borderRadius: 8,
                overflow: "hidden",
              }}
              className="my-5"
            >
              <div className="card-header card-header-content-sm-between py-4 px-4">
                <div className="mb-2 mb-sm-0">
                  <h4 className="card-header-title">Details</h4>
                </div>
              </div>

              <div className="table-responsive datatable-custom bg-white">
                <Table
                  columns={columns}
                  data={paginateData(previewData, activeItem, 10)}
                  isFetching={isFetching}
                />
              </div>

              <div className="card-footer bg-white">
                <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                  <div className="col-sm mb-2 mb-sm-0"></div>

                  <div className="col-sm-auto">
                    <div className="d-flex justify-content-center justify-content-sm-end">
                      {previewData?.length > 0 && (
                        <PaginationComponent
                          totalItems={previewData?.length / 10}
                          activeItem={activeItem}
                          setActiveItem={setActiveItem}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {size?.[0] <= 998 && (
            <>
              <div>
                {isFetching &&
                  ["", "", "", "", "", "", "", "", "", ""].map(
                    (item: any, index: number) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-start pointer"
                        style={{
                          borderBottom: "1px solid #dee2e6",
                          cursor: "pointer",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="d-flex flex-column p-2">
                            <p className="text-capitalize mb-1 fw-bold">
                              <Skeleton height={30} width={100} />
                            </p>
                            <span
                              className="text-capitalize"
                              style={{ color: "#788088" }}
                            >
                              <Skeleton height={30} width={100} />
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                {!isFetching &&
                  paginateData(previewData, activeItem, 10)?.map(
                    (item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="d-flex justify-content-between bg-white p-2 align-items-start pointer"
                          style={{
                            borderRadius: 8,
                            marginBottom: 20,
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div className="d-flex flex-column p-2">
                              <span
                                className="text-capitalize mb-1 fw-semibold"
                                style={{ color: "#000", fontSize: 14 }}
                              >
                                {(item?.network as string) ??
                                  (airtime_purchase_id_map[
                                    item?.purchase_id
                                  ] as string)}{" "}
                                - {item?.mobile_number}
                              </span>
                              <span
                                className="text-capitalize mb-1 fw-bold"
                                style={{ color: "#788088", fontSize: 14 }}
                              >
                                {formatAmount(item?.amount)}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
              {!isFetching &&
                paginateData(previewData, activeItem, 10).length > 0 && (
                  <div className="col-sm-auto mt-2 w-100">
                    <div className="d-flex py-4 w-100 justify-content-center justify-content-sm-end">
                      {paginateData(previewData, activeItem, 10)?.length >
                        0 && (
                        <PaginationComponent
                          totalItems={previewData?.length / 10}
                          activeItem={activeItem}
                          setActiveItem={setActiveItem}
                        />
                      )}
                    </div>
                  </div>
                )}
              {!isFetching &&
                (paginateData(previewData, activeItem, 10).length === 0 ||
                  !paginateData(previewData, activeItem, 10)) && (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    You&apos;ve made no Bulk Upload yet.
                  </div>
                )}
              <div
                style={{
                  width: "100%",
                  boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.08)",
                  gap: 14,
                  left: 0,
                  right: 0,
                  padding: "22px 27px 51px",
                  zIndex: "99999",
                }}
                className="d-flex flex-column bottom-0 position-fixed bg-white"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ gap: 20 }}
                >
                  <button
                    className="btn fw-bolder w-50"
                    onClick={() => {
                      setPreview(false);
                      updateQueryParams("preview", null);
                    }}
                    style={{
                      color: "black",
                      border: "1px solid #CBD1EC",
                      height: "42px",
                      display: "flex",
                      backgroundColor: "white",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "12px",
                      borderRadius: "8px",
                      boxShadow: "2px 2px 0px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    Upload again
                  </button>
                  <button
                    className="btn btn-primary w-50"
                    onClick={async () => {
                      setShowPin(true);
                    }}
                    style={{
                      height: "42px",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Bulk;
