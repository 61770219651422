import React, { useState } from "react";
import SpinnerComponent from "../../component/spinner/Spinner";
import { tokenInterface } from "../../utils/interfaces";
import { useRequestTokenValidation } from "../../hooks/auth/auth.hook";
import { PATH_NAMES } from "../../constants/index.constant";
import { useNavigate, useParams } from "react-router-dom";
import AuthIllustration from "../../assets/svg/logos/Pooler-sign-up-image.png";
import PoolerIpad from "../../assets/svg/logos/ipad-pooler.svg";
import PoolerLogoWhite from "../../assets/svg/logos/Pooler_logo_white.svg";
import { validatePassword } from "../../helpers/index.helper";
import PoolerLogo from "../../assets/svg/logos/poolerdarklogo.svg";

function ResetToken(): JSX.Element {
  const navigate = useNavigate();
  const params: any = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<tokenInterface>({
    token: params?.token,
    password: "",
  });
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<any>(null);

  const toggleVisibility = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const { token_validation } = useRequestTokenValidation();

  const onSubmitToken = async (payload: tokenInterface) => {
    setIsLoading(true);
    try {
      const response = await token_validation.mutateAsync(payload);
      if (response.status === 200) {
        navigate(`/auth${PATH_NAMES.signin}`);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  return (
    <div className="row">
        <div
          style={{ backgroundImage: `url(${AuthIllustration})` }}
          className="col-lg-6 overflow-hidden d-none --bg-auth d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
        >
          <div id="banner-conc">
            <img src={PoolerLogoWhite} alt="pooler-logo" />
            <h1>
              The Easier Way to
              <br />
              Receive Payments.
            </h1>
            <p>Experience world-class banking-as-a-service</p>
          </div>
          <img src={PoolerIpad} alt="pooler-ipad" />
        </div>

      <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100 position-relative">
        <div
          className="w-100 content-space-t-1 content-space-t-lg-2 content-space-b-1 px-4 px-lg-0"
          style={{ maxWidth: "25rem" }}
        >
          <div className="text-center d-sm-block d-md-block d-lg-none mx-auto">
            <img
              src={PoolerLogo}
              alt="pooler"
              className="navbar-brand-logo d-sm-block d-md-block d-lg-none mx-auto"
              style={{ paddingBottom: "15%" }}
            />
          </div>
          {/* <div className="text-center">
            <div className="mb-5">
              <h1 className="display-5">Reset Password</h1>
            </div>

            <div className="mb-5">
              <p className="mb-0">We sent a token to your email.</p>
              <p>Enter the token from the email in the field below.</p>
            </div>
          </div> */}

          <form
            className="js-validate needs-validation"
            onSubmit={(e) => e.preventDefault()}
          >
            <>
              <div className="mb-4">
                <label
                  className="form-label w-100"
                  htmlFor="signupSrPassword"
                  tabIndex={0}
                >
                  New Password
                </label>

                <div
                  className="input-group input-group-merge"
                  data-hs-validation-validate-class
                >
                  <input
                    type={passwordOpen ? "text" : "password"}
                    className={`js-toggle-password form-control form-control-lg ${
                      isPasswordValid === false ? "border border-danger" : ""
                    } ${
                      isPasswordValid === true ? "border border-secondary" : ""
                    }`}
                    name="password"
                    id="password"
                    placeholder="password"
                    aria-label="password"
                    autoComplete="off"
                    value={formData?.password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setIsPasswordValid(validatePassword(e.target.value));
                    }}
                  />
                  <button
                    id="changePassTarget"
                    className="input-group-append input-group-text"
                    type={"button"}
                  >
                    {!eyeOpen ? (
                      <i
                        id="changePassIcon"
                        className="bi-eye"
                        onClick={() => {
                          toggleVisibility();
                          setEyeOpen(!eyeOpen);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash"
                        onClick={() => {
                          toggleVisibility();
                          setEyeOpen(!eyeOpen);
                        }}
                      />
                    )}
                  </button>
                </div>

                <span
                  className={`form-text ${
                    isPasswordValid === true ? "text-success" : ""
                  } ${isPasswordValid === false ? "text-danger" : ""}`}
                >
                  Your password must be 8-20 characters long, atleast one
                  uppercase letter, one lowercase letter, one number and one
                  special character.
                </span>
              </div>
              <div className="d-grid">
                <button
                  type={"button"}
                  className="btn btn-primary btn-lg"
                  disabled={
                    isLoading ||
                    formData.password === "" ||
                    formData.token === "" ||
                    isPasswordValid === false ||
                    formData.token.length <= 0
                      ? true
                      : false
                  }
                  onClick={() =>
                    onSubmitToken({
                      token: params?.token,
                      password: formData.password,
                    })
                  }
                >
                  Update &nbsp;{" "}
                  <span className="align-middle">
                    {isLoading && <SpinnerComponent size="sm" />}
                  </span>
                </button>
              </div>
            </>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetToken;
