import React from "react";
import { CardProps } from "../../utils/interfaces";
import CardFooter from "./CardFooter";
import CardHeader from "./CardHeader";

function Card({
  children,
  pagination,
  refresh,
  inputValue,
  handleInputChange,
  dates,
  handleDatesFilter,
  handleExports,
  data,
  handleFilter,
  loading,
  setDates,
}: CardProps) {
  return (
    <div className="card position-relative">
      {/* CARD HEADER */}
      <CardHeader
        refresh={refresh}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        dates={dates}
        handleDatesFilter={handleDatesFilter}
        handleExports={handleExports}
        data={data}
        handleFilter={handleFilter}
        loading={loading}
        setDates={setDates}
      />
      {/* CARD BODY */}
      <>{children}</>
      {/* CARD FOOTER */}
      <CardFooter pagination={<>{pagination}</>} />
    </div>
  );
}

export default Card;
