import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthIllustration from "../../assets/svg/logos/Pooler-sign-up-image.png";
import PoolerIpad from "../../assets/svg/logos/ipad-pooler.svg";
import PoolerLogoWhite from "../../assets/svg/logos/Pooler_logo_white.svg";
import PoolerLogo from "../../assets/svg/logos/poolerdarklogo.svg";
import OtpFields from "../../component/otp/OtpFields";
import SEO from "../../component/seo/seo";
import SpinnerComponent from "../../component/spinner/Spinner";
import { PATH_NAMES } from "../../constants/index.constant";
import { useResendOtp, useTwoFactor } from "../../hooks/auth/auth.hook";
import Spinner from "./spinner";

function VerifyEmail(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const email: string | any = location?.state?.email;
  const { verify_otp } = useTwoFactor("complete2fa");
  const { resend_otp } = useResendOtp();

  const Verify = async (otpStrings: any | string, email: string) => {
    // setIsLoading(true);
    try {
      const response: any = await verify_otp.mutateAsync({
        email: email,
        auth_code: otpStrings,
      });
      if (response.status === 200) {
        handleClose();
        navigate(`/auth/merchant${PATH_NAMES.signin}`);
      }
    } catch (error) {
      setReadOnly(false);
      setShow(false);
      handleClose();
      setIsLoading(false);
      return error;
    }
    handleClose();
    // setIsLoading(false);
  };

  const ResendOtp = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await resend_otp.mutateAsync(email);
      if (response.status === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOtpChange = (otp: any) => {
    if (otp.length === 6) {
      handleShow();
      setReadOnly(true);
      setTimeout(() => {
        Verify(otp, email);
      }, 2500);
    }
  };

  return (
    <>
      <SEO
        title="Pooler - Collecting Safely"
        description="Use Pooler to collect and settle payments seamless and faster by transfer and avoid chargeback"
        type="website"
      />
      {show && (
        <div className="overlay-effect">
          <div className="overlay-child">
            <Spinner />
          </div>
        </div>
      )}
      <div className="row">
        {/* LEFT */}
        <div
          style={{ backgroundImage: `url(${AuthIllustration})` }}
          className="col-lg-6 overflow-hidden d-none --bg-auth d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
        >
          <div id="banner-conc">
            <img src={PoolerLogoWhite} alt="pooler-logo" />
            <h1>
              The Easier Way to
              <br />
              Receive Payments.
            </h1>
            <p>Experience world-class banking-as-a-service</p>
          </div>
          <img src={PoolerIpad} alt="pooler-ipad" />
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100 position-relative">
          <div
            className=" content-space-t-1 content-space-t-lg-2 content-space-b-1 px-3 px-lg-0"
            style={{ maxWidth: "25rem" }}
          >
            <div className="text-center d-sm-block d-md-block d-lg-none mx-auto">
              <img
                src={PoolerLogo}
                alt="pooler"
                className="navbar-brand-logo d-sm-block d-md-block d-lg-none mx-auto"
                style={{ paddingBottom: "15%" }}
              />
            </div>
            <div className="text-center">
              <div className="mb-5">
                <h1 className="display-5">Please verify your email</h1>
                <p className="mb-0">
                  Enter the OTP sent to <strong>{email}</strong>.
                </p>
                {/* <p>Enter the code from the email in the field below.</p> */}
              </div>
              <div className="row gx-2 gx-sm-3">
                <OtpFields onOtpInput={handleOtpChange} readOnly={readOnly} />
                <div className="d-grid mb-3">
                  <button
                    type="button"
                    className="btn btn-ghost-primary"
                    onClick={() => ResendOtp(email)}
                  >
                    Resend OTP{" "}
                    {isLoading && (
                      <>
                        &nbsp; <SpinnerComponent size="sm" />
                      </>
                    )}
                  </button>
                </div>
                <div className="d-grid mb-3 d-none">
                  <button type="button" className="btn btn-primary btn-lg">
                    Activate
                    <span className="align-middle">
                      {isLoading && <SpinnerComponent size="sm" />}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
