import React, { Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import BackBtn from "../../component/back-btn";
import DeleteInvoiceModal from "../../component/modal/DeleteInvoiceModal";
import SendInvoiceReminderModal from "../../component/modal/SendInvoiceReminderModal";
import ShareInvoiceModal from "../../component/modal/ShareInvoiceModal";

function InvoiceDetails(): JSX.Element {
  const [share, setShare] = useState<boolean>(false);
  const [reminder, setReminder] = useState<boolean>(false);
  const [deleteInvoice, setDeleteInvoice] = useState<boolean>(false);

  return (
    <Fragment>
      <ShareInvoiceModal show={share} handleClose={() => setShare(false)} />
      <SendInvoiceReminderModal
        show={reminder}
        handleClose={() => setReminder(false)}
      />
      <DeleteInvoiceModal
        show={deleteInvoice}
        handleClose={() => setDeleteInvoice(false)}
      />
      <div className="page-header mb-0">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            {false ? (
              <Skeleton width={200} />
            ) : (
              <h1 className="page-header-title">
                <BackBtn />
                Micheal Ballard &nbsp;
                <span className="badge bg-soft-success text-success ms-2">
                  <span className="legend-indicator bg-success"></span>
                  Paid
                </span>
              </h1>
            )}
          </div>

          <div className="col-sm-auto d-none d-md-block d-lg-block">
            <div className="d-flex align-items-center  justify-content-end mt-auto">
              <button type="button" className="btn btn-primary">
                Mark as paid
              </button>
              &nbsp;&nbsp;&nbsp;
              {false ? (
                <Skeleton />
              ) : (
                <button
                  type="button"
                  className="btn kyc-save-btn"
                  onClick={() => setReminder(true)}
                >
                  Send reminder
                </button>
              )}
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn kyc-save-btn text-success"
                onClick={() => setShare(true)}
              >
                Share <i className="bi bi-arrow-up-right text-success"></i>
              </button>
              &nbsp;&nbsp;&nbsp;
              <div className="btn-group">
                <button
                  className="btn btn-white border border-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButtonWhite"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Options <i className="bi bi-chevron-down ps-1" hidden></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButtonWhite"
                >
                  <span className="dropdown-item" style={{ cursor: "pointer" }}>
                    Edit invoice
                  </span>
                  <span className="dropdown-item" style={{ cursor: "pointer" }}>
                    Duplicate invoice
                  </span>
                  <span className="dropdown-item" style={{ cursor: "pointer" }}>
                    Download invoice
                  </span>
                  <span
                    className="dropdown-item text-danger"
                    onClick={() => setDeleteInvoice(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Delete invoice
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-block d-md-none d-lg-none">
          <div className="d-flex flex-wrap gap-2">
            <button type="button" className="btn btn-primary">
              Mark as paid
            </button>
            &nbsp;&nbsp;&nbsp;
            {false ? (
              <Skeleton />
            ) : (
              <button
                type="button"
                className="btn kyc-save-btn"
                onClick={() => setReminder(true)}
              >
                Send reminder
              </button>
            )}
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn kyc-save-btn text-success"
              onClick={() => setShare(true)}
            >
              Share <i className="bi bi-arrow-up-right text-success"></i>
            </button>
            &nbsp;&nbsp;&nbsp;
            <div className="btn-group">
              <button
                className="btn btn-white border border-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButtonWhite"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Options <i className="bi bi-chevron-down ps-1" hidden></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButtonWhite"
              >
                <span className="dropdown-item" style={{ cursor: "pointer" }}>
                  Edit invoice
                </span>
                <span className="dropdown-item" style={{ cursor: "pointer" }}>
                  Duplicate invoice
                </span>
                <span className="dropdown-item" style={{ cursor: "pointer" }}>
                  Download invoice
                </span>
                <span
                  className="dropdown-item text-danger"
                  onClick={() => setDeleteInvoice(true)}
                  style={{ cursor: "pointer" }}
                >
                  Delete invoice
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6 col-lg-4 mb-1 mb-lg-1">
          <div className="card">
            <div className="card-body text-start">
              {" "}
              <small className="text-cap">Total amount due</small>
              {false ? (
                <span className="js-counter d-block display-3 text-dark mb-2">
                  <Skeleton height={"100%"} width={200} />
                </span>
              ) : (
                <span className="js-counter d-block h4 text-dark mb-2">
                  NGN700,000
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
          <div className="card">
            <div className="card-body text-start">
              {" "}
              <small className="text-cap">Total amount due</small>
              {false ? (
                <span className="js-counter d-block display-3 text-dark mb-2">
                  <Skeleton height={"100%"} width={200} />
                </span>
              ) : (
                <span className="js-counter d-block h4 text-dark mb-2">
                  NGN3,400,505.00
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
          <div className="card">
            <div className="card-body text-start">
              {" "}
              <small className="text-cap">Total amount due</small>
              {false ? (
                <span className="js-counter d-block display-3 text-dark mb-2">
                  <Skeleton height={"100%"} width={200} />
                </span>
              ) : (
                <span className="js-counter d-block h4 text-dark mb-2">
                  NGN3,400,505.00
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-3 mb-sm-0">
          <div className="card hard-shadow mb-3">
            <div className="card-body">
              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Invoice title</p>
                <p className="text-sm-end ms-sm-auto">
                  New merchandise purchase
                </p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Bill to</p>
                <p className="text-sm-end ms-sm-auto text-primary">
                  michaelesigbone@gmai.com
                </p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Sent on</p>
                <p className="text-sm-end ms-sm-auto">23-09-2023</p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Date created</p>
                <p className="text-sm-end ms-sm-auto">23-09-2023</p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Due date</p>
                <p className="text-sm-end ms-sm-auto">23-09-2023</p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Invoice note</p>
                <p className="text-sm-end ms-sm-auto">
                  Thank you for choosing to do business with us
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-3 mb-sm-0">
          <div className="card hard-shadow mb-3">
            <div className="card-body">
              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Shirt</p>
                <p className="text-sm-end ms-sm-auto">NGN 10,000.00</p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Jeans</p>
                <p className="text-sm-end ms-sm-auto text-primary">
                  NGN 10,000.00
                </p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Blueface cap</p>
                <p className="text-sm-end ms-sm-auto">NGN 10,000.00</p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0 fw-bold text-dark">Subtotal</p>
                <p className="text-sm-end ms-sm-auto">NGN 10,000.00</p>
              </div>

              <hr />

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Discount</p>
                <p className="text-sm-end ms-sm-auto">NGN 10,000.00</p>
              </div>

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0">Tax</p>
                <p className="text-sm-end ms-sm-auto">NGN 10,000.00</p>
              </div>

              <hr />

              <div className="hstack flex-column flex-sm-row align-items-start gap-sm-5 pt-3">
                <p className="text-body mb-0 fw-bold text-dark">Total</p>
                <p className="text-sm-end ms-sm-auto">NGN 10,000.00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default InvoiceDetails;
