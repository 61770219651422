import { Modal } from "react-bootstrap";
import React from "react";
import { ReactComponent as PendingIcon } from "../../../assets/processing.svg";

const SuccessfulPurchaseModal = ({
  show,
  handleClose,
  title = "airtime",
  size,
}: {
  size: any;
  show: boolean;
  handleClose: () => void;
  title?: string;
}) => {
  return (
    <React.Fragment>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          contentClassName="top-up-modal"
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                style={{
                  display: "flex",
                  width: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "22px",
                  marginTop: "-22px",
                }}
              >
                <PendingIcon />
              </div>
              <div
                className="text-black"
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textAlign: "center",
                  marginBottom: "22px",
                }}
              >
                <strong>Bulk {title} purchase is being processed</strong>
                <br />
                <small style={{ color: "#8F9BB2" }}>
                  You can check the status of your purchase in your top up
                  history shortly
                </small>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default SuccessfulPurchaseModal;
