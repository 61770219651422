import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../component/back-btn";

function CreateInvoice(): JSX.Element {
  const navigate = useNavigate();
  const [fieldPairs, setFieldPairs] = useState<
    { itemName: ""; qty: ""; price: "" }[]
  >([]);

  const [reminder, setRminder] = useState<boolean>(false);

  const addNewPair = () => {
    setFieldPairs([...fieldPairs, { itemName: "", qty: "", price: "" }]);
  };

  const handleRemoveClick = (index: any) => {
    const newList = [...fieldPairs];
    newList.splice(index, 1);
    setFieldPairs(newList);
  };

  return (
    <main>
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">
              <BackBtn />
              Create invoice
            </h1>
          </div>

          <div className="col-sm-auto"></div>
        </div>
      </div>

      <div className="row mt-3 px-3 px-lg-0 px-md-3">
        {/* LEFT */}
        <div className="col-lg-6 pe-5">
          <div className="row mb-3">
            <div className="col">
              <label className="form-label" htmlFor="firstname">
                Invoice title*
              </label>
              <input
                type="text"
                className={`form-control border border-secondary`}
                name="firstname"
                id="firstname"
                tabIndex={1}
                placeholder=""
                aria-label="firstname"
                required
              />
              <span className="invalid-feedback">
                Please enter valid first name.
              </span>
            </div>
            <div className="col">
              <label className="form-label" htmlFor="lastname">
                Invoice ID
              </label>
              <input
                type="text"
                className={`form-control border border-secondary`}
                name="lastname"
                id="lastname"
                tabIndex={1}
                placeholder="0001"
                aria-label="lastname"
                required
              />
              <span className="invalid-feedback">
                Please enter valid last name.
              </span>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col mb-2">
              <label className="form-label" htmlFor="issue_date">
                Invoice date
              </label>
              <input
                type="date"
                name="issue_date"
                id="issue_date"
                placeholder="Enter date"
                className={`js-daterangepicker form-control daterangepicker-custom-input border border-secondary`}
              />
            </div>

            <div className="col mb-2">
              <label className="form-label" htmlFor="issue_date">
                Due date
              </label>
              <input
                type="date"
                name="issue_date"
                id="issue_date"
                placeholder="Enter date"
                className={`js-daterangepicker form-control daterangepicker-custom-input border border-secondary`}
              />
            </div>
          </div>

          <hr className="hor-rule" />

          <div>
            {fieldPairs.map(
              (field: { itemName: ""; qty: ""; price: "" }, index: any) => {
                return (
                  <div className="row mb-3 animated-row">
                    <div className="col-lg-4">
                      <label className="form-label" htmlFor="firstname">
                        Item name
                      </label>
                      <input
                        type="text"
                        className={`form-control border border-secondary`}
                        name="firstname"
                        id="firstname"
                        tabIndex={1}
                        placeholder="Enter first name"
                        aria-label="firstname"
                        required
                      />
                    </div>
                    <div className="col-lg-3">
                      <label className="form-label" htmlFor="firstname">
                        Quantity
                      </label>
                      <input
                        type="text"
                        className={`form-control border border-secondary`}
                        name="firstname"
                        id="firstname"
                        tabIndex={1}
                        placeholder="Enter first name"
                        aria-label="firstname"
                        required
                      />
                    </div>
                    <div className="col-lg-3">
                      <label className="form-label" htmlFor="firstname">
                        Unit price
                      </label>
                      <input
                        type="text"
                        className={`form-control border border-secondary`}
                        name="firstname"
                        id="firstname"
                        tabIndex={1}
                        placeholder="Enter first name"
                        aria-label="firstname"
                        required
                      />
                    </div>
                    <div className="col-lg-2 d-flex">
                      <button
                        type="button"
                        className="btn btn-danger btn-icon"
                        style={{
                          alignSelf: "end",
                          background: "#FF003D",
                          borderRadius: "8px",
                          fontSize: "23px",
                        }}
                        onClick={() => handleRemoveClick(index)}
                      >
                        <i className="bi bi-trash3"></i>
                      </button>
                    </div>
                  </div>
                );
              }
            )}
            <div className="mx-auto text-center">
              <button
                type="button"
                className="btn btn-link"
                onClick={addNewPair}
              >
                <i
                  className="bi bi-plus-lg fw-bold"
                  style={{ fontSize: "16px" }}
                ></i>{" "}
                Add items
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col mb-3">
              <label
                className="form-label"
                htmlFor="exampleFormControlTextarea1"
              >
                Invoice note
              </label>
              <textarea
                id="exampleFormControlTextarea1"
                className="form-control border border-secondary"
                placeholder=""
                style={{ height: "90px" }}
              ></textarea>
              <span className="form-text">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setRminder(!reminder)}
                >
                  Set reminder{" "}
                  <i
                    className="bi bi-bell fw-bold"
                    style={{ fontSize: "16px" }}
                  ></i>{" "}
                </button>
              </span>
            </div>
          </div>

          {reminder && (
            <Fragment>
              <div className="row mb-3">
                <div className="col-6 col-lg-6 col-md-6">
                  <label className="form-label" htmlFor="issue_date">
                    Before due date
                  </label>
                  <input
                    type="date"
                    name="issue_date"
                    id="issue_date"
                    placeholder="Enter date"
                    className={`js-daterangepicker form-control daterangepicker-custom-input border border-secondary`}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6 col-lg-6 col-md-6">
                  <label className="form-label" htmlFor="issue_date">
                    On due date
                  </label>
                  <input
                    type="date"
                    name="issue_date"
                    id="issue_date"
                    placeholder="Enter date"
                    className={`js-daterangepicker form-control daterangepicker-custom-input border border-secondary`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-lg-6 col-md-6">
                  <label className="form-label" htmlFor="issue_date">
                    After due date
                  </label>
                  <input
                    type="date"
                    name="issue_date"
                    id="issue_date"
                    placeholder="Enter date"
                    className={`js-daterangepicker form-control daterangepicker-custom-input border border-secondary`}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-auto mb-2">
                <button type="button" className="btn kyc-save-btn">
                  Cancel
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-primary">
                  Save
                </button>
              </div>
            </Fragment>
          )}
        </div>

        {/* RIGHT */}
        <div className="col-lg-6 ps-lg-5 ps-md-0 pt-5 mt-5">
          <div className="col d-flex flex-row align-items-baseline justify-content-between bal-sheet py-2">
            <p className="total-text">Subtotal</p>
            <h5 className="total-value">NGN100,000.00</h5>
          </div>

          <div className="row mb-3">
            <div className="col">
              <label className="form-label" htmlFor="firstname">
                Tax %
              </label>
              <input
                type="text"
                className={`form-control border border-secondary`}
                name="firstname"
                id="firstname"
                tabIndex={1}
                placeholder=""
                aria-label="firstname"
                required
              />
              <span className="invalid-feedback">
                Please enter valid first name.
              </span>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <label className="form-label" htmlFor="firstname">
                Discount
              </label>
              <input
                type="text"
                className={`form-control border border-secondary`}
                name="firstname"
                id="firstname"
                tabIndex={1}
                placeholder=""
                aria-label="firstname"
                required
              />
              <span className="invalid-feedback">
                Please enter valid first name.
              </span>
            </div>

            <div className="col d-flex flex-row align-items-end">
              <label className="form-label" htmlFor="firstname">
                {/* Tax % */}
              </label>
              <select
                className={`form-select form-control border border-secondary`}
                id="validationValidSelect1"
                name="business_type"
              >
                <option>Select</option>
              </select>
            </div>

            <hr className="hor-rule" />
            <div className="col d-flex flex-row align-items-center justify-content-between bal-sheet py-2">
              <p className="total-text">Total</p>
              <h5 className="total-value">NGN100,000.00</h5>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end mt-auto mb-2">
          <button type="button" className="btn kyc-save-btn">
            Save draft
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => navigate("/merchant/invoices/review_invoice")}
          >
            Send invoice
          </button>
        </div>
      </div>
    </main>
  );
}

export default CreateInvoice;
