import React from "react";
export interface InputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "className"
  > {
  label?: string;
  suffix?: any;
  prefix?: any;
  className?: string;
  inputClassName?: string;
}
function Input({
  label,
  className = "",
  prefix,
  suffix,
  style,
  inputClassName,
  ...props
}: InputProps): JSX.Element {
  return (
    <div style={style} className={className}>
      {label && (
        <label
          className="form-label text-black text-capitalize"
          htmlFor={props.name}
        >
          {label}
        </label>
      )}
      <div className="input-group input-group-merge">
        {prefix && <div>{prefix}</div>}
        <input
          aria-label={props.name}
          className={`form-control ps-3 border border-secondary ${inputClassName}`}
          {...props}
        />
        {suffix && (
          <div className="input-group-append input-group-text ms-2">
            {suffix}
          </div>
        )}
      </div>
    </div>
  );
}

export default Input;
