const MerchantDetailsHeader = () => {
	return (
		<>
			<div className="profile-cover"></div>
			<div className="text-center mb-5">
				<label
					className="avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar"
					htmlFor="editAvatarUploaderModal"
				>
					<img
						id="editAvatarImgModal"
						className="avatar-img"
						src="/assets/img/160x160/img6.jpg"
						alt="Profile Avatar"
					/>

					<input
						type="file"
						className="js-file-attach avatar-uploader-input"
						id="editAvatarUploaderModal"
						data-hs-file-attach-options='{
                          "textTarget": "#editAvatarImgModal",
                          "mode": "image",
                          "targetAttr": "src",
                          "allowTypes": [".png", ".jpeg", ".jpg"]
                       }'
					/>

					<span className="avatar-uploader-trigger">
						<i className="bi-pencil-fill avatar-uploader-icon shadow-sm"></i>
					</span>
				</label>

				<h1 className="page-header-title">
					Mark Williams
					<i
						className="bi-patch-check-fill fs-2 text-primary"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						title="Top endorsed"
					></i>
				</h1>

				<ul className="list-inline list-px-2">
					<li className="list-inline-item">
						<i className="bi-building me-1"></i>
						<span>Pixeel Ltd.</span>
					</li>

					<li className="list-inline-item">
						<i className="bi-geo-alt me-1"></i>
						<a href="#">London,</a>
						<a href="#">UK</a>
					</li>

					<li className="list-inline-item">
						<i className="bi-calendar-week me-1"></i>
						<span>Joined March 2013</span>
					</li>
				</ul>
			</div>
		</>
	)
}

export default MerchantDetailsHeader