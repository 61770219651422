import React, { useRef, useState } from "react";
import { useGenerateWebhook, useSession } from "../../hooks/auth/auth.hook";
import SpinnerComponent from "../spinner/Spinner";
import { validateUrl } from "../../helpers/index.helper";

function ApiKeyModal() {
  const { refetch } = useSession();
  const { generate_webhook } = useGenerateWebhook();
  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUrl, setIsUrl] = useState<any>(null);
  const [webhook, setWebhook] = useState<string>("");

  const handleSubmit = (payload: { webhook_url: string }) => {
    setIsLoading(true);
    generate_webhook
      .mutateAsync(payload)
      .then(() => {
        setWebhook("");
        buttonRef.current?.click();
        refetch();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        return e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className="modal fade"
      id="createAKIKeyModal"
      tabIndex={-1}
      aria-labelledby="createAKIKeyModalLabel"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="modal-title card-subtitle"
              id="createAKIKeyModalLabel"
            >
              Update Webhook URL{" "}
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row gx-2 gx-sm-3 mt-4">
                <div className="col">
                  <input
                    type="url"
                    id="tooltipExample"
                    className={`form-control ${isUrl ? "is-valid" : ""}`}
                    value={webhook}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setWebhook(e.target.value);
                      setIsUrl(validateUrl(e.target.value));
                    }}
                    placeholder="https://mywebhook.com/listen"
                  />
                  {isUrl === false && (
                    <span className="invalid-feedback">
                      Please enter a valid url.
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div className="row align-items-sm-center flex-grow-1 mx-n2 justify-content-end">
              <div className="col-sm-auto">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={buttonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleSubmit({ webhook_url: webhook })}
                  >
                    Update
                    {isLoading && (
                      <>
                        {" "}
                        &nbsp;
                        <SpinnerComponent size="sm" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiKeyModal;
