import React from "react";
export interface InputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    "className"
  > {
  label?: string;
  suffix?: any;
  prefix?: any;
  inputClassName?: string;
  className?: string;
}
function TextArea({
  label,
  className = "",
  inputClassName,
  ...props
}: InputProps): JSX.Element {
  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={props.name}>
          {label}
        </label>
      )}
      <div className="tom-select-custom">
        <textarea
          id={props.name}
          className={`form-control ${inputClassName}`}
          style={{ minHeight: 85 }}
          {...props}
        ></textarea>
      </div>
    </div>
  );
}

export default TextArea;
