import CurrencyInput from "react-currency-input-field";
import { currencyInputProps } from "../../utils/interfaces";

const CurrencyInputField = ({
  id,
  name,
  placeholder,
  defaultValue,
  value,
  onAmountChange,
  className
}: currencyInputProps) => {
  return (
    <CurrencyInput
      id={id}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      decimalsLimit={2}
      value={value}
      className={className}
      allowNegativeValue={false}
      onValueChange={(value: any) => onAmountChange(value)}
    />
  );
};

export default CurrencyInputField;
