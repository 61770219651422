import React, { useState } from "react";
import BackBtn from "../../component/back-btn";

function ReviewInvoice(): JSX.Element {
  const [oneTime, setOneTime] = useState<boolean>(false);
  const [partTime, setPartTime] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("");

  return (
    <main>
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">
              <BackBtn />
              Review Invoice
            </h1>{" "}
          </div>
        </div>
      </div>

      <div className="row mt-4 pt-4">
        <div className="col-lg-6 mb-3 mb-lg-0 gap-5">
          {/* TOP */}
          <div className="col-lg-12 col-md-12 mb-5 pb-5 mb-lg-0 item-container px-4">
            <div className="row">
              <div className="col">
                <h5 className="">Payment options</h5>
                <div className="d-flex gap-5">
                  <div className="form-check">
                    <input
                      className={`form-check-input border border-secondary`}
                      type="radio"
                      id="emailChannel"
                      name="emailChannel"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setOneTime(e.target.checked)
                      }
                    />
                    <label className={`form-label`} htmlFor="termsCheckbox">
                      One time payment
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className={`form-check-input border border-secondary`}
                      type="radio"
                      id="whatsappChannel"
                      name="whatsappChannel"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPartTime(e.target.checked)
                      }
                    />
                    <label className={`form-label`} htmlFor="termsCheckbox">
                      Partial payment
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {partTime && (
              <div className="col-lg-12 w-100 pt-4">
                <label className="form-label" htmlFor="firstname">
                  Select payment type
                </label>
                <select
                  className={`form-select form-control border border-secondary`}
                  id="validationValidSelect1"
                  name="business_type"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setPaymentType(e.target.value)
                  }
                >
                  <option>Select</option>
                  <option value={"flat"}>Flat</option>
                  <option value={"percentage"}>Percentage</option>
                  <option value={"customer"}>Determined by Customer</option>
                </select>
              </div>
            )}
            {paymentType === "flat" || paymentType === "percentage" ? (
              <>
                <div className="col-lg-12 w-100 pt-4">
                  <div className="col">
                    <label className="form-label" htmlFor="firstname">
                      Number of payments
                    </label>
                    <input
                      type="text"
                      className={`form-control border border-secondary`}
                      name="firstname"
                      id="firstname"
                      tabIndex={1}
                      placeholder=""
                      aria-label="firstname"
                      required
                    />
                    <span className="invalid-feedback">
                      Please enter valid first name.
                    </span>
                  </div>
                </div>

                <div className="col-lg-12 w-100 pt-4">
                  <div className="row">
                    <div className="col">
                      <label className="form-label" htmlFor="firstname">
                        First payment
                      </label>
                      <input
                        type="text"
                        className={`form-control border border-secondary`}
                        name="firstname"
                        id="firstname"
                        tabIndex={1}
                        placeholder=""
                        aria-label="firstname"
                        required
                      />
                      <span className="invalid-feedback">
                        Please enter valid first name.
                      </span>
                    </div>
                    <div className="col">
                      <label className="form-label" htmlFor="firstname">
                        Second payment
                      </label>
                      <input
                        type="text"
                        className={`form-control border border-secondary`}
                        name="firstname"
                        id="firstname"
                        tabIndex={1}
                        placeholder="Enter first name"
                        aria-label="firstname"
                        required
                      />
                      <span className="invalid-feedback">
                        Please enter valid first name.
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {paymentType === "customer" && (
              <>
                <div className="col-lg-12 w-100 pt-4">
                  <div className="col">
                    <label className="form-label" htmlFor="firstname">
                      Number of payments
                    </label>
                    <input
                      type="text"
                      className={`form-control border border-secondary`}
                      name="firstname"
                      id="firstname"
                      tabIndex={1}
                      placeholder=""
                      aria-label="firstname"
                      required
                    />
                    <span className="invalid-feedback">
                      Please enter valid first name.
                    </span>
                  </div>
                </div>

                <div className="col-lg-12 w-100 pt-4">
                  <div className="col">
                    <label className="form-label" htmlFor="firstname">
                      Minimum Amount
                    </label>
                    <input
                      type="text"
                      className={`form-control border border-secondary`}
                      name="firstname"
                      id="firstname"
                      tabIndex={1}
                      placeholder=""
                      aria-label="firstname"
                      required
                    />
                    <span className="invalid-feedback">
                      Please enter valid first name.
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* BOTTOM */}
          <div className="col-lg-12 col-md-12 item-container px-4 mt-5 pt-5">
            <h5 className="pb-4 pt-5 mt-3">Customer details</h5>
            <div className="row mb-4">
              <div className="col">
                <span className="d-block trx-key">Customer name</span>
              </div>

              <div className="col-auto">
                <h5 className="">Olusegun Adeniyi</h5>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <span className="d-block trx-key">Customer email</span>
              </div>

              <div className="col-auto">
                <h5 className="">olusegun@yup.com</h5>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <span className="d-block trx-key">Invoice ID</span>
              </div>

              <div className="col-auto">
                <h5 className="">00001</h5>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <span className="d-block trx-key">Due date</span>
              </div>

              <div className="col-auto">
                <h5 className="">10-10-2023</h5>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <span className="d-block trx-key">Invoice note</span>
              </div>

              <div className="col-auto">
                <h5 className="">Thank you for doing business with us!</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-3 mb-lg-0 px-4 pt-5 pb-5 item-container">
          <h5 className="pb-4">Item details</h5>
          <div className="row mb-4">
            <div className="col">
              <span className="d-block trx-key">New Jeans</span>
            </div>

            <div className="col-auto">
              <h5 className="">Olusegun Adeniyi</h5>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <span className="d-block trx-key">White round neck shirt</span>
            </div>

            <div className="col-auto">
              <h5 className="">olusegun@yup.com</h5>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <span className="d-block trx-key">Red cap</span>
            </div>

            <div className="col-auto">
              <h5 className="">00001</h5>
            </div>
          </div>
          <hr className="hor-rule-success" />
          <div className="row mb-4">
            <div className="col">
              <span className="d-block trx-key">Subtotal</span>
            </div>

            <div className="col-auto">
              <h5 className="total-value">NGN77,000</h5>
            </div>
          </div>
          <hr className="hor-rule-success" />
          <div className="row mb-4">
            <div className="col">
              <span className="d-block trx-key">White round neck shirt</span>
            </div>

            <div className="col-auto">
              <h5 className="">olusegun@yup.com</h5>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <span className="d-block trx-key">Red cap</span>
            </div>

            <div className="col-auto">
              <h5 className="">00001</h5>
            </div>
          </div>
          <hr className="hor-rule-success" />
          <div className="row mb-4">
            <div className="col">
              <span className="d-block trx-key">Subtotal</span>
            </div>

            <div className="col-auto">
              <h5 className="total-value">NGN77,000</h5>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end mt-auto mb-2 pt-5">
            <button type="button" className="btn btn-primary">
              Send invoice
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ReviewInvoice;
