import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../../assets/checksucc.svg";
import FilterModal from "../../../component/modal/filterModal";
import {
  filterEmptyValues,
  isFieldArrayValid,
  validateEmail,
  validateText,
} from "../../../helpers/index.helper";
import { useCreateAccount } from "../../../hooks/accounts/account.hook";
import useUserDeviceInfo from "../../../hooks/device.hook";
import { ACCOUNT_TYPE } from "../../../utils/data";
import Spinner from "../../auth/spinner";

function CreateVirtualAccount(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { createAccount } = useCreateAccount();
  const { deviceInfo } = useUserDeviceInfo();
  const [metaData, setMetadata] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [track, setTrack] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    account_type: "",
    // account_name: "",
    first_name: "",
    last_name: "",
    business_name: "",
    display_name: "",
    email: "",
    bvn: "",
    mobile_number: "",
  });
  const [error, setError] = useState({
    display_name: false,
    email: false,
    bvn: false,
    mobile_number: false,
    account_name: false,
  });
  const [fieldPairs, setFieldPairs] = useState<any>([
    { fieldName: "", fieldValue: "" },
  ]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEventHandler<HTMLSelectElement>
      | any
  ) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // if (name === "email" && validateEmail(value) === false) {
    //   setError((prev: any) => ({ ...prev, email: true }));
    // }
    if (name === "bvn" && value.length < 11) {
      setError((prev: any) => ({ ...prev, bvn: false }));
    }

    if (name === "account_name" && validateText(value)) {
      setError((prev: any) => ({ ...prev, account_name: false }));
    }

    if (name === "mobile_number" && value === "") {
      setError((prev: any) => ({ ...prev, mobile_number: false }));
    }
  };

  const handleSubmit = async (formData: any) => {
    const removeEmptyValues = filterEmptyValues(formData);
    let payload: any = {
      ...removeEmptyValues,
      account_name: formData?.display_name,
    };
    const validateFieldPairs = isFieldArrayValid(fieldPairs);
    if (validateFieldPairs === true) {
      payload.metadata = { metadata: fieldPairs };
    }
    setLoading(true);
    try {
      const response = await createAccount.mutateAsync(payload);
      if (response.status === 200) {
        setShow(true);
        moengage.track_event("CREATE_VIRTUAL_ACCOUNT", {
          success_status: true,
          ...payload,
          platform: deviceInfo?.userAgent,
        });
        queryClient.refetchQueries();
        setLoading(false);
        setTrack(true);
        setData(response?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const validateBeforeSubmit = () => {
    let isValid = true;
    if (
      formData.email.trim() === "" ||
      validateEmail(formData.email) === false
    ) {
      setError((prev) => ({ ...prev, email: true }));
      isValid = false;
    }
    if (formData.bvn === "" || formData.bvn.length < 11) {
      setError((prev) => ({ ...prev, bvn: true }));
      isValid = false;
    }
    if (formData?.display_name === "") {
      setError((prev) => ({ ...prev, display_name: true }));
      isValid = false;
    }

    // if (formData?.account_name === "") {
    //   setError((prev) => ({ ...prev, account_name: true }));
    //   isValid = false;
    // }

    if (
      formData?.mobile_number === "" ||
      formData?.mobile_number?.length < 11 ||
      formData?.mobile_number?.length > 15
    ) {
      setError((prev) => ({ ...prev, mobile_number: true }));
      isValid = false;
    }

    if (
      isValid &&
      validateEmail(formData.email) === true &&
      formData?.display_name !== "" &&
      (formData?.mobile_number?.length === 11 ||
        formData?.mobile_number?.length <= 15) &&
      formData.bvn.length === 11
    ) {
      handleSubmit(formData);
    }
  };

  const handleFieldChange = (index: any, field: any, value: any) => {
    const updatedPairs: any = [...fieldPairs];
    updatedPairs[index] = { ...updatedPairs[index], [field]: value };
    setFieldPairs(updatedPairs);
  };

  const addNewPair = () => {
    setFieldPairs([...fieldPairs, { fieldName: "", fieldValue: "" }]);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <FilterModal show={show} handleClose={handleClose}>
        <div className="d-flex flex-column mx-auto text-center">
          <h5 className="text-center mx-auto">Account created successfully</h5>

          <div className="mt-3 text-center mx-auto">
            <SuccessIcon className="text-center mx-auto" />
          </div>

          <p className="mt-3 pb-2 text-center mx-auto">
            You have created a virtual account
          </p>

          <button
            className="btn btn-primary btn-sm"
            onClick={() => navigate("/merchant/accounts")}
            style={{ cursor: "pointer" }}
          >
            Done
          </button>
        </div>
      </FilterModal>
      {loading && (
        <div className="overlay-effect">
          <div className="overlay-child">
            <Spinner />
          </div>
        </div>
      )}
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-baseline">
          <i
            className="bi bi-chevron-left text-dark"
            style={{ fontSize: "14px" }}
            onClick={() => navigate("/merchant/accounts")}
          ></i>
          <h1 className="create-virtual-acc text-center mx-auto">
            Create account
          </h1>
        </div>

        {/* VIRTUL ACCOUNTS FORM */}
        <form onSubmit={(e) => e.preventDefault()} className="mt-4">
          <div className="row">
            <div className="col-12 col-md-6 mb-2 mb-md-3">
              <label className="form-label card-subtitle" htmlFor="firstname">
                Account Name
              </label>
              <input
                type="text"
                className={`form-control mb-3  ${
                  error.display_name === true
                    ? "border border-danger "
                    : "border border-secondary"
                } ${
                  error.display_name === false && formData?.display_name !== ""
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                name="display_name"
                id="display_name"
                value={formData?.display_name}
                tabIndex={1}
                placeholder="Anita Berry"
                aria-label="display name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>

            <div className="col-12 col-md-6 mb-2 mb-md-3">
              <label className="form-label card-subtitle" htmlFor="firstname">
                Account Type
              </label>
              <select
                className={`form-select mb-3 text-uppercase ${
                  formData.account_type !== ""
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                id="validationValidSelect1"
                name="account_type"
                onChange={handleChange}
                defaultValue={formData?.account_type}
              >
                <option>Select</option>
                {ACCOUNT_TYPE.map((i: string) => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 mb-2 mb-md-3">
              <label className="form-label card-subtitle" htmlFor="firstname">
                First Name
              </label>
              <input
                type="text"
                className={`form-control mb-3 ${
                  formData?.first_name !== ""
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                name="first_name"
                id="first_name"
                value={formData?.first_name}
                tabIndex={1}
                placeholder="Anita"
                aria-label="first name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-2 mb-md-3">
              <label className="form-label card-subtitle" htmlFor="firstname">
                Last Name
              </label>
              <input
                type="text"
                className={`form-control mb-3 ${
                  formData?.last_name !== ""
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                name="last_name"
                id="last_name"
                value={formData?.last_name}
                tabIndex={1}
                placeholder="Berry"
                aria-label="last name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>
          </div>

          {formData?.account_type === "individual" ? (
            <div className="row">
              <div className="col-12 col-md-12 mb-2 mb-md-3">
                <label className="form-label card-subtitle" htmlFor="email">
                  Email
                </label>
                <input
                  type="text"
                  className={`form-control mb-3 ${
                    error.email === true
                      ? " border border-danger"
                      : "border border-secondary"
                  } ${
                    error.email === false &&
                    formData.email !== "" &&
                    validateEmail(formData.email) === true
                      ? " border border-secondary"
                      : "border border-secondary"
                  }`}
                  placeholder="anita@pooler.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 col-md-6 mb-2 mb-md-3">
                <label className="form-label card-subtitle" htmlFor="firstname">
                  BusIness Name
                </label>
                <input
                  type="text"
                  className={`form-control mb-3 ${
                    formData?.business_name !== ""
                      ? "border border-secondary "
                      : "border border-secondary"
                  }`}
                  name="business_name"
                  id="business_name"
                  value={formData?.business_name}
                  tabIndex={1}
                  placeholder="Pooler"
                  aria-label="first name"
                  onChange={handleChange}
                  autoComplete="off"
                  required
                />
              </div>

              <div className="col mb-4">
                <label className="form-label card-subtitle" htmlFor="email">
                  Email
                </label>
                <input
                  type="text"
                  className={`form-control mb-3 ${
                    error.email === true
                      ? " border border-danger"
                      : "border border-secondary"
                  } ${
                    error.email === false &&
                    formData.email !== "" &&
                    validateEmail(formData.email) === true
                      ? " border border-secondary"
                      : "border border-secondary"
                  }`}
                  placeholder="anita@pooler.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 col-md-6 mb-2 mb-md-3">
              <label className="form-label card-subtitle" htmlFor="bvn">
                BVN
              </label>
              <input
                type="text"
                className={`form-control mb-3 ${
                  error.bvn === true
                    ? " border border-danger"
                    : "border border-secondary"
                } ${
                  error.bvn === false &&
                  formData.bvn !== "" &&
                  formData.bvn.length === 11
                    ? " border border-secondary"
                    : "border border-secondary"
                }`}
                placeholder="BVN"
                name="bvn"
                value={formData.bvn}
                onChange={handleChange}
                maxLength={11}
              />
            </div>
            {/* PHONE NUMBER */}
            <div className="col-12 col-md-6 mb-2 mb-md-3">
              <label
                className="form-label card-subtitle"
                htmlFor="mobile_number"
              >
                Phone Number
              </label>
              <PhoneInput
                country={"ng"}
                value={formData.mobile_number}
                onChange={(phoneNumber: any) =>
                  handleChange({
                    target: { name: "mobile_number", value: phoneNumber },
                  })
                }
                inputClass={`form-control form-control-lg mb-3 w-100 ${
                  formData.mobile_number !== ""
                    ? " border border-secondary"
                    : "border border-secondary"
                }`}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-sm-9 mb-3 card-subtitle"
              onClick={() => {
                setMetadata(!metaData);
                //   setScrollable(!scrollable);
              }}
            >
              <span
                className="js-create-field form-link link link-primary"
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <i className="bi-plus-lg me-1 "></i>&nbsp;Additional Information
              </span>
            </div>
          </div>

          {metaData && (
            <div className="mb-3 pb-3">
              <span className="divider-start mb-4">Additional Information</span>
              <div>
                {fieldPairs.map((pair: any, index: any) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-12 col-md-6 mb-2 mb-md-3">
                        <input
                          type="text"
                          placeholder="Field Name"
                          className={`form-control mb-3 ${
                            pair.fieldName !== ""
                              ? " border-border-secondary"
                              : "border border-secondary"
                          }`}
                          value={pair.fieldName}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "fieldName",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="col-12 col-md-6 mb-2 mb-md-3">
                        <input
                          type="text"
                          placeholder="Field Value"
                          className={`form-control mb-3 ${
                            pair.fieldValue !== ""
                              ? " border-border-secondary"
                              : "border border-secondary"
                          }`}
                          value={pair.fieldValue}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "fieldValue",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {fieldPairs.length > 1 ? (
                <div className="row">
                  <div className="col">
                    <span
                      className="js-create-field form-link link link-primary"
                      onClick={() => {
                        addNewPair();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-plus-lg"></i>&nbsp;&nbsp; Add
                    </span>
                  </div>
                  <div className="col">
                    <span
                      className="js-create-field form-link link link-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (fieldPairs.length > 1) {
                          let data = [...fieldPairs];
                          data.pop();
                          setFieldPairs([...data]);
                        }
                      }}
                    >
                      <i className="bi bi-dash-lg"></i>&nbsp;&nbsp; Remove
                    </span>
                  </div>
                </div>
              ) : (
                <div className="col text-center mx-auto">
                  <button
                    type="button"
                    className="mx-auto btn-sm text-primary adddd"
                    onClick={() => {
                      addNewPair();
                    }}
                  >
                    <i className="bi bi-plus-lg"></i>&nbsp; Add
                  </button>
                </div>
              )}
            </div>
          )}

          <div className="d-flex mx-auto">
            <button
              onClick={() => validateBeforeSubmit()}
              type="button"
              className="btn btn-primary w-100 w-md-50 mx-auto text-center"
            >
              Create account
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateVirtualAccount;
