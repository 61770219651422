const MerchantContent = () => {
	return (
		<div className="card">
			<div className="card-header card-header-content-md-between">
				<div className="mb-2 mb-md-0">
					<form>
						<div className="input-group input-group-merge input-group-flush">
							<div className="input-group-prepend input-group-text">
								<i className="bi-search"></i>
							</div>
							<input
								id="datatableSearch"
								type="search"
								className="form-control"
								placeholder="Search users"
								aria-label="Search users"
							/>
						</div>
					</form>
				</div>

				<div className="d-grid d-sm-flex gap-2">
					<div className="dropdown">
						<button
							type="button"
							className="btn btn-white btn-sm dropdown-toggle w-100"
							aria-expanded="false"
							aria-labelledby="usersFilterDropdown"
							data-bs-toggle="dropdown"
							data-bs-auto-close="outside"
						>
							<i className="bi-download me-2"></i> Export
						</button>
						<div
							className="dropdown-menu dropdown-menu-sm-end dropdown-card card-dropdown-filter-centered"
							aria-labelledby="usersFilterDropdown"
							style={{ minWidth: "22rem" }}
						>
							<div className="card">
								<div className="card-header card-header-content-between">
									<h5 className="card-header-title">Filter users</h5>

									<button
										type="button"
										className="btn btn-ghost-secondary btn-icon btn-sm ms-2"
									>
										<i className="bi-x-lg"></i>
									</button>
								</div>

								<div className="card-body">
									<form>
										<div className="row">
											<div className="col-sm mb-4">
												<small className="text-cap text-body">Start date</small>

												<div className="tom-select-custom">
													<input
														type="text"
														className="js-daterangepicker form-control daterangepicker-custom-input"
													/>
												</div>
											</div>

											<div className="col-sm mb-4">
												<small className="text-cap text-body">End date</small>

												<div className="tom-select-custom">
													<input
														type="text"
														className="js-daterangepicker form-control daterangepicker-custom-input"
													/>
												</div>
											</div>

										</div>

										<div className="d-grid">
											<a className="btn btn-primary" href="javascript:;">Generate</a>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="dropdown">
						<button
							type="button"
							className="btn btn-white btn-sm w-100"
							aria-expanded="false"
						>
							<i className="bi bi-arrow-clockwise me-2"></i> Refresh
						</button>
					</div>


				</div>
			</div>

			<div className="table-responsive datatable-custom">
				<table
					id="datatable"
					className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
					style={{ width: "100%" }}
				>
					<thead className="thead-light">
						<tr>
							<th>Reference</th>
							<th>Account</th>
							<th>Amount</th>
							<th>Status</th>
							<th>Transaction Date</th>
							<th>Settlement</th>
							<th>Action</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>THFH_JJJ</td>
							<td>5667567667</td>
							<td>NGN 5,000</td>
							<td>
								<span className="badge bg-soft-success text-success">
									<span className="legend-indicator bg-success"></span>completed
								</span>
							</td>

							<td>Aug 17, 2020, 5:48 (ET)</td>
							<td>
								<span className="badge bg-soft-success text-success">
									<span className="legend-indicator bg-success"></span>success
								</span>
							</td>
							<td>
								<div className="btn-group" role="group">
									<a className="btn btn-white btn-sm" href="/admin/merchant/details/1">
										<i className="bi-eye"></i> View
									</a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className="card-footer">
				<div
					className="row justify-content-center justify-content-sm-between align-items-sm-center"
				>
					<div className="col-sm mb-2 mb-sm-0">
						<div
							className="d-flex justify-content-center justify-content-sm-start align-items-center"
						>
							<span className="me-2">Showing:</span>

							<div className="tom-select-custom">
								<select
									id="datatableEntries"
									className="js-select form-select form-select-borderless w-auto"
									autoComplete="off"
									data-hs-tom-select-options='{
                            "searchInDropdown": false,
                            "hideSearch": true
                          }'
								>
									<option value="12" selected>12</option>
									<option value="14">14</option>
									<option value="16">16</option>
									<option value="18">18</option>
								</select>
							</div>

							<span className="text-secondary me-2">of</span>

							<span id="datatableWithPaginationInfoTotalQty"></span>
						</div>
					</div>

					<div className="col-sm-auto">
						<div
							className="d-flex justify-content-center justify-content-sm-end"
						>
							<nav
								id="datatablePagination"
								aria-label="Activity pagination"
							></nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MerchantContent