import moengage from "@moengage/web-sdk";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SuccessCheckIcon } from "../../assets/svg/illustrations/success-check-icon.svg";
import Stepper from "../../component/Stepper";
import BackBtn from "../../component/back-btn";
import { Button } from "../../component/button";
import CustomizeLink from "../../component/payment-link/create-payment-link/customize-link";
import PaymentDetailsForm from "../../component/payment-link/create-payment-link/payment-details";
import SpinnerComponent from "../../component/spinner/Spinner";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  useCreatePayLink,
  useSavePayLinkAsTemplate,
} from "../../hooks/payment-link/index.hook";
import useCopyToClipboard from "../../hooks/useCopy.hook";
import { Currency } from "../../utils/enums";

export type ExtraFieldsProps = {
  type: string;
  dropdown?: Array<string>;
};

export interface IPaymentLink {
  note?: string;
  amount?: number | string;
  currency?: keyof typeof Currency;
  charge_bearer?: string;
  vat?: string | number | any;
  interval?: string;
  link?: string;
  success_message?: string;
  failure_message?: string;
  redirect_link?: string;
  trx_notification_email?: string;
  payment_link_name?: string;
  is_recurring?: boolean;
  extra_fields: any;
  // initial_amount?: number | string;
}

const initialData: IPaymentLink = {
  payment_link_name: "",
  note: "",
  amount: "",
  currency: "NGN",
  charge_bearer: "",
  vat: "",
  success_message: "",
  failure_message: "",
  trx_notification_email: "",
  is_recurring: false,
  extra_fields: {},
};
const steps = [
  {
    title: "Payment details",
    description: "Let us know the details of your payment page.",
  },
  {
    title: "Advanced options (optional)",
    description: "Customize your page for preferred experience.",
  },
];
function CreatePaymentLink({ recurring = false }: { recurring?: boolean }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const { create_link } = useCreatePayLink();
  const { save_template } = useSavePayLinkAsTemplate();
  const { deviceInfo } = useUserDeviceInfo();

  const isEdit = !!state;
  const [formData, setFormData] = useState<IPaymentLink>(
    isEdit
      ? {
          payment_link_name: state?.payment_link_name,
          note: state?.note,
          currency: "NGN",
          charge_bearer: state?.charge_bearer,
          vat: state?.vat,
          success_message: state?.success_message,
          failure_message: state?.failure_message,
          trx_notification_email: state?.trx_notification,
          is_recurring: false,
          extra_fields: state?.extra_fields,
        }
      : initialData
  );

  const handleChildCollectionUpdate = (childCollection: any) => {
    setFormData((prev) => ({
      ...prev,
      extra_fields: { extra_fields: childCollection },
    }));
  };

  const handleEditChange = (
    field: keyof typeof formData,
    value: string | number | boolean | any
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const [curStep, setCurStep] = useState(1);
  const [showModal, setshowModal] = useState(false);
  const [url, setUrl] = useState<string | any>("");
  const [linkId, setLinkId] = useState<string | any>("");
  const [preview, setPreview] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);

  const { isCopied, copyToClipboard } = useCopyToClipboard();

  const validateBeforeSubmit = (payload: IPaymentLink) => {
    if (
      payload.payment_link_name !== "" &&
      payload.note !== "" &&
      payload.charge_bearer !== ""
    ) {
      setCurStep((prev) => prev + 1);
    }
  };

  const handleSubmit = async (data: IPaymentLink) => {
    // const amount: any = isEdit && data.amount !== "" ? data.amount : "";
    const amount: any = data.amount;
    setLoading(true);
    let payload: any = {
      payment_link_name: data.payment_link_name,
      note: data.note,
      charge_bearer: data.charge_bearer,
      currency: "NGN",
      is_recurring: data.is_recurring,
    };
    if (data.amount !== "") {
      payload.amount = parseFloat(amount?.replace(/,/g, ""));
    }
    if (data.trx_notification_email !== "") {
      payload.trx_notification_email = data.trx_notification_email;
    }
    if (
      data.extra_fields.type !== "" ||
      Array.isArray(data.extra_fields) === false
    ) {
      payload.extra_fields = data.extra_fields;
    }
    if (data.success_message !== "") {
      payload.success_message = data.success_message;
    }
    if (data.failure_message !== "") {
      payload.failure_message = data.failure_message;
    }
    if (data.redirect_link !== "") {
      payload.redirect_link =
        data?.redirect_link?.includes("https://") ||
        data?.redirect_link?.includes("http://")
          ? data.redirect_link
          : `https://${data.redirect_link}`;
    }
    if (data.vat !== "") {
      payload.vat = parseFloat(data?.vat);
    }
    // if (isEdit && data.amount === "") {
    //   payload.initial_amount = state.amount;
    // }

    try {
      const response = await create_link.mutateAsync(payload);
      if (response?.status === 200) {
        moengage.track_event("CREATE_PAYMENT_LINK", {
          ...payload,
          success_status: true,
          platform: deviceInfo?.userAgent,
        });
        setLoading(false);
        queryClient.refetchQueries();
        setPreview(response?.data?.data);
        setUrl(response?.data?.data?.link);
        setLinkId(response?.data?.data?.id);
        setshowModal(true);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const onSave = async () => {
    try {
      const response: any = save_template.mutateAsync(linkId);
      if (response.status === 200) {
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="container-fluid p-0 overflow-hidden">
      <section style={{ minHeight: "90vh" }} className="row w-100">
        <div
          className="col-lg-4 p-4 d-none d-lg-block"
          style={{ background: "#FAFAFD" }}
        >
          <div className="ms-auto my-5" style={{ maxWidth: 320 }}>
            <Stepper
              steps={steps}
              orientation="vertical"
              activeStep={curStep}
            />
          </div>
        </div>
        <div className="col-lg-8 p-4 d-flex flex-column gap-3 overflow-auto ">
          <div className="page-header border-bottom-0 mb-0">
            <h1 className="page-header-title">
              <BackBtn />
              New payment link
            </h1>{" "}
          </div>
          <div
            style={{ maxWidth: 550 }}
            className={`mx-auto flex-grow-1 w-100 d-flex flex-column ${
              curStep === 2 ? "" : "justify-content-between"
            }`}
          >
            <div className="w-100 mb-10">
              {curStep >= 1 && (
                <div className={curStep === 1 ? "d-block" : "d-none"}>
                  <PaymentDetailsForm
                    recurring={recurring}
                    formData={formData}
                    handleEditChange={handleEditChange}
                    onCollectionUpdate={handleChildCollectionUpdate}
                  />
                </div>
              )}
              {curStep >= 2 && (
                <div className={curStep === 2 ? "d-block" : "d-none"}>
                  <CustomizeLink
                    formData={formData}
                    handleEditChange={handleEditChange}
                  />
                </div>
              )}
            </div>
            <div
              className={`hstack ${
                curStep === 2 ? "" : "mt-auto"
              } justify-content-end gap-3`}
            >
              {curStep === 2 && (
                <Button
                  onClick={() => setCurStep((prev) => Math.max(prev - 1, 1))}
                  outlined
                >
                  <i className="bi bi-arrow-left"></i> Back
                </Button>
              )}

              {curStep === 1 && (
                <Button onClick={() => validateBeforeSubmit(formData)}>
                  Continue
                </Button>
              )}
              {curStep >= 2 && (
                <Button
                  type="button"
                  onClick={() =>
                    handleSubmit({
                      ...formData,
                    })
                  }
                >
                  Create Link{" "}
                  {isLoading && (
                    <>
                      &nbsp;{" "}
                      <span className="align-middle">
                        <SpinnerComponent size="sm" />{" "}
                      </span>
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showModal}
        onHide={() => {
          setshowModal(false);
          setFormData({ ...formData, ...initialData });
          setCurStep(1);
          navigate("/merchant/business/pay-link");
        }}
        size="sm"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            className="mx-auto text-center d-grid gap-3"
            style={{ width: "auto", maxWidth: 408 }}
          >
            <SuccessCheckIcon className="mx-auto" />
            <p className="fw-semibold m-0">
              Your payment link has been created.
            </p>
            {/* <Input style={{ flexGrow: 2 }} value={url} readOnly /> */}
            <div className="input-group input-group-sm input-group-merge table-input-group">
              <input
                id="referralsKeyCode1"
                type="text"
                className="form-control"
                readOnly
                value={url}
              />

              <button className="js-clipboard input-group-append input-group-text position-relative">
                <i
                  id="referralsKeyCodeIcon1"
                  className={
                    isCopied ? "bi bi-check2-all text-success" : "bi-clipboard"
                  }
                  onClick={() => {
                    copyToClipboard(url);
                  }}
                ></i>
              </button>
            </div>
            {/* <div className="row">
              <div className="col-4">
                <Button onClick={() => copyToClipboard(url)}>
                  {isCopied ? "Copied" : "Copy Link"}
                </Button>
              </div>
              <div className="col-4">
                <Button outlined>
                  <i className="bi bi-eye me-2"></i>
                  Preview
                </Button>
              </div>
              <div className="col-4">
                <Button outlined onClick={onSave}>
                  Save as template
                </Button>
              </div>
            </div> */}
            <div className="d-flex flex-row justify-content-center mt-3 gap-3">
              {/* <button
                className="btn btn-primary"
                type="button"
                onClick={() =>
                  navigate("/payment-link/preview", {
                    state: { ...preview },
                  })
                }
              >
                <i className="bi bi-eye me-2"></i>
                Preview
              </button> */}
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={onSave}
              >
                Save as template
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreatePaymentLink;
