import React from "react";
import FailureImage from "../../assets/failure.svg";
import { useParams } from "react-router-dom";

function Failure(): JSX.Element {
  const params = useParams();

  return (
    <main
      style={{ background: "#FAFAFD" }}
      id="content"
      role="main"
      className="main position-relative"
    >
      <div className="container d-flex justify-content-center align-items-center vh-100 py-5 py-sm-7">
        <div className="mx-auto" style={{ maxWidth: "24rem", width: "24rem" }}>
          <div
            className="card hard-shadow mb-3 position-relative"
            style={{ height: "6rem" }}
          >
            <div
              className="card hard-shadow mb-3 position-relative"
              style={{ height: "auto" }}
            >
              <div className="mb-5">
                <img
                  src={FailureImage}
                  alt="success_image"
                  className="position-absolute"
                  style={{ top: "-25%", left: "37%" }}
                />
              </div>

              <div className="mx-auto mt-5 pt-3">
                <h5 className="link link-dark">
                  {params?.currency_code} {params?.amount}
                </h5>
              </div>

              <div className="mb-4 mt-4 w-75 text-center mx-auto">
                {params?.failure_message ? (
                  <p className="link link-dark">{params.failure_message}</p>
                ) : (
                  <p className="link link-dark">
                    {params.message} failed to {params.merchant}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Failure;
