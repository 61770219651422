import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTwoFactor } from "../../hooks/auth/auth.hook";
import { PATH_NAMES } from "../../constants/index.constant";
import SpinnerComponent from "../../component/spinner/Spinner";
import { decode_jwt } from "../../utils/decode";
import { IJWT } from "../../utils/interfaces";
import AuthIllustration from "../../assets/svg/logos/Pooler-sign-up-image.png";
import { ROLES } from "../../utils/enums";
import { useQueryClient } from "react-query";
import PoolerLogo from "../../assets/svg/logos/poolerdarklogo.svg";
import PoolerIpad from "../../assets/svg/logos/ipad-pooler.svg";
import PoolerLogoWhite from "../../assets/svg/logos/Pooler_logo_white.svg";
import OtpFields from "../../component/otp/OtpFields";
import Spinner from "./spinner";

function Otp(): JSX.Element {
  const location: any = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const submitRef: any = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copy, setCopied] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const QR_CODE: string | any = location?.state?.QR_CODE;
  const account_no: string | any = location?.state?.email;
  const password: string | any = location?.state?.password;
  const authSecret: string | any = location?.state?.authSecret;

  const queryParams = new URLSearchParams(location?.search);
  const actionType: string | any = queryParams.get("action");
  const { verify_otp } = useTwoFactor(actionType);

  const handleCopy = () => {
    setCopied(true);
    const copyTimeout = setTimeout(() => {
      setCopied(false);
    }, 5000);
    if (copy === false) {
      clearTimeout(copyTimeout);
    }
  };

  useEffect(() => {
    const handleBrowserBack = (event: any) => {
      event.preventDefault();
      const prevPath: string | any = location?.state?.pathname;
      if (prevPath.includes("merchant")) {
        navigate("/auth/merchant/signin");
      } else if (prevPath.includes("cashier")) {
        navigate("/auth/cashier/signin");
      }
    };
    window.addEventListener("popstate", handleBrowserBack);
    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [location?.state?.pathname, navigate]);

  const Verify = async (otpStrings: any) => {
    setReadOnly(true);
    setIsLoading(true);
    let payload: any = {};
    let url: string = `/auth${PATH_NAMES.signin}`;
    if (actionType === "twofactor") {
      payload.email = account_no;
      payload.password = password;
      payload.auth_code = otpStrings;
    } else {
      payload.email = account_no;
      payload.auth_code = otpStrings;
    }

    try {
      const response: any = await verify_otp.mutateAsync(payload);
      if (response.status === 200 && actionType === "twofactor") {
        setReadOnly(false);
        handleClose();
        const token = response?.data?.data?.token;
        const jwt: IJWT | any | undefined = decode_jwt(token);
        const role = jwt?.roles;
        setIsLoading(false);
        if (role.includes(ROLES.MERCHANT)) {
          handleClose();
          navigate(`${PATH_NAMES.client_home}/${PATH_NAMES.get_started}`, { replace: true });
          await queryClient.refetchQueries();
        } else if (role?.includes(ROLES.CASHIER)) {
          handleClose();
          navigate(`/cashier/dashboard`, {
            replace: true,
          });
          queryClient.refetchQueries();
        }
      } else if (response.status === 200 && actionType === "complete2fa") {
        handleClose();
        navigate(url, { replace: true, state: { account_no: account_no } });
      }
    } catch (error) {
      handleClose();
      setIsLoading(false);
      setReadOnly(false);
      return error;
    }
    setIsLoading(false);
    setReadOnly(false);
  };

  const handleOtpChange = (otp: any) => {
    if (otp.length === 6) {
      handleShow();
      setReadOnly(true);
      setTimeout(() => {
        Verify(otp);
      }, 2500);
    }
  };

  return (
    <>
      {show && (
        <div className="overlay-effect">
          <div className="overlay-child">
            <Spinner />
          </div>
        </div>
      )}
      <div className="row">
        {/* left */}
        <div
          style={{ backgroundImage: `url(${AuthIllustration})` }}
          className="col-lg-6 overflow-hidden d-none --bg-auth d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
        >
          <div id="banner-conc">
            <img src={PoolerLogoWhite} alt="pooler-logo" />
            <h1>
              The Easier Way to
              <br />
              Receive Payments.
            </h1>
            <p>Experience world-class banking-as-a-service</p>
          </div>
          <img src={PoolerIpad} alt="pooler-ipad" />
        </div>

        {/* right */}
        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100 position-relative">
          <div
            className="w-100 content-space-t-1 content-space-t-lg-2 content-space-b-1 px-3 px-lg-0"
            style={{ maxWidth: "25rem" }}
          >
            <div className="text-center d-sm-block d-md-block d-lg-none mx-auto">
              <img
                src={PoolerLogo}
                alt="pooler"
                className="navbar-brand-logo d-sm-block d-md-block d-lg-none mx-auto"
                style={{ paddingBottom: "15%" }}
              />
            </div>
            <div className="text-center">
              {actionType !== "twofactor" && (
                <div className="mb-4">
                  <img
                    className="avatar avatar-xxl avatar-4x3"
                    src={QR_CODE}
                    alt=" Description"
                    data-hs-theme-appearance="default"
                  />
                  <p className="">
                    {authSecret}{" "}
                    <i
                      className={`${
                        copy
                          ? "bi bi-check2-all text-success"
                          : "bi bi-clipboard"
                      }`}
                      onClick={() => {
                        navigator.clipboard.writeText(`${authSecret}`);
                        handleCopy();
                      }}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </p>
                </div>
              )}
              {actionType !== "twofactor" ? (
                <div className="mb-5">
                  <h1 className="display-5">2-step Verification</h1>
                  <p className="mb-0">
                    Scan the QR code or enter the secret key above in your
                    authenticator app to activate your OTP. Then enter the OTP
                    below
                  </p>
                  {/* <p>Enter the code from the email in the field below.</p> */}
                </div>
              ) : (
                <div className="mb-5">
                  <h1 className="display-5">2-step Verification</h1>
                  <p className="mb-0">
                    Enter the OTP from your authenticator app.
                  </p>
                  {/* <p>Enter the code from the email in the field below.</p> */}
                </div>
              )}

              <div className="row gx-2 gx-sm-3">
                <OtpFields onOtpInput={handleOtpChange} readOnly={readOnly} />
                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    id="agreed_terms"
                    name="agreed_terms"
                    hidden
                  />
                  <a
                    className={` link-underline-primary link link-primary`}
                    href="https://docs.poolerapp.com/#setting-up-authenticator-app-using-secret-key"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    How to setup Google Authenticator
                  </a>
                </div>
              </div>

              <div className="d-grid mb-3 d-none">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  // onClick={() => Verify(otp.join(""))}
                  ref={submitRef}
                >
                  {actionType !== "twofactor"
                    ? "Activate"
                    : "Verify my account"}{" "}
                  &nbsp;{" "}
                  <span className="align-middle">
                    {isLoading && <SpinnerComponent size="sm" />}
                  </span>
                </button>
              </div>

              {/* <div className="text-center">
              <p>Haven't received it? Resend a new code.</p>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Otp;

// 123456
