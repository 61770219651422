import { FormEvent, useMemo, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Skeleton from "react-loading-skeleton";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { payment_links_column } from "../../configs/dataTable.config";
import {
  useOneTimePayLinks,
  usePayLinks,
  usePayLinksMutation,
  useRecurringPayLinks,
} from "../../hooks/payment-link/index.hook";
import { IPayLinks } from "../../utils/interfaces";
import DropDownWrapper from "../DropDownWrapper";
import Tabs from "../Tab/Tab";
import PaginationComponent from "../pagination/pagination";
import Table from "../table/Table";
import "./payment.css";

import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import { formatAmount } from "../../helpers/index.helper";
import CopyButton from "../Copy";
import { Button } from "../button";
import Input from "../input/Input";
import MyModal from "../modal/Modal";
import FilterModal from "../modal/filterModal";
import SpinnerComponent from "../spinner/Spinner";
import DeleteLinkModal from "./delete-link-modal";

const PaymentTable = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const [linkId, setLinkId] = useState();

  const [reference, setReference] = useState("");
  const [activeItem, setActiveItem] = useState<number>(0);
  const [oneTime, setOneTime] = useState<number>(0);
  const [recurring, setRecurring] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
    setFilter((filter) => ({
      ...filter,
      reference: "",
      start_date: "",
      end_date: "",
      status: "all",
    }));
    setReference("");
    refetch();
  };

  const [filter, setFilter] = useState({
    reference: "",
    payment_start_date: "",
    payment_end_date: "",
    start_date: "",
    end_date: "",
    status: "all",
    download: "all",
  });
  const [activeTab, setActiveTab] = useState<string>("all");
  const [loading, setIsLoading] = useState<boolean>(false);

  const handleDropdownClick = (event: any) => {
    event.stopPropagation(); // Prevent event from propagating to the parent
  };

  const columns = useMemo(
    () => [
      ...payment_links_column,
      {
        Header: "",
        columnId: 8,
        accessor: "actions",
        Cell: ({ row }: any): JSX.Element => {
          const id = row?.original?.id;
          return (
            <>
              <Dropdown onClick={handleDropdownClick}>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="btn btn-white btn-sm"
                  style={{ background: "#fff" }}
                >
                  <i className="bi-three-dots" style={{ color: "#000" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to={`/merchant/business/pay-link/details/${id}`}
                  >
                    View
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    data-bs-target="#share-modal"
                    data-bs-toggle="modal"
                  >
                    Share
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    data-bs-target="#delete-link-modal"
                    data-bs-toggle="modal"
                    className="btn btn-text"
                    onClick={() => setLinkId(id)}
                  >
                    Cancel
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    ],
    []
  );

  // const debouncedInputValue = useDebounce<string>(reference, 2000);
  // ALL
  const { refetch, data, isLoading, isFetching } = usePayLinks(
    activeTab,
    activeItem,
    reference
  );

  // ONE TIME
  const { oneTimeData, fetchOneTimePayLink, loadOneTimePayLink } =
    useOneTimePayLinks(activeTab, oneTime, reference);

  // RECURRING
  const { recurringData, fetchRecurringPayLinks, loadRecurringPayLinks } =
    useRecurringPayLinks(activeTab, recurring, reference);

  const { filter_links } = usePayLinksMutation();

  // ONE TIME, RECURRING , ALL, DATA && TOTAL ITEMS
  const paymentLinks: IPayLinks[] = data?.data?.data?.items || data;
  const oneTimePayLinks: IPayLinks[] = oneTimeData?.data?.data?.items;
  const recurringPayLinks: IPayLinks[] = recurringData?.data?.data?.items;
  const totalOneTimePayLinks = oneTimeData?.data?.data?.totalPages;
  const totalRecurringPayLinks = recurringData?.data?.data?.totalPages;
  const totalItems = data?.data?.data?.totalPages;

  const handleRefFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };

  const handleFilterChange = (name: string, value: string) => {
    setFilter((filter) => ({
      ...filter,
      [name]: value,
      download: "",
      status: "",
    }));
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    refetch();
  };

  const filterData = (data: any) => {
    const type = activeTab;
    const page = activeItem;
    setIsLoading(true);
    filter_links
      .mutateAsync(data)
      .then((data: any) => {
        if (data?.data) {
          queryClient.setQueryData(
            ["one-time-payment-link-data", type, page, reference],
            data?.data?.data?.items
          );
          setFilter((filter) => ({
            ...filter,
            status: "all",
          }));
        }
      })
      .catch((e) => {
        setIsLoading(false);
        return e;
      })
      .finally(() => {
        setFilter((filter) => ({
          ...filter,
          status: "all",
        }));
        setIsLoading(false);
      });
  };

  const refreshData = () => {
    setReference("");
    setFilter((prev) => ({
      ...prev,
      reference,
    }));
    refetch();
  };

  // useEffect(() => {
  //   const type = activeTab;
  //   const page = activeItem;
  //   if (data?.data?.data) {
  //     queryClient.fetchQuery(
  //       ["payment-links-data", type, page + 1, reference],
  //       () => API_SERVICE._getPayLinks(activeTab, activeItem, reference)
  //     );

  //     queryClient.setQueryData(
  //       ["payment-link-data", type, page + 1, reference],
  //       data?.data?.data?.items
  //     );
  //   }
  //   return () => {};
  // }, [activeItem, activeTab, data?.data?.data, queryClient, reference, page]);

  return (
    <>
      <FilterModal show={show} handleClose={handleClose}>
        <div className="row mb-4">
          <div className="col tom-select-custom mb-3">
            <label>Start date</label>
            <input
              type="date"
              name="start_date"
              value={filter?.start_date}
              onChange={({ target }) =>
                handleFilterChange(target.name, target.value)
              }
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
          <div className="col tom-select-custom mb-3">
            <label>End date</label>
            <input
              type="date"
              name="end_date"
              value={filter?.end_date}
              onChange={({ target }) =>
                handleFilterChange(target.name, target.value)
              }
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-white btn-sm" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              filterData({
                ...filter,
                key:
                  activeTab === "all"
                    ? "payment-link-data"
                    : activeTab === "onetime"
                    ? "one-time-payment-link-data"
                    : activeTab === "recurring"
                    ? "recurring-payment-link-data"
                    : "payment-link-data",
              });
            }}
            style={{ cursor: "pointer" }}
          >
            Apply filter{" "}
            {loading && <SpinnerComponent variant="light" size="sm" />}
          </button>
        </div>
      </FilterModal>
      <div className="d-none d-lg-block d-xl-block">
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            {
              label: "All",
              key: "all",
            },
            {
              label: "One-time payment",
              key: "onetime",
            },
            {
              label: "Recurring payment",
              key: "recurring",
            },
          ]}
        />
      </div>
      {/* LARGE SCREENS */}
      <div className="d-none d-md-block d-lg-block d-xl-block">
        <div className="card">
          <div className="card-header card-header-content-md-between">
            <div className="mb-2 mb-md-0">
              <form onSubmit={handleSearch} style={{ width: "20rem" }}>
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend input-group-text me-2">
                    <i className="bi-search"></i>
                  </div>
                  <input
                    id="datatableSearch"
                    type="search"
                    className="form-control"
                    value={reference}
                    onChange={handleRefFilter}
                    placeholder="Search"
                    aria-label="Search"
                    style={{
                      paddingLeft: "2.6rem",
                      paddingRight: "2rem",
                    }}
                  />
                </div>
              </form>
            </div>

            <div className="d-grid d-sm-flex gap-2">
              <DropDownWrapper
                action={
                  <button
                    type="button"
                    className="btn btn-white btn-sm dropdown-togglee w-100"
                    ref={buttonRef}
                  >
                    <i className="bi bi-filter"></i> Filter
                  </button>
                }
              >
                <div className="p-3">
                  <label className="text-body">Date Range</label>
                  <div className="row mb-4">
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="start_date"
                        value={filter?.start_date}
                        onChange={({ target }) =>
                          handleFilterChange(target.name, target.value)
                        }
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="end_date"
                        value={filter?.end_date}
                        onChange={({ target }) =>
                          handleFilterChange(target.name, target.value)
                        }
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="btn btn-white btn-sm"
                      onClick={() => {
                        buttonRef.current?.click();
                        setFilter((filter) => ({
                          ...filter,
                          reference: "",
                          start_date: "",
                          end_date: "",
                          status: "all",
                        }));
                        setReference("");
                        refetch();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        filterData({
                          ...filter,
                          key:
                            activeTab === "all"
                              ? "payment-link-data"
                              : activeTab === "onetime"
                              ? "one-time-payment-link-data"
                              : activeTab === "recurring"
                              ? "recurring-payment-link-data"
                              : "payment-link-data",
                        });
                      }}
                    >
                      Apply Filter{" "}
                      {loading && (
                        <>
                          &nbsp;
                          <SpinnerComponent size="sm" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </DropDownWrapper>
              <div className="">
                <button
                  type="button"
                  className="btn btn-white btn-sm w-100"
                  onClick={() => {
                    refetch();
                  }}
                >
                  <i className="bi bi-arrow-clockwise me-2"></i> Refresh
                </button>
              </div>
            </div>
          </div>

          {activeTab === "onetime" && (
            <Table
              columns={columns}
              data={oneTimePayLinks !== undefined ? oneTimePayLinks : []}
              isFetching={loadOneTimePayLink || fetchOneTimePayLink}
              navigateTo={`/merchant/business/pay-link/details/`}
            />
          )}
          {activeTab === "recurring" && (
            <Table
              columns={columns}
              data={recurringPayLinks !== undefined ? recurringPayLinks : []}
              isFetching={loadRecurringPayLinks || fetchRecurringPayLinks}
              navigateTo={`/merchant/business/pay-link/details/`}
            />
          )}
          {activeTab === "all" && (
            <Table
              columns={columns}
              data={paymentLinks !== undefined ? paymentLinks : []}
              isFetching={isLoading || isFetching}
              navigateTo={`/merchant/business/pay-link/details/`}
            />
          )}

          <MyModal title="Share link" id="share-modal">
            <form>
              <Input name="email" type="email" label="Email address" />
              <Button className="mt-3" fullwidth type="submit">
                Share Link <i className="bi bi-arrow-up-right"></i>{" "}
              </Button>
            </form>
          </MyModal>
          <DeleteLinkModal id={linkId} />
          <div className="card-footer">
            <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
              <div className="col-sm mb-2 mb-sm-0"></div>

              <div className="col-sm-auto">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  {totalItems && totalItems > 1 && activeTab === "all" && (
                    <PaginationComponent
                      totalItems={totalItems}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                    />
                  )}
                  {totalOneTimePayLinks &&
                    totalOneTimePayLinks > 1 &&
                    activeTab === "onetime" && (
                      <PaginationComponent
                        totalItems={totalOneTimePayLinks}
                        activeItem={oneTime}
                        setActiveItem={setOneTime}
                      />
                    )}

                  {totalRecurringPayLinks &&
                    totalRecurringPayLinks > 1 &&
                    activeTab === "recurring" && (
                      <PaginationComponent
                        totalItems={totalRecurringPayLinks}
                        activeItem={recurring}
                        setActiveItem={setRecurring}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SMALL SCREENS */}
      <div className="d-sm-block d-md-none d-lg-none d-xl-none">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row gap-3 mb-3">
            <input
              id="datatableSearch"
              type="search"
              className="js-form-search form-control border border-secondary icon w-75"
              placeholder="Search..."
              value={reference}
              onChange={handleRefFilter}
            />

            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn btn-light rounded btn-icon icon-btn"
              >
                <i className="bi-three-dots" style={{ color: "#000" }} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as={"button"}
                  type="button"
                  onClick={() => setShow(true)}
                >
                  Filter
                </Dropdown.Item>
                <Dropdown.Item
                  as={"button"}
                  type="button"
                  hidden
                  onClick={() => {
                    // handleExports();
                    // moengage.track_event("DOWNLOAD_ACCOUNT", {
                    //   success_status: true,
                    // });
                  }}
                >
                  Download
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="mt-3">
            <ul className="list-group">
              {isFetching ? (
                <>
                  {[1, 2, 3, 4, 5, 6].map(() => {
                    return (
                      <li className="list-group-item my-2">
                        <Skeleton height={30} />
                      </li>
                    );
                  })}
                </>
              ) : (
                <>
                  {paymentLinks?.map((txn_data: any, index: any) => {
                    return (
                      <>
                        <li
                          className={`${
                            index === 0 || index === paymentLinks?.length - 1
                              ? "list-group-item"
                              : "list-group-item"
                          } d-flex flex-row justify-content-between align-items-start card mb-3`}
                          onClick={() => {
                            navigate(
                              `/merchant/business/pay-link/details/${txn_data.id}`,
                              {
                                state: { ...txn_data },
                              }
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="ms-2 me-auto"
                            style={{ maxWidth: "150px" }}
                          >
                            <div className="fw-semibold text-truncate">
                              {txn_data?.payment_link_name}
                            </div>
                            <div
                              className="text-truncate d-flex"
                              style={{ maxWidth: "150px" }}
                            >
                              {txn_data?.link}{" "}
                            </div>
                            <span>
                              <CopyButton text={"txn_data?.link"} />
                            </span>
                          </div>

                          <div className="d-flex flex-column gap-3">
                            <div className="text-end">
                              {txn_data?.expired === true ? (
                                <span className="badge bg-soft-danger text-danger">
                                  <span className="legend-indicator bg-danger"></span>
                                  EXPIRED
                                </span>
                              ) : (
                                <span className="badge bg-soft-success text-success">
                                  <span className="legend-indicator bg-success"></span>
                                  ACTIVE
                                </span>
                              )}
                            </div>

                            {txn_data?.amount === null ? (
                              <span className="">N/A</span>
                            ) : (
                              <span className="">
                                {formatAmount(
                                  txn_data?.amount,
                                  txn_data?.currency
                                )}
                              </span>
                            )}
                          </div>
                        </li>
                      </>
                    );
                  })}
                </>
              )}
            </ul>

            <div className="col-sm-auto">
              <div className="d-flex justify-content-center justify-content-sm-end">
                {totalItems && totalItems > 1 && activeTab === "all" && (
                  <PaginationComponent
                    totalItems={totalItems}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                  />
                )}
                {totalOneTimePayLinks &&
                  totalOneTimePayLinks > 1 &&
                  activeTab === "onetime" && (
                    <PaginationComponent
                      totalItems={totalOneTimePayLinks}
                      activeItem={oneTime}
                      setActiveItem={setOneTime}
                    />
                  )}

                {totalRecurringPayLinks &&
                  totalRecurringPayLinks > 1 &&
                  activeTab === "recurring" && (
                    <PaginationComponent
                      totalItems={totalRecurringPayLinks}
                      activeItem={recurring}
                      setActiveItem={setRecurring}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTable;
