import React, { useState, useEffect } from "react";
import Kyc from "./Kyc";
import BuisnessDocuments from "./business/BuisnessDocuments";
import { useQueryClient } from "react-query";
import BuisnessDetails from "./business/BuisnessDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_NAMES } from "../../constants/index.constant";
import { useKyc, useSession } from "../../hooks/auth/auth.hook";
import { kycInterface, sessionInterface } from "../../utils/interfaces";
import SpinnerComponent from "../../component/spinner/Spinner";
import API_SERVICE from "../../services/services";

function Buisness(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const actionType: string | any = queryParams.get("stage");

  const { data, refetch } = useSession();
  const merchant_data: sessionInterface = data?.data?.data;

  const business_information: any =
    merchant_data?.kycDetails?.business_information;
  const documents: any = merchant_data?.kycDetails?.documents;
  const personal_information: any =
    merchant_data?.kycDetails?.personal_information;

  const { provide_kyc } = useKyc();
  const [stage, setStage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    buisness_category: "",
    buisness_name: "",
    rc_no: "",
    buisness_type: "",
    website_url: "",
    country: "",
    buisness_nature: "",
    api_uses: "",
    buisness_use_case: "",
    number_of_directors: "",
    user_interactions: "",
  });
  const [details, setDetails] = useState({
    operational_licence: "",
    address: "",
    cac: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleBuisnessDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setDetails((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileLicenceExport = (data: any) => {
    setDetails((prevFormData) => ({
      ...prevFormData,
      licence: data?.licence_image,
    }));
  };
  const handlePoaExport = (data: any) => {
    setDetails((prevFormData) => ({
      ...prevFormData,
      proof_of_address: data?.proof_of_address,
    }));
  };
  const handleMoaExport = (data: any) => {
    setDetails((prevFormData) => ({
      ...prevFormData,
      moa: data?.moa,
    }));
  };

  const formDisplay = () => {
    if (stage === 0) {
      return (
        <BuisnessDetails
          formValues={formData}
          handleChange={handleChange}
          data={business_information}
        />
      );
    } else if (stage === 1) {
      return (
        <BuisnessDocuments
          formValues={details}
          handleChange={handleBuisnessDetails}
          onObjectExport={handleFileLicenceExport}
          onProofExport={handlePoaExport}
          onMoaexport={handleMoaExport}
        />
      );
    }
  };
  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());

    if (actionType === "1") {
      setStage(0);
    } else {
      setStage(1);
    }

    // buisness details / form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      buisness_category:
        business_information?.business_details?.buisness_category,
      buisness_name: business_information?.business_details?.buisness_name,
      rc_no: business_information?.business_details?.rc_no,
      buisness_type: business_information?.business_details?.buisness_type,
      website_url: business_information?.business_details?.website_url,
      country: business_information?.business_details?.country,
      buisness_nature: business_information?.business_details?.buisness_nature,
      api_uses: business_information?.business_details?.api_uses,
      buisness_use_case:
        business_information?.business_details?.buisness_use_case,
      number_of_directors:
        business_information?.business_details?.number_of_directors,
      user_interactions:
        business_information?.business_details?.user_interactions,
    }));

    // details / buisness documents
    setDetails((prevFormData) => ({
      ...prevFormData,
      operational_licence: documents?.buisness_documents?.operational_licence,
      address: documents?.buisness_documents?.address,
      cac: documents?.buisness_documents?.cac,
      proof_of_address: documents?.buisness_documents?.proof_of_address,
      licence: documents?.buisness_documents?.licence,
      moa: documents?.buisness_documents?.moa,
    }));
  }, [
    actionType,
    business_information?.business_details?.api_uses,
    business_information?.business_details?.buisness_category,
    business_information?.business_details?.buisness_name,
    business_information?.business_details?.buisness_nature,
    business_information?.business_details?.buisness_type,
    business_information?.business_details?.buisness_use_case,
    business_information?.business_details?.country,
    business_information?.business_details?.number_of_directors,
    business_information?.business_details?.rc_no,
    business_information?.business_details?.user_interactions,
    business_information?.business_details?.website_url,
    documents?.buisness_documents?.address,
    documents?.buisness_documents?.cac,
    documents?.buisness_documents?.licence,
    documents?.buisness_documents?.moa,
    documents?.buisness_documents?.operational_licence,
    documents?.buisness_documents?.proof_of_address,
    queryClient,
  ]);

  const SaveBuisnessDetails = async (payload: kycInterface) => {
    setIsLoading(false);
    try {
      const response: any = await provide_kyc.mutateAsync(payload);
      if (response.status === 200) {
        refetch();
        setIsLoading(false);
        setFormData({
          buisness_category: "",
          buisness_name: "",
          rc_no: "",
          buisness_type: "",
          website_url: "",
          country: "",
          buisness_nature: "",
          api_uses: "",
          buisness_use_case: "",
          number_of_directors: "",
          user_interactions: "",
        });
        if (actionType === "1") {
          navigate(
            `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/buisness_information?stage=2`
          );
        } else {
          navigate(
            `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/personal_information`
          );
        }
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  return (
    <Kyc activeTab="buisness_information">
      <div
        id="basicVerStepDetails"
        className="card card-body active"
        style={{ minHeight: "15rem" }}
      >
        <div className="row align-items-center mb-5 pb-2 mt-2">
          <div className="col-sm mb-2 mb-sm-0">
            <h2 className="page-header-title">Business Information</h2>
          </div>

          <div className="col-sm-auto">
            <button
              type="button"
              disabled={isLoading}
              className="btn btn-primary"
              data-hs-step-form-next-options='{
                        "targetSelector": "#basicVerStepTerms"
                      }'
              onClick={() => {
                if (stage === 0) {
                  SaveBuisnessDetails({
                    business_information: {
                      ...business_information,
                      business_details: { ...formData },
                    },
                    documents: {
                      ...documents,
                    },
                    personal_information: { ...personal_information },
                  });
                } else {
                  SaveBuisnessDetails({
                    documents: {
                      ...documents,
                      buisness_documents: { ...details },
                    },
                    personal_information: { ...personal_information },
                    business_information: { ...business_information },
                  });
                }
              }}
            >
              Save changes{" "}
              {isLoading && (
                <>
                  &nbsp;{" "}
                  <span className="align-middle">
                    <SpinnerComponent size="sm" />
                  </span>
                </>
              )}
            </button>
          </div>
        </div>

        <div className="d-none d-lg-block">
          <ul className="step step-md step-centered">
            <li className={`step-item ${stage === 0 ? "active" : ""}`}>
              <div className="step-content-wrapper">
                <span className="step-icon step-icon-soft-primary">1</span>
                <p className="step-text">Details</p>
              </div>
            </li>

            <li className={`step-item ${stage === 1 ? "active" : ""}`}>
              <div className="step-content-wrapper">
                <span className="step-icon step-icon-soft-primary">2</span>
                <p className="step-text">Documents</p>
              </div>
            </li>
          </ul>
        </div>

        {/* form */}
        {formDisplay()}
        {/* LG && MD SCREENS */}
        <div className="d-none d-md-block d-lg-block">
          <div className="d-flex align-items-center mt-auto ">
            <div className="ms-auto">
              {stage === 1 && (
                <>
                  {" "}
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/buisness_information?stage=1`
                      )
                    }
                    className="btn btn-primary"
                  >
                    <i className="bi bi-chevron-left"></i>
                    &nbsp;Back
                  </button>
                  &nbsp;
                </>
              )}
              <button
                type="button"
                onClick={() => {
                  // SAVES KYC INFO AND NAVIGATES TO THE NEXT STAGE
                  if (stage === 0) {
                    SaveBuisnessDetails({
                      business_information: {
                        ...business_information,
                        business_details: { ...formData },
                      },
                      documents: {
                        ...documents,
                      },
                      personal_information: { ...personal_information },
                    });
                  } else {
                    SaveBuisnessDetails({
                      documents: {
                        ...documents,
                        buisness_documents: { ...details },
                      },
                      personal_information: { ...personal_information },
                      business_information: { ...business_information },
                    });
                  }
                }}
                className="btn btn-primary"
              >
                Next <i className="bi-chevron-right small"></i>
              </button>
            </div>
          </div>
        </div>
        {/* SM SCREENS */}
        <div className="d-sm-block d-md-none d-lg-none">
          <div className="d-flex align-items-center justify-content-space-between mt-auto">
            <div className="ms-auto">
              {stage === 1 && (
                <>
                  {" "}
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/buisness_information?stage=1`
                      )
                    }
                    className="btn btn-ghost-secondary"
                  >
                    <i className="bi bi-chevron-left"></i>
                    &nbsp;Back
                  </button>
                  &nbsp;
                </>
              )}
              <button
                type="button"
                onClick={() => {
                  // SAVES KYC INFO AND NAVIGATES TO THE NEXT STAGE
                  if (stage === 0) {
                    SaveBuisnessDetails({
                      business_information: {
                        ...business_information,
                        business_details: { ...formData },
                      },
                      documents: {
                        ...documents,
                      },
                      personal_information: { ...personal_information },
                    });
                  } else {
                    SaveBuisnessDetails({
                      documents: {
                        ...documents,
                        buisness_documents: { ...details },
                      },
                      personal_information: { ...personal_information },
                      business_information: { ...business_information },
                    });
                  }
                }}
                className="btn btn-ghost-primary"
              >
                Next <i className="bi-chevron-right small"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Kyc>
  );
}

export default Buisness;
