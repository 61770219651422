import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import AirtelIcon from "../../assets/airtel.svg";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import MtnIcon from "../../assets/mtn.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import NineMobileIcon from "../../assets/svg/9mobile.svg";
import GloIcon from "../../assets/svg/glo.svg";
import ActionSheet from "../../component/mobile/ActionSheet";
import SpinnerComponent from "../../component/spinner/Spinner";
import {
  convertToInternationalFormat,
  formatAmount,
  isValidNigeriaPhoneNumber,
} from "../../helpers/index.helper";
import {
  useConfirmDataPurchase,
  useDataNumberInfo,
  usePrepareDataPurchase,
} from "../../hooks/top-up/index.hook";
import API_SERVICE from "../../services/services";
import { allowOnlyNumericValues } from "../../utils/decode";
import PurchaseModal from "./PurchaseModal";
import SaveBeneficiaryModal from "./SaveBeneficiaryModal";
import SavedBeneficiaryModal from "./SavedBeneficiaryModal";
import SuccessfulPurchaseModal from "./SuccessfulPurchaseModal";

interface FormData {
  mobile_number: string;
  network_provider?: string;
  data_plan?: string;
  data_amount: string;
  purchase_id: string;
}

interface NetworkProvider {
  name: string;
  icon?: any;
}

interface DataTopUpFormProps {
  formData: FormData;
  providers: NetworkProvider[];
  setFormData: (prevData: FormData) => void;
  index: number;
  size: any;
  dataPlans: any;
  setDataPlans: any;
  setIsSavedBeneficiary: (val: boolean) => void;
}

export const providers = [
  {
    name: "MTN",
    icon: MtnIcon,
  },
  {
    name: "Globacom",
    icon: GloIcon,
  },
  {
    name: "Airtel",
    icon: AirtelIcon,
  },
  {
    name: "9mobile",
    icon: NineMobileIcon,
  },
];

const Data = ({ stage, setStage, handleRefetch }: any) => {
  const queryClient = useQueryClient();
  const { resolve_prepare_purchase_data } = usePrepareDataPurchase();
  const { resolve_confirm_purchase_data } = useConfirmDataPurchase();
  const [size, setSize] = React.useState([0, 0]);
  const [beneficiary, setBeneficiary] = React.useState<any>([]);
  const [batchDate, setBatchData] = React.useState<any[]>([]);
  const [confirmedBatch, setConfirmedBatch] = React.useState(null);
  const [formsData, setFormsData] = React.useState<FormData[]>([
    {
      mobile_number: "",
      network_provider: "",
      data_plan: "",
      data_amount: "",
      purchase_id: "",
    },
  ]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleAddMore = () => {
    setFormsData((prevForms) => [
      ...prevForms,
      {
        /* initial state for a new form */
        mobile_number: "",
        network_provider: "",
        data_plan: "",
        data_amount: "",
        purchase_id: "",
      },
    ]);
    scrollDownToElement("#scroll-body");
  };

  const [dataPlans, setDataPlans] = React.useState<any[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isSavedBeneficiary, setIsSavedBeneficiary] = useState<boolean>(false);
  const [canSaveBeneficiary, setCanSaveBeneficiary] = useState<boolean>(false);
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setStage("init");
  };
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const [showProceed, setShowProceed] = React.useState<boolean>(false);
  const [showSaveBeneficiaryModal, setShowSaveBeneficiaryModal] =
    React.useState<boolean>(false);
  const handleOpenSaveBeneficiaryModal = () => {
    setShowSuccessModal(false);
    setShowSaveBeneficiaryModal(true);
  };
  const handleCloseSaveBeneficiaryModal = () => {
    setShowSaveBeneficiaryModal(false);
    setStage("init");
  };
  const handleCloseProceed = () => {
    setShowProceed(false);
    if (size?.[0] <= 998) setStage("init");
  };
  const handleShowProceed = async () => {
    try {
      setShowProceed(true);
      if (size?.[0] <= 998) setStage("pay");
    } catch (e) {
      return e;
    }
  };
  const handlePay = async (transaction_pin: string) => {
    try {
      const res = await resolve_prepare_purchase_data.mutateAsync(batchDate);
      const id = res?.data?.data?.batch_id ?? res?.data?.data?.[0]?.reference;
      await resolve_confirm_purchase_data.mutateAsync({ transaction_pin, id });
      moengage.track_event("INITIATE_DATA", {
        success_status: true,
        ...formsData,
      });
      queryClient.invalidateQueries({
        queryKey: ["airtime", 0],
      });
      queryClient.invalidateQueries({
        queryKey: ["data", 0],
      });
      handleRefetch();
      if (!isSavedBeneficiary) setCanSaveBeneficiary(batchDate.length < 2);
      setBeneficiary({ ...formsData?.[0], type: "airtime" });
      setFormsData([
        {
          mobile_number: "",
          network_provider: "",
          data_plan: "",
          data_amount: "",
          purchase_id: "",
        },
      ]);
      setBatchData([]);
      if (size?.[0] > 998) setShowProceed(false);
      handleShowSuccessModal();
    } catch (e) {
      return e;
    }
  };
  const handleReset = () => {
    setFormsData([
      {
        mobile_number: "",
        network_provider: "",
        data_plan: "",
        data_amount: "",
        purchase_id: "",
      },
    ]);
    setBatchData([]);
  };

  function scrollDownToElement(elementSelector: string) {
    window.location.href = `#last-one`;
  }

  return (
    <React.Fragment>
      {showSaveBeneficiaryModal && (
        <SaveBeneficiaryModal
          show={showSaveBeneficiaryModal}
          size={size?.[0]}
          handleClose={handleCloseSaveBeneficiaryModal}
          beneficiary={beneficiary}
        />
      )}
      {showProceed && (
        <>
          {size?.[0] <= 998 && stage === "pay" && (
            <PurchaseModal
              show={showProceed}
              handleClose={handleCloseProceed}
              handlePay={handlePay}
              handleRefetch={handleRefetch}
              processor={resolve_confirm_purchase_data}
              data={confirmedBatch}
              noModal={true}
              formsData={formsData}
              size={size}
              title="data"
              isDisabled={
                resolve_prepare_purchase_data.isLoading ||
                resolve_confirm_purchase_data.isLoading
              }
              acttionType="DATA"
            />
          )}
          {size?.[0] > 998 && (
            <PurchaseModal
              show={showProceed}
              handleClose={handleCloseProceed}
              handlePay={handlePay}
              handleRefetch={handleRefetch}
              processor={resolve_confirm_purchase_data}
              data={confirmedBatch}
              noModal={false}
              formsData={formsData}
              size={size}
              title="data"
              isDisabled={
                resolve_prepare_purchase_data.isLoading ||
                resolve_confirm_purchase_data.isLoading
              }
              acttionType="DATA"
            />
          )}
        </>
      )}
      <SuccessfulPurchaseModal
        show={showSuccessModal}
        handleClose={handleCloseSuccessModal}
        handleSaveBeneficiary={handleOpenSaveBeneficiaryModal}
        handleReset={() => handleReset()}
        title="Data"
        canSaveBeneficiary={canSaveBeneficiary}
        size={size}
      />
      {/* form(s) */}
      {stage !== "pay" && stage !== "history" && (
        <>
          <div
            style={
              size?.[0] <= 998
                ? {}
                : {
                    minHeight: "60vh",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  }
            }
            id="scroll-body"
          >
            <div
              style={
                size?.[0] <= 998 ? {} : { maxHeight: 450, overflowY: "auto" }
              }
            >
              {formsData.map((singleFormData, index) => (
                <div>
                  <div className={`w-100 position-relative`}>
                    {/* delete form button */}
                    {index !== 0 && (
                      <div
                        className="position-absolute end-0 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          const newFormsData = formsData.filter(
                            (_, i) => i !== index
                          );
                          setFormsData(newFormsData);
                          const newBatchData = batchDate.filter(
                            (_, i) => i !== index
                          );
                          setBatchData(newBatchData);
                        }}
                        style={{
                          top: size?.[0] <= 998 ? "-45px" : "-15px",
                          background: "#FF003D",
                          padding: "4px",
                          borderRadius: "8px",
                          gap: "10px",
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    )}
                    <DataTopUpForm
                      key={index}
                      index={index}
                      formData={singleFormData}
                      providers={providers}
                      setIsSavedBeneficiary={setIsSavedBeneficiary}
                      setFormData={(updatedData) => {
                        const newFormsData = [...formsData];
                        newFormsData[index] = updatedData;
                        setFormsData(newFormsData);
                        setBatchData(
                          newFormsData.map((item: any) => ({
                            mobile_number: convertToInternationalFormat(
                              item.mobile_number
                            ),
                            purchase_id: item.purchase_id,
                            amount: item.data_amount,
                          }))
                        );
                      }}
                      size={size}
                      dataPlans={dataPlans}
                      setDataPlans={setDataPlans}
                    />
                  </div>
                  <div id={formsData.length - 1 === index ? `last-one` : ""} />
                  {index !== formsData.length - 1 && (
                    <hr style={{ color: "#DDE7FB" }} />
                  )}
                </div>
              ))}
            </div>
            <br />
            {/* action button */}
            {size?.[0] <= 998 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.08)",
                    gap: 14,
                    left: 0,
                    right: 0,
                    padding: "22px 27px 51px",
                  }}
                  className="d-flex flex-column bottom-0 position-fixed bg-white"
                >
                  <div className="d-flex flex-row" style={{ gap: 14 }}>
                    <button
                      className="btn fw-bolder"
                      onClick={handleAddMore}
                      disabled={
                        formsData.length === 10 ||
                        formsData.filter(
                          (form) =>
                            !form.data_amount ||
                            !form.mobile_number ||
                            !form.network_provider ||
                            !form.purchase_id
                        )?.length > 0 ||
                        !isValidNigeriaPhoneNumber(
                          formsData.map((item) =>
                            convertToInternationalFormat(item?.mobile_number)
                          )
                        )
                      }
                      style={{
                        flex: "1 1 0",
                        height: 40,
                        boxShadow: "2px 2px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: 8,
                        overflow: "hidden",
                        border: "1px #D0D5E9 solid",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          flex: "1 1 0",
                          height: 40,
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 10,
                          paddingBottom: 10,
                          background: "white",
                          borderRadius: 8,
                          overflow: "hidden",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 4,
                          display: "flex",
                        }}
                      >
                        <PlusIcon className="w-12 h-12" />
                        Add more
                      </div>
                    </button>
                    <div
                      style={{
                        flex: "1 1 0",
                        height: 40,
                        borderRadius: 4,
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <button
                        type={"button"}
                        className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                        disabled={
                          resolve_prepare_purchase_data.isLoading ||
                          resolve_confirm_purchase_data.isLoading ||
                          formsData.filter(
                            (form) =>
                              !form.data_amount ||
                              !form.mobile_number ||
                              !form.network_provider ||
                              !form.purchase_id
                          )?.length > 0 ||
                          !isValidNigeriaPhoneNumber(
                            formsData.map((item) =>
                              convertToInternationalFormat(item?.mobile_number)
                            )
                          )
                        }
                        onClick={handleShowProceed}
                        style={{
                          borderRadius: "8px",
                          background:
                            resolve_prepare_purchase_data.isLoading ||
                            resolve_confirm_purchase_data.isLoading ||
                            formsData.filter(
                              (form) =>
                                !form.data_amount ||
                                !form.mobile_number ||
                                !form.network_provider ||
                                !form.purchase_id
                            )?.length > 0 ||
                            !isValidNigeriaPhoneNumber(
                              formsData.map((item) =>
                                convertToInternationalFormat(
                                  item?.mobile_number
                                )
                              )
                            )
                              ? "#D0D5E9"
                              : "#2C1DFF",
                        }}
                      >
                        Proceed{" "}
                        <span className="align-middle ml-2">
                          &emsp;
                          {(resolve_prepare_purchase_data.isLoading ||
                            resolve_confirm_purchase_data.isLoading) && (
                            <SpinnerComponent size="sm" />
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                  {Boolean(formsData.length === 10) && (
                    <span className="mt-1 form-text">
                      Maximum of 10 recipients allowed
                    </span>
                  )}
                </div>
              </>
            ) : (
              <div>
                {/* add more button */}
                <button
                  className="btn fw-bolder"
                  onClick={handleAddMore}
                  disabled={
                    formsData.length === 10 ||
                    formsData.filter(
                      (form) =>
                        !form.data_amount ||
                        !form.mobile_number ||
                        !form.network_provider ||
                        !form.purchase_id
                    )?.length > 0 ||
                    !isValidNigeriaPhoneNumber(
                      formsData.map((item) =>
                        convertToInternationalFormat(item?.mobile_number)
                      )
                    )
                  }
                  style={{
                    color: "black",
                    border: "1px solid #CBD1EC",
                    width: "116px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    borderRadius: "8px",
                    gap: "4px",
                    boxShadow: "2px 2px 0px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <PlusIcon className="w-12 h-12" />
                  Add more
                </button>
                {Boolean(formsData.length === 10) && (
                  <span className="mt-1 form-text">
                    Maximum of 10 recipients allowed
                  </span>
                )}
                <div
                  className="mt-5"
                  style={{
                    paddingBottom: "50px",
                  }}
                >
                  <button
                    type={"button"}
                    className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                    disabled={
                      resolve_prepare_purchase_data.isLoading ||
                      resolve_confirm_purchase_data.isLoading ||
                      formsData.filter(
                        (form) =>
                          !form.data_amount ||
                          !form.mobile_number ||
                          !form.network_provider ||
                          !form.purchase_id
                      )?.length > 0 ||
                      !isValidNigeriaPhoneNumber(
                        formsData.map((item) =>
                          convertToInternationalFormat(item?.mobile_number)
                        )
                      )
                    }
                    onClick={handleShowProceed}
                    style={{
                      borderRadius: "8px",
                      background:
                        resolve_prepare_purchase_data.isLoading ||
                        resolve_confirm_purchase_data.isLoading ||
                        formsData.filter(
                          (form) =>
                            !form.data_amount ||
                            !form.mobile_number ||
                            !form.network_provider ||
                            !form.purchase_id
                        )?.length > 0 ||
                        !isValidNigeriaPhoneNumber(
                          formsData.map((item) =>
                            convertToInternationalFormat(item?.mobile_number)
                          )
                        )
                          ? "#D0D5E9"
                          : "#2C1DFF",
                    }}
                  >
                    Proceed
                    <span className="align-middle ml-2">
                      &emsp;
                      {(resolve_prepare_purchase_data.isLoading ||
                        resolve_confirm_purchase_data.isLoading) && (
                        <SpinnerComponent size="sm" />
                      )}
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
};

const DataTopUpForm: React.FC<DataTopUpFormProps> = ({
  formData,
  index,
  providers,
  setFormData,
  size,
  dataPlans,
  setDataPlans,
  setIsSavedBeneficiary,
}) => {
  const [showData, setShowData] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [numberAirtime, setNumberAirtime] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const workingData = dataPlans?.[index]?.filter(
    (item: any) =>
      item?.data_amount?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      item?.decription?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      formatAmount(item.amount_to_pay)
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase())
  );

  return (
    <>
      <SavedBeneficiaryModal
        selectNumber={async (number) => {
          setFormData({ ...formData, mobile_number: number });
          if (
            (number?.startsWith("0") && number?.length === 11) ||
            (!number?.startsWith("0") && number?.length === 10)
          ) {
            setLoading(true);
            setIsSavedBeneficiary(true);
            const res = await API_SERVICE._getNumberDataInfo(
              convertToInternationalFormat(allowOnlyNumericValues(number))
            );
            setDataPlans((prev: any) => {
              let dataPlans: any[] = [...prev];
              dataPlans[index] = res?.data?.data;
              return dataPlans;
            });
            setFormData({
              ...formData,
              network_provider: res?.data?.data?.[0]?.telco,
            });
            setLoading(false);
          }
          handleClose();
        }}
        show={show}
        index={index}
        size={size?.[0]}
        handleClose={handleClose}
      />
      {size?.[0] <= 998 && showData && (
        <ActionSheet
          id={`data${index}`}
          show={showData}
          onHide={() => setShowData(false)}
          title={
            <div className="d-flex flex-column">
              <img
                src={
                  providers.filter(
                    (provider: any) =>
                      formData.network_provider === provider.name
                  )?.[0]?.icon
                }
                alt={
                  providers.filter(
                    (provider: any) =>
                      formData.network_provider === provider.name
                  )?.[0]?.name
                }
                style={{ height: 24, width: 24 }}
              />
              <p className="mb-0">Select data plan</p>
            </div>
          }
        >
          <div className="w-100">
            <input
              className="form-control mb-2 fw-normal"
              type="text"
              value={searchTerm}
              onChange={(e: any) => {
                setSearchTerm(e.target.value);
              }}
              placeholder="Search data plan"
              style={{
                height: "43px",
                border: "1px solid #CBD1EC",
                borderRadius: "8px",
                padding: "8px",
                fontSize: "16px",
                color: "#8F9BB2",
                lineHeight: "24px",
                letterSpacing: "-0.16px",
              }}
            />
          </div>
          <div
            style={{
              overflowY: "auto",
              height: "84vh",
            }}
          >
            {workingData?.map((item: any) => (
              <div
                key={item.purchase_id}
                className="d-flex justify-content-between align-items-center pointer"
                style={{
                  borderTop: index === 0 ? "1px solid #dee2e6" : undefined,
                  borderBottom: "1px solid #dee2e6",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    purchase_id: item.purchase_id,
                    data_amount: item.amount_to_pay,
                  });
                  setShowData(false);
                }}
              >
                <div className="p-2">
                  {item.data_amount} {item.decription}
                </div>
                <div className="d-flex flex-column p-2">
                  {formatAmount(item.amount_to_pay)}
                </div>
              </div>
            ))}
          </div>
        </ActionSheet>
      )}
      {/* phone number */}
      <div className="mb-4 overflow-hidden d-flex flex-column">
        <label className="form-label fw-semibold" htmlFor="mobile_number">
          Phone number*
        </label>
        <div className="d-flex w-100">
          <select
            className="form-select"
            style={{
              border: "1px solid #CBD1EC",
              borderRadius: "8px",
              height: "42px",
              width: "25%",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
            // Add proper id, name, value, and onChange handler
          >
            <option>+234</option>
          </select>
          <input
            className="w-100 fw-normal"
            id="mobile_number"
            maxLength={11}
            value={formData.mobile_number}
            onChange={async (e) => {
              try {
                setFormData({
                  ...formData,
                  mobile_number: allowOnlyNumericValues(e.target.value),
                  network_provider: "",
                  purchase_id: "",
                  data_amount: "",
                });
                setSelectedOption(null);
                setDataPlans((prev: any) => {
                  let dataPlans: any[] = [...prev];
                  dataPlans[index] = [];
                  return dataPlans;
                });
                if (
                  (allowOnlyNumericValues(e.target.value)?.startsWith("0") &&
                    allowOnlyNumericValues(e.target.value)?.length === 11) ||
                  (!allowOnlyNumericValues(e.target.value)?.startsWith("0") &&
                    allowOnlyNumericValues(e.target.value)?.length === 10)
                ) {
                  setLoading(true);
                  const res = await API_SERVICE._getNumberDataInfo(
                    convertToInternationalFormat(
                      allowOnlyNumericValues(e.target.value)
                    )
                  );
                  setDataPlans((prev: any) => {
                    let dataPlans: any[] = [...prev];
                    dataPlans[index] = res?.data?.data;
                    return dataPlans;
                  });
                  setFormData({
                    ...formData,
                    network_provider: res?.data?.data?.[0]?.telco,
                    mobile_number: allowOnlyNumericValues(e.target.value),
                    purchase_id: "",
                    data_amount: "",
                  });
                  setLoading(false);
                  setIsSavedBeneficiary(false);
                }
              } catch (e) {
                toast.error("Issue fetching Phone number Details");
                setLoading(false);
                setIsSavedBeneficiary(false);
              }
            }}
            style={{
              height: "42px",
              border: "1px solid #CBD1EC",
              borderRadius: "8px",
              borderLeft: "none",
              padding: "8px",
              fontSize: "16px",
              color: "#000000",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              lineHeight: "24px",
              letterSpacing: "-0.16px",
            }}
          />
        </div>
        {formData.mobile_number &&
          !isValidNigeriaPhoneNumber([
            convertToInternationalFormat(formData.mobile_number),
          ]) && (
            <span className="mt-1 form-text" style={{ color: "red" }}>
              Please enter a valid phone number
            </span>
          )}
      </div>

      {/* select beneficiaries */}
      {size?.[0] > 998 ? (
        <div
          className="d-flex w-auto"
          style={{
            justifyContent: size?.[0] <= 998 ? "center" : "flex-start",
          }}
        >
          <p
            className="link link-primary fw-normal"
            onClick={() => setShow(true)}
            style={{
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            Select beneficiary
          </p>
        </div>
      ) : (
        <div
          className="d-flex w-auto"
          style={{
            justifyContent: size?.[0] <= 998 ? "center" : "flex-start",
          }}
        >
          <p
            className="link link-primary fw-normal"
            onClick={handleOpen}
            style={{
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            Select beneficiary
          </p>
        </div>
      )}

      {/* select network provider */}
      <div className="d-flex flex-column w-100 mt-2 mb-5">
        <label className="form-label fw-semibold" htmlFor="network_provider">
          Select network provider
        </label>
        <div className="d-flex gap-3 justify-content-between">
          {providers.map((provider, idx) => (
            <div
              key={idx}
              className={`position-relative d-flex flex-column align-items-center justify-content-center bg-body border border-2 ${
                formData.network_provider === provider.name
                  ? "border-primary"
                  : ""
              }`}
              style={{
                width: "20%",
                height: "64px",
                cursor: "pointer",
                borderRadius: "8px",
                padding: "12px 15px",
              }}
              onClick={() =>
                setFormData({ ...formData, network_provider: provider.name })
              }
            >
              {formData.network_provider === provider.name && (
                <span
                  className="position-absolute  translate-middle"
                  style={{
                    top: "8px",
                    left: "8px",
                  }}
                >
                  <Check />
                  <span className="visually-hidden">Selected</span>
                </span>
              )}
              <div
                className="text-black fw-normal d-flex justify-content-center flex-column align-items-center gap-1"
                style={{ fontSize: "10px" }}
              >
                <img src={provider.icon} alt={provider.name} />
                {provider.name}
              </div>
            </div>
          ))}
        </div>

        {loading && (
          <div className="d-flex mt-2 justify-content-center">
            <SpinnerComponent />
          </div>
        )}
      </div>

      {/* Data plan */}
      <div className="mb-6 mt-5 w-100">
        <label className="form-label fw-bold" htmlFor="data_plan">
          Data plan*
        </label>
        {size?.[0] > 998 ? (
          <Select
            // defaultValue={formData?.purchase_id?.toString()}
            formatOptionLabel={(option: any, meta: any) => {
              if (meta?.context === "value") {
                return (
                  <>
                    <div className="d-flex justify-content-between align-items-center pointer">
                      <div className="">
                        {formatAmount(option.amount_to_pay)} -{" "}
                        {option.data_amount} {option.decription}
                      </div>
                    </div>
                  </>
                );
              }
              return (
                <>
                  <div className="d-flex justify-content-between align-items-center pointer">
                    <div className="">
                      {formatAmount(option.amount_to_pay)} -{" "}
                      {option.data_amount} {option.decription}
                    </div>
                  </div>
                </>
              );
            }}
            className=" border-secondary rounded card-subtitle"
            getOptionValue={(option) => option?.purchase_id?.toString()}
            getOptionLabel={(item: any) =>
              `${formatAmount(item.amount_to_pay)} - ${item.data_amount}{" "}${
                item.decription
              }`
            }
            isSearchable
            menuPlacement="top"
            classNamePrefix="top-up-select"
            placeholder="Select a plan"
            value={!formData.network_provider ? {} : selectedOption}
            isDisabled={!formData.network_provider}
            onChange={(selectedOption: any) => {
              setSelectedOption(selectedOption);
              setFormData({
                ...formData,
                ...selectedOption,
                purchase_id: selectedOption?.purchase_id,
                data_amount: selectedOption?.amount_to_pay,
              });
            }}
            options={dataPlans?.[index]}
          />
        ) : (
          <div
            onClick={
              !formData.network_provider ? () => {} : () => setShowData(true)
            }
          >
            <div
              className="form-select d-flex align-items-center"
              style={{
                border: "1px solid #CBD1EC",
                borderRadius: "8px",
                height: 50,
                overflow: "hidden",
                color: "#000000",
              }}
              // Add proper id, name, value, and onChange handler
            >
              {!formData.purchase_id && <option>Select a plan</option>}
              {dataPlans?.[index]
                ?.filter(
                  (item: any) => item.purchase_id === formData.purchase_id
                )
                ?.map((item: any) => (
                  <option
                    key={item.purchase_id}
                    value={`${item.purchase_id}*${item.amount_to_pay}`}
                  >
                    {formatAmount(item.amount_to_pay)} - {item.data_amount}{" "}
                    {item.decription}
                  </option>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Data;
