export enum Currency {
  USD = "USD",
  NGN = "NGN",
  GBP = "GBP",
}

export enum Status {
  active = "active",
  inactive = "inactive",
}

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export enum ROLES {
  CASHIER = "CASHIER",
  SUPER_ADMIN = "SUPER_ADMIN",
  MERCHANT = "ADMIN",
}

export enum BUISNESS_TYPE {
  UN_REGISTERED = "unRegisterd",
  REGISTERED = "registerd",
}

export enum KYC_LEVELS {
  GENERAL = "general",
  LEVEL_ONE = "levelOne",
  LEVEL_TWO = "levelTwo",
  LEVEL_THREE = "levelThree",
}
