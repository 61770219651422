import moengage from "@moengage/web-sdk";
import { useState } from "react";
import { toast } from "react-toastify";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  useResolveOTPForPin,
  useSetPin,
  useVerifyOTPforPIn,
} from "../../hooks/transfer/index.hook";
import OtpFields from "../otp/OtpFields";
import SpinnerComponent from "../spinner/Spinner";
import "./modal.css";

const SetPin = ({ handleStage }: { handleStage?: any }) => {
  const { deviceInfo } = useUserDeviceInfo();
  const [otpSent, setOTPSent] = useState<boolean | string>(false);
  const [error, setError] = useState<any>(false);
  const [errorObj, setErrorObj] = useState<any>({
    ques: false,
    answ: false,
  });
  const [answer, setAnswer] = useState<string>("");
  const [secQuestion, setSecQuestion] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");
  const { resolve_otp_pin } = useResolveOTPForPin();
  const { resolve_verify_otp_pin } = useVerifyOTPforPIn();
  const { resolve_set_pin } = useSetPin();
  const sendOTP = async () => {
    try {
      await resolve_otp_pin.mutateAsync();
    } catch (error) {
      return error;
    }
  };

  const handleOtpChange = async (otp: any) => {
    if (otp.length === 6) {
      try {
        await resolve_verify_otp_pin.mutateAsync({ auth_code: otp });
        setOTPSent(otp);
      } catch (error) {
        return error;
      }
    }
  };

  const handleSetPin = async () => {
    try {
      await resolve_set_pin.mutateAsync({
        transaction_pin: pin,
        security_question: secQuestion,
        security_answer: answer,
        auth_code: otpSent,
      });

      moengage.track_event("SET_PIN", {
        PIN: pin,
        security_question: secQuestion,
        email_otp: otpSent,
        success_status: true,
        platform: deviceInfo?.userAgent,
      });

      handleStage("success");
    } catch (error: any) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.data);
      }
      return error;
    }
  };

  return (
    <>
      {!otpSent ? (
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-row justify-contents-center">
            <p className="text-center">
              Please enter the 6-digit OTP sent to your business mail.
            </p>
          </div>
          <div className="w-75 my-3 text-center">
            <p className="fs-6 mb-2">Enter OTP</p>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <OtpFields onOtpInput={handleOtpChange} readOnly={false} />
            </div>
          </div>
          <div className="d-flex flex-row justify-contents-center">
            <p className="">
              Didn’t receieve OTP?{" "}
              <button
                onClick={() => sendOTP()}
                className="fw-bold btn-link btn"
              >
                Resend OTP <i className="fw-bold bi bi-arrow-right"></i>
              </button>
            </p>
            {resolve_verify_otp_pin?.isLoading && (
              <>
                <SpinnerComponent />
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex pt-4 flex-column align-items-center">
            <p className="text-start w-75">Choose PIN</p>
            <OtpFields
              numInputs={4}
              secret
              onOtpInput={(value: string, index: number) => {
                setPin(value);
              }}
              value={pin}
              readOnly={false}
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyles={{
                borderColor: "#CBD1EC",
                margin: "0px 6px",
                padding: "0.6125rem 1rem",
              }}
            />
          </div>
          <div className="d-flex pt-4 flex-column align-items-center">
            <p className="text-start w-75">Confirm PIN</p>
            <OtpFields
              numInputs={4}
              secret
              onOtpInput={(value: string, index: number) => {
                setConfirmPin(value);
                if (value[index] !== pin[index]) {
                  setError(true);
                } else {
                  setError(false);
                }
              }}
              value={confirmPin}
              readOnly={false}
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyles={{
                borderColor: "#FF003D",
                margin: "0px 6px",
                padding: "0.6125rem 1rem",
              }}
              error={error}
            />
          </div>
          <br />
          <div className="my-2 mt-4">
            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="question" className="mb-2 card-subtitle">
                  Set your security question
                </label>
                <select
                  className={`form-select ${
                    !errorObj.ques || secQuestion.length > 0
                      ? "--bor-pri"
                      : "is-invalid"
                  }`}
                  onBlur={() => {
                    setErrorObj((prev: any) => ({
                      ...prev,
                      ques: true,
                    }));
                  }}
                  aria-label="Set your security question"
                  onChange={(e) => setSecQuestion(e?.target?.value)}
                  value={secQuestion}
                >
                  <option value="">Select one</option>
                  <option value="What is the name of your favorite TV show ?">
                    What is the name of your favorite TV show ?
                  </option>
                  <option value="What is the name of your dream car ?">
                    What is the name of your dream car ?
                  </option>
                  <option value="What is the name of your dream city to live in ?">
                    What is the name of your dream city to live in ?
                  </option>
                  <option value="What is the name of your best friend ?">
                    What is the name of your best friend ?
                  </option>
                </select>
              </div>
            </div>
            <div className="row mb-3 mt-4">
              <div className="col-12">
                <label htmlFor="answer" className="mb-2 card-subtitle">
                  Your answer
                </label>
                <input
                  id="answer"
                  name="answer"
                  onChange={(e) => setAnswer(e?.target?.value)}
                  onBlur={() => {
                    setErrorObj((prev: any) => ({
                      ...prev,
                      answ: true,
                    }));
                  }}
                  value={answer}
                  type="text"
                  className={`form-control ${
                    !errorObj.answ || answer.length > 0
                      ? "--bor-pri"
                      : "is-invalid"
                  }`}
                  placeholder=""
                  aria-label="answer"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex mt-4 flex-column align-items-center">
            <button
              onClick={() => handleSetPin()}
              disabled={
                !secQuestion ||
                !answer ||
                confirmPin !== pin ||
                !pin ||
                !confirmPin
              }
              className="btn btn-primary btn-block w-100 --bor-pri my-2"
            >
              Continue{" "}
              {resolve_set_pin?.isLoading && (
                <>
                  {" "}
                  &nbsp;
                  <SpinnerComponent size="sm" />
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SetPin;
