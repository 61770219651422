/* eslint-disable react-hooks/exhaustive-deps */
import moengage from "@moengage/web-sdk";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KycLevels from "../../../component/modal/KycLevels";
import SpinnerComponent from "../../../component/spinner/Spinner";
import { PATH_NAMES } from "../../../constants/index.constant";
import {
  encryptKyc,
  mode,
  signatureHarsh,
} from "../../../helpers/index.helper";
import { useSession } from "../../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../../hooks/device.hook";
import {
  useBuisnessCompliance,
  useCheckBuisnessRequirements,
  useGetComplianceRequirements,
} from "../../../hooks/kyc/kyc.hook";
import API_SERVICE from "../../../services/services";
import { BUISNESS_TYPE, KYC_LEVELS } from "../../../utils/enums";
import { sessionInterface } from "../../../utils/interfaces";
import Kyc from "../Kyc";
import MandatoryRegisteredFields from "./MandatoryRegisteredFields";

function MandatoryRegistered(): JSX.Element {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionData } = useSession();
  const { compliance } = useBuisnessCompliance();
  const { complianceData } = useGetComplianceRequirements();
  const { deviceInfo } = useUserDeviceInfo();
  const merchantData: sessionInterface = sessionData?.data?.data;

  const requirementsData =
    complianceData?.data?.data?.registerd?.level_one?.account_limits;
  const single_withdrawal = requirementsData?.single_withdrawal;
  const daily_withdrawal = requirementsData?.daily_withdrawal;
  const collection = requirementsData?.collection;

  const merchantId: string | any = localStorage.getItem(
    "merchant_compliance_id"
  );
  const BUSINESS_TYPE: string = BUISNESS_TYPE.REGISTERED;

  const message = merchantId && JSON.stringify({ id: merchantId });

  const x_signature = encryptKyc(message, signatureHarsh);

  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );
  const requirementData: any = requiredData?.data?.data;
  const levelOne: any = requirementData?.level_one;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportedFields, setExportedFields] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleExportFields = (fields: any) => {
    setExportedFields(fields);
  };

  const Submit = async (
    formData: any,
    mode: any,
    submit: boolean,
    url?: string,
    proceed?: boolean
  ) => {
    let x = formData;
    if (proceed === true) {
      x.proceed = true;
    }
    const payloadSign =
      proceed === true
        ? encryptKyc(JSON.stringify({ ...x, submit: submit }), signatureHarsh)
        : encryptKyc(
            JSON.stringify({ ...formData, submit: submit }),
            signatureHarsh
          );
    setIsLoading(true);
    const payload =
      proceed === true
        ? {
            KYC_TYPE: BUISNESS_TYPE.REGISTERED,
            KYC_DATA: {
              ...formData,
              proceed: true,
              submit: submit,
            },
            KYC_MERCHANT_ID: merchantData?.merchant_compliance_id,
            KYC_LEVEL: KYC_LEVELS.LEVEL_ONE,
            x_signature: payloadSign,
          }
        : {
            KYC_TYPE: BUISNESS_TYPE.REGISTERED,
            KYC_DATA: {
              ...formData,
              submit: submit,
            },
            KYC_MERCHANT_ID: merchantData?.merchant_compliance_id,
            KYC_LEVEL: KYC_LEVELS.LEVEL_ONE,
            x_signature: payloadSign,
          };

    try {
      const response = await compliance.mutateAsync(payload);
      if (response?.status === 200 && submit === true && url !== "") {
        setIsLoading(false);
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        handleShow();
      } else if (response?.status === 200 && submit === false && url !== "") {
        setIsLoading(false);
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        navigate(
          `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
        );
      } else {
        setIsLoading(false);
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());
    if (merchantId !== undefined && BUSINESS_TYPE !== undefined) {
      queryClient.fetchQuery(
        ["business-requirements-check", merchantId, BUSINESS_TYPE, x_signature],
        () =>
          API_SERVICE._checkBuisnessComplianceRequirements(
            BUSINESS_TYPE,
            merchantId,
            x_signature
          )
      );
    }
  }, []);

  const hasNonEmptyValues = (obj: any) => {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        if (!hasNonEmptyValues(obj[key])) {
          return false;
        }
      } else if (typeof obj[key] === "string" && obj[key].trim() !== "") {
        return true;
      }
    }
    return false;
  };

  const SaveEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_user_attribute("starts_kyc", true);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier1_saved: true,
      platform: deviceInfo?.userAgent,
    });
  };

  const SubmitEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_user_attribute("starts_kyc", true);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier1_submitted: true,
      platform: deviceInfo?.userAgent,
    });
  };

  function isEmptyObject(obj: any) {
    if (typeof obj !== "object" || obj === null) {
      return false;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "string" && value.trim() === "") {
          return false;
        }
      }
    }
    return true;
  }

  function validateObject(payload: any) {
    if (typeof payload !== "object" || !payload) {
      toast.info(`kindly fill required fields and upload approriate documents`);
      return false;
    }

    const allowedIdentificationTypes = [
      "passport",
      "nin",
      "driver_license",
      "voter_card",
    ];

    if (
      typeof payload.tin_number !== "string" ||
      typeof payload.cac_number !== "string" ||
      typeof payload.cac_document_upload !== "string" ||
      typeof payload.business_type !== "string" ||
      typeof payload.memorandum_upload !== "string" ||
      Array.isArray(payload.business_owners) === false ||
      payload.business_owners.length === 0
    ) {
      toast.info(`kindly fill required fields and upload approriate documents`);

      return false;
    }

    for (const owner of payload.business_owners) {
      if (
        typeof owner.first_name !== "string" ||
        typeof owner.last_name !== "string" ||
        typeof owner.email !== "string" ||
        typeof owner.phone_number !== "string" ||
        typeof owner.dob !== "string" ||
        typeof owner.valid_means_of_identification !== "string" ||
        (owner.valid_means_of_identification &&
          !allowedIdentificationTypes.includes(
            owner.valid_means_of_identification
          ))
      ) {
        toast.info(
          `kindly fill required fields and upload approriate documents`
        );
        return false;
      }

      // MEANS OF IDENTIFICATION VALIDATION
      if (
        owner.valid_means_of_identification &&
        allowedIdentificationTypes.includes(owner.valid_means_of_identification)
      ) {
        // NIN VALIDATION
        if (owner.valid_means_of_identification === "nin") {
          const nin = owner["nin"];

          if (!nin) {
            toast.info(`kindly fill NIN fields and upload documents`);
            return false;
          } else {
            const nin_Keys = Object?.keys(nin);
            if (!nin["nin_image"] || !nin["nin_number"] || !nin["upload"]) {
              toast.info(`kindly fill  NIN fields and upload documents`);
              return false;
            }

            if (
              nin_Keys?.length === 3 &&
              nin["nin_image"] &&
              nin["nin_number"] &&
              nin["upload"]
            ) {
              owner["voter_card"] && delete owner["voter_card"];
              owner["passport"] && delete owner["passport"];
              owner["driver_license"] && delete owner["driver_license"];
              return true;
            }
          }
        }

        // VOTER CARD VALIDATION
        if (owner.valid_means_of_identification === "voter_card") {
          const voter_card = owner["voter_card"];
          if (!voter_card) {
            toast.info(`kindly fill Voter card fields and upload documents`);
            return false;
          } else {
            const voter_card_keys = Object?.keys(voter_card);
            if (!voter_card["voter_card_number"]) {
              toast.info(`kindly fill Voter card number`);
              return false;
            }

            if (!voter_card["upload"] || voter_card["upload"] === "") {
              toast.info(`kindly fill upload voter card document`);
              return false;
            }

            if (
              voter_card_keys?.length === 2 &&
              voter_card["voter_card_number"] &&
              voter_card["upload"]
            ) {
              owner["nin"] && delete owner["nin"];
              owner["passport"] && delete owner["passport"];
              owner["driver_license"] && delete owner["driver_license"];
              return true;
            }
          }
        }

        // PASSPORT VALIDATION
        if (owner.valid_means_of_identification === "passport") {
          const passport = owner["passport"];
          console.log(passport, "pasport");
          if (!passport) {
            toast.info(`kindly fill Intl Passport fields and upload documents`);
            return false;
          } else {
            const passport_keys = Object.keys(passport);
            if (
              !passport["issue_date"] ||
              !passport["upload"] ||
              !passport["expiry_date"] ||
              !passport["passport_number"]
            ) {
              toast.info(
                `kindly fill Intl Passport fields and upload documents`
              );
              return false;
            }
            if (
              passport_keys?.length === 4 &&
              passport["issue_date"] &&
              passport["upload"] &&
              passport["expire_date"] &&
              passport["number"]
            ) {
              owner["nin"] && delete owner["nin"];
              owner["voter_card"] && delete owner["voter_card"];
              owner["driver_license"] && delete owner["driver_license"];
              return true;
            }
          }
        }

        // DRIVER LICENSE VALIDATION
        if (owner.valid_means_of_identification === "driver_license") {
          const driver_license = owner["driver_license"];

          if (!driver_license) {
            console.log("here");
            toast.info(
              `kindly fill Driver license fields and upload documents`
            );
            return false;
          } else {
            const driver_license_keys = Object.keys(driver_license);
            if (
              !driver_license["issue_date"] ||
              !driver_license["upload"] ||
              !driver_license["expiry_date"] ||
              !driver_license["number"]
            ) {
              toast.info(
                `kindly fill Driver license fields and upload documents`
              );
              return false;
            }
          }
        }
      }

      // NOK VALIDATION
      if (
        !owner.nextOfKin ||
        !owner.nextOfKin["nok_firstname"] ||
        !owner.nextOfKin["nok_lastname"] ||
        !owner.nextOfKin["nok_address"] ||
        !owner.nextOfKin["nok_mobile_number"] ||
        !owner.nextOfKin["nok_email"] ||
        !owner.nextOfKin["nok_relationship"]
      ) {
        toast.info(`kindly fill next of kin fields`);
        return false;
      }
    }
    return true;
  }

  const checkPayloadBeforeSubmit = (jsonData: any) => {
    const data = jsonData["business_owners"];
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      if (entry.hasOwnProperty("voter_card")) {
        const voterCardObject = entry.voter_card;

        if (
          !voterCardObject.hasOwnProperty("voter_card_number") ||
          !voterCardObject.hasOwnProperty("upload")
        ) {
          delete entry.voter_card;
        }
      }

      if (entry.hasOwnProperty("driver_license")) {
        const driver_license = entry.driver_license;

        if (
          !driver_license.hasOwnProperty("number") ||
          !driver_license.hasOwnProperty("issue_date") ||
          !driver_license.hasOwnProperty("expiry_date") ||
          !driver_license.hasOwnProperty("upload")
        ) {
          delete entry.driver_license;
        }
      }

      if (entry.hasOwnProperty("passport")) {
        const passport = entry.passport;

        if (
          !passport.hasOwnProperty("passport_number") ||
          !passport.hasOwnProperty("issue_date") ||
          !passport.hasOwnProperty("expiry_date") ||
          !passport.hasOwnProperty("upload")
        ) {
          delete entry.passport;
        }
      }

      if (entry.hasOwnProperty("nin")) {
        const nin = entry.nin;
        if (
          !nin.hasOwnProperty("nin_number") ||
          !nin.hasOwnProperty("nin_image") ||
          !nin.hasOwnProperty("upload")
        ) {
          delete entry.nin;
        }
      }
    }
    return jsonData;
  };

  return (
    <>
      <KycLevels
        show={show}
        handleClose={handleClose}
        onClick={() =>
          navigate(
            `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
          )
        }
        buttonLabel={"Proceed to tier 2"}
      />
      <Kyc activeTab="mandatory_details" activeMenu="registered">
        <div
          id="basicVerStepDetails"
          className="card"
          style={{ minHeight: "15rem" }}
        >
          <div className="card kyc-card-top">
            <div className="row align-items-start mb-0 pb-2 mt-2">
              <div className="col-sm mb-2 mb-sm-0">
                <h2 className="page-header-title text-white pb-3">Tier 1</h2>
                {/* <i class="bi bi-circle"></i> */}
              </div>

              <div className="col-sm-auto">
                {levelOne?.submit === true ? (
                  <>
                    {/* <button type="button" className="btn  kyc-save-btn" hidden>
                      Request Edit
                    </button> */}
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn  kyc-save-btn"
                    onClick={() => {
                      Submit(exportedFields, mode, false, "", false);
                      SaveEvent("KYC_SAVE_REG_BIZ_TIER1");
                    }}
                  >
                    Save changes{" "}
                    {isLoading && (
                      <>
                        &nbsp;{" "}
                        <span className="align-middle">
                          <SpinnerComponent size="sm" />
                        </span>
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
            <ul className="ps-0 w-100 w-md-75 w-lg-100 list-unstyled pe-lg-5 me-lg-5">
              {/* SM SCREENS */}
              <li className="text-white listStyleDisc w-100 d-sm-block d-md-none d-lg-none">
                <span
                  className=""
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Withdrawal limit
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  &#8358;
                  {parseInt(
                    single_withdrawal?.replace(/,/g, ""),
                    10
                  ).toLocaleString()}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc w-100 d-none d-md-block d-lg-block">
                <span
                  className=""
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Withdrawal limit
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  &#8358;
                  {parseInt(
                    single_withdrawal?.replace(/,/g, ""),
                    10
                  ).toLocaleString()}
                </span>
              </li>

              {/* SM SCREENS */}
              <li className="text-white listStyleDisc py-1 w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Daily withdrawal limit{" "}
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  &#8358;
                  {parseInt(
                    daily_withdrawal?.replace(/,/g, ""),
                    10
                  ).toLocaleString()}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc py-1 w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Daily withdrawal limit{" "}
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  &#8358;
                  {parseInt(
                    daily_withdrawal?.replace(/,/g, ""),
                    10
                  ).toLocaleString()}
                </span>
              </li>

              {/* SM SCREENS  */}
              <li className="text-white listStyleDisc w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Maximum Deposit limit{" "}
                </span>
                <span
                  className="float-end text-capitalize"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {collection}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Maximum Deposit limit{" "}
                </span>
                <span
                  className="float-end text-capitalize"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  {collection}
                </span>
              </li>
            </ul>
          </div>

          <div className="card-body active c-overridedd">
            {levelOne ? (
              <MandatoryRegisteredFields
                onFileExport={handleExportFields}
                data={levelOne}
                isDisabled={levelOne?.submit === true ? true : false}
              />
            ) : (
              <MandatoryRegisteredFields onFileExport={handleExportFields} />
            )}
            {/* LG SCREENS */}
            {levelOne?.submit === true ? null : (
              <>
                <div className="d-none d-md-block d-lg-block pb-3">
                  <div className="d-flex align-items-center justify-content-end mt-auto">
                    <button
                      type="button"
                      className="btn kyc-save-btn"
                      onClick={() => {
                        if (validateObject(exportedFields) === true) {
                          Submit(exportedFields, mode, false, "xg", true);
                          SaveEvent("KYC_PROCEED_REG_BIZ_TIER1");
                        }
                      }}
                    >
                      Proceed to tier 2{" "}
                      <i className="bi bi-arrow-right small"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        if (validateObject(exportedFields) === true) {
                          Submit(exportedFields, mode, true, "c", true);
                          SubmitEvent("KYC_SUBMIT_REG_BIZ_TIER1");
                        }
                      }}
                    >
                      Submit <i className="bi bi-check2 text-white small"></i>
                    </button>
                  </div>
                </div>
                {/* SM SCREENS */}
                <div className="d-sm-block d-md-none d-lg-none">
                  <div className="d-flex align-items-center justify-content-end mt-auto">
                    <button
                      type="button"
                      className="btn kyc-save-btn"
                      onClick={() => {
                        if (validateObject(exportedFields) === true) {
                          Submit(exportedFields, mode, false, "xg", true);
                          SaveEvent("KYC_PROCEED_REG_BIZ_TIER1");
                        }
                      }}
                    >
                      Proceed to tier 2{" "}
                      <i className="bi bi-arrow-right small"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        if (validateObject(exportedFields) === true) {
                          Submit(exportedFields, mode, true, "c", true);
                          SubmitEvent("KYC_SUBMITTED_REG_BIZ_TIER1");
                        }
                      }}
                    >
                      Submit <i className="bi bi-check2 text-white small"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Kyc>
    </>
  );
}

export default MandatoryRegistered;
