import { ReactNode, useRef, useEffect } from "react";
const sizeMap = {
  sm: "modal-sm",
  lg: "modal-lg",
  xl: "modal-xl",
};
function MyModal({
  title,
  children,
  id,
  className = "",
  size,
  onButtonRef,
  onClick,
}: {
  title?: string;
  children: ReactNode;
  id: string;
  className?: string;
  size?: keyof typeof sizeMap;
  onButtonRef?: (buttonRef: React.RefObject<HTMLButtonElement>) => void;
  onClick?: () => void;
}) {
  const buttonRef: any = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (onButtonRef) {
      onButtonRef(buttonRef);
    }
  }, [onButtonRef]);

  return (
    <div
      className={`modal fade ${className}`}
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-dialog-scrollable modal-dialog-centered ${
          size ? sizeMap[size] : ""
        }`}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={buttonRef}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default MyModal;
