import moengage from "@moengage/web-sdk";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { payment_template_column } from "../../configs/dataTable.config";
import useUserDeviceInfo from "../../hooks/device.hook";
import { useViewTemplates } from "../../hooks/payment-link/index.hook";
import { IPaymentLink } from "../../pages/payment-link/create-payment-link";
import API_SERVICE from "../../services/services";
import SpinnerComponent from "../spinner/Spinner";
import Table from "../table/Table";
import LinkModal from "./LinkModal";

const PaymentHeader = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { deviceInfo } = useUserDeviceInfo();

  // const { create_link } = useCreatePayLink();
  const [openModal, setOpenModal] = useState(true);
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page] = useState<number>(0);

  const { data, isLoading } = useViewTemplates(page, 5);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    moengage.track_event("VIEW/USE_TEMPLATE", {
      success_status: true,
      platform: deviceInfo?.userAgent,
    });
  };

  const tableData = data?.data?.data?.items;

  const handleSubmit = (data: IPaymentLink) => {
    setLoading(true);
    navigate("/merchant/business/one-time-link", {
      state: data,
    });
  };

  const columns = useMemo(
    () => [
      ...payment_template_column,
      {
        Header: "",
        columnId: 5,
        accessor: "action",
        Cell: ({ row }: any): JSX.Element => {
          const payload: any = row?.original;
          return (
            <>
              <button
                className="btn btn-white btn-sm"
                onClick={() => handleSubmit(payload)}
              >
                Use{" "}
                {loading && (
                  <>
                    &nbsp; <SpinnerComponent size="sm" />
                  </>
                )}
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    queryClient.fetchQuery(["payment-link-template", page + 1], () =>
      API_SERVICE._getPaymentTemplate(page, 5)
    );
  }, [page, queryClient]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Payment link templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            columns={columns}
            data={tableData !== undefined ? tableData : []}
            isFetching={isLoading}
          />
          <button
            type="button"
            className="btn btn-white btn-sm dropdown-togglee w-100"
            onClick={() => {
              navigate("/merchant/business/pay-link/templates");
            }}
          >
            See all templates
          </button>
        </Modal.Body>
      </Modal>
      <LinkModal show={openModal} handleClose={() => setOpenModal(false)} />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Payment Links</h1>
          </div>

          <div className="col-sm-auto">
            <div className="d-flex gap-3">
              <button
                className="btn btn-white link-dark"
                type="button"
                // onClick={() =>
                //   navigate("/merchant/business/pay-link/templates")
                // }
                onClick={handleShow}
              >
                Use Templates
              </button>

              <button
                className="btn btn-primary"
                type="button"
                onClick={() => navigate("/merchant/business/one-time-link")}
              >
                New Link <i className="bi bi-plus-lg ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentHeader;
