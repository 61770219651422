import React, { useEffect, useState } from "react";
import { IPaymentLink } from "../../../pages/payment-link/create-payment-link";
import Input from "../../input/Input";
import TextArea from "../../input/textarea";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { formatCurrency } from "../../../helpers/index.helper";

function PaymentDetailsForm({
  formData,
  handleEditChange,
  recurring,
  onCollectionUpdate,
}: {
  formData: IPaymentLink;
  handleEditChange: (
    field: keyof IPaymentLink,
    value: string | number | boolean | any
  ) => void;
  recurring?: boolean;
  onCollectionUpdate?: any;
}) {
  const dropdown: string[] = formData?.extra_fields?.dropdown || [];
  const texts: string[] = formData?.extra_fields?.textfield || [];

  const [amount, setAmount] = useState(false);
  const [formatVal, setFormatVal] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string>("");
  const [fieldType, setFieldType] = useState<string>("");
  //   const [options, setOptions] = useState<Array<string>>([]);
  const [dropdownOptions, setDropdownOptions] =
    useState<Array<string>>(dropdown);

  const [txtFields, setTxtFields] = useState<Array<string>>(texts);
  const [option1, setOption1] = useState("");
  const [txtField1, setTxtField1] = useState("");

  const [extraOptions, setExtraOptions] = useState<Array<string>>([]);
  const [extraTxtField, setExtraField] = useState<Array<string>>([]);
  const [txtField, setTxtField] = useState<Array<string>>([]);

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [collection, setCollection] = useState<any>([]);
  const [actionType, setActionType] = useState<boolean>(false);
  const [updatedFields, setUpdatedFields] = useState<any>([]);
  const [updatedOptions, setUpdatedOptions] = useState<any>([]);

  // const [fieldOptions, setFieldOptions] = useState([]);
  const handleClose = () => {
    setShow(false);
    setFieldName("");
    setFieldType("");
    setActionType(false);
    setOption1("");
    setExtraOptions([]);
  };
  const handleShow = () => setShow(true);

  const removeExtraOption = (index: number) => {
    const newOptions = [...extraOptions];
    newOptions.splice(index, 1);
    setExtraOptions(newOptions);
  };
  const removeUpdatedOption = (index: number) => {
    const x = [...updatedOptions];
    x.splice(index, 1);
    setUpdatedOptions(x);
  };

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const u = [...extraOptions];
    u[index] = e.target.value;
    setExtraOptions(u);
    if (updatedOptions.length > 0) {
      const x = [...updatedOptions];
      x[index] = e.target.value;
      setUpdatedOptions(x);
    }
  };

  const handleExtraFieldClick = () => {
    if (extraOptions.length > 0 && fieldType === "dropdown") {
      const newOptions = [...extraOptions, option1];
      setDropdownOptions([...dropdownOptions, ...newOptions]);

      setCollection([
        ...collection,
        {
          type: fieldType,
          dropdown: [...newOptions],
          fieldName,
        },
      ]);
      onCollectionUpdate([
        ...collection,
        {
          type: fieldType,
          dropdown: [...newOptions],
          fieldName,
        },
      ]);
      handleClose();
    }
    if (extraOptions.length <= 0 && fieldType === "dropdown") {
      const newOptions = [option1];
      setDropdownOptions(newOptions);

      setCollection([
        ...collection,
        {
          type: fieldType,
          dropdown: [...newOptions],
          fieldName,
        },
      ]);
      onCollectionUpdate([
        ...collection,
        {
          type: fieldType,
          dropdown: [...newOptions],
          fieldName,
        },
      ]);
      handleClose();
    }
    if (extraTxtField.length > 0 && fieldType === "textfield") {
      const newFields = [...extraTxtField, txtField1];
      setTxtField(newFields);
      setTxtFields([...txtFields, ...newFields]);
      const newItem: any = {
        type: fieldType,
        fieldName: fieldName,
      };
      setCollection([...collection, newItem]);
      onCollectionUpdate([...collection, newItem]);
      handleClose();
    }
    if (extraTxtField.length <= 0 && fieldType === "textfield") {
      setTxtFields([txtField1]);
      setTxtField(txtField);

      const newItem: any = {
        type: fieldType,
        fieldName: fieldName,
      };
      setCollection([...collection, newItem]);

      onCollectionUpdate([...collection, newItem]);
      handleClose();
    }
  };

  const handleOptionClick = () => {
    if (fieldType === "dropdown") {
      const newOption = { value: "" };
      setExtraOptions([...extraOptions, newOption.value]);
      setUpdatedOptions([...extraOptions, newOption.value]);
    } else {
    }
  };

  const filteredData = (data: any, word: string) => {
    const x = data?.extra_fields;
    const result = x?.filter((item: any) => item.fieldName !== word);
    setCollection([]);
    onCollectionUpdate([...result]);
    return result;
  };

  const handleExtraFieldUpdate = () => {
    const fieldNameToFind = fieldName;
    const newValue = inputValue;
    const newOption = selectedOption;

    const index = updatedFields?.findIndex(
      (item: any) => item?.fieldName === fieldNameToFind
    );

    if (index !== -1) {
      let updatedArray = [...updatedFields];
      updatedArray[index].fieldName = newValue;

      if (newOption) {
        updatedArray[index].type = newOption;
      }

      if (updatedArray[index].dropdown) {
        updatedArray[index].dropdown = [];
      }

      onCollectionUpdate([...updatedArray]);
      handleClose();
    }
  };

  useEffect(() => {
    const data = formData?.extra_fields?.extra_fields;
    setUpdatedFields(data);
  }, [formData?.extra_fields?.extra_fields]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="sm" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add extra field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row gx-2 gx-sm-3">
            <div className="mb-4">
              <label className="form-label" htmlFor="field_name">
                Field name
              </label>
              {actionType === false ? (
                <input
                  type="text"
                  className={`form-control form-control-lg border border-secondary`}
                  name="field_name"
                  id="field_name"
                  tabIndex={1}
                  value={fieldName}
                  placeholder="field name"
                  aria-label="field name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldName(e.target.value);
                    // setInputValue(e.target.value);
                  }}
                />
              ) : (
                <input
                  type="text"
                  className={`form-control form-control-lg border border-secondary`}
                  name="field_name"
                  id="field_name"
                  tabIndex={1}
                  value={inputValue}
                  placeholder="field name"
                  aria-label="field name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // setFieldName(e.target.value);
                    setInputValue(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="field_name">
                Field type
              </label>
              {actionType === false ? (
                <select
                  className={`form-select form-control-lg`}
                  id="validationValidSelect1"
                  value={fieldType}
                  name="field_type"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setFieldType(e.target.value);
                    // setSelectedOption(e.target.value);
                  }}
                >
                  <option>Select category</option>
                  {[
                    { label: "Drop down", value: "dropdown" },
                    { label: "Text field", value: "textfield" },
                  ].map((i) => {
                    return (
                      <option
                        key={i.value}
                        value={i.value}
                        className="text-uppercase"
                      >
                        {i.label}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  className={`form-select form-control-lg`}
                  id="validationValidSelect1"
                  value={selectedOption}
                  name="field_type"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    // setFieldType(e.target.value);
                    setSelectedOption(e.target.value);
                  }}
                >
                  <option>Select category</option>
                  {[
                    { label: "Drop down", value: "dropdown" },
                    { label: "Text field", value: "textfield" },
                  ].map((i) => {
                    return (
                      <option
                        key={i.value}
                        value={i.value}
                        className="text-uppercase"
                      >
                        {i.label}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>

            {fieldType === "dropdown" && (
              <div
                className="mb-3"
                style={{
                  maxHeight: "200px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div>
                  <label
                    className="form-label"
                    htmlFor="field_name"
                    style={{ color: "#8F9BB2" }}
                  >
                    Define drop down options
                  </label>
                </div>
                <div>
                  {actionType === false && (
                    <input
                      type="text"
                      className={`form-control form-control-lg `}
                      name="option1"
                      id="option1"
                      tabIndex={1}
                      aria-label="field name"
                      value={option1}
                      placeholder="Enter option 1"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setOption1(e.target.value);
                      }}
                    />
                  )}
                  {actionType === false ? (
                    <>
                      {extraOptions.map((option, index) => (
                        <div key={index} className="position-relative">
                          <span
                            className=" position-absolute  top-10"
                            style={{ zIndex: "10", right: "5px", top: "12px" }}
                            onClick={() => removeExtraOption(index)}
                          >
                            <i
                              className="bi bi-dash-circle"
                              style={{
                                cursor: "pointer",
                                color: "#FF3333",
                                width: "24px",
                                height: "24px",
                                fontWeight: "bold",
                              }}
                            />
                          </span>
                          <input
                            type="text"
                            className={`form-control form-control-lg  mt-2`}
                            value={option}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {updatedOptions.map((option: any, index: any) => (
                        <div key={index} className="position-relative">
                          <span
                            className=" position-absolute  top-10"
                            style={{ zIndex: "10", right: "5px", top: "12px" }}
                            onClick={() => removeUpdatedOption(index)}
                          >
                            <i
                              className="bi bi-dash-circle"
                              style={{
                                cursor: "pointer",
                                color: "#FF3333",
                                width: "24px",
                                height: "24px",
                                fontWeight: "bold",
                              }}
                            />
                          </span>
                          <input
                            type="text"
                            className={`form-control form-control-lg  mt-2`}
                            value={option}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <div
                  className="js-create-field d-flex form-link"
                  onClick={handleOptionClick}
                >
                  <i className="bi-plus me-1" />
                  <p
                    style={{
                      cursor: "pointer",
                      color: "#128100",
                      fontWeight: "regular",
                    }}
                  >
                    Add extra options
                  </p>
                </div>
              </div>
            )}
            {/* {fieldType === "textfield" && (
              <div
                className="mb-3"
                style={{
                  maxHeight: "200px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div>
                  <label
                    className="form-label"
                    htmlFor="field_name"
                    style={{ color: "#8F9BB2" }}
                  >
                    Define textfield
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg `}
                    name="option1"
                    id="option1"
                    tabIndex={1}
                    aria-label="field name"
                    value={txtField1}
                    placeholder="Enter textfield"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTxtField1(e.target.value);
                    }}
                  />
                  {extraTxtField.map((field, index) => (
                    <div key={index} className="position-relative">
                      <span
                        className=" position-absolute  top-10"
                        style={{ zIndex: "10", right: "5px", top: "12px" }}
                        onClick={() => removeExtraFields(index)}
                      >
                        <i
                          className="bi bi-dash-circle"
                          style={{
                            cursor: "pointer",
                            color: "#FF3333",
                            width: "24px",
                            height: "24px",
                            fontWeight: "bold",
                          }}
                        />
                      </span>
                      <input
                        type="text"
                        className={`form-control form-control-lg  mt-2`}
                        value={field}
                        onChange={(e) => handleTxtFieldInputChange(index, e)}
                      />
                    </div>
                  ))}
                </div>

                <div
                  className="js-create-field d-flex form-link"
                  onClick={handleTxtFieldClick}
                >
                  <i className="bi-plus me-1" />
                  <p
                    style={{
                      cursor: "pointer",
                      color: "#128100",
                      fontWeight: "regular",
                    }}
                  >
                    Add extra fields
                  </p>
                </div>
              </div>
            )} */}

            <div className="mt-3">
              {actionType === false ? (
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={() => {
                    handleExtraFieldClick();
                  }}
                >
                  Create field
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={() => {
                    handleExtraFieldUpdate();
                  }}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="d-grid gap-4">
        <div className="form-check mb-3">
          <input
            type="checkbox"
            id="formHelperCheck1"
            name="is_recurring"
            className="form-check-input"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEditChange("is_recurring", e.target.checked)
            }
          />
          <label
            className="form-check-label text-black"
            htmlFor="formHelperCheck1"
          >
            Use as recurring link
          </label>
          <div className="text-muted">
            <i className="bi bi-info-circle-fill"></i> This means the link can
            be used over and over again.
          </div>
        </div>
        <Input
          name="payment_link_name"
          label="Name of payment page*"
          required
          value={formData.payment_link_name}
          onChange={(e) =>
            handleEditChange("payment_link_name", e.target.value)
          }
          inputClassName={`${
            formData.payment_link_name !== ""
              ? "border border-secondary "
              : ""
          }`}
        />
        <TextArea
          name="note"
          label="Description*"
          required
          value={formData.note}
          onChange={({ target }) => handleEditChange("note", target.value)}
          inputClassName={`${
            formData.note !== "" ? "border border-secondary" : ""
          }`}
        />

        {recurring && (
          <div>
            <label htmlFor="status" className="form-label text-black">
              Payment interval
            </label>
            <Select
              aria-label="Payment interval"
              options={[
                "Hourly",
                "Daily",
                "Weekly",
                "Monthly",
                "Annual",
                "Biannually",
              ].map((el) => ({
                label: el,
                value: el,
              }))}
              onChange={(option) =>
                handleEditChange("interval", option?.value || "")
              }
              className="react-select border border-secondary"
              classNamePrefix="react-select"
              placeholder="Select interval"
            />
          </div>
        )}

        <div className="form-check mt-3">
          <input
            type="checkbox"
            className="form-check-input border border-secondary"
            checked={amount}
            id="checkbox"
            onChange={(e) => setAmount((prev) => !prev)}
          />
          <label className="form-check-label" htmlFor="checkbox">
            I want to specify amount
          </label>
        </div>

        {amount && (
          <div className="row">
            {/* <div className="col-6">
            <div>
              <label htmlFor="status" className="form-label text-black">
                Currency
              </label>
              <Select
                aria-label="currency"
                options={[Currency.GBP, Currency.NGN, Currency.USD].map(
                  (el) => ({
                    label: el,
                    value: el,
                  })
                )}
                onChange={(option) =>
                  handleEditChange("currency", option?.value || "")
                }
                className="react-select"
                classNamePrefix="react-select"
                placeholder="Select currency"
              />
            </div>
          </div> */}
            <Input
              name="amount"
              label="Amount"
              value={formatVal}
              onChange={({ target }) => {
                handleEditChange("amount", target.value);
                setFormatVal(formatCurrency(target.value));
              }}
            />
          </div>
        )}

        <div>
          <label htmlFor="status" className="form-label text-black">
            Who pays the charge*
          </label>
          {/* <Select
            aria-label="Select..."
            options={["Customer", "Merchant"].map((el: string) => ({
              label: el,
              value: el.toLowerCase(),
            }))}
            onChange={(option: any) =>
              handleEditChange("charge_bearer", option?.value || "")
            }
            className="react-select"
            classNamePrefix="react-select"
            placeholder="Select"
            styles={{ ...colourStyles }}
          /> */}
          <select
            className={`form-select form-control-lg ${
              formData?.charge_bearer === "customer"
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formData?.charge_bearer === "merchant"
                ? "border border-secondary "
                : "border border-secondary"
            }`}
            onChange={(e: any) =>
              handleEditChange("charge_bearer", e?.target?.value || "")
            }
          >
            <option>Select...</option>
            {["Customer", "Merchant"].map((i: string) => {
              return (
                <option key={i} value={i.toLowerCase()}>
                  {i}
                </option>
              );
            })}
          </select>
        </div>

        <Input
          name="vat"
          label="VAT % (optional)"
          required={false}
          value={formData.vat}
          onChange={({ target }) => handleEditChange("vat", target.value)}
          // placeholder="%"
        />
        <div className="">
          <p
            className="form-check-label"
            style={{ color: "#128100", cursor: "pointer" }}
            onClick={handleShow}
          >
            <i
              className="bi bi-plus-circle me-2"
              style={{
                cursor: "pointer",
                color: "#128100",
                width: "24px",
                height: "24px",
                fontWeight: "bold",
              }}
            />
            Add extra field
          </p>

          {formData?.extra_fields?.extra_fields?.map((i: any, index: any) => {
            return (
              <div key={index}>
                <p key={i + index}>
                  <i
                    className="bi bi-dash-circle text-danger me-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (formData?.extra_fields) {
                        const result = filteredData(
                          formData?.extra_fields,
                          i?.fieldName
                        );
                        onCollectionUpdate([...result]);
                      }
                    }}
                  ></i>{" "}
                  {i?.fieldName}
                  <span
                    className="badge bg-soft-primary text-primary ms-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setActionType(true);
                      setFieldName(i?.fieldName);
                      setInputValue(i?.fieldName);
                      setFieldType(i?.type);
                      setSelectedOption(i?.type);
                      if (i?.dropdown) {
                        setExtraOptions(i?.dropdown);
                        setUpdatedOptions(i?.dropdown);
                      }
                      handleShow();
                    }}
                  >
                    edit
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default PaymentDetailsForm;
