import React from "react";
import Skeleton from "react-loading-skeleton";
import { formatCurrency } from "../../helpers/index.helper";
import { payoutprops } from "../../utils/interfaces";
import ViewAccountModal from "../modal/viewAccountModal";

function PayoutsMetrics({
  metrics,
  isLoading,
  session,
}: payoutprops): JSX.Element {
  const currency_code: string | any = session?.currency_code;
  const formattedAmount = formatCurrency(`${session?.balance?.balance}`);

  return (
    <>
      <ViewAccountModal />
      <div className="row">
        <div className="d-none d-md-block d-lg-block d-xl-block">
          <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
            <div className="card">
              <div className="card-body text-start">
                <small className="text-cap">Account Balance</small>
                {isLoading ? (
                  <span className="js-counter d-block display-3 text-dark mb-2">
                    <Skeleton height={"100%"} width={200} />
                  </span>
                ) : (
                  <span className="js-counter d-block h4 text-dark mb-2">
                    {currency_code}{" "}
                    {session?.balance?.balance === "0.00"
                      ? session?.balance?.balance
                      : formattedAmount}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-sm-block d-md-none d-lg-none d-xl-none">
          <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
            <div className="card">
              <div className="card-body text-center">
                <small className="">Account balance</small>
                <span className="js-counter d-block display-3 text-dark mb-2">
                  {currency_code}{" "}
                  {session?.balance?.balance === "0.00"
                    ? session?.balance?.balance
                    : formattedAmount}
                </span>
                <span
                  className="link link-primary text-center pt-2"
                  style={{ cursor: "pointer", color: "#2C1DFF" }}
                  data-bs-toggle="modal"
                  data-bs-target="#welcomeMessageModal"
                >
                  View Account details
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PayoutsMetrics;
