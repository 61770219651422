import moengage from "@moengage/web-sdk";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Dropzone from "../../../component/drag-and-drop/Dropzone";
import SpinnerComponent from "../../../component/spinner/Spinner";
import {
  encryptKyc,
  signatureHarsh,
  splitAwsString,
} from "../../../helpers/index.helper";
import { useSession } from "../../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../../hooks/device.hook";
import {
  useBuisnessCompliance,
  useCheckBuisnessRequirements,
  useGetComplianceRequirements,
} from "../../../hooks/kyc/kyc.hook";
import API_SERVICE from "../../../services/services";
import { BUISNESS_TYPE, KYC_LEVELS } from "../../../utils/enums";
import { sessionInterface } from "../../../utils/interfaces";
import Kyc from "../Kyc";

function ProofOfAddressRegistered(): JSX.Element {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionData } = useSession();
  const { compliance } = useBuisnessCompliance();
  const { complianceData } = useGetComplianceRequirements();
  const { deviceInfo } = useUserDeviceInfo();
  const merchantData: sessionInterface = sessionData?.data?.data;

  const requirementsData =
    complianceData?.data?.data?.registerd?.level_two?.account_limits;
  const single_withdrawal = requirementsData?.single_withdrawal;
  const daily_withdrawal = requirementsData?.daily_withdrawal;
  const collection = requirementsData?.collection;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageURL, setImageUrl] = useState("");

  const merchantId: string | any = localStorage.getItem(
    "merchant_compliance_id"
  );
  const message = merchantId && JSON.stringify({ id: merchantId });
  const x_signature = encryptKyc(message, signatureHarsh);
  const { requiredData } = useCheckBuisnessRequirements(
    BUISNESS_TYPE.REGISTERED,
    merchantId,
    x_signature
  );
  const requirementData: any = requiredData?.data?.data;
  const levelTwo: any = requirementData?.level_two;

  const Submit = async (submit: boolean) => {
    setIsLoading(true);
    const x = {
      upload_utility: imageURL,
      submit: submit,
    };
    const payloadSign = encryptKyc(JSON.stringify(x), signatureHarsh);
    const payload = {
      KYC_TYPE: BUISNESS_TYPE.REGISTERED,
      KYC_DATA: {
        upload_utility: imageURL,
        submit: submit,
      },
      KYC_MERCHANT_ID: merchantData?.merchant_compliance_id,
      KYC_LEVEL: KYC_LEVELS.LEVEL_TWO,
      x_signature: payloadSign,
    };
    try {
      const response = await compliance.mutateAsync(payload);
      if (response?.status === 200 && submit === true) {
        setIsLoading(false);
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        navigate(`/merchant`);
      } else {
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    queryClient.refetchQueries();
    queryClient.fetchQuery(
      [
        "business-requirements-check",
        merchantId,
        BUISNESS_TYPE.REGISTERED,
        x_signature,
      ],
      () =>
        API_SERVICE._checkBuisnessComplianceRequirements(
          BUISNESS_TYPE.REGISTERED,
          `${merchantId}`,
          x_signature
        )
    );
    if (levelTwo) {
      setImageUrl(levelTwo?.upload_utility);
    }
  }, [levelTwo, merchantId, queryClient, x_signature]);

  const SaveEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier2_saved: true,
      platform: deviceInfo?.userAgent,
    });
  };

  const SubmitEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier2_submitted: true,
      platform: deviceInfo?.userAgent,
    });
  };

  return (
    <Kyc activeTab="proof_of_address" activeMenu="registered">
      <div
        id="basicVerStepDetails"
        className="card"
        style={{ minHeight: "15rem" }}
      >
        <div className="card kyc-card-top">
          <div
            className="row align-items-start mb-0
           pb-2 mt-2"
          >
            <div className="col-sm mb-2 mb-sm-0">
              <h2 className="page-header-title text-white pb-3">Tier 2</h2>
              {/* <i class="bi bi-circle"></i> */}
            </div>

            <div className="col-sm-auto">
              {levelTwo?.submit === true ? (
                <>
                  {/* <button type="button" className="btn  kyc-save-btn" hidden>
                    Request Edit
                  </button> */}
                </>
              ) : (
                <button
                  type="button"
                  className="btn  kyc-save-btn"
                  onClick={() => {
                    Submit(false);
                    SaveEvent("KYC_SAVE_REG_BIZ_TIER2");
                  }}
                >
                  Save changes{" "}
                  {isLoading && (
                    <>
                      &nbsp;{" "}
                      <span className="align-middle">
                        <SpinnerComponent size="sm" />
                      </span>
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="">
            <ul className="ps-0 w-100 w-md-75 w-lg-75 list-unstyled">
              <li className="text-white listStyleDisc w-100">
                <span className="" style={{ fontSize: "13px" }}>
                  Withdrawal limit
                </span>
                <span
                  className="float-end text-capitalize"
                  style={{ fontSize: "13px" }}
                >
                  {single_withdrawal}
                </span>
              </li>
              <li className="text-white listStyleDisc py-1">
                <span className="" style={{ fontSize: "13px" }}>
                  Daily withdrawal limit{" "}
                </span>

                <span
                  className="float-end text-capitalize"
                  style={{ fontSize: "13px" }}
                >
                  {daily_withdrawal}
                </span>
              </li>
              <li className="text-white listStyleDisc">
                <span className="" style={{ fontSize: "13px" }}>
                  Maximum Deposit limit{" "}
                </span>

                <span
                  className="float-end text-capitalize"
                  style={{ fontSize: "13px" }}
                >
                  {collection}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="card-body active">
          <div
            className="w-100 w-md-75 w-lg-75 content-space-t-0 content-space-t-lg-1 content-space-b-1 mx-auto"
            style={{ maxWidth: "50rem" }}
          >
            {levelTwo ? (
              <div className="row mb-4 mt-4">
                <div className="col mx-auto mb-3">
                  <label className="form-label" htmlFor="firstname">
                    Upload Proof of Address*
                  </label>
                  <Dropzone
                    onFileExport={(url: any) => {
                      if (url) {
                        setImageUrl(url);
                      }
                    }}
                    isDisabled={levelTwo?.submit === true ? true : false}
                    fileName={splitAwsString(
                      levelTwo?.upload_utility || imageURL
                    )}
                  />
                </div>
              </div>
            ) : (
              <div className="row mb-4 mt-4">
                <div className="col mx-auto mb-3">
                  <label className="form-label" htmlFor="firstname">
                    Upload Proof of Address*
                  </label>
                  <Dropzone
                    onFileExport={(url: any) => {
                      if (url) {
                        setImageUrl(url);
                      }
                    }}
                    isDisabled={levelTwo?.submit === true ? true : false}
                    fileName={splitAwsString(
                      levelTwo?.upload_utility || imageURL
                    )}
                  />
                </div>
              </div>
            )}
          </div>

          {/* LG SCREENS */}
          {levelTwo?.submit === true ? null : (
            <>
              <div className="d-none d-md-block d-lg-block pb-3">
                <div className="d-flex align-items-center justify-content-end mt-auto">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (imageURL || levelTwo?.upload_utility) {
                        Submit(true);
                        SubmitEvent("KYC_SUBMIT_REG_BIZ_TIER2");
                      }
                    }}
                  >
                    Submit <i className="bi bi-check2 text-white small"></i>
                  </button>
                </div>
              </div>
              {/* SM SCREENS */}
              <div className="d-sm-block d-md-none d-lg-none">
                <div className="d-flex align-items-center justify-content-end mt-auto">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (imageURL) {
                        Submit(true);
                        SubmitEvent("KYC_SUBMIT_REG_BIZ_TIER2");
                      }
                    }}
                  >
                    Submit <i className="bi bi-check2 text-white small"></i>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Kyc>
  );
}

export default ProofOfAddressRegistered;
