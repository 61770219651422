import React, { Fragment, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InvoiceModal from "../../component/modal/InvoiceModal";
import PaginationComponent from "../../component/pagination/pagination";
import Table from "../../component/table/Table";
import { invoice_column } from "../../configs/dataTable.config";

const Invoices = (): JSX.Element => {
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>("");
  const [show, setShow] = useState(false);

  const [activeItem, setActiveItem] = useState<number>(0);

  const totalItems = 0;

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleDropdownClick = (event: any) => {
    event.stopPropagation();
  };

  const columns: any = useMemo(
    () => [
      ...invoice_column,
      {
        Header: "",
        columnId: 8,
        accessor: "actions",
        Cell: ({ row }: any): JSX.Element => {
          const id = row?.original?.id;
          return (
            <>
              <Dropdown onClick={handleDropdownClick}>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="btn btn-white btn-sm"
                  style={{ background: "#fff" }}
                >
                  <i className="bi-three-dots" style={{ color: "#000" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as={"button"}
                    type="button"
                    onClick={() => {
                      navigate(`/merchant/invoices/details/${id}`, {
                        state: row?.original,
                      });
                    }}
                  >
                    View
                  </Dropdown.Item>
                  <Dropdown.Item as={"button"} type="button">
                    Download
                  </Dropdown.Item>
                  <Dropdown.Item as={"button"} type="button">
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <Fragment>
      <InvoiceModal show={show} handleClose={() => setShow(false)} />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Invoices</h1>
          </div>

          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShow(true)}
            >
              Create invoice <i className="bi bi-plus-lg me-1"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header card-header-content-md-between">
          <div className="mb-2 mb-md-0">
            <form onSubmit={(e) => e.preventDefault()}>
              <div
                className="input-group input-group-merge input-group-flush"
                style={{ minWidth: "160%", maxWidth: "100%" }}
              >
                <div className="input-group-prepend input-group-text">
                  <i className="bi-search"></i>
                </div>
                <input
                  id="datatableSearch"
                  type="search"
                  value={query}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Search ..."
                  aria-label="Search users"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="table-responsive datatable-custom position-relative">
          <Table
            columns={columns}
            data={[
              {
                email: "michael@email.com",
                amount: "NGN10,500",
                createdAt: "12-03-2023 / 10:45am",
                invoideId: "12345",
                id: "123456",
              },
            ]}
            isFetching={false}
            navigateTo="/merchant/invoices/details/"
          />
        </div>

        <div className="card-footer">
          <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
            <div className="col-sm mb-2 mb-sm-0"></div>

            <div className="col-sm-auto">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <PaginationComponent
                  totalItems={totalItems}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Invoices;
