import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customer_invoice_column } from "../../configs/dataTable.config";
import DropDownWrapper from "../DropDownWrapper";
import Table from "../table/Table";

function CustomerInvoice() {
  const navigate = useNavigate();
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const columns: any = useMemo(() => [...customer_invoice_column], []);

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter: any) => ({
      ...filter,
      [name]: value,
    }));
  };

  return (
    <div className="mt-3">
      <div className="card">
        <div className="card-header card-header-content-md-between">
          <div className="mb-2 mb-md-0">
            <h1 className="page-header-title">Invoice history</h1>
          </div>

          <div className="d-grid d-sm-flex gap-2">
            <DropDownWrapper
              action={
                <button
                  type="button"
                  className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                >
                  <i className="bi bi-filter"></i> Filter
                </button>
              }
              className="d-none d-md-block d-lg-block"
            >
              <div className="p-3">
                <label className="text-body">Date Range</label>
                <div className="row mb-4">
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="start_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button className="btn btn-white btn-sm">Close</button>
                  <button
                    className="btn btn-primary btn-sm"
                    // onClick={filterData}
                    style={{ cursor: "pointer" }}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </DropDownWrapper>
            {/* SM */}
            <DropDownWrapper
              action={
                <button
                  type="button"
                  className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                >
                  <i className="bi bi-filter"></i> Filter
                </button>
              }
              styleObject={{ left: "-34%" }}
              className="d-block d-md-none d-lg-none"
            >
              <div className="p-3">
                <label className="text-body">Date Range</label>
                <div className="row mb-4">
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="start_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button className="btn btn-white btn-sm">Close</button>
                  <button
                    className="btn btn-primary btn-sm"
                    // onClick={filterData}
                    style={{ cursor: "pointer" }}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </DropDownWrapper>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                aria-expanded="false"
                onClick={() => navigate("/merchant/invoices/create_invoice")}
              >
                <i className="bi bi-plus-lg"></i> Create invoice
              </button>
            </div>
          </div>
        </div>

        <div className="table-responsive datatable-custom position-relative">
          <Table
            columns={columns}
            data={[]}
            isFetching={false}
            navigateTo={`/merchant/customers/details/`}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomerInvoice;
