import React, { useState } from "react";

function AdminLogin() {
  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState({ email: "", password: "" });

  const toggleVisibility = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: `url('../../../assets/svg/components/card-6.svg')`,
        }}
      >
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon
              fill="#fff"
              points="0,273 1921,273 1921,0 "
              style={{ fill: "#fff" }}
            />
          </svg>
        </div>
      </div>
      ​
      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5" href="index.html">
          <img
            className="zi-2"
            src="../../../assets/svg/logos/poolerlogo2.svg"
            alt="Description"
            style={{ width: "8rem" }}
          />
        </a>
        ​
        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          <div className="card card-lg mb-5">
            <div className="card-body">
              <form
                className="js-validate needs-validation"
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
              >
                <div className="text-center">
                  <div className="mb-5">
                    <h1 className="display-5">Sign in</h1>
                    <p>
                      Don't have an account yet?{" "}
                      <a className="link link-primary" href="/admin/signup">
                        Sign up here
                      </a>
                    </p>
                  </div>
                  ​
                  <div className="d-grid mb-4">
                    <button className="btn btn-white btn-lg">
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          className="avatar avatar-xss me-2"
                          src="/assets/svg/brands/google-icon.svg"
                          alt="Description"
                        />
                        Sign in with Google
                      </span>
                    </button>
                  </div>
                  ​<span className="divider-center text-muted mb-4">OR</span>
                </div>
                ​
                <div className="mb-4">
                  <label className="form-label" htmlFor="signinSrEmail">
                    Your email
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    name="email"
                    id="signinSrEmail"
                    value={formData.email}
                    tabIndex={1}
                    placeholder="email@address.com"
                    aria-label="email@address.com"
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <span className="invalid-feedback">
                    Please enter a valid email address.
                  </span>
                </div>
                <div className="mb-4">
                  <label
                    className="form-label w-100"
                    htmlFor="signupSrPassword"
                    tabIndex={0}
                  >
                    <span className="d-flex justify-content-between align-items-center">
                      <span>Password</span>
                      <a
                        className="form-label-link mb-0"
                        href="authentication-reset-password-basic.html"
                      >
                        Forgot Password?
                      </a>
                    </span>
                  </label>
                  ​
                  <div
                    className="input-group input-group-merge"
                    data-hs-validation-validate-class
                  >
                    <input
                      type={passwordOpen ? "text" : "password"}
                      className="js-toggle-password form-control form-control-lg"
                      name="password"
                      id="signupSrPassword"
                      placeholder="8+ characters required"
                      aria-label="8+ characters required"
                      required
                      autoComplete="off"
                      value={formData.password}
                      onChange={handleChange}
                      minLength={8}
                    />
                    <button
                      id="changePassTarget"
                      className="input-group-append input-group-text"
                    >
                      {!eyeOpen ? (
                        <i
                          id="changePassIcon"
                          className="bi-eye"
                          onClick={() => {
                            toggleVisibility();
                            setEyeOpen(!eyeOpen);
                          }}
                        />
                      ) : (
                        <i
                          className="bi bi-eye-slash"
                          onClick={() => {
                            toggleVisibility();
                            setEyeOpen(!eyeOpen);
                          }}
                        />
                      )}
                    </button>
                  </div>
                  ​
                  <span className="invalid-feedback">
                    Please enter a valid password.
                  </span>
                </div>
                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="termsCheckbox"
                  />
                  <label className="form-check-label" htmlFor="termsCheckbox">
                    Remember me
                  </label>
                </div>
                ​
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary btn-lg">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </main>
  );
}

export default AdminLogin;
