import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { formprops } from "../../../utils/interfaces";

function PersonalFields({ formValues, handleChange }: formprops) {
  return (
    <div
      className="w-100 content-space-t-0 content-space-t-lg-2 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="signinSrEmail">
            Full name *
          </label>
          <input
            type="text"
            className="form-control "
            name="full_name"
            id="full_name"
            tabIndex={1}
            value={formValues?.full_name}
            placeholder="full name"
            aria-label="last name"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please enter a buissness.</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="signinSrEmail">
            Designation *
          </label>
          <input
            type="text"
            className="form-control "
            name="designation"
            id="designation"
            tabIndex={1}
            value={formValues?.designation}
            placeholder="e.g. Managing Director"
            aria-label="last name"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">Please enter a designation.</span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <label className="form-label" htmlFor="email">
            Your email
          </label>
          <input
            type="email"
            className="form-control "
            name="email"
            id="email"
            tabIndex={1}
            value={formValues?.email}
            placeholder="email@address.com"
            aria-label="email@address.com"
            onChange={handleChange}
            required
          />
          <span className="invalid-feedback">
            Please enter a valid email address.
          </span>
        </div>
        <div className="col">
          <label className="form-label" htmlFor="mobile_number">
            Phone number
          </label>
          <PhoneInput
            country={"ng"}
            value={formValues?.mobile_number}
            onChange={(phoneNumber: any) =>
              handleChange({
                target: { name: "mobile_number", value: phoneNumber },
              })
            }
            inputClass="form-control form-control-lg  w-100"
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalFields;
