import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { initQueryOptions, parseResponse } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import { RequestMethod } from "../../utils/enums";
import { instance } from "../../utils/instance";

export const usePayLinkInfo = (id?: string | number) => {
  const { data, error, isLoading, isSuccess, isError, refetch } = useQuery(
    ["transactions", "transaction", id],
    () => API_SERVICE._getPayLinkInfo(id),
    initQueryOptions({
      enabled: !!id,
    })
  );
  return { data, error, isError, isLoading, isSuccess, refetch };
};

// LIST && SORTING PAYMENT LINK
export const usePayLinks = (
  type?: string,
  page?: number,
  payment_link_name?: string
) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["payment-link-data", type, page, payment_link_name],
      () => API_SERVICE._getPayLinks(type, page, payment_link_name),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

// LIST && SORT ONE TIME PAYMENT LINK
export const useOneTimePayLinks = (
  type?: string,
  page?: number,
  payment_link_name?: string
) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["one-time-payment-link-data", type, page, payment_link_name],
      () => API_SERVICE._getPayLinks(type, page, payment_link_name),
      initQueryOptions()
    );
  const oneTimeData = data;
  const loadOneTimePayLink = isLoading;
  const fetchOneTimePayLink = isFetching;
  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
    isFetching,
    oneTimeData,
    loadOneTimePayLink,
    fetchOneTimePayLink,
  };
};

// LIST & SORT RECURRING PAYMENT LINK
export const useRecurringPayLinks = (
  type?: string,
  page?: number,
  payment_link_name?: string
) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["recurring-payment-link-data", type, page, payment_link_name],
      () => API_SERVICE._getPayLinks(type, page, payment_link_name),
      initQueryOptions()
    );
  const recurringData = data;
  const loadRecurringPayLinks = isLoading;
  const fetchRecurringPayLinks = isFetching;
  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
    isFetching,
    recurringData,
    loadRecurringPayLinks,
    fetchRecurringPayLinks,
  };
};

// MUTATE PAYMENT LINK QEURY TO HANDLE FILTER
export const usePayLinksMutation = () => {
  const filter_links = useMutation((filterData: any) => {
    if (filterData.download === "all") {
      return instance({
        url: `/merchant/payment/recurring/link/${filterData.id}?start_date=${filterData.start_date}&end_date=${filterData.end_date}`,
        method: RequestMethod.GET,
      });
    } else {
      return instance({
        url: `/merchant/payment?start_date=${filterData.start_date}&end_date=${filterData.end_date}`,
        method: RequestMethod.GET,
      });
    }
  });
  return { filter_links };
};

// CANCEL PAYMENT LINK
export const useCancelPaymentLink = () => {
  const cancel_link = useMutation(
    (id: string) => {
      return instance({
        url: `/merchant/payment/cancel/${id}`,
        method: RequestMethod.POST,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success("payment link cancelled successfully");
      },
      onError: (error: any) => {
        toast.success("there was en error");
      },
    }
  );
  return { cancel_link };
};

// CREATE PAYMENT LINK
export const useCreatePayLink = () => {
  const create_link = useMutation(
    (data: any) => {
      return instance({
        url: `/merchant/payment`,
        method: RequestMethod.POST,
        data: data,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success("payment link created successfully");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { create_link };
};

export const useExpirePayLink = () => {
  const expireHandler = useMutation(
    (id: string | number | any) => API_SERVICE._expirePayLink(id),
    parseResponse()
  );
  return { expireHandler };
};

export const useGetSinglePayLinkTransaction = (id?: string, page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["payment-link-transactions", id, page],
      () => API_SERVICE._getSinglePayLinkTransaction(id, page),
      initQueryOptions()
    );

  const link_tx = data;
  const loading = isLoading;
  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
    isFetching,
    link_tx,
    loading,
  };
};

export const useSavePayLinkAsTemplate = () => {
  const save_template = useMutation(
    (id?: string) => {
      return instance({
        url: `/merchant/payment/set_as_template/${id}`,
        method: RequestMethod.POST,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success("Payment Link Set As Template Succesfully");
      },
      onError: (error: any) => {
        toast.success("there was en error");
      },
    }
  );
  return { save_template };
};

export const useViewTemplates = (page: number, limit: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      ["payment-link-template", page, limit],
      () => API_SERVICE._getPaymentTemplate(page, limit),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};
