import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../../../component/drag-and-drop/dropzone.css";
import {
  compressFile,
  encryptKyc,
  mode,
  signatureHarsh,
  truncate,
} from "../../../helpers/index.helper";

type Props = {
  onFileExport?: any;
  isDisabled?: boolean;
  fileName?: string;
};

function CloudIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#2C1DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 16.242A4.5 4.5 0 016.08 8.02a6.002 6.002 0 0111.84 0A4.5 4.5 0 0120 16.242M8 16l4-4m0 0l4 4m-4-4v9"
      ></path>
    </svg>
  );
}

function TrashMobileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="10" fill="#FF2626"></circle>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 7L7 17M7 7l10 10"
      ></path>
    </svg>
  );
}

function Upload({
  file,
  setFile,
  isDisabled,
  progress,
  files,
  setFiles,
  size,
}: any): JSX.Element {
  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles) {
        const acceptedFilesMap = acceptedFiles.map((file: any) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );
        setFiles(acceptedFilesMap);
        const formData = new FormData();
        const compressedFile = await compressFile(acceptedFiles[0]);

        formData.append("file", acceptedFiles[0]);

        setFile(acceptedFiles[0]);
      }
    },
    [isDisabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 50000000,
    accept: {
      "application/vnd.ms-excel": [".xlsx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  return (
    <form>
      <div
        {...getRootProps({
          className:
            "dropzone-component-upload pt-3 pb-5 d-flex flex-column justify-content-start",
        })}
      >
        <div className="w-100 py-2 px-3" style={{ fontSize: 16 }}>
          Upload File
        </div>
        <br />
        <input {...getInputProps()} />
        <div style={{ height: 30, width: 30 }}>
          <CloudIcon />
        </div>
        <div className="mx-auto text-center">
          {isDragActive ? (
            <p className="link link-secondary">Drag and drop files here</p>
          ) : (
            <h5 className="text-pooler-blue text-center pt-2 click">
              Click to upload or drag and drop
            </h5>
          )}
          <span className="file-size">Please upload your recipient list in xlsx format and limit each upload to 200 recipients.</span>
          <br />
          <br />
          {isDisabled === true && <p style={{ color: "#000" }}>{file?.name}</p>}
          {!isDisabled &&
            (files?.length > 0 ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  style={{
                    padding: 10,
                    border: "1px solid #CBD1EC",
                    borderRadius: 8,
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                  }}
                  id="progress"
                  className="position-relative"
                >
                  <span style={{ color: "#8F9BB2", width: "25%" }}>
                    {truncate(files?.[0]?.name, 10)}
                  </span>
                  <div
                    style={{
                      backgroundColor: "#E9F2FF",
                      borderRadius: 40,
                      width: "85%",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1F7AFF",
                        width: `${progress}%`,
                        color: "transparent",
                      }}
                    >
                      <span>progress</span>
                    </div>
                  </div>
                  {size?.[0] <= 998 && (
                    <div
                      className="position-absolute"
                      style={{ right: -11, top: -13, cursor: "pointer" }}
                      onClick={() => {
                        if (files.length > 0) {
                          let fileData = [...files];
                          fileData.pop();
                          setFiles(fileData);
                          setFiles(null);
                        }
                      }}
                    >
                      <TrashMobileIcon />
                    </div>
                  )}
                </div>
                {size?.[0] > 998 && (
                  <>
                    &emsp;
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        if (files.length > 0) {
                          let fileData = [...files];
                          fileData.pop();
                          setFiles(fileData);
                          setFiles(null);
                        }
                      }}
                    >
                      <i className="bi bi-trash3"></i>
                    </button>
                  </>
                )}
              </div>
            ) : (
              <p style={{ color: "#8F9BB2" }}>No file selected</p>
            ))}
        </div>
      </div>
    </form>
  );
}

export default Upload;
