import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../component/pagination/pagination";
import { useViewTemplates } from "../../hooks/payment-link/index.hook";
import { useQueryClient } from "react-query";
import API_SERVICE from "../../services/services";
import { payment_template_column } from "../../configs/dataTable.config";
import Table from "../../component/table/Table";

function Templates(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<number>(0);
  const { data, isLoading } = useViewTemplates(activeItem, 10);

  useEffect(() => {
    queryClient.fetchQuery(["payment-link-template", activeItem + 1], () =>
      API_SERVICE._getPaymentTemplate(activeItem, 10)
    );
  }, [queryClient, activeItem]);

  const tableData = data?.data?.data?.items;
  const totalItems = data?.data?.data?.totalPages;

  const columns = useMemo(
    () => [
      ...payment_template_column,
      {
        Header: "",
        columnId: 5,
        accessor: "action",
        Cell: ({ row }: any): JSX.Element => {
          const payload: any = row?.original;
          return (
            <>
              <button
                className="btn btn-white btn-sm"
                onClick={() => {
                  navigate("/merchant/business/one-time-link", {
                    state: payload,
                  });
                }}
              >
                Use
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <main>
      <div>
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col-sm mb-2 mb-sm-0" style={{ cursor: "pointer" }}>
              <div className="d-sm-flex align-items-sm-center">
                <h1 className="page-header-title">
                  <i
                    className="bi bi-arrow-left me-2"
                    style={{ fontSize: "#000", cursor: "pointer" }}
                    onClick={() => navigate(`/merchant/business/pay-link`)}
                  ></i>
                  Link templates
                </h1>
              </div>
            </div>
          </div>
        </div>

        {/* TABLE */}
        <div className="card">
          <Table
            columns={columns}
            data={tableData !== undefined ? tableData : []}
            isFetching={isLoading}
          />

          <div className="card-footer">
            <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
              <div className="col-sm mb-2 mb-sm-0"></div>

              <div className="col-sm-auto">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  {totalItems > 1 && (
                    <PaginationComponent
                      totalItems={totalItems}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Templates;
