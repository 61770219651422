/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Table from "../../../component/table/Table";
import { latest_txn_column } from "../../../configs/dataTable.config";
import { useGetAccountTransaction } from "../../../hooks/accounts/account.hook";
// import { PATH_NAMES } from "../../../constants/index.constant";
import Skeleton from "react-loading-skeleton";
import CopyButton from "../../../component/Copy";
import BackBtn from "../../../component/back-btn";
import { PATH_NAMES } from "../../../constants/index.constant";
import { formatAmount } from "../../../helpers/index.helper";
import useResize from "../../../hooks/resize.hook";
import { useSession } from "../../../hooks/auth/auth.hook";
import { sessionInterface } from "../../../utils/interfaces";

function AccountDetails(): JSX.Element {
  const location = useLocation();
  const { data: sessionData } = useSession();
  const { size } = useResize();
  const merchant_data: sessionInterface = sessionData?.data?.data;
  const params: any = useParams();
  const navigate = useNavigate();
  const { account_txn, isLoading } = useGetAccountTransaction(
    `${location?.state?.account_no}`
  );
  const data: any = account_txn?.data?.data?.items;
  const columns: any = React.useMemo(
    () => [
      ...latest_txn_column(),
      // {
      //   Header: "Action",
      //   columnId: 8,
      //   accessor: "actions",
      //   Cell: ({ row }: any): JSX.Element => {
      //     const id = row?.original?.id;
      //     return (
      //       <div className="btn-group" role="group">
      //         <button
      //           className="btn btn-white btn-sm"
      //           onClick={() =>
      //             navigate(`/${PATH_NAMES.transactions}/details/${id}`)
      //           }
      //         >
      //           <i className="bi-eye"></i> View
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  const metadata_: any =
    location?.state?.metadata_ !== null
      ? location?.state?.metadata_?.metadata
      : [];

  return (
    <>
      <div className="page-header">
        {size?.[0] <= 1024 ? (
          <div className="d-flex flex-row align-items-baseline">
            <i
              className="bi bi-chevron-left text-dark"
              style={{ fontSize: "14px" }}
              onClick={() => navigate(`/merchant/${PATH_NAMES.accounts}`)}
            ></i>
            <h1 className="create-virtual-acc text-center mx-auto">
              {location?.state?.account_name}
            </h1>
          </div>
        ) : (
          <div className="row align-items-center">
            <div className="col-sm mb-2 mb-sm-0">
              <h3 className="page-header-title">
                <BackBtn />
                {location?.state?.account_name}
              </h3>{" "}
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-lg-4">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h5>Account details</h5>
            </div>
            <div className="card-body">
              <div className="list-group list-group-flush list-group-no-gutters">
                <div className="list-group-item">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <span className="d-block">Full Name</span>
                        </div>
                        <div className="col-auto">
                          <h6>
                            {location?.state?.firstname}{" "}
                            {location?.state?.lastname}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="list-group-item">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <span className="d-block">Email</span>
                        </div>

                        <div className="col-auto">
                          <h6>{location?.state?.email}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {location?.state?.client_type !== "disposable" && (
                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <div className="col">
                            <span className="d-block">Phone Number</span>
                          </div>
                          <div className="col-auto">
                            <h6>{location?.state?.mobile_number}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="list-group-item">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <span className="d-block">Account Number</span>
                        </div>

                        <div className="col-auto">
                          <h6>
                            <CopyButton text={location?.state?.account_no} />
                            &emsp;{location?.state?.account_no}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="list-group-item">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <span className="d-block">Account Name</span>
                        </div>

                        <div className="col-auto">
                          <h6>
                            <CopyButton text={location?.state?.account_name} />
                            &emsp;{location?.state?.account_name}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="list-group-item">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <span className="d-block">Account Type</span>
                        </div>

                        <div className="col-auto">
                          <h6>{location?.state?.client_type}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="list-group-item">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-2">
                      <div className="row align-items-start">
                        <div className="col">
                          <span className="d-block">Banks</span>
                        </div>

                        <div className="col-auto">
                          <h6
                            className="mb-0"
                            style={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {[
                              merchant_data?.primary_bank?.name,
                              ...merchant_data?.secondary_banks?.map(
                                (i: any) => i.name
                              ),
                            ].map((b) => (
                              <span>{b}</span>
                            ))}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* META DATA */}
                {metadata_ &&
                  metadata_?.length > 0 &&
                  metadata_?.map((i: any, index: any) => {
                    return (
                      <div className="list-group-item" key={index}>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              <div className="col">
                                <span className="d-block">{i?.fieldName}</span>
                              </div>
                              <div className="col-auto">
                                <h6>{i?.fieldValue}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8">
          <div
            className="card mb-3 mb-lg-5"
            hidden={size?.[0] <= 1024 ? true : false}
          >
            <div className="card-header card-header-content-sm-between">
              <div className="d-grid gap-2 mb-2 mb-sm-0">
                <h4 className="card-header-title">Recent transactions</h4>
              </div>
            </div>

            <div className="table-responsive datatable-custom">
              {/* TABLE HERE */}
              <Table
                columns={columns}
                data={data !== undefined ? data : []}
                isFetching={isLoading}
              />
            </div>

            <div className="card-footer">
              <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                <div className="col-sm-auto">
                  <div className="d-flex justify-content-center justify-content-sm-end"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="card h-100" hidden={size?.[0] <= 1024 ? false : true}>
            <ul className="list-group list-group-numberedd">
              <li className="list-group-item d-flex align-items-center justify-content-between">
                <h4 className="card-header-title">Recent transactions</h4>
              </li>
              {isLoading ? (
                <li className="list-group-item">
                  {[1, 2, 3, 4, 5, 6].map(() => {
                    return <Skeleton height={30} />;
                  })}
                </li>
              ) : (
                <>
                  {data?.map((txn_data: any) => {
                    return (
                      <>
                        <li
                          className="list-group-item d-flex justify-content-between align-items-start"
                          style={{ cursor: "pointer" }}
                        >
                          {txn_data?.transaction_type === "transfer" ||
                          txn_data?.transaction_type === "payout" ? (
                            <i className="bi bi-arrow-up-right-circle text-danger"></i>
                          ) : null}

                          {txn_data?.transaction_type === "collection" && (
                            <i
                              className="bi bi-arrow-up-left-circle text-success"
                              style={{ rotate: "270deg" }}
                            ></i>
                          )}

                          {txn_data?.transaction_type === "collection" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.sender_details?.account_number} -{" "}
                                {txn_data?.sender_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "transfer" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number} -{" "}
                                {txn_data?.reciever_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "payout" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number} -{" "}
                                {txn_data?.reciever_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          <span className="">
                            {formatAmount(txn_data?.amount)}
                          </span>
                        </li>
                      </>
                    );
                  })}
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountDetails;
