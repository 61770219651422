import React from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import SpinnerComponent from "../spinner/Spinner";

function CreateCustomerModal({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}): JSX.Element {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Customer</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="firstname">
              First name*
            </label>
            <input
              type="text"
              className={`form-control border border-secondary`}
              name="firstname"
              id="firstname"
              tabIndex={1}
              placeholder="Enter first name"
              aria-label="firstname"
              required
            />
            <span className="invalid-feedback">
              Please enter valid first name.
            </span>
          </div>
          <div className="col">
            <label className="form-label" htmlFor="lastname">
              Last name*
            </label>
            <input
              type="text"
              className={`form-control border border-secondary`}
              name="lastname"
              id="lastname"
              tabIndex={1}
              placeholder="Enter last name"
              aria-label="lastname"
              required
            />
            <span className="invalid-feedback">please enter last name</span>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="email">
              Email*
            </label>
            <input
              type="email"
              className={`form-control  border border-secondary`}
              name="email"
              id="email"
              tabIndex={1}
              placeholder="e.g michael@email.com"
              aria-label="email@address.com"
              autoComplete="off"
              required
            />
            <span className="invalid-feedback">
              Please enter a valid email address.
            </span>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="phone_number">
              Phone number*
            </label>
            <PhoneInput
              country={"ng"}
              inputClass={`form-control form-control-lg w-100 border border-secondary`}
              inputStyle={{ fontFamily: "BricolageGrotesque" }}
              containerStyle={{ fontFamily: "BricolageGrotesque" }}
            />
          </div>
        </div>

        <div className="w-75 mx-auto text-center mt-4 pt-3">
          <button
            type="button"
            className="btn btn-primary w-75 mx-auto text-center"
          >
            Create Customer
            {false && <SpinnerComponent variant="light" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CreateCustomerModal;
