import React from "react";
import { useNavigate } from "react-router-dom";
import useResize from "../../hooks/resize.hook";
import "./kyc.css";

function Kycheader(): JSX.Element {
  const navigate = useNavigate();
  const { size } = useResize();
  return (
    <div
      className="kyc-page-header"
      style={{ borderBottom: "0px solid #fff !important" }}
    >
      {size?.[0] <= 998 ? (
        <>
          <div className="d-flex flex-row align-items-baseline">
            <i
              className="bi bi-chevron-left text-dark"
              style={{ fontSize: "14px" }}
              onClick={() => navigate("/merchant/profile#content")}
            ></i>
            <h1 className="create-virtual-acc text-center mx-auto page-header-title">
              Compliance & KYC
            </h1>
          </div>
        </>
      ) : (
        <div className="row align-items-center">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Compliance & KYC</h1>
            <p className="page-header-text">
              Here's what's happening with your account today.
            </p>
          </div>
        </div>
      )}

      {/* {mode === "customer.test" && (
        <div className="d-none d-md-block d-lg-none mt-2" hidden>
          <ul className="step step-md step-centered pt-2">
            <li
              className={`step-item ${
                path === "/merchant/kyc/buisness_information"
                  ? "active text-primary"
                  : ""
              }`}
              onClick={() => {
                navigate(
                  `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/buisness_information?stage=1`
                );
              }}
            >
              <div className="step-content-wrapper">
                <span className="step-icon step-icon-soft-primary">1</span>
                <p
                  className={`step-text ${
                    path === "/merchant/kyc/buisness_information"
                      ? "active text-primary"
                      : ""
                  }`}
                >
                  Business Information
                </p>
              </div>
            </li>

            <li
              className={`step-item ${
                path === "/merchant/kyc/personal_information"
                  ? "active text-primary"
                  : ""
              }`}
              onClick={() => {
                navigate(
                  `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/personal_information`
                );
              }}
            >
              <div className="step-content-wrapper">
                <span className="step-icon step-icon-soft-primary">2</span>
                <p
                  className={`step-text ${
                    path === "/merchant/kyc/personal_information"
                      ? "active text-primary"
                      : ""
                  }`}
                >
                  Primary Contact
                </p>
              </div>
            </li>

            <li
              className={`step-item ${
                path === "/merchant/kyc/director_information"
                  ? "active text-primary"
                  : ""
              }`}
              onClick={() => {
                navigate(
                  `${PATH_NAMES.client_home}/${PATH_NAMES.kyc}/director_information`
                );
              }}
            >
              <div className="step-content-wrapper">
                <span className="step-icon step-icon-soft-primary">3</span>
                <p
                  className={`step-text ${
                    path === "/merchant/kyc/director_information"
                      ? "active text-primary"
                      : ""
                  }`}
                >
                  Director's Information
                </p>
              </div>
            </li>
          </ul>
        </div>
      )} */}
    </div>
  );
}

export default Kycheader;
