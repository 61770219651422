import { Modal } from "react-bootstrap";
import React from "react";
import { ReactComponent as PendingIcon } from "../../assets/processing.svg";

const SuccessfulPurchaseModal = ({
  show,
  handleClose,
  handleReset,
  handleSaveBeneficiary,
  title = "Airtime",
  canSaveBeneficiary,
  size,
}: {
  size: any;
  show: boolean;
  handleClose: () => void;
  handleReset: () => void;
  handleSaveBeneficiary: () => void;
  title?: string;
  canSaveBeneficiary: boolean;
}) => {
  return (
    <React.Fragment>
      {show && (
        <Modal show={show} onHide={handleClose} centered  contentClassName="top-up-modal">
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                className="text-black"
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textAlign: "center"
                }}
              >
                <strong>{title} purchase in progress.</strong><br /><small>You can check the status in your top up history shortly.</small>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "22px",
                  marginTop: "42px",
                }}
              >
                <PendingIcon />
              </div>
              {canSaveBeneficiary && (
                <div
                  className="w-100"
                  style={{
                    marginTop: "34px",
                  }}
                >
                  <button
                    type={"button"}
                    className={`btn w-100 d-flex items-center justify-content-center text-black bg-white fw-bolder`}
                    disabled={false}
                    onClick={() => {
                      handleSaveBeneficiary();
                    }}
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #CBD1EC",
                    }}
                  >
                    Save beneficiary
                  </button>
                </div>
              )}
              <div
                className="w-100"
                style={{
                  marginBottom: "2px",
                  marginTop: "14px",
                }}
              >
                <button
                  type={"button"}
                  className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                  disabled={false}
                  onClick={() => {
                    handleClose();
                  }}
                  style={{
                    borderRadius: "8px",
                    background: "#2C1DFF",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default SuccessfulPurchaseModal;
