import React, { Dispatch, SetStateAction } from "react";
import "./style.css"; // Import the CSS file for styling

type TabItem = string | { label: string; key: string; onTabClick?: () => void; empty?: boolean };

interface TabProps {
  tabs: TabItem[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const Tab = ({
  tab,
  active,
  onClick,
}: {
  active: boolean;
  onClick: () => void;
  tab: string | { label: string; key: string; onTabClick?: () => void; empty?: boolean };
}) => {
  const activeClass = active ? (typeof tab === "string"  ? false : tab?.empty) ? "" : "active" : "";

  return (
    <li className="nav-item" onClick={onClick}>
      <span
        className={`nav-link ${activeClass}`}
        role="tab"
        aria-selected={active ? "true" : "false"}
        style={{ fontWeight: "400" }}
      >
        {typeof tab === "string" ? tab : tab?.label}
      </span>
    </li>
  );
};

const Tabs = ({ tabs, activeTab, setActiveTab }: TabProps) => {
  const handleTabClick = (selectedTab: string) => {
    setActiveTab(selectedTab);
  };

  return (
    <div className="tabs" style={{ cursor: "pointer" }}>
      <ul className="nav mb-2 fw-semibold" role="tablist">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            tab={tab}
            active={(typeof tab === "string" ? tab : tab.key) === activeTab}
            onClick={() =>
              handleTabClick(typeof tab === "string" ? tab : tab.key)
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
