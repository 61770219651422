import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { spinnerProps } from "../../utils/interfaces";

function SpinnerComponent({ size, variant, animation }: spinnerProps) {
  return (
    <Spinner
      as="span"
      animation={animation}
      size={size}
      role="status"
      aria-hidden="true"
      variant={variant}
    />
  );
}

export default SpinnerComponent;
