import PaymentHeader from "../../component/payment-link/PaymentHeader";
import PaymentTable from "../../component/payment-link/PaymentTable";

const PaymentLink = () => {
  return (
    <div>
      <PaymentHeader />
      <PaymentTable />
    </div>
  );
};

export default PaymentLink;
