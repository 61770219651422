import React from "react";
import Modal from "react-bootstrap/Modal";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { mode } from "../../helpers/index.helper";

function AccountDetailsModal({
  show,
  handleClose,
  cashierInfo,
  statsLoading,
}: {
  show: boolean;
  handleClose: () => void;
  cashierInfo?: any;
  statsLoading?: any;
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Account details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              //   padding: "0px 10px",
              overflow: "hidden",
            }}
          >
            {/* <div className="signage-front"></div> */}
            <div className="mx-auto d-flex flex-row align-items-center justify-content-center">
              <div className="signage-modal mx-auto">
                <div className="pt-5">
                  <ul className="pt-5 mt-4" style={{ listStyle: "none" }}>
                    <li className="text-center d-flex flex-column justify-content-start">
                      {statsLoading ? (
                        <Skeleton height={30} />
                      ) : (
                        <>
                          <span
                            className="cashier-stats "
                            style={{
                              color: "#9C9BB0",
                              fontSize: "10px",
                              letterSpacing: "5px",
                              textAlign: "center",
                            }}
                          >
                            BANK NAME
                          </span>
                          <span
                            className="cashier-value"
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {cashierInfo?.account?.bank_name}
                          </span>
                        </>
                      )}
                    </li>

                    <li className="text-center d-flex flex-column justify-content-center divider">
                      {statsLoading ? (
                        <Skeleton />
                      ) : (
                        <>
                          <span
                            className="cashier-stats text-center"
                            style={{
                              color: "#9C9BB0",
                              fontSize: "10px",
                              letterSpacing: "5px",
                            }}
                          >
                            ACCOUNT NUMBER
                          </span>
                          <span
                            className="cashier-value"
                            style={{
                              fontSize: "14px",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {cashierInfo?.account?.account_number}
                          </span>
                        </>
                      )}
                    </li>

                    <li className="text-center d-flex flex-column justify-content-center pb-4">
                      {statsLoading ? (
                        <Skeleton />
                      ) : (
                        <>
                          <span
                            className="cashier-stats text-center"
                            style={{
                              color: "#9C9BB0",
                              fontSize: "10px",
                              letterSpacing: "5px",
                            }}
                          >
                            ACCOUNT NAME
                          </span>
                          <p
                            className="cashier-value text-center"
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                            }}
                          >
                            {cashierInfo?.account?.display_name}
                          </p>
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {mode === "internal.test" && (
            <div className="signage-download">
              <button type="button" className="btn btn-outline-success btn-sm">
                <span style={{ color: "#128100 !important" }}>Download</span>
                <DownloadIcon className="ms-2 text-success" />
              </button>
            </div>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
}

export default AccountDetailsModal;
