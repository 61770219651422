import React, { ChangeEvent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { formatCurrency } from "../../helpers/index.helper";
import SpinnerComponent from "../spinner/Spinner";
// import OtpFields from "../otp/OtpFields";
import { useQueryClient } from "react-query";
import { useCashierPayback } from "../../hooks/cashier/index.hook";

function PaybackModal({
  show,
  handleClose,
  account_no,
}: {
  show: boolean;
  handleClose: () => void;
  account_no: string | any;
}) {
  const { paybackHandler } = useCashierPayback();
  const queryClient = useQueryClient();

  const [amount, setAmount] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (formData: {
    amount: number | any;
    account_no: string;
  }) => {
    setIsLoading(true);
    try {
      const response = await paybackHandler.mutateAsync(formData);
      if (response.status === 202) {
        setAmount("");
        setIsLoading(false);
        setFormData({});
        queryClient.refetchQueries();
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setAmount("");
        setFormData({});
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="">
          <h4 className="modal-title" id="exampleModalLabel">
            Settle
          </h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="">
          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="amount">
                Amount*
              </label>
              <input
                type="text"
                className={`form-control ${
                  amount?.length > 0
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                name="amount"
                id="amount"
                tabIndex={1}
                placeholder=""
                aria-label="amount"
                value={amount}
                onChange={(e) => {
                  const currency = formatCurrency(e.target.value);
                  setAmount(currency);
                  setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    amount: e.target.value,
                  }));
                }}
                required
              />
            </div>
          </div>

          {/* <div className="row mt-3">
            <div className="col">
              <label className="form-label" htmlFor="amount">
                Enter Transfer pin*
              </label>
              <OtpFields
                onOtpInput={handleOtpChange}
                readOnly={readOnly}
                numInputs={4}
              />
            </div>
          </div> */}

          <div className="row mt-3">
            <div className="col">
              <label className="form-label" htmlFor="amount">
                Narration
              </label>

              <textarea
                className={`form-control ${
                  formData?.description?.length > 0
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                name="description"
                id="description"
                tabIndex={1}
                placeholder=""
                aria-label=""
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <button
                onClick={() =>
                  handleSubmit({
                    amount: parseInt(formData?.amount?.replace(/,/g, "")),
                    account_no,
                  })
                }
                type="button"
                className="btn btn-primary w-100"
                disabled={isLoading}
              >
                Settle
                {isLoading && (
                  <>
                    {" "}
                    &nbsp;
                    <SpinnerComponent size="sm" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PaybackModal;
