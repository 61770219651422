import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import StoreImage from "../../assets/img/others/store.png";
import { Button } from "../../component/button";
import Input from "../../component/input/Input";
import SEO from "../../component/seo/seo";
import PoolerIcon from "../../component/vectors/pooler-icon";
import {
  formatAmount,
  formatCurrency,
  hmacSHA512,
  validateEmail,
} from "../../helpers/index.helper";
import Loader from "./loader";

function PaymentPage() {
  const params = useParams();
  const handleButtonClickRef = useRef(null);
  const id = params?.reference;
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    amount: "",
    email: "",
  });
  const [sign, setSign] = useState(true);
  const [formattedAmount, setFormatAmount] = useState("");
  const [fields, setFields] = useState({});
  const [expire, setExpire] = useState("not-expired");

  const handleChange = (e, index) => {
    if (e?.target?.name !== undefined) {
      setFields({ ...fields, [e.target.name]: e.target.value });
    }
  };

  const host = window.location.host;
  const BASE_URL =
    host.includes("localhost") ||
    host.includes("netlify") ||
    host.includes("app.swim")
      ? process.env.REACT_APP_BASE_URL_PUSHER
      : process.env.REACT_APP_BASE_URL_PUSHER;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

  const SCRIPT_URL =
    host.includes("localhost") ||
    host.includes("netlify") ||
    host.includes("app.swim")
      ? process.env.REACT_APP_SCRIPT
      : process.env.REACT_APP_SCRIPT;

  const getPaymentLinkDetails = async () => {
    const stringToHash = `<+++==>${params?.reference}${SECRET_KEY}<===++==>`;
    const hash_signature = hmacSHA512(stringToHash, SECRET_KEY);
    const reqObject = {
      reference: params?.reference,
      extra_fields: { ...fields },
      payment_link_reference: params?.reference,
    };
    try {
      const response = await fetch(`${BASE_URL}/payment_link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-signature": hash_signature,
        },
        body: JSON.stringify(reqObject),
      });
      const result = await response.json();

      if (response.status === 200) {
        setData(result?.data);
        setExpire("valid");
        setLoader(false);
      } else {
        setExpire("expired");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (params?.reference) {
      getPaymentLinkDetails();
    }
    return () => {};
  }, [params?.reference]);

  useEffect(() => {
    if (
      typeof window.Pooler === "undefined" &&
      document.getElementById("pooler-payment-link") === null
    ) {
      const script = document.createElement("script");
      script.src = `${SCRIPT_URL}?version=2`;
      script.async = true;
      script.id = "pooler-payment-link";
      document.body.appendChild(script);
    }
    if (
      formData.email &&
      data &&
      id &&
      window?.Pooler &&
      validateEmail(formData?.email) === true
    ) {
      let envObj = {};
      if (process.env.REACT_APP_ENV === "internal")
        envObj.env = "internal-test";
      const handleButtonClick = () => {
        const amountValue = formData?.amount;
        const Pooler = window.Pooler;
        Pooler.Popup({
          ...data,
          ...envObj,
          email: formData?.email,
          pub_key: data?.pub_key,
          amount:
            parseFloat(amountValue.replace(/\D/g, "")) || data?.payment?.amount,
          redirect_link: data?.payment?.redirect_link,
          extra_fields: { ...fields },
          payment_link_reference: params?.reference,
          user_type: "merchant",
          success_message: data?.payment?.success_message || "",
          failure_message: data?.payment?.failure_message || "",
          host_url: window.location.origin,
          message: "payment",
          payer_first_name: formData.first_name,
          payer_last_name: formData.last_name,
          domain: data?.domain,
        });
      };
      handleButtonClickRef.current = handleButtonClick;
    }
    return () => {};
  }, [
    SCRIPT_URL,
    data,
    fields,
    formData?.amount,
    formData.email,
    formData.first_name,
    formData.last_name,
    id,
    params?.reference,
    sign,
  ]);

  const pub_key = data?.pub_key;
  const extraFields = data?.payment?.extra_fields?.extra_fields;

  const handleAmountChange = (event) => {
    const val = event.target.value;
    const currency = formatCurrency(event.target.value);
    setFormData((prev) => ({
      ...prev,
      amount: val.replace(/\D/g, ""),
    }));
    setFormatAmount(currency);
  };

  return (
    <>
      <SEO
        title={
          data?.payment?.merchant + " — " + data?.payment?.payment_link_name ||
          "Pooler Payment Link"
        }
        description={data?.payment?.note}
        type="payment link"
      />
      <main
        style={{ background: "#FAFAFD" }}
        id="content"
        role="main"
        className="main position-relative"
      >
        {loader === true ? (
          <div className="container d-flex justify-content-center align-items-center py-5 py-sm-7">
            {" "}
            <Loader />
          </div>
        ) : (
          <div className="container d-flex justify-content-center align-items-center py-5 py-sm-7">
            {expire === "valid" ? (
              <div className="mx-auto" style={{ maxWidth: "24rem" }}>
                <div className="card hard-shadow mb-3 position-relative">
                  <div>
                    <img
                      src={StoreImage}
                      alt="store-merchant"
                      style={{
                        position: "absolute",
                        width: "12%",
                        right: 0,
                        top: "12%",
                      }}
                    />
                  </div>
                  <div
                    className="card-body pl-4 pr-5  "
                    style={{ background: "#F0F1F9" }}
                  >
                    <div className="">
                      <h3
                        className="fs-6 fw-regular  m-0"
                        style={{ fontWeight: "400", color: "#8F9BB2" }}
                      >
                        {data?.payment?.merchant}
                      </h3>
                      <p className="m-0 fs-6 fw-normal">
                        {data?.payment?.payment_link_name}
                      </p>
                    </div>
                    <div className="mt-2">
                      <h3
                        className="fs-6 text-secondary m-0"
                        style={{ fontWeight: "400", color: "#8F9BB2" }}
                      >
                        Description
                      </h3>
                      <p className="m-0 fs-5 text-black fw-normal">
                        {data?.payment?.note}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card hard-shadow mb-3">
                  <div className="card-body p-4 p-sm-5 d-grid gap-3">
                    <div className="row">
                      <div className="col-6">
                        <Input
                          value={formData.first_name}
                          required
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              first_name: e.target.value,
                            }))
                          }
                          label="First name"
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          value={formData.last_name}
                          required
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              last_name: e.target.value,
                            }))
                          }
                          label="Last name"
                        />
                      </div>
                    </div>
                    <Input
                      value={formData.email}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                      required
                      label="Email address"
                      type="email"
                    />
                    {data?.payment?.amount === null ? (
                      <Input
                        label="Amount*"
                        required
                        value={formattedAmount}
                        onChange={(e) => {
                          handleAmountChange(e);
                        }}
                        type="text"
                        name="amount"
                      />
                    ) : (
                      ""
                    )}

                    {extraFields?.map((i, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <label className="form-label text-capitalize">
                            {i?.fieldName}
                          </label>
                          {i.type === "textfield" ? (
                            <input
                              type="text"
                              className="form-control form-control-lg border border-secondary"
                              name={
                                i?.fieldName !== undefined ? i?.fieldName : ""
                              }
                              id={
                                i?.fieldName !== undefined ? i?.fieldName : ""
                              }
                              tabIndex={1}
                              placeholder={
                                i?.fieldName !== undefined ? i?.fieldName : ""
                              }
                              aria-label={
                                i?.fieldName !== undefined ? i?.fieldName : ""
                              }
                              onChange={(e) => {
                                handleChange(e, index);
                                setFields({
                                  ...fields,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          ) : (
                            <select
                              className="form-select form-control-lg border border-secondary"
                              name={
                                i?.fieldName !== undefined ? i?.fieldName : ""
                              }
                              id={
                                i?.fieldName !== undefined ? i?.fieldName : ""
                              }
                              onChange={(e) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  [e?.target?.name]: e.target.value,
                                }));
                                setFields({
                                  ...fields,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            >
                              <option>Select...</option>
                              {i?.dropdown?.map((i) => {
                                return (
                                  <option value={i} key={i}>
                                    {i}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      );
                    })}

                    <div className="d-flex justify-content-center btn-sm gap-6 mt-2">
                      {data?.payment?.amount ? (
                        <Button
                          fullwidth
                          disabled={pub_key === undefined}
                          type="button"
                          id="paybutton"
                          onClick={() => handleButtonClickRef.current()}
                        >
                          Pay {formatAmount(data?.payment?.amount)} now
                        </Button>
                      ) : (
                        <Button
                          fullwidth
                          disabled={
                            pub_key === undefined ||
                            validateEmail(formData?.email) === false
                          }
                          type="button"
                          id="paybutton"
                          onClick={() => handleButtonClickRef.current()}
                        >
                          Pay {formatAmount(formData.amount)} now
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: "max-content" }}
                  className="bg-white text-center p-2 rounded-2 mx-auto"
                >
                  Secured By <PoolerIcon />
                  <span className="fw-bold">Pooler</span>
                </div>
              </div>
            ) : (
              <div
                className="mx-auto"
                style={{ maxWidth: "24rem", width: "24rem" }}
              >
                <div
                  className="card hard-shadow mb-3 position-relative"
                  style={{ height: "6rem" }}
                >
                  <div>
                    <img
                      src={StoreImage}
                      alt="store-merchant"
                      style={{
                        position: "absolute",
                        width: "12%",
                        right: 0,
                        top: "12%",
                      }}
                    />
                  </div>
                  <div
                    className="card-body pl-4 pr-5  "
                    style={{ background: "#F0F1F9" }}
                  >
                    <div className="">
                      <h3
                        className="fs-6 fw-regular  m-0"
                        style={{ fontWeight: "400", color: "#8F9BB2" }}
                      >
                        {data?.payment?.merchant}
                      </h3>
                      <p className="m-0 fs-6 fw-normal">
                        {data?.payment?.payment_link_name}
                      </p>
                    </div>
                    <div className="mt-2">
                      <h3
                        className="fs-6 text-secondary m-0"
                        style={{ fontWeight: "400", color: "#8F9BB2" }}
                        hidden
                      >
                        Description
                      </h3>
                      <p className="m-0 fs-5 text-black fw-semibold">
                        This payment link has expired
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </main>
    </>
  );
}

export default PaymentPage;
