export const graphConfig = (data?: any) => ({
  options: {
    colors: ["#2163e8", "#E91E63", "#66DA26", "#546E7A"],
    chart: {
      id: "area-chart",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    selection: {
      enabled: true,
    },
    xaxis: {
      labels: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
    grid: {
      strokeDashArray: 4,
      // position: "back",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      // curve: "smooth",
      // strokeWidth: 3,
    },
    legend: {
      show: true,
      // horizontalAlign: "right",
      // position: "top",
      labels: {},
      markers: {
        width: 8,
        height: 8,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
  series: data !== undefined ? data : [],
});
