import { useEffect, useState } from "react";
import Select from "react-select";
import { formatAmount, shortenString } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import { useResolveAccount } from "../../hooks/payouts/payouts.hook";
import useResize from "../../hooks/resize.hook";
import { useBenificiaries } from "../../hooks/transfer/index.hook";
import { benificiaryProps, sessionInterface } from "../../utils/interfaces";
import CurrencyInputField from "../CurrencyInput/CurrencyInput";
import SpinnerComponent from "../spinner/Spinner";
import "./modal.css";

const SavedBenificiary = ({
  account,
  limit,
  handleAddAccount,
  handleContinue,
}: {
  account: any;
  limit: number | string;
  handleAddAccount: (data: any) => void;
  handleContinue: (stage: string) => void;
}) => {
  const { size } = useResize();
  const [amount, setAmount] = useState<string>(account?.amount || "");
  const [narration, setNarration] = useState<string>(account?.narration || "");
  const [hidden, setHidden] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [selectedBenificiary, setSelectedBenificiary] = useState<any>(
    account?.selectedBenificiary || {}
  );
  const { data, isLoading } = useBenificiaries();
  const beneficiaries: benificiaryProps[] = data?.data?.data?.items;
  const session = useSession();
  const merchant_data: sessionInterface = session?.data?.data?.data;
  const { resolve_account } = useResolveAccount();
  const handleAccountDetails = () => {
    let data = {};
    if (narration) {
      data = {
        beneficiary_account_name: selectedBenificiary?.account_name,
        amount: parseFloat(amount),
        narration: narration,
        save_beneficiary: true,
        beneficiary_account_no: resolve_account?.data?.data?.data?.account_no,
        beneficiary_bank_name: selectedBenificiary?.bank_name,
        beneficiary_bank: selectedBenificiary?.bank_code,
        selectedBenificiary,
      };
    } else {
      data = {
        beneficiary_account_name: selectedBenificiary?.account_name,
        amount: parseFloat(amount),
        save_beneficiary: true,
        beneficiary_account_no: resolve_account?.data?.data?.data?.account_no,
        beneficiary_bank_name: selectedBenificiary?.bank_name,
        beneficiary_bank: selectedBenificiary?.bank_code,
        selectedBenificiary,
      };
    }
    handleAddAccount(data);
    setHidden(true);
  };
  useEffect(() => {
    async function ConfirmAcc() {
      try {
        const response: any = await resolve_account.mutateAsync({
          account_no: account?.selectedBenificiary?.account_number,
          bank_code: account?.selectedBenificiary?.bank_code,
        });
        if (response?.status === 200) {
          setHidden(false);
          setError({
            account_number: false,
          });
        }
      } catch (error) {
        setError({
          account_number: true,
        });
        return error;
      }
    }
    if (account?.selectedBenificiary?.bank_code) ConfirmAcc();
  }, [account?.selectedBenificiary]);
  return (
    <div className="my-2">
      <div className="row mb-3">
        <div className="col-12">
          <label htmlFor="amount" className="mb-2 card-subtitle">
            Amount
          </label>
          <CurrencyInputField
            id="amount"
            name="amount"
            value={amount}
            onAmountChange={(value) => setAmount(value)}
            className="form-control border border-secondary"
            aria-label="amount"
          />
          {merchant_data?.charge_type === "flat" ? (
            <div id="amountHelp" className="form-text mt-1">
              You will be charged{" "}
              {formatAmount(parseFloat(merchant_data?.transfer_fee))} for this
              transaction.
            </div>
          ) : (
            <div id="amountHelp" className="form-text mt-1">
              You will be charged {merchant_data?.charge_percentage}% of the
              amount for this transaction capped at NGN 200.
            </div>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <label htmlFor="benificiary" className="mb-2 card-subtitle">
            Saved beneficiary
          </label>
          <div>
            <Select
              options={beneficiaries}
              isLoading={isLoading}
              formatOptionLabel={(option: benificiaryProps, meta: any) => {
                if (meta?.context === "value") {
                  return (
                    <>
                      <strong>
                        {option?.account_name} - {option?.bank_name}
                      </strong>
                    </>
                  );
                }
                return (
                  <>
                    <strong>
                      {shortenString(option?.account_name, 15)} -{" "}
                      {option?.bank_name}
                    </strong>
                    <br />
                    <span>{option?.account_number}</span>
                  </>
                );
              }}
              className=" border-secondary rounded card-subtitle"
              getOptionValue={(option) => option?.id?.toString()}
              getOptionLabel={(option) =>
                `${option.bank_name} ${option?.account_name}`
              }
              isSearchable
              isClearable
              menuPlacement="bottom"
              placeholder="Search..."
              value={selectedBenificiary}
              onChange={async (option) => {
                setSelectedBenificiary(option);
                try {
                  const response: any = await resolve_account.mutateAsync({
                    account_no: option?.account_number,
                    bank_code: option?.bank_code,
                  });
                  if (response?.status === 200) {
                    setHidden(false);
                    setError({
                      account_number: false,
                    });
                  }
                } catch (error) {
                  setError({
                    account_number: true,
                  });
                  return error;
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <label htmlFor="Narration" className="mb-2 card-subtitle">
            Narration
          </label>
          <textarea
            id="narration"
            name="narration"
            aria-label="narration"
            className="form-control border border-secondary"
            value={narration}
            rows={4}
            onChange={(e) => setNarration(e.target.value)}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="d-flex flex-column align-items-center">
        {/* <button
          onClick={() => handleAccountDetails()}
          disabled={hidden}
          className="btn btn-outline --bor-pri my-2"
        >
          Add another transfer <i className="bi bi-plus-lg"></i>
        </button> */}
        <button
          onClick={() => {
            handleAccountDetails();
            handleContinue("summary");
          }}
          disabled={
            hidden ||
            (typeof limit === "number"
              ? parseFloat(amount) > Number(limit)
              : false)
          }
          className="btn btn-primary btn-block w-100 --bor-pri my-2 card-subtitle"
          style={{ color: "white" }}
        >
          Continue{" "}
          <span className="align-middle">
            {resolve_account?.isLoading && <SpinnerComponent size="sm" />}
          </span>
        </button>
      </div>
    </div>
  );
};

export default SavedBenificiary;
