import React from "react";
import ProfileHeader from "../../component/profile/ProfileHeader";
import ProfileSideBar from "../../component/profile/ProfileSideBar";
import ProfileBody from "../../component/profile/ProfileBody";

function Profile(): JSX.Element {
  return (
    <>
      <ProfileHeader />
      <div className="row">
        <ProfileSideBar />
        <ProfileBody />
      </div>
    </>
  );
}

export default Profile;
