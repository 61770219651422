import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useIpWhitelist, useSession } from "../../hooks/auth/auth.hook";
import { sessionInterface } from "../../utils/interfaces";
import OtpFields from "../otp/OtpFields";
import SpinnerComponent from "../spinner/Spinner";

function IPModal({
  show,
  handleClose,
  twoFA,
}: {
  show: boolean;
  handleClose: () => void;
  twoFA?: boolean;
}): JSX.Element {
  const { data, refetch } = useSession();
  const { ipHandler } = useIpWhitelist();
  const [value, setValue] = useState<string>("");
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [fieldPairs, setFieldPairs] = useState<any>([]);
  const [showOtopFields, setOtopFields] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const merchant_data: sessionInterface = data?.data?.data;
  const ipAddresses: string[] | any = merchant_data?.state?.ip_address;

  useEffect(() => {
    if (merchant_data) {
      setFieldPairs(ipAddresses);
    } else {
      setFieldPairs([]);
    }
  }, [ipAddresses, merchant_data]);

  const addNewPair = () => {
    if (value !== "") {
      setFieldPairs([...fieldPairs, value]);
      setValue("");
    }
  };

  const handleRemoveClick = (index: any) => {
    const newList = [...fieldPairs];
    newList.splice(index, 1);
    setFieldPairs(newList);
    if (twoFA) {
      setOtopFields(true);
    } else {
      ipHandler
        .mutateAsync({ ip_address: newList })
        .then(() => refetch())
        .catch()
        .finally(() => refetch());
    }
  };

  const handleOtpChange = (otp: any) => {
    if (otp.length === 6) {
      setOtp(otp);
    }
  };

  const AddIpAddress = async (payload: {
    ip_address: string[];
    auth_code?: string;
  }) => {
    setLoading(true);
    setReadOnly(true);
    try {
      const response = await ipHandler.mutateAsync(payload);
      if (response.status === 200) {
        refetch();
        handleClose();
        setLoading(false);
        setFieldPairs([]);
        setValue("");
        setOtopFields(false);
        setReadOnly(false);
      }
    } catch (error) {
      setLoading(false);
      setReadOnly(false);
      return error;
    } finally {
      setLoading(false);
      setReadOnly(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        refetch();
        handleClose();
        setValue("");
        setOtopFields(false);
        setReadOnly(false);
        if (ipAddresses.length > 0) {
          setFieldPairs(ipAddresses);
        } else {
          setFieldPairs([]);
        }
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>IP Whitelist</Modal.Title>
      </Modal.Header>
      {showOtopFields ? (
        <Modal.Body>
          <div className="row gx-2">
            <p className="mx-auto text-center">Enter OTP</p>
            <OtpFields
              onOtpInput={handleOtpChange}
              readOnly={readOnly}
              inputMode="number"
            />
            <div className="d-flex flex-row align-items-center justify-content-between mt-4">
              <button
                type="button"
                className="btn btn-white border border-secondary w-25"
                onClick={() => setOtopFields(false)}
              >
                <i className="bi bi-arrow-left"></i> Back
              </button>

              <button
                type="button"
                className="btn btn-primary w-25"
                onClick={() =>
                  AddIpAddress({ ip_address: [...fieldPairs], auth_code: otp })
                }
              >
                Submit{" "}
                {loading && <SpinnerComponent variant="light" size="sm" />}
              </button>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className="row gx-2">
            <div className="col mb-4">
              <label
                htmlFor="newEmailLabel"
                className="col-sm-3 col-form-label form-label card-subtitle fw-bold"
              >
                IP
              </label>
              <div className="d-flex gap-3">
                <div className="w-75">
                  <input
                    type="url"
                    id="tooltipExample"
                    className="form-control border border-secondary"
                    placeholder="192.158.1.38"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue(e.target.value)
                    }
                  />
                </div>

                <div className="">
                  <Button
                    variant="link"
                    style={{ color: "#128100", fontSize: "16px" }}
                    onClick={() => {
                      if (value !== "") {
                        setValue("");
                        addNewPair();
                      }
                    }}
                  >
                    Add{" "}
                    <i
                      className="bi bi-plus-lg fw-bold"
                      style={{ fontSize: "16px" }}
                    ></i>
                  </Button>
                </div>
              </div>
            </div>

            {fieldPairs?.length > 0 && (
              <ul className="list-unstyled mb-4 mt-4">
                <li className="fw-bold pb-2">IP Addresses</li>
                {fieldPairs?.map((_i: string, index: any) => (
                  <li
                    className={`${
                      _i === "" ? "d-none" : ""
                    } w-100 d-flex flex-row align-items-center py-1 `}
                    key={_i}
                  >
                    <span
                      className="py-1 border w-75 px-2 bordered d-flex flex-row align-items-center gap-3"
                      style={{
                        background: "#F5F6FB",
                        color: "#0E0B38",
                        borderRadius: "8px",
                        height: "44px",
                        border: "1px solid #CBD1EC",
                      }}
                    >
                      {_i}
                    </span>
                    <button
                      className="py-1"
                      style={{
                        background: "#FF003D",
                        borderRadius: "8px",
                        height: "32px",
                        width: "32px",
                        color: "#ffffff",
                        border: "1px solid #FF003D",
                        marginLeft: "5px",
                      }}
                      onClick={() => handleRemoveClick(index)}
                    >
                      <i
                        className="bi bi-trash3"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </button>
                  </li>
                ))}
              </ul>
            )}

            {twoFA ? (
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => {
                  setOtopFields(true);
                }}
              >
                Continue
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => {
                  AddIpAddress({ ip_address: [...fieldPairs] });
                }}
              >
                Submit{" "}
                {loading && <SpinnerComponent variant="light" size="sm" />}
              </button>
            )}
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default IPModal;
