import React from "react";
import "react-phone-input-2/lib/style.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Webcam from "react-webcam";
import "react-circular-progressbar/dist/styles.css";

const base64ToBlob = (base64String: any) =>
  fetch(base64String).then((res) => res.blob());

const videoConstraints = {
  aspectRatio: 0.6666666667,
  facingMode: "user",
  height: 640,
  width: 480,
};

export default function Camera(props: any) {
  const webcamRef = React.useRef<any>(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const handleSaveImage = async () => {
    const img = await base64ToBlob(imgSrc);
    props.saveImage(img);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {imgSrc ? (
        <img src={imgSrc} alt="img" />
      ) : (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
          />
        </>
      )}
      {imgSrc ? (
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-danger btn-lg w-50 rounded-0"
            onClick={capture}
          >
            <i className="bi bi-arrow-counterclockwise"></i> Retake picture
          </button>
          <button
            className="btn btn-primary btn-lg w-50 rounded-0"
            onClick={handleSaveImage}
          >
            <i className="bi bi-camera"></i> Use picture
          </button>
        </div>
      ) : (
        <button className="btn btn-primary btn-lg w-100" onClick={capture}>
          <i className="bi bi-camera"></i> Capture picture
        </button>
      )}
    </Modal>
  );
}
