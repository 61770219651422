import React from "react";
import Select from "react-select";
import { BUISNESS_CATEGORY, BUISNESS_USE_CASE } from "../../../utils/data";
import { formprops } from "../../../utils/interfaces";

function BuisnessRegisteredFields({
  formValues,
  handleChange,
  data,
  isDisabled,
}: formprops): JSX.Element {
  const useCaseMap =
    data?.use_case &&
    data?.use_case?.map((i: any) => {
      return { label: i, value: i };
    });
  const colourStyles: any = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor: "#fff",
        border: `${
          state?.hasValue === true ? "1px solid #00c9a7" : "1px solid"
        }`,
        minHeight: "45px",
        overflow: "hidden",
      };
    },
  };

  return (
    <div
      className="w-100 w-md-75 w-lg-75 content-space-t-0 content-space-t-lg-1 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      {/* 1 */}
      <div className="row mb-2 mb-md-4 mb-lg-4">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="buisness_type">
            Business Type*
          </label>

          <select
            className={`form-select form-control ${
              isDisabled === true
                ? "border border-secondary"
                : "border border-secondary "
            } `}
            id="validationValidSelect1"
            name="business_type"
            // onChange={handleChange}
            defaultValue={"Registered Business"}
            value={"Registered Business"}
          >
            <option value="">Registered Business</option>
            {/* {REGISTERED_BUSINESS_TYPE.map((i: string) => {
                return <option value={i}>{i}</option>;
              })} */}
          </select>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="business_name">
            Business Name*
          </label>
          <input
            type="text"
            className={`form-control  ${
              isDisabled === true && data?.business_name
                ? "border border-secondary"
                : "border border-secondary "
            }`}
            name="business_name"
            id="business_name"
            tabIndex={1}
            defaultValue={data?.business_name}
            placeholder="Business Name"
            aria-label="business_name"
            onChange={handleChange}
            required
            readOnly
          />
        </div>
      </div>

      {/* 2 */}
      <div className="row mb-2 mb-md-4 mb-lg-4">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="firstname">
            Use case*
          </label>
          {isDisabled === true ? (
            <Select
              aria-label="country"
              options={BUISNESS_USE_CASE}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              defaultValue={useCaseMap}
              onChange={(option: any) => {
                const values = option.map((i: any) => i.value);
                const e = { target: { name: "use_case", value: values } };
                handleChange(e);
              }}
              classNamePrefix="react-select"
              placeholder="Select..."
              isClearable
              isSearchable
              isMulti
              isDisabled={isDisabled}
            />
          ) : (
            <Select
              aria-label="country"
              options={BUISNESS_USE_CASE}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(option: any) => {
                const values = option.map((i: any) => i.value);
                const e = { target: { name: "use_case", value: values } };
                handleChange(e);
              }}
              classNamePrefix="react-select"
              placeholder="Select..."
              isClearable
              isSearchable
              isMulti
              styles={colourStyles}
            />
          )}
          <span className="invalid-feedback">Please select a category</span>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="business_sector">
            Business sector*
          </label>
          <select
            className={`form-select form-control  ${
              formValues?.business_sector &&
              formValues?.business_sector?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.business_sector &&
              data?.business_sector?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.business_sector?.length > 0 &&
              isDisabled === true &&
              formValues?.business_sector?.length > 0
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            id="validationValidSelect1"
            value={formValues?.business_sector}
            defaultValue={
              data?.business_sector !== undefined ? data?.business_sector : ""
            }
            name="business_sector"
            onChange={handleChange}
            disabled={isDisabled}
          >
            <option value={""}>Select category</option>
            {BUISNESS_CATEGORY.map((i: string) => {
              return (
                <option key={i} value={i}>
                  {i}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      {/* 3 */}
      <div className="row mb-4">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <label className="form-label" htmlFor="business_description">
            Describe what your business does*
          </label>
          <textarea
            className={`form-control   ${
              formValues?.business_description &&
              formValues?.business_description?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }
            ${
              data?.business_description &&
              data?.business_description?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }  ${
              data?.business_description &&
              formValues?.business_description &&
              data?.business_description?.length > 0 &&
              data?.business_description?.length > 0 &&
              isDisabled === true
                ? "border border-secondary"
                : "border border-secondary"
            } `}
            name="business_description"
            id="business_description"
            tabIndex={1}
            defaultValue={data?.business_description}
            placeholder=""
            aria-label="business_description"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
      </div>
    </div>
  );
}

export default BuisnessRegisteredFields;
