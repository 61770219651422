import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { PATH_NAMES, TOKEN_STRING } from "../../constants/index.constant";
import Otp from "../../pages/auth/otp";
import Resetpassword from "../../pages/auth/resetpassword";
import Signup from "../../pages/auth/signup";
import Signin from "../../pages/auth/singin";
import Verify from "../../pages/auth/verify";
import VerifyEmail from "../../pages/auth/verifyemail";

export default function ClientAuthRoutes() {
  const token = window.localStorage.getItem(TOKEN_STRING);
  const navigate = useNavigate();

  useEffect((): any => {
    if (token) {
      navigate(`/`);
    }
  }, [navigate, token]);

  return (
    <Routes>
      <Route path={PATH_NAMES.signup} element={<Signup />} />
      <Route path={PATH_NAMES.signin} element={<Signin />} />
      <Route path={PATH_NAMES.verify_otp} element={<Otp />} />
      <Route path={PATH_NAMES.reset_password} element={<Resetpassword />} />
      <Route path={PATH_NAMES.verify} element={<Verify />} />
      <Route path={PATH_NAMES.verify_email} element={<VerifyEmail />} />
    </Routes>
  );
}
