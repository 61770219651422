import React from "react";
import "./auth.css";
import AuthIllustration from "../../assets/svg/logos/Pooler-sign-up-image.png";
import PoolerIpad from "../../assets/svg/logos/ipad-pooler.svg";
import PoolerLogoWhite from "../../assets/svg/logos/Pooler_logo_white.svg";
import { useLocation } from "react-router-dom";
import SEO from "../../component/seo/seo";

function ResetInstruction(): JSX.Element {
  const location = useLocation();
  const email = location?.state?.email;
  return (
    <>
      <SEO
        title="Pooler - Collecting Safely"
        description="Use Pooler to collect and settle payments seamless and faster by transfer and avoid chargeback"
        type="website"
      />
      <div className="row">
      <div
          style={{ backgroundImage: `url(${AuthIllustration})` }}
          className="col-lg-6 overflow-hidden d-none --bg-auth d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
        >
          <div id="banner-conc">
            <img src={PoolerLogoWhite} alt="pooler-logo" />
            <h1>
              The Easier Way to
              <br />
              Receive Payments.
            </h1>
            <p>Experience world-class banking-as-a-service</p>
          </div>
          <img src={PoolerIpad} alt="pooler-ipad" />
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
          <div
            className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
            style={{ maxWidth: "25rem" }}
          >
            <div className="text-center">
              <div className="mb-5">
                <h1 className="display-5">Reset Instructions</h1>
              </div>
              <p className="card-text  text-center">
                Check your mailbox, If an account exists for{" "}
                <strong>{email}</strong>, you will receive an email with
                instructions to reset your password.
              </p>
              <p className="card-text  text-center">
                Also ensure you check your spam/junk folder.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetInstruction;
