import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  AUTH_BASE_PATH,
  PATH_NAMES,
  TOKEN_STRING,
} from "../../constants/index.constant";
import { initQueryOptions, parseResponse } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import { RequestMethod } from "../../utils/enums";
import { instance } from "../../utils/instance";
import {
  IVerifyAnswer,
  apiKeyInterface,
  kycInterface,
  loginInterface,
  registerInterface,
  requestInterface,
  sessionInterface,
  tokenInterface,
} from "../../utils/interfaces";
import useUserDeviceInfo from "../device.hook";

export const useLogin = () => {
  const login_user = useMutation(
    (loginData: loginInterface) => {
      return instance({
        url: `${AUTH_BASE_PATH}/login`,
        method: RequestMethod.POST,
        data: loginData,
      });
    },
    parseResponse((data: any) =>
      window.localStorage.setItem(TOKEN_STRING, data)
    )
  );
  return { login_user };
};

export const useRequestReset = () => {
  const request_token = useMutation((reqData: requestInterface) => {
    return instance({
      url: `${AUTH_BASE_PATH}/request_reset`,
      method: RequestMethod.POST,
      data: reqData,
    });
  }, parseResponse());
  return { request_token };
};

export const useRequestTokenValidation = () => {
  const token_validation = useMutation((reqData: tokenInterface) => {
    return instance({
      url: `${AUTH_BASE_PATH}/reset`,
      method: RequestMethod.POST,
      data: reqData,
    });
  }, parseResponse());
  return { token_validation };
};

export const useRegister = () => {
  const register_user = useMutation((registerData: registerInterface) => {
    return instance({
      url: `${AUTH_BASE_PATH}`,
      method: RequestMethod.POST,
      data: registerData,
    });
  }, parseResponse());
  return { register_user };
};

export const useTwoFactor = (actionType: string) => {
  const { deviceInfo } = useUserDeviceInfo();
  const verify_otp = useMutation(
    (payload: any): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/${actionType}`,
        method: RequestMethod.POST,
        data: { ...payload },
        headers: {
          "x-device-name": deviceInfo.userAgent,
          "x-login-channel": "web",
        },
      });
    },
    {
      onSuccess: (data: any) => {
        if (actionType === "twofactor") {
          window.localStorage.setItem(TOKEN_STRING, data?.data?.data?.token);
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { verify_otp };
};

export const useKyc = () => {
  const provide_kyc = useMutation((payload: kycInterface): any => {
    return instance({
      url: `${AUTH_BASE_PATH}/kyc`,
      method: RequestMethod.POST,
      data: { ...payload },
    });
  }, parseResponse());
  return { provide_kyc };
};

export const useSubmitKyc = () => {
  const submit_kyc = useMutation(() => {
    return instance({
      url: `${AUTH_BASE_PATH}/submitkyc`,
      method: RequestMethod.POST,
    });
  }, parseResponse());
  return { submit_kyc };
};

export const useSession = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["merchant"],
    () => API_SERVICE._getSession(),
    {
      onSuccess: (data) => {
        const merchant_data: sessionInterface = data?.data?.data;
        window.localStorage.setItem(
          "merchant_compliance_id",
          merchant_data?.merchant_compliance_id
        );
        window.localStorage.setItem(
          "business_cat",
          merchant_data?.business_category
        );
        window.localStorage.setItem(
          "domain",
          merchant_data?.state?.active_domain
        );
      },
      onError: (error: any) => {},
    }
  );
  const sessionData = data;
  return { data, isLoading, isError, isFetching, refetch, sessionData };
};

export const useDashboard = (config: {
  start_date?: string;
  end_date?: string;
}) => {
  const depArr = config
    ? ["dashboard-report", config.start_date, config.end_date]
    : ["dashboard-report"];
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    depArr,
    () => API_SERVICE._getDashboardReports(config),
    initQueryOptions()
  );
  const dashboard_data = data;
  const fetching = isFetching;
  return { dashboard_data, fetching, isLoading, isError, isFetching, refetch };
};

export const useLatestTransactions = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["latest-txn"],
    () => API_SERVICE._getLatestTransactions(),
    initQueryOptions()
  );
  const latest_txn: any = data;
  return { latest_txn, isLoading, isError, isFetching, refetch };
};

export const useGenerateKeys = () => {
  const generate_key = useMutation(
    (payload: apiKeyInterface): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/security`,
        method: RequestMethod.POST,
        data: { ...payload },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { generate_key };
};

export const useViewKeys = () => {
  const view_keys = useMutation(
    (auth_code: string): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/security?auth_code=${auth_code}`,
        method: RequestMethod.GET,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { view_keys };
};

export const useGetKeys = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["accounts"],
    () => API_SERVICE._getKeys(),
    initQueryOptions()
  );
  const keys_data: any = data;
  return { keys_data, isLoading, isError, isFetching, refetch };
};

export const useGenerateWebhook = () => {
  const generate_webhook = useMutation(
    (payload: { webhook_url: string }): any => {
      return instance({
        url: `/merchant/addwebhook`,
        method: RequestMethod.POST,
        data: { ...payload },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { generate_webhook };
};

export const useRenderHTML = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["t&c"],
    () => API_SERVICE._renderHTML(),
    { keepPreviousData: true }
  );
  return { data, isLoading, isError, isFetching, refetch };
};

export const useInitiateTwoFa = () => {
  const initiate_2fa = useMutation(
    (): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/initiate2fa`,
        method: RequestMethod.GET,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { initiate_2fa };
};

export const useEnableTwoFa = () => {
  const enable_2fa = useMutation(
    (auth_code: string): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/enable2fa?auth_code=${auth_code}`,
        method: RequestMethod.GET,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.success(error?.response?.data?.data);
      },
    }
  );
  return { enable_2fa };
};

export const useDisableTwoFa = () => {
  const disable_2fa = useMutation(
    (auth_code: string): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/disable2fa?auth_code=${auth_code}`,
        method: RequestMethod.GET,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.success(error?.response?.data?.data);
      },
    }
  );
  return { disable_2fa };
};

export const useResendOtp = () => {
  const resend_otp = useMutation(
    (email: string): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/requestsignupotp`,
        method: RequestMethod.POST,
        data: { email: email },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { resend_otp };
};

export const useAuthValidator = () => {
  const validator = useMutation(
    (data: { query: string; value: string }): any => {
      return instance({
        url: `merchant/validators/${data.query}/${data.value}`,
        method: RequestMethod.GET,
      });
    },
    {
      onSuccess: (data: any) => {
        // toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        // toast.error(error?.response?.data?.data);
      },
    }
  );
  return { validator };
};

export const useAuthFirstTimeLogin = () => {
  const firstTime = useMutation(
    (): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/firsttimelogin`,
        method: RequestMethod.POST,
      });
    },
    {
      onSuccess: (data: any) => {
        // toast.success(data?.data?.message ?? data?.data?.data);
      },
      onError: (error: any) => {
        // toast.error(error?.response?.data?.data);
      },
    }
  );
  return { firstTime };
};

export const useSecurityQuestion = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["security-question"],
    () => API_SERVICE._getSecurityQuestions(),
    initQueryOptions()
  );
  const securityQuestions = data;
  return { securityQuestions, isLoading, isError, isFetching, refetch };
};

export const useVerifySecurityAnswer = () => {
  const verify_answer = useMutation(
    (payload: IVerifyAnswer): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/verifyanswer`,
        method: RequestMethod.POST,
        data: { ...payload },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { verify_answer };
};

export const useResetSms = () => {
  const smsHandler = useMutation(
    (payload: { sms_auth_code: string }): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/resetpinotp`,
        method: RequestMethod.POST,
        data: { ...payload },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { smsHandler };
};

export const useResendSms = () => {
  const smsResendHandler = useMutation(
    (): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/resetsmsotp/resend`,
        method: RequestMethod.POST,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { smsResendHandler };
};

export const useResetEmailOtp = () => {
  const emailOtpHandler = useMutation(
    (payload: { auth_code: string }): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/resetpinotp/verify`,
        method: RequestMethod.POST,
        data: { ...payload },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { emailOtpHandler };
};

export const useResetTrxnPin = () => {
  const resetPinHandler = useMutation(
    (payload: { auth_code: string; transaction_pin: string }): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/resetpin`,
        method: RequestMethod.POST,
        data: { ...payload },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { resetPinHandler };
};

export const useIpWhitelist = () => {
  const ipHandler = useMutation(
    (payload: { ip_address: string[]; auth_code?: string }): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/addIp`,
        method: RequestMethod.POST,
        data: { ...payload },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { ipHandler };
};

export const useResendEmail = () => {
  const emailResendHandler = useMutation(
    (): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/resendresetpinotp`,
        method: RequestMethod.POST,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.data);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { emailResendHandler };
};

export const useLogout = () => {
  const invalidateToken = useMutation(
    (): any => {
      return instance({
        url: `${AUTH_BASE_PATH}/logout`,
        method: RequestMethod.POST,
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.data ?? data?.data?.message);
        localStorage.clear();
        window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
        localStorage.clear();
        window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
      },
    }
  );
  return { invalidateToken };
};

export const useSupportedBanks = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["supported-banks"],
    () => API_SERVICE._getSupportedBanks(),
    initQueryOptions()
  );
  const supported_banks: any = data?.data?.data?.data;
  return { supported_banks, isLoading, isError, isFetching, refetch };
};

export const useSetSecondaryBank = () => {
  const queryClient = useQueryClient();
  const { refetch } = useSession();
  const saveSecondaryBank = useMutation(
    (payload: any[]): any => {
      return API_SERVICE._postSecondaryBanks({ secondary_banks: payload });
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(["merchant"]);
        toast.success(data?.data?.data ?? data?.data?.data);
        refetch();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data);
      },
    }
  );
  return { saveSecondaryBank };
};
