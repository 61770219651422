import "./modal.css";
import { useNavigate } from "react-router-dom";

const SuccessImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    fill="none"
    viewBox="0 0 80 80"
  >
    <g filter="url(#filter0_d_994_6842)">
      <circle cx="39" cy="39" r="39" fill="#fff"></circle>
    </g>
    <circle cx="39" cy="39" r="34.125" fill="#C8DEC4"></circle>
    <path
      fill="#9AC693"
      fillRule="evenodd"
      d="M64.17 62.043A34.002 34.002 0 0073.125 39c0-9.21-3.648-17.566-9.578-23.705-.56-.04-1.125-.06-1.695-.06-12.958 0-23.461 10.503-23.461 23.46s10.503 23.461 23.46 23.461c.783 0 1.556-.038 2.32-.113z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#128100"
      fillRule="evenodd"
      d="M70.43 52.315A34.016 34.016 0 0073.125 39c0-4.724-.96-9.224-2.695-13.315-.515-.06-1.04-.091-1.57-.091-7.405 0-13.407 6.002-13.407 13.406 0 7.404 6.002 13.406 13.406 13.406.532 0 1.056-.03 1.57-.09z"
      clipRule="evenodd"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M48.75 31.688L35.344 45.093 29.25 39"
    ></path>
    <defs>
      <filter
        id="filter0_d_994_6842"
        width="80"
        height="80"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="2"></feOffset>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_994_6842"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_994_6842"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

const FailedImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    fill="none"
    viewBox="0 0 80 80"
  >
    <g clipPath="url(#clip0_302_50)">
      <g filter="url(#filter0_d_302_50)">
        <path
          fill="#fff"
          d="M38.788 77.576c21.422 0 38.788-17.366 38.788-38.788C77.576 17.366 60.21 0 38.788 0 17.366 0 0 17.366 0 38.788 0 60.21 17.366 77.576 38.788 77.576z"
        ></path>
      </g>
      <path
        fill="color(display-p3 1.0000 0.8980 0.9255)"
        d="M38.788 72.602c18.676 0 33.815-15.14 33.815-33.815 0-18.676-15.14-33.815-33.815-33.815-18.676 0-33.815 15.14-33.815 33.815 0 18.675 15.14 33.815 33.815 33.815z"
      ></path>
      <path
        fill="color(display-p3 1.0000 0.8980 0.9255)"
        d="M72.468 39.1c0 18.675-15.139 33.814-33.815 33.814-18.675 0-33.815-15.14-33.815-33.815 0-18.675 15.14-33.815 33.816-33.815 18.675 0 33.814 15.14 33.814 33.815z"
      ></path>
      <path
        fill="color(display-p3 1.0000 0.8000 0.8471)"
        fillRule="evenodd"
        d="M26.853 52.65c13.732 0 24.864-11.132 24.864-24.864 0-10.213-6.158-18.988-14.963-22.814C19.025 6.023 4.973 20.734 4.973 38.727c0 .3.004.601.011.9 4.21 7.757 12.424 13.023 21.869 13.023z"
        clipRule="evenodd"
      ></path>
      <path
        fill="color(display-p3 1.0000 0.6000 0.6941)"
        fillRule="evenodd"
        d="M6.77 27.881c3.152-9.253 10.202-16.703 19.193-20.39 6.81 1.802 11.83 8.007 11.83 15.384 0 8.788-7.125 15.913-15.913 15.913-7.04 0-13.012-4.571-15.11-10.907z"
        clipRule="evenodd"
      ></path>
      <path
        fill="color(display-p3 1.0000 0.0000 0.2392)"
        d="M38.788 53.705c8.24 0 14.919-6.679 14.919-14.918 0-8.24-6.68-14.919-14.919-14.919-8.24 0-14.918 6.68-14.918 14.919s6.679 14.918 14.918 14.918z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.282"
        d="M44.754 32.817L32.82 44.752m0-11.935l11.934 11.935"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_302_50"
        width="80.858"
        height="80.858"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="3.282" dy="3.282"></feOffset>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_302_50"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_302_50"
          result="shape"
        ></feBlend>
      </filter>
      <clipPath id="clip0_302_50">
        <path fill="#fff" d="M0 0H80V80H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const ProcessingImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="130"
    height="130"
    fill="none"
    viewBox="0 0 130 130"
  >
    <g filter="url(#filter0_d_2639_23307)">
      <circle cx="64" cy="64" r="64" fill="#fff"></circle>
    </g>
    <circle cx="64" cy="64" r="56" fill="#D2E4FF"></circle>
    <path
      fill="#BCD7FF"
      fillRule="evenodd"
      d="M105.305 101.814C114.431 91.851 120 78.576 120 64c0-15.112-5.986-28.827-15.717-38.901A39.081 39.081 0 00101.5 25C80.237 25 63 42.237 63 63.5S80.237 102 101.5 102c1.284 0 2.553-.063 3.805-.186z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#2C1DFF"
      fillRule="evenodd"
      d="M115.577 85.85A55.821 55.821 0 00120 64a55.821 55.821 0 00-4.423-21.85A22.23 22.23 0 00113 42c-12.15 0-22 9.85-22 22s9.85 22 22 22c.872 0 1.732-.05 2.577-.15z"
      clipRule="evenodd"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6"
      d="M44 68s.243 1.698 7.272 8.728c7.03 7.03 18.426 7.03 25.456 0A17.915 17.915 0 0081.552 68M44 68v12m0-12h12m28-8s-.243-1.699-7.272-8.728c-7.03-7.03-18.426-7.03-25.456 0A17.915 17.915 0 0046.448 60M84 60V48m0 12H72"
    ></path>
    <defs>
      <filter
        id="filter0_d_2639_23307"
        width="130"
        height="130"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="2"></feOffset>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2639_23307"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2639_23307"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

const Success = ({
  message,
  subText,
}: {
  message: string;
  subText?: string;
}) => {
  return (
    <div className="d-flex flex-column align-items-center justify-contents-center">
      <SuccessImage />
      <p className="mt-5 fw-bold fs-3 text-center">{message}</p>
      <p className="fs-5 text-center">{subText}</p>
    </div>
  );
};

const Failed = ({
  message,
  subText,
}: {
  message: string;
  subText?: string;
}) => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center mb-5">
        <FailedImage />
        <p className="mt-5 fw-bold fs-3">{message}</p>
        {subText && <p className="fs-5">{subText}</p>}
      </div>
    </div>
  );
};

const Processing = ({
  message,
  subText,
}: {
  message: string;
  subText?: string;
}) => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center mb-5">
        <ProcessingImage />
        <p className="mt-5 fw-bold fs-3 text-center">{message}</p>
      </div>
    </div>
  );
};

const SuccessMessage = ({
  handleStage,
  text = "withdrawal",
}: {
  handleStage: (stage: string) => void;
  text?: string;
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <Success
        message="PIN created successfully"
        subText="Now you can perform withdrawals with your pin"
      />
      <br />
      <br />
      <div className="d-flex flex-column align-items-center w-100">
        <button
          onClick={() => handleStage("summary")}
          className="btn btn-primary btn-block w-100 --bor-pri my-2"
        >
          Complete {text}
        </button>
      </div>
    </div>
  );
};

const ProcessMessage = ({
  counts,
  handleReset,
  link = "/merchant/transfer/all",
  handleClose = () => {},
  text = "withdrawal",
}: {
  counts: boolean;
  handleReset: () => void;
  link?: string;
  handleClose?: () => void;
  text?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <Processing
        message={`Your ${text} ${counts ? "are" : "is"} being processed`}
      />
      <div className="d-flex flex-row align-items-center gap-3 justify-content-betweeen">
        <button
          onClick={() => handleReset()}
          className="btn btn-outline w-50 --bor-pri my-2"
        >
          Make a {text}
        </button>
        <button
          onClick={() => {
            navigate(link);
            handleClose();
          }}
          className="btn btn-primary w-50 --bor-pri my-2"
        >
          View transaction
        </button>
      </div>
    </div>
  );
};

const FailedMessage = ({
  handleReset,
  message = "",
  text = "Withdrawal",
}: {
  message?: string;
  handleReset: () => void;
  text?: string;
}) => {
  return (
    <div>
      <Failed message={`${text} failed`} subText={message} />
      <div className="d-flex flex-column align-items-center">
        <button
          onClick={() => handleReset()}
          className="btn btn-primary btn-block w-100 --bor-pri my-2"
        >
          Try again
        </button>
      </div>
    </div>
  );
};

export { FailedMessage, ProcessMessage, SuccessMessage };
