import React from "react";
import Modal from "react-bootstrap/Modal";
import SpinnerComponent from "../spinner/Spinner";
import SuccessImage from "../../assets/success.svg";
import { useNavigate } from "react-router-dom";

function KycLevels({
  show,
  handleClose,
  onClick,
  buttonLabel,
}: {
  show: boolean;
  handleClose: () => void;
  onClick: () => void;
  buttonLabel: string;
}): JSX.Element {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="sm"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ display: "contents" }}></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gx-2 gx-sm-3 text-center">
          <div className="mb-2">
            <img src={SuccessImage} alt="success_image" className="" />
          </div>
          <p className="w-100 text-center mx-auto py-2 fw-semibold">
            Your information has been received and we will be reviewing your
            verification. Verification takes at least 12-48 hours.
          </p>
          <div className="d-flex flex-row justify-content-center mt-3 me-1">
            <button
              type="button"
              className="btn kyc-save-btn"
              onClick={() => navigate(`/merchant`, { replace: true })}
            >
              Go to dashboard
            </button>

            <button
              type="button"
              className="btn btn-primary ms-1"
              onClick={onClick}
            >
              {buttonLabel} <i className="bi bi-arrow-right small"></i>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default KycLevels;
