import {useEffect, useState} from "react";

function useCopyToClipboard() {
  const [isCopied, setIsCopied] = useState<boolean | null>(null);

  const copyToClipboard = (textOrUrl: string) => {
    const textField = document.createElement("textarea");
    textField.value = textOrUrl;
    document.body.appendChild(textField);
    textField.select();

    try {
      const success = document.execCommand("copy");
      setIsCopied(success);
    } catch (error) {
      
      setIsCopied(false);
    } finally {
      document.body.removeChild(textField);
    }
  };

  useEffect(() => {
    if (isCopied !== null) {
      // Reset the copied state after 2 seconds
      const timeout = setTimeout(() => setIsCopied(null), 2000);
      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  return {isCopied, copyToClipboard};
}

export default useCopyToClipboard;
