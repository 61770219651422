import axios from "axios";
import { Country, State } from "country-state-city";
import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Image from "react-bootstrap/Image";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { IDENTITY, RELATIONSHIP_WITH_NOK } from "../../../utils/data";
import { formprops } from "../../../utils/interfaces";

import Dropzone from "../../../component/drag-and-drop/Dropzone";
import {
  encryptKyc,
  mode,
  signatureHarsh,
  splitAwsString,
} from "../../../helpers/index.helper";
import { REGISTERED_BUSINESS_TYPE } from "../../../utils/data";

function MandatoryRegisteredFields({
  onFileExport,
  data,
  isDisabled,
}: formprops): JSX.Element {
  // const key = generateUniqueId();

  const COUNTRY_JSON: any = Country.getAllCountries().map((i: any) => {
    return { value: i?.name, label: i?.name, isoCode: i?.isoCode };
  });

  const STATE_BY_COUNTRYCODE = (stateCode: string) => {
    if (stateCode) {
      const result = State.getStatesOfCountry(stateCode);
      const stateJson = result.map((i: any) => {
        return { label: i?.name, value: i?.name };
      });
      return stateJson;
    }
  };

  const mapDataToFields = () => {
    if (data !== undefined && data?.business_owners) {
      const result = data?.business_owners?.map((i: any) => {
        let payload = {};
        if (i?.valid_means_of_identification === "nin") {
          payload = {
            nin: {
              nin_image: i?.nin?.nin_image,
              upload: i?.nin?.upload,
              nin_number: i?.nin?.nin_number,
            },
          };
        }
        if (i?.valid_means_of_identification === "passport") {
          payload = {
            passport: {
              issue_date: i?.passport?.issue_date,
              expiry_date: i?.passport?.expiry_date,
              passport_number: i?.passport?.passport_number,
              upload: i?.passport?.upload,
            },
          };
        }
        if (i?.valid_means_of_identification === "driver_license") {
          payload = {
            driver_license: {
              upload: i?.driver_license?.upload,
              number: i?.driver_license?.number,
              issue_date: i?.driver_license?.issue_date,
              expiry_date: i?.driver_license?.expiry_date,
            },
          };
        }
        if (i?.valid_means_of_identification === "voter_card") {
          payload = {
            voter_card: {
              upload: i?.voter_card?.upload,
              voter_card_number: i?.voter_card?.voter_card_number,
            },
          };
        }
        return {
          first_name: i?.first_name,
          last_name: i?.last_name,
          middle_name: i?.middle_name,
          dob: i?.dob,
          email: i?.email,
          phone_number: i?.phone_number,
          bvn: i?.bvn,
          country: i?.country,
          city: i?.city,
          state: i?.state,
          address: i?.address,
          image: i?.image,
          valid_means_of_identification: i?.valid_means_of_identification,
          showNextOfKin: true,
          nextOfKin: {
            nok_firstname: i?.nextOfKin?.nok_firstname,
            nok_lastname: i?.nextOfKin?.nok_lastname,
            nok_relationship: i?.nextOfKin?.nok_relationship,
            nok_address: i?.nextOfKin?.nok_address,
            nok_mobile_number: i?.nextOfKin?.nok_mobile_number,
            nok_email: i?.nextOfKin?.nok_email,
            nok_middlename: i?.nextOfKin?.nok_middlename,
          },
          ...payload,
        };
      });
      return result;
    } else {
      return [
        {
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          middle_name: "",
          bvn: "",
          country: "",
          city: "",
          state: "",
          address: "",
          image: "",
          valid_means_of_identification: "",
          showNextOfKin: true,
          nextOfKin: {
            nok_firstname: "",
            nok_lastname: "",
            nok_relationship: "",
            nok_address: "",
            nok_mobile_number: "",
            nok_email: "",
            nok_middlename: "",
          },
        },
      ];
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const [addOwner, setAddOwner] = useState<boolean>(true);
  const [addDoc, setAddDoc] = useState<boolean>(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [statevalue, setStateValue] = useState<any>({});
  const [track, setTrack] = useState<boolean>(false);
  const [issueDate, setIssueDate] = useState<any>("");
  const [expiryDate, setExpiryDate] = useState<any>("");
  const [passportIssue, setPassportIssue] = useState("");
  const [passportExpire, setPassportExpire] = useState("");

  const [formValues, setFormValues] = useState<any>({
    tin_number: "",
    cac_number: "",
    cac_document_upload: "",
  });

  const [fields, setFields] = useState<any>(mapDataToFields());

  const handleChange = async (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | any
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChange = async (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | any
      | React.ChangeEvent<HTMLSelectElement>,
    index: any
  ) => {
    const id = e?.target?.id;
    const name = e?.target?.name;

    let values: any = [...fields];

    if (id === "nin_number") {
      values[index]["nin"] = {
        ...values[index]["nin"],
        [name]: e.target.value,
      };
      setFields(values);
    } else if (id === "number" || id === "issue_date" || id === "expiry_date") {
      values[index]["driver_license"] = {
        ...values[index]["driver_license"],
        [name]: e?.target?.value,
      };
      setFields(values);
    } else if (
      id === "passport_number" ||
      id === "issue_date" ||
      id === "expiry_date" ||
      id === "passport"
    ) {
      values[index]["passport"] = {
        ...values[index]["passport"],
        [name]: e.target.value,
      };
      setFields(values);
    } else if (id === "voter_card_number" || id === "voter_card") {
      values[index]["voter_card"] = {
        ...values[index]["voter_card"],
        [name]: e.target.value,
      };
      setFields(values);
    } else if (
      id === "nok_firstname" ||
      id === "nok_lastname" ||
      id === "nok_relationship" ||
      id === "nok_address" ||
      id === "nok_mobile_number" ||
      id === "nok_email" ||
      id === "nok_middlename"
    ) {
      values[index]["nextOfKin"] = {
        ...values[index]["nextOfKin"],
        [name]: e.target.value,
      };
      setFields(values);
    } else {
      values[index][e?.target?.name] = e?.target?.value;
      setFields(values);
    }
  };

  const handlePictureUpload = async (e: any, index: any) => {
    if (e) {
      setTrack(true);
      const file = e.target.files[0];
      const baseUrl =
        mode === "customer.test"
          ? process.env.REACT_APP_KYC
          : process.env.REACT_APP_BASE_URL;

      const Id: any = localStorage.getItem("merchant_compliance_id");
      const message = Id && JSON.stringify({ id: Id });
      const x_signature = encryptKyc(message, signatureHarsh);

      const url =
        mode === "customer.test"
          ? `${baseUrl}/upload/${Id}`
          : `${baseUrl}/kyc/upload/${Id}`;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(url, formData, {
          headers: { "x-compliance-signature": x_signature },
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent?.lengthComputable) {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              setUploadProgress(progress);
            }
          },
        });
        const imageUrl = response?.data?.data?.url;
        if (imageUrl) {
          let updatedFields = [...fields];
          updatedFields[index]["image"] = imageUrl;
          setFields(updatedFields);
          setTrack(false);
        }
        toast.success("file uploaded successfully");
      } catch (error) {
        toast.error("File upload failed");
        setTrack(false);
        throw error;
      }
    }
  };

  const colourStyles: any = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor: "#fff",
        // border: state?.hasValue === true ? "1px solid #00c9a7" : "1px solid #000",
        border: "1px solid",
        minHeight: "45px",
        overflow: "hidden",
      };
    },
  };

  const addField = () => {
    setFields([
      ...fields,
      {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        middle_name: "",
        dob: "",
        bvn: "",
        country: "",
        city: "",
        state: "",
        address: "",
        image: "",
        valid_means_of_identification: "",
        showNextOfKin: true,
        nextOfKin: {
          nok_fullname: "",
          nok_relationship: "",
          nok_address: "",
          nok_mobile_number: "",
          nok_middlename: "",
        },
      },
    ]);
  };

  const removeField = (index: any) => {
    if (index > 0) {
      let data = [...fields];
      data.splice(index, 1);
      setFields(data);
    }
  };

  const addNextOfKinField = (index: any) => {
    const updatedFields = [...fields];
    updatedFields[index].showNextOfKin = true;
    setFields(updatedFields);
  };

  const removeNextOfKin = (index: any) => {
    const updatedFields = [...fields];
    updatedFields[index].nextOfKin = {
      nok_fullname: "",
      nok_relationship: "",
      nok_address: "",
      nok_mobile_number: "",
    };
    updatedFields[index].showNextOfKin = false;
    setFields(updatedFields);
  };

  useEffect(() => {
    let dataToExport: any = {};

    if (formValues) {
      dataToExport = { ...dataToExport, ...formValues };
    }

    if (fields) {
      const mapToJson = fields?.map((item: any) => {
        const { showNextOfKin, ...rest } = item;
        return rest;
      });
      const filteredBusinessOwners = mapToJson.map((owner: any) => {
        const filteredOwner: any = {};
        for (const key in owner) {
          if (owner[key] !== "") {
            if (typeof owner[key] === "object") {
              filteredOwner[key] = {};
              for (const subKey in owner[key]) {
                if (owner[key][subKey] !== "") {
                  filteredOwner[key][subKey] = owner[key][subKey];
                }
              }
            } else {
              filteredOwner[key] = owner[key];
            }
          }
        }
        return filteredOwner;
      });

      dataToExport.business_owners = [...filteredBusinessOwners];
    }
    onFileExport(dataToExport);
  }, [fields, formValues, onFileExport]);

  useEffect(() => {
    if (data) {
      mapDataToFields();
      setFormValues({
        tin_number: data?.tin_number,
        cac_number: data?.cac_number,
        cac_document_upload: data?.cac_document_upload,
        business_type: data?.business_type,
        memorandum_upload: data?.memorandum_upload,
      });

      const result = data?.business_owners?.map((i: any) => {
        let payload: any = {};
        if (i?.valid_means_of_identification === "nin") {
          payload = {
            ...payload,
            nin: {
              nin_image: i?.nin?.nin_image,
              upload: i?.nin?.upload,
              nin_number: i?.nin?.nin_number,
            },
          };
        }
        if (i?.valid_means_of_identification === "passport") {
          payload = {
            ...payload,
            passport: {
              issue_date: i?.passport?.issue_date,
              expiry_date: i?.passport?.expiry_date,
              passport_number: i?.passport?.passport_number,
              upload: i?.passport?.upload,
            },
          };
        }
        if (i?.valid_means_of_identification === "driver_license") {
          payload = {
            ...payload,
            driver_license: {
              upload: i?.driver_license?.upload,
              number: i?.driver_license?.number,
              issue_date: i?.driver_license?.issue_date,
              expiry_date: i?.driver_license?.expiry_date,
            },
          };
        }
        if (i?.valid_means_of_identification === "voter_card") {
          payload = {
            ...payload,
            voter_card: {
              upload: i?.voter_card?.upload,
              voter_card_number: i?.voter_card?.voter_card_number,
            },
          };
        }

        if (i?.city) {
          payload = {
            ...payload,
            city: i?.city,
          };
        }

        return {
          first_name: i?.first_name,
          last_name: i?.last_name,
          middle_name: i?.middle_name,
          dob: i?.dob,
          email: i?.email,
          phone_number: i?.phone_number,
          bvn: i?.bvn,
          country: i?.country,
          state: i?.state,
          address: i?.address,
          image: i?.image,
          valid_means_of_identification: i?.valid_means_of_identification,
          showNextOfKin: true,
          nextOfKin: {
            nok_firstname: i?.nextOfKin?.nok_firstname,
            nok_lastname: i?.nextOfKin?.nok_lastname,
            nok_relationship: i?.nextOfKin?.nok_relationship,
            nok_address: i?.nextOfKin?.nok_address,
            nok_mobile_number: i?.nextOfKin?.nok_mobile_number,
            nok_email: i?.nextOfKin?.nok_email,
            nok_middlename: i?.nextOfKin?.nok_middlename,
          },
          ...payload,
        };
      });
      setFields(result);
    }
  }, [data]);

  return (
    <div
      className="w-100 w-md-75 w-lg-75 content-space-t-0 content-space-t-lg-1 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      <div className="pb-4">
        <div className="row mb-4">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
            <label className="form-label" htmlFor="firstname">
              Business Registration Type*
            </label>
            <select
              className={`form-select form-control  ${
                data?.business_type &&
                data?.business_type?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                formValues?.business_type &&
                formValues?.business_type?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                formValues?.business_type && data?.business_type && isDisabled
                  ? "border border-secondary"
                  : "border border-secondary"
              }`}
              id="business_type"
              name="business_type"
              defaultValue={data?.business_type}
              onChange={handleChange}
              disabled={isDisabled}
            >
              <option value="">Select</option>
              {REGISTERED_BUSINESS_TYPE.map((i: string, index: any) => {
                return (
                  <option value={i} key={i}>
                    {i}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
            <label
              className="form-label d-none d-md-block d-lg-block"
              htmlFor="cac_number"
            >
              Business registration number*
            </label>
            <label
              className="form-label d-block d-md-none d-lg-none"
              htmlFor="cac_number"
            >
              Business reg no*
            </label>
            <input
              type="text"
              className={`form-control ${
                data?.cac_number && data?.cac_number?.length > 0 && !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                formValues?.cac_number &&
                formValues?.cac_number?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                data?.cac_number && formValues?.cac_number && isDisabled
                  ? "border border-secondary"
                  : "border border-secondary"
              }`}
              name="cac_number"
              id="cac_number"
              tabIndex={1}
              defaultValue={data?.cac_number}
              placeholder=""
              aria-label="cac_number"
              onChange={handleChange}
              required
              readOnly={isDisabled}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="tin_number">
              Tax identification number*
            </label>
            <input
              type="text"
              className={`form-control ${
                data?.tin_number && data?.tin_number?.length > 0 && !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                formValues?.tin_number &&
                formValues?.tin_number?.length > 0 &&
                !isDisabled
                  ? "border border-secondary "
                  : "border border-secondary"
              } ${
                formValues?.tin_number && data?.tin_number && isDisabled
                  ? "border border-secondary"
                  : "border border-secondary"
              }`}
              name="tin_number"
              id="tin_number"
              tabIndex={1}
              defaultValue={data?.tin_number}
              placeholder=""
              aria-label="tin_number"
              onChange={handleChange}
              required
              readOnly={isDisabled}
            />
            <span className={`form-text`}>
              It can be found at the footer of your registration document
            </span>
          </div>
        </div>

        <div className="row mb-4">
          {/* {isDisabled === true ? (
            <div className="col mb-3">
              <div className="form-label">CAC document*</div>
              <div className="mt-3 d-flex">
                <Image
                  src={data?.cac_document_upload}
                  style={{
                    width: "250px",
                    height: "250px",
                    margin: "auto",
                  }}
                  rounded
                />
              </div>
            </div>
          ) : (
            <div className="col">
              <label className="form-label" htmlFor="firstname">
                Upload CAC document*
              </label>
              <Dropzone
                onFileExport={(url: any) => {
                  if (url) {
                    setFormValues((prevFormData: any) => ({
                      ...prevFormData,
                      cac_document_upload: url,
                    }));
                  }
                }}
                isDisabled={isDisabled}
                fileName={splitAwsString(
                  data?.cac_document_upload
                )}
              />
            </div>
          )} */}
          <div className="col">
            <label className="form-label" htmlFor="firstname">
              Upload CAC document*
            </label>
            <Dropzone
              onFileExport={(url: any) => {
                if (url) {
                  setFormValues((prevFormData: any) => ({
                    ...prevFormData,
                    cac_document_upload: url,
                  }));
                }
              }}
              isDisabled={isDisabled}
              fileName={splitAwsString(data?.cac_document_upload)}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="firstname">
              Upload Memorandum of Article
            </label>

            <Dropzone
              onFileExport={(url: any) => {
                if (url) {
                  setFormValues((prevFormData: any) => ({
                    ...prevFormData,
                    memorandum_upload: url,
                  }));
                }
              }}
              isDisabled={isDisabled}
              fileName={splitAwsString(data?.memorandum_upload)}
            />
          </div>
        </div>
      </div>

      <Collapse in={isDisabled === true ? true : addOwner}>
        <div id="example-collapse-text">
          {fields &&
            fields.map((i: any, index: any) => {
              const defaultVal = [
                {
                  value: i?.country,
                  label: i?.country,
                },
              ];
              const defaultStateVal = [{ label: i?.state, value: i?.state }];
              return (
                <div key={index}>
                  <div
                    className={`d-none d-md-block d-lg-block `}
                    style={{ paddingTop: index > 0 ? "30px" : "0px" }}
                  >
                    {isDisabled === true ? (
                      <ul
                        id="basicStepFormProgress"
                        className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-7"
                      >
                        <li className="step-item">
                          <div
                            className="step-content-wrapper"
                            data-hs-step-form-next-options='{
        "targetSelector": "#basicStepDetails"
      }'
                          >
                            <div className="step-content pt-1">
                              <span className="step-title pt-1">
                                Business Owner
                              </span>
                            </div>
                          </div>
                        </li>

                        <li className="step-item">
                          <div
                            className="step-content-wrapper"
                            data-hs-step-form-next-options='{
         "targetSelector": "#basicStepTerms"
       }'
                          >
                            <button type="button" className="kyc-btn-sm" hidden>
                              <i
                                className="bi bi-plus-lg me-1 pe-1"
                                style={{ color: "#128100" }}
                              ></i>
                            </button>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <ul
                        id="basicStepFormProgress"
                        className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-7"
                      >
                        <li className="step-item">
                          <div
                            className="step-content-wrapper"
                            data-hs-step-form-next-options='{
          "targetSelector": "#basicStepDetails"
        }'
                          >
                            <div className="step-content pt-1">
                              <span className="step-title pt-1">
                                Business Owner
                              </span>
                            </div>
                          </div>
                        </li>

                        <li className="step-item">
                          <div
                            className="step-content-wrapper"
                            data-hs-step-form-next-options='{
           "targetSelector": "#basicStepTerms"
         }'
                          >
                            <button
                              type="button"
                              className="kyc-btn-sm"
                              onClick={() => setAddOwner(!addOwner)}
                              aria-controls="example-collapse-text"
                              aria-expanded={addOwner}
                              hidden
                            >
                              <i
                                className="bi bi-plus-lg me-1 pe-1"
                                style={{ color: "#128100" }}
                              ></i>
                              Add
                            </button>
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>

                  <div
                    className={`d-block d-md-none d-lg-none`}
                    style={{ paddingTop: index > 0 ? "30px" : "0px" }}
                  >
                    {isDisabled === true ? (
                      <span
                        className="step-title pt-1 pb-4 active"
                        style={{ color: "#1F7AFF", cursor: "pointer" }}
                      >
                        <i className="bi-plus-circle me-1"></i>&nbsp; Business
                        Owner
                      </span>
                    ) : (
                      <span
                        className="step-title pt-1 pb-4 active"
                        style={{ color: "#1F7AFF", cursor: "pointer" }}
                        aria-controls="example-collapse-text"
                        aria-expanded={addOwner}
                      >
                        <i className="bi-plus-circle me-1"></i>&nbsp; Business
                        Owner
                      </span>
                    )}
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                      <label className="form-label" htmlFor="first_name">
                        First Name*
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          i?.first_name &&
                          i?.first_name?.length > 0 &&
                          !isDisabled
                            ? "border border-secondary "
                            : "border border-secondary"
                        } ${
                          i?.first_name &&
                          i?.first_name?.length > 0 &&
                          isDisabled
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                        name="first_name"
                        id="first_name"
                        tabIndex={1}
                        value={i?.first_name}
                        placeholder=""
                        aria-label="first_name"
                        onChange={(e) => handleInputChange(e, index)}
                        required
                        readOnly={isDisabled}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                      <label className="form-label" htmlFor="last_name">
                        Last Name*
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          i?.last_name &&
                          i?.last_name?.length > 0 &&
                          !isDisabled
                            ? "border border-secondary "
                            : "border border-secondary"
                        } ${
                          i?.last_name && i?.last_name?.length > 0 && isDisabled
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                        name="last_name"
                        id="last_name"
                        tabIndex={1}
                        defaultValue={i?.last_name}
                        placeholder=""
                        aria-label="first_name"
                        onChange={(e) => handleInputChange(e, index)}
                        required
                        readOnly={isDisabled}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    {isDisabled && !(i?.middle_name?.length > 0) ? null : (
                      <div
                        className={`col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0`}
                      >
                        <label className="form-label" htmlFor="middle_name">
                          Middle name
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            i?.middle_name &&
                            i?.middle_name?.length > 0 &&
                            !isDisabled
                              ? "border border-secondary "
                              : "border border-secondary"
                          } ${
                            i?.middle_name &&
                            i?.middle_name?.length > 0 &&
                            isDisabled
                              ? "border border-secondary"
                              : "border border-secondary"
                          }`}
                          name="middle_name"
                          id="middle_name"
                          tabIndex={1}
                          defaultValue={i?.middle_name}
                          placeholder=""
                          aria-label="middle_name"
                          onChange={(e) => handleInputChange(e, index)}
                          required
                          readOnly={isDisabled}
                        />
                        <span className="invalid-feedback">
                          Please enter middle name.
                        </span>
                      </div>
                    )}
                    {/* d-none d-md-block d-lg-none d-xl-none */}
                    {isDisabled && !(i?.dob?.length > 0) ? null : (
                      <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0 d-none d-md-block d-lg-block d-xl-block">
                        <label className="form-label" htmlFor="dob">
                          Date of birth (linked to BVN)*
                        </label>
                        <input
                          type="date"
                          name="dob"
                          id="dob"
                          defaultValue={i?.dob}
                          onChange={(e) => handleInputChange(e, index)}
                          className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                            i?.dob && i?.dob?.length > 0 && !isDisabled
                              ? "border border-secondary "
                              : "border border-secondary"
                          } ${
                            i?.dob && i?.length > 0 && !isDisabled
                              ? "border border-secondary "
                              : "border border-secondary"
                          }`}
                          readOnly={isDisabled}
                        />
                        <span className="invalid-feedback">
                          Please enter date of birth.
                        </span>
                      </div>
                    )}
                  </div>

                  {/* displays only on sm screens */}
                  <div className="row d-sm-block d-md-none d-lg-none d-xl-none mb-4">
                    {isDisabled && !(i?.dob?.length > 0) ? null : (
                      <div className="col">
                        <label className="form-label" htmlFor="dob">
                          Date of birth (DOB linked to BVN)*
                        </label>
                        <input
                          type="date"
                          name="dob"
                          id="dob"
                          defaultValue={i?.dob}
                          onChange={(e) => handleInputChange(e, index)}
                          className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                            i?.dob && i?.dob?.length > 0 && !isDisabled
                              ? "border border-secondary "
                              : "border border-secondary"
                          } ${
                            i?.dob && i?.length > 0 && !isDisabled
                              ? "border border-secondary "
                              : "border border-secondary"
                          }`}
                          readOnly={isDisabled}
                        />
                        <span className="invalid-feedback">
                          Please enter date of birth.
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                      <label className="form-label" htmlFor="email">
                        Email Address (linked to BVN)*
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          i?.email && i?.email?.length > 0 && !isDisabled
                            ? "border border-secondary "
                            : "border border-secondary"
                        } ${
                          i?.email && i?.email?.length > 0 && isDisabled
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                        name="email"
                        id="email"
                        tabIndex={1}
                        defaultValue={i?.email}
                        placeholder=""
                        aria-label="email"
                        onChange={(e) => handleInputChange(e, index)}
                        required
                        readOnly={isDisabled}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                      <label className="form-label" htmlFor="mobile_number">
                        Phone Number (linked to BVN)*
                      </label>
                      <PhoneInput
                        country={"ng"}
                        value={i?.phone_number}
                        disabled={isDisabled}
                        onChange={(phoneNumber: any) => {
                          const e: any = {
                            target: {
                              name: "phone_number",
                              value: phoneNumber,
                            },
                          };
                          handleInputChange(e, index);
                        }}
                        inputClass={`form-control form-control-lg  w-100 ${
                          i?.phone_number &&
                          i?.phone_number?.length > 0 &&
                          !isDisabled
                            ? "border border-secondary "
                            : "border border-secondary"
                        } ${
                          i?.phone_number &&
                          i?.phone_number?.length > 0 &&
                          isDisabled
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                      <label className="form-label" htmlFor="bvn">
                        BVN*
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          i?.bvn && i?.bvn?.length === 11 && !isDisabled
                            ? "border border-secondary "
                            : "border border-secondary"
                        } ${
                          i?.bvn && i?.bvn?.length > 11 && !isDisabled
                            ? "border border-danger "
                            : "border border-secondary"
                        } ${
                          i?.bvn && isDisabled
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                        name="bvn"
                        id="bvn"
                        tabIndex={1}
                        defaultValue={i?.bvn}
                        placeholder=""
                        aria-label="bvn"
                        onChange={(e) => handleInputChange(e, index)}
                        required
                        readOnly={isDisabled}
                      />
                      <span className="invalid-feedback">
                        BVN must not be greater or less than 11 digits.
                      </span>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                      <label className="form-label" htmlFor="email">
                        Country*
                      </label>
                      {isDisabled === true ? (
                        <input
                          name="country"
                          id="country"
                          tabIndex={1}
                          defaultValue={i?.country}
                          placeholder=""
                          aria-label="country"
                          required
                          readOnly={isDisabled}
                          className={`form-control border border-secondary`}
                        />
                      ) : (
                        <Select
                          aria-label="country"
                          options={COUNTRY_JSON}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.label}
                          onChange={(option: any) => {
                            const e = {
                              target: { name: "country", value: option?.value },
                            };
                            setStateValue(option);
                            handleInputChange(e, index);
                          }}
                          classNamePrefix="react-select"
                          placeholder="Select..."
                          isClearable
                          isSearchable
                          styles={colourStyles}
                          defaultInputValue={i?.country}
                          defaultValue={defaultVal[0]}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    {isDisabled && !i?.city ? null : (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                          <label className="form-label" htmlFor="email">
                            City*
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              i?.city && i?.city?.length > 0 && !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.city && i?.city?.length > 0 && isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }`}
                            name="city"
                            id="city"
                            tabIndex={1}
                            defaultValue={i?.city}
                            placeholder=""
                            aria-label="city"
                            onChange={(e) => handleInputChange(e, index)}
                            required
                            readOnly={isDisabled}
                          />
                        </div>
                      </>
                    )}
                    <div
                      className={`col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0`}
                    >
                      <label className="form-label" htmlFor="email">
                        State*
                      </label>

                      {isDisabled === true ? (
                        <input
                          name="state"
                          id="state"
                          tabIndex={1}
                          defaultValue={i?.state}
                          placeholder=""
                          aria-label="state"
                          required
                          readOnly={isDisabled}
                          className={`form-control border border-secondary`}
                        />
                      ) : (
                        <Select
                          aria-label="country"
                          options={STATE_BY_COUNTRYCODE(statevalue?.isoCode)}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.label}
                          onChange={(option: any) => {
                            const e = {
                              target: { name: "state", value: option?.value },
                            };
                            handleInputChange(e, index);
                          }}
                          classNamePrefix="react-select"
                          placeholder="Select..."
                          isClearable
                          isSearchable
                          styles={colourStyles}
                          defaultInputValue={i?.state}
                          defaultValue={defaultStateVal[0]}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col">
                      <label className="form-label" htmlFor="address">
                        Address*
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          i?.address && i?.address?.length > 0 && !isDisabled
                            ? "border border-secondary "
                            : "border border-secondary"
                        } ${
                          i?.address && i?.address?.length > 0 && isDisabled
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                        name="address"
                        id="address"
                        tabIndex={1}
                        defaultValue={i?.address}
                        placeholder=""
                        aria-label="address"
                        onChange={(e) => handleInputChange(e, index)}
                        required
                        readOnly={isDisabled}
                      />
                    </div>
                  </div>

                  <div className="row mb-4 text-center">
                    {i?.image !== "" && (
                      <div className="col mt-3">
                        <Image
                          src={i?.image}
                          rounded
                          style={{ width: "200px", height: "250px" }}
                        />
                      </div>
                    )}
                    <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                      <div>
                        <label
                          htmlFor={`${index + 2}`}
                          className="btn btn-primary btn-lg"
                          hidden={isDisabled}
                          onClick={() => setModalShow(true)}
                        >
                          <i className="bi bi-camera"></i>{" "}
                          {track
                            ? "Uploading"
                            : i?.image
                            ? "Change Picture"
                            : "Upload a picture"}
                        </label>
                        <input
                          type="file"
                          id={`${index + 2}`}
                          name={`${index + 2}`}
                          accept=".png, .jpg, .jpeg, .pdf, .docx"
                          onChange={(e) => handlePictureUpload(e, index)}
                          style={{ display: "none" }}
                        />
                      </div>
                      <span
                        className={``}
                        style={{
                          marginTop: "0.5rem",
                          fontSize: "0.875em",
                          color: "#8c98a4",
                        }}
                      >
                        passport photograph (pdf, png, jpg and jpeg format
                        accepted.)
                      </span>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col">
                      <label className="form-label">
                        Valid Means of Business Owner(s) ID*
                      </label>
                      <select
                        className={`form-select form-control ${
                          i?.valid_means_of_identification &&
                          i?.valid_means_of_identification?.length > 0 &&
                          !isDisabled
                            ? "border border-secondary "
                            : "border border-secondary"
                        } ${
                          i?.valid_means_of_identification &&
                          i?.valid_means_of_identification?.length > 0 &&
                          isDisabled
                            ? "border border-secondary"
                            : "border border-secondary"
                        }`}
                        id="valid_means_of_identification"
                        name="valid_means_of_identification"
                        onChange={(e) => handleInputChange(e, index)}
                        defaultValue={i?.valid_means_of_identification}
                        disabled={isDisabled}
                      >
                        <option value="">Select identification</option>
                        {IDENTITY.map((identification) => {
                          return (
                            <option
                              value={identification.value}
                              key={identification.label}
                            >
                              {identification.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {i?.valid_means_of_identification === "nin" ? (
                    <div className="row mb-4">
                      <div className="col">
                        <label className="form-label" htmlFor="idno">
                          {i?.valid_means_of_identification === "nin" &&
                            "NIN Number*"}

                          {i?.valid_means_of_identification ===
                            "driver_license" && "License Number*"}
                        </label>
                        <>
                          {i?.valid_means_of_identification === "nin" && (
                            <input
                              type="text"
                              className={`form-control ${
                                i?.nin?.nin_number &&
                                i?.nin?.nin_number?.length > 0 &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nin?.nin_number &&
                                i?.nin?.nin_number?.length > 0 &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              name={"nin_number"}
                              id={"nin_number"}
                              tabIndex={1}
                              placeholder=""
                              aria-label={i?.nin?.nin_number}
                              onChange={(e) => handleInputChange(e, index)}
                              required
                              defaultValue={i?.nin?.nin_number}
                              readOnly={isDisabled}
                            />
                          )}
                          {i?.valid_means_of_identification ===
                            "driver_license" && (
                            <input
                              type="text"
                              className={`form-control ${
                                i?.driver_license?.number &&
                                i?.driver_license?.number?.length > 0 &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.driver_license?.number &&
                                i?.driver_license?.number?.length > 0 &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              name={"number"}
                              id={"number"}
                              tabIndex={1}
                              placeholder=""
                              aria-label={i?.driver_license?.number}
                              onChange={(e) => handleInputChange(e, index)}
                              required
                              defaultValue={i?.driver_license?.number}
                              readOnly={isDisabled}
                            />
                          )}
                        </>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {i?.valid_means_of_identification === "driver_license" && (
                    <>
                      <div className="row mb-4">
                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                          <label className="form-label" htmlFor="idno">
                            License Number*
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              i?.driver_license?.number &&
                              i?.driver_license?.number?.length > 0 &&
                              !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.driver_license?.number &&
                              i?.driver_license?.number?.length > 0 &&
                              isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }`}
                            name={"number"}
                            id={"number"}
                            tabIndex={1}
                            placeholder=""
                            aria-label={i?.driver_license?.number}
                            onChange={(e) => {
                              handleInputChange(e, index);
                            }}
                            required
                            defaultValue={i?.driver_license?.number}
                            readOnly={isDisabled}
                          />
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                          <label className="form-label" htmlFor="issue_date">
                            Issued Date*
                          </label>
                          <input
                            type="date"
                            name="issue_date"
                            id="issue_date"
                            onChange={(e) => {
                              setIssueDate(e.target.value);
                              // handleInputChange(e, index);
                              let updatedFields = [...fields];
                              updatedFields[index]["driver_license"] = {
                                ...updatedFields[index]["driver_license"],
                                issue_date: e.target.value,
                              };
                            }}
                            className={`js-daterangepicker form-control daterangepicker-custom-input ${
                              i?.driver_license?.issue_date &&
                              i?.driver_license?.issue_date?.length > 0 &&
                              !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.driver_license?.expiry_date?.length > 0 &&
                              i?.driver_license?.expiry_date <=
                                i?.driver_license?.issue_date &&
                              !isDisabled
                                ? "border border-danger "
                                : "border border-secondary"
                            } ${
                              i?.driver_license?.issue_date && isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }
                            `}
                            defaultValue={i?.driver_license?.issue_date}
                            readOnly={isDisabled}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col">
                          <label className="form-label" htmlFor="expiry_date">
                            Expiry Date*
                          </label>
                          <input
                            type="date"
                            name="expiry_date"
                            id="expiry_date"
                            onChange={(e) => {
                              setExpiryDate(e.target.value);

                              let updatedFields = [...fields];
                              updatedFields[index]["driver_license"] = {
                                ...updatedFields[index]["driver_license"],
                                expiry_date: e.target.value,
                              };
                            }}
                            className={`js-daterangepicker form-control daterangepicker-custom-input ${
                              i?.driver_license?.expiry_date &&
                              i?.driver_license?.expiry_date?.length > 0 &&
                              !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.driver_license?.expiry_date?.length > 0 &&
                              i?.driver_license?.expiry_date <=
                                i?.driver_license?.issue_date &&
                              !isDisabled
                                ? "border border-danger "
                                : "border border-secondary"
                            } ${
                              i?.driver_license?.expiry_date && isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }
                          `}
                            defaultValue={i?.driver_license?.expiry_date}
                            readOnly={isDisabled}
                          />
                          <span className="form-text invalid-feedback">
                            Expiry date should not be earlier than or the same
                            as issue date.
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {i?.valid_means_of_identification === "passport" && (
                    <>
                      <div className="row mb-4">
                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                          <label className="form-label" htmlFor="idno">
                            Passport Number*
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              i?.passport?.passport_number &&
                              i?.passport?.passport_number?.length > 0 &&
                              !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.passport?.passport_number &&
                              i?.passport?.passport_number?.length > 0 &&
                              isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }`}
                            name="passport_number"
                            id="passport_number"
                            tabIndex={1}
                            placeholder="id number"
                            aria-label="idno"
                            onChange={(e) => handleInputChange(e, index)}
                            required
                            defaultValue={i?.passport?.passport_number}
                            readOnly={isDisabled}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                          <label className="form-label" htmlFor="issue_date">
                            Issued Date*
                          </label>
                          <input
                            type="date"
                            name="issue_date"
                            id="issue_date"
                            onChange={(e) => {
                              setPassportIssue(e.target.value);
                              // handleInputChange(e, index);
                              let updatedFields = [...fields];
                              updatedFields[index]["passport"] = {
                                ...updatedFields[index]["passport"],
                                issue_date: e.target.value,
                              };
                              setFields(updatedFields);
                            }}
                            className={`js-daterangepicker form-control daterangepicker-custom-input ${
                              i?.passport?.issue_date &&
                              i?.passport?.issue_date?.length > 0 &&
                              !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.passport?.expiry_date?.length > 0 &&
                              i?.passport?.expiry_date <=
                                i?.passport?.issue_date &&
                              !isDisabled
                                ? "border border-danger "
                                : "border border-secondary"
                            } ${
                              i?.passport?.issue_date &&
                              i?.passport?.issue_date?.length > 0 &&
                              isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }`}
                            defaultValue={i?.passport?.issue_date}
                            readOnly={isDisabled}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col">
                          <label className="form-label" htmlFor="expiry_date">
                            Expiry Date*
                          </label>
                          <input
                            type="date"
                            name="expiry_date"
                            id="expiry_date"
                            onChange={(e) => {
                              setPassportExpire(e.target.value);
                              // handleInputChange(e, index);
                              let updatedFields = [...fields];
                              updatedFields[index]["passport"] = {
                                ...updatedFields[index]["passport"],
                                expiry_date: e.target.value,
                              };

                              setFields(updatedFields);
                            }}
                            className={`js-daterangepicker form-control daterangepicker-custom-input ${
                              i?.passport?.expiry_date &&
                              i?.passport?.expiry_date?.length > 0 &&
                              !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.passport?.expiry_date?.length > 0 &&
                              i?.passport?.expiry_date <=
                                i?.passport?.issue_date &&
                              !isDisabled
                                ? "border border-danger "
                                : "border border-secondary"
                            } ${
                              i?.passport?.expiry_date &&
                              i?.passport?.expiry_date?.length > 0 &&
                              isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }`}
                            defaultValue={i?.passport?.expiry_date}
                            readOnly={isDisabled}
                          />
                          <span className="form-text invalid-feedback">
                            Expiry date should not be earlier than or the same
                            as issue date.
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {i?.valid_means_of_identification === "voter_card" && (
                    <>
                      <div className="row mb-4">
                        <div className="col">
                          <label className="form-label" htmlFor="idno">
                            Voter Identification Number (VIN)*
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              i?.voter_card?.voter_card_number &&
                              i?.voter_card?.voter_card_number?.length > 0 &&
                              !isDisabled
                                ? "border border-secondary "
                                : "border border-secondary"
                            } ${
                              i?.voter_card?.voter_card_number &&
                              i?.voter_card?.voter_card_number?.length > 0 &&
                              isDisabled
                                ? "border border-secondary"
                                : "border border-secondary"
                            }`}
                            name="voter_card_number"
                            id="voter_card_number"
                            tabIndex={1}
                            placeholder="Voter Identification Number"
                            aria-label="idno"
                            onChange={(e) => handleInputChange(e, index)}
                            required
                            defaultValue={i?.voter_card?.voter_card_number}
                            readOnly={isDisabled}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {i?.valid_means_of_identification !== "" && (
                    <div className="row mb-4">
                      <div className="col">
                        <label className="form-label" htmlFor="firstname">
                          {i?.valid_means_of_identification === "nin"
                            ? "Upload NIN Card *"
                            : i?.valid_means_of_identification ===
                              "driver_license"
                            ? "Upload Driver license *"
                            : i?.valid_means_of_identification === "passport"
                            ? "Upload International Passport *"
                            : i?.valid_means_of_identification === "voter_card"
                            ? "Upload Voter's Card *"
                            : ""}
                        </label>

                        {i?.valid_means_of_identification === "nin" && (
                          <Dropzone
                            onFileExport={(url: any) => {
                              if (url) {
                                let updatedFields = [...fields];
                                if (
                                  updatedFields[index][
                                    "valid_means_of_identification"
                                  ] === "nin"
                                ) {
                                  updatedFields[index]["nin"]["nin_image"] =
                                    url;
                                  updatedFields[index]["nin"]["upload"] = url;
                                  setFields(updatedFields);
                                }
                              }
                            }}
                            isDisabled={isDisabled}
                            fileName={splitAwsString(i?.nin?.upload)}
                          />
                        )}

                        {i?.valid_means_of_identification ===
                          "driver_license" && (
                          <Dropzone
                            onFileExport={(url: any) => {
                              if (url) {
                                let updatedFields = [...fields];
                                if (
                                  updatedFields[index][
                                    "valid_means_of_identification"
                                  ] === "driver_license"
                                ) {
                                  updatedFields[index]["driver_license"][
                                    "upload"
                                  ] = url;
                                  setFields(updatedFields);
                                }
                              }
                            }}
                            isDisabled={isDisabled}
                            fileName={splitAwsString(i?.driver_license?.upload)}
                          />
                        )}

                        {i?.valid_means_of_identification === "passport" && (
                          <Dropzone
                            onFileExport={(url: any) => {
                              if (url) {
                                let updatedFields = [...fields];

                                if (
                                  updatedFields[index][
                                    "valid_means_of_identification"
                                  ] === "passport"
                                ) {
                                  updatedFields[index]["passport"]["upload"] =
                                    url;
                                  setFields(updatedFields);
                                }
                              }
                            }}
                            isDisabled={isDisabled}
                            fileName={splitAwsString(i?.passport?.upload)}
                          />
                        )}

                        {i?.valid_means_of_identification === "voter_card" && (
                          <Dropzone
                            onFileExport={(url: any) => {
                              if (url) {
                                let updatedFields = [...fields];

                                if (
                                  updatedFields[index][
                                    "valid_means_of_identification"
                                  ] === "voter_card"
                                ) {
                                  updatedFields[index]["voter_card"]["upload"] =
                                    url;
                                  setFields(updatedFields);
                                }
                              }
                            }}
                            isDisabled={isDisabled}
                            fileName={splitAwsString(i?.voter_card?.upload)}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {isDisabled === true ? (
                    <ul
                      id="basicStepFormProgress"
                      className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-1 mb-md-7 mb-lg-7 d-none d-md-block d-lg-block"
                    >
                      <li className="step-item">
                        <div
                          className="step-content-wrapper"
                          data-hs-step-form-next-options='{
        "targetSelector": "#basicStepDetails"
      }'
                        >
                          <div className="step-content pt-1">
                            <span
                              className="step-title pt-1 active"
                              style={{ color: "#1F7AFF", cursor: "pointer" }}
                            >
                              Next of kin
                            </span>
                          </div>
                        </div>
                      </li>

                      <li className="step-item">
                        <div
                          className="step-content-wrapper"
                          data-hs-step-form-next-options='{
         "targetSelector": "#basicStepTerms"
       }'
                        ></div>
                      </li>
                    </ul>
                  ) : (
                    <>
                      {/* <ul
                      id="basicStepFormProgress"
                      className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-1 mb-md-7 mb-lg-7"
                    >
                      <li className="step-item">
                        <div
                          className="step-content-wrapper"
                          data-hs-step-form-next-options='{
          "targetSelector": "#basicStepDetails"
        }'
                        >
                          <div className="step-content pt-1">
                            {i.showNextOfKin === false && (
                              <span
                                className="step-title pt-1 active"
                                style={{ color: "#1F7AFF", cursor: "pointer" }}
                                onClick={() => {
                                  addNextOfKinField(index);
                                  setAddDoc(true);
                                }}
                              >
                                <i className="bi-plus-circle me-1"></i>&nbsp;
                                Add Next of kin
                              </span>
                            )}
                            {i.showNextOfKin === true && (
                              <span
                                className="step-title pt-1 active"
                                style={{ color: "#1F7AFF", cursor: "pointer" }}
                                onClick={() => {
                                  removeNextOfKin(index);
                                  setAddDoc(false);
                                }}
                              >
                                <i className="bi bi-trash me-1"></i>&nbsp;
                                Remove Next of kin
                              </span>
                            )}
                          </div>
                        </div>
                      </li>

                      <li className="step-item">
                        <div
                          className="step-content-wrapper"
                          data-hs-step-form-next-options='{
           "targetSelector": "#basicStepTerms"
         }'
                        ></div>
                      </li>
                    </ul> */}
                      <ul
                        id="basicStepFormProgress"
                        className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-1 mb-md-7 mb-lg-7 d-none d-md-block d-lg-block"
                      >
                        <li className="step-item">
                          <div
                            className="step-content-wrapper"
                            data-hs-step-form-next-options='{
        "targetSelector": "#basicStepDetails"
      }'
                          >
                            <div className="step-content pt-1">
                              <span
                                className="step-title pt-1 active"
                                style={{ color: "#1F7AFF", cursor: "pointer" }}
                              >
                                Next of kin
                              </span>
                            </div>
                          </div>
                        </li>

                        <li className="step-item">
                          <div
                            className="step-content-wrapper"
                            data-hs-step-form-next-options='{
         "targetSelector": "#basicStepTerms"
       }'
                          ></div>
                        </li>
                      </ul>
                    </>
                  )}

                  <span
                    className="step-title pt-1 active d-sm-block d-md-none d-lg-none mb-5"
                    style={{ color: "#1F7AFF" }}
                  >
                    Next of kin
                  </span>

                  {i.showNextOfKin && (
                    <Collapse in={isDisabled === true ? true : addDoc}>
                      <div id="example-collaps-text">
                        <div className="row mb-4">
                          <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                            <label
                              className="form-label"
                              htmlFor="nok_firstname"
                            >
                              First Name*
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                i?.nextOfKin?.nok_firstname &&
                                i?.nextOfKin?.nok_firstname !== "" &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nextOfKin?.nok_firstname &&
                                i?.nextOfKin?.nok_firstname !== "" &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              name="nok_firstname"
                              id="nok_firstname"
                              tabIndex={1}
                              value={i?.nextOfKin?.nok_firstname}
                              placeholder=""
                              aria-label=""
                              onChange={(e) => handleInputChange(e, index)}
                              required
                              readOnly={isDisabled}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                            <label
                              className="form-label"
                              htmlFor="nok_lastname"
                            >
                              Last Name*
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                i?.nextOfKin?.nok_lastname &&
                                i?.nextOfKin?.nok_lastname !== "" &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nextOfKin?.nok_lastname &&
                                i?.nextOfKin?.nok_lastname !== "" &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              name="nok_lastname"
                              id="nok_lastname"
                              tabIndex={1}
                              value={i?.nextOfKin?.nok_lastname}
                              placeholder=""
                              aria-label="nok_lastname"
                              onChange={(e) => handleInputChange(e, index)}
                              required
                              readOnly={isDisabled}
                            />
                          </div>
                        </div>

                        <div
                          className="row mb-4"
                          hidden={
                            isDisabled &&
                            !(i?.nextOfKin?.nok_middlename?.length > 0)
                          }
                        >
                          <div className="col">
                            <label
                              className="form-label"
                              htmlFor="nok_middlename"
                            >
                              Middle name
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                i?.nextOfKin?.nok_middlename &&
                                i?.nextOfKin?.nok_middlename !== "" &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nextOfKin?.nok_middlename &&
                                i?.nextOfKin?.nok_middlename !== "" &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              name="nok_middlename"
                              id="nok_middlename"
                              tabIndex={1}
                              defaultValue={i?.nextOfKin?.nok_middlename}
                              placeholder=""
                              aria-label="nok_middlename"
                              onChange={(e) => handleInputChange(e, index)}
                              required
                              readOnly={isDisabled}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                            <label className="form-label" htmlFor="nok_email">
                              Email*
                            </label>
                            <input
                              type="email"
                              className={`form-control ${
                                i?.nextOfKin?.nok_email &&
                                i?.nextOfKin?.nok_email !== "" &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nextOfKin?.nok_email &&
                                i?.nextOfKin?.nok_email !== "" &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              name="nok_email"
                              id="nok_email"
                              tabIndex={1}
                              value={i?.nextOfKin?.nok_email}
                              placeholder=""
                              aria-label="nok_email"
                              onChange={(e) => handleInputChange(e, index)}
                              required
                              readOnly={isDisabled}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
                            <label
                              className="form-label"
                              htmlFor="mobile_number"
                            >
                              Phone number*
                            </label>
                            <PhoneInput
                              country={"ng"}
                              value={i?.nextOfKin?.nok_mobile_number}
                              disabled={isDisabled}
                              onChange={(phoneNumber: any) => {
                                const e: any = {
                                  target: {
                                    name: "nok_mobile_number",
                                    value: phoneNumber,
                                    id: "nok_mobile_number",
                                  },
                                };
                                handleInputChange(e, index);
                              }}
                              inputClass={`form-control form-control-lg w-100 ${
                                i?.nextOfKin?.nok_mobile_number &&
                                i?.nextOfKin?.nok_mobile_number !== "" &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nextOfKin?.nok_mobile_number &&
                                i?.nextOfKin?.nok_mobile_number !== "" &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <label className="form-label" htmlFor="nok_address">
                              House address*
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                i?.nextOfKin?.nok_address &&
                                i?.nextOfKin?.nok_address !== "" &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nextOfKin?.nok_address &&
                                i?.nextOfKin?.nok_address !== "" &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              name="nok_address"
                              id="nok_address"
                              tabIndex={1}
                              value={i?.nextOfKin?.nok_address}
                              placeholder=""
                              aria-label="nok_address"
                              onChange={(e) => handleInputChange(e, index)}
                              required
                              readOnly={isDisabled}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <label
                              className="form-label"
                              htmlFor="nok_relationship"
                            >
                              Relationship*
                            </label>
                            <select
                              className={`form-select form-control  ${
                                i?.nextOfKin?.nok_relationship &&
                                i?.nextOfKin?.nok_relationship?.length > 0 &&
                                !isDisabled
                                  ? "border border-secondary "
                                  : "border border-secondary"
                              } ${
                                i?.nextOfKin?.nok_relationship &&
                                i?.nextOfKin?.nok_relationship?.length > 0 &&
                                isDisabled
                                  ? "border border-secondary"
                                  : "border border-secondary"
                              }`}
                              id="nok_relationship"
                              name="nok_relationship"
                              onChange={(e) => handleInputChange(e, index)}
                              defaultValue={i?.nextOfKin?.nok_relationship}
                              disabled={isDisabled}
                            >
                              <option value="">Select</option>
                              {RELATIONSHIP_WITH_NOK.map(
                                (item: any): JSX.Element => {
                                  return (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  )}
                </div>
              );
            })}

          <div className="row mb-4" hidden={isDisabled}>
            {fields.length > 1 ? (
              <>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-secondary w-100 border border-secondary"
                    onClick={addField}
                  >
                    <i className="bi bi-plus-lg"></i>&nbsp;&nbsp; Add new owner
                  </button>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-secondary w-100 border border-secondary"
                    onClick={() => {
                      if (fields.length > 1) {
                        return removeField(fields.length - 1);
                      }
                    }}
                  >
                    <i className="bi bi-dash-lg"></i>&nbsp;&nbsp; Remove owner
                  </button>
                </div>
              </>
            ) : (
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-secondary w-100 border border-secondary"
                  onClick={addField}
                >
                  <i className="bi bi-plus-lg"></i>&nbsp;&nbsp; Add new owner
                </button>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default MandatoryRegisteredFields;
