import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Success } from "../../assets/transfer_successful.svg";
import { ReactComponent as Failure } from "../../assets/ttansfer_failure.svg";
import {
  formatAmount,
  formatAmountWithDecimal,
} from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  useResolveOTPForPin,
  useSendTransfer,
  useSetPin,
  useVerifyOTPforPIn,
} from "../../hooks/transfer/index.hook";
import { sessionInterface } from "../../utils/interfaces";
import OtpFields from "../otp/OtpFields";
import SpinnerComponent from "../spinner/Spinner";
import SetPin from "./SetPin";

function MakeTransferModalMobile({
  show,
  handleClose,
  refetch,
  data,
  mobileStage = "initiate",
  from,
}: {
  show: boolean;
  handleClose: () => void;
  refetch?: any;
  data?: any;
  mobileStage?: string;
  from?: string;
}): JSX.Element {
  const navigate = useNavigate();
  const { resolve_transfer } = useSendTransfer();
  const { deviceInfo } = useUserDeviceInfo();

  const { resolve_otp_pin } = useResolveOTPForPin();
  const { resolve_set_pin } = useSetPin();
  const { resolve_verify_otp_pin } = useVerifyOTPforPIn();
  const session = useSession();

  const [pin, setPin] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [stage, setStage] = useState<string>(mobileStage);
  const [errorMsg, setErrorMsg] = useState<string>("");

  // SET OTP
  const [otpSent, setOTPSent] = useState<boolean | string>(false);
  const [error, setError] = useState<any>(false);
  const [errorObj, setErrorObj] = useState<any>({
    ques: false,
    answ: false,
  });
  const [answer, setAnswer] = useState<string>("");
  const [secQuestion, setSecQuestion] = useState<string>("");
  const [trxnPin, setTrxnPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");

  const merchant_data: sessionInterface = session?.data?.data?.data;

  const transactionPinSet = merchant_data?.state?.transaction_pin_set;

  const charge =
    merchant_data?.charge_type !== "percentage"
      ? parseFloat(merchant_data?.transfer_fee)
      : data?.amount * (parseFloat(merchant_data?.charge_percentage) / 100) <
        200
      ? data?.amount * (parseFloat(merchant_data?.charge_percentage) / 100)
      : 200;

  const amount = parseFloat(data?.amount);

  const handlePay = async (data: any) => {
    setLoading(true);
    try {
      const response: any = await resolve_transfer.mutateAsync({
        beneficiary_account_name: data?.beneficiary_account_name,
        amount: data?.amount,
        narration: data?.narration,
        save_beneficiary: data?.save_beneficiary,
        beneficiary_account_no: data?.beneficiary_account_no,
        beneficiary_bank: data?.beneficiary_bank,
        transaction_pin: pin,
      });
      if (response.status === 202) {
        setLoading(false);
        // moengage.track_event("INITIATE_TRANSFER", {
        //   amount: data?.amount,
        //   narration: data?.narration,
        //   beneficiary_account_no: data?.beneficiary_account_no,
        //   beneficiary_bank: data?.beneficiary_bank,
        //   platform: deviceInfo?.userAgent,
        // });
        setStage("success");
        handleSuccess();
      }
    } catch (error: any) {
      setLoading(false);
      setStage("failure");
      setErrorMsg(error?.response?.data?.data);
      if (error?.response?.status === 403) {
        toast.error(error?.response?.data?.data);
      } else {
        // handleStage("Failed", error?.response?.data?.data);
      }
      return error;
    }
    setLoading(false);
  };

  const handleSuccess = (data?: any) => {
    return (
      <div className="text-center summary">
        <p className="">Transfer successful!</p>

        <Success className="mb-2" />

        <h4 className="text-card">
          NGN {formatAmountWithDecimal(data?.amount?.toString())}
        </h4>

        <small className="transferred-to">TRANSFERRED TO</small>

        <div className="d-flex flex-column">
          <p className="">{data?.beneficiary_account_name}</p>
          <span>
            <small className="bank_name">{data?.beneficiary_bank_name}</small>
            <small className="">{data?.beneficiary_account_no}</small>
          </span>
        </div>

        <button
          onClick={() => {
            handleClose();
            setStage("initiate");
            navigate("/merchant/transfer");
          }}
          className="btn btn-primary btn-block w-100 --bor-pri my-4"
        >
          Done
        </button>
      </div>
    );
  };

  const handleFailure = () => {
    return (
      <div className="text-center summary">
        <p className="">Transfer failure!</p>

        <Failure className="mb-2" />

        <div className="d-flex flex-column">
          <p className="">{errorMsg}</p>
        </div>

        <button
          onClick={() => {
            handleClose();
            setStage("initiate");
          }}
          className="btn btn-primary btn-block w-100 --bor-pri"
        >
          Try again
        </button>
      </div>
    );
  };

  const sendOTP = async () => {
    setLoading(true);
    try {
      const response = await resolve_otp_pin.mutateAsync();
      if (response.status === 200) {
        //  setShow(true);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      return error;
    }
  };

  const handleOtpChange = async (otp: any) => {
    if (otp.length === 6) {
      try {
        await resolve_verify_otp_pin.mutateAsync({ auth_code: otp });
        setOTPSent(otp);
      } catch (error: any) {
        toast.error(error?.response?.data?.data);
        return error;
      }
    }
  };

  const handleSetPin = async () => {
    try {
      await resolve_set_pin.mutateAsync({
        transaction_pin: trxnPin,
        security_question: secQuestion,
        security_answer: answer,
        auth_code: otpSent,
      });
      session?.refetch();

      moengage.track_event("SET_PIN", {
        PIN: pin,
        security_question: secQuestion,
        email_otp: otpSent,
        success_status: true,
        platform: deviceInfo?.userAgent,
      });
      session?.refetch();
      from === "profile" ? refetch() : setStage("initiate");
    } catch (error: any) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.data);
      }
      return error;
    }
  };

  return (
    <>
      <Modal
        show={stage === "initiate" && show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 d-flex align-items-center">
            <p className="text-dark text-start">Confirm Transfer</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center summary">
            <div className="text-center">
              <h6 className="card-subtitle">
                Provide transaction Pin to complete
              </h6>
            </div>
            <h4 className="text-card">
              NGN {formatAmountWithDecimal(data?.amount?.toString())}
            </h4>
          </div>

          {/* BODY */}
          <div className="d-flex pt-4 flex-column summary-body">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <label className="card-subtitle">Bank:</label>
              <p className="text-right text-cap">
                {data?.beneficiary_bank_name}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <label className="card-subtitle">Amount:</label>
              <p className="text-right text-cap">
                {formatAmount(data?.amount)}{" "}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <label className="card-subtitle">Charge:</label>
              <p className="text-right text-cap">
                {merchant_data?.charge_type !== "percentage"
                  ? formatAmount(parseFloat(merchant_data?.transfer_fee))
                  : formatAmount(
                      data?.amount *
                        (parseFloat(merchant_data?.charge_percentage) / 100) <
                        200
                        ? data?.amount *
                            (parseFloat(merchant_data?.charge_percentage) / 100)
                        : 200
                    )}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center ">
              <label className="card-subtitle">Beneficiary</label>
              <p className="text-right text-cap">
                {data?.beneficiary_account_name}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <label className="card-subtitle">Account number:</label>
              <p className="text-right text-cap">
                {data?.beneficiary_account_no}
              </p>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center summary-body">
              <label className="card-subtitle">Total amount</label>
              <p className="text-right text-cap">
                {formatAmount(amount + charge)}
              </p>
            </div>

            <hr />
            {transactionPinSet === true && (
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex pt-4 flex-column align-items-center">
                  <p className="card-subtitle">
                    Enter transaction pin to confirm
                  </p>
                  <OtpFields
                    numInputs={4}
                    secret
                    value={pin}
                    onOtpInput={(value: string) => {
                      setPin(value);
                    }}
                    inputMode="number"
                    style={{ padding: "10px", width: "100%" }}
                    inputStyles={{
                      borderColor: "#CBD1EC",
                      padding: "0.6125rem 1rem",
                      margin: "0px 6px",
                    }}
                    readOnly={false}
                    serectDelay={2800}
                  />
                </div>
              </div>
            )}

            {transactionPinSet === false ? (
              <button
                onClick={() => {
                  sendOTP();
                  setStage("set-pin");
                }}
                className="btn btn-primary btn-block w-100 --bor-pri my-2"
              >
                Set PIN
                {loading && (
                  <>
                    &emsp;
                    <SpinnerComponent size="sm" variant="dark" />
                  </>
                )}
              </button>
            ) : (
              <button
                onClick={async () => {
                  moengage.track_event("INITIATE_TRANSFER", {
                    amount: data?.amount,
                    narration: data?.narration,
                    beneficiary_account_no: data?.beneficiary_account_no,
                    beneficiary_bank: data?.beneficiary_bank,
                    platform: deviceInfo?.userAgent,
                  });
                  await handlePay({ ...data });
                  setPin("");
                }}
                disabled={pin.length !== 4 || loading === true}
                className="btn btn-primary btn-block w-100 --bor-pri my-2"
              >
                Continue
                {loading && (
                  <>
                    &emsp;
                    <SpinnerComponent size="sm" variant="dark" />
                  </>
                )}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {stage === "success" || stage === "failure" ? (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
          size="sm"
        >
          <>
            {stage === "success" && (
              <Modal.Body>{handleSuccess(data)}</Modal.Body>
            )}

            {stage === "failure" && <Modal.Body>{handleFailure()}</Modal.Body>}
          </>
        </Modal>
      ) : null}

      {stage === "set-pin" ? (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
          size="sm"
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Set up PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!otpSent ? (
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-row justify-contents-center">
                  <p className="text-center">
                    Please enter the 6-digit OTP sent to your business mail.
                  </p>
                </div>
                <div className="w-75 my-3 text-center">
                  <p className="fs-6 mb-2">Enter OTP</p>
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <OtpFields onOtpInput={handleOtpChange} readOnly={false} />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row justify-contents-center">
                    <p className="">
                      Didn’t receieve OTP?{" "}
                      <button
                        onClick={() => sendOTP()}
                        className="fw-bold btn-link btn"
                      >
                        Resend OTP <i className="fw-bold bi bi-arrow-right"></i>
                      </button>
                    </p>
                  </div>
                  <div className="mx-auto text-center">
                    {resolve_verify_otp_pin?.isLoading || loading ? (
                      <>
                        <SpinnerComponent />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="d-flex pt-4 flex-column align-items-center">
                  <p className="text-start w-75">Choose PIN</p>
                  <OtpFields
                    numInputs={4}
                    secret
                    onOtpInput={(value: string, index: number) => {
                      setTrxnPin(value);
                    }}
                    value={trxnPin}
                    readOnly={false}
                    inputMode="number"
                    style={{ padding: "10px" }}
                    inputStyles={{
                      borderColor: "#CBD1EC",
                      margin: "0px 6px",
                      padding: "0.6125rem 1rem",
                    }}
                  />
                </div>
                <div className="d-flex pt-4 flex-column align-items-center">
                  <p className="text-start w-75">Confirm PIN</p>
                  <OtpFields
                    numInputs={4}
                    secret
                    onOtpInput={(value: string, index: number) => {
                      setConfirmPin(value);
                      if (value[index] !== trxnPin[index]) {
                        setError(true);
                      } else {
                        setError(false);
                      }
                    }}
                    value={confirmPin}
                    readOnly={false}
                    inputMode="number"
                    style={{ padding: "10px" }}
                    inputStyles={{
                      borderColor: "#FF003D",
                      margin: "0px 6px",
                      padding: "0.6125rem 1rem",
                    }}
                    error={error}
                  />
                </div>
                <br />
                <div className="my-2 mt-4">
                  <div className="row mb-3">
                    <div className="col-12">
                      <label htmlFor="question" className="mb-2 card-subtitle">
                        Set your security question
                      </label>
                      <select
                        className={`form-select ${
                          !errorObj.ques || secQuestion.length > 0
                            ? "--bor-pri"
                            : "is-invalid"
                        }`}
                        onBlur={() => {
                          setErrorObj((prev: any) => ({
                            ...prev,
                            ques: true,
                          }));
                        }}
                        aria-label="Set your security question"
                        onChange={(e) => setSecQuestion(e?.target?.value)}
                        value={secQuestion}
                      >
                        <option value="">Select one</option>
                        <option value="What is the name of your favorite TV show ?">
                          What is the name of your favorite TV show ?
                        </option>
                        <option value="What is the name of your dream car ?">
                          What is the name of your dream car ?
                        </option>
                        <option value="What is the name of your dream city to live in ?">
                          What is the name of your dream city to live in ?
                        </option>
                        <option value="What is the name of your best friend ?">
                          What is the name of your best friend ?
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className="col-12">
                      <label htmlFor="answer" className="mb-2 card-subtitle">
                        Your answer
                      </label>
                      <input
                        id="answer"
                        name="answer"
                        onChange={(e) => setAnswer(e?.target?.value)}
                        onBlur={() => {
                          setErrorObj((prev: any) => ({
                            ...prev,
                            answ: true,
                          }));
                        }}
                        value={answer}
                        type="text"
                        className={`form-control ${
                          !errorObj.answ || answer.length > 0
                            ? "--bor-pri"
                            : "is-invalid"
                        }`}
                        placeholder=""
                        aria-label="answer"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="d-flex mt-4 flex-column align-items-center">
                  <button
                    onClick={() => handleSetPin()}
                    disabled={
                      !secQuestion ||
                      !answer ||
                      confirmPin !== trxnPin ||
                      !trxnPin ||
                      !confirmPin
                    }
                    className="btn btn-primary btn-block w-100 --bor-pri my-2"
                  >
                    Continue{" "}
                    {resolve_set_pin?.isLoading && (
                      <>
                        {" "}
                        &nbsp;
                        <SpinnerComponent size="sm" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}

export default MakeTransferModalMobile;
