import React from "react";
import { Modal } from "react-bootstrap";
import SpinnerComponent from "../spinner/Spinner";

function ShareInvoiceModal({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}): JSX.Element {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Share link</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row mb-4">
          <div className="col">
            <div className="input-group mb-3">
              <input
                type="url"
                className="form-control border border-secondary"
                placeholder="https://pooler.com/pay/j75ebt271s"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <span
                className="input-group-text border border-secondary"
                id="basic-addon2"
                style={{ cursor: "pointer" }}
              >
                Copy link
              </span>
            </div>
            <span className="invalid-feedback">
              Please enter valid first name.
            </span>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <label className="form-label" htmlFor="email">
              Email*
            </label>
            <input
              type="email"
              className={`form-control  border border-secondary`}
              name="email"
              id="email"
              tabIndex={1}
              placeholder="e.g michael@email.com"
              aria-label="email@address.com"
              autoComplete="off"
              required
            />
            <span className="invalid-feedback">
              Please enter a valid email address.
            </span>
          </div>
        </div>

        <div className="w-75 mx-auto text-center mt-4 pt-3">
          <button
            type="button"
            className="btn btn-primary w-75 mx-auto text-center"
          >
            Share link
            {false ? (
              <SpinnerComponent variant="light" size="sm" />
            ) : (
              <i className="bi bi-arrow-up-right ps-1"></i>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ShareInvoiceModal;
