import moengage from "@moengage/web-sdk";
import React from "react";
import { capitalizeString } from "../../helpers/index.helper";
import useUserDeviceInfo from "../../hooks/device.hook";
import useResize from "../../hooks/resize.hook";
import { payoutprops } from "../../utils/interfaces";
import OverlayTooltip from "../OverlayTooltip";
import CreatePayoutAcc from "../modal/CreatePayoutAcc";
import InitiatePayout from "../modal/InitiatePayout";
import ViewAccountModal from "../modal/viewAccountModal";

function PayoutHeader({ session }: payoutprops) {
  const { size } = useResize();
  const { deviceInfo } = useUserDeviceInfo();
  const disbleTransfer =
    session?.state?.active_domain === "test"
      ? false
      : session?.account_limit === null
      ? true
      : false;

  return (
    <>
      <CreatePayoutAcc />
      <InitiatePayout />
      <ViewAccountModal />

      {/* LARGE AND MEDIUM SCREENS */}
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Payouts</h1>
          </div>
          {size[0] < 768 && (
            <div className="d-block d-md-none d-lg-none d-xl-none">
              {session?.hasPayoutEnabled === false ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#createCashierModal"
                >
                  <i className="bi bi-plus-circle"></i> Create Account
                </button>
              ) : (
                <>
                  {disbleTransfer ? (
                    <OverlayTooltip
                      tooltipMsg={capitalizeString(
                        "You can not make Payout until KYC is completed. Kindly complete your KYC then proceed to make a payout."
                      )}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ opacity: "0.3" }}
                        // data-bs-toggle="modal"
                        // data-bs-target="#createAKIKeyModal"
                      >
                        <i className="bi-plus me-1"></i> Initiate Payout
                      </button>
                    </OverlayTooltip>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#createAKIKeyModal"
                      disabled={disbleTransfer}
                    >
                      <i className="bi-plus me-1"></i> Initiate Payout
                    </button>
                  )}
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-soft-info"
                    data-bs-toggle="modal"
                    data-bs-target="#welcomeMessageModal"
                    onClick={() => {
                      moengage.track_event("VIEW_PAYOUT_ACC", {
                        success: true,
                        platform: deviceInfo?.userAgent,
                      });
                    }}
                  >
                    <i className="bi-eye me-1"></i> See Account
                  </button>
                </>
              )}
            </div>
          )}

          <div className="col-sm-auto">
            {session?.hasPayoutEnabled === false ? (
              <>
                {size[0] < 768 ? null : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#createCashierModal"
                  >
                    <i className="bi bi-plus-circle"></i> Create Account
                  </button>
                )}
              </>
            ) : (
              <>
                {size[0] < 768 ? null : (
                  <>
                    {disbleTransfer ? (
                      <OverlayTooltip
                        tooltipMsg={capitalizeString(
                          "You can not make Payout until KYC is completed. Kindly complete your KYC then proceed to make a payout."
                        )}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ opacity: "0.3" }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#createAKIKeyModal"
                        >
                          <i className="bi-plus me-1"></i> Initiate Payout
                        </button>
                      </OverlayTooltip>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#createAKIKeyModal"
                        disabled={disbleTransfer}
                      >
                        <i className="bi-plus me-1"></i> Initiate Payout
                      </button>
                    )}
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-soft-info"
                      data-bs-toggle="modal"
                      data-bs-target="#welcomeMessageModal"
                    >
                      <i className="bi-eye me-1"></i> See Account
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PayoutHeader;
