import React from "react";
import { Modal } from "react-bootstrap";
import SpinnerComponent from "../spinner/Spinner";

function DeleteInvoiceModal({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}): JSX.Element {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete invoice</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row mb-4">
          <span className="text-center mx-auto text-dark">
            Are you sure you want to delete
          </span>
          <span className="text-center mx-auto text-dark">
            <strong className="fw-bold">Invoice 123456.</strong> This action
            cannot be reversed
          </span>
        </div>

        <div className="w-100 mx-auto text-center mt-4 pt-3">
          <button
            type="button"
            className="btn btn-danger w-100 mx-auto text-center"
          >
            Delete invoice
            {false && <SpinnerComponent variant="light" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteInvoiceModal;
