import { useNavigate } from "react-router-dom";
import ReverseIcon from "../vectors/reverse-icon";
import RecycleIcon from "../vectors/recycle-icon";
import MyModal from "../modal/Modal";

function LinkModal({
  show,
  handleClose,
}: {
  handleClose: () => void;
  show: boolean;
}) {
  const navigate = useNavigate();

  return (
    <>
      <MyModal size="lg" id="link-modal" title="New Payment Page">
        <div className="row justify-content-center">
          <div className="col-sm-4 mb-3 mb-sm-0 text-center">
            <div
              style={{ minHeight: "266px", height: "100%" }}
              className="card shadow-none"
            >
              <div className="card-body align-items-center d-flex flex-column justify-content-center">
                <div className="my-auto">
                  <div
                    style={{ background: "#F0F1F9", width: "max-content" }}
                    className="p-3 mb-3 rounded-circle mx-auto"
                  >
                    <ReverseIcon />
                  </div>
                  <h5 className="card-title fs-6">One-time Payment</h5>
                  <p className="card-text mb-4">
                    One-time payment for your customers to make single payment.
                  </p>
                </div>
                <button
                  style={{ color: "#128100", fontWeight: "500 !important" }}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    navigate("/merchant/business/one-time-link", {
                      state: {
                        is_recurrring: false,
                        title: "One-time payment",
                      },
                    });
                  }}
                  className="btn text-green-500 btn-outlined mt-auto "
                >
                  Create link
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-4 text-center">
            <div
              style={{ minHeight: "266px", height: "100%" }}
              className="card shadow-none"
            >
              <div className="card-body align-items-center d-flex flex-column justify-content-center">
                <div className="my-auto">
                  <div
                    style={{ background: "#F0F1F9", width: "max-content" }}
                    className="p-3 mb-3 rounded-circle mx-auto"
                  >
                    <RecycleIcon />
                  </div>
                  <h5 className="card-title fs-6">Recurring Payment</h5>
                  <p className="card-text mb-4">
                    Create link for recurring payments.
                  </p>
                </div>
                <button
                  style={{ color: "#128100", fontWeight: "500 !important" }}
                  className="btn text-green-500 btn-outlined mt-auto "
                  data-bs-dismiss="modal"
                  onClick={() => {
                    navigate("/merchant/business/recurring-link", {
                      state: {
                        is_recurrring: true,
                        title: "Recurring Payment",
                      },
                    });
                  }}
                >
                  Create link
                </button>
              </div>
            </div>
          </div>
        </div>
      </MyModal>
    </>
  );
}

export default LinkModal;
