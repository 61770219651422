/* eslint-disable jsx-a11y/anchor-is-valid */
import moengage from "@moengage/web-sdk";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";

import {
  useGenerateWebhook,
  useGetKeys,
  useInitiateTwoFa,
  useSession,
  useSetSecondaryBank,
  useSupportedBanks,
  useViewKeys,
} from "../../hooks/auth/auth.hook";

import { mode } from "../../helpers/index.helper";
import { securiyKeyInterface, sessionInterface } from "../../utils/interfaces";
import IPModal from "../modal/IPModal";
import ApiKeyModal from "../modal/apiKey";
import SecretModal from "../modal/secret_key";
import TwoFactorModal from "../modal/twoFactor";
import SpinnerComponent from "../spinner/Spinner";

function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="#8F9BB2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        clipPath="url(#clip0_7471_61060)"
      >
        <path d="M7.667 1a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.333zM7.667 10.334V7.667M7.667 5.002h-.008"></path>
      </g>
      <defs>
        <clipPath id="clip0_7471_61060">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="rotate(-180 8 8)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function BankIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
    >
      <path
        fill="#A5CAFF"
        d="M6.214 1a1 1 0 011.572 0l1.023 1.3a1 1 0 00.77.382l1.632.025a1 1 0 01.964 1.2l-.36 1.77a1 1 0 00.176.794l1.066 1.442a1 1 0 01-.34 1.48l-1.52.795a1 1 0 00-.51.66l-.398 1.699a1 1 0 01-1.41.672l-1.443-.699a1 1 0 00-.872 0l-1.444.7a1 1 0 01-1.41-.673l-.396-1.7a1 1 0 00-.51-.659l-1.521-.795a1 1 0 01-.34-1.48L2.008 6.47a1 1 0 00.176-.794l-.36-1.77a1 1 0 01.964-1.2l1.631-.025a1 1 0 00.77-.382L6.215 1z"
      ></path>
    </svg>
  );
}

function ProfileBody(): JSX.Element {
  const inputRefs: any = useRef([]);
  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const submitRef: any = useRef<HTMLDivElement | null>(null);
  const { view_keys } = useViewKeys();
  const { keys_data } = useGetKeys();
  const { data, refetch } = useSession();
  const { initiate_2fa } = useInitiateTwoFa();
  const {
    supported_banks = [
      {
        name: "KREDI MONEY MFB",
        bank_code: "090380",
      },
      {
        name: "PYRAMID MFB",
        bank_code: "090657",
      },
      {
        name: "HERITAGE BANK",
        bank_code: "000020",
      },
    ],
  } = useSupportedBanks();

  const merchant_data: sessionInterface = data?.data?.data;
  const { generate_webhook } = useGenerateWebhook();
  const { saveSecondaryBank } = useSetSecondaryBank();
  const two_fa_enabled: boolean | undefined | any =
    merchant_data?.state?.two_fa_enabled;

  const keysInfo = keys_data?.data?.data;

  const ipAddresses: string[] | any = merchant_data?.state?.ip_address;
  const result = ipAddresses?.join(", ");

  const [copy, setCopied] = useState<boolean>(false);
  const [copyPubKey, setCopyPubKey] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [webhook, setWebhook] = useState<string>("");
  const [modalData, setModalData] = useState({
    title: "",
    type: "",
    action: "",
  });
  const [show, setShow] = useState(false);
  const [selectedBanks, setSelectedBanks] = useState<any[]>([]);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  // secret key
  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  // pub key
  const [eyePub, setEyePub] = useState<boolean>(false);
  const [passwordOpenPub, setPasswordOpenPub] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSelectedBankOpen, setIsSelectedBankOpen] = useState<boolean>(false);
  const [spin, setSpin] = useState<boolean>(false);
  const [ipModal, setIpModal] = useState<boolean>(false);
  const [twoFactorData, setTwofactordata] = useState({
    QR_CODE: "",
    authSecret: "",
    actionType: "",
  });

  const toggleSecret = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };

  const togglePublic = () => {
    setPasswordOpenPub(passwordOpenPub ? false : true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [exportedData, setExportedData] = useState<securiyKeyInterface | any>(
    {}
  );

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const handleCopyPubKey = () => {
    setCopyPubKey(true);
    setTimeout(() => {
      setCopyPubKey(false);
    }, 5000);
  };

  const handleDataExport = (data: any) => {
    setExportedData(data);
  };

  const submitWebhook = async (payload: { webhook_url: string }) => {
    setIsLoading(true);
    try {
      const response = await generate_webhook.mutateAsync(payload);
      if (response.status === 200) {
        setIsLoading(false);
        refetch();
      }
    } catch (error) {
      return error;
    }
    setIsLoading(false);
  };

  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = e.target.value;
    setOtp(updatedOtp);
    if (e.target.value !== "") {
      if (index < inputRefs?.current?.length - 1) {
        inputRefs?.current[index + 1]?.focus();
      }
    } else {
      if (index > 0) {
        inputRefs?.current[index - 1]?.focus();
      }
    }
    const areNotEmptyStrings = updatedOtp.every((element) => element !== "");
    if (areNotEmptyStrings) {
      handleViewsecurityKey(updatedOtp);
    }
  };

  const handleViewsecurityKey = (updatedOtp: any) => {
    const auth_code = getOtpValue(updatedOtp);
    if (auth_code) {
      handleView(auth_code);
    }
  };

  const handleView = (auth_code: string) => {
    setIsLoading(true);
    view_keys
      .mutateAsync(auth_code)
      .then((data) => {
        setOtp(["", "", "", "", "", ""]);
        setExportedData(data?.data?.data);
        handleClose();
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.data);
        setOtp(["", "", "", "", "", ""]);
        buttonRef.current?.click();
      })
      .finally(() => {
        setIsLoading(false);
        setOtp(["", "", "", "", "", ""]);
        buttonRef.current?.click();
      });
  };

  const handleOtpPaste = (e: any) => {
    e?.preventDefault();
    const pastedData = e?.clipboardData?.getData("text/plain")?.slice(0, 6);
    const updatedOtp = [...otp];
    for (let i = 0; i < 6; i++) {
      if (pastedData[i]) {
        updatedOtp[i] = pastedData[i];
      } else {
        break;
      }
    }
    setOtp(updatedOtp);
    if (inputRefs.current[5]) {
      inputRefs.current[5].focus(); // 123456
    }
  };

  const getOtpValue = (otp: any) => {
    return otp.join("");
  };

  const initiate2FA = async () => {
    setSpin(true);
    try {
      const response = await initiate_2fa.mutateAsync();
      if (response.status === 200) {
        moengage.add_user_attribute("success_2fa", true);
        setSpin(false);
        setTwofactordata((prev) => ({
          ...prev,
          QR_CODE: response?.data?.data?.authImage,
          authSecret: response?.data?.data?.authSecret,
          actionType: "enable-2fa",
        }));
        setIsModalOpen(true);
      }
    } catch (error) {
      setSpin(false);
      return error;
    }
    setSpin(false);
  };

  const handleLogout = () => {
    setIsModalOpen(false);
  };

  const handleChange = async (item: any) => {
    const secondaryBanks = merchant_data?.secondary_banks || [];
    const combinedBanks = [...secondaryBanks, ...selectedBanks];
    const bankExists = combinedBanks?.some(
      (i) => i?.bank_code === item?.bank_code
    );

    if (bankExists) {
      const updatedBanks = secondaryBanks?.filter(
        (n) => n?.bank_code !== item?.bank_code
      );
      setSelectedBanks((prev) =>
        prev?.filter((n) => n?.bank_code !== item?.bank_code)
      );
      await saveSecondaryBank?.mutateAsync(updatedBanks);
    } else {
      const updatedBanks = [...secondaryBanks, item];
      setSelectedBanks((prev) => [...prev, item]);
      await saveSecondaryBank?.mutateAsync(updatedBanks);
    }

    await refetch();
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {
        <style>
          {`
          .tooltip-inner {
            background-color: white !important;
            color: var(--bs-tooltip-bg);
            box-shadow: 14.62px 21.93px 27.41px 0px #A3A3A317;
          }
          .bs-tooltip-top .tooltip-arrow::before {
            border-top-color: var(--bs-tooltip-color);
          }
          `}
        </style>
      }
      You can add more banks as supporting accounts for your business
    </Tooltip>
  );

  const secondary_bank_name = merchant_data?.secondary_banks?.map(
    (i) => i.bank_code
  );

  return (
    <>
      <ApiKeyModal />
      <SecretModal
        data={modalData}
        webhook_url={webhook}
        onDataExport={handleDataExport}
      />
      <TwoFactorModal
        show={isModalOpen}
        handleClose={() => handleLogout()}
        QR_CODE={twoFactorData.QR_CODE}
        authSecret={twoFactorData.authSecret}
        actionType={twoFactorData.actionType}
        merchant_data={merchant_data}
      />
      <IPModal
        show={ipModal}
        handleClose={() => setIpModal(false)}
        twoFA={two_fa_enabled}
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title" id="exampleModalLabel">
              Validate OTP
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <p>Enter the code from the authenticator app in the field below.</p>
          </div>
          <form>
            <div className="row gx-2 gx-sm-3 mt-4">
              {otp.map((value, index) => (
                <div className="col" key={index}>
                  <div className="mb-4">
                    <input
                      type="text"
                      className="form-control form-control-single-number spin-button-one"
                      name="code1"
                      placeholder=""
                      aria-label=""
                      maxLength={1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleOtpChange(e, index)
                      }
                      value={value}
                      onPaste={handleOtpPaste}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                      onPasteCapture={handleOtpPaste}
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer hidden>
          <div className="row align-items-sm-center flex-grow-1 mx-n2 justify-content-end">
            <div className="col-sm-auto">
              <div className="d-flex gap-3">
                <button
                  type="button"
                  className="btn btn-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={buttonRef}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  ref={submitRef}
                  onClick={() => handleViewsecurityKey(otp)}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="col-lg-8">
        <div className="d-grid gap-3 gap-lg-5">
          {/* PROFILE */}
          <div className="card">
            <div className="card-header">
              <h2 className="card-title h4 card-subtitle">Basic information</h2>
            </div>

            <div className="card-body">
              <form>
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">first name</span>
                        </label>
                        <div className="input-group input-group-sm-vertical">
                          <input
                            type="text"
                            className="form-control border border-secondary"
                            name="firstName"
                            id="firstNameLabel"
                            placeholder="Your first name"
                            aria-label="Your first name"
                            value={merchant_data ? merchant_data.firstname : ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">last name</span>
                        </label>
                        <div className="input-group input-group-sm-vertical">
                          <input
                            type="text"
                            className="form-control border border-secondary"
                            name="lastName"
                            id="lastNameLabel"
                            placeholder="Your last name"
                            aria-label="Your last name"
                            value={merchant_data ? merchant_data?.lastname : ""}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">email</span>
                        </label>
                        <div className="input-group input-group-sm-vertical">
                          <input
                            type="text"
                            className="form-control border border-secondary"
                            name="firstName"
                            id="firstNameLabel"
                            placeholder="Your first name"
                            aria-label="Your first name"
                            value={merchant_data ? merchant_data?.email : ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">phone number</span>
                        </label>
                        <div className="input-group input-group-sm-vertical">
                          <input
                            type="text"
                            className="form-control border border-secondary"
                            name="lastName"
                            id="lastNameLabel"
                            placeholder="Your last name"
                            aria-label="Your last name"
                            value={
                              merchant_data ? merchant_data?.mobile_number : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col">
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">organisation</span>
                        </label>
                        <div className="input-group input-group-sm-vertical">
                          <input
                            type="text"
                            className="form-control border border-secondary"
                            name="organization"
                            id="organizationLabel"
                            placeholder="Your first name"
                            aria-label="Your first name"
                            value={
                              merchant_data ? merchant_data?.business_name : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">
                            business address
                          </span>
                        </label>
                        <div className="input-group input-group-sm-vertical ">
                          <input
                            type="text"
                            className="form-control border border-secondary"
                            name="address"
                            id="addressLabel"
                            placeholder="Your first name"
                            aria-label="Your first name"
                            value={merchant_data?.address || ""}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4" hidden>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-8">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">
                            business address
                          </span>
                        </label>
                        <div className="input-group input-group-sm-vertical ">
                          <input
                            type="text"
                            className="form-control border border-secondary"
                            name="address"
                            id="addressLabel"
                            placeholder="Your first name"
                            aria-label="Your first name"
                            value={merchant_data?.address || ""}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Bank */}
          <div className="card">
            <div className="card-header">
              <h2 className="card-title h4 card-subtitle">Bank</h2>
            </div>

            <div className="card-body">
              <form>
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6 d-flex flex-column">
                        <label className="form-label">
                          {" "}
                          <span className="card-subtitle">Primary bank</span>
                        </label>
                        <div
                          style={{
                            width: "fit-content",
                            height: "30px",
                            padding: 8,
                            background: "#2C1DFF",
                            borderRadius: 8,
                            overflow: "hidden",
                            color: "#E9F2FF",
                            gap: 8,
                            marginRight: 10,
                          }}
                          className="mb-2 d-flex justify-content-center align-items-center"
                        >
                          <BankIcon />
                          &nbsp;
                          {merchant_data?.primary_bank?.name}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label d-flex"
                          htmlFor="business_type"
                        >
                          <span className="card-subtitle d-flex">
                            Other banks &emsp;
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                              trigger={["hover", "focus"]}
                            >
                              <div style={{ width: "auto" }}>
                                <InfoIcon />
                              </div>
                            </OverlayTrigger>
                          </span>
                        </label>
                        <div
                          className={`form-select form-control-lg border border-secondary position-relative`}
                          id="validationValidSelect1"
                          onMouseEnter={() => setIsSelectedBankOpen(true)}
                          onMouseLeave={() => setIsSelectedBankOpen(false)}
                        >
                          Select Bank
                          {isSelectedBankOpen && (
                            <div
                              className="position-absolute"
                              style={{
                                width: "100%",
                                top: "100%",
                                backgroundColor: "white",
                                boxShadow: "0px 15px 25px 0px #00000026",
                                padding: 10,
                                borderRadius: 8,
                                left: 0,
                              }}
                            >
                              {supported_banks
                                ?.filter(
                                  (n: any) =>
                                    n.bank_code !==
                                    merchant_data?.primary_bank?.bank_code
                                )
                                ?.filter(
                                  (item: { bank_code: string }) =>
                                    !secondary_bank_name?.includes(
                                      item?.bank_code
                                    )
                                )
                                ?.map((i: any) => {
                                  return (
                                    <div key={i.value} className="py-2">
                                      <div className="form-check">
                                        <input
                                          className={`form-check-input border border-secondary`}
                                          type="checkbox"
                                          defaultChecked={secondary_bank_name?.includes(
                                            i?.bank_code
                                          )}
                                          onChange={() => handleChange(i)}
                                          id="agreed_terms"
                                          name="agreed_terms"
                                          disabled={
                                            saveSecondaryBank?.isLoading ||
                                            merchant_data?.secondary_banks
                                              ?.map((i: any) => i.bank_code)
                                              ?.includes(i?.bank_code)
                                          }
                                        />
                                        <label
                                          className={`form-check-label`}
                                          htmlFor="termsCheckbox"
                                          // onClick={() => handleDownload()}
                                        >
                                          {i.name}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                        <br />
                        <div className="d-flex flex-wrap">
                          {merchant_data?.secondary_banks?.map((i: any) => {
                            return (
                              <div
                                style={{
                                  width: "fit-content",
                                  height: "30px",
                                  padding: "6px 12px 6px 12px",
                                  background: "#E9F2FF",
                                  borderRadius: 8,
                                  overflow: "hidden",
                                  color: "#2C1DFF",
                                  gap: 8,
                                  marginRight: 10,
                                  border: "1px solid #1F7AFF",
                                }}
                                className="mb-2 d-flex justify-content-center align-items-center"
                              >
                                {i?.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <br />
            <br />
            <br />
          </div>

          {/* DEVELOPERS */}
          <div id="developers" className="card">
            <div className="card-header">
              <h4 className="card-title h4 card-subtitle">Developers</h4>
            </div>
            <div className="card-body">
              {/* IP WHITELIST */}
              <div className="mb-4" id="id-whitelist">
                <div className="row mb-4">
                  <div className="col">
                    <label
                      htmlFor="newEmailLabel"
                      className="col-sm-3 col-form-label form-label card-subtitle fw-bold"
                    >
                      IP Whitelist
                    </label>
                    <div className="d-flex gap-3">
                      <div className="" style={{ width: "86%" }}>
                        <input
                          type="url"
                          id="tooltipExample"
                          className="form-control border border-secondary"
                          value={result ? result : ""}
                          readOnly
                        />
                      </div>
                      <div className="">
                        <Button
                          variant="link"
                          style={{ color: "#128100", fontSize: "16px" }}
                          onClick={() => setIpModal(true)}
                        >
                          Add{" "}
                          <i
                            className="bi bi-plus-lg fw-bold"
                            style={{ fontSize: "16px" }}
                          ></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p>
                  <span className="fw-semibold card-subtitle">
                    security KEYS
                  </span>
                </p>

                {/* PRIVATE KEYS */}
                <div className="mb-4">
                  <div className="row mb-4">
                    <div className="col-sm-12">
                      <div className="row">
                        {two_fa_enabled === false ? (
                          <>
                            <div className="col">
                              <label
                                htmlFor="newEmailLabel"
                                className="col-sm-3 col-form-label form-label card-subtitle"
                              >
                                Secret Key
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  type={passwordOpen ? "text" : "password"}
                                  id="tooltipExample"
                                  className="form-control border border-secondary"
                                  value={keysInfo?.api_key || ""}
                                  readOnly
                                />
                                <button
                                  className="js-clipboard input-group-append input-group-text"
                                  data-bs-toggle="tooltip"
                                  title="Copy to clipboard!"
                                >
                                  {" "}
                                  {eyeOpen && (
                                    <>
                                      <OverlayTrigger
                                        key={"top"}
                                        placement={"top"}
                                        overlay={
                                          <Tooltip
                                            id={copy ? "Copied" : "Copy"}
                                          >
                                            {copy ? "Copied" : "Copy"}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          id="changePassIcon"
                                          className={`${
                                            copy
                                              ? "bi bi-check2-all text-success"
                                              : "bi bi-clipboard"
                                          }`}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              `${keysInfo?.api_key}`
                                            );
                                            handleCopy();
                                          }}
                                        />
                                      </OverlayTrigger>
                                    </>
                                  )}
                                  &nbsp;
                                  <i
                                    className={
                                      !eyeOpen ? "bi bi-eye" : "bi bi-eye-slash"
                                    }
                                    onClick={() => {
                                      toggleSecret();
                                      setEyeOpen(!eyeOpen);
                                    }}
                                  />
                                </button>
                              </div>
                            </div>

                            <div className="col">
                              <label
                                htmlFor="newEmailLabel"
                                className="col-sm-3 col-form-label form-label  card-subtitle"
                              >
                                Public Key
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  type={passwordOpenPub ? "text" : "password"}
                                  id="tooltipExample"
                                  className="form-control border border-secondary"
                                  value={keysInfo?.public_key || ""}
                                  readOnly
                                />
                                <button
                                  className="js-clipboard input-group-append input-group-text"
                                  data-bs-toggle="tooltip"
                                  title="Copy to clipboard!"
                                >
                                  {eyePub && (
                                    <>
                                      <OverlayTrigger
                                        key={"top"}
                                        placement={"top"}
                                        overlay={
                                          <Tooltip
                                            id={copyPubKey ? "Copied" : "Copy"}
                                          >
                                            {copyPubKey ? "Copied" : "Copy"}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          id="changePassIcon"
                                          className={`${
                                            copyPubKey
                                              ? "bi bi-check2-all text-success"
                                              : "bi bi-clipboard"
                                          }`}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              `${keysInfo?.public_key}`
                                            );
                                            handleCopyPubKey();
                                          }}
                                        />
                                      </OverlayTrigger>
                                    </>
                                  )}
                                  &nbsp;
                                  <i
                                    className={
                                      !eyePub ? "bi bi-eye" : "bi bi-eye-slash"
                                    }
                                    onClick={() => {
                                      togglePublic();
                                      setEyePub(!eyePub);
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col">
                              <label
                                htmlFor="newEmailLabel"
                                className="col-sm-3 col-form-label form-label  card-subtitle"
                              >
                                Secret Key
                              </label>
                              <div className="input-group input-group-merge">
                                {exportedData?.api_key ? (
                                  <input
                                    type={"text"}
                                    id="tooltipExample"
                                    className="form-control border border-secondary"
                                    value={exportedData?.api_key || ""}
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    type={"password"}
                                    id="tooltipExample"
                                    className="form-control border border-secondary"
                                    value="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                    readOnly
                                  />
                                )}

                                <button
                                  className="js-clipboard input-group-append input-group-text"
                                  data-bs-toggle="tooltip"
                                  title="Copy to clipboard!"
                                >
                                  {exportedData?.api_key ? (
                                    <>
                                      <OverlayTrigger
                                        key={"top"}
                                        placement={"top"}
                                        overlay={
                                          <Tooltip
                                            id={copy ? "Copied" : "Copy"}
                                          >
                                            {copy ? "Copied" : "Copy"}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          id="changePassIcon"
                                          className={`${
                                            copy
                                              ? "bi bi-check2-all text-success"
                                              : "bi bi-clipboard"
                                          }`}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              `${exportedData?.api_key}`
                                            );
                                            handleCopy();
                                          }}
                                        />
                                      </OverlayTrigger>
                                    </>
                                  ) : (
                                    <i
                                      className={`bi bi-eye-slash`}
                                      onClick={() => {
                                        setModalData((prev) => ({
                                          ...prev,
                                          title: "",
                                          type: "view",
                                          action: "view",
                                        }));
                                        handleShow();
                                      }}
                                    />
                                  )}
                                </button>
                              </div>
                            </div>

                            <div className="col">
                              <label
                                htmlFor="newEmailLabel"
                                className="col-sm-3 col-form-label form-label  card-subtitle"
                              >
                                Public Key
                              </label>

                              <div className="input-group input-group-merge">
                                {exportedData?.api_key ? (
                                  <input
                                    type={"text"}
                                    id="tooltipExample"
                                    className="form-control border border-secondary"
                                    value={exportedData?.public_key || ""}
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    type={"password"}
                                    id="tooltipExample"
                                    className="form-control border border-secondary"
                                    value="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                    readOnly
                                  />
                                )}

                                <button
                                  className="js-clipboard input-group-append input-group-text"
                                  data-bs-toggle="tooltip"
                                  title="Copy to clipboard!"
                                >
                                  {exportedData?.public_key ? (
                                    <>
                                      <OverlayTrigger
                                        key={"top"}
                                        placement={"top"}
                                        overlay={
                                          <Tooltip
                                            id={copyPubKey ? "Copied" : "Copy"}
                                          >
                                            {copyPubKey ? "Copied" : "Copy"}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          id="changePassIcon"
                                          className={`${
                                            copyPubKey
                                              ? "bi bi-check2-all text-success"
                                              : "bi bi-clipboard"
                                          }`}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              `${exportedData?.public_key}`
                                            );
                                            handleCopyPubKey();
                                          }}
                                        />
                                      </OverlayTrigger>
                                    </>
                                  ) : (
                                    <i
                                      className={`bi bi-eye-slash`}
                                      onClick={() => {
                                        setModalData((prev) => ({
                                          ...prev,
                                          title: "",
                                          type: "view",
                                          action: "view",
                                        }));
                                        handleShow();
                                      }}
                                    />
                                  )}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* WEBHOOK */}
              <div className="mb-4">
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <label
                      htmlFor="newEmailLabel"
                      className="col-sm-3 col-form-label form-label  card-subtitle"
                    >
                      Webhook URL
                    </label>
                    <div className="input-group input-group-merge">
                      {merchant_data?.webhook_url ||
                      merchant_data?.webhook_url !== "" ? (
                        <input
                          type="url"
                          id="tooltipExample"
                          className="form-control border border-secondary"
                          defaultValue={merchant_data?.webhook_url || ""}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setWebhook(e.target.value);
                          }}
                        />
                      ) : (
                        <input
                          type="url"
                          id="tooltipExample"
                          className="form-control border border-secondary"
                          value={webhook}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setWebhook(e.target.value);
                          }}
                        />
                      )}
                      {merchant_data?.webhook_url ||
                      merchant_data?.webhook_url !== "" ? (
                        <button className="js-clipboard input-group-append input-group-text">
                          <i
                            className="bi bi-pencil"
                            data-bs-toggle="modal"
                            data-bs-target="#createAKIKeyModal"
                          ></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                {merchant_data?.webhook_url ||
                merchant_data?.webhook_url === "" ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => submitWebhook({ webhook_url: webhook })}
                      disabled={
                        webhook.length <= 0 || webhook === "" ? true : false
                      }
                    >
                      Submit {isLoading && <SpinnerComponent size="sm" />}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* TWO-FACTPR AUTHENTICATION */}
          <div id="2fa" className="card">
            <div className="card-header">
              <h4 className="card-title h4 card-subtitle">
                Two-factor authentication
              </h4>
            </div>
            <div className="card-body">
              {two_fa_enabled === true ? (
                <div className="">
                  <p>
                    <i className="bi bi-exclamation-triangle link link-warning"></i>
                    &nbsp;Disabling your two factor simply means the following
                    without a second level authorisation
                  </p>
                  <ol type="1">
                    {[
                      "You will be allowed to login",
                      "You will be allowed to process payout",
                      "You will be able to view your API and Secret and Public Keys",
                    ].map((i) => {
                      return (
                        <li key={i} className="">
                          {i}
                        </li>
                      );
                    })}
                  </ol>
                  <p>Would you like to proceed?</p>
                </div>
              ) : (
                <>
                  <p className="">
                    Enable two-factor authentication to add an extra layer of
                    security to your account. Use an application such as{" "}
                    <a
                      href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline" }}
                      className="link link-dark"
                    >
                      Google Authenticator
                    </a>{" "}
                    to get an authorization code when prompted.
                  </p>

                  <p>
                    <a
                      className={``}
                      href="https://docs.poolerapp.com/#setting-up-authenticator-app-using-secret-key"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      Learn more in the docs
                    </a>{" "}
                    <i className="bi bi-arrow-up-right link link-primary"></i>
                  </p>
                </>
              )}
              {two_fa_enabled === true ? (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setIsModalOpen(true);
                    setTwofactordata((prev) => ({
                      ...prev,
                      QR_CODE: "",
                      authSecret: "",
                      actionType: "disable-2fa",
                    }));
                  }}
                >
                  Disable two-factor authentication{" "}
                  {spin && (
                    <>
                      &nbsp; <SpinnerComponent size="sm" />
                    </>
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    initiate2FA();
                  }}
                >
                  Enable two-factor authentication{" "}
                  {spin && (
                    <>
                      &nbsp; <SpinnerComponent size="sm" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        <div id="stickyBlockEndPoint"></div>
      </div>
    </>
  );
}

export default ProfileBody;
