import { useMutation, useQuery } from "react-query";
import { initQueryOptions } from "../../helpers/query.helper";
import API_SERVICE from "../../services/services";
import { RequestMethod } from "../../utils/enums";
import { instance } from "../../utils/instance";
import { setPinProps } from "../../utils/interfaces";

export const useSendTransfer = () => {
  const resolve_transfer: any = useMutation(
    (payload: {
      beneficiary_account_name: string;
      amount: number;
      narration: string;
      save_beneficiary: boolean;
      beneficiary_account_no: string;
      beneficiary_bank: string;
      pin: string;
    }): any => {
      return instance({
        url: "/merchant/transfer/initiate",
        method: RequestMethod.POST,
        data: { ...payload, currency_code: "NGN" },
      });
    },
    {
      ...initQueryOptions(),
    }
  );
  return { resolve_transfer };
};

export const useTransferInfo = (id?: string | number) => {
  const { data, error, isLoading, isSuccess, isError } = useQuery(
    ["transfers", "transfer", id],
    () => API_SERVICE._getTransactionInfo(id),
    initQueryOptions()
  );
  return { data, error, isError, isLoading, isSuccess };
};

export const useBenificiaries = () => {
  const { data, error, isLoading, isSuccess, isError } = useQuery(
    ["transfers", "transfer", "benificiaries"],
    () => API_SERVICE._getBenificiaries(),
    initQueryOptions()
  );
  const beneficiaryData = data;
  return { data, error, isError, isLoading, isSuccess, beneficiaryData };
};

export const useTransferDayUsage = () => {
  const { data, error, isLoading, isSuccess, isError, refetch } = useQuery(
    ["transfers", "transfer", "day_usage"],
    () => API_SERVICE._getTransferDayUsage(),
    initQueryOptions()
  );
  return { data, error, isError, isLoading, isSuccess, refetch };
};

export const useTransfers = (config?: Record<any, any>, page?: number) => {
  const { data, error, isLoading, isSuccess, isError, refetch, isFetching } =
    useQuery(
      [
        "transfers",
        config?.reference,
        config?.start_date && config?.end_date,
        page,
      ],
      () => API_SERVICE._getTransfers(config, page),
      initQueryOptions()
    );
  return { data, error, isError, isLoading, isSuccess, refetch, isFetching };
};

export const useTranactionExports = () => {
  const export_transfers: any = useMutation(
    (config: {
      start_date: string;
      end_date: string;
      download: string;
    }): any => {
      let queryString;
      if (config?.download === "date_range") {
        queryString = `?start_date=${config.start_date}&end_date=${config.end_date}&download=true`;
        return instance({
          url: `/merchant/transfers${queryString}`,
          method: RequestMethod.GET,
        });
      } else if (config?.download === "all") {
        queryString = `?download=true`;
        return instance({
          url: `/merchant/transfers${queryString}`,
          method: RequestMethod.GET,
        });
      }
    },
    {
      ...initQueryOptions(),
    }
  );
  return { export_transfers };
};

export const useResolveOTPForPin = () => {
  const resolve_otp_pin: any = useMutation(
    (): any => API_SERVICE._postSendOTPForPin(),
    {
      ...initQueryOptions(),
    }
  );
  return { resolve_otp_pin };
};

export const useVerifyOTPforPIn = () => {
  const resolve_verify_otp_pin: any = useMutation(
    (data: { auth_code: string }): any =>
      API_SERVICE._postVerifyOTPForPin(data),
    {
      ...initQueryOptions(),
    }
  );
  return { resolve_verify_otp_pin };
};

export const useSetPin = () => {
  const resolve_set_pin: any = useMutation(
    (data: setPinProps): any => API_SERVICE._postSetPin(data),
    {
      ...initQueryOptions(),
    }
  );
  return { resolve_set_pin };
};
