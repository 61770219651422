/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import { formprops } from "../../../utils/interfaces";
import { checkCloudinary } from "../../../helpers/index.helper";
import {
  GENDER,
  MARITAL_STATUS,
  MEANS_OF_IDENTITY,
  RELATIONSHIP_WITH_NOK,
} from "../../../utils/data";

function DirectorFields({ onFileExport, data }: formprops): JSX.Element {
  const preset: any = process.env.REACT_APP_CLOUDINARY_PRESET;
  const cloud_name: any = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const cloud_api: any = process.env.REACT_APP_CLOUDINARY_API_URL;

  const mapDataToFields = () => {
    if (data) {
      const result = data.map((i: any) => {
        return {
          firstname: i?.firstname || "",
          lastname: i?.lastname || "",
          email: i?.email || "",
          mobile_number: i?.mobile_number || "",
          designation: i?.designation || "",
          gender: i?.gender || "",
          marital_status: i?.marital_status,
          dateofbirth: i?.dateofbirth,
          address: i?.address,
          meansofidentity: i?.meansofidentity,
          idno: i?.idno,
          issuedate: i?.issuedate,
          expirydate: i?.expirydate,
          placeofissue: i?.placeofissue,
          document: { url: i?.document?.url, imageName: "" },
          showNextOfKin: false,
          nextOfKin: {
            nok_fullname: i?.nextOfKin?.nok_fullname,
            nok_relationship: i?.nextOfKin?.nok_relationship,
            nok_address: i?.nextOfKin?.nok_address,
            nok_mobile_number: i?.nextOfKin?.nok_mobile_number,
          },
        };
      });

      return result;
    } else {
      return [
        {
          firstname: "",
          lastname: "",
          email: "",
          mobile_number: "",
          designation: "",
          gender: "",
          marital_status: "",
          dateofbirth: "",
          address: "",
          meansofidentity: "",
          idno: "",
          issuedate: "",
          expirydate: "",
          placeofissue: "",
          document: { url: "", imageName: "" },
          showNextOfKin: false,
          nextOfKin: {
            nok_fullname: "",
            nok_relationship: "",
            nok_address: "",
            nok_mobile_number: "",
          },
        },
      ];
    }
  };

  const [fields, setFields] = useState(mapDataToFields());

  // updates the form fields
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | any
      | React.ChangeEvent<HTMLSelectElement>,
    index: any
  ) => {
    const values: any = [...fields];
    values[index][e?.target?.name] = e?.target?.value;
    setFields(values);
  };

  // adds new fields to the form
  const addField = () => {
    setFields([
      ...fields,
      {
        firstname: "",
        lastname: "",
        email: "",
        mobile_number: "",
        designation: "",
        gender: "",
        marital_status: "",
        dateofbirth: "",
        address: "",
        meansofidentity: "",
        idno: "",
        issuedate: "",
        expirydate: "",
        placeofissue: "",
        document: { url: "", imageName: "" },
        nextOfKin: {
          nok_fullname: "",
          nok_relationship: "",
          nok_address: "",
          nok_mobile_number: "",
        },
        showNextOfKin: false,
      },
    ]);
  };

  // updates the next of kin fields
  const addNextOfKinField = (index: any) => {
    const updatedFields = [...fields];
    updatedFields[index].showNextOfKin = true;
    setFields(updatedFields);
  };

  // removed next of kin fields
  const removeNextOfKin = (index: any) => {
    const updatedFields = [...fields];
    updatedFields[index].nextOfKin = {
      nok_fullname: "",
      nok_relationship: "",
      nok_address: "",
      nok_mobile_number: "",
    };
    updatedFields[index].showNextOfKin = false;
    setFields(updatedFields);
  };

  // updates next of kin fields
  const handleNextOfKinInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | any
      | React.ChangeEvent<HTMLSelectElement>,
    index: any
  ) => {
    const { name, value } = e.target;
    const updatedFields: any = [...fields];
    updatedFields[index]["nextOfKin"][name] = value;
    setFields(updatedFields);
  };

  // remove all fields
  const removeField = (index: any) => {
    if (index > 0) {
      let data = [...fields];
      data.splice(index, 1);
      setFields(data);
    }
  };

  // handles file upload to cloudinary server
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement> | any,
    index: any
  ) => {
    const { files } = event.target;
    const updatedFields = [...fields];
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", preset);
    await axios
      .post(`${cloud_api}/${cloud_name}/auto/upload`, formData)
      .then((response: any) => {
        const urlString: string | any =
          response?.data?.secure_url || response?.data?.url;
        const observeString = checkCloudinary(urlString);
        updatedFields[index]["document"].url = observeString;
        updatedFields[index]["document"].imageName = files[0]?.name;
        setFields(updatedFields);
      })
      .catch((error) => {
        return error?.response;
      });
  };

  const handleFileDrop = async (
    event: React.ChangeEvent<HTMLInputElement> | any,
    index: any
  ) => {
    event.preventDefault();
    const files: any = event?.dataTransfer?.files[0];
    const updatedFields = [...fields];
    const formData = new FormData();
    formData.append("file", files);
    formData.append("upload_preset", preset);
    await axios
      .post(`${cloud_api}/${cloud_name}/auto/upload`, formData)
      .then((response: any) => {
        const urlString: string | any =
          response?.data?.secure_url || response?.data?.url;
        const observeString = checkCloudinary(urlString);
        updatedFields[index]["document"].url = observeString;
        updatedFields[index]["document"].imageName = files[0]?.name;
        setFields(updatedFields);
      })
      .catch((error) => {
        return error?.response;
      });
  };

  const handleFileDrag = (event: any, index: any) => {
    event.preventDefault();
  };

  // exports the fields to the parent component
  useEffect(() => {
    onFileExport(fields);
  }, [fields, onFileExport]);

  return (
    <div
      className="w-100 content-space-t-0 content-space-t-lg-2 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      {fields.map((i: any, index: any) => {
        return (
          <div key={index}>
            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control "
                  name="firstname"
                  id="firstname"
                  value={i?.firstname || ""}
                  tabIndex={1}
                  placeholder="first name"
                  aria-label="first name"
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <span className="invalid-feedback">
                  Please enter first name.
                </span>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="signinSrEmail">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control "
                  name="lastname"
                  id="lastname"
                  tabIndex={1}
                  value={i?.lastname || ""}
                  placeholder="last name"
                  aria-label="last name"
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <span className="invalid-feedback">
                  Please enter a valid email address.
                </span>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="email">
                  Your email
                </label>
                <input
                  type="email"
                  className="form-control "
                  name="email"
                  id="email"
                  tabIndex={1}
                  value={i?.email || ""}
                  placeholder="email@address.com"
                  aria-label="email@address.com"
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <span className="invalid-feedback">
                  Please enter a valid email address.
                </span>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="mobile_number">
                  Phone number
                </label>
                <PhoneInput
                  country={"ng"}
                  value={i?.mobile_number || ""}
                  onChange={(phoneNumber: any) => {
                    const e: any = {
                      target: { value: phoneNumber, name: "mobile_number" },
                    };
                    handleChange(e, index);
                  }}
                  inputClass="form-control  w-100"
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="email">
                  Designation *
                </label>
                <input
                  type="email"
                  className="form-control "
                  name="designation"
                  id="designation"
                  tabIndex={1}
                  value={i?.designation || ""}
                  placeholder="e.g. Director"
                  aria-label="director"
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <span className="invalid-feedback">
                  Please enter a designation.
                </span>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  Sex
                </label>
                <select
                  className="form-select form-control  border border-secondary"
                  id="validationValidSelect1"
                  name="gender"
                  defaultValue={i?.gender}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option>Select identification</option>
                  {GENDER.map((item: any): JSX.Element => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                <span className="invalid-feedback">
                  Please select a category
                </span>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  Marital status
                </label>
                <select
                  className="form-select form-control  border border-secondary"
                  id="validationValidSelect1"
                  name="marital_status"
                  defaultValue={i?.marital_status}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option>Select</option>
                  {MARITAL_STATUS.map((item) => {
                    return (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                <span className="invalid-feedback">
                  Please select a category
                </span>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="dateofbirth"
                  value={i?.dateofbirth || ""}
                  onChange={(e) => handleChange(e, index)}
                  className="js-daterangepicker form-control daterangepicker-custom-input"
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="email">
                  Residential address *
                </label>
                <input
                  type="email"
                  className="form-control "
                  name="address"
                  id="address"
                  tabIndex={1}
                  value={i?.address}
                  placeholder="rensidential address"
                  aria-label="email@address.com"
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <span className="invalid-feedback">
                  Please enter an address.
                </span>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  Identification type*
                </label>
                <select
                  className="form-select form-control  border border-secondary"
                  id="validationValidSelect1"
                  name="meansofidentity"
                  defaultValue={i?.meansofidentity}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option>Select identification</option>
                  {MEANS_OF_IDENTITY.map((identification) => {
                    return (
                      <option value={identification} key={identification}>
                        {identification}
                      </option>
                    );
                  })}
                </select>
                <span className="invalid-feedback">
                  Please select a category
                </span>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="idno">
                  ID number *
                </label>
                <input
                  type="text"
                  className="form-control "
                  name="idno"
                  id="idno"
                  tabIndex={1}
                  value={i?.idno}
                  placeholder="id number"
                  aria-label="idno"
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <span className="invalid-feedback">
                  Please enter an ID number.
                </span>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  Date of issue *
                </label>
                <input
                  type="date"
                  name="issuedate"
                  value={i?.issuedate}
                  onChange={(e) => handleChange(e, index)}
                  className="js-daterangepicker form-control daterangepicker-custom-input"
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="form-label" htmlFor="firstname">
                  Expiry date *
                </label>
                <input
                  type="date"
                  name="expirydate"
                  value={i?.expirydate}
                  onChange={(e) => handleChange(e, index)}
                  className="js-daterangepicker form-control daterangepicker-custom-input"
                />
              </div>
              <div className="col">
                <label className="form-label" htmlFor="email">
                  Place of issue *
                </label>
                <input
                  type="text"
                  className="form-control "
                  name="placeofissue"
                  id="placeofissue"
                  tabIndex={1}
                  value={i?.placeofissue}
                  placeholder="place of issue"
                  aria-label="placeofissue"
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <span className="invalid-feedback">
                  Please enter a place of issue.
                </span>
              </div>
            </div>

            <div className="row mb-4">
              <label className="form-label" htmlFor="signinSrEmail">
                Upload identification documents *
              </label>
              <div className="col">
                <div className="px-5 d-none d-md-block d-lg-block">
                  <div
                    id="basicExampleDropzone"
                    className="js-dropzone row dz-dropzone dz-dropzone-card border border-secondary"
                    onDrop={(e) => {
                      handleFileDrop(e, index);
                    }}
                    onDragOver={(e) => {
                      handleFileDrag(e, index);
                    }}
                  >
                    <div className="dz-message">
                      {i?.document?.url ? (
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src={i?.document?.url}
                          alt="Description"
                        />
                      ) : (
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src="../../assets/svg/illustrations/oc-browse.svg"
                          alt="Description"
                        />
                      )}

                      <h5>Drag and drop your file here</h5>

                      <p className="mb-2">or</p>

                      <label htmlFor={"file"} className="btn btn-white btn-sm">
                        Browse files (supported file include png, jpeg, jpg and
                        pdf)
                      </label>
                      {i?.document?.imageName && (
                        <p className="form-label pt-3">
                          {i?.document?.imageName}
                        </p>
                      )}
                      <input
                        type="file"
                        id={"file"}
                        // accept="image/*"
                        accept=".png, .jpg, .jpeg, .pdf, .docx"
                        onChange={(e) => handleFileInputChange(e, index)}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="px-5 d-sm-block d-md-none d-lg-none">
                  <div
                    id="basicExampleDropzone"
                    className="js-dropzone row dz-dropzone dz-dropzone-carddd py-4"
                    style={{
                      background: "transparent !important",
                      border: "none !important",
                    }}
                    onDrop={(e) => {
                      handleFileDrop(e, index);
                    }}
                    onDragOver={(e) => {
                      handleFileDrag(e, index);
                    }}
                  >
                    <div className="dz-message">
                      {i?.document?.url ? (
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src={i?.document?.url}
                          alt="Description"
                        />
                      ) : (
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src="../../assets/svg/illustrations/oc-browse.svg"
                          alt="Description"
                        />
                      )}

                      <h5 style={{ fontSize: "0.6rem" }}>
                        Drag and drop your file here
                      </h5>

                      <p className="mb-2">or</p>

                      <label htmlFor={"file"} className="btn btn-white btn-sm">
                        Browse files
                      </label>
                      {i?.document?.imageName && (
                        <p className="form-label pt-3">
                          {i?.document?.imageName}
                        </p>
                      )}
                      <input
                        type="file"
                        id={"file"}
                        // accept="image/*"
                        accept=".png, .jpg, .jpeg, .pdf, .docx"
                        onChange={(e) => handleFileInputChange(e, index)}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ADD NEXT OF KIN BUTTON */}
            {i.showNextOfKin === false && (
              <div
                className="col-sm-9 mb-4"
                onClick={() => addNextOfKinField(index)}
              >
                <span
                  className="js-create-field form-link link link-primary"
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi-plus-circle me-1"></i>&nbsp;Add Next of Kin
                </span>
              </div>
            )}

            {/* REMOVE NEXT OF KIN BUTTON */}
            {i.showNextOfKin === true && (
              <div
                className="col-sm-9 mb-4"
                onClick={() => removeNextOfKin(index)}
              >
                <span
                  className="js-create-field form-link link link-primary"
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-trash"></i>&nbsp;Remove Next of kin
                </span>
              </div>
            )}

            {/* NEXT OF KIN FIELDS */}
            {i.showNextOfKin && (
              <>
                <div className="row mb-4">
                  <div className="col">
                    <label className="form-label" htmlFor="nok_fullname">
                      Full name *
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      name="nok_fullname"
                      id="nok_fullname"
                      tabIndex={1}
                      value={i?.nextOfKin?.nok_fullname}
                      placeholder="full name"
                      aria-label="full name"
                      onChange={(e) => handleNextOfKinInputChange(e, index)}
                      required
                    />
                    <span className="invalid-feedback">Please enter full.</span>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className="form-label" htmlFor="email">
                      Relationship *
                    </label>
                    <select
                      className="form-select form-control "
                      id="validationValidSelect1"
                      name="nok_relationship"
                      defaultValue={i?.nextOfKin?.nok_relationship}
                      onChange={(e) => handleNextOfKinInputChange(e, index)}
                    >
                      <option>Select</option>
                      {RELATIONSHIP_WITH_NOK.map((item: any): JSX.Element => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col">
                    <label className="form-label" htmlFor="mobile_number">
                      Phone number
                    </label>
                    <PhoneInput
                      country={"ng"}
                      value={i?.nextOfKin?.nok_relationship}
                      onChange={(phoneNumber: any) => {
                        const e: any = {
                          target: {
                            value: phoneNumber,
                            name: "nok_relationship",
                          },
                        };
                        handleNextOfKinInputChange(e, index);
                      }}
                      inputClass="form-control form-control-lg w-100"
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className="form-label" htmlFor="email">
                      Residential address *
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      name="nok_address"
                      id="nok_address"
                      tabIndex={1}
                      value={i?.nextOfKin?.nok_address}
                      placeholder="residential address"
                      aria-label="nok_address"
                      onChange={(e) => handleNextOfKinInputChange(e, index)}
                      required
                    />
                    <span className="invalid-feedback">
                      Please enter an address.
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
      <div className="row mb-4">
        {fields.length > 1 ? (
          <>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-secondary w-100 border border-secondary"
                onClick={addField}
              >
                <i className="bi bi-plus-lg"></i>&nbsp;&nbsp; Add new director
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-secondary w-100 border border-secondary"
                onClick={() => {
                  if (fields.length > 1) {
                    return removeField(fields.length - 1);
                  }
                }}
              >
                <i className="bi bi-dash-lg"></i>&nbsp;&nbsp; Remove
              </button>
            </div>
          </>
        ) : (
          <div className="col">
            <button
              type="button"
              className="btn btn-outline-secondary w-100 border border-secondary"
              onClick={addField}
            >
              <i className="bi bi-plus-lg"></i>&nbsp;&nbsp; Add new director
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default DirectorFields;
