import React, {forwardRef} from "react";
import {joinClasses} from "../../helpers/index.helper";
import SpinnerComponent from "../spinner/Spinner";
import "./style.css";

export interface IButtonClasses {
  base: string;
  fullWidth: string;
  variant: {
    primary: string;
    secondary: string;
    danger: string;
    warning: string;
    dark: string;
    white: string;
    success: string;
    none: string;
  };
  size: {
    sm: string;
    lg: string;
    normal: string;
  };
}

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullwidth?: boolean;
  size?: keyof IButtonClasses["size"];
  variant?: keyof IButtonClasses["variant"];
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  outlined?: boolean;
}

export const classes: IButtonClasses = {
  base: "btn",
  fullWidth: "w-100",
  size: {
    sm: "btn-sm",
    lg: "btn-lg",
    normal: "",
  },
  variant: {
    primary: "btn-primary",
    secondary: "btn-secondary",
    danger: "btn-danger",
    warning: "btn-warning",
    dark: "btn-warning",
    white: "btn-white",
    success: "btn-success",
    none: "",
  },
};

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      disabled,
      fullwidth,
      variant,
      size,
      className,
      type,
      loading,
      children,
      onClick,
      outlined,
      ...props
    },
    ref
  ) => {
    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      !disabled && !loading && onClick && onClick(event);
    };

    return (
      <button
        {...props}
        ref={ref}
        type={type ?? "button"}
        disabled={disabled || loading}
        onClick={handleClick}
        className={joinClasses(
          classes.base,
          className,
          classes.size[size ?? "normal"],
          outlined
            ? `${classes.variant[variant ?? "none"]
                .split("-")
                .join("-outline-")} btn-outlined`
            : classes.variant[variant ?? "primary"],
          fullwidth && classes.fullWidth,
          loading && "cursor-not-allowed"
        )}
        {...props}
      >
        {children}
        <span className="align-middle">
          {loading && <SpinnerComponent size="sm" />}
        </span>
      </button>
    );
  }
);

Button.displayName = "Button";
