import React from "react";
import Skeleton from "react-loading-skeleton";

function CustomerMetrics() {
  return (
    <div className="row">
      <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
        <div className="card">
          <div className="card-body text-start">
            {" "}
            <small className="text-cap">
              {" "}
              <i className="bi bi-people-fill"></i> Total Customers
            </small>
            {false ? (
              <span className="js-counter d-block display-3 text-dark mb-2">
                <Skeleton height={"100%"} width={200} />
              </span>
            ) : (
              <span className="js-counter d-block h4 text-dark mb-2">700</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerMetrics;
