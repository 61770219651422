const MerchantDetailsContent = () => {
	return (
		<div className="row">
			<div className="col-lg-4">

				<div className="card card-body mb-3 mb-lg-5">
					<h5>KYC Progress</h5>

					<div
						className="d-flex justify-content-between align-items-center"
					>
						<div className="progress flex-grow-1">
							<div
								className="progress-bar bg-primary"
								role="progressbar"
								style={{ width: "15%" }}
								aria-valuenow={15}
								aria-valuemin={0}
								aria-valuemax={100}
							></div>
						</div>
						<span className="ms-4">15%</span>
					</div>
				</div>

				<div className="card mb-3 mb-lg-5">
					<div className="card-header card-header-content-between">
						<h4 className="card-header-title">Profile</h4>
					</div>

					<div className="card-body">
						<ul className="list-unstyled list-py-2 text-dark mb-0">
							<li className="pb-0">
								<span className="card-subtitle">About</span>
							</li>
							<li>
								<i className="bi-person dropdown-item-icon"></i> Mark
								Williams
							</li>
							<li>
								<i className="bi-briefcase dropdown-item-icon"></i> No
								department
							</li>
							<li>
								<i className="bi-building dropdown-item-icon"></i> Pixeel
								Ltd.
							</li>

							<li className="pt-4 pb-0">
								<span className="card-subtitle">Contacts</span>
							</li>
							<li>
								<i className="bi-at dropdown-item-icon"></i> mark@site.com
							</li>
							<li>
								<i className="bi-phone dropdown-item-icon"></i> +1 (555)
								752-01-10
							</li>

							<li className="pt-4 pb-0">
								<span className="card-subtitle">Teams</span>
							</li>
							<li className="fs-6 text-body">
								<i className="bi-people dropdown-item-icon"></i> You are not
								a member of any teams
							</li>
							<li className="fs-6 text-body">
								<i className="bi-stickies dropdown-item-icon"></i> You are
								not working on any projects
							</li>
						</ul>
					</div>
				</div>
				<div className="card mb-3 mb-lg-5">
					<div className="card-header card-header-content-between">
						<h4 className="card-header-title">Settlement Account</h4>
					</div>

					<div className="card-body">
						<ul className="list-unstyled list-py-2 text-dark mb-0">
							<li className="pb-0">
								<span className="card-subtitle">About</span>
							</li>
							<li>
								<i className="bi-person dropdown-item-icon"></i> Mark
								Williams
							</li>
						</ul>
					</div>
				</div>

				<div className="card mb-3 mb-lg-5">
					<div className="card-header card-header-content-between">
						<h4 className="card-header-title">Fee and Charges</h4>
					</div>

					<div className="card-body">
						<ul className="list-unstyled list-py-2 text-dark mb-0">
							<li className="pb-0">
								<span className="card-subtitle">About</span>
							</li>
							<li>
								<i className="bi-person dropdown-item-icon"></i> Mark
								Williams
							</li>
						</ul>
					</div>
				</div>
				<div className="card mb-3 mb-lg-5">
					<div className="card-header card-header-content-between">
						<h4 className="card-header-title">Contact Person</h4>
					</div>

					<div className="card-body">
						<ul className="list-unstyled list-py-2 text-dark mb-0">
							<li className="pb-0">
								<span className="card-subtitle">About</span>
							</li>
							<li>
								<i className="bi-person dropdown-item-icon"></i> Mark
								Williams
							</li>
						</ul>
					</div>
				</div>

				<div className="card card-lg mb-3 mb-lg-5">
					<div className="card-body text-center">
						<div className="mb-3">
							<h3>Suspend Merchant</h3>
							<p>temproray suspend a mercahnt Activity</p>
						</div>

						<a className="btn btn-danger" href="">Suspend</a>
					</div>
				</div>
			</div>

			<div className="col-lg-8">
				<div className="card card-centered mb-3 mb-lg-5">
					<div className="card-header card-header-content-between">
						<h4 className="card-header-title">Accounts Created</h4>


					</div>

					<div className="table-responsive datatable-custom">
						<table
							id="datatable"
							className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
							style={{ width: "100%" }}
						>
							<thead className="thead-light">
								<tr>
									<th>Reference</th>
									<th>Account</th>
									<th>Amount</th>
									<th>Status</th>
									<th>Transaction Date</th>
									<th>Settlement</th>
									<th>Action</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>THFH_JJJ</td>
									<td>5667567667</td>
									<td>NGN 5,000</td>
									<td>
										<span className="badge bg-soft-success text-success">
											<span className="legend-indicator bg-success"></span>completed
										</span>
									</td>

									<td>Aug 17, 2020, 5:48 (ET)</td>
									<td>
										<span className="badge bg-soft-success text-success">
											<span className="legend-indicator bg-success"></span>success
										</span>
									</td>
									<td>
										<div className="btn-group" role="group">
											<a className="btn btn-white btn-sm" href="">
												<i className="bi-eye"></i> View
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="card card-centered mb-3 mb-lg-5">
					<div className="card-header card-header-content-between">
						<h4 className="card-header-title">Transactions</h4>

					</div>

					<div className="table-responsive datatable-custom">
						<table
							id="datatable"
							className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
							style={{ width: "100%" }}
						>
							<thead className="thead-light">
								<tr>
									<th>Reference</th>
									<th>Account</th>
									<th>Amount</th>
									<th>Status</th>
									<th>Transaction Date</th>
									<th>Settlement</th>
									<th>Action</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>THFH_JJJ</td>
									<td>5667567667</td>
									<td>NGN 5,000</td>
									<td>
										<span className="badge bg-soft-success text-success">
											<span className="legend-indicator bg-success"></span>completed
										</span>
									</td>

									<td>Aug 17, 2020, 5:48 (ET)</td>
									<td>
										<span className="badge bg-soft-success text-success">
											<span className="legend-indicator bg-success"></span>success
										</span>
									</td>
									<td>
										<div className="btn-group" role="group">
											<a
												className="btn btn-white btn-sm"
												href="./details.html"
											>
												<i className="bi-eye"></i> View
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</div>
	)
}

export default MerchantDetailsContent