import { useNavigate } from "react-router-dom";

const TransferHistoryHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="page-header">
      <div className="row align-items-center mb-3">
        <div className="col-sm mb-2 mb-sm-0">
          <h1 className="page-header-title">
            <i
              className="bi bi-arrow-left me-2"
              onClick={()=>{navigate("/merchant/transfer")}}
              style={{ fontSize: "#000", cursor: "pointer" }}
            />{" "}Transfer History
          </h1>
        </div>
      </div>
    </div>
  );
};

export default TransferHistoryHeader;

// total collection volume
//  total collection value
// totol transaction volume
// total transfer value