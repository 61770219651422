import React from "react";
import { useLocation } from "react-router-dom";
import CopyButton from "../../component/Copy";
import BackBtn from "../../component/back-btn";
import { formatAmount } from "../../helpers/index.helper";

function CashierCollections(): JSX.Element {
  const location = useLocation();

  return (
    <main>
      <div className="">
        <div className="page-header mb-0">
          <div className="row align-items-end">
            <div className="col-sm mb-2 mb-sm-0">
              <h1 className="page-header-title">
                <BackBtn />
                {location?.state?.cashier_name}
              </h1>
            </div>

            <div className="col-sm-auto"></div>
          </div>
        </div>

        <div className="row">
          {/* TRANSACTION DETAILS */}
          <div className="col-lg-6 mb-3 mb-lg-0 mt-5">
            <div className="card mb-3 mb-lg-5">
              <div className="card-header card-header-content-between">
                <h4 className="card-header-title tranx-headerdd link link-dark text-cap">
                  Transaction Details
                </h4>
              </div>

              <div className="card-body">
                <div className="list-group list-group-flush list-group-no-gutters">
                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">Status</span>
                            </div>

                            <div className="col-auto">
                              {location?.state?.completed === true && (
                                <h5 className="badge bg-soft-success text-success">
                                  SUCCESSFUL
                                </h5>
                              )}
                              {location?.state?.completed === false && (
                                <h5 className="badge bg-soft-danger text-danger">
                                  FAILED
                                </h5>
                              )}
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">Amount</span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                {formatAmount(location?.state?.amount)}
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">
                                Account Number
                              </span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                <CopyButton
                                  text={location?.state?.account_number}
                                  className="me-2"
                                />
                                {location?.state?.account_number}
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">Reference</span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                <CopyButton
                                  text={location?.state?.reference}
                                  className="me-2"
                                />
                                {location?.state?.reference}
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">
                                Transaction Date
                              </span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                {location?.state?.created_at}
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">
                                Transaction Type
                              </span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                {location?.state?.is_collection === 1 &&
                                  "Collection"}
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SENDER DETAILS */}
          <div className="col-lg-6 mb-3 mb-lg-0 mt-5">
            <div className="card mb-3 mb-lg-5">
              <div className="card-header card-header-content-between">
                <h4 className="card-header-title tranx-headerdd link link-dark text-cap">
                  Sender Details
                </h4>
              </div>

              <div className="card-body">
                <div className="list-group list-group-flush list-group-no-gutters">
                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">Name</span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                {location?.state?.sender_details?.name}
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">Bank name</span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                {location?.state?.sender_details?.bank_name}
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <div className="row align-items-center">
                          <>
                            <div className="col">
                              <span className="d-block trx-key">
                                Account number
                              </span>
                            </div>

                            <div className="col-auto">
                              <p className="link link-dark text-capitalize">
                                {
                                  location?.state?.sender_details
                                    ?.account_number
                                }
                              </p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CashierCollections;
