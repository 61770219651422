import MerchantContent from "../../../component/admin/merchant/MerchantContent"
import MerchantHeader from "../../../component/admin/merchant/MerchantHeader"
import MerchantStats from "../../../component/admin/merchant/MerchantStats"

const Merchants = () => {
	return (
		<div>
			<MerchantHeader />
			<MerchantStats />
			<MerchantContent />
		</div>
	)
}

export default Merchants