import React, { FC } from "react";
import { timelineItem } from "../../utils/interfaces";
import "./timeline.css";

const Timeline: FC<{ timelineItems: timelineItem[] }> = ({ timelineItems }) => {
  return (
    <div className="card --timeline mb-3 mb-lg-5">
      <div className="card-body my-4">
        <ol className="steps">
          {timelineItems?.map((item: timelineItem, index: number) => (
            <li
              className={`step ${
                item?.event_name?.toLowerCase()?.includes("unsuccessful")
                  ? "unsuccessful"
                  : item?.event_name?.toLowerCase()?.includes("unsuccessful")
                  ? "failed"
                  : ""
              }`}
              key={index}
              aria-label={
                timelineItems[index + 1]?.event_name
                  ?.toLowerCase()
                  ?.includes("unsuccessful")
                  ? "unsuccessful"
                  : timelineItems[index + 1]?.event_name
                      ?.toLowerCase()
                      ?.includes("failed")
                  ? "failed"
                  : ""
              }
            >
              <div>
                <p>{item?.event_name}</p>
                <span>{item?.event_time}</span>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Timeline;
