import React from "react";
import { payoutprops } from "../../utils/interfaces";
import Skeleton from "react-loading-skeleton";
import { formatCurrency } from "../../helpers/index.helper";

function TransferMetrics({
  metrics,
  isLoading,
  session,
}: payoutprops): JSX.Element {
  const currency_code: string | any = session?.currency_code;
  const formattedAmount = formatCurrency(`${session?.balance?.balance}`);

  return (
    <div className="row">
      <div className="col-md-6 col-lg-4 mb-3 mb-lg-5">
        <div className="card">
          <div className="card-body text-start">
            <small className="text-cap">Account Balance</small>
            {isLoading ? (
              <span className="js-counter d-block display-3 text-dark mb-2">
                <Skeleton height={"100%"} width={200} />
              </span>
            ) : (
              <span className="js-counter d-block h4 text-dark mb-2">
                {currency_code}{" "}
                {session?.balance?.balance === "0.00"
                  ? session?.balance?.balance
                  : formattedAmount}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferMetrics;
