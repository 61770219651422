import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../spinner/Spinner";

function InvoiceModal({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [addCustomer, setAddCustomer] = useState<boolean>(false);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mx-auto">
          <h5 className="text-center mx-auto pb-5">
            Who are you creating this invoice for?
          </h5>
          {!addCustomer ? (
            <div className="overflow-hidden mb-4">
              <label className="form-label fw-bold" htmlFor="merchant_name">
                Select customer
              </label>
              <select
                className={`form-select form-control-lg border border-secondary`}
                id="validationValidSelect1"
                value={""}
                name="business_type"
                onChange={() => {}}
              >
                <option>Select</option>
              </select>

              <div className="d-flex flex-column mx-auto text-center pt-1 mt-1 w-75 gap-4">
                <>
                  <p
                    className="form-text"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    Or
                  </p>
                  <p
                    className="form-text  text-center mx-auto"
                    style={{
                      cursor: "pointer",
                      color: "#128100",
                      fontSize: "16px",
                    }}
                    onClick={() => setAddCustomer(true)}
                  >
                    <i className="bi bi-plus-lg fw-bold"></i> Add new customer
                  </p>
                </>

                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={() => navigate("/merchant/invoices/create_invoice")}
                >
                  Continue
                  {loading && <SpinnerComponent variant="light" size="sm" />}
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="row mb-4">
                <div className="col">
                  <label className="form-label" htmlFor="firstname">
                    First name*
                  </label>
                  <input
                    type="text"
                    className={`form-control border border-secondary`}
                    name="firstname"
                    id="firstname"
                    tabIndex={1}
                    placeholder="Enter first name"
                    aria-label="firstname"
                    required
                  />
                  <span className="invalid-feedback">
                    Please enter valid first name.
                  </span>
                </div>
                <div className="col">
                  <label className="form-label" htmlFor="lastname">
                    Last name*
                  </label>
                  <input
                    type="text"
                    className={`form-control border border-secondary`}
                    name="lastname"
                    id="lastname"
                    tabIndex={1}
                    placeholder="Enter last name"
                    aria-label="lastname"
                    required
                  />
                  <span className="invalid-feedback">
                    please enter last name
                  </span>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col">
                  <label className="form-label" htmlFor="email">
                    Email*
                  </label>
                  <input
                    type="email"
                    className={`form-control  border border-secondary`}
                    name="email"
                    id="email"
                    tabIndex={1}
                    placeholder="e.g michael@email.com"
                    aria-label="email@address.com"
                    autoComplete="off"
                    required
                  />
                  <span className="invalid-feedback">
                    Please enter a valid email address.
                  </span>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col">
                  <label className="form-label" htmlFor="phone_number">
                    Phone number*
                  </label>
                  <PhoneInput
                    country={"ng"}
                    inputClass={`form-control form-control-lg w-100 border border-secondary`}
                    inputStyle={{ fontFamily: "BricolageGrotesque" }}
                    containerStyle={{ fontFamily: "BricolageGrotesque" }}
                  />
                </div>
              </div>

              <div className="w-75 mx-auto text-center">
                <p
                  className="form-text"
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Or
                </p>
                <p
                  className="form-text  text-center mx-auto pb-3 "
                  style={{
                    cursor: "pointer",
                    color: "#128100",
                    fontSize: "16px",
                  }}
                  onClick={() => setAddCustomer(false)}
                >
                  Select a customer
                </p>
                <button
                  type="button"
                  className="btn btn-primary w-75 mx-auto text-center"
                  onClick={() => navigate("/merchant/invoices/create_invoice")}
                >
                  Continue
                  {loading && <SpinnerComponent variant="light" size="sm" />}
                </button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default InvoiceModal;
