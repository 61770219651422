import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function OverlayTooltip({
  children,
  tooltipMsg,
}: {
  children: any;
  tooltipMsg: string;
}) {
  const renderTooltip = (props: any) => (
    <Tooltip
      id="element-tooltip"
      {...props}
    >
      <div>{tooltipMsg}</div>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top-end"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
}

export default OverlayTooltip;
