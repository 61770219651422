import React, { Fragment, useMemo, useState } from "react";
import DropDownWrapper from "../../component/DropDownWrapper";
import CustomerMetrics from "../../component/customers/CustomerMetrics";
import CreateCustomerModal from "../../component/modal/CreateCustomerModal";
import PaginationComponent from "../../component/pagination/pagination";
import Table from "../../component/table/Table";
import { customer_column } from "../../configs/dataTable.config";

function Customers(): JSX.Element {
  const [activeItem, setActiveItem] = useState<number>(0);
  const [query, setQuery] = useState<string>("");
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const totalItems = 0;
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };
  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  const columns: any = useMemo(() => [...customer_column], []);

  return (
    <Fragment>
      <CreateCustomerModal show={show} handleClose={handleClose} />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Customers</h1>
          </div>

          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShow}
            >
              <i className="bi bi-plus-lg me-1"></i> Create customer
            </button>
          </div>
        </div>
      </div>

      <CustomerMetrics />

      <div className="card">
        <div className="card-header card-header-content-md-between">
          <div className="mb-2 mb-md-0">
            <form onSubmit={(e) => e.preventDefault()}>
              <div
                className="input-group input-group-merge input-group-flush"
                style={{ minWidth: "160%", maxWidth: "100%" }}
              >
                <div className="input-group-prepend input-group-text">
                  <i className="bi-search"></i>
                </div>
                <input
                  id="datatableSearch"
                  type="search"
                  value={query}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Search ..."
                  aria-label="Search users"
                />
              </div>
            </form>
          </div>

          <div className="d-grid d-sm-flex gap-2">
            <>
              <DropDownWrapper
                action={
                  <button
                    type="button"
                    className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                  >
                    <i className="bi bi-filter"></i> Filter
                  </button>
                }
                className="d-none d-md-block d-lg-block"
              >
                <div className="p-3">
                  <label className="text-body">Date Range</label>
                  <div className="row mb-4">
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="start_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="end_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-white btn-sm">Close</button>
                    <button
                      className="btn btn-primary btn-sm"
                      // onClick={filterData}
                      style={{ cursor: "pointer" }}
                    >
                      Apply filter
                    </button>
                  </div>
                </div>
              </DropDownWrapper>
              {/* SM */}
              <DropDownWrapper
                action={
                  <button
                    type="button"
                    className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                  >
                    <i className="bi bi-filter"></i> Filter
                  </button>
                }
                styleObject={{ left: "-34%" }}
                className="d-block d-md-none d-lg-none"
              >
                <div className="p-3">
                  <label className="text-body">Date Range</label>
                  <div className="row mb-4">
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="start_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                    <div className="col tom-select-custom">
                      <input
                        type="date"
                        name="end_date"
                        onChange={handleDatesFilter}
                        className="js-daterangepicker form-control daterangepicker-custom-input"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-white btn-sm">Close</button>
                    <button
                      className="btn btn-primary btn-sm"
                      // onClick={filterData}
                      style={{ cursor: "pointer" }}
                    >
                      Apply filter
                    </button>
                  </div>
                </div>
              </DropDownWrapper>

              {/* <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={handleExports}
              >
                <span style={{ color: "#128100 !important" }}>Download</span>
                <DownloadIcon className="ms-2 text-success" />
              </button> */}
            </>

            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-sm w-100"
                aria-expanded="false"
              >
                <i className="bi bi-arrow-clockwise me-2"></i> Refresh
              </button>
            </div>
          </div>
        </div>

        <div className="table-responsive datatable-custom position-relative">
          <Table
            columns={columns}
            data={[
              {
                email: "michael@email.com",
                name: "Michael Jones",
                mobile_number: "07042430344",
                createdAt: "12-03-2023 / 10:45am",
                id: "23",
              },
            ]}
            isFetching={false}
            navigateTo={`/merchant/customers/details/`}
          />
        </div>

        <div className="card-footer">
          <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
            <div className="col-sm mb-2 mb-sm-0"></div>

            <div className="col-sm-auto">
              <div
                className="d-flex justify-content-center justify-content-sm-end"
                hidden
              >
                <PaginationComponent
                  totalItems={totalItems}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Customers;
