import React from "react";
import AuthForm from "../../component/auth_form/AuthForm";
import PoolerIpad from "../../assets/svg/logos/ipad-pooler.svg";
import PoolerLogoWhite from "../../assets/svg/logos/Pooler_logo_white.svg";
import AuthIllustration from "../../assets/svg/logos/Pooler-sign-up-image.png";
import { useLocation } from "react-router-dom";
import PoolerLogo from "../../assets/svg/logos/poolerdarklogo.svg";
import SEO from "../../component/seo/seo";

function Signup(): JSX.Element {
  const location = useLocation();

  const handleUrl = () => {
    const isMerchant = location.pathname.includes("/auth/merchant");
    const isClient = location.pathname.includes("/auth/cashier");
    if (isMerchant) {
      return "/auth/merchant/signin";
    } else if (isClient) {
      return "/auth/cashier/signin";
    }
  };
  return (
    <>
      <SEO
        title="Pooler - Collecting Safely"
        description="Use Pooler to collect and settle payments seamless and faster by transfer and avoid chargeback"
        type="website"
      />
      <div className="row overflow-hidden">
        <div
          style={{ backgroundImage: `url(${AuthIllustration})` }}
          className="col-lg-6 overflow-hidden d-none --bg-auth d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
        >
          <div id="banner-conc">
            <img src={PoolerLogoWhite} alt="pooler-logo" />
            <h1>
              The Easier Way to
              <br />
              Receive Payments.
            </h1>
            <p>Experience world-class banking-as-a-service</p>
          </div>
          <img src={PoolerIpad} alt="pooler-ipad" />
        </div>
        <div
          className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100 position-relative overflow-hidden"
          style={{ overflowX: "hidden" }}
        >
          <div
            className=" content-space-t-1 content-space-t-lg-2 content-space-b-1 overflow-hidden"
            style={{ maxWidth: "25rem" }}
          >
            <div className="text-center d-sm-block d-md-block d-lg-none mx-auto">
              <img
                src={PoolerLogo}
                alt="pooler"
                className="navbar-brand-logo d-sm-block d-md-block d-lg-none mx-auto"
                style={{ paddingBottom: "15%" }}
              />
            </div>
            <AuthForm redirect_url={handleUrl()} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
