const MerchantHeader = () => {
	return (
		<div className="page-header">
			<div className="row align-items-center mb-3">
				<div className="col-sm">
					<h1 className="page-header-title">Merchants</h1>
				</div>
			</div>

			<div className="js-nav-scroller hs-nav-scroller-horizontal">
				<span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
					<a className="hs-nav-scroller-arrow-link" href="javascript:;">
						<i className="bi-chevron-left"></i>
					</a>
				</span>

				<span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
					<a className="hs-nav-scroller-arrow-link" href="javascript:;">
						<i className="bi-chevron-right"></i>
					</a>
				</span>


			</div>
		</div>
	)
}

export default MerchantHeader