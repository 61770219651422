import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  airtime_purchase_id_map,
  formatAmount,
  formatAmountWithDecimal,
  shortenString,
} from "../helpers/index.helper";
import useCopyToClipboard from "../hooks/useCopy.hook";
import { convertDateFormat, convertToNationalFormat } from "../utils/decode";

export const accounts_column = () => {
  return [
    {
      Header: "Account Name",
      columnId: 1,
      accessor: "account_name",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <span className="text-uppercase">{row?.original?.account_name}</span>
        );
      },
    },
    { Header: "Account No", columnId: 2, accessor: "account_no" },
    {
      Header: "Account Type",
      columnId: 3,
      accessor: "client_type",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <span className="text-uppercase">{row?.original?.client_type}</span>
        );
      },
    },
    {
      Header: "Status",
      columnId: 4,
      accessor: "active",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <span className="">
            {row?.original?.active === true ? (
              <span className="badge bg-soft-success text-success">
                <span className="legend-indicator bg-success"></span>ACTIVE
              </span>
            ) : (
              ""
            )}
          </span>
        );
      },
    },
    {
      Header: "Date Created",
      columnId: 5,
      accessor: "created_at",
      Cell: ({ row }: any): JSX.Element => {
        return <span className="">{row?.original?.created_at}</span>;
      },
    },
  ];
};

export const latest_txn_column = () => {
  return [
    {
      Header: "Reference",
      columnId: 1,
      accessor: "reference",
      Cell: ({ row }: any): JSX.Element => {
        return <div>{shortenString(row?.original.reference, 15)}</div>;
      },
    },
    {
      Header: "Account No",
      columnId: 2,
      accessor: "account_no",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <>
            {row?.original?.transaction_type === "collection" && (
              <>
                <span className="text-capitalize">
                  {row?.original?.sender_details?.account_number}
                </span>
              </>
            )}
            {row?.original?.transaction_type === "transfer" && (
              <>
                <span className="">
                  {row?.original?.reciever_details?.account_number}
                </span>
              </>
            )}
            {row?.original?.transaction_type === "intra-transfer" && (
              <>
                <span className="">
                  {row?.original?.reciever_details?.account_number}
                </span>
              </>
            )}
            {row?.original?.transaction_type === "wallet charge" && (
              <>
                <span className="text-capitalize">N/A</span>
              </>
            )}
            {row?.original?.transaction_type === "payout" && (
              <>
                <span className="">
                  {row?.original?.reciever_details?.account_number}
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      Header: "Transaction type",
      columnId: 3,
      accessor: "transaction_type",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <div className="text-center">
            <span className="text-center">
              {row?.original?.transaction_type === "collection" && (
                <span className="badge bg-soft-info text-info">COLLECTION</span>
              )}

              {row?.original?.transaction_type === "transfer" && (
                <span className="badge bg-soft-secondary text-secondary">
                  TRANSFER
                </span>
              )}

              {row?.original?.transaction_type === "intra-transfer" && (
                <span className="badge bg-soft-secondary text-secondary">
                  TRANSFER
                </span>
              )}

              {row?.original?.transaction_type === "wallet charge" && (
                <td className="">
                  <span className="badge bg-soft-secondary text-secondary">
                    WALLET CHARGE
                  </span>
                </td>
              )}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Amount",
      columnId: 4,
      accessor: "amount",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <>
            <span className="">
              {formatAmount(row?.original?.amount, row?.original?.currency)}
            </span>
          </>
        );
      },
    },
    {
      Header: "Status",
      columnId: 5,
      accessor: "active",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <>
            {row?.original?.pending && (
              <span className="badge bg-soft-warning text-warning">
                <span className="legend-indicator bg-warning"></span>PENDING
              </span>
            )}
            {!row?.original?.completed &&
              !row?.original?.pending &&
              row?.original?.payout?.failed && (
                <span className="badge bg-soft-danger text-danger">
                  <span className="legend-indicator bg-danger"></span>FAILED
                </span>
              )}
            {row?.original?.completed && (
              <span className="badge bg-soft-success text-success">
                <span className="legend-indicator bg-success"></span>COMPLETED
              </span>
            )}
          </>
        );
      },
    },
    {
      Header: "Transaction Date",
      columnId: 6,
      accessor: "created_at",
      Cell: ({ row }: any): JSX.Element => {
        return <span className="">{row?.original?.created_at}</span>;
      },
    },
  ];
};

export const payouts_txn = () => {
  return [
    {
      Header: "Recipient's Name",
      columnId: 1,
      accessor: "target_name",
      Cell: ({ row }: any) => {
        return <span>{row?.original?.target_name}</span>;
      },
    },
    {
      Header: "Account No",
      accessor: "target_number",
      columnId: 2,
      Cell: ({ row }: any) => {
        return <span>{row?.original?.target_number}</span>;
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      columnId: 3,
      Cell: ({ row }: any) => {
        const amount = parseFloat(row?.original?.amount).toFixed(2);
        return <span>{formatAmountWithDecimal(amount)}</span>;
      },
    },
    {
      Header: "Status",
      columnId: 4,
      accessor: "active",
      Cell: ({ row }: any): JSX.Element => {
        return (
          <>
            {row?.original?.in_transit && (
              <span className="badge bg-soft-warning text-warning">
                <span className="legend-indicator bg-warning"></span>PENDING
              </span>
            )}
            {!row?.original?.completed &&
              !row?.original?.in_transit &&
              row?.original?.failed && (
                <span className="badge bg-soft-danger text-danger">
                  <span className="legend-indicator bg-danger"></span>FAILED
                </span>
              )}
            {row?.original?.completed && (
              <span className="badge bg-soft-success text-success">
                <span className="legend-indicator bg-success"></span>COMPLETED
              </span>
            )}
          </>
        );
      },
    },
    { Header: "Reference", columnId: 5, accessor: "reference" },
    {
      Header: "Date",
      columnId: 6,
      accessor: "created_at",
      Cell: ({ row }: any): JSX.Element => {
        return <span className="">{row?.original?.createdAt}</span>;
      },
    },
  ];
};

export const payment_template_column = [
  { Header: "Name", columnId: 1, accessor: "payment_link_name" },
  {
    Header: "Link type",
    columnId: 2,
    accessor: "is_recurring",
    Cell: ({ row }: any) => {
      return (
        <span>
          {row?.original?.is_recurring === true ? "Recurring" : "One-time"}
        </span>
      );
    },
  },
  {
    Header: "Date created",
    columnId: 3,
    accessor: "createdAt",
    Cell: ({ row }: any): JSX.Element => {
      return <span className="">{row?.original?.createdAt}</span>;
    },
  },
  {
    Header: "Status",
    columnId: 4,
    accessor: "expired",
    Cell: ({ row }: any): JSX.Element => {
      const status = row?.original?.expired;
      return (
        <>
          {status === true ? (
            <span className="badge bg-soft-danger text-danger">
              <span className="legend-indicator bg-danger"></span>Expired
            </span>
          ) : (
            <span className="badge bg-soft-success text-success">
              <span className="legend-indicator bg-success"></span>Active
            </span>
          )}
        </>
      );
    },
  },
];

export const payment_links_column = [
  { Header: "Name", columnId: 1, accessor: "payment_link_name" },
  {
    Header: "Amount",
    columnId: 4,
    accessor: "amount",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          {row?.original?.amount === null ? (
            <span className="">N/A</span>
          ) : (
            <span className="">
              {formatAmount(row?.original?.amount, row?.original?.currency)}
            </span>
          )}
        </>
      );
    },
  },
  {
    Header: "Date created",
    columnId: 3,
    accessor: "created_at",
    Cell: ({ row }: any): JSX.Element => {
      return <span className="">{row?.original?.createdAt}</span>;
    },
  },
  {
    Header: "Status",
    columnId: 5,
    accessor: "expired",
    Cell: ({ row }: any): JSX.Element => {
      const status = row?.original?.expired;
      return (
        <>
          {status === true ? (
            <span className="badge bg-soft-danger text-danger">
              <span className="legend-indicator bg-danger"></span>EXPIRED
            </span>
          ) : (
            <span className="badge bg-soft-success text-success">
              <span className="legend-indicator bg-success"></span>ACTIVE
            </span>
          )}
        </>
      );
    },
  },
  {
    Header: "Link",
    columnId: 6,
    accessor: "link",
    Cell: ({ row }: any): JSX.Element => {
      const { isCopied, copyToClipboard } = useCopyToClipboard();
      return (
        <>
          <div className="input-group input-group-sm input-group-merge table-input-group">
            <input
              id="referralsKeyCode1"
              type="text"
              className="form-control"
              readOnly
              value={row?.original?.link || ""}
            />
            <OverlayTrigger
              key={"top"}
              placement={"top"}
              overlay={
                <Tooltip id={isCopied ? "Copied" : "Copy Link"}>
                  {isCopied ? "Copied" : "Copy Link"}
                </Tooltip>
              }
            >
              <button className="js-clipboard input-group-append input-group-text position-relative">
                <>
                  {isCopied ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-copy text-success"
                      viewBox="0 0 16 16"
                      // onClick={(
                      //   event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>
                      // ) => {
                      //   event.stopPropagation();
                      //   copyToClipboard(row.original.link || "");
                      // }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-copy"
                      viewBox="0 0 16 16"
                      onClick={(
                        event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>
                      ) => {
                        event.stopPropagation();
                        copyToClipboard(row?.original?.link || "");
                      }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                      />
                    </svg>
                  )}
                  {/* <i
                  id="referralsKeyCodeIcon1"
                  className={
                    isCopied ? "bi bi-copy text-success" : "bi bi-copy"
                  }
                  onClick={(
                    event: React.MouseEvent<HTMLElement, MouseEvent>
                  ) => {
                    event.stopPropagation();
                    copyToClipboard(row.original.link || "");
                  }}
                ></i> */}
                </>
              </button>
            </OverlayTrigger>
          </div>
        </>
      );
    },
  },
];

export const payment_received_column = [
  {
    Header: "Date",
    columnId: 1,
    accessor: "created_at",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <span className="">{row?.original?.payment_link_info?.createdAt}</span>
      );
    },
  },
  {
    Header: "Customer Name",
    columnId: 2,
    accessor: "payment_link_info",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {row?.original?.payment_link_info?.payer_firstname} &nbsp;
            {row?.original?.payment_link_info?.payer_lastname}
          </span>
        </>
      );
    },
  },
  {
    Header: "Amount Paid",
    columnId: 3,
    accessor: "amount",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="">
            {formatAmount(row?.original?.amount, row?.original?.currency)}
          </span>
        </>
      );
    },
  },
];

export const cashier_column = [
  { Header: "Account number", columnId: 0, accessor: "account_number" },
  {
    Header: "Cashier till",
    columnId: 1,
    accessor: "cashier_name",
    Cell: ({ row }: any): JSX.Element => {
      const cashier_name = row?.original?.cashier_name;
      if (cashier_name.includes("/")) {
        const parts = cashier_name?.split("/");
        const result = parts && parts[1]?.trim();
        return <div className="link-secondary">{result}</div>;
      } else {
        return (
          <>
            <div className="link-secondary">{cashier_name}</div>
          </>
        );
      }
    },
  },
  { Header: "First name", columnId: 2, accessor: "firstname" },
  { Header: "Last name", columnId: 3, accessor: "lastname" },
  { Header: "Phone number", columnId: 4, accessor: "mobile_number" },
  { Header: "Creation date", columnId: 6, accessor: "createdAt" },
  {
    Header: "Status",
    columnId: 6,
    accessor: "active",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          {row?.original?.active === true && (
            <span className="badge bg-soft-success text-success ms-2">
              {" "}
              <span className="legend-indicator bg-success"></span> Active{" "}
            </span>
          )}
          {row?.original?.active === false && (
            <span className="badge bg-soft-danger text-danger ms-2">
              {" "}
              <span className="legend-indicator bg-danger"></span> Inactive{" "}
            </span>
          )}
        </>
      );
    },
  },
];

export const collections_column = [
  { Header: "Date/Time", columnId: 1, accessor: "created_at" },
  {
    Header: "Customer",
    columnId: 2,
    accessor: "sender_details",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {row?.original?.sender_details?.name}
          </span>
        </>
      );
    },
  },
  {
    Header: "Account number",
    columnId: 3,
    accessor: "account_number",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {row?.original?.sender_details?.account_number}
          </span>
        </>
      );
    },
  },
  {
    Header: "Amount",
    columnId: 4,
    accessor: "amount",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {formatAmount(row?.original?.amount)}
          </span>
        </>
      );
    },
  },
  { Header: "Reference no.", columnId: 5, accessor: "reference" },
];

export const payback_column = [
  { Header: "Date/Time", columnId: 1, accessor: "createdAt" },
  { Header: "Reference no.", columnId: 2, accessor: "references" },
  {
    Header: "Amount",
    columnId: 3,
    accessor: "amount_settled",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {formatAmount(row?.original?.amount_settled)}
          </span>
        </>
      );
    },
  },
  {
    Header: "Status",
    columnId: 4,
    accessor: "completed",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          {row?.original?.completed === true && (
            <span className="badge bg-soft-success text-success ms-2">
              {" "}
              <span className="legend-indicator bg-success"></span> SUCCESSFUL{" "}
            </span>
          )}
          {row?.original?.completed === false && (
            <span className="badge bg-soft-success text-success ms-2">
              {" "}
              <span className="legend-indicator bg-success"></span> FAILED{" "}
            </span>
          )}
        </>
      );
    },
  },
];

export const invoice_column = [
  { Header: "Email address", columnId: 1, accessor: "email" },
  { Header: "Amount", columnId: 2, accessor: "amount" },
  { Header: "Date created", columnId: 3, accessor: "createdAt" },
  { Header: "Invoice ID", columnId: 4, accessor: "invoideId" },
  {
    Header: "Status",
    columnId: 5,
    accessor: "status",
    Cell: ({ row }: any): JSX.Element => {
      return <span className="badge bg-soft-success text-success">PAID</span>;
    },
  },
];

export const customer_column = [
  { Header: "Email address", columnId: 1, accessor: "email" },
  { Header: "Full name", columnId: 2, accessor: "name" },
  { Header: "Phone number", columnId: 3, accessor: "mobile_number" },
  { Header: "Date created", columnId: 3, accessor: "createdAt" },
];

export const customer_invoice_column = [
  { Header: "Amount", columnId: 1, accessor: "email" },
  { Header: "Date created", columnId: 3, accessor: "createdAt" },
  { Header: "Invoice ID", columnId: 2, accessor: "name" },
  { Header: "Status", columnId: 4, accessor: "mobile_number" },
];

export const customer_trxn_column = [
  { Header: "Amount", columnId: 1, accessor: "email" },
  { Header: "Date created", columnId: 3, accessor: "createdAt" },
  { Header: "Transaction Type", columnId: 2, accessor: "name" },
  { Header: "Status", columnId: 4, accessor: "mobile_number" },
  { Header: "Naration", columnId: 5, accessor: "mobile_num" },
];

export const customer_acc_column = [
  { Header: "Account name", columnId: 1, accessor: "email" },
  { Header: "Account number", columnId: 3, accessor: "createdt" },
  { Header: "Date created", columnId: 4, accessor: "createdAt" },
  { Header: "Status", columnId: 5, accessor: "mobile_number" },
];

export const customer_wallets_column = [
  { Header: "Account name", columnId: 1, accessor: "email" },
  { Header: "Account number", columnId: 3, accessor: "createdt" },
  { Header: "Date created", columnId: 4, accessor: "createdAt" },
  { Header: "Wallet balance", columnId: 6, accessor: "creatt" },
  { Header: "Status", columnId: 5, accessor: "mobile_number" },
];

export const Bulk_purchase_preview_column = [
  { Header: "Phone Number", columnId: 1, accessor: "mobile_number" },
  {
    Header: "Network",
    columnId: 3,
    accessor: "purchase_id",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <span>
          {(row?.original?.network as string) ??
            (airtime_purchase_id_map[row?.original?.purchase_id] as string)}
        </span>
      );
    },
  },
  {
    Header: "Amount",
    columnId: 4,
    accessor: "amount",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {formatAmount(row?.original?.amount)}
          </span>
        </>
      );
    },
  },
];

export const Bulk_purchase_details_column = [
  {
    Header: "Date&Time",
    columnId: 1,
    accessor: "createdAt",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {convertDateFormat(row?.original?.createdAt)}
          </span>
        </>
      );
    },
  },
  {
    Header: "Phone Number",
    columnId: 2,
    accessor: "mobile_number",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <span>
          {convertToNationalFormat(row?.original?.mobile_number as string)}
        </span>
      );
    },
  },
  {
    Header: "Network",
    columnId: 3,
    accessor: "purchase_id",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <span>
          {(row?.original?.network as string) ??
            (airtime_purchase_id_map[row?.original?.purchase_id] as string)}
        </span>
      );
    },
  },
  {
    Header: "Status",
    columnId: 4,
    accessor: "status",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span
            className={`text-capitalize ${
              row?.original?.status === "pending" &&
              "badge bg-soft-info text-info"
            } ${
              row?.original?.status === "processing" &&
              "badge bg-soft-info border-info border rounded-pill text-info"
            } ${
              row?.original?.status === "successful" &&
              "badge bg-soft-success border-success border rounded-pill text-success"
            } ${
              row?.original?.status === "failed" &&
              "badge bg-soft-danger border-danger border rounded-pill text-danger"
            } ${
              row?.original?.status?.toLowerCase() === "reversed" &&
              "badge bg-soft-danger border-danger border rounded-pill text-danger"
            }`}
          >
            {row?.original?.status}
          </span>
        </>
      );
    },
  },
  {
    Header: "Amount",
    columnId: 5,
    accessor: "total_amount",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {formatAmount(row?.original?.topup_amount)}
          </span>
        </>
      );
    },
  },
  { Header: "Reference", columnId: 6, accessor: "reference" },
];

export const Bulk_purchase_table_column = [
  { Header: "File name", columnId: 1, accessor: "filename" },
  {
    Header: "Date&Time",
    columnId: 3,
    accessor: "createdAt",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {convertDateFormat(row?.original?.createdAt)}
          </span>
        </>
      );
    },
  },
  { Header: "Total count", columnId: 4, accessor: "total" },
  {
    Header: "Success count",
    columnId: 5,
    accessor: "completed",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span
            className="badge bg-soft-success text-success ms-2"
            style={{ fontSize: 14 }}
          >
            {" "}
            <span className="legend-indicator bg-success"></span>
            {row?.original?.completed}{" "}
          </span>
        </>
      );
    },
  },
  {
    Header: "Total Amount",
    columnId: 6,
    accessor: "total_amount",
    Cell: ({ row }: any): JSX.Element => {
      return (
        <>
          <span className="text-capitalize">
            {formatAmount(row?.original?.total_amount)}
          </span>
        </>
      );
    },
  },
  { Header: "Reference", columnId: 4, accessor: "reference" },
];
