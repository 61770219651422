import React from "react";
import PayoutCard from "../../component/payouts/PayoutCard";
import PayoutHeader from "../../component/payouts/PayoutHeader";
import PayoutsMetrics from "../../component/payouts/PayoutsMetrics";
import { useDashboard, useSession } from "../../hooks/auth/auth.hook";
import { useGetPayoutTransactions } from "../../hooks/payouts/payouts.hook";
import {
  IPayoutTransaction,
  metricInterface,
  sessionInterface,
} from "../../utils/interfaces";

function Payouts(): JSX.Element {
  const { data } = useSession();
  const { dashboard_data, isLoading } = useDashboard({});
  const merchant_data: sessionInterface = data?.data?.data;
  const metrics: metricInterface = dashboard_data?.data?.data;

  return (
    <>
      <PayoutHeader session={merchant_data} />
      <PayoutsMetrics
        metrics={metrics}
        isLoading={isLoading}
        session={merchant_data}
      />
      <PayoutCard />
    </>
  );
}

export default Payouts;
