import TransactionsHeader from "../../component/transactions/TransactionsHeader";
import TransactionsTable from "../../component/transactions/TransactionsTable";
import TransactionsTableMobile from "../../component/transactions/TransactionsTableMobile";
import { useSession } from "../../hooks/auth/auth.hook";
import useResize from "../../hooks/resize.hook";
import { sessionInterface } from "../../utils/interfaces";

function Transactions() {
  const { data } = useSession();
  const { size } = useResize();
  const merchant_data: sessionInterface = data?.data?.data;
  return (
    <main>
      <div>
        <TransactionsHeader />
        {size?.[0] <= 998 ? (
          <TransactionsTableMobile merchant_data={merchant_data} />
        ) : (
          <TransactionsTable merchant_data={merchant_data} />
        )}
      </div>
    </main>
  );
}

export default Transactions;
