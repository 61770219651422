import React, { useRef, useState } from "react";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { CardProps } from "../../utils/interfaces";
import DropDownWrapper from "../DropDownWrapper";
import SpinnerComponent from "../spinner/Spinner";

function CardHeader({
  refresh,
  inputValue,
  handleInputChange,
  dates,
  handleDatesFilter,
  handleExports,
  handleFilter,
  data,
  loading,
  setDates,
}: CardProps) {
  const closeRef: any = useRef<HTMLButtonElement | null>(null);
  const buttonRef: any = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="card-header card-header-content-md-between">
      <div className="mb-2 mb-md-0">
        <div>
          <div className="input-group input-group-merge input-group-flush">
            <div className="input-group-prepend input-group-text">
              <i className="bi-search"></i>
            </div>
            <input
              id="datatableSearch"
              type="search"
              className="form-control"
              placeholder="Search by account number"
              aria-label="Search"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="d-grid d-sm-flex gap-2">
        {data !== undefined && data?.length > 0 ? (
          <>
            <DropDownWrapper
              action={
                <button
                  type="button"
                  className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                  ref={closeRef}
                >
                  <i className="bi bi-filter"></i> Filter
                </button>
              }
            >
              <div className="p-3">
                <label className="text-body">Date Range</label>
                <div className="row mb-4">
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="start_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-white btn-sm"
                    onClick={() => {
                      handleClose();
                      closeRef?.current?.click();
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleFilter}
                    style={{ cursor: "pointer" }}
                  >
                    Apply filter{" "}
                    {loading && <SpinnerComponent variant="light" size="sm" />}
                  </button>
                </div>
              </div>
            </DropDownWrapper>

            <DropDownWrapper
              action={
                <button
                  type="button"
                  className="btn btn-outline-success btn-sm"
                  ref={buttonRef}
                >
                  <span style={{ color: "#128100 !important" }}>Download</span>
                  <DownloadIcon className="ms-2 text-success" />
                </button>
              }
            >
              <div className="p-3">
                <label className="text-body">Date Range</label>
                <div className="row mb-4">
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="start_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                  <div className="col tom-select-custom">
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleDatesFilter}
                      className="js-daterangepicker form-control daterangepicker-custom-input"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-white btn-sm"
                    onClick={() => {
                      handleClose();
                      buttonRef?.current.click();
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleExports}
                    style={{ cursor: "pointer" }}
                  >
                    Export{" "}
                    {loading && <SpinnerComponent variant="light" size="sm" />}
                  </button>
                </div>
              </div>
            </DropDownWrapper>
          </>
        ) : (
          ""
        )}

        <div className="dropdown">
          <button
            type="button"
            className="btn btn-white btn-sm w-100 border border-secondary"
            aria-expanded="false"
            onClick={() => {
              refresh();
              window.location.reload();
            }}
          >
            <i className="bi bi-arrow-clockwise me-2"></i> Refresh
          </button>
        </div>
      </div>
    </div>
  );
}

export default CardHeader;
