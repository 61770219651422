const AdminHeader = () => {
	return (
		<div className="page-header">
			<div className="row align-items-center">
				<div className="col-sm mb-2 mb-sm-0">
					<h1 className="page-header-title">Good morning, Admin.</h1>
					<p className="page-header-text">
						Here's what's happening with your account today.
					</p>
				</div>
				<div className="col-sm-auto">Administrative Console</div>
			</div>
		</div>
	)
}

export default AdminHeader