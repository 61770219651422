import moengage from "@moengage/web-sdk";
import { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ReactComponent as People } from "../../assets/vpeople.svg";
import CreateCashierModal from "../../component/modal/CreateCashierModal";
import DeactivateCashierModal from "../../component/modal/DeactivateCashierModal";
import PaginationComponent from "../../component/pagination/pagination";
import Table from "../../component/table/Table";
import { cashier_column } from "../../configs/dataTable.config";
import { useSession } from "../../hooks/auth/auth.hook";
import { useCashiers } from "../../hooks/cashier/index.hook";
import useDebounce from "../../hooks/debounce.hook";
import useResize from "../../hooks/resize.hook";
import API_SERVICE from "../../services/services";
import { sessionInterface } from "../../utils/interfaces";
import CashierSm from "./CashierSm";

const Cashier = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { size } = useResize();

  const [cashierId, setCashierId] = useState<string | number>();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<number>(0);
  const [query, setQuery] = useState<string>("");
  const [cashierInfo, setCashierInfo] = useState({});
  const [status, setStatus] = useState<boolean>(false);

  const { sessionData } = useSession();
  const merchant_data: sessionInterface = sessionData?.data?.data;
  const { data, refetch, isFetching } = useCashiers(activeItem, query);

  const cashierData = data?.data?.data?.items;
  const totalItems = data?.data?.data?.totalPages;

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleDropdownClick = (event: any) => {
    event.stopPropagation();
  };

  const columns: any = useMemo(
    () => [
      ...cashier_column,
      {
        Header: "",
        columnId: 8,
        accessor: "actions",
        Cell: ({ row }: any): JSX.Element => {
          const id = row?.original?.account_number;
          return (
            <>
              <Dropdown onClick={handleDropdownClick}>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="btn btn-white btn-sm"
                  style={{ background: "#fff" }}
                >
                  <i className="bi-three-dots" style={{ color: "#000" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as={"button"}
                    type="button"
                    onClick={() => {
                      navigate(
                        `/merchant/business/cashier/update_cashier/${id}`,
                        { state: row?.original }
                      );
                    }}
                  >
                    Edit Cashier
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={"button"}
                    type="button"
                    onClick={() => {
                      setShow(true);
                      setCashierId(id);
                      setCashierInfo(row?.original);
                      setStatus(row?.original?.active);
                    }}
                  >
                    {row?.original?.active === true
                      ? "Suspend Cashier"
                      : "Unsuspend Cashier"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    ],
    [navigate]
  );

  const debouncedInputValue = useDebounce<string>(query, 2000);

  useEffect(() => {
    if (debouncedInputValue !== "") {
      queryClient.fetchQuery(
        ["cashiers", activeItem, debouncedInputValue],
        () => API_SERVICE._getAllCashiers(activeItem, debouncedInputValue)
      );
    }
  }, [activeItem, debouncedInputValue, queryClient]);

  useEffect(() => {
    queryClient.fetchQuery(["cashiers", activeItem, debouncedInputValue], () =>
      API_SERVICE._getAllCashiers(activeItem)
    );
  }, [activeItem, debouncedInputValue, queryClient]);

  useEffect(() => {
    moengage.initialize({ app_id: process.env.REACT_APP_MOENGAGE_APPID });
    if (merchant_data) {
      moengage.add_first_name(merchant_data?.firstname);
      moengage.add_last_name(merchant_data?.lastname);
      moengage.add_email(merchant_data?.email);
      moengage.add_mobile(merchant_data?.mobile_number);
      moengage.add_user_name(merchant_data?.email);
      moengage.add_unique_user_id(merchant_data?.email);
      moengage.add_user_attribute(
        "business_name",
        merchant_data?.business_name
      );
    }
  }, [merchant_data]);

  return (
    <>
      <CreateCashierModal
        show={open}
        handleClose={() => {
          refetch();
          setOpen(false);
        }}
      />

      {size?.[0] <= 998 ? (
        <>
          {cashierData?.length === 0 ? (
            <>
              <div
                className="d-flex flex-column align-items-center justify-content-center mx-auto text-center"
                style={{ height: "60vh" }}
              >
                <div>
                  <People />

                  <p>You’ve created no Cashiers yet!</p>

                  <button
                    className="btn btn-primary btn-sm"
                    // onClick={() => navigate("/merchant/virtual-accounts")}
                  >
                    <i className="bi-people-fille me-1"></i> Create cashier
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <CashierSm />
            </>
          )}
        </>
      ) : (
        <>
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title">Cashiers</h1>
              </div>

              <div className="col-sm-auto">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    navigate(`/merchant/business/cashier/create_cashier`)
                  }
                >
                  Create Cashier <i className="bi bi-plus-lg me-1"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header card-header-content-md-between">
              <div className="mb-2 mb-md-0">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div
                    className="input-group input-group-merge input-group-flush"
                    style={{ minWidth: "160%", maxWidth: "100%" }}
                  >
                    <div className="input-group-prepend input-group-text">
                      <i className="bi-search"></i>
                    </div>
                    <input
                      id="datatableSearch"
                      type="search"
                      value={query}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Search by till name, account no"
                      aria-label="Search users"
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="table-responsive datatable-custom position-relative">
              <Table
                columns={columns}
                data={cashierData !== undefined ? cashierData : []}
                isFetching={isFetching}
                navigateTo={`/merchant/business/cashier/`}
                eventName="VIEW_CASHIER"
              />
            </div>

            <div className="card-footer">
              <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                <div className="col-sm mb-2 mb-sm-0"></div>

                <div className="col-sm-auto">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    <PaginationComponent
                      totalItems={totalItems}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <DeactivateCashierModal
        show={show}
        handleClose={() => {
          refetch();
          setShow(false);
          setCashierId("");
          setCashierInfo({});
          setStatus(false);
        }}
        id={cashierId}
        cashierData={cashierInfo}
        actionType={!status ? "unblock" : "block"}
      />
    </>
  );
};

export default Cashier;
