import React from 'react'

const AdminContent = () => {
	return (
		<>
			<div className="card card-body mb-3 mb-lg-5">
				<div className="row col-lg-divider gx-lg-6">
					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									Total collection value
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$7,820.75</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-shop"></i>
							</span>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									total collection volume
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$985,937.45</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-layout-text-window-reverse"></i>
							</span>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									total transfer volume
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$15,503.00</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-percent"></i>
							</span>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									total transfer value
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$3,982.53</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-bag"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="card card-body mb-3 mb-lg-5">
				<div className="row col-lg-divider gx-lg-6">
					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									Total collection value
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$7,820.75</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-shop"></i>
							</span>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									total collection volume
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$985,937.45</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-layout-text-window-reverse"></i>
							</span>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									total transfer volume
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$15,503.00</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-percent"></i>
							</span>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="d-flex">
							<div className="flex-grow-1">
								<h6 className="card-subtitle mb-3">
									total transfer value
									<i
										className="bi-question-circle text-body ms-1"
										data-bs-toggle="tooltip"
										data-bs-placement="top"
										title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
									></i>
								</h6>
								<h3 className="card-title">$3,982.53</h3>
							</div>

							<span className="icon icon-soft-secondary icon-sm icon-circle ms-3">
								<i className="bi-bag"></i>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="card mb-3 mb-lg-5">
				<div className="card-header card-header-content-sm-between">
					<h4 className="card-header-title mb-2 mb-sm-0">
						Activity Metrics
						<i
							className="bi-question-circle text-body ms-1"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
						></i>
					</h4>
				</div>

				<div className="card-body">
					<div className="row col-lg-divider">
						<div className="col-lg-9 mb-5 mb-lg-0">
							<div className="chartjs-custom mb-4"></div>

							<div className="row justify-content-center">
								<div className="col-auto">
									<span className="legend-indicator"></span> collection
								</div>

								<div className="col-auto">
									<span className="legend-indicator bg-primary"></span> transfer
								</div>
							</div>
						</div>

						<div className="col-lg-3">
							<div className="row">
								<div className="col-sm-6 col-lg-12">
									<div
										className="d-flex justify-content-center flex-column"
										style={{ minHeight: "9rem" }}
									>
										<h6 className="card-subtitle">Gross Revenue</h6>
										<span className="d-block display-4 text-dark mb-1 me-3"
										>$97,458.20</span>
									</div>

									<hr className="d-none d-lg-block my-0" />
								</div>

								<div className="col-sm-6 col-lg-12">
									<div
										className="d-flex justify-content-center flex-column"
										style={{ minHeight: "9rem" }}
									>
										<h6 className="card-subtitle">Net reveneue</h6>
										<span className="d-block display-4 text-dark mb-1 me-3"
										>67,893</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="card">
				<div className="row col-lg-divider">
					<div className="col-lg-6">
						<div className="card-body">
							<h4 className="card-subtitle">
								Reserved account created today
								<i
									className="bi-question-circle text-body ms-1"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									title="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."
								></i>
							</h4>

							<div className="row align-items-sm-center mt-4 mt-sm-0 mb-5">
								<div className="col-sm mb-3 mb-sm-0">
									<span className="display-5 text-dark me-2">67677,898</span>
								</div>

							</div>

							<div className="chartjs-custom mb-4" style={{ height: "18rem" }}>
								<canvas
									id="ecommerce-overview-1"
									className="js-chart"
									data-hs-chartjs-options='{
                          "type": "line",
                          "data": {
                             "labels": ["1AM","2AM","3AM","4AM","5AM","6AM","7AM","8AM","9AM","10AM"],
                             "datasets": [{
                              "data": [200, 200, 240, 350, 200, 350, 200, 250, 285, 220],
                              "backgroundColor": "transparent",
                              "borderColor": "#377dff",
                              "borderWidth": 2,
                              "pointRadius": 0,
                              "hoverBorderColor": "#377dff",
                              "pointBackgroundColor": "#377dff",
                              "pointBorderColor": "#fff",
                              "pointHoverRadius": 0,
                              "tension": 0.4
                            },
                            {
                              "data": [150, 230, 382, 204, 269, 290, 200, 250, 200, 225],
                              "backgroundColor": "transparent",
                              "borderColor": "#e7eaf3",
                              "borderWidth": 2,
                              "pointRadius": 0,
                              "hoverBorderColor": "#e7eaf3",
                              "pointBackgroundColor": "#e7eaf3",
                              "pointBorderColor": "#fff",
                              "pointHoverRadius": 0,
                              "tension": 0.4
                            }]
                          },
                          "options": {
                             "scales": {
                                "y": {
                                  "grid": {
                                    "color": "#e7eaf3",
                                    "drawBorder": false,
                                    "zeroLineColor": "#e7eaf3"
                                  },
                                  "ticks": {
                                    "beginAtZero": true,
                                    "stepSize": 100,
                                    "color": "#97a4af",
                                    "font": {
                                      "size": 12,
                                      "family": "Open Sans, sans-serif"
                                    },
                                    "padding": 10,
                                    "prefix": "$",
                                    "postfix": "k"
                                  }
                                },
                                "x": {
                                  "grid": {
                                    "display": false,
                                    "drawBorder": false
                                  },
                                  "ticks": {
                                    "color": "#97a4af",
                                    "font": {
                                      "size": 12,
                                      "family": "Open Sans, sans-serif"
                                    },
                                    "padding": 5
                                  }
                                }
                            },
                            "plugins": {
                              "tooltip": {
                                "prefix": "$",
                                "postfix": "k",
                                "hasIndicator": true,
                                "mode": "index",
                                "intersect": false,
                                "lineMode": true,
                                "lineWithLineColor": "rgba(19, 33, 68, 0.075)"
                              }
                            },
                            "hover": {
                              "mode": "nearest",
                              "intersect": true
                            }
                          }
                        }'
								>
								</canvas>
							</div>

							<div className="row justify-content-center">
								<div className="col-auto">
									<span className="legend-indicator"></span> Yesterday
								</div>

								<div className="col-auto">
									<span className="legend-indicator bg-primary"></span> Today
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="card-body">
							<h4 className="card-subtitle">disposable accounr created</h4>

							<div className="row align-items-sm-center mt-4 mt-sm-0 mb-5">
								<div className="col-sm mb-3 mb-sm-0">
									<span className="display-5 text-dark me-2">831,071</span>
								</div>

							</div>

							<div className="chartjs-custom mb-4" style={{ height: "18rem" }}></div>

							<div className="row justify-content-center">
								<div className="col-auto">
									<span className="legend-indicator"></span> Yesterday
								</div>

								<div className="col-auto">
									<span className="legend-indicator bg-primary"></span> Today
								</div>
							</div>
						</div>
					</div>
				</div>

				<hr />

				<div className="row col-lg-divider">
					<div className="col-lg-6">
						<div className="card-body">
							<h4 className="card-subtitle">New merchant</h4>

							<div className="row align-items-sm-center mt-4 mt-sm-0 mb-5">
								<div className="col-sm mb-3 mb-sm-0">
									<span className="display-5 text-dark me-2">1,348,935</span>
								</div>

							</div>

							<div className="chartjs-custom mb-4" style={{ height: "18rem" }}></div>

							<div className="row justify-content-center">
								<div className="col-auto">
									<span className="legend-indicator"></span> Yesterday
								</div>

								<div className="col-auto">
									<span className="legend-indicator bg-primary"></span> Today
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="card-body">
							<h4 className="card-subtitle">payouts</h4>

							<div className="row align-items-sm-center mt-4 mt-sm-0 mb-5">
								<div className="col-sm mb-3 mb-sm-0">
									<span className="display-5 text-dark me-2">52,441</span>
								</div>

							</div>

							<div className="chartjs-custom mb-4" style={{ height: "18rem" }}></div>

							<div className="row justify-content-center">
								<div className="col-auto">
									<span className="legend-indicator"></span> Yesterday
								</div>

								<div className="col-auto">
									<span className="legend-indicator bg-primary"></span> Today
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AdminContent