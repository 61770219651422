import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { ReactComponent as MtnIcon } from "../../assets/mtn.svg";
import OtpFields from "../../component/otp/OtpFields";
import SpinnerComponent from "../../component/spinner/Spinner";
import { formatAmount } from "../../helpers/index.helper";
import { providers } from "./Data";
import { useSession } from "../../hooks/auth/auth.hook";
import { sessionInterface } from "../../utils/interfaces";
import { useResolveOTPForPin } from "../../hooks/transfer/index.hook";
import MakeTransferModalMobile from "../../component/modal/MakeTransferModalMobile";

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 18l-6-6 6-6"
      ></path>
    </svg>
  );
}

const KeyPadPin = ({
  size,
  handleOtpChange,
  processor,
  handleProceed,
  isDisabled,
}: any) => {
  const { data, refetch } = useSession();
  const { resolve_otp_pin } = useResolveOTPForPin();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const merchant_data: sessionInterface = data?.data?.data;
  const transactionPinSet: boolean | any =
    merchant_data?.state?.transaction_pin_set;
  const sendOTP = async () => {
    setLoading(true);
    try {
      const response = await resolve_otp_pin.mutateAsync();
      if (response.status === 200) {
        setOpen(true);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      return error;
    }
  };
  return (
    <>
      <MakeTransferModalMobile
        show={open}
        mobileStage="set-pin"
        handleClose={() => {
          refetch();
          setOpen(false);
        }}
        refetch={() => {
          refetch();
          setOpen(false);
        }}
        from="profile"
      />
      {transactionPinSet && (
        <div
          className="d-flex flex-column w-100"
          style={{
            marginTop: size?.[0] <= 998 ? "3px" : "38px",
            marginBottom: "70px",
            alignItems: "center",
          }}
        >
          <div
            className="text-black"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "18.426px",
              letterSpacing: "-0.14px",
            }}
          >
            Enter transaction PIN*
          </div>
          <br />
          <OtpFields
            numInputs={4}
            secret
            onOtpInput={handleOtpChange}
            style={{
              padding: "0px",
              width: "70%",
              marginTop: "8px",
            }}
            inputMode="number"
            readOnly={false}
            inputStyles={{
              border: "1px solid #CBD1EC",
              width: "57px",
            }}
          />
        </div>
      )}
      {transactionPinSet ? (
        <div
          className="w-100"
          style={{
            marginBottom: "33px",
          }}
        >
          <button
            type={"button"}
            className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
            disabled={processor.isLoading || isDisabled}
            onClick={() => {
              handleProceed();
            }}
            style={{
              borderRadius: "8px",
              background: "#2C1DFF",
            }}
          >
            Complete Purchase&emsp;
            {(processor.isLoading || isDisabled) && (
              <span className="align-middle ml-2">
                &emsp;
                <SpinnerComponent size="sm" />
              </span>
            )}
          </button>
        </div>
      ) : (
        <div
          className="w-100"
          style={{
            marginBottom: "33px",
            marginTop: "33px",
          }}
        >
          <button
            type={"button"}
            className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
            disabled={loading}
            onClick={() => {
              sendOTP();
            }}
            style={{
              borderRadius: "8px",
              background: "#2C1DFF",
            }}
          >
            Set Up PIN
          </button>
        </div>
      )}
    </>
  );
};

const PurchaseModal = ({
  show,
  handleClose,
  handlePay,
  processor,
  data,
  noModal,
  size,
  formsData,
  title = "airtime",
  handleRefetch,
  isDisabled,
  acttionType,
}: {
  title?: string;
  formsData: any;
  show: boolean;
  noModal: boolean;
  size: any;
  handleClose: () => void;
  data: any;
  handlePay: (transaction_pin: string) => void;
  processor: any;
  handleRefetch: () => void;
  isDisabled: boolean;
  acttionType?: string;
}) => {
  const [showPin, setShowPin] = useState(false);
  const [transaction_pin, setTransactionPin] = useState("");
  const handleOtpChange = (otp: any) => {
    setTransactionPin(otp);
  };
  const handleProceed = async () => {
    try {
      await handlePay(transaction_pin);
      acttionType === "DATA"
        ? moengage.track_event("COMPLETE_DATA_UP", {
            success_status: true,
          })
        : moengage.track_event("COMPLETE_TOP_UP", {
            success_status: true,
          });
    } catch (e) {
      return e;
    }
  };
  const workingData = formsData;

  if (noModal) {
    return (
      <div className="d-flex flex-column justify-content-start align-items-center">
        <div className="d-flex flex-row justify-content-start py-3 align-items-center w-100 position-relative">
          <div
            style={{ cursor: "pointer" }}
            onClick={!showPin ? handleClose : () => setShowPin(false)}
          >
            <BackIcon />
          </div>
          <p className="text-center mb-0">Complete {title} purchase</p>
        </div>
        {!showPin ? (
          <>
            <div
              style={{ maxHeight: "60vh", overflowY: "auto" }}
              className="d-flex flex-column justify-content-center align-items-center w-100"
            >
              {workingData?.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  <div
                    className="text-center w-100 text-black"
                    style={{
                      height: "100%",
                      padding: "8px 55px",
                      background: "#DDE7FB",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                      }}
                    >
                      Amount
                    </div>
                    <div
                      style={{
                        fontSize: "24px",
                        fontWeight: 500,
                        lineHeight: "22px",
                      }}
                    >
                      {formatAmount(item?.amount)}
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-2 mb-3"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "22px",
                      marginRight: "10px",
                    }}
                  >
                    for{" "}
                    <img
                      src={
                        providers.filter(
                          (provider) =>
                            formsData?.filter(
                              (i: any) =>
                                i?.mobile_number ===
                                item?.mobile_number?.replace("234", "0")
                            )?.[0]?.network_provider === provider?.name
                        )?.[0]?.icon
                      }
                      alt={
                        providers.filter(
                          (provider) =>
                            formsData?.filter(
                              (i: any) =>
                                i?.mobile_number ===
                                item?.mobile_number?.replace("234", "0")
                            )?.[0]?.network_provider === provider?.name
                        )?.[0]?.name
                      }
                      style={{ height: 24, width: 24, marginLeft: "5px" }}
                    />
                    <div
                      className="d-flex align-items-center text-black"
                      style={{
                        gap: "5px",
                        marginLeft: "5px",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "22px",
                      }}
                    >
                      {item?.mobile_number}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {workingData?.length <= 2 ? (
              <KeyPadPin
                {...{
                  size,
                  handleOtpChange,
                  processor,
                  handleProceed,
                  isDisabled,
                }}
              />
            ) : (
              <div
                className="w-100"
                style={{
                  marginBottom: "33px",
                }}
              >
                <br />
                <button
                  type={"button"}
                  className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                  disabled={processor.isLoading}
                  onClick={() => {
                    setShowPin(true);
                  }}
                  style={{
                    borderRadius: "8px",
                    background: "#2C1DFF",
                  }}
                >
                  Continue
                </button>
              </div>
            )}
          </>
        ) : (
          <KeyPadPin
            {...{ size, handleOtpChange, processor, handleProceed, isDisabled }}
          />
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        contentClassName="top-up-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              className="text-black"
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                letterSpacing: "-0.32px",
              }}
            >
              Complete {title} purchase
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column justify-content-start align-items-center">
            {!showPin ? (
              <>
                <div
                  style={{ maxHeight: "60vh", overflowY: "auto" }}
                  className="d-flex flex-column justify-content-start align-items-center w-100"
                >
                  {workingData?.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      <div
                        className="text-center w-100 text-black"
                        style={{
                          height: "100%",
                          padding: "8px 55px",
                          background: "#DDE7FB",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                          }}
                        >
                          Amount
                        </div>
                        <div
                          style={{
                            fontSize: "24px",
                            fontWeight: 500,
                            lineHeight: "22px",
                          }}
                        >
                          {formatAmount(item?.amount ?? item?.data_amount)}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center mt-2 mb-3"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        for{" "}
                        <img
                          src={
                            providers.filter(
                              (provider) =>
                                formsData?.filter(
                                  (i: any) =>
                                    i?.mobile_number ===
                                    item?.mobile_number?.replace("234", "0")
                                )?.[0]?.network_provider === provider?.name
                            )?.[0]?.icon
                          }
                          alt={
                            providers.filter(
                              (provider) =>
                                formsData?.filter(
                                  (i: any) =>
                                    i?.mobile_number ===
                                    item?.mobile_number?.replace("234", "0")
                                )?.[0]?.network_provider === provider?.name
                            )?.[0]?.name
                          }
                          style={{ height: 24, width: 24, marginLeft: "5px" }}
                        />
                        <div
                          className="d-flex align-items-center text-black"
                          style={{
                            gap: "5px",
                            marginLeft: "5px",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          {item?.mobile_number}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                {workingData?.length <= 2 ? (
                  <KeyPadPin
                    {...{
                      size,
                      handleOtpChange,
                      processor,
                      handleProceed,
                      isDisabled,
                    }}
                  />
                ) : (
                  <div
                    className="w-100"
                    style={{
                      marginBottom: "33px",
                    }}
                  >
                    <br />
                    <button
                      type={"button"}
                      className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                      disabled={processor.isLoading}
                      onClick={() => {
                        setShowPin(true);
                      }}
                      style={{
                        borderRadius: "8px",
                        background: "#2C1DFF",
                      }}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </>
            ) : (
              <KeyPadPin
                {...{
                  size,
                  handleOtpChange,
                  processor,
                  handleProceed,
                  isDisabled,
                }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default PurchaseModal;
