import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MaskNum, formatAmountWithDecimal } from "../../helpers/index.helper";
import { useTransactionInfo } from "../../hooks/transactions/index.hook";
import {
  ITransaction,
  bankInterface,
  sessionInterface,
} from "../../utils/interfaces";
// import { PATH_NAMES } from "../../constants/index.constant";
import Skeleton from "react-loading-skeleton";
import { useGetBanks } from "../../hooks/payouts/payouts.hook";
// import BackBtn from "../../component/back-btn";
// import { sessionInterface } from "../../utils/interfaces";
import CopyButton from "../../component/Copy";
import { useSession } from "../../hooks/auth/auth.hook";

const TransactionDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useTransactionInfo(id);
  const { bankData } = useGetBanks();
  const { sessionData } = useSession();
  const banks: bankInterface[] = bankData?.data?.data;

  const transaction: ITransaction = data?.data?.data;
  const reference = transaction?.reference;
  const merchant_data: sessionInterface = sessionData?.data?.data;
  const [senderBank, setSenderBank] = useState<any>();
  const [recBank, setRecBank] = useState<any>();

  const reciever_bankcode = transaction?.reciever_details?.bank_code;
  const sender_bankcode = transaction?.sender_details?.bank_code;

  console.log(sender_bankcode, "hh");

  useEffect(() => {
    if (sender_bankcode) {
      const senderBankName: any =
        banks?.length > 0 &&
        banks?.filter((bank) => bank.bank_code === sender_bankcode);
      console.log(senderBankName, "bank name");
      setSenderBank(senderBankName[0]);
    }
    if (reciever_bankcode) {
      const receiverBankName: any =
        banks?.length > 0 &&
        banks?.filter((bank) => bank.bank_code === reciever_bankcode);
      setRecBank(receiverBankName[0]);
    }
  }, [banks, reciever_bankcode, sender_bankcode]);

  console.log(senderBank, "senderBank");

  return (
    <>
      <main>
        <div>
          <div className="page-header d-print-none">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <h1
                  className="page-header-title d-flex"
                  onClick={() => {
                    navigate("/merchant/transactions");
                  }}
                >
                  {/* <BackBtn /> */}
                  <i
                    className="bi bi-arrow-left me-2"
                    style={{ fontSize: "#000", cursor: "pointer" }}
                  ></i>
                  {/* {transaction?.payout?.reference || transaction?.reference} */}
                  <span className="d-none d-lg-block">
                    {transaction?.reference}{" "}
                  </span>
                  {/* VISIBLE ONLY ON SM SCREENS */}
                  {reference?.length > 15 ? (
                    <span className="d-sm-block d-md-none d-lg-none">
                      {transaction?.reference}{" "}
                    </span>
                  ) : (
                    <span className="d-sm-block d-md-none d-lg-none">
                      {transaction?.reference}
                    </span>
                  )}
                  {/* VISIBLE ONLY ON MD SCREENS */}
                  <span className="d-none d-md-block d-lg-none">
                    {transaction?.reference}{" "}
                  </span>
                </h1>{" "}
              </div>
            </div>
          </div>

          <div className="row">
            {/* TRANSACTION DETAILS */}
            <div className="col-lg-6 mb-3 mb-lg-0">
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title tranx-headerdd link link-dark text-cap">
                    Transaction Details
                  </h4>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="trx-key">Account ID</h4>
                      <p className="link link-dark">
                        {transaction?.settlement_account_id}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 d-flex flex-row">
                      <h4 className="trx-key">Account Number</h4>
                      <p className="link link-dark">
                        {transaction?.account_no}
                        {transaction?.account_no && (
                          <CopyButton text={transaction?.account_no} />
                        )}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="trx-key"> BVN</h4>
                      <p className="link link-dark">
                        {MaskNum(transaction?.sender_details?.bvn)}
                      </p>{" "}
                    </div>
                  </div>

                  {isLoading ? (
                    <div className="col">
                      <Skeleton />
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          <h4 className="trx-key">Currency</h4>
                          <p className="link link-dark">
                            {transaction?.currency}
                          </p>{" "}
                        </div>
                        <div className="col-md-4">
                          <h4 className="trx-key">Account Type</h4>
                          <p className="link link-dark">
                            {transaction?.is_virtual_account === true
                              ? "Virtual Account"
                              : "Settlement Account"}
                          </p>{" "}
                        </div>
                        <hr />
                      </div>
                    </>
                  )}
                </div>
                <div className="card-body">
                  <div className="list-group list-group-flush list-group-no-gutters">
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Status
                                  </span>
                                </div>

                                <div className="col-auto">
                                  {transaction?.completed === true && (
                                    <h3 className="badge bg-soft-success text-success">
                                      COMPLETED
                                    </h3>
                                  )}
                                  {transaction?.completed === false && (
                                    <h5 className="badge bg-soft-danger text-danger">
                                      FAILED
                                    </h5>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Transaction Type
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.transaction_type?.toUpperCase()}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Amount
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    <CopyButton
                                      text={formatAmountWithDecimal(
                                        transaction?.amount
                                      )}
                                      className="d-none"
                                    />
                                    &emsp;{transaction?.currency}{" "}
                                    {formatAmountWithDecimal(
                                      transaction?.amount
                                    )}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Fee Amount
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.currency}{" "}
                                    {formatAmountWithDecimal(
                                      transaction?.fee_amount
                                    )}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Currency
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.currency === "NGN"
                                      ? "Nigerian Naira"
                                      : "NGN"}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Reference
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <div className="col-auto">
                                    <h5 className="d-none d-lg-block link link-dark">
                                      {transaction?.reference}{" "}
                                    </h5>
                                    {/* VISIBLE ONLY ON SM SCREENS */}
                                    {reference?.length > 15 ? (
                                      <h5 className="d-sm-block d-md-none d-lg-none w-100 link link-dark">
                                        {transaction?.reference}{" "}
                                      </h5>
                                    ) : (
                                      <h5 className="d-sm-block d-md-none d-lg-none link link-dark w-75">
                                        {transaction?.reference}
                                      </h5>
                                    )}
                                    {/* VISIBLE ONLY ON MD SCREENS */}
                                    <h5 className="d-none d-md-block d-lg-none link link-dark">
                                      {transaction?.reference}{" "}
                                    </h5>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Session ID
                                  </span>
                                </div>
                                <div className="col-auto">
                                  {/* <h5 className="">
                                    {transaction?.session_id}{" "}
                                  </h5> */}
                                  {/* VISIBLE ONLY ON LG SCREENS */}
                                  <h5 className="d-none d-lg-block link link-dark">
                                    <CopyButton
                                      text={transaction?.session_id}
                                    />
                                    &emsp;{transaction?.session_id}{" "}
                                  </h5>
                                  {/* VISIBLE ONLY ON SM SCREENS */}
                                  <h5 className="d-sm-block d-md-none d-lg-none w-100 link link-dark">
                                    <span className="d-flex align-items-end">
                                      <span>
                                        <CopyButton
                                          text={transaction?.session_id}
                                        />
                                      </span>
                                      <span className="pe-1">
                                        {transaction?.session_id}
                                      </span>
                                    </span>
                                  </h5>
                                  {/* VISIBLE ONLY ON MD SCREENS */}
                                  <h5 className="d-none d-md-block d-lg-none link link-dark">
                                    <CopyButton
                                      text={transaction?.session_id}
                                    />
                                    &emsp;{transaction?.session_id}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Is Reversed
                                  </span>
                                </div>
                                <div className="col-auto">
                                  {transaction?.is_reversed === true && (
                                    <h3 className="badge bg-soft-warning text-warning">
                                      Yes
                                    </h3>
                                  )}
                                  {transaction?.is_reversed === false && (
                                    <h5 className="badge bg-soft-success text-success">
                                      No
                                    </h5>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            {isLoading ? (
                              <div className="col">
                                <Skeleton />
                              </div>
                            ) : (
                              <>
                                <div className="col">
                                  <span className="d-block trx-key">
                                    Narration
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <h5 className="link link-dark">
                                    {transaction?.narration}{" "}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-3 mb-lg-0">
              {/* SENDER DETAILS */}
              <div className="col-lg-12">
                <div className="card mb-4">
                  <div className="card-header">
                    <h4 className="card-header-title tranx-headerff link link-dark text-cap">
                      Sender Details
                    </h4>
                  </div>

                  <div className="card-body">
                    <div className="list-group list-group-flush list-group-no-gutters">
                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Display Name
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      {transaction?.sender_details?.name?.toUpperCase()}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Account Number
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      <CopyButton
                                        text={
                                          transaction?.sender_details
                                            ?.account_number
                                        }
                                      />
                                      &emsp;
                                      {
                                        transaction?.sender_details
                                          ?.account_number
                                      }{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Bank Name
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      <CopyButton
                                        text={
                                          transaction?.sender_details
                                            ?.bank_name || senderBank?.bank_name
                                        }
                                        className="d-none"
                                      />
                                      &emsp;
                                      {transaction?.sender_details?.bank_name ||
                                        senderBank?.bank_name}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Currency
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      {transaction?.currency}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Session ID
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    {/* VISIBLE ONLY ON LG SCREENS */}
                                    <h5 className="d-none d-lg-block link link-dark">
                                      <CopyButton
                                        text={transaction?.session_id}
                                      />
                                      &emsp;{transaction?.session_id}{" "}
                                    </h5>
                                    {/* VISIBLE ONLY ON SM SCREENS */}
                                    <h5 className="d-sm-block d-md-none d-lg-none w-75 link link-dark">
                                      <CopyButton
                                        text={transaction?.session_id}
                                      />
                                      &emsp;{transaction?.session_id}{" "}
                                    </h5>
                                    {/* VISIBLE ONLY ON MD SCREENS */}
                                    <h5 className="d-none d-md-block d-lg-none link link-dark">
                                      <CopyButton
                                        text={transaction?.session_id}
                                      />
                                      &emsp;{transaction?.session_id}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Reference
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    {/* <h5 className="d-none d-lg-block">
                                    {transaction?.reference}{" "}
                                  </h5>
                                  <h5 className="d-block w-50 d-lg-none">
                                    {transaction?.reference}{" "}
                                  </h5> */}
                                    {/* VISIBLE ONLY ON LG SCREENS */}
                                    <h5 className="d-none d-lg-block link link-dark">
                                      {transaction?.reference}{" "}
                                    </h5>
                                    {/* VISIBLE ONLY ON SM SCREENS */}
                                    {reference?.length > 15 ? (
                                      <h5 className="d-sm-block d-md-none d-lg-none w-50 link link-dark">
                                        {transaction?.reference}{" "}
                                      </h5>
                                    ) : (
                                      <h5 className="d-sm-block d-md-none d-lg-none w-75 link link-dark">
                                        {transaction?.reference}
                                      </h5>
                                    )}
                                    {/* VISIBLE ONLY ON MD SCREENS */}
                                    <h5 className="d-none d-md-block d-lg-none link link-dark">
                                      {transaction?.reference}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Status
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    {transaction?.completed === true && (
                                      <h3 className="badge bg-soft-success text-success">
                                        COMPLETED
                                      </h3>
                                    )}
                                    {transaction?.completed === false && (
                                      <h5 className="badge bg-soft-danger text-danger">
                                        FAILED
                                      </h5>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Transaction Date
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      {transaction?.created_at}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* RECEIVER DETAILS */}
              <div className="col-lg-12">
                <div className="card mb-4">
                  <div className="card-header">
                    <h4 className="card-header-title tranx-headerff link link-dark text-cap">
                      Beneficiary’s Details
                    </h4>
                  </div>

                  <div className="card-body">
                    <div className="list-group list-group-flush list-group-no-gutters">
                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Account Number
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      <CopyButton
                                        text={
                                          transaction?.reciever_details
                                            ?.account_number
                                        }
                                      />
                                      &emsp;
                                      {
                                        transaction?.reciever_details
                                          ?.account_number
                                      }{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Account Name
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      {transaction?.reciever_details?.name?.toUpperCase()}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Bank
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      <CopyButton text={recBank?.bank_name} />
                                      &emsp;{recBank?.bank_name}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Currency
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      {transaction?.currency}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">BVN</span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      {transaction?.reciever_details?.bvn
                                        ? MaskNum(
                                            transaction?.reciever_details?.bvn
                                          )
                                        : "N/A"}{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="list-group-item">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-2">
                            <div className="row align-items-center">
                              {isLoading ? (
                                <div className="col">
                                  <Skeleton />
                                </div>
                              ) : (
                                <>
                                  <div className="col">
                                    <span className="d-block trx-key">
                                      Bank code
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <h5 className="link link-dark">
                                      <CopyButton
                                        text={
                                          transaction?.reciever_details
                                            ?.bank_code
                                        }
                                      />
                                      &emsp;
                                      {
                                        transaction?.reciever_details?.bank_code
                                      }{" "}
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SETTLEMENT ACCOUNT */}
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title link link-dark text-cap">
                Settlement account
              </h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <h4 className="trx-key">Settlement Amount</h4>
                  <p>
                    {transaction?.currency}{" "}
                    {formatAmountWithDecimal(transaction?.settled_amount)}
                    <CopyButton
                      text={formatAmountWithDecimal(
                        transaction?.settled_amount
                      )}
                      className="d-none"
                    />
                  </p>{" "}
                </div>
                <div className="col-md-3">
                  <h4 className="trx-key">Account Number</h4>
                  <p>
                    {transaction?.reciever_details?.account_number}
                    <CopyButton
                      text={transaction?.reciever_details?.account_number}
                    />
                  </p>{" "}
                </div>
                {/* <div className="col-md-3">
                  <h4 className="trx-key">Account Type</h4>
                  <p>Parent</p>
                </div> */}
                <div className="col-md-3">
                  <h4 className="trx-key"> Settlement ID</h4>
                  <p>{transaction?.settlement_account_id}</p>{" "}
                </div>
                <div className="col-md-3">
                  <h4 className="trx-key">Currency</h4>
                  <p>
                    {transaction?.currency === "NGN" ? "Nigerian Naira" : "NGN"}
                  </p>{" "}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <h4 className="trx-key">Email</h4>
                  <p>{sessionData?.data?.data?.email}</p>{" "}
                </div>
                <div className="col-md-3">
                  <h4 className="trx-key"> Status</h4>
                  <div className="col-auto">
                    {transaction?.completed === true && (
                      <h3 className="badge bg-soft-success text-success">
                        COMPLETED
                      </h3>
                    )}
                    {transaction?.completed === false && (
                      <h5 className="badge bg-soft-danger text-danger">
                        FAILED
                      </h5>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <h4 className="trx-key">Timestamp</h4>
                  <p>{transaction?.modified_at}</p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TransactionDetails;
