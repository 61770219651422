import React from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeString } from "../../helpers/index.helper";
import OverlayTooltip from "../OverlayTooltip";
import MakeTransferModal from "../modal/MakeTransfer";

const TransferHeader = ({
  refetch,
  disbleTransfer,
}: {
  refetch: () => void;
  disbleTransfer: boolean;
}) => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  return (
    <React.Fragment>
      {show && (
        <MakeTransferModal
          show={show}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
      <div className="page-header">
        <div className="row align-items-center mb-3">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Transfer</h1>
          </div>

          <div className="col-sm-auto">
            {disbleTransfer ? (
              <OverlayTooltip
                tooltipMsg={capitalizeString(
                  "You can not make Transfer until KYC is completed. Kindly complete your KYC then proceed to make a transfer."
                )}
              >
                <button
                  className="btn btn-primary card-subtitle"
                  style={{ opacity: "0.3" }}
                  // onClick={() => setShow(true)}
                  onClick={() => navigate("/merchant/make_transfer")}
                >
                  Make Transfer <i className="bi bi-arrow-up-right"></i>
                </button>
              </OverlayTooltip>
            ) : (
              <button
                className="btn btn-primary card-subtitle"
                style={{ color: "white" }}
                disabled={disbleTransfer}
                // onClick={() => setShow(true)}
                onClick={() => navigate("/merchant/make_transfer")}
              >
                Make Transfer <i className="bi bi-arrow-up-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransferHeader;

// total collection volume
//  total collection value
// totol transaction volume
// total transfer value
