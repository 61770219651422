import moengage from "@moengage/web-sdk";
import React, { createContext, useContext, useState } from "react";
import useUserDeviceInfo from "../hooks/device.hook";

const ClickContext: any = createContext({});

export const ClickContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { deviceInfo } = useUserDeviceInfo();
  const [isModal, setIsModal] = useState(false);
  const onClick = (): any => {
    setIsModal(!isModal);
    moengage.track_event("GUIDED_TOUR", {
      initiates_tour: true,
      platform: deviceInfo?.userAgent,
    });
  };

  const contextValue: any = {
    onClick,
    isModal,
  };

  return (
    <ClickContext.Provider value={contextValue}>
      {children}
    </ClickContext.Provider>
  );
};

export const useClickContext: any = () => {
  return useContext(ClickContext);
};
