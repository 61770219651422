import moengage from "@moengage/web-sdk";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { PATH_NAMES } from "./constants/index.constant";
import { ClickContextProvider } from "./context/tourContext";
import Otp from "./pages/auth/otp";
import ResetInstruction from "./pages/auth/resetInstruction";
import ResetToken from "./pages/auth/resetToken";
import Resetpassword from "./pages/auth/resetpassword";
import Signup from "./pages/auth/signup";
import Signin from "./pages/auth/singin";
import VerifyEmail from "./pages/auth/verifyemail";
import CashierSetup from "./pages/cashier/auth/CashierSetup";
import Preview from "./pages/payment-page/Preview";
import Failure from "./pages/payment-page/failure";
import PaymentPage from "./pages/payment-page/index";
import Success from "./pages/payment-page/success";
import CashierAuthenticationRoute from "./routes/cashier/auth.route";
import CashierRoute from "./routes/cashier/cashier.route";
import ClientAuthRoutes from "./routes/client/auth.route";
import ClientRoutes from "./routes/client/client.route";
import SuperAdminRoutes from "./routes/super-admin/admin.route";
import SuperAdminAuthRoutes from "./routes/super-admin/auth.route";

function App() {
  useEffect(() => {
    moengage.initialize({ app_id: process.env.REACT_APP_MOENGAGE_APPID });
  }, []);

  return (
    <ClickContextProvider>
      <div id="app" role="application" className="overflow-hidden">
        <BrowserRouter>
          <Routes>
            {/* PAYMENT LINK PAGE */}
            <Route path="/:reference" element={<PaymentPage />} />
            <Route
              path="/success/:amount/:currency_code/:merchant/:message/:success_message"
              element={<Success />}
            />
            <Route
              path="/success/:amount/:currency_code/:merchant/:message"
              element={<Success />}
            />
            <Route
              path="/failure/:amount/:currency_code/:merchant/:message/:failure_message"
              element={<Failure />}
            />
            <Route
              path="/failure/:amount/:currency_code/:merchant/:message"
              element={<Failure />}
            />
            {/* global */}
            <Route path={PATH_NAMES.verify_otp} element={<Otp />} />
            <Route
              path={PATH_NAMES.reset_password}
              element={<Resetpassword />}
            />
            <Route path="/reset_instruction" element={<ResetInstruction />} />
            <Route path={`/reset/:token`} element={<ResetToken />} />

            {/* MERCHANT AUTH** */}
            <Route
              path={`/auth/signup`}
              element={<Navigate to={"/auth/merchant/signup"} />}
            />
            <Route path="/auth/merchant/signup" element={<Signup />} />
            <Route path="/auth/merchant/signin" element={<Signin />} />
            <Route path="/auth/cashier/setup" element={<CashierSetup />} />
            <Route path="/auth/cashier/signin" element={<Signin />} />
            <Route
              path="/auth/merchant/verify_email"
              element={<VerifyEmail />}
            />
            <Route
              path={`/auth/signin`}
              element={<Navigate to={"/auth/merchant/signin"} />}
            />
            <Route path={`${PATH_NAMES.preview}`} element={<Preview />} />
            <Route
              path={`/`}
              element={<Navigate to={`${PATH_NAMES.client_home}`} />}
            />
            {/* CASHIER */}
            <Route
              path={`${PATH_NAMES.cashier_auth}`}
              element={<CashierAuthenticationRoute />}
            />
            <Route
              path={PATH_NAMES.cashierDashboard}
              element={<CashierRoute />}
            />
            {/* ADMIN ROUTES */}
            <Route path={PATH_NAMES.admin} element={<SuperAdminRoutes />} />
            <Route
              path={PATH_NAMES.admin_auth}
              element={<SuperAdminAuthRoutes />}
            />

            {/* CLIENT/MERCHANT ROUTES*/}
            <Route path={PATH_NAMES.client} element={<ClientRoutes />} />
            <Route
              path={PATH_NAMES.client_auth}
              element={<ClientAuthRoutes />}
            />
          </Routes>
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover={false}
          role="alert"
          containerId={"toaster-id"}
          transition={Slide}
        />
      </div>
    </ClickContextProvider>
  );
}

export default App;
