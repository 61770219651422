import React from "react";
import { Modal } from "react-bootstrap";
import SpinnerComponent from "../spinner/Spinner";

function SendInvoiceReminderModal({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}): JSX.Element {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Send reminder</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row mb-4">
          <span className="text-center mx-auto text-dark">
            An invoice reminder will be sent to
          </span>
          <span className="text-center mx-auto text-dark fw-bold">
            michaelesigbone@gmail.com
          </span>
        </div>

        <div className="w-100 mx-auto text-center mt-4 pt-3">
          <button
            type="button"
            className="btn btn-primary w-100 mx-auto text-center"
          >
            Send reminder
            {false && <SpinnerComponent variant="light" size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SendInvoiceReminderModal;
