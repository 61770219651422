import "./index.css"

const ActionSheet = ({
  id = "actionsheet",
  title,
  children,
  show,
  onHide,
}: {
  id?: string;
  title: any;
  children: any;
  show: boolean;
  onHide: any;
}) => {
  return (
    <div>
      <div
        className={`overlay ${show ? "show-overlay" : ""}`}
        onClick={onHide}
      ></div>

      <div
        className={`modal-container mobile-mobile ${
          show ? "show-modal" : ""
        }`}
      >
        <div className="modal-content">
          <div className="modal-header py-3">
            <h5 className="modal-title" id={`${id}ModalLabel`}>
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onHide}
            ></button>
          </div>
          <div className="modal-body">
            <div className="h-auto" id="content-body">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default ActionSheet;
