/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState } from "react";
import Chart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../component/page-header/PageHeader";
import Table from "../../component/table/Table";
import { latest_txn_column } from "../../configs/dataTable.config";
import { PATH_NAMES } from "../../constants/index.constant";
import { formatAmount, formatCurrency } from "../../helpers/index.helper";
import {
  useDashboard,
  useLatestTransactions,
  useSession,
} from "../../hooks/auth/auth.hook";
import API_SERVICE from "../../services/services";
import { graphConfig } from "../../utils/chart";
import { metricInterface, sessionInterface } from "../../utils/interfaces";
import dayjs from "dayjs";

export const AccountBalance = ({ start_date, end_date }: { start_date?: string; end_date?: string; }) => {
  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const { data } = useSession();
  const { fetching } = useDashboard({ start_date, end_date });
  const merchant_data: sessionInterface = data?.data?.data;
  const toggleVisibility = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mb-4">
      <div className="text-center">
        <h6 className="card-subtitle text-center subtitle-box py-1 px-2">
          Account Balance&emsp;
          {!eyeOpen ? (
            <i
              id="changePassIcon"
              className="bi-eye"
              onClick={() => {
                toggleVisibility();
                setEyeOpen(!eyeOpen);
              }}
            />
          ) : (
            <i
              className="bi bi-eye-slash"
              onClick={() => {
                toggleVisibility();
                setEyeOpen(!eyeOpen);
              }}
            />
          )}
        </h6>
        {fetching ? (
          <Skeleton height={20} width={200} />
        ) : (
          <>
            {!eyeOpen ? (
              <span className="" style={{ color: "#000", fontSize: "12px" }}>
                **************
              </span>
            ) : (
              <h2 className="card-title text-center py-2">
                {formatAmount(merchant_data?.balance?.balance)}
              </h2>
            )}
          </>
        )}
      </div>
    </div>
  );
};

function Home() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data } = useSession();
  const [size, setSize] = useState([0, 0]);
  const [filterBy, setFilterBy] = useState<{
    label: string;
    start_date: string;
    end_date: string;
}
>({
    label: "",
    start_date: "",
    end_date: "",
  });

  const start_date = filterBy?.start_date;
  const end_date = filterBy?.end_date;

  const { dashboard_data, fetching } = useDashboard({ start_date, end_date });
  const { latest_txn, isFetching } = useLatestTransactions();
  const merchant_data: sessionInterface = data?.data?.data;
  const metrics: metricInterface = dashboard_data?.data?.data;
  const latest_txn_data = latest_txn?.data?.data?.items;
  const columns: any = React.useMemo(
    () => [
      ...latest_txn_column(),
      // {
      //   Header: "Action",
      //   columnId: 8,
      //   accessor: "actions",
      //   Cell: ({ row }: any): JSX.Element => {
      //     const id = row?.original?.id;

      //     return (
      //       <div className="btn-group" role="group">
      //         <button
      //           className="btn btn-white btn-sm"
      //           onClick={() =>
      //             navigate(`${PATH_NAMES.transactions}/details/${id}`)
      //           }
      //         >
      //           <i className="bi-eye"></i> View
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  const graphData: any = metrics?.graph;

  const volumeCollected = metrics?.volumeCollected;

  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());

    queryClient.fetchQuery(["dashboard-report"], () =>
      API_SERVICE._getDashboardReports({ start_date, end_date })
    );

    queryClient.fetchQuery(["latest-txn"], () =>
      API_SERVICE._getLatestTransactions()
    );
  }, [queryClient]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const activeDomain = merchant_data?.state?.active_domain;

  return (
    <>
      <PageHeader merchant_data={merchant_data} isLoading={fetching} {...{filterBy, setFilterBy}} />

      {size?.[0] <= 998 && (
        <div className="col-lg-3 mx-auto mb-4">
          <AccountBalance start_date={start_date} end_date={end_date} />

          <div className="d-flex flex-row align-items-center justify-content-center">
            {/* return a pop up modal */}
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#welcomeMessageModal"
              className="btn btn-primary btn-sm"
              id="view-account-tour"
            >
              View account
            </button>
            {activeDomain === "test" && (
              <>
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  className="btn btn-success btn-link"
                  id="fund-account-tour"
                  to={"https://demobank.poolerapp.com/"}
                  target="_blank"
                  style={{ color: "#169E00" }}
                >
                  Fund account
                </Link>
              </>
            )}
          </div>
        </div>
      )}

      {size?.[0] <= 998 ? null : (
        <div className="card card-body mb-3 mb-lg-5">
          <div className="row col-lg-divider gx-lg-6">
            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Value collected</h6>
                  {fetching ? (
                    <Skeleton height={20} width={200} />
                  ) : (
                    <h3 className="card-title">
                      {formatAmount(metrics?.valueCollected)}
                    </h3>
                  )}
                </div>

                <span className="ms-3">
                  <i className="bi bi-cash-stack"></i>
                </span>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Volume collected</h6>
                  {fetching ? (
                    <Skeleton height={20} width={200} />
                  ) : (
                    <h3 className="card-title">
                      {volumeCollected === 0
                        ? volumeCollected
                        : formatCurrency(volumeCollected?.toString())}
                    </h3>
                  )}
                </div>

                <span className="ms-3">
                  <i className="bi bi-save2-fill"></i>
                </span>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Amount Transferred</h6>
                  {fetching ? (
                    <Skeleton height={20} width={200} />
                  ) : (
                    <h3 className="card-title">
                      {formatAmount(metrics?.transffered)}
                    </h3>
                  )}
                </div>

                <span className="ms-3">
                  <i className="bi bi-arrow-up-right-square-fill"></i>
                </span>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Account Balance</h6>
                  {fetching ? (
                    <Skeleton height={20} width={200} />
                  ) : (
                    <h3 className="card-title">
                      {/* {merchant_data?.balance?.balance === "NaN"
                      ? "---"
                      : `${formatAmount(merchant_data?.balance?.balance)}`} */}
                      {formatAmount(merchant_data?.balance?.balance)}
                    </h3>
                  )}
                </div>

                <span className="ms-3">
                  <i className="bi bi-wallet-fill"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {size?.[0] <= 998 ? (
        <div className="card card-body mb-3 mb-lg-5">
          <div className="row col-lg-divider gx-lg-6">
            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Volume Collected {(filterBy?.label === "Today" || filterBy?.label === "") && "Today"}</h6>
                  {fetching ? (
                    <Skeleton height={20} width={200} />
                  ) : (
                    <h3 className="card-title">{metrics?.collecToday}</h3>
                  )}
                </div>

                <span className="ms-3" hidden>
                  <i className="bi bi-arrow-up-right-square-fill"></i>
                </span>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Volume Transferred {(filterBy?.label === "Today" || filterBy?.label === "") && "Today"}</h6>
                  {fetching ? (
                    <Skeleton height={20} width={200} />
                  ) : (
                    <h3 className="card-title">{metrics?.transferToday}</h3>
                  )}
                </div>

                <span className="ms-3" hidden>
                  <i className="bi bi-wallet-fill"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* TO BE LOOKED INTO */}
      <div className={`card mb-3 mb-lg-5 d-none d-lg-block d-xl-block`}>
        <div className="card-header card-header-content-sm-between">
          <h4 className="card-header-title mb-2 mb-sm-0">Activity Metrics</h4>
        </div>

        <div className="card-body">
          <div className="row col-lg-divider">
            <div className="col-lg-9 mb-5 mb-lg-0">
              <div className="chartjs-custom mb-4">
                <Chart
                  options={graphConfig(graphData).options}
                  series={graphConfig(graphData).series}
                  type="area"
                  width={"100%"}
                  height={"320"}
                />
              </div>

              {/* <div className="row justify-content-center">
                <div className="col-auto">
                  <span className="legend-indicator"></span> Collections
                </div>

                <div className="col-auto">
                  <span className="legend-indicator bg-primary"></span>{" "}
                  Transfers
                </div>
              </div> */}
            </div>

            <div className="col-lg-3">
              <div className="row">
                <div className="col-sm-6 col-lg-12">
                  <div
                    className="d-flex justify-content-center flex-column"
                    style={{ minHeight: "9rem" }}
                  >
                    <h6 className="card-subtitle">Volume Collected {(filterBy?.label === "Today" || filterBy?.label === "") && "Today"}</h6>

                    {fetching ? (
                      <Skeleton height={20} width={200} />
                    ) : (
                      <span className="d-block display-4 text-dark mb-1 me-3">
                        {metrics?.collecToday}
                      </span>
                    )}

                    <span className="d-block text-success">
                      {/* <i className="bi-graph-up me-1"></i> $2,401.02 (3.7%) */}
                    </span>
                  </div>

                  <hr className="d-none d-lg-block my-0" />
                </div>

                <div className="col-sm-6 col-lg-12">
                  <div
                    className="d-flex justify-content-center flex-column"
                    style={{ minHeight: "9rem" }}
                  >
                    <h6 className="card-subtitle">Volume Transferred {(filterBy?.label === "Today" || filterBy?.label === "") && "Today"}</h6>
                    {fetching ? (
                      <Skeleton height={20} width={200} />
                    ) : (
                      <span className="d-block display-4 text-dark mb-1 me-3">
                        {metrics?.transferToday}
                      </span>
                    )}

                    <span className="d-block text-danger">
                      {/* <i className="bi-graph-down me-1"></i> +3,301 (1.2%) */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-3 mb-lg-5">
          <div className="card h-100" hidden={size?.[0] <= 998 ? true : false}>
            <div
              className={`card-header card-header-content-between d-none d-lg-flex d-xl-flex d-flex`}
            >
              <h4 className="card-header-title">Recent transactions</h4>
              <Link
                className="btn btn-ghost-secondary btn-sm"
                to={`${PATH_NAMES.client_home}/${PATH_NAMES.transactions}`}
              >
                View all
              </Link>
            </div>

            <div
              className={`card-body-height d-none d-lg-block d-xl-block ${
                size?.[0] === 998 ? "d-none" : ""
              }`}
            >
              <Table
                columns={columns}
                data={latest_txn_data !== undefined ? latest_txn_data : []}
                isFetching={isFetching}
                navigateTo={`${PATH_NAMES.transactions}/details/`}
              />
            </div>
          </div>

          <div className="card h-100" hidden={size?.[0] <= 998 ? false : true}>
            <ul className="list-group list-group-numberedd">
              <li className="list-group-item d-flex align-items-center justify-content-between">
                <h4 className="card-header-title">Recent transactions</h4>
                <Link
                  className="btn btn-ghost-secondary btn-sm text-primary"
                  to={`${PATH_NAMES.client_home}/${PATH_NAMES.transactions}`}
                  style={{ color: "#2C1DFF !important", cursor: "pointer" }}
                >
                  View all
                </Link>
              </li>
              {isFetching ? (
                <li className="list-group-item">
                  {[1, 2, 3, 4, 5, 6].map(() => {
                    return <Skeleton height={30} />;
                  })}
                </li>
              ) : (
                <>
                  {latest_txn_data?.map((txn_data: any) => {
                    return (
                      <>
                        <li
                          className="list-group-item d-flex justify-content-between align-items-start"
                          onClick={() =>
                            navigate(
                              `${PATH_NAMES.transactions}/details/${txn_data.id}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {txn_data?.transaction_type === "transfer" ||
                          txn_data?.transaction_type === "payout" ? (
                            <i className="bi bi-arrow-up-right-circle text-danger"></i>
                          ) : null}

                          {txn_data?.transaction_type === "collection" && (
                            <i
                              className="bi bi-arrow-up-left-circle text-success"
                              style={{ rotate: "270deg" }}
                            ></i>
                          )}

                          {txn_data?.transaction_type === "collection" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.sender_details?.account_number} -{" "}
                                {txn_data?.sender_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "transfer" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number} -{" "}
                                {txn_data?.reciever_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "intra-transfer" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number} -{" "}
                                {txn_data?.reciever_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          {txn_data?.transaction_type === "payout" && (
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-semibold text-truncate"
                                style={{ maxWidth: "150px" }}
                              >
                                {txn_data?.reciever_details?.account_number} -{" "}
                                {txn_data?.reciever_details?.name}
                              </div>
                              {txn_data?.created_at}
                            </div>
                          )}
                          <span className="">
                            {formatAmount(txn_data?.amount)}
                          </span>
                        </li>
                      </>
                    );
                  })}
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
