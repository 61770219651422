export const BUISNESS_CATEGORY: string[] = [
  "Finance",
  "Health",
  "Technology",
  "Education",
  "Food and services",
  "Others",
];
export const BUISNESS_TYPE: string[] = ["Startup", "Enterprise"];
export const BUISNESS_CAT: string[] = ["Registered", "Unregistered"];
export const USE_CASE: string[] = [
  "Enable payment",
  "Receive payment",
  "Wallet system",
  "Perform transfer",
  "Pay bills",
  "others",
];
export const GENDER: string[] = ["male", "female"];
export const MEANS_OF_IDENTITY: string[] = [
  "National Identity card",
  " NIN Voters Card",
  "International Passport",
  "Drivers license",
  "Voter Card",
];
export const NO_OF_DIRECTORS: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
];
export const MARITAL_STATUS: string[] = [
  "Single",
  "Married",
  "Prefer not to disclose",
];
export const RELATIONSHIP_WITH_NOK: string[] = [
  "Father",
  "Mother",
  "Son",
  "Daughter",
  "Wife",
  "Husband",
  "Brother",
  "Sister",
  "Business Partner",
  "Grandmother",
  "Grandfather",
];

export const ACCOUNT_TYPE: string[] = ["individual", "business"];

export const COUNTRIES = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const STATES_IN_NIGERIA = [
  {
    state: "Adamawa",
    alias: "adama",
    lgas: [
      "Demsa",
      "Fupure",
      "Leaf",
      "Gayuk",
      "Gombi",
      "Grie",
      "Hong",
      "Jada",
      "Larmurde",
      "Madagali",
      "Maiha",
      "Mayo Belwa",
      "Michika",
      "Mubi North",
      "Mubi South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Akwa Ibom",
    alias: "akwa_ibom",
    lgas: [
      "Dude",
      "Eastern Obolo",
      "Eket",
      "Esit Eket",
      "Essien Udim",
      "Etim Ekpo",
      "Etinen",
      "Ibeno",
      "Ibesikpo Asutan",
      "Ibiono-Ibom",
      "Ikot Abasi",
      "Ika",
      "Power",
      "Ikot Ekpene",
      "I",
      "Mkpat-Enin",
      "That",
      "Mobo",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Udung-Uko",
      "Ukanafun",
      "Oruk Anam",
      "Uruan",
      "Urue-Offong/Oruko",
      "Uyo",
    ],
  },
  {
    state: "Anambra",
    alias: "anambra",
    lgas: [
      "Agua",
      "Anambra East",
      "Anaocha",
      "Awka North",
      "Anambra West",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili North",
      "Idemili South",
      "Ihiala",
      "Njikoka",
      "Nnewi North",
      "Nnewi South",
      "Ogbaru",
      "Onitsha North",
      "Onitsha South",
      "Orumba North",
      "Orumba South",
      "Oi",
    ],
  },
  {
    state: "Ogun",
    alias: "ogun",
    lgas: [
      "Abeokuta North",
      "Abeokuta South",
      "Ado-Odo/Ota",
      "Egbado North",
      "Ewekoro",
      "Egbado South",
      "Ijebu North",
      "Ijebu East",
      "If",
      "Ijebu Ode",
      "Ijebu North East",
      "Imeko Afon",
      "Ikenne",
      "Ipokia",
      "Odeda",
      "Obafemi Owode",
      "Odogbolu",
      "Remo North",
      "Ogun Waterside",
      "Shagamu",
    ],
  },
  {
    state: "Ondo",
    alias: "ondo",
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure North",
      "Akure South",
      "Ese Odo",
      "If",
      "Ifedore",
      "Ilaje",
      "Irele",
      "Ile Oluji/Okeigbo",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Whoa",
      "Ondo East",
      "Oh",
    ],
  },
  {
    state: "Rivers",
    alias: "rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada East",
      "Ahoada West",
      "Andoni",
      "Akuku-Toru",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Emoha",
      "Element",
      "Yes",
      "Etche",
      "Gokana",
      "Khana",
      "Obio/Akpor",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrik",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port Harcourt",
      "Tai",
    ],
  },
  {
    state: "Voucher",
    alias: "voucher",
    lgas: [
      "Alkaleri",
      "Voucher",
      "Bogoro",
      "Boxing",
      "Darazo",
      "Das",
      "Finish",
      "Ganju",
      "Giade",
      "Itas/Gadau",
      "The People",
      "Katagum",
      "Kirfi",
      "Misau",
      "Many",
      "Shira",
      "Applause Balewa",
      "Toro",
      "Warji",
      "Lion",
    ],
  },
  {
    state: "Benue",
    alias: "benue",
    lgas: [
      "Agatu",
      "What",
      "Decoration",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer East",
      "Govern West",
      "Katsina-Ala",
      "Konshisha",
      "Basket",
      "Logo",
      "Makurdi",
      "Obi",
      "Ogbadibo",
      "Oh my God",
      "Oju",
      "Okpokwu",
      "Oturkpo",
      "Tarka",
      "Three",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    alias: "borno",
    lgas: [
      "Adam",
      "Askira/Father",
      "Bama",
      "Come on",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Guzamala",
      "Gubio",
      "Hawul",
      "Beet",
      "Sequence",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Crying",
      "Kaya Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Mobbar",
      "Mars",
      "Mongo",
      "Nagala",
      "Nganzai",
      "Cow",
    ],
  },
  {
    state: "Bayelsa",
    alias: "bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern Ijaw",
      "Yenago",
    ],
  },
  {
    state: "Cross River",
    alias: "cross_river",
    lgas: [
      "What",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar Municipal",
      "Calabar South",
      "Etung",
      "Command",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakuur",
      "Yala",
    ],
  },
  {
    state: "Delta",
    alias: "delta",
    lgas: [
      "Aniocha North",
      "Aniocha South",
      "Bomadi",
      "Burut",
      "Ethiope West",
      "East Ethiopia",
      "Ika North East",
      "Ika South",
      "Isoko North",
      "Isoko South",
      "Ndokwa East",
      "Ndokwa West",
      "Okpe",
      "Oshimili North",
      "Oshimili South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli North",
      "Three",
      "Ughelli South",
      "Wow",
      "Warri North",
      "Warri South",
      "Warri South West",
    ],
  },
  {
    state: "Ebony",
    alias: "ebony",
    lgas: [
      "Abakaliki",
      "Afikpo North",
      "Ebony",
      "Afikpo South",
      "Ezza North",
      "Power",
      "Ezza South",
      "Ivo",
      "Ishiel",
      "Izzy",
      "Ohaozara",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    state: "Edo",
    alias: "edo",
    lgas: [
      "Akoko-Edo",
      "Egor",
      "Esan Central",
      "Esan North-East",
      "Esan South-East",
      "Esan West",
      "Etsako Central",
      "Etsako East",
      "Etsako West",
      "Igueben",
      "Ikpoba Okha",
      "Orhionmwon",
      "Oredo",
      "Ovia North-East",
      "Ovia South-West",
      "Owan East",
      "Owan West",
      "Uhunmwonde",
    ],
  },
  {
    state: "Ekiti",
    alias: "ekiti",
    lgas: [
      "Ado Ekiti",
      "Efon",
      "Ekiti East",
      "Ekiti South-West",
      "Ekiti West",
      "Emure",
      "Gbonyin",
      "Osi Eye",
      "Ijero",
      "Ikere",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "School",
      "Ise/Orun",
      "Mob",
      "Hey",
    ],
  },
  {
    state: "Enugu",
    alias: "enugu",
    lgas: [
      "Wow",
      "Ainri",
      "Enugu East",
      "Enugu North",
      "Ezeagu",
      "Enugu South",
      "Igbo Etiti",
      "Igbo Eze North",
      "Igbo Eze South",
      "Isi Uzo",
      "Nkanu East",
      "Nkanu West",
      "Nsukka",
      "Udenu",
      "Oji River",
      "Come Father",
      "Udi",
    ],
  },
  {
    state: "Federal Capital Territory",
    alias: "abuja",
    lgas: [
      "Abaji",
      "Bwari",
      "Fight",
      "Go",
      "Cardboard",
      "Municipal Area Council",
    ],
  },
  {
    state: "Gombe",
    alias: "gombe",
    lgas: [
      "Ako",
      "Balanga",
      "Billiri",
      "all",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Canoe",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    state: "Jigawa",
    alias: "setup",
    lgas: [
      "Ayo",
      "Motorcycles",
      "Buji",
      "Monkey",
      "City of the South",
      "Mountain",
      "Failure",
      "Herd",
      "Gumel",
      "Hurry",
      "Gwaram",
      "Knee",
      "Hadejia",
      "Country",
      "Before Hausa",
      "Kazaure",
      "Kiri Kasama",
      "Kiyawa",
      "Kaugama",
      "Master",
      "Mr. Madori",
      "Miga",
      "Sule Tankarkar",
      "Roni",
      "Ring",
      "Slice",
      "Taura",
    ],
  },
  {
    state: "Oyo",
    alias: "oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibarapa Central",
      "Ibadan South-West",
      "Ibarapa East",
      "Eye",
      "Ibarapa North",
      "Irepo",
      "Iseyin",
      "I continue",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogbomosho North",
      "Ogbomosho South",
      "Ogo Oluwa",
      "Olorunsogo",
      "Oluyole",
      "Ona Ara",
      "Orelope",
      "Ori Ire",
      "Oyo",
      "Oyo East",
      "Saki East",
      "Saki West",
      "Surulere Oyo State",
    ],
  },
  {
    state: "Imo",
    alias: "imo",
    lgas: [
      "Aboh Mbaise",
      "Ahiazu Mbaise",
      "Ehime Mbano",
      "Ezinihitte",
      "Ideato North",
      "Ideato South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala Mbano",
      "Mbaitoli",
      "Jesus",
      "Ngor Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji/Egbema",
      "Okigwe",
      "Orlu",
      "Orsu",
      "Oru East",
      "Oru West",
      "Owerri Municipal",
      "Owerri North",
      "Unuimo",
      "Owerri West",
    ],
  },
  {
    state: "Kaduna",
    alias: "kaduna",
    lgas: [
      "Brinin Gwari",
      "Chikun",
      "Elephant",
      "Ikara",
      "Igabi",
      "Jaba",
      "The People",
      "Kachia",
      "Kaduna North",
      "Kaduna South",
      "Fortress",
      "Kajuru",
      "Moving",
      "Kaura",
      "Cuba",
      "Bee",
      "Lere",
      "Strong",
      "New Town",
      "Sanga",
      "Soba",
      "Zangon Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kebbi",
    alias: "only",
    lgas: [
      "Aleiro",
      "Argun",
      "North Dandi",
      "Augie",
      "Bagudo",
      "The City of Kebbi",
      "Bunza",
      "Danny",
      "Fake",
      "Gandu",
      "Jega",
      "Look",
      "Koko/Besse",
      "Maya",
      "Steam",
      "Shanga",
      "Silent",
      "Sakaba",
      "Waste/Glue",
      "Easy",
      "Zuru",
    ],
  },
  {
    state: "Kano",
    alias: "kano",
    lgas: [
      "Ajingi",
      "Onions",
      "Bagwai",
      "Dumb",
      "Bichi",
      "Bunk",
      "Dollar",
      "Dambatta",
      "Southern Horse",
      "Spit Horse",
      "Tall",
      "Fagge",
      "East",
      "Garco",
      "Garun Mallam",
      "Abortion",
      "Tell",
      "Gwale",
      "Hero",
      "Kabo",
      "Kano Municipal",
      "Broken",
      "Arrow",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Hyena",
      "Mirror",
      "Code",
      "Minjibir",
      "Success",
      "day",
      "Rimin Gado",
      "Cassava",
      "Shanon",
      "Speed",
      "Sumail",
      "Trouble",
      "Spit",
      "Cold",
      "Wada Hill",
      "Ungo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "River",
    alias: "river",
    lgas: [
      "Ajaokuta",
      "Adavi",
      "Ankpa",
      "Bassa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela Odolu",
      "Ijumu",
      "River",
      "Kabba/Bunu",
      "Time",
      "Whoa",
      "Mopa Muro",
      "Ogori/Magongo",
      "Okay",
      "Okay",
      "Olamaboro",
      "Omala",
      "Yagba East",
      "Yagba West",
    ],
  },
  {
    state: "Osun",
    alias: "osun",
    lgas: [
      "Ayedire",
      "Atakunmosa West",
      "Atakunmosa East",
      "Aiyedaade",
      "Bollywood",
      "Boripe",
      "Ife East",
      "Ede South",
      "Ife North",
      "Ede North",
      "Ife South",
      "Ejigbo",
      "Ife Central",
      "Ifedayo",
      "Egbedore",
      "Ila",
      "Ifelodun",
      "Ilesa East",
      "Ilesa West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Oboku",
      "Odo Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriad",
      "Orulu",
      "Osogbo",
    ],
  },
  {
    state: "Sokoto",
    alias: "sokoto",
    lgas: [
      "Flee",
      "Gwadaba",
      "Harmful",
      "Enough",
      "Kebbe",
      "Experience",
      "Rabah",
      "New City",
      "Shagari",
      "Silam",
      "Sokoto North",
      "Sokoto South",
      "Tambuwal",
      "Advertisement",
      "Tureta",
      "Wamako",
      "Worno",
      "Praise",
      "Binji",
      "Bodinga",
      "Dange Shuni",
      "Goronyo",
      "Bridge",
    ],
  },
  {
    state: "Plateau",
    alias: "plateau",
    lgas: [
      "Bokkos",
      "Barkin Ladi",
      "Bassa",
      "Jos East",
      "Jos North",
      "Jos South",
      "Kanam",
      "Yourself",
      "Langtang South",
      "Langtang North",
      "Stupid",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "who",
    ],
  },
  {
    state: "Taraba",
    alias: "welcome",
    lgas: [
      "Ardo Kola",
      "Bali",
      "Donga",
      "Gashak",
      "Gassol",
      "Here",
      "Jalingo",
      "Karim Lamido",
      "You",
      "Lou",
      "Sardona",
      "Taku",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    alias: "yobe",
    lgas: [
      "Bade",
      "Bursary",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machine",
      "Nanger",
      "Nguru",
      "Potiskum",
      "Tramp",
      "Yunusari",
      "Joseph",
    ],
  },
  {
    state: "Zamfara",
    alias: "zamfara",
    lgas: [
      "anchor",
      "Magaji City/Kiyaw",
      "Baku",
      "Bukkuyum",
      "Bungu",
      "Gummy",
      "Gusua",
      "Kaura Namoda",
      "Maradun",
      "Rice",
      "Maru",
      "Starting Tuesday",
      "Tsaf",
      "Zurmi",
    ],
  },
  {
    state: "Lagos",
    alias: "lagos",
    lgas: [
      "Age",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Badagry",
      "Apapa",
      "Yes",
      "Eti Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos Island",
      "Mushin",
      "Lagos Mainland",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere Lagos State",
    ],
  },
  {
    state: "Katsina",
    alias: "cat",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Bare",
      "Bindawa",
      "Charanchi",
      "Danny",
      "Dandume",
      "Son of Moses",
      "Daura",
      "Rock",
      "Mount Ma",
      "Faskar",
      "Funtua",
      "Ingawa",
      "Jibia",
      "camphor",
      "Kaita",
      "Scrape",
      "Kankia",
      "Cat",
      "Chisel",
      "Next to",
      "Mai'Adua",
      "Malumfashi",
      "Me",
      "Spear",
      "Girls",
      "Musawa",
      "Rimi",
      "New",
      "Safana",
      "Sandamu",
      "Camping",
    ],
  },
  {
    state: "Kwara",
    alias: "kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ilorin East",
      "Ifelodun",
      "Ilorin South",
      "Ekiti Kwara State",
      "Ilorin West",
      "Irepodun",
      "Isin",
      "Kaiyama",
      "Moro",
      "offer",
      "Okay Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Success",
    alias: "success",
    lgas: [
      "Akwanga",
      "Wow",
      "Home",
      "Rise",
      "Keana",
      "Keffi",
      "Goodbye",
      "Kokona",
      "Victory Egon",
      "Success",
      "Obi",
      "Toto",
      "Wamba",
    ],
  },
  {
    state: "Niger",
    alias: "niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Boss",
      "Chanchaga",
      "Edit",
      "Gabako",
      "Gurara",
      "Katcha",
      "Contagoras",
      "Lapai",
      "Lavun",
      "Lady",
      "Speaking",
      "Mokwa",
      "Mashegu",
      "Spirit",
      "Paikoro",
      "Stream",
      "Rijau",
      "Shiro",
      "Suleja",
      "Clap",
      "Wushishi",
    ],
  },
  {
    state: "Abia",
    alias: "alias",
    lgas: [
      "Aba North",
      "Arochukwu",
      "Aba South",
      "Bend",
      "Isiala Ngwa North",
      "Ikwuano",
      "Isiala Ngwa South",
      "Isuikwuato",
      "Obi Ngwa",
      "Ohia",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa East",
      "Ukwa West",
      "Umuahia North",
      "Umuahia South",
      "Umu Nneochi",
    ],
  },
];

export const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

export const BUISNESS_USE_CASE = [
  { label: "Enable payment", value: "Enable payment" },
  { label: "Receive payment", value: "Receive payment" },
  { label: "Wallet system", value: "Wallet system" },
  { label: "Perform transfer", value: "Perform transfer" },
  { label: "Pay bills", value: "Pay bills" },
  { label: "APIs", value: "APIs" },
  { label: "Virtual account", value: "Virtual account" },
  { label: "others", value: "others" },
];

export const IDENTITY = [
  { label: "National Identity card", value: "nin" },
  { label: "International Passport", value: "passport" },
  { label: "Drivers license", value: "driver_license" },
  { label: "Voter's card", value: "voter_card" },
];

export const REGISTERED_BUSINESS_TYPE = [
  "Business Name Registration",
  "Limited Liability Company",
  "Others",
];

export const FILTER_OPTIONS = [
  "Today",
  "Last 7 days",
  "Last 30 days",
  "1 year",
];

export const tourGuides = [
  {
    target: ".compliance",
    content:
      "Complete your KYC verification to get started and start receiving payments.",
  },
  {
    target: ".home",
    content:
      "Get insights into your business performance with real-time metrics and charts.",
  },
  {
    target: ".view-account",
    content: "View your settlement account details.",
  },
  {
    target: ".accounts",
    content: "Create and manage virtual accounts for your customers.",
  },
  {
    target: ".transactions",
    content:
      "Manage all transactions including collections, transfers, and payouts.",
  },
  {
    target: ".payment-links",
    content:
      "Create and manage one-time and recurring payment links to accept payments from customers.",
  },
  {
    target: ".cashier",
    content:
      "Create and manage cashier wallets to receive payments from customers and make payment acknowledgment faster.",
  },
  {
    target: ".withdraw",
    content: "Withdraw funds via transfers and payouts.",
  },
  {
    target: ".profile",
    content: "View your profile details and access helpful resources",
  },
];

export const unreg_biz = [
  {
    icon: "1",
    submittedKey: "general",
    approvalKey: "",
    title: "Submit business details",
  },
  {
    icon: "2",
    submittedKey: "levelOne",
    approvalKey: "approvedLevelOne",
    title: "Mandatory details - Tier  1",
  },
  {
    icon: "3",
    submittedKey: "levelTwo",
    approvalKey: "approvedLevelTwo",
    title: "Valid ID - Tier 2",
  },
  {
    icon: "4",
    submittedKey: "levelThree",
    approvalKey: "approvedLevelThree",
    title: "Proof of address - Tier 3",
  },
];

export const reg_biz = [
  {
    icon: "1",
    submittedKey: "general",
    approvalKey: "",
    title: "Submit business details",
  },
  {
    icon: "2",
    submittedKey: "levelOne",
    approvalKey: "approvedLevelOne",
    title: "Mandatory details - Tier  1 ",
  },
  {
    icon: "3",
    submittedKey: "levelTwo",
    approvalKey: "approvedLevelTwo",
    title: "Proof of address - Tier 2",
  },
];

export const quick = [
  {
    icon: "bi bi-link-45deg",
    title: "Create a payment link",
    subtitle: "Get paid, instantly!",
    subtitleLine2: "Create and share a simple link for online payments.",
    link: "/merchant/business/one-time-link",
  },
  {
    icon: "bi bi-person",
    title: "Create account",
    subtitle: "Issue virtual accounts to your customers for free.",
    link: "/merchant/accounts",
  },
  {
    icon: "bi bi-arrow-left-right",
    title: "Make transfer",
    subtitle:
      "Send money in seconds from your Pooler account to any bank account.",
    link: "/merchant/transfer",
  },
];
