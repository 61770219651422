/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moengage from "@moengage/web-sdk";
import React, {
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useQueryClient } from "react-query";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PoolerLogo from "../assets/svg/logos/poolerlogo2.svg";
import { ReactComponent as TopupIcon } from "../assets/topup-dark.svg";
// import PoolerLogoSmall from "../assets/svg/logos/poolersmall.svg";
import { PATH_NAMES } from "../constants/index.constant";
import { useLogout, useSession } from "../hooks/auth/auth.hook";
import { useStateManager } from "../hooks/state-manager/state.hook";
import API_SERVICE from "../services/services";
import { sessionInterface } from "../utils/interfaces";
import SpinnerComponent from "./spinner/Spinner";
// OFF CANVAS
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Joyride, {
  CallBackProps,
  STATUS,
  StoreHelpers,
  TooltipRenderProps,
} from "react-joyride";
import { ReactComponent as TransferIcon } from "../assets/transfer.svg";
import { useClickContext } from "../context/tourContext";
import { encryptKyc, mode, signatureHarsh } from "../helpers/index.helper";
import useUserDeviceInfo from "../hooks/device.hook";
import { useCheckBuisnessRequirements } from "../hooks/kyc/kyc.hook";
import { BUISNESS_TYPE } from "../utils/enums";
import HeadwayWidget from "./headway/headway";
import HeadwayWidgetClass from "./headway/headwayClass";
import ButtonMailto from "./support/support";

export const CustomToggle = forwardRef<
  HTMLDivElement,
  {
    onClick: MouseEventHandler<HTMLDivElement>;
    children: ReactNode;
  }
>(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick}>
    {children}
  </div>
));

function Header() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { onClick } = useClickContext();
  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const closeButtonRef: any = useRef<HTMLDivElement | null>(null);
  const toggleRef: any = useRef<HTMLDivElement | null>(null);
  const helpers = useRef<StoreHelpers>();

  const { data, refetch } = useSession();
  const { api_env } = useStateManager();
  const merchant_data: sessionInterface = data?.data?.data;
  const isAdmin = window.location.pathname.includes("/admin");
  const isCashier = window.location.pathname.includes("/cashier/dashboard");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean>(false);
  const [size, setSize] = useState([0, 0]);

  const [{ run, steps, complete }, setState] = useState<any>({
    complete: false,
    run: true,
    steps: [
      {
        content:
          "Complete your KYC verification to get started and start receiving payments.",
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        title: "Compliance",
        target: "body",
      },
      {
        content:
          "Get insights into your business performance with real-time metrics and charts.",
        placement: "bottom",
        title: "Home",
        target: "#home-tour",
      },
      {
        target: "#view-account-tour",
        content: "View your settlement account details.",
        title: "Account Details",
        placement: "bottom",
      },
      {
        target: "#accounts-tour",
        content: "Create and manage virtual accounts for your customers.",
        title: "Account",
        placement: "bottom",
      },
      {
        target: "#transactions-tour",
        content:
          "Manage all transactions including collections, transfers, and payouts.",
        title: "Transactions",
        placement: "bottom",
      },
      {
        target: "#payment-links-tour",
        content:
          "Create and manage one-time and recurring payment links to accept payments from customers.",
        title: "Payment links",
        placement: "bottom",
      },
      {
        target: "#cashier-tour",
        content:
          "Create and manage cashier wallets to receive payments from customers and make payment acknowledgment faster.",
        title: "Cashier",
        placement: "bottom",
      },
      {
        target: "#withdraw-tour",
        content: "Withdraw funds via transfers and payouts.",
        title: "Withdraw",
        placement: "bottom",
      },
      {
        target: "#profile-tour",
        content: "View your profile details and access helpful resources",
        title: "Profile",
        placement: "bottom",
      },
    ],
  });

  const setHelpers = (storeHelpers: StoreHelpers) => {
    helpers.current = storeHelpers;
  };

  const handleClickRestart = () => {
    const { reset } = helpers.current!;
    setState({ complete: false, run: true });
    reset(true);
  };

  function Tooltip({
    backProps,
    continuous,
    index,
    isLastStep,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
  }: TooltipRenderProps) {
    return (
      <div {...tooltipProps}>
        <div className="card" style={{ maxWidth: "20rem" }}>
          <div className="card-body">
            {step.title && <h3 className="card-title">{step.title}</h3>}
            {step.content && <p className="card-text">{step.content}</p>}
          </div>

          {!isLastStep ? (
            <div className="card-footer">
              <div className="d-flex flex-row align-items-center justify-content-between">
                {!isLastStep && (
                  <button
                    {...skipProps}
                    type="button"
                    className="btn btn-ghost-secondary btn-sm"
                  >
                    Skip
                  </button>
                )}

                <div>
                  {index > 0 && (
                    <button
                      {...backProps}
                      type="button"
                      className="btn btn-secondary btn-sm"
                    >
                      Back
                    </button>
                  )}
                  &nbsp;&nbsp;
                  <button
                    {...primaryProps}
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    {continuous ? "Next" : "Close"}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="card-footer">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <button
                  {...skipProps}
                  type="button"
                  className="btn btn-ghost-primary btn-sm"
                >
                  Back
                </button>

                <button
                  {...primaryProps}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  {"Close"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const options: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setState({ complete: true, run: false });
    }

    // logGroup(type, data);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const business_name = merchant_data?.business_name?.split(" ");
  const firstLetter = business_name ? business_name[0]?.charAt(0) : "";
  const lastLetter = business_name ? business_name[1]?.charAt(0) : "";

  const is_live = merchant_data?.state?.is_live;
  const active_domain = merchant_data?.state?.active_domain;
  const isKycComplete = merchant_data?.isKycCompleted;

  useEffect(() => {
    queryClient.fetchQuery(["merchant"], () => API_SERVICE._getSession());
  }, [queryClient]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleModalContent = () => {
    if (isKycComplete === true) {
      return (
        <div className="modal-body">
          <div className="row gx-2 gx-sm-3 text-center">
            <h5 className="h3">KYC Details Under Review</h5>
            <p className="w-100 text-center mx-auto py-2 fw-normal">
              You will be able to switch to your live environment after your KYC
              details are reviewed.
            </p>

            <div className="d-flex flex-row justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-ghost-primary me-2"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      );
    } else if (isKycComplete === false) {
      return (
        <div className="modal-body">
          <div className="row gx-2 gx-sm-3 text-center">
            <h5 className="h3">Complete KYC and compliance</h5>
            <p className="w-100 text-center mx-auto py-2 fw-normal">
              Complete and submit your KYC to enable you switch to your live
              environment.
            </p>
            <div className="d-flex flex-row justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-ghost-primary me-2"
                data-bs-dismiss="modal"
                ref={closeButtonRef}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary ms-1"
                onClick={() => {
                  closeButtonRef?.current?.click();
                  navigate(`${PATH_NAMES.client_home}/${PATH_NAMES.kyc}`);
                }}
              >
                Complete KYC now
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleModalHeader = () => {
    if (isKycComplete === true) {
      return (
        <div className="modal-header">
          <h5
            className="text-center mx-auto btn btn-warning"
            id="exampleModalLabel"
          >
            Test mode.
          </h5>
        </div>
      );
    } else if (isKycComplete === false) {
      return (
        <div className="modal-header">
          <h5
            className="text-center mx-auto btn btn-warning"
            id="exampleModalLabel"
          >
            You’re currently in Test mode.
          </h5>
        </div>
      );
    }
  };

  const onSwitchToLiveEnv = () => {
    window.localStorage.removeItem("domain");
    setIsLoading(true);
    api_env
      .mutateAsync()
      .then((response) => {
        const domain = response?.data?.data?.active_domain;
        window.localStorage.setItem("domain", domain);
        if (domain === "test") {
          setIsLoading(false);
          refetch();
          handleClose();
          buttonRef.current?.click();
          setIsModalOpen(false);
          toast.success("You are now in test mode");
          queryClient.refetchQueries();
        } else {
          setIsLoading(false);
          refetch();
          buttonRef.current?.click();
          setIsModalOpen(false);
          handleClose();
          toast.success("You are now in live mode");
          queryClient.refetchQueries();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsModalOpen(false);
        return e;
      })
      .finally(() => {
        buttonRef.current?.click();
        setIsModalOpen(false);
        refetch();
        setIsLoading(false);
      });
  };

  const handleLiveToggle = () => {
    if (is_live === true) {
      return (
        <li className="nav-item">
          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
              <input
                type="checkbox"
                className="form-check-input is-invalid"
                defaultChecked
                style={{ height: "18px", width: "30px" }}
                onClick={() => {
                  setIsModalOpen(true);
                  toggleRef.current?.click();
                }}
              />
              <label className="form-check-label is-invalid text-danger">
                Test
              </label>
            </div>
          </div>
        </li>
      );
    } else if (is_live === false) {
      return (
        <li className="nav-item">
          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
              <input
                type="checkbox"
                className="form-check-input is-invalid"
                data-bs-toggle={"modal"}
                data-bs-target={"#exampleModal"}
                onClick={() => toggleRef.current?.click()}
                defaultChecked
                disabled
                readOnly
                style={{ height: "18px", width: "30px" }}
              />
              <label className="form-check-label is-invalid text-danger">
                Test
              </label>
            </div>
          </div>
        </li>
      );
    }
  };

  const handleLiveToggleLg = () => {
    if (is_live === true) {
      return (
        <li className="nav-item">
          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
              <input
                type="checkbox"
                className="form-check-input is-invalid"
                data-bs-toggle={"modal"}
                data-bs-target={"#exampleModal"}
                defaultChecked
                style={{ height: "18px", width: "30px" }}
              />
              <label className="form-check-label is-invalid text-danger">
                Test
              </label>
            </div>
          </div>
        </li>
      );
    } else if (is_live === false) {
      return (
        <li className="nav-item">
          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
              <input
                type="checkbox"
                className="form-check-input is-invalid"
                data-bs-toggle={"modal"}
                data-bs-target={"#exampleModal"}
                defaultChecked
                disabled
                readOnly
                style={{ height: "18px", width: "30px" }}
              />
              <label className="form-check-label is-invalid text-danger">
                Test
              </label>
            </div>
          </div>
        </li>
      );
    }
  };

  const ModalForSwitch = () => {
    return (
      <>
        <Modal
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          centered
          size="sm"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ display: "contents" }}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gx-2 gx-sm-3 text-center">
              <h5 className="h2">Switch to Live mode</h5>
              <p className="w-100 text-center mx-auto py-2 fw-normal">
                You are about to switch to Live mode. This means that you will
                start processing real transactions and actual payments from your
                customers.
              </p>
              <div className="d-flex flex-row justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onSwitchToLiveEnv}
                >
                  Switch to Live mode{" "}
                  {isLoading ? (
                    <SpinnerComponent size="sm" />
                  ) : (
                    <i className="bi bi-arrow-right"></i>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          centered
          size="sm"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ display: "contents" }}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gx-2 gx-sm-3 text-center">
              <h5 className="h2">Switch to Test mode</h5>
              <p className="w-100 text-center mx-auto py-2 fw-normal">
                You are about to switch to test mode. This means that all
                transactions and payments carried out are not real
              </p>
              <div className="d-flex flex-row justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={onSwitchToLiveEnv}
                >
                  Switch to test mode{" "}
                  {isLoading ? (
                    <SpinnerComponent size="sm" />
                  ) : (
                    <i className="bi bi-arrow-right"></i>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const merchantId: any = localStorage.getItem("merchant_compliance_id");
  const message = merchantId && JSON.stringify({ id: merchantId });
  const x_signature = encryptKyc(message, signatureHarsh);

  const BUSINESS_TYPE: string | any =
    localStorage.getItem("business_cat") === "Registered"
      ? BUISNESS_TYPE.REGISTERED
      : BUISNESS_TYPE.UN_REGISTERED;

  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );

  const business_type = merchant_data?.business_category;
  const requirementData: any = requiredData?.data?.data;
  const levelOne: any = requirementData?.level_one;
  const levelTwo: any = requirementData?.level_two;

  const handleComplianceURL = (): string | any => {
    if (business_type === "Registered") {
      return levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/registered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`;
    } else if (business_type === "Unregistered") {
      return levelOne?.submit === true && levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : levelOne?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`
        : levelTwo?.submit === true
        ? `/merchant/${PATH_NAMES.kyc}/unregistered_business/proof_of_address`
        : `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`;
    }
  };

  const { invalidateToken } = useLogout();
  const { deviceInfo } = useUserDeviceInfo();

  const handleLogout = () => {
    invalidateToken
      .mutateAsync()
      .then()
      .catch()
      .finally(() => {
        localStorage.clear();
        moengage.track_event("LOGOUT", {
          success_status: true,
          firstName: merchant_data?.firstname,
          lastName: merchant_data?.lastname,
          email: merchant_data?.email,
          mobile: merchant_data?.mobile_number,
          userName: merchant_data?.email,
          uniqueUserId: merchant_data?.email,
          phone_number: merchant_data?.mobile_number,
          terms_agreement: merchant_data?.agreed_terms,
          address: merchant_data?.address,
          business_category: merchant_data?.business_category,
          business_type: merchant_data?.business_type,
          platform: deviceInfo?.userAgent,
        });
        window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
      });
  };

  return (
    <>
      <ModalForSwitch />
      {/* {mode === "internal.test" && (
        <Joyride
          continuous
          beaconComponent={()=>{return <div className="d-none"></div>}}
          steps={steps}
          run={run}
          getHelpers={setHelpers}
          tooltipComponent={Tooltip}
          callback={handleJoyrideCallback}
          hideCloseButton
          scrollToFirstStep
          showSkipButton
          showProgress
          spotlightClicks={false}
          spotlightPadding={0}
        />
      )} */}

      <header
        id="header"
        className="navbar navbar-expand-lg navbar-spacer-y-0 flex-lg-column"
      >
        {/* <div className="d-none d-md-none d-lg-block w-100">
          <div className="navbar-dark w-100 bg-dark">
            <div className="container">
              <div className="navbar-nav-wrap">
                <a className="navbar-brand" href="/merchant" aria-label="Front">
                  <img
                    className="navbar-brand-logo"
                    src={PoolerLogo}
                    alt="Logo"
                  />
                </a>

                <div className="navbar-nav-wrap-content-start"></div>

                <div className="navbar-nav-wrap-content-end">
                  <ul className="navbar-nav">
                    <HeadwayWidget />

                    <li className="nav-item d-none d-sm-block d-md-block d-lg-block">
                      <Form>
                        {active_domain === "test" &&
                        active_domain !== undefined ? (
                          <>{handleLiveToggleLg()}</>
                        ) : (
                          ""
                        )}

                        {active_domain === "live" &&
                        active_domain !== undefined ? (
                          <div className="d-flex justify-content-center">
                            <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
                              <input
                                type="checkbox"
                                className="form-check-input is-valid"
                                onClick={handleShow}
                                defaultChecked
                                style={{ height: "18px", width: "30px" }}
                              />
                              <label className="form-check-label text-success">
                                Live
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Form>
                    </li>

                    <li className="nav-item" id="profile-tour">
                      <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                          <span
                            className="avatar avatar-circle"
                            style={{ cursor: "pointer" }}
                          >
                            {lastLetter ? (
                              <span className="avatar-initials">
                                {firstLetter} {lastLetter}
                              </span>
                            ) : (
                              <span className="avatar-initials">
                                {firstLetter}
                              </span>
                            )}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                          >
                            <div className="d-flex align-items-center">
                              <span className="avatar avatar-primary avatar-circle">
                                {lastLetter ? (
                                  <span className="avatar-initials">
                                    {firstLetter} {lastLetter}
                                  </span>
                                ) : (
                                  <span className="avatar-initials">
                                    {firstLetter}
                                  </span>
                                )}
                              </span>
                              <div className="flex-grow-1 ms-3">
                                <h5
                                  className="mb-0 text-truncate"
                                  style={{ maxWidth: "150px" }}
                                >
                                  {merchant_data?.business_name}
                                </h5>
                              </div>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <NavLink
                              className={"link-dark"}
                              to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                            >
                              Profile &amp; account
                            </NavLink>
                          </Dropdown.Item>
                          <Dropdown.Item href={`https://docs.poolerapp.com/`}>
                            <a
                              href={`https://docs.poolerapp.com/`}
                              target="_blank"
                              rel="noreferrer"
                              className="link-dark"
                            >
                              Documentation &nbsp;
                              <i className="bi bi-arrow-up-right"></i>
                            </a>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              localStorage.clear();
                              window.location.replace(
                                `/auth/merchant${PATH_NAMES.signin}`
                              );
                            }}
                          >
                            Sign out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                    <li className="nav-item" id="profile-tour">
                      <button className="navbar-toggler" type="button">
                        {mobile === false ? (
                          <span className="navbar-toggler-default">
                            <i
                              className="bi-list"
                              onClick={() => setMobile(true)}
                            ></i>
                          </span>
                        ) : (
                          <span className="navbar-toggler-default">
                            <i
                              className="bi-x"
                              onClick={() => setMobile(false)}
                            ></i>
                          </span>
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {active_domain === "test" && (
          <div
            className="alert alert-soft-danger w-100 alert-no-radius"
            role="alert"
            style={{ borderRadius: "0px !important" }}
          >
            <i className="bi bi-exclamation-triangle-fill"></i> You are
            currently in test mode. Transactions performed here are not real.
          </div>
        )}
        {[false].map((expand: any) => (
          <Navbar
            key={expand === false ? "false" : expand}
            expand={expand}
            className="w-100 d-sm-block d-md-block d-lg-none"
            sticky="top"
          >
            <Container>
              {/* <Navbar.Brand href="/merchant">
                <img
                  className="navbar-brand-logo"
                  src={PoolerLogo}
                  alt="Logo"
                />
              </Navbar.Brand> */}
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                className="border border-0"
                ref={toggleRef}
                children={
                  <>
                    <i
                      className="bi bi-list text-dark"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </>
                }
                color="#fff"
                style={{ color: "#fff" }}
              />
              <div className="d-flex flex-row align-items-center">
                <Navbar.Text style={{ listStyleType: "none" }} hidden>
                  <Form>
                    {active_domain === "test" && active_domain !== undefined ? (
                      <>{handleLiveToggle()}</>
                    ) : (
                      ""
                    )}

                    {active_domain === "live" && active_domain !== undefined ? (
                      <div className="d-flex justify-content-center">
                        <div className="form-check form-switch form-switch-between d-flex flex-row align-items-end">
                          <input
                            type="checkbox"
                            className="form-check-input is-valid"
                            onClick={() => {
                              handleShow();
                            }}
                            defaultChecked
                            style={{ height: "18px", width: "30px" }}
                          />
                          <label className="form-check-label text-success">
                            Live
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Form>
                </Navbar.Text>
                &nbsp;
                {size?.[0] <= 1100 && <HeadwayWidgetClass />}
                &nbsp;
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    <span
                      className="ms-2"
                      style={{ fontSize: "20px", color: "#71869d" }}
                    >
                      <i className="bi bi-person-circle"></i>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mobile-position">
                    <Dropdown.Item>
                      <NavLink
                        className={"link-dark"}
                        to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                      >
                        <i className="bi-person"></i> &emsp;Profile &amp;
                        account
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink
                        className={"link-dark"}
                        to={handleComplianceURL()}
                      >
                        <i className="bi-person"></i> &emsp;Compliance
                      </NavLink>
                    </Dropdown.Item>

                    {size?.[0] > 1024 && (
                      <Dropdown.Item
                        as="button"
                        onClick={onClick}
                        hidden={false}
                      >
                        <i className="bi bi-signpost"></i>&emsp;Take a tour
                      </Dropdown.Item>
                    )}

                    {/* <span className="dropdown-header">Contact</span> */}
                    <Dropdown.Item href={`https://docs.poolerapp.com/`}>
                      <a
                        href={`https://docs.poolerapp.com/`}
                        target="_blank"
                        rel="noreferrer"
                        className="link-dark"
                      >
                        <i className="bi-journals"></i> &emsp;API Docs &nbsp;
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <ButtonMailto mailto="mailto:support@poolerapp.com" />
                    </Dropdown.Item>
                    {/* <span className="dropdown-header">deauthenticate</span> */}
                    <Dropdown.Item
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      <i className="bi bi-shield-lock"></i> &emsp;Sign out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
              >
                <Offcanvas.Header
                  closeButton={false}
                  className=""
                  style={{ height: "68px" }}
                >
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img
                      className="navbar-brand-logo"
                      // src={PoolerLogoSmall}
                      src={PoolerLogo}
                      alt="Logo"
                      style={{ height: "26px" }}
                    />
                  </Offcanvas.Title>
                  <div className="d-flex flex-row align-items-center">
                    <Navbar.Text style={{ listStyleType: "none" }}>
                      <Form>
                        {active_domain === "test" &&
                        active_domain !== undefined ? (
                          <>{handleLiveToggle()}</>
                        ) : (
                          ""
                        )}

                        {active_domain === "live" &&
                        active_domain !== undefined ? (
                          <div className="d-flex justify-content-center">
                            <div className="form-check form-switch form-switch-between">
                              <input
                                type="checkbox"
                                className="form-check-input is-valid"
                                onClick={() => {
                                  handleShow();
                                  toggleRef.current?.click();
                                }}
                                defaultChecked
                              />
                              <label className="form-check-label text-success">
                                Live
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Form>
                    </Navbar.Text>
                    <Navbar.Toggle
                      aria-controls={`offcanvasNavbar-expand-${expand}`}
                      className="border border-0"
                      ref={toggleRef}
                      children={
                        <>
                          <i
                            className="bi bi-x"
                            style={{ fontSize: "30px", color: "#fff" }}
                          ></i>
                        </>
                      }
                      color="#fff"
                      style={{ color: "#fff" }}
                      hidden
                    />
                  </div>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <ul className="list-unstyled">
                      {isAdmin ? (
                        <li className="" style={{ paddingRight: "25px" }}>
                          <Nav.Link>
                            <Link
                              className="nav-link mb-1"
                              to="/admin"
                              data-placement="left"
                            >
                              <i className="bi-house-door dropdown-item-icon me-2 d-none"></i>{" "}
                              Home
                            </Link>
                          </Nav.Link>
                          <Nav.Link>
                            <Link
                              className="nav-link mb-1"
                              to="/admin/merchants"
                              data-placement="left"
                            >
                              <i className="bi-files-alt dropdown-item-icon d-none"></i>{" "}
                              Merchants
                            </Link>
                          </Nav.Link>
                          <Nav.Link>
                            <Link
                              className="nav-link mb-1"
                              to={PATH_NAMES.adminTransactions}
                              data-placement="left"
                            >
                              <i className="bi-files-alt dropdown-item-icon d-none"></i>{" "}
                              Transactions
                            </Link>
                          </Nav.Link>
                        </li>
                      ) : (
                        <li className="" style={{ paddingRight: "0px" }}>
                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to="/merchant"
                            className="nav-link mb-1"
                          >
                            <i className="bi-house-door"></i>
                            &emsp; Dashboard
                          </Nav.Link>

                          {mode === "internal.test" && (
                            <Nav.Link
                              onClick={() => toggleRef.current?.click()}
                              as={Link}
                              to={`${PATH_NAMES.client_home}/${PATH_NAMES.customers}`}
                              className="nav-link mb-1"
                            >
                              <i className="bi bi-people-fill"></i>&emsp;
                              Customers
                            </Nav.Link>
                          )}

                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.accounts}`}
                            className="nav-link mb-1"
                          >
                            <i className="bi bi-people"></i>&emsp; Virtual
                            Accounts
                          </Nav.Link>

                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.payouts}`}
                            className="nav-link mb-1"
                          >
                            <i className="bi-layers"></i>&emsp; Payouts
                          </Nav.Link>

                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.transfer}`}
                            className="nav-link mb-1"
                          >
                            <TransferIcon width={20} height={20} />
                            &nbsp;&nbsp; Transfer
                          </Nav.Link>
                          {active_domain === "live" && (
                            <Nav.Link
                              onClick={() => toggleRef.current?.click()}
                              as={Link}
                              to={`${PATH_NAMES.client_home}/${PATH_NAMES.topup}`}
                              className={`nav-link mb-1`}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <TopupIcon width={14} height={14} />
                              &nbsp;&nbsp; &nbsp;&nbsp;Top up{" "}
                            </Nav.Link>
                          )}
                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.transactions}`}
                            className="nav-link mb-1"
                          >
                            <i className="bi bi-grid"></i>&emsp; Transactions
                          </Nav.Link>

                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.cashier}`}
                            className="nav-link mb-1"
                          >
                            <i className="bi-people"></i>&emsp; Cashier
                          </Nav.Link>

                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.payLink}`}
                            className="nav-link mb-1"
                          >
                            <i className="bi bi-app-indicator"></i>&emsp;
                            Payment Links
                          </Nav.Link>

                          {mode === "internal.test" && (
                            <Nav.Link
                              onClick={() => toggleRef.current?.click()}
                              as={Link}
                              to={`${PATH_NAMES.client_home}/${PATH_NAMES.invoices}`}
                              className="nav-link mb-1"
                            >
                              <i className="bi bi-receipt"></i>&emsp; Invoice
                            </Nav.Link>
                          )}

                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#developers`}
                            className="nav-link mb-1"
                          >
                            <i className="bi-code-slash"></i>&emsp; Developers
                          </Nav.Link>

                          <Nav.Link
                            onClick={() => toggleRef.current?.click()}
                            as={Link}
                            to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#2fa`}
                            className="nav-link"
                          >
                            <i className="bi bi-shield"></i>&emsp; Security
                          </Nav.Link>
                        </li>
                      )}

                      <li className="" style={{ paddingRight: "25px" }} hidden>
                        <Nav.Link
                          onClick={() => toggleRef.current?.click()}
                          as={Link}
                          to={`${PATH_NAMES.client_home}/${PATH_NAMES.profile}#content`}
                          className="nav-link"
                        >
                          <i className="bi bi-person me-2 d-none"></i> Profile
                          &amp; account
                        </Nav.Link>

                        <Nav.Link
                          onClick={() => toggleRef.current?.click()}
                          href={`https://docs.poolerapp.com/`}
                          className="nav-link"
                          target="_blank"
                        >
                          <i className="bi bi-book me-2 d-none"></i>{" "}
                          Documentation
                        </Nav.Link>

                        <Nav.Link
                          className=""
                          onClick={() => {
                            localStorage.clear();
                            window.location.replace(
                              `/auth/merchant${PATH_NAMES.signin}`
                            );
                          }}
                        >
                          <i
                            className="bi bi-box-arrow-in-left me-2 d-none"
                            style={{ fontSize: "16px" }}
                          ></i>
                          Sign out
                        </Nav.Link>
                      </li>
                    </ul>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </header>
    </>
  );
}

export default Header;
