import MerchantDetailsContent from "../../../component/admin/merchant/MerchantDetailsContent"
import MerchantDetailsHeader from "../../../component/admin/merchant/MerchantDetailsHeader"
import MerchantDetailsStats from "../../../component/admin/merchant/MerchantDetailsStats"

const MerchantDetails = () => {
	return (
		<div className="row justify-content-lg-center">
			<div className="col-lg-10">
				<MerchantDetailsHeader />
				<MerchantDetailsStats />
				<MerchantDetailsContent />
			</div>
		</div>
	)
}

export default MerchantDetails