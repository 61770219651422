import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDisableTwoFa, useEnableTwoFa } from "../../hooks/auth/auth.hook";

import useUserDeviceInfo from "../../hooks/device.hook";
import { sessionInterface } from "../../utils/interfaces";
import OtpFields from "../otp/OtpFields";

function TwoFactorModal({
  show,
  handleClose,
  QR_CODE,
  authSecret,
  actionType,
  merchant_data,
}: {
  show: boolean;
  handleClose: () => void;
  QR_CODE: string;
  authSecret: string;
  actionType: string;
  merchant_data?: sessionInterface;
}) {
  const [copy, setCopied] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const { enable_2fa } = useEnableTwoFa();
  const { disable_2fa } = useDisableTwoFa();
  const { deviceInfo } = useUserDeviceInfo();

  const handleCopy = () => {
    setCopied(true);
    const copyTimeout = setTimeout(() => {
      setCopied(false);
    }, 5000);
    if (copy === false) {
      clearTimeout(copyTimeout);
    }
  };

  const Enable = async (otpStrings: any | string) => {
    setReadOnly(true);
    try {
      const response: any = await enable_2fa.mutateAsync(otpStrings);
      if (response.status === 200) {
        moengage.track_event("ENABLE_2FA", {
          OTP: true,
          otpStrings,
          success_status: true,
          firstName: merchant_data?.firstname,
          lastName: merchant_data?.lastname,
          email: merchant_data?.email,
          mobile: merchant_data?.mobile_number,
          userName: merchant_data?.email,
          uniqueUserId: merchant_data?.email,
          phone_number: merchant_data?.mobile_number,
          terms_agreement: merchant_data?.agreed_terms,
          address: merchant_data?.address,
          business_category: merchant_data?.business_category,
          business_type: merchant_data?.business_type,
          platform: deviceInfo?.userAgent,
        });
        setReadOnly(false);
        handleClose();
      }
    } catch (error: any) {
      setReadOnly(false);
      return error;
    }
    setReadOnly(false);
  };

  const Disable = async (otpStrings: any | string) => {
    setReadOnly(true);
    try {
      const response: any = await disable_2fa.mutateAsync(otpStrings);
      if (response.status === 200) {
        moengage.track_event("DISABLE_2FA", {
          OTP: true,
          otpStrings,
          success_status: true,
          firstName: merchant_data?.firstname,
          lastName: merchant_data?.lastname,
          email: merchant_data?.email,
          mobile: merchant_data?.mobile_number,
          userName: merchant_data?.email,
          uniqueUserId: merchant_data?.email,
          phone_number: merchant_data?.mobile_number,
          terms_agreement: merchant_data?.agreed_terms,
          address: merchant_data?.address,
          business_category: merchant_data?.business_category,
          business_type: merchant_data?.business_type,
          platform: deviceInfo?.userAgent,
        });
        setReadOnly(false);
        handleClose();
      }
    } catch (error: any) {
      setReadOnly(false);
      return error;
    }
    setReadOnly(false);
  };

  const handleOtpChange = (otp: any) => {
    if (otp.length === 6) {
      setReadOnly(true);
      setTimeout(() => {
        actionType === "enable-2fa" ? Enable(otp) : Disable(otp);
      }, 100);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="sm">
      <Modal.Header closeButton={false}>
        <Modal.Title className="scan-header">
          {actionType === "enable-2fa" ? (
            <h4
              className="modal-title title card-subtitle"
              id="exampleModalLabel"
            >
              Please scan to enable two-factor
            </h4>
          ) : (
            <h4 className="modal-title title" id="exampleModalLabel">
              Please enter OTP to disbale two-factor
            </h4>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="text-center">
          {actionType === "enable-2fa" &&
            QR_CODE !== "1" &&
            QR_CODE !== null && (
              <div className="mb-4">
                <img
                  className="avatar avatar-xxl avatar-4x3"
                  src={QR_CODE}
                  alt=" Description"
                  data-hs-theme-appearance="default"
                />
                <p className="">
                  {authSecret}{" "}
                  <i
                    className={`${
                      copy ? "bi bi-check2-all text-success" : "bi bi-clipboard"
                    }`}
                    onClick={() => {
                      navigator.clipboard.writeText(`${authSecret}`);
                      handleCopy();
                    }}
                    style={{ cursor: "pointer" }}
                  ></i>
                </p>
              </div>
            )}

          <div className="row gx-2 gx-sm-3">
            <OtpFields onOtpInput={handleOtpChange} readOnly={readOnly} />

            <div className="mb-4">
              <p className="link link-info" style={{ fontSize: "12px" }}>
                You will be logged out after the operation is completed
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer hidden>
        <div className="row align-items-sm-center flex-grow-1 mx-n2 justify-content-end">
          <div className="col-sm-auto">
            <div className="d-flex gap-3">
              <button type="button" className="btn btn-white">
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Enable
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default TwoFactorModal;
