import moengage from "@moengage/web-sdk";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KycLevels from "../../../component/modal/KycLevels";
import SpinnerComponent from "../../../component/spinner/Spinner";
import { PATH_NAMES } from "../../../constants/index.constant";
import { encryptKyc, signatureHarsh } from "../../../helpers/index.helper";
import { useSession } from "../../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../../hooks/device.hook";
import {
  useBuisnessCompliance,
  useCheckBuisnessRequirements,
  useGetComplianceRequirements,
} from "../../../hooks/kyc/kyc.hook";
import API_SERVICE from "../../../services/services";
import { BUISNESS_TYPE, KYC_LEVELS } from "../../../utils/enums";
import { sessionInterface } from "../../../utils/interfaces";
import Kyc from "../Kyc";
import MandatoryUnregisteredFields from "./MandatoryUnregisteredFields";

function MandatoryUnregistered(): JSX.Element {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionData } = useSession();
  const { compliance } = useBuisnessCompliance();
  const { complianceData } = useGetComplianceRequirements();
  const { deviceInfo } = useUserDeviceInfo();
  const merchantData: sessionInterface = sessionData?.data?.data;
  const requirementsData =
    complianceData?.data?.data?.unRegistered?.level_one?.account_limits;
  const single_withdrawal = requirementsData?.single_withdrawal;
  const daily_withdrawal = requirementsData?.daily_withdrawal;
  const collection = requirementsData?.collection;

  const merchantId: string | any = localStorage.getItem(
    "merchant_compliance_id"
  );

  const message = merchantId && JSON.stringify({ id: merchantId });

  const x_signature = encryptKyc(message, signatureHarsh);

  const BUSINESS_TYPE: string = BUISNESS_TYPE.UN_REGISTERED;

  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );
  const requirementData: any = requiredData?.data?.data;
  const levelOne: any = requirementData?.level_one;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type, id } = e.target;
    if (
      id === "nok_firstname" ||
      id === "nok_lastname" ||
      id === "nok_address" ||
      id === "nok_email" ||
      id === "nok_mobile_number" ||
      id === "nok_relationship" ||
      id === "nok_middlename"
    ) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        nextOfKin: {
          ...prevFormData["nextOfKin"],
          [name]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const Submit = async (
    formData: any,
    payloadSign: any,
    url?: string,
    proceed?: boolean
  ) => {
    setIsLoading(true);
    const payload =
      proceed === true
        ? {
            KYC_TYPE: BUISNESS_TYPE.UN_REGISTERED,
            KYC_DATA: {
              ...formData,
              proceed: true,
            },
            KYC_MERCHANT_ID: merchantData?.merchant_compliance_id,
            KYC_LEVEL: KYC_LEVELS.LEVEL_ONE,
            x_signature: payloadSign,
          }
        : {
            KYC_TYPE: BUISNESS_TYPE.UN_REGISTERED,
            KYC_DATA: {
              ...formData,
            },
            KYC_MERCHANT_ID: merchantData?.merchant_compliance_id,
            KYC_LEVEL: KYC_LEVELS.LEVEL_ONE,
            x_signature: payloadSign,
          };
    try {
      const response = await compliance.mutateAsync(payload);
      if (response?.status === 200 && formData?.submit === true && url !== "") {
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.UN_REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.UN_REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        setIsLoading(false);
        handleShow();
      } else if (
        response?.status === 200 &&
        formData?.submit === false &&
        url !== ""
      ) {
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.UN_REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.UN_REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        navigate(`/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`);
        setIsLoading(false);
      } else {
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            payload?.KYC_MERCHANT_ID,
            BUISNESS_TYPE.UN_REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.UN_REGISTERED,
              `${payload?.KYC_MERCHANT_ID}`,
              x_signature
            )
        );
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    queryClient.refetchQueries();
    if (merchantId !== undefined && BUSINESS_TYPE !== undefined) {
      const Id: any = localStorage.getItem("merchant_compliance_id");

      queryClient.fetchQuery(
        ["business-requirements-check", Id, BUSINESS_TYPE, x_signature],
        () =>
          API_SERVICE._checkBuisnessComplianceRequirements(
            BUSINESS_TYPE,
            Id,
            x_signature
          )
      );

      if (levelOne) {
        setFormData({
          first_name: levelOne?.first_name || "",
          last_name: levelOne?.last_name || "",
          email: levelOne?.email || "",
          phone_number: levelOne?.phone_number || "",
          dob: levelOne?.dob || "",
          bvn: levelOne?.bvn || "",
          country: levelOne?.country || "",
          state: levelOne?.state || "",
          address: levelOne?.address || "",
          image: levelOne?.image || "",
          nextOfKin: {
            nok_firstname: levelOne?.nextOfKin?.nok_firstname || "",
            nok_lastname: levelOne?.nextOfKin?.nok_lastname || "",
            nok_email: levelOne?.nextOfKin?.nok_email || "",
            nok_mobile_number: levelOne?.nextOfKin?.nok_mobile_number || "",
            nok_address: levelOne?.nextOfKin?.nok_address || "",
            nok_relationship: levelOne?.nextOfKin?.nok_relationship || "",
          },
        });
      }

      if (levelOne?.middle_name) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          middle_name: levelOne?.middle_name || "",
        }));
      }

      if (levelOne?.city) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          city: levelOne?.city || "",
        }));
      }

      if (levelOne?.nextOfKin?.nok_middlename) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          nextOfKin: {
            ...prevFormData["nextOfKin"],
            nok_middlename: levelOne?.nextOfKin?.nok_middlename || "",
          },
        }));
      }
    }
  }, [BUSINESS_TYPE, levelOne, merchantId, queryClient, x_signature]);

  const isEmptyObject = (obj: any) => {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        if (!isEmptyObject(obj[key])) {
          return false;
        }
      } else if (typeof obj[key] === "string" && obj[key].trim() !== "") {
        return false;
      }
    }
    return true;
  };

  function removeEmptyValues(obj: any) {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        removeEmptyValues(obj[key]); // Recursively check nested objects
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key]; // Delete the entire key if nested object becomes empty
        }
      }
    }

    return obj;
  }

  const SaveEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_user_attribute("starts_kyc", true);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier1_saved: true,
      platform: deviceInfo?.userAgent,
    });
  };

  const SubmitEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_user_attribute("starts_kyc", true);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      tier1_submitted: true,
      platform: deviceInfo?.userAgent,
    });
  };

  return (
    <>
      <KycLevels
        show={show}
        handleClose={handleClose}
        onClick={() =>
          navigate(`/merchant/${PATH_NAMES.kyc}/unregistered_business/valid_id`)
        }
        buttonLabel={"Proceed to tier 2"}
      />
      <Kyc activeTab="mandatory_details" activeMenu="unregistered">
        <div
          id="basicVerStepDetails"
          className="card"
          style={{ minHeight: "15rem" }}
        >
          <div className="card kyc-card-top">
            <div className="row align-items-start mb-0 pb-2 mt-2">
              <div className="col-sm mb-2 mb-sm-0">
                <h2 className="page-header-title text-white pb-3">Tier 1</h2>
                {/* <i class="bi bi-circle"></i> */}
              </div>

              <div className="col-sm-auto">
                {levelOne?.submit === true ? (
                  <>
                    {/* <button type="button" className="btn  kyc-save-btn" hidden>
                      Request Edit
                    </button> */}
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn  kyc-save-btn"
                    onClick={() => {
                      const payloadSign = encryptKyc(
                        JSON.stringify({
                          ...formData,

                          submit: false,
                        }),
                        signatureHarsh
                      );
                      Submit({ ...formData, submit: false }, payloadSign, "");
                      SaveEvent("KYC_SAVE_UNREG_BIZ_TIER1");
                    }}
                  >
                    Save changes{" "}
                    {isLoading && (
                      <>
                        &nbsp;{" "}
                        <span className="align-middle">
                          <SpinnerComponent size="sm" />
                        </span>
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
            <ul className="ps-0 w-100 w-md-75 w-lg-100 list-unstyled pe-lg-5 me-lg-5">
              {/* SM SCREENS */}
              <li className="text-white listStyleDisc w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Withdrawal limit
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  &#8358;{single_withdrawal}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Withdrawal limit
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  &#8358;{single_withdrawal}
                </span>
              </li>

              {/* SM SCREENS */}
              <li className="text-white listStyleDisc py-1 w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Daily withdrawal limit{" "}
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  &#8358;{daily_withdrawal}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc py-1 w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Daily withdrawal limit{" "}
                </span>
                <span
                  className="float-end"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  &#8358;{daily_withdrawal}
                </span>
              </li>

              {/* SM SCREENS */}
              <li className="text-white listStyleDisc w-100 d-sm-block d-md-none d-lg-none">
                <span className="" style={{ fontSize: "13px" }}>
                  Maximum Deposit limit{" "}
                </span>
                <span
                  className="float-end text-capitalize"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {collection}
                </span>
              </li>

              {/* LG SCREENS */}
              <li className="text-white listStyleDisc w-100 d-none d-md-block d-lg-block">
                <span className="" style={{ fontSize: "13px" }}>
                  Maximum Deposit limit{" "}
                </span>
                <span
                  className="float-end text-capitalize"
                  style={{
                    fontSize: "13px",
                    width: "27%",
                    textAlign: "left",
                  }}
                >
                  {collection}
                </span>
              </li>
            </ul>
          </div>

          <div className="card-body active">
            {levelOne ? (
              <MandatoryUnregisteredFields
                formValues={formData}
                handleChange={handleChange}
                data={levelOne}
                isDisabled={
                  levelOne && levelOne?.submit === true ? true : false
                }
                setFormData={setFormData}
              />
            ) : (
              <MandatoryUnregisteredFields
                formValues={formData}
                handleChange={handleChange}
                setFormData={setFormData}
              />
            )}

            {/* LG SCREENS */}
            {levelOne?.submit === true ? null : (
              <>
                <div className="d-none d-md-block d-lg-block pb-3">
                  <div className="d-flex align-items-center justify-content-end mt-auto">
                    <button
                      type="button"
                      className="btn kyc-save-btn"
                      onClick={() => {
                        const isEmpty: boolean = isEmptyObject(formData);
                        if (isEmpty === false) {
                          let x: any = removeEmptyValues(formData);
                          x.proceed = true;
                          const payloadSign = encryptKyc(
                            JSON.stringify({
                              ...x,
                              submit: false,
                            }),
                            signatureHarsh
                          );

                          Submit(
                            { ...formData, submit: false },
                            payloadSign,
                            "p",
                            true
                          );
                          SaveEvent("KYC_PROCEED_UNREG_BIZ_TIER1");
                        } else {
                          toast.error("Kindly fill all required fields");
                        }
                      }}
                    >
                      Proceed to tier 2{" "}
                      <i className="bi bi-arrow-right small"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        const isEmpty: boolean = isEmptyObject(formData);
                        if (isEmpty === false) {
                          let x: any = removeEmptyValues(formData);
                          x.proceed = true;
                          const payloadSign = encryptKyc(
                            JSON.stringify({
                              ...x,
                              submit: true,
                            }),
                            signatureHarsh
                          );

                          Submit(
                            { ...formData, submit: true },
                            payloadSign,
                            "xf",
                            true
                          );
                          SubmitEvent("KYC_UNREG_BIZ_SUBMIT_TIER1");
                        } else {
                          toast.error("Kindly fill all required fields");
                        }
                      }}
                    >
                      Submit <i className="bi bi-check2 text-white small"></i>
                    </button>
                  </div>
                </div>
                {/* SM SCREENS */}
                <div className="d-sm-block d-md-none d-lg-none">
                  <div className="d-flex align-items-center justify-content-space-between mt-auto">
                    <div className="ms-auto">
                      <>
                        {" "}
                        <button
                          type="button"
                          onClick={() => {
                            const isEmpty: boolean = isEmptyObject(formData);
                            if (isEmpty === false) {
                              let x: any = removeEmptyValues(formData);
                              x.proceed = true;
                              const payloadSign = encryptKyc(
                                JSON.stringify({
                                  ...x,
                                  submit: false,
                                }),
                                signatureHarsh
                              );

                              Submit(
                                { ...formData, submit: false },
                                payloadSign,
                                "p",
                                true
                              );
                              SaveEvent("KYC_PROCEED_UNREG_BIZ_TIER1");
                            } else {
                              toast.error("Kindly fill all required fields");
                            }
                          }}
                          className="btn kyc-save-btn"
                        >
                          Proceed to tier 2{" "}
                          <i className="bi bi-arrow-right small"></i>
                        </button>
                        &nbsp;
                      </>

                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          const isEmpty: boolean = isEmptyObject(formData);
                          if (isEmpty === false) {
                            let x: any = removeEmptyValues(formData);
                            x.proceed = true;
                            const payloadSign = encryptKyc(
                              JSON.stringify({
                                ...x,
                                submit: true,
                              }),
                              signatureHarsh
                            );

                            Submit(
                              { ...formData, submit: true },
                              payloadSign,
                              "xf",
                              true
                            );
                            SubmitEvent("KYC_SUBMIT_UNREG_BIZ_TIER1");
                          } else {
                            toast.error("Kindly fill all required fields");
                          }
                        }}
                      >
                        Submit <i className="bi bi-check2 text-white small"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Kyc>
    </>
  );
}

export default MandatoryUnregistered;

// cutefun@mailinator.com
