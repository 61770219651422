import React from "react";
import { Link, NavLink } from "react-router-dom";

const ButtonMailto = ({ mailto }) => {
  return (
    <NavLink
      to="#"
      className="link-dark"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      <i className="bi-chat-left-dots"></i> &emsp;Contact support
    </NavLink>
  );
};

export default ButtonMailto;
