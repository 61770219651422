import moengage from "@moengage/web-sdk";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as Cancel } from "../../assets/slash-octagon.svg";
import { ReactComponent as TransferIcon } from "../../assets/transfer.svg";
import CopyButton from "../../component/Copy";
import DropDownWrapper from "../../component/DropDownWrapper";
import Tabs from "../../component/Tab/Tab";
import BackBtn from "../../component/back-btn";
import AccountDetailsModal from "../../component/modal/AccountDetailsModal";
import CashierSettlement from "../../component/modal/CashierSettlement";
import CreateCashierModal from "../../component/modal/CreateCashierModal";
import DeactivateCashierModal from "../../component/modal/DeactivateCashierModal";
import PaybackModal from "../../component/modal/PaybackModal";
import FilterModal from "../../component/modal/filterModal";
import PaginationComponent from "../../component/pagination/pagination";
import SpinnerComponent from "../../component/spinner/Spinner";
import Table from "../../component/table/Table";
import {
  collections_column,
  payback_column,
} from "../../configs/dataTable.config";
import { formatAmount, mode } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import {
  useCashier,
  useCashierStats,
  useCashiersCollection,
  useCashiersSettlement,
} from "../../hooks/cashier/index.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import useResize from "../../hooks/resize.hook";
import API_SERVICE from "../../services/services";
import { sessionInterface } from "../../utils/interfaces";

function CashierDetails() {
  const buttonRef: any = useRef(null);
  const filterButtonRef: any = useRef(null);
  const settleementButtonRef: any = useRef(null);
  const settleementButtonDownloadRef: any = useRef(null);
  const collectionButtonDownloadRef: any = useRef(null);

  const { deviceInfo } = useUserDeviceInfo();
  const { size } = useResize();

  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const params: any = useParams();
  const { data: sessionData } = useSession();

  const merchant_data: sessionInterface = sessionData?.data?.data;
  const accountNumber = location?.state?.account_number;
  const cashier_id = params?.id;

  const [activeTab, setActiveTab] = useState<string>("collections");
  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [paybackShow, setPaybackShow] = useState<boolean>(false);
  const [activeCollection, setActiveCollection] = useState<number>(0);
  const [activeSettlement, setActiveSettlement] = useState<number>(0);
  const [dateRange, setDateRange] = useState({ start_date: "", end_date: "" });
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [setttlementDetails, setDetails] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAccount, setShowAccount] = useState<boolean>(false);

  const [collectionInfo, setCollectionInfo] = useState<any>();
  const [settlementInfo, setSettlementInfo] = useState<any>();

  const handleClose = () => setShowModal(false);

  const [cashierStats, setCashierStats] = useState<{
    totalAmountCollected: string | any;
    totalAmountSettled: string | any;
  }>({
    totalAmountCollected: "",
    totalAmountSettled: "",
  });

  const { settlement, settlementFetching, settlementRefetch } =
    useCashiersSettlement(activeSettlement, cashier_id, query);

  const { collectionData, collectionFetching, collectionRefetch } =
    useCashiersCollection(activeCollection, accountNumber, query);

  const handleCollectionDateRangeFilter = async (download?: boolean) => {
    setLoading(true);
    try {
      const response = await queryClient.fetchQuery(
        [
          "cashier-collections",
          activeCollection,
          accountNumber,
          query,
          dateRange,
          download,
        ],
        () =>
          API_SERVICE._getCashierCollections(
            activeCollection,
            accountNumber,
            query,
            dateRange,
            download
          )
      );
      if (response?.status === 200) {
        setLoading(false);
        setCollectionInfo(response?.data?.data?.items);

        if (download) {
          collectionButtonDownloadRef?.current?.click();
          setLoading(false);
          const url = response?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cashier_collections.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          buttonRef?.current?.click();
        }
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const handleCollectionRefetch = async (download?: boolean) => {
    const x = { start_date: "", end_date: "" };
    try {
      const response = await queryClient.fetchQuery(
        ["cashier-collections", 0, accountNumber, query, x],
        () =>
          API_SERVICE._getCashierCollections(
            activeCollection,
            accountNumber,
            query,
            x
          )
      );
      if (response?.status === 200) {
        setShowModal(false);
        setCollectionInfo(response?.data?.data?.items);
      }
    } catch (error) {}
  };

  const handleSettlementDateRangeFilter = async (download?: boolean) => {
    setLoading(true);
    try {
      const response = await queryClient.fetchQuery(
        [
          "cashier-settlements",
          activeSettlement,
          cashier_id,
          query,
          dateRange,
          download,
        ],
        () =>
          API_SERVICE._getCashierSettlements(
            activeSettlement,
            cashier_id,
            query,
            dateRange,
            download
          )
      );
      if (response?.status === 200) {
        setLoading(false);
        setSettlementInfo(response?.data?.data?.items);

        // setDateRange({ start_date: "", end_date: "" });
        if (download) {
          settleementButtonDownloadRef?.current.click();
          setLoading(false);
          const url = response?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cashier_settlement.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          settleementButtonRef?.current.click();
        }
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  const handleSettlementRefetch = async (download?: boolean) => {
    const x = { start_date: "", end_date: "" };
    try {
      const response = await queryClient.fetchQuery(
        ["cashier-settlements", 0, accountNumber, query, x],
        () =>
          API_SERVICE._getCashierCollections(
            activeSettlement,
            cashier_id,
            query,
            x
          )
      );
      if (response?.status === 200) {
        setShowModal(false);
        setSettlementInfo(response?.data?.data?.items);
      }
    } catch (error) {}
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
    if (activeTab === "collections" && value !== "") {
      await queryClient.fetchQuery(
        [
          "cashier-collections",
          activeCollection,
          accountNumber,
          value,
          dateRange,
        ],
        () =>
          API_SERVICE._getCashierCollections(
            activeCollection,
            accountNumber,
            value,
            dateRange
          )
      );
    } else if (activeTab === "payback-history" && value !== "") {
      await queryClient.fetchQuery(
        ["cashier-settlements", activeSettlement, cashier_id, value, dateRange],
        () =>
          API_SERVICE._getCashierSettlements(
            activeSettlement,
            cashier_id,
            value,
            dateRange
          )
      );
    }
  };

  // const collectionResponse = collectionData?.data?.data?.items;
  const TotalCollectionItems = collectionData?.data?.data?.totalPages;

  // const settlementResponse = settlement?.data?.data?.items;
  const TotalSettlementItems = settlement?.data?.data?.totalPages;

  useEffect(() => {
    if (settlement?.data?.data?.items) {
      setSettlementInfo(settlement?.data?.data?.items);
    }
  }, [settlement?.data?.data?.items]);

  useEffect(() => {
    if (collectionData?.data?.data?.items) {
      setCollectionInfo(collectionData?.data?.data?.items);
    }
  }, [collectionData?.data?.data?.items]);

  const collections = useMemo(
    () => [
      ...collections_column,
      {
        Header: "",
        columnId: 6,
        accessor: "actions",
        Cell: ({ row }: any): JSX.Element => {
          return (
            <>
              <i className="bi bi-eye" style={{ cursor: "pointer" }}></i>
            </>
          );
        },
      },
    ],
    []
  );

  const payback = useMemo(
    () => [
      ...payback_column,
      {
        Header: "",
        columnId: 6,
        accessor: "actions",
        Cell: ({ row }: any): JSX.Element => {
          const details = row?.original;
          return (
            <>
              <i
                className="bi bi-eye"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsModalOpen(true);
                  setDetails({
                    ...details,
                    cashier_name: location?.state?.cashier_name,
                  });
                }}
              ></i>
            </>
          );
        },
      },
    ],
    [location?.state?.cashier_name]
  );

  const { data, refetch, isFetching, cashierRefetch } =
    useCashier(accountNumber);

  const { stats, statsFetching, statsLoading, statsRefetch } = useCashierStats(
    cashier_id,
    {}
  );

  const cashierInfo = data?.data?.data;
  const status = cashierInfo?.active;

  useEffect(() => {
    if (stats?.data?.data) {
      setCashierStats({
        totalAmountCollected: stats?.data?.data?.total_amount_collected,
        totalAmountSettled: stats?.data?.data?.total_amount_settled,
      });
    }
  }, [stats?.data?.data]);

  const handleStatsDateRangeFilter = async () => {
    setLoading(true);
    try {
      const response = await queryClient.fetchQuery(
        ["cashier-statistics", cashier_id, dateRange],
        () => API_SERVICE._getCashierStats(cashier_id, dateRange)
      );

      if (response?.status === 200) {
        setCashierStats({
          totalAmountCollected: response?.data?.data?.total_amount_collected,
          totalAmountSettled: response?.data?.data?.total_amount_settled,
        });
        setLoading(false);
        filterButtonRef?.current?.click();
        // setDateRange({ start_date: "", end_date: "" });
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
    setLoading(false);
  };

  useEffect(() => {
    queryClient.fetchQuery(["cashiers", "cashier", cashier_id], () =>
      API_SERVICE._getSingleCashier(cashier_id)
    );
  }, [cashier_id, queryClient]);

  useEffect(() => {
    moengage.track_event("VIEW_CASHIER", {
      cashier_id: params?.id,
      cashier_name: cashierInfo?.cashier_name,
      platform: deviceInfo?.userAgent,
    });
  }, [cashierInfo?.cashier_name, deviceInfo?.userAgent, params?.id]);

  const originalCashierName = cashierInfo?.cashier_name;
  const parts = originalCashierName && originalCashierName?.split("/");
  const result = parts && parts[1]?.trim();

  return (
    <>
      <AccountDetailsModal
        show={showAccount}
        handleClose={() => setShowAccount(false)}
        cashierInfo={cashierInfo}
        statsLoading={statsLoading}
      />
      <FilterModal
        show={showModal}
        handleClose={() => {
          handleClose();
          settlementRefetch();
          setDateRange({ start_date: "", end_date: "" });
          collectionRefetch();
          cashierRefetch();
        }}
      >
        <div className="row mb-4">
          <div className="col tom-select-custom mb-3">
            <label>Start date</label>
            <input
              type="date"
              name="start_date"
              value={dateRange?.start_date}
              onChange={(e) => {
                setDateRange((prev: any) => ({
                  ...prev,
                  start_date: e.target.value,
                }));
              }}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
          <div className="col tom-select-custom mb-3">
            <label>End date</label>
            <input
              type="date"
              value={dateRange?.end_date}
              onChange={(e) => {
                setDateRange((prev: any) => ({
                  ...prev,
                  end_date: e.target.value,
                }));
              }}
              className="js-daterangepicker form-control daterangepicker-custom-input"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-white btn-sm"
            onClick={() => {
              handleClose();
              settlementRefetch();
              setDateRange({ start_date: "", end_date: "" });
              collectionRefetch();
              cashierRefetch();
            }}
          >
            Close
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              if (activeTab === "collections") {
                handleCollectionDateRangeFilter();
              } else {
                handleSettlementDateRangeFilter();
              }
            }}
            style={{ cursor: "pointer" }}
          >
            Apply filter{" "}
            {loading && <SpinnerComponent variant="light" size="sm" />}
          </button>
        </div>
      </FilterModal>
      <PaybackModal
        show={paybackShow}
        handleClose={() => setPaybackShow(false)}
        account_no={accountNumber}
      />
      <DeactivateCashierModal
        show={show}
        handleClose={() => {
          setShow(false);
          refetch();
        }}
        id={location?.state?.account_number}
        cashierData={cashierInfo}
        actionType={!status ? "unblock" : "block"}
      />
      <CreateCashierModal
        show={open}
        handleClose={() => {
          setOpen(false);
        }}
        id={location?.state?.id}
      />
      <CashierSettlement
        show={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          setDetails({});
          setOpen(false);
          setPaybackShow(false);
        }}
        settlementDetails={setttlementDetails}
      />
      <div>
        {/* MOBILE SCREENS COMPATIBILITY UP HERE */}
        <div className="d-sm-block d-md-none d-lg-none d-xl-none">
          <div className="d-flex flex-row align-items-baseline">
            <i
              className="bi bi-chevron-left text-dark"
              style={{ fontSize: "14px" }}
              onClick={() => navigate("/merchant/business/cashier")}
            ></i>
            <h1 className="create-virtual-acc text-center mx-auto">
              {originalCashierName?.includes("/")
                ? result
                : originalCashierName}
              &nbsp;
              {status ? (
                <span className="badge bg-soft-success text-success ms-2">
                  <span className="legend-indicator bg-success"></span>
                  Active{" "}
                </span>
              ) : (
                <span className="badge bg-soft-danger text-danger ms-2">
                  <span className="legend-indicator bg-danger"></span> Inactive{" "}
                </span>
              )}
            </h1>

            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn btn-light rounded btn-icon icon-btn"
              >
                <i className="bi-three-dots" style={{ color: "#000" }} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={"button"}
                  type="button"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <Cancel className="me-2" />
                  &nbsp;
                  {!status ? "Unsuspend" : "Suspend"}
                </Dropdown.Item>

                <Dropdown.Item
                  as={"button"}
                  type="button"
                  onClick={() => {
                    navigate(
                      `/merchant/business/cashier/update_cashier/${cashier_id}`,
                      { state: location.state }
                    );
                  }}
                >
                  <i className="bi bi-pencil me-2"></i>&nbsp; Edit
                </Dropdown.Item>

                <Dropdown.Item
                  as={"button"}
                  type="button"
                  onClick={() => {
                    setPaybackShow(true);
                  }}
                >
                  <TransferIcon width={20} height={20} />
                  &nbsp; Settle
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="card card-body mb-3 mt-3">
            <h1 className="create-virtual-acc text-center mx-auto d-flex flex-column">
              {originalCashierName?.includes("/")
                ? result
                : originalCashierName}
              <small className="text-center mx-auto pt-2">
                {cashierInfo?.account_number}{" "}
                <CopyButton text={cashierInfo?.account_number} />
              </small>
            </h1>

            <span
              className="link link-primary text-center pt-2"
              style={{ cursor: "pointer", color: "#2C1DFF" }}
              onClick={() => setShowAccount(true)}
            >
              View Account details
            </span>
          </div>
        </div>
        {/* LG AND MEDIUM SCREENS COMPATIBILITY */}
        <div className="d-none d-md-block d-lg-block d-xl-block">
          <div className="page-header mb-0">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                {isFetching ? (
                  <Skeleton width={200} />
                ) : (
                  <h1 className="page-header-title">
                    <BackBtn />
                    {originalCashierName?.includes("/")
                      ? result
                      : originalCashierName}
                    &nbsp;
                    {status ? (
                      <span className="badge bg-soft-success text-success ms-2">
                        <span className="legend-indicator bg-success"></span>
                        Active{" "}
                      </span>
                    ) : (
                      <span className="badge bg-soft-danger text-danger ms-2">
                        <span className="legend-indicator bg-danger"></span>{" "}
                        Inactive{" "}
                      </span>
                    )}
                  </h1>
                )}
              </div>

              <div className="col-sm-auto">
                <div className="d-flex align-items-center justify-content-end mt-auto">
                  {isFetching ? (
                    <Skeleton />
                  ) : (
                    <button
                      type="button"
                      className="btn kyc-save-btn"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      {!status ? "Unsuspend" : "Suspend"}
                    </button>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn kyc-save-btn"
                    onClick={() => {
                      navigate(
                        `/merchant/business/cashier/update_cashier/${cashier_id}`,
                        { state: location.state }
                      );
                    }}
                  >
                    <i className="bi bi-pencil ms-1"></i> Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* SM SCREEN ACCOUNT DETAILS */}
          <div className="d-block d-md-block d-lg-none d-xl-none">
            <div className="card card-body mb-3 mb-lg-5 mt-3">
              <div className="row col-lg-dividerdd gx-lg-6">
                <div className="d-none">
                  <DropDownWrapper
                    className="mx-auto"
                    action={
                      <button
                        type="button"
                        className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary mx-auto"
                        ref={buttonRef}
                      >
                        <i className="bi bi-filter"></i> Filter
                      </button>
                    }
                  >
                    <div className="p-3">
                      <label className="text-body">Date Range</label>
                      <div className="row mb-4">
                        <div className="col tom-select-custom">
                          <input
                            type="date"
                            name="start_date"
                            value={dateRange?.start_date}
                            onChange={(e) => {
                              setDateRange((prev: any) => ({
                                ...prev,
                                start_date: e.target.value,
                              }));
                            }}
                            className="js-daterangepicker form-control daterangepicker-custom-input"
                          />
                        </div>
                        <div className="col tom-select-custom">
                          <input
                            type="date"
                            name="end_date"
                            value={dateRange?.end_date}
                            onChange={(e) => {
                              setDateRange((prev: any) => ({
                                ...prev,
                                end_date: e.target.value,
                              }));
                            }}
                            className="js-daterangepicker form-control daterangepicker-custom-input"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          className="btn btn-white btn-sm"
                          onClick={() => {
                            setDateRange({ start_date: "", end_date: "" });
                            collectionRefetch();
                            queryClient.refetchQueries();
                          }}
                        >
                          Reset
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleStatsDateRangeFilter()}
                        >
                          Apply Filter
                          {loading && (
                            <>
                              &nbsp;{" "}
                              <SpinnerComponent variant="light" size="sm" />
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </DropDownWrapper>
                </div>

                <div className="col-lg-3 border-bottom mb-3 pb-2">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="card-subtitle mb-3 text-capitalize">
                        Wallet balance
                      </h6>
                      {statsFetching ? (
                        <Skeleton height={20} width={200} />
                      ) : (
                        <h3 className="card-title">
                          {cashierInfo?.currency_code}{" "}
                          {formatAmount(cashierInfo?.balance?.balance)}
                        </h3>
                      )}
                    </div>

                    <span
                      className="icon icon-soft-secondary icon-sm icon-circle ms-3"
                      hidden
                    >
                      <i className="bi bi-cash-stack"></i>
                    </span>
                  </div>
                </div>

                <div className="col-lg-3 border-bottom mb-3 pb-2">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="card-subtitle mb-3 text-capitalize">
                        Total collection
                      </h6>
                      {statsFetching ? (
                        <Skeleton height={20} width={200} />
                      ) : (
                        <h3 className="card-title">
                          {cashierInfo?.currency_code}{" "}
                          {formatAmount(cashierStats?.totalAmountCollected)}
                        </h3>
                      )}
                    </div>

                    <span
                      className="icon icon-soft-secondary icon-sm icon-circle ms-3"
                      hidden
                    >
                      <i className="bi bi-cash-stack"></i>
                    </span>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="card-subtitle mb-3 text-capitalize">
                        Total settlement
                      </h6>
                      {statsFetching ? (
                        <Skeleton height={20} width={200} />
                      ) : (
                        <h3 className="card-title">
                          {cashierInfo?.currency_code}{" "}
                          {formatAmount(cashierStats?.totalAmountSettled)}
                        </h3>
                      )}
                    </div>

                    <span
                      className="icon icon-soft-secondary icon-sm icon-circle ms-3"
                      hidden
                    >
                      <i className="bi bi-cash-stack"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* LG && MEDIUM SCREENS ACC DETAILS */}
          {/* LEFT */}
          <div className="col-lg-3 mb-3 mb-sm-0 cashier-left-col d-none d-md-none d-lg-block">
            <div
              className="w-75 content-space-t-0 content-space-t-lg-1 content-space-b-1 mx-auto"
              style={{ maxWidth: "50rem" }}
            >
              <>
                <DropDownWrapper
                  className=""
                  action={
                    <button
                      type="button"
                      className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary mx-auto"
                      ref={filterButtonRef}
                    >
                      <i className="bi bi-filter"></i> Filter
                    </button>
                  }
                  styleObject={{ left: "2%" }}
                >
                  <div className="p-3">
                    <label className="text-body">Date Range</label>
                    <div className="row mb-4">
                      <div className="col tom-select-custom">
                        <input
                          type="date"
                          name="start_date"
                          value={dateRange?.start_date}
                          onChange={(e) => {
                            setDateRange((prev: any) => ({
                              ...prev,
                              start_date: e.target.value,
                            }));
                          }}
                          className="js-daterangepicker form-control daterangepicker-custom-input"
                        />
                      </div>
                      <div className="col tom-select-custom">
                        <input
                          type="date"
                          name="end_date"
                          value={dateRange?.end_date}
                          onChange={(e) => {
                            setDateRange((prev: any) => ({
                              ...prev,
                              end_date: e.target.value,
                            }));
                          }}
                          className="js-daterangepicker form-control daterangepicker-custom-input"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-white btn-sm"
                        onClick={() => {
                          filterButtonRef?.current?.click();
                          // window.location.reload();
                          setDateRange({ start_date: "", end_date: "" });
                          collectionRefetch();
                          queryClient.invalidateQueries({
                            queryKey: ["cashier-statistics"],
                          });
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleStatsDateRangeFilter()}
                      >
                        Apply Filter
                        {loading && (
                          <>
                            &nbsp;{" "}
                            <SpinnerComponent variant="light" size="sm" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </DropDownWrapper>
              </>
            </div>

            <div className="w-75 px-5">
              <ul className="list-unstyled list-py-3">
                <li className="text-start d-flex flex-column justify-conteent-center">
                  {statsFetching ? (
                    <Skeleton />
                  ) : (
                    <>
                      <span className="cashier-stats text-capitalize">
                        Wallet balance
                      </span>
                      <p className="cashier-value" style={{ fontSize: "20px" }}>
                        {cashierInfo?.currency_code}{" "}
                        {formatAmount(cashierInfo?.balance?.balance)}
                      </p>
                    </>
                  )}
                </li>
                <li className="text-start d-flex flex-column cashier-dividerrr">
                  {statsFetching ? (
                    <Skeleton />
                  ) : (
                    <>
                      <span className="cashier-stats text-capitalize">
                        Total collection
                      </span>
                      <p className="cashier-value" style={{ fontSize: "20px" }}>
                        {cashierInfo?.currency_code}{" "}
                        {formatAmount(cashierStats?.totalAmountCollected)}
                      </p>
                    </>
                  )}
                </li>
                <li className="text-start d-flex flex-column">
                  {statsFetching ? (
                    <Skeleton />
                  ) : (
                    <>
                      <span className="cashier-stats text-capitalize">
                        Total settlement
                      </span>
                      <p className="cashier-value" style={{ fontSize: "20px" }}>
                        {cashierInfo?.currency_code}{" "}
                        {formatAmount(cashierStats?.totalAmountSettled)}
                      </p>
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* RIGHT */}
          <div className="col-lg-9">
            {/* LG AND MD SCREENS */}
            <div className="pt-3 d-none d-md-block d-lg-block d-xl-block">
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={[
                  {
                    label: "Collection",
                    key: "collections",
                  },
                  {
                    label: "Settlements history",
                    key: "payback-history",
                  },
                  {
                    label: "Account details",
                    key: "account-details",
                  },
                ]}
              />
            </div>

            {/* MD && LG && XL SCREENS */}
            <div className="d-none d-md-block d-lg-block d-xl-block">
              <div
                className={
                  activeTab === "account-details"
                    ? "d-flex flex-row align-items-centerd justify-content-centerd py-5"
                    : "card"
                }
              >
                {activeTab === "collections" && (
                  <div className="card-header card-header-content-md-between">
                    <div className="mb-2 mb-md-0">
                      <form
                        style={{ width: "20rem" }}
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div className="input-group input-group-merge border border-secondary">
                          <div className="input-group-prepend input-group-text me-2">
                            <i className="bi-search"></i>
                          </div>
                          <input
                            id="datatableSearch"
                            type="search"
                            className="form-control"
                            onChange={handleChange}
                            placeholder="Search by amount, reference"
                            aria-label="Search"
                            style={{
                              paddingLeft: "2.6rem",
                              paddingRight: "2rem",
                            }}
                          />
                        </div>
                      </form>
                    </div>

                    <div className="d-grid d-sm-flex gap-2">
                      <DropDownWrapper
                        action={
                          <button
                            type="button"
                            className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                            ref={buttonRef}
                          >
                            <i className="bi bi-filter"></i> Filter
                          </button>
                        }
                        styleObject={{ left: "-34%" }}
                      >
                        <div className="p-3">
                          <label className="text-body">Date Range</label>
                          <div className="row mb-4">
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="start_date"
                                value={dateRange?.start_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    start_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="end_date"
                                value={dateRange?.end_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    end_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              className="btn btn-white btn-sm"
                              onClick={() => {
                                setDateRange({ start_date: "", end_date: "" });
                                collectionRefetch();
                                handleCollectionRefetch(false).finally(() =>
                                  buttonRef?.current?.click()
                                );
                              }}
                            >
                              Reset
                            </button>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                handleCollectionDateRangeFilter();
                              }}
                            >
                              Apply Filter
                              {loading && (
                                <>
                                  &nbsp;{" "}
                                  <SpinnerComponent variant="light" size="sm" />
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </DropDownWrapper>
                      <DropDownWrapper
                        action={
                          <button
                            type="button"
                            className="btn btn-outline-success btn-sm"
                            ref={collectionButtonDownloadRef}
                          >
                            <span style={{ color: "#128100 !important" }}>
                              Download{" "}
                              <DownloadIcon className="ms-2 text-success" />
                            </span>
                          </button>
                        }
                      >
                        <div className="p-3">
                          <label className="text-body">Date Range</label>
                          <div className="row mb-4">
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="start_date"
                                value={dateRange?.start_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    start_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="end_date"
                                value={dateRange?.end_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    end_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              className="btn btn-white btn-sm"
                              onClick={() => {
                                collectionRefetch();
                                setDateRange({ start_date: "", end_date: "" });
                                collectionRefetch();
                                handleCollectionRefetch(true).finally(() =>
                                  collectionButtonDownloadRef?.current?.click()
                                );
                              }}
                            >
                              Reset
                            </button>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                handleCollectionDateRangeFilter(true)
                              }
                            >
                              Export
                              {loading && (
                                <>
                                  &nbsp;{" "}
                                  <SpinnerComponent variant="dark" size="sm" />
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </DropDownWrapper>
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-white btn-sm border border-secondary"
                          onClick={() => {
                            collectionRefetch();
                            cashierRefetch();
                            statsRefetch();
                          }}
                        >
                          <i className="bi bi-arrow-clockwise me-2"></i> Refresh
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "payback-history" && (
                  <div className="card-header card-header-content-md-between">
                    <div className="mb-2 mb-md-0">
                      <form
                        style={{ width: "20rem" }}
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div className="input-group input-group-merge border border-secondary">
                          <div className="input-group-prepend input-group-text me-2">
                            <i className="bi-search"></i>
                          </div>
                          <input
                            id="datatableSearch"
                            type="search"
                            className="form-control"
                            onChange={handleChange}
                            placeholder="Search by amount, reference"
                            aria-label="Search"
                            style={{
                              paddingLeft: "2.6rem",
                              paddingRight: "2rem",
                            }}
                          />
                        </div>
                      </form>
                    </div>

                    <div className="d-grid d-sm-flex gap-2">
                      <DropDownWrapper
                        action={
                          <button
                            type="button"
                            className="btn btn-white btn-sm dropdown-togglee w-100 border border-secondary"
                            ref={settleementButtonRef}
                          >
                            <i className="bi bi-filter"></i> Filter
                          </button>
                        }
                        styleObject={{ left: "-36%" }}
                      >
                        <div className="p-3">
                          <label className="text-body">Date Range</label>
                          <div className="row mb-4">
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="start_date"
                                value={dateRange?.start_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    start_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="end_date"
                                value={dateRange?.end_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    end_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              className="btn btn-white btn-sm"
                              onClick={() => {
                                settlementRefetch();
                                setDateRange({ start_date: "", end_date: "" });
                                handleSettlementRefetch().finally(() =>
                                  settleementButtonRef?.current.click()
                                );
                              }}
                            >
                              Reset
                            </button>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleSettlementDateRangeFilter()}
                            >
                              Apply Filter{" "}
                              {loading && (
                                <>
                                  &nbsp;{" "}
                                  <SpinnerComponent variant="light" size="sm" />
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </DropDownWrapper>
                      <DropDownWrapper
                        action={
                          <button
                            type="button"
                            className="btn btn-outline-success btn-sm"
                            ref={settleementButtonDownloadRef}
                          >
                            <span style={{ color: "#128100 !important" }}>
                              Download{" "}
                              <DownloadIcon className="ms-2 text-success" />
                            </span>
                          </button>
                        }
                      >
                        <div className="p-3">
                          <label className="text-body">Date Range</label>
                          <div className="row mb-4">
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="start_date"
                                value={dateRange?.start_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    start_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                            <div className="col tom-select-custom">
                              <input
                                type="date"
                                name="end_date"
                                value={dateRange?.end_date}
                                onChange={(e) => {
                                  setDateRange((prev: any) => ({
                                    ...prev,
                                    end_date: e.target.value,
                                  }));
                                }}
                                className="js-daterangepicker form-control daterangepicker-custom-input"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              className="btn btn-white btn-sm"
                              onClick={() => {
                                settlementRefetch();
                                setDateRange({ start_date: "", end_date: "" });
                                handleSettlementRefetch().finally(() =>
                                  settleementButtonDownloadRef?.current.click()
                                );
                              }}
                            >
                              Reset
                            </button>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                handleSettlementDateRangeFilter(true)
                              }
                            >
                              Export
                              {loading && (
                                <>
                                  &nbsp;{" "}
                                  <SpinnerComponent variant="light" size="sm" />
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </DropDownWrapper>
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm w-100"
                          onClick={() => {
                            setPaybackShow(true);
                          }}
                        >
                          Settle
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "collections" && (
                  <Table
                    columns={collections}
                    data={collectionInfo ?? []}
                    isFetching={collectionFetching}
                    navigateTo={`/merchant/business/cashier/${cashier_id}/collection/`}
                    locationData={{
                      cashier_name: location?.state?.cashier_name,
                    }}
                  />
                )}

                {activeTab === "payback-history" && (
                  <Table
                    columns={payback}
                    data={settlementInfo ?? []}
                    isFetching={settlementFetching}
                  />
                )}

                {activeTab === "payback-history" &&
                  TotalSettlementItems > 1 && (
                    <div className="card-footer">
                      <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                        <div className="col-sm mb-2 mb-sm-0"></div>

                        <div className="col-sm-auto">
                          <div className="d-flex justify-content-center justify-content-sm-end">
                            <PaginationComponent
                              totalItems={TotalSettlementItems}
                              activeItem={activeSettlement}
                              setActiveItem={setActiveSettlement}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {activeTab === "collections" && TotalCollectionItems > 1 && (
                  <div className="card-footer">
                    <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                      <div className="col-sm mb-2 mb-sm-0"></div>

                      <div className="col-sm-auto">
                        <div className="d-flex justify-content-center justify-content-sm-end">
                          <PaginationComponent
                            totalItems={TotalCollectionItems}
                            activeItem={activeCollection}
                            setActiveItem={setActiveCollection}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "account-details" && (
                  <>
                    {statsLoading ? (
                      <div className="d-flex flex-row align-items-center justify-content-center mx-auto pt-5">
                        <div className="loadingio-spinner-spinner-zefk90pf4qd">
                          <div className="ldio-we0kgaonmrr">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="d-none d-md-none d-lg-block">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              padding: "0px 10px",
                              overflow: "hidden",
                            }}
                          >
                            <div className="signage-back"></div>
                            <div className="signage">
                              <div className="">
                                <ul
                                  className="list-py-2"
                                  style={{ listStyle: "none" }}
                                >
                                  <li className="text-center d-flex flex-column justify-content-center">
                                    {statsLoading ? (
                                      <Skeleton height={30} />
                                    ) : (
                                      <>
                                        <span
                                          className="cashier-stats text-center"
                                          style={{
                                            color: "#9C9BB0",
                                            fontSize: "10px",
                                            letterSpacing: "5px",
                                          }}
                                        >
                                          BANK NAME
                                        </span>
                                        <p
                                          className="cashier-value text-center"
                                          style={{
                                            fontSize: "16px",
                                            color: "#fff",
                                            marginBottom:
                                              merchant_data?.secondary_banks
                                                ?.length > 0
                                                ? 0
                                                : "inherit",
                                          }}
                                        >
                                          {merchant_data?.primary_bank?.name}
                                        </p>
                                        <>
                                          {merchant_data?.secondary_banks?.map(
                                            (i: any) => (
                                              <span
                                                className="cashier-value text-center"
                                                style={{
                                                  fontSize: "16px",
                                                  color: "#fff",
                                                }}
                                                key={i?.bank_code}
                                              >
                                                {i?.name}
                                              </span>
                                            )
                                          )}
                                        </>
                                      </>
                                    )}
                                  </li>

                                  <li className="text-center d-flex flex-column justify-content-center divider">
                                    {statsLoading ? (
                                      <Skeleton />
                                    ) : (
                                      <>
                                        <span
                                          className="cashier-stats text-center"
                                          style={{
                                            color: "#9C9BB0",
                                            fontSize: "10px",
                                            letterSpacing: "5px",
                                          }}
                                        >
                                          ACCOUNT NUMBER
                                        </span>
                                        <p
                                          className="cashier-value"
                                          style={{
                                            fontSize: "40px",
                                            color: "#fff",
                                          }}
                                        >
                                          {cashierInfo?.account?.account_number}
                                        </p>
                                      </>
                                    )}
                                  </li>

                                  <li className="text-center d-flex flex-column justify-content-center pb-4">
                                    {statsLoading ? (
                                      <Skeleton />
                                    ) : (
                                      <>
                                        <span
                                          className="cashier-stats text-center"
                                          style={{
                                            color: "#9C9BB0",
                                            fontSize: "10px",
                                            letterSpacing: "5px",
                                          }}
                                        >
                                          ACCOUNT NAME
                                        </span>
                                        <p
                                          className="cashier-value text-center"
                                          style={{
                                            fontSize: "16px",
                                            color: "#fff",
                                          }}
                                        >
                                          {cashierInfo?.account?.display_name}
                                        </p>
                                      </>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {mode === "internal.test" && (
                            <div className="signage-download">
                              <button
                                type="button"
                                className="btn btn-outline-success btn-sm"
                              >
                                <span style={{ color: "#128100 !important" }}>
                                  Download
                                </span>
                                <DownloadIcon className="ms-2 text-success" />
                                {/* {loading ? (
                            <>
                              &nbsp;{" "}
                              <SpinnerComponent variant="dark" size="sm" />
                            </>
                          ) : (
                            <DownloadIcon className="ms-2 text-success" />
                          )} */}
                              </button>
                            </div>
                          )}
                        </div>

                        <div className="d-block d-md-block d-lg-none">
                          <div
                            className=""
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            <div className="signage-back-mobile"></div>
                            <div className="signage-mobile">
                              <div className="">
                                <ul
                                  className="list-py-2"
                                  style={{ listStyle: "none" }}
                                >
                                  <li className="text-center d-flex flex-column justify-content-center">
                                    {statsLoading ? (
                                      <Skeleton height={30} />
                                    ) : (
                                      <>
                                        <span
                                          className="cashier-stats text-center"
                                          style={{
                                            color: "#9C9BB0",
                                            fontSize: "10px",
                                            letterSpacing: "5px",
                                          }}
                                        >
                                          BANK NAME
                                        </span>
                                        <p
                                          className="cashier-value text-center"
                                          style={{
                                            fontSize: "16px",
                                            color: "#fff",
                                            marginBottom:
                                              merchant_data?.secondary_banks
                                                ?.length > 0
                                                ? 2
                                                : "inherit",
                                          }}
                                        >
                                          {merchant_data?.primary_bank?.name}
                                        </p>
                                        {merchant_data?.secondary_banks?.map(
                                          (i: any) => (
                                            <p
                                              className="cashier-value text-center"
                                              style={{
                                                fontSize: "16px",
                                                color: "#fff",
                                              }}
                                              key={i?.bank_code}
                                            >
                                              {i?.name}
                                            </p>
                                          )
                                        )}
                                      </>
                                    )}
                                  </li>

                                  <li className="text-center d-flex flex-column justify-content-center divider">
                                    {statsLoading ? (
                                      <Skeleton />
                                    ) : (
                                      <>
                                        <span
                                          className="cashier-stats text-center"
                                          style={{
                                            color: "#9C9BB0",
                                            fontSize: "10px",
                                            letterSpacing: "5px",
                                          }}
                                        >
                                          ACCOUNT NUMBER
                                        </span>
                                        <p
                                          className="cashier-value"
                                          style={{
                                            fontSize: "40px",
                                            color: "#fff",
                                          }}
                                        >
                                          {cashierInfo?.account?.account_number}
                                        </p>
                                      </>
                                    )}
                                  </li>

                                  <li className="text-center d-flex flex-column justify-content-center pb-4">
                                    {statsLoading ? (
                                      <Skeleton />
                                    ) : (
                                      <>
                                        <span
                                          className="cashier-stats text-center"
                                          style={{
                                            color: "#9C9BB0",
                                            fontSize: "10px",
                                            letterSpacing: "5px",
                                          }}
                                        >
                                          ACCOUNT NAME
                                        </span>
                                        <p
                                          className="cashier-value text-center"
                                          style={{
                                            fontSize: "16px",
                                            color: "#fff",
                                          }}
                                        >
                                          {cashierInfo?.account?.display_name}
                                        </p>
                                      </>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>

                            {mode === "internal.test" && (
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                <button
                                  type="button"
                                  className="btn btn-outline-success btn-sm"
                                >
                                  <span style={{ color: "#128100 !important" }}>
                                    Downloadimport Loader from
                                    '../payment-page/loader';
                                  </span>
                                  <DownloadIcon className="ms-2 text-success" />
                                  {/* {loading ? (
                            <>
                              &nbsp;{" "}
                              <SpinnerComponent variant="dark" size="sm" />
                            </>
                          ) : (
                            <DownloadIcon className="ms-2 text-success" />
                          )} */}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* SM SCREENS */}
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="d-flex flex-column">
                {/* SM MOBILE TABS */}
                <div
                  className="btn-group btn-group-segment"
                  role="group"
                  aria-label="Basic segment styles example"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnGroupSegmentRadio"
                    id="btnGroupSegmentRadioOption2"
                    autoComplete="off"
                    onClick={() => {
                      setActiveTab("collections");
                    }}
                    checked={activeTab === "collections" ? true : false}
                  />
                  <label
                    className="btn btn-sm"
                    htmlFor="btnGroupSegmentRadioOption2"
                    style={{ cursor: "pointer" }}
                  >
                    Collection
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="btnGroupSegmentRadio"
                    id="btnGroupSegmentRadioOption3"
                    autoComplete="off"
                    onClick={() => {
                      setActiveTab("payback-history");
                    }}
                    checked={activeTab === "payback-history" ? true : false}
                  />
                  <label
                    className="btn btn-sm"
                    htmlFor="btnGroupSegmentRadioOption3"
                    style={{ cursor: "pointer" }}
                  >
                    Settlement
                  </label>
                </div>

                {activeTab === "collections" && (
                  <div className="mt-3">
                    <div className="d-flex flex-row mt-2 mb-3">
                      <div className="mb-2 mb-md-0 me-3">
                        <form
                          style={{ width: "15rem" }}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          {/* <div className="input-group input-group-merge border border-secondary">
                            <div className="input-group-prepend input-group-text me-2">
                              <i className="bi-search"></i>
                            </div>
                            <input
                              id="datatableSearch"
                              type="search"
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Search by amount, reference"
                              aria-label="Search"
                              style={{
                                paddingLeft: "2.6rem",
                                paddingRight: "2rem",
                              }}
                            />
                          </div> */}
                          <input
                            id="datatableSearch"
                            type="search"
                            className="form-control"
                            onChange={handleChange}
                            placeholder="Search by amount, reference"
                            aria-label="Search"
                            style={{
                              paddingLeft: "1rem",
                            }}
                          />
                        </form>
                      </div>

                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                          className="btn btn-light rounded btn-icon icon-btn"
                        >
                          <i
                            className="bi-three-dots"
                            style={{ color: "#000" }}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={"button"}
                            type="button"
                            onClick={() => setShowModal(true)}
                          >
                            <i className="bi bi-filter me-2"></i> Filter
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={"button"}
                            type="button"
                            onClick={() => {
                              handleCollectionDateRangeFilter(true);
                            }}
                          >
                            <i className="bi bi-download me-2"></i> Download
                          </Dropdown.Item>

                          <Dropdown.Item
                            as={"button"}
                            type="button"
                            onClick={() => {
                              collectionRefetch();
                              handleCollectionRefetch(false);
                            }}
                          >
                            <i className="bi bi-arrow-clockwise me-2"></i>{" "}
                            Refresh
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <ul className="list-group">
                      {isFetching ? (
                        <li className="mb-2">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                            return <Skeleton height={30} />;
                          })}
                        </li>
                      ) : (
                        <>
                          {collectionInfo?.map((txn_data: any, index: any) => {
                            return (
                              <>
                                <li
                                  className={`${
                                    index === 0 ||
                                    index === collectionInfo?.length - 1
                                      ? "list-group-item"
                                      : "list-group-item"
                                  } d-flex flex-row justify-content-between align-items-start card mb-3`}
                                  onClick={() => {
                                    navigate(
                                      `/merchant/business/cashier/${txn_data?.cashier_id}/collection/${txn_data?.id}`,
                                      {
                                        state: {
                                          ...txn_data,
                                          cashier_name:
                                            cashierInfo?.cashier_name,
                                        },
                                      }
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="ms-2 me-auto d-flex flex-column">
                                    <div
                                      className="fw-semibold text-truncate"
                                      style={{ maxWidth: "150px" }}
                                    >
                                      {txn_data?.sender_details?.name}
                                    </div>

                                    <small>
                                      {txn_data?.sender_details?.account_number}
                                    </small>
                                    <small>{txn_data?.created_at}</small>
                                  </div>

                                  <span className="">
                                    {formatAmount(txn_data?.amount)}
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </>
                      )}
                    </ul>
                    {activeTab === "collections" &&
                      TotalCollectionItems > 1 && (
                        <div className="card-footer">
                          <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                            <div className="col-sm mb-2 mb-sm-0"></div>

                            <div className="col-sm-auto">
                              <div className="d-flex justify-content-center justify-content-sm-end">
                                <PaginationComponent
                                  totalItems={TotalCollectionItems}
                                  activeItem={activeCollection}
                                  setActiveItem={setActiveCollection}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )}

                {activeTab === "payback-history" && (
                  <>
                    <div className="mt-3">
                      <div className="d-flex flex-row mt-2 mb-3">
                        <div className="mb-2 mb-md-0 me-3">
                          <form
                            style={{ width: "15rem" }}
                            onSubmit={(e) => e.preventDefault()}
                          >
                            {/* <div className="input-group input-group-merge border border-secondary">
                              <div className="input-group-prepend input-group-text me-2">
                                <i className="bi-search"></i>
                              </div>
                              <input
                                id="datatableSearch"
                                type="search"
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Search by amount, reference"
                                aria-label="Search"
                                style={{
                                  paddingLeft: "2.6rem",
                                  paddingRight: "2rem",
                                }}
                              />
                            </div> */}
                            <input
                              id="datatableSearch"
                              type="search"
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Search by amount, reference"
                              aria-label="Search"
                              style={{
                                paddingLeft: "1rem",
                              }}
                            />
                          </form>
                        </div>

                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                            className="btn btn-light rounded btn-icon icon-btn"
                          >
                            <i
                              className="bi-three-dots"
                              style={{ color: "#000" }}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={"button"}
                              type="button"
                              // onClick={() => setShow(true)}
                            >
                              <i className="bi bi-filter me-2"></i> Filter
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={"button"}
                              type="button"
                              onClick={() => {
                                handleSettlementDateRangeFilter(true);
                              }}
                            >
                              <i className="bi bi-download me-2"></i> Download
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={"button"}
                              type="button"
                              onClick={() => {
                                settlementRefetch();
                                handleSettlementRefetch(false);
                              }}
                            >
                              <i className="bi bi-arrow-clockwise me-2"></i>{" "}
                              Refresh
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <ul className="list-group">
                        {isFetching ? (
                          <li className="mb-2">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                              return <Skeleton height={30} />;
                            })}
                          </li>
                        ) : (
                          <>
                            {settlementInfo?.map(
                              (txn_data: any, index: any) => {
                                return (
                                  <>
                                    <li
                                      className={`${
                                        index === 0 ||
                                        index === settlementInfo?.length - 1
                                          ? "list-group-item"
                                          : "list-group-item"
                                      } d-flex flex-row justify-content-between align-items-start card mb-3`}
                                      onClick={() => {
                                        navigate(
                                          `/merchant/business/cashier/${txn_data.id}`,
                                          {
                                            state: { ...txn_data },
                                          }
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="ms-2 me-auto d-flex flex-column">
                                        <div className="fw-semibold text-truncate">
                                          {txn_data?.references}
                                        </div>

                                        <small>
                                          {
                                            txn_data?.sender_details
                                              ?.account_number
                                          }
                                        </small>
                                        <small>{txn_data?.createdAt}</small>
                                      </div>

                                      <span className="">
                                        {formatAmount(txn_data?.amount_settled)}
                                      </span>
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                      </ul>
                      {activeTab === "payback-history" &&
                        TotalSettlementItems > 1 && (
                          <div className="card-footer">
                            <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                              <div className="col-sm mb-2 mb-sm-0"></div>

                              <div className="col-sm-auto">
                                <div className="d-flex justify-content-center justify-content-sm-end">
                                  <PaginationComponent
                                    totalItems={TotalSettlementItems}
                                    activeItem={activeSettlement}
                                    setActiveItem={setActiveSettlement}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>

                    {/* <div className="dropup position-absolute bottom-0 end-0 rounded-circle m-5">
                      <button
                        type="button"
                        onClick={() => {
                          setPaybackShow(true);
                        }}
                        className="btn btn-primary btn-lg dropdown-toggle hide-toggle"
                      >
                        <i className="fa-solid fa-plus"></i>
                        <span className="">Settle</span>
                      </button>
                    </div> */}
                  </>
                )}

                {/*SETTLEMENT FLOATING BUTTON */}
                {/* {activeTab === "payback-history" && (
                  <div
                    style={{
                      width: "100%",
                      boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.08)",
                      gap: 14,
                      left: 0,
                      right: 0,
                      padding: "22px 27px 51px",
                    }}
                    className="d-flex flex-column bottom-0 position-fixed bg-white"
                  >
                    <div
                      className="d-flex flex-row align-itens-center justify-content-center"
                      style={{ gap: 14 }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          setPaybackShow(true);
                        }}
                        className="btn btn-primary btn-lg w-75"
                      >
                        <span className="">Settle</span>
                      </button>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CashierDetails;
