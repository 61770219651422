import React, { useState } from "react";
import { Button } from "../../component/button";
import Input from "../../component/input/Input";
import PoolerIcon from "../../component/vectors/pooler-icon";
import StoreImage from "../../assets/img/others/store.png";
import { useLocation } from "react-router-dom";
import { formatAmount } from "../../helpers/index.helper";

function Preview(): JSX.Element {
  const location = useLocation();
  const data = location?.state;
  const [amount, setAmount] = useState<string>("");
  const extraFields = data?.extra_fields;
  return (
    <main
      style={{ background: "#FAFAFD" }}
      id="content"
      role="main"
      className="main position-relative"
    >
      <div className="container d-flex justify-content-center align-items-center vh-100 py-3 py-sm-7 ">
        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          <div className="card hard-shadow mb-3 position-relative">
            <div>
              <img
                src={StoreImage}
                alt="store-merchant"
                style={{
                  position: "absolute",
                  width: "12%",
                  right: 0,
                  top: "12%",
                }}
              />
            </div>
            <div
              className="card-body pl-4 pr-5  "
              style={{ background: "#F0F1F9" }}
            >
              <div className="">
                <h3
                  className="fs-6 fw-regular  m-0"
                  style={{ fontWeight: "400", color: "#8F9BB2" }}
                >
                  Merchant name
                </h3>
                <p className="m-0 fs-6 fw-semibold">
                  {data?.payment_link_name}
                </p>
              </div>
              <div className="mt-2">
                <h3
                  className="fs-6 text-secondary m-0"
                  style={{ fontWeight: "400", color: "#8F9BB2" }}
                >
                  Description
                </h3>
                <p className="m-0 fs-5 text-black fw-semibold">{data?.note}</p>
              </div>
            </div>
          </div>
          <div className="card hard-shadow mb-3">
            <div className="card-body p-4 p-sm-5 d-grid gap-3">
              <div className="row">
                <div className="col-6">
                  <Input value={""} required label="First name" />
                </div>
                <div className="col-6">
                  <Input value={""} required label="Last name" />
                </div>
              </div>
              <Input value={""} required label="Email address" type="email" />
              {data?.amount ? (
                ""
              ) : (
                <Input label="Amount*" required value={""} type="text" />
              )}

              {extraFields?.type === "dropdown" && (
                <div className="mb-3">
                  <label
                    className="form-label text-capitalize"
                    htmlFor="business_type"
                  >
                    {extraFields?.fieldName}
                  </label>

                  <select
                    className={`form-select form-control-lg `}
                    id="validationValidSelect1"
                    name="dropdown"
                  >
                    <option>Select category</option>
                    {extraFields?.dropdown?.map((i: string) => {
                      return (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {extraFields?.type === "textfield" && (
                <div className="mb-3">
                  <label
                    className="form-label text-capitalize"
                    htmlFor="business_type"
                  >
                    {extraFields?.fieldName}
                  </label>
                  {extraFields?.textfield?.map((i: string) => {
                    return (
                      <div className="mb-2">
                        <Input
                          label={i}
                          key={i}
                          required
                          value={""}
                          type="text"
                          
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="d-flex justify-content-center btn-sm gap-6 mt-2">
                {data?.amount ? (
                  <Button
                    fullwidth
                    type="button"
                    id="paybutton"
                    onClick={() => {}}
                  >
                    Pay {formatAmount(data?.amount)} now
                  </Button>
                ) : (
                  <Button
                    fullwidth
                    type="button"
                    id="paybutton"
                    onClick={() => {}}
                  >
                    Pay NGN {amount} now
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ width: "max-content" }}
            className="bg-white text-center p-2 rounded-2 mx-auto text-black"
          >
            Secured By <PoolerIcon />
            <span className="fw-bold ">Pooler</span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Preview;
