import { Route, Routes, Navigate } from "react-router-dom";
import { CASHIER_STRING } from "../../constants/index.constant";
import CashierSetup from "../../pages/cashier/auth/CashierSetup";
import Signin from "../../pages/auth/singin";

const CashierAuthenticationRoute = () => {
  const token = window.localStorage.getItem(CASHIER_STRING);

  if (token) {
    return <Navigate to={`/cashier/dashboard`} replace={true} />;
  }

  return (
    <>
      <Routes>
        <Route path={`setup`} element={<CashierSetup />} />
        <Route path={`signin`} element={<Signin />} />
      </Routes>
    </>
  );
};

export default CashierAuthenticationRoute;
