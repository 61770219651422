import { Modal } from "react-bootstrap";

import { useQueryClient } from "react-query";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as BeneficiaryIcon } from "../../assets/beneficiary-icon.svg";
import MtnIcon from "../../assets/mtn.svg";
import AirtelIcon from "../../assets/airtel.svg";
import NineMobileIcon from "../../assets/svg/9mobile.svg";
import GloIcon from "../../assets/svg/glo.svg";
import {
  useBillPaymentBeneficiary,
  useDeleteBeneficiary,
} from "../../hooks/top-up/index.hook";
import SpinnerComponent from "../../component/spinner/Spinner";
import { useState } from "react";
import ActionSheet from "../../component/mobile/ActionSheet";

export const providers = [
  {
    name: "MTN",
    icon: MtnIcon,
  },
  {
    name: "Globacom",
    icon: GloIcon,
  },
  {
    name: "Airtel",
    icon: AirtelIcon,
  },
  {
    name: "9mobile",
    icon: NineMobileIcon,
  },
];

interface Beneficiary {
  name: string;
  network_provider: string;
  mobileNumber: string;
}

const SavedBeneficiaryModal = ({
  show,
  handleClose,
  selectNumber,
  size,
  index,
}: {
  index: number;
  size: any;
  show: boolean;
  handleClose: () => void;
  selectNumber: (number: string) => void;
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [pages, setPage] = useState(0);
  const [id, setId] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data, isLoading } = useBillPaymentBeneficiary(pages);
  const { resolve_delete_beneficiary } = useDeleteBeneficiary();
  const savedBeneficiaries = data?.data?.data?.items || [];

  const handleDeleteItem = async (id: string) => {
    try {
      setId(id);
      setLoading(true);
      await resolve_delete_beneficiary.mutateAsync(id);
      queryClient.invalidateQueries({
        queryKey: ["airtime", "Beneficiaries", 0],
      });
      setLoading(false);
      setId(null);
    } catch (e: any) {
      return e;
    }
  };

  if (size <= 998) {
    return (
      <ActionSheet
        id={"beneficiaries" + index}
        show={show}
        onHide={handleClose}
        title="Saved beneficiaries"
      >
        <div>
          {savedBeneficiaries?.length > 0 ? (
            <div
              style={{
                height: "348px",
                overflowY: "auto",
              }}
            >
              <div className="w-100">
                <input
                  className="form-control mb-2 fw-normal"
                  type="text"
                  value={searchTerm}
                  onChange={(e: any) => {
                    setSearchTerm(e.target.value);
                  }}
                  placeholder="Search beneficiary"
                  style={{
                    height: "43px",
                    border: "1px solid #CBD1EC",
                    borderRadius: "8px",
                    padding: "8px",
                    fontSize: "16px",
                    color: "#8F9BB2",
                    lineHeight: "24px",
                    letterSpacing: "-0.16px",
                  }}
                />
              </div>
              {savedBeneficiaries
                ?.filter(
                  (item: any) =>
                    item?.name
                      ?.toLowerCase()
                      ?.includes(searchTerm?.toLowerCase()) ||
                    item?.mobile_number?.includes(searchTerm)
                )
                ?.map((beneficiary: any, index: number) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center pointer"
                    style={{
                      borderTop: index === 0 ? "1px solid #dee2e6" : undefined,
                      borderBottom: "1px solid #dee2e6",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        selectNumber(beneficiary.mobile_number);
                        handleClose();
                      }}
                    >
                      <div className="p-2">
                        <img
                          src={
                            providers?.filter(
                              (provider, idx) =>
                                provider.name === beneficiary?.telco
                            )?.[0]?.icon
                          }
                          alt={
                            providers?.filter(
                              (provider, idx) =>
                                provider.name === beneficiary?.telco
                            )?.[0]?.name
                          }
                        />
                      </div>
                      <div className="d-flex flex-column p-2">
                        <div className="text-capitalize">
                          {beneficiary.name}
                        </div>
                        <h3>
                          <strong>{beneficiary.mobile_number}</strong>
                        </h3>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        background: id === beneficiary?.id ? "#fff" : "#FF003D",
                        borderRadius: "8px",
                        padding: "8px",
                      }}
                      onClick={(e) => {
                        if (e) e.stopPropagation();
                        handleDeleteItem(beneficiary?.id);
                      }}
                    >
                      {id === beneficiary?.id ? (
                        <SpinnerComponent size="sm" />
                      ) : (
                        <DeleteIcon width={18} height={18} />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-center mb-10"
              style={{ margin: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "48px",
                  height: "48px",
                  padding: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                  borderRadius: "56px",
                  background: "#E9F2FF",
                  marginBottom: "16px",
                  marginTop: "42px",
                }}
              >
                <BeneficiaryIcon />
              </div>
              <div>
                {isLoading ? "Loading..." : "You have no saved beneficiaries."}
              </div>
            </div>
          )}
        </div>
      </ActionSheet>
    );
  }

  return (
    <Modal
      className="mobile-mobile"
      show={show}
      onHide={handleClose}
      centered
      contentClassName="top-up-modal"
    >
      <Modal.Header>
        <Modal.Title>Saved beneficiaries</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {savedBeneficiaries?.length > 0 ? (
            <div
              style={{
                height: "348px",
                overflowY: "auto",
              }}
            >
              {savedBeneficiaries?.map((beneficiary: any, index: number) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center pointer"
                  style={{
                    borderTop: index === 0 ? "1px solid #dee2e6" : undefined,
                    borderBottom: "1px solid #dee2e6",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      selectNumber(beneficiary.mobile_number);
                    }}
                  >
                    <div className="p-2">
                      <img
                        src={
                          providers?.filter(
                            (provider, idx) =>
                              provider.name === beneficiary?.telco
                          )?.[0]?.icon
                        }
                        alt={
                          providers?.filter(
                            (provider, idx) =>
                              provider.name === beneficiary?.telco
                          )?.[0]?.name
                        }
                      />
                    </div>
                    <div className="d-flex flex-column p-2">
                      <div className="text-capitalize">{beneficiary.name}</div>
                      <h3>
                        <strong>{beneficiary.mobile_number}</strong>
                      </h3>
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      background: id === beneficiary?.id ? "#fff" : "#FF003D",
                      borderRadius: "8px",
                      padding: "8px",
                    }}
                    onClick={(e) => {
                      if (e) e.stopPropagation();
                      handleDeleteItem(beneficiary?.id);
                    }}
                  >
                    {id === beneficiary?.id ? (
                      <SpinnerComponent size="sm" />
                    ) : (
                      <DeleteIcon width={18} height={18} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-center mb-10"
              style={{ margin: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "48px",
                  height: "48px",
                  padding: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                  borderRadius: "56px",
                  background: "#E9F2FF",
                  marginBottom: "16px",
                  marginTop: "42px",
                }}
              >
                <BeneficiaryIcon />
              </div>
              <div>
                {isLoading ? "Loading..." : "You have no saved beneficiaries."}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SavedBeneficiaryModal;
