import { useEffect, useState } from "react";

const useUserDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState<any>({});

  useEffect(() => {
    // Accessing user's device information
    const userAgent = window.navigator.userAgent;
    setDeviceInfo({
      userAgent,
    });
  }, []);

  return { deviceInfo };
};

export default useUserDeviceInfo;
