/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import { kycProps } from "../../utils/interfaces";
import KycContent from "./KycContent";
import KycRegisteredBusinessSideBar from "./KycRegisteredBusinessSideBar";
import KycUnregisteredSideBar from "./KycUnregisteredSideBar";
import "./kyc.css";

function KycBody({ children, activeTab, activeMenu, isFilled }: kycProps) {
  return (
    <>
      <div
        className="js-step-form mt-lg-4 pt-lg-2"
        data-hs-step-form-options='{
        "progressSelector": "#basicVerStepFormProgress",
        "stepsSelector": "#basicVerStepFormContent",
        "endSelector": "#basicVerStepFinishBtn"
      }'
      >
        {/* {mode === "internal.test" ? (
          <div className="row">
            {activeMenu === "registered" ? (
              <>
                <KycRegisteredBusinessSideBar activeTab={activeTab} />
              </>
            ) : (
              <>
                <KycUnregisteredSideBar
                  activeTab={activeTab}
                  isFilled={isFilled}
                />
              </>
            )}

            <KycContent children={children} />
          </div>
        ) : (
          <div className="row">
            {activeMenu === "registered" ? (
              <>
                <KycRegisteredBusinessSideBar activeTab={activeTab} />
              </>
            ) : (
              <>
                <KycUnregisteredSideBar
                  activeTab={activeTab}
                  isFilled={isFilled}
                />
              </>
            )}
            <KycContent children={children} />
          </div>
        )} */}
        <div className="row">
          {activeMenu === "registered" ? (
            <>
              <KycRegisteredBusinessSideBar activeTab={activeTab} />
            </>
          ) : (
            <>
              <KycUnregisteredSideBar
                activeTab={activeTab}
                isFilled={isFilled}
              />
            </>
          )}
          <KycContent children={children} />
        </div>
      </div>
    </>
  );
}

export default KycBody;

/**
 * made upload field label dynamic
 */
