import moengage from "@moengage/web-sdk";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SendWave } from "../../assets/sendwave.svg";
import CopyButton from "../../component/Copy";
import FilterModal from "../../component/modal/filterModal";
import PaginationComponent from "../../component/pagination/pagination";
import SpinnerComponent from "../../component/spinner/Spinner";
import TransferHeader from "../../component/transfer/TransferHeader";
import TransferMetrics from "../../component/transfer/TransferMetrics";
import TransferTable from "../../component/transfer/TransferTable";
import { formatAmount, shortenString } from "../../helpers/index.helper";
import { useDashboard, useSession } from "../../hooks/auth/auth.hook";
import useDebounce from "../../hooks/debounce.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import useResize from "../../hooks/resize.hook";
import { useTranactionExports } from "../../hooks/transactions/index.hook";
import { useTransfers } from "../../hooks/transfer/index.hook";
import {
  ITransaction,
  metricInterface,
  sessionInterface,
} from "../../utils/interfaces";

function Transfer() {
  const { deviceInfo } = useUserDeviceInfo();
  const { size } = useResize();
  const { data: merchantData } = useSession();
  const { export_transactions } = useTranactionExports();
  const navigate = useNavigate();
  const { dashboard_data, isLoading: isMetricLoading } = useDashboard({});
  const [filter, setFilter] = useState({
    reference: "",
    start_date: "",
    end_date: "",
  });
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });

  const [activeItem, setActiveItem] = useState<number>(0);
  const [reference, setReference] = useState("");
  const [eyeOpen, setEyeOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [downloadResult, setDownload] = useState(true);

  const toggleVisibility = () => {
    setPasswordOpen(passwordOpen ? false : true);
  };
  const debouncedInputValue = useDebounce<string>(reference, 2000);
  const { data, refetch, isLoading, isFetching } = useTransfers(
    {
      reference: debouncedInputValue,
      start_date: filter.start_date,
      end_date: filter.end_date,
    },
    activeItem
  );
  const merchant_data: sessionInterface = merchantData?.data?.data;
  const metrics: metricInterface = dashboard_data?.data?.data;
  const txn_data: ITransaction[] = data?.data?.data?.items;
  const totalItems = data?.data?.data?.totalPages;

  const refreshData = () => {
    setReference("");
    setDates({
      start_date: "",
      end_date: "",
    });
    setFilter({
      reference,
      ...dates,
    });
    refetch();
    window.location.reload();
  };

  const handleExports = () => {
    setLoader(true);
    if (dates?.start_date === "" && dates?.end_date === "") {
      // setFilter({
      //   reference,
      //   ...dates,
      // });
      // export_transactions
      //   .mutateAsync({
      //     start_date: dates.start_date,
      //     end_date: dates.end_date,
      //     download: "all",
      //   })
      //   .then((data: any) => {
      //     const url = data?.data?.data;
      //     const link: any = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     link.parentNode.removeChild(link);
      //     setLoader(false);
      //   })
      //   .catch((error: any) => {
      //     setLoader(false);
      //     return error;
      //   });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          const url = data?.data?.data;
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoader(false);
        })
        .catch((error: any) => {
          setLoader(false);
          return error;
        });
    }
  };

  const handleClose = () => {
    setShow(false);
    // refreshData();
  };

  const handleDatesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDates((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  const filterData = () => {
    setLoader(true);
    if (dates?.start_date === "" && dates?.end_date === "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "all",
        })
        .then((data: any) => {
          setLoader(false);
          // const url = data?.data?.data;
          // const link: any = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "file.xlsx");
          // document.body.appendChild(link);
          // link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          setLoader(false);
          return error;
        });
    } else if (dates?.start_date !== "" || dates?.end_date !== "") {
      setFilter({
        reference,
        ...dates,
      });
      export_transactions
        .mutateAsync({
          start_date: dates.start_date,
          end_date: dates.end_date,
          download: "date_range",
        })
        .then((data: any) => {
          setLoader(false);
          // const url = data?.data?.data;
          // const link: any = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "file.xlsx");
          // document.body.appendChild(link);
          // link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((error: any) => {
          setLoader(false);
          return error;
        });
    }
  };

  return (
    <main>
      <>
        <FilterModal show={show} handleClose={handleClose}>
          <div className="row mb-4">
            <div className="col tom-select-custom mb-3">
              <label>Start date</label>
              <input
                type="date"
                name="start_date"
                onChange={handleDatesFilter}
                className="js-daterangepicker form-control daterangepicker-custom-input"
              />
            </div>
            <div className="col tom-select-custom mb-3">
              <label>End date</label>
              <input
                type="date"
                name="end_date"
                onChange={handleDatesFilter}
                className="js-daterangepicker form-control daterangepicker-custom-input"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <button className="btn btn-white btn-sm" onClick={handleClose}>
              Close
            </button>
            {downloadResult === true ? (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  handleExports();
                  moengage.track_event("DOWNLOAD_TRANSFER", {
                    success_status: true,
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                Export
                {loader && <SpinnerComponent size="sm" variant="light" />}
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  filterData();
                  moengage.track_event("FILTER_ACCOUNT", {
                    platform: deviceInfo?.userAgent,
                    success_status: true,
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                Apply filter{" "}
                {loader && <SpinnerComponent size="sm" variant="light" />}
              </button>
            )}
          </div>
        </FilterModal>
        {/* notify ishaya to remove it */}
        {size?.[0] <= 998 ? (
          <>
            {isLoading ? (
              <div className="d-flex flex-row align-items-center justify-content-center">
                <SpinnerComponent variant="primary" />
              </div>
            ) : (
              <>
                {txn_data?.length === 0 && totalItems < 1 ? (
                  <>
                    <div
                      className="d-flex flex-column align-items-center justify-content-center mx-auto text-center"
                      style={{ height: "60vh" }}
                    >
                      <div>
                        <SendWave />

                        <p className="mt-3">You have not made any transfers</p>

                        <button
                          className="btn btn-primary mx-auto"
                          onClick={() => navigate("/merchant/make_transfer")}
                        >
                          Make Transfer <i className="bi bi-arrow-up-right"></i>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex flex-column">
                    <div>
                      <button
                        className="btn btn-primary mb-4 mt-2"
                        onClick={() => navigate("/merchant/make_transfer")}
                      >
                        Make Transfer <i className="bi bi-arrow-up-right"></i>
                      </button>
                    </div>
                    <div className="col-lg-3 mx-auto mb-4">
                      <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                        <div className="text-center">
                          <h6 className="card-subtitle text-center subtitle-box py-1 px-2">
                            Account Balance&emsp;
                            {!eyeOpen ? (
                              <i
                                id="changePassIcon"
                                className="bi-eye"
                                onClick={() => {
                                  toggleVisibility();
                                  setEyeOpen(!eyeOpen);
                                }}
                              />
                            ) : (
                              <i
                                className="bi bi-eye-slash"
                                onClick={() => {
                                  toggleVisibility();
                                  setEyeOpen(!eyeOpen);
                                }}
                              />
                            )}
                          </h6>
                          {isFetching ? (
                            <Skeleton height={20} width={200} />
                          ) : (
                            <>
                              {!eyeOpen ? (
                                <span
                                  className=""
                                  style={{ color: "#000", fontSize: "12px" }}
                                >
                                  **************
                                </span>
                              ) : (
                                <h2 className="card-title text-center">
                                  {formatAmount(
                                    merchant_data?.balance?.balance
                                  )}
                                </h2>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row gap-3 mb-3">
                        <input
                          type="text"
                          className="js-form-search form-control border border-secondary icon w-75"
                          placeholder="Search..."
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setReference(e.target.value)
                          }
                          style={{ paddingLeft: "50px !important" }}
                        />
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                            className="btn btn-light rounded btn-icon icon-btn"
                          >
                            <i
                              className="bi-three-dots"
                              style={{ color: "#000" }}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={"button"}
                              type="button"
                              onClick={() => {
                                setShow(true);
                                setDownload(false);
                              }}
                            >
                              <i className="bi bi-filter me-2"></i> Filter
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={"button"}
                              type="button"
                              onClick={() => {
                                setShow(true);
                                setDownload(true);

                                moengage.track_event("DOWNLOAD_ACCOUNT", {
                                  success_status: true,
                                });
                              }}
                            >
                              <i className="bi bi-download me-2"></i>
                              Download
                            </Dropdown.Item>

                            <Dropdown.Item
                              as={"button"}
                              type="button"
                              onClick={() => {
                                refreshData();
                                refetch();
                                window.location.reload();
                              }}
                            >
                              <i className="bi bi-arrow-clockwise me-2"></i>{" "}
                              Refresh
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div className="mt-3">
                        <ul className="list-group">
                          <li className="list-group-item d-flex align-items-center justify-content-between">
                            <h4 className="card-header-title">
                              Transfer history
                            </h4>
                          </li>
                          {isFetching ? (
                            <li className="mb-2">
                              {[1, 2, 3, 4, 5, 6].map(() => {
                                return <Skeleton height={30} />;
                              })}
                            </li>
                          ) : (
                            <>
                              {txn_data?.map((i: ITransaction, index: any) => {
                                return (
                                  <li
                                    className={`${
                                      index === 0 ||
                                      index === txn_data?.length - 1
                                        ? "list-group-item"
                                        : "list-group-item"
                                    } d-flex flex-row justify-content-between align-items-start`}
                                    key={index}
                                    onClick={() =>
                                      navigate(
                                        `/merchant/transfer/details/${i?.id}`
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="d-flex flex-column">
                                      <p>
                                        Transfer to&emsp;
                                        {shortenString(
                                          i?.payout?.target_name,
                                          15
                                        )}
                                      </p>
                                      <p className="d-flex flex-column">
                                        <small>Account number</small>
                                        <span>
                                          {i?.reciever_details?.account_number}
                                          <CopyButton
                                            text={
                                              i?.reciever_details
                                                ?.account_number
                                            }
                                          />
                                        </span>
                                      </p>
                                    </div>
                                    <div className="d-flex flex-column">
                                      <div className="text-end">
                                        {" "}
                                        {i?.payout?.completed ||
                                        i?.completed === true ? (
                                          <span className="badge bg-soft-success text-success">
                                            COMPLETED
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {i?.payout?.in_transit === true ? (
                                          <span className="badge bg-soft-warning text-warning">
                                            PENDING
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {i?.payout?.failed === true ? (
                                          <span className="badge bg-soft-danger text-danger">
                                            FAILED
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <p className="text-end">
                                        <span className="">
                                          {formatAmount(
                                            +i?.amount,
                                            i?.currency
                                          )}
                                        </span>
                                      </p>
                                      {i?.created_at && (
                                        <small className="text-end">
                                          {i?.created_at}
                                        </small>
                                      )}
                                    </div>
                                  </li>
                                );
                              })}
                            </>
                          )}
                          <div className="col-sm-auto mt-2">
                            <div className="d-flex justify-content-center justify-content-sm-end">
                              {totalItems > 1 && (
                                <PaginationComponent
                                  totalItems={totalItems}
                                  activeItem={activeItem}
                                  setActiveItem={setActiveItem}
                                />
                              )}
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div>
            <TransferHeader
              disbleTransfer={
                merchant_data?.state?.active_domain === "test"
                  ? false
                  : merchant_data?.account_limit === null
                  ? true
                  : false
              }
              refetch={refetch}
            />
            <TransferMetrics
              metrics={metrics}
              isLoading={isMetricLoading}
              session={merchant_data}
            />
            <TransferTable
              data={data}
              refetch={refetch}
              isLoading={isLoading}
              isFetching={isFetching}
              reference={reference}
              setFilter={setFilter}
              setReference={setReference}
            />
          </div>
        )}
      </>
    </main>
  );
}

export default Transfer;
