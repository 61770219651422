import TransferHistoryHeader from "../../component/transfer/TransferHistoryHeader";
import TransferHistoryTable from "../../component/transfer/TransferHistoryTable";

function TransferHistory() {
  return (
    <main >
      {/* notify ishaya to remove it */}
      <div className="">
        <TransferHistoryHeader />
        <TransferHistoryTable />
      </div>
    </main>
  );
}

export default TransferHistory;
