import React from "react";
import AdminHeader from "../../component/admin/AdminHeader";
import AdminContent from "../../component/admin/AdminContent";

const Admin = () => {
  return (
    <>
      <AdminHeader />
      <AdminContent />
    </>
  );
};

export default Admin;
