import { useState } from "react";

const CopyButton: React.FC<{ text: string | number; className?: string }> = ({
  text,
  className,
}) => {
  const [copy, setCopied] = useState<boolean>(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
    // if (copy === false) {
    //   clearTimeout(copyTimeout);
    // }
  };
  return (
    <>
      &emsp;
      {copy ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className={`bi bi-check-all text-success`}
          viewBox="0 0 16 16"
          style={{ cursor: "pointer" }}
        >
          <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill="currentColor"
          className={`bi bi-copy ${className}`}
          viewBox="0 0 16 16"
          onClick={(event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
            event.stopPropagation();
            navigator.clipboard.writeText(`${text}`);
            handleCopy();
          }}
          style={{ cursor: "pointer" }}
        >
          <path
            fillRule="evenodd"
            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
          />
        </svg>
      )}
    </>
  );
};

export default CopyButton;
