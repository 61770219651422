import moengage from "@moengage/web-sdk";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  formatAmount,
  formatAmountWithDecimal,
} from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import { useSendTransfer } from "../../hooks/transfer/index.hook";
import { sessionInterface } from "../../utils/interfaces";
import OtpFields from "../otp/OtpFields";
import PinSetup from "./PinSetup";
import "./modal.css";

const SummaryBody = ({
  data,
  merchant_data,
}: {
  data: any;
  merchant_data: sessionInterface;
}) => {
  return (
    <div className="d-flex pt-4 flex-column summary-body">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <label className="card-subtitle">Bank:</label>
        <p className="text-right text-cap">{data?.beneficiary_bank_name}</p>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <label className="card-subtitle">Amount:</label>
        <p className="text-right text-cap">{formatAmount(data?.amount)} </p>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <label className="card-subtitle">Charge:</label>
        <p className="text-right text-cap">
          {merchant_data?.charge_type !== "percentage"
            ? formatAmount(parseFloat(merchant_data?.transfer_fee))
            : formatAmount(
                data?.amount *
                  (parseFloat(merchant_data?.charge_percentage) / 100) <
                  200
                  ? data?.amount *
                      (parseFloat(merchant_data?.charge_percentage) / 100)
                  : 200
              )}
        </p>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center ">
        <label className="card-subtitle">Beneficiary</label>
        <p className="text-right text-cap">{data?.beneficiary_account_name}</p>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <label className="card-subtitle">Account number:</label>
        <p className="text-right text-cap">{data?.beneficiary_account_no}</p>
      </div>

      <hr />
    </div>
  );
};

const Summary = ({
  data,
  handleStage,
  sendOTP,
}: {
  data: Array<any>;
  handleStage: (stage: string, message?: string) => void;
  sendOTP: () => void;
}) => {
  const [pin, setPin] = useState<string>("");
  const { resolve_transfer } = useSendTransfer();
  const { deviceInfo } = useUserDeviceInfo();
  const session = useSession();
  const merchant_data: sessionInterface = session?.data?.data?.data;
  const sumTotal = data.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.amount,
    0
  );
  const sumCharge = data.reduce(
    (accumulator) =>
      accumulator +
      (merchant_data?.charge_type !== "percentage"
        ? parseFloat(merchant_data?.transfer_fee)
        : sumTotal * (parseFloat(merchant_data?.charge_percentage) / 100) < 200
        ? sumTotal * (parseFloat(merchant_data?.charge_percentage) / 100)
        : 200),
    0
  );
  const handlePay = async (data: any) => {
    try {
      const response: any = await resolve_transfer.mutateAsync({
        beneficiary_account_name: data?.beneficiary_account_name,
        amount: data?.amount,
        narration: data?.narration,
        save_beneficiary: data?.save_beneficiary,
        beneficiary_account_no: data?.beneficiary_account_no,
        beneficiary_bank: data?.beneficiary_bank,
        transaction_pin: pin,
      });
      if (response.status === 202) {
        moengage.track_event("INITIATE_TRANSFER", {
          amount: data?.amount,
          narration: data?.narration,
          beneficiary_account_no: data?.beneficiary_account_no,
          beneficiary_bank: data?.beneficiary_bank,
          platform: deviceInfo?.userAgent,
        });
        handleStage("Processing");
      }
    } catch (error: any) {
      if (error?.response?.status === 403) {
        toast.error(error?.response?.data?.data);
      } else {
        handleStage("Failed", error?.response?.data?.data);
      }
      return error;
    }
  };

  return (
    <div>
      <div className="text-center summary">
        {/* <p className="card-subtitle">You are about to transfer:</p> */}
        <h4 className="text-card">
          NGN {formatAmountWithDecimal(sumTotal.toString())}
        </h4>
      </div>
      {data.map((item, index) => (
        <SummaryBody key={index} merchant_data={merchant_data} data={item} />
      ))}

      <div className="d-flex flex-row justify-content-between align-items-center summary-body">
        <label className="card-subtitle">Total amount</label>
        <p className="text-right text-cap">
          {formatAmount(sumTotal + sumCharge)}
        </p>
      </div>
      <br />
      <PinSetup
        data={data}
        pin={pin}
        setPin={setPin}
        sendOTP={sendOTP}
        resolve_transfer={resolve_transfer}
        handlePay={handlePay}
        setUpPinAction={() => handleStage("PIN")}
      />
    </div>
  );
};

export default Summary;
