import React from "react";

const Products = (): JSX.Element => {
  return (
    <div
      className="text-primary fs-1 fw-bold d-flex justify-content-center align-items-center"
      style={{
        height: "60vh",
      }}
    >
      Coming Soon...
    </div>
  );
};

export default Products;
