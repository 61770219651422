import moengage from "@moengage/web-sdk";
import React, { useEffect, useRef, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import KycBody from "../../component/kyc/KycBody";
import Kycheader from "../../component/kyc/Kycheader";
import { PATH_NAMES } from "../../constants/index.constant";
import { mode } from "../../helpers/index.helper";
import { useSession } from "../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../hooks/device.hook";
import { kycProps, sessionInterface } from "../../utils/interfaces";

function Kyc({
  children,
  activeTab,
  activeMenu = "unregistered",
  isFilled,
}: kycProps): JSX.Element {
  const buttonRef: any = useRef<HTMLDivElement | null>(null);
  const [activeKey, setActivekey] = useState<string>("unregistered");
  // const [show, setShow] = useState<string>("collapse navbar-collapse");
  const navigate = useNavigate();
  const { data } = useSession();
  const { deviceInfo } = useUserDeviceInfo();
  const merchant_data: sessionInterface = data?.data?.data;
  const merchantId: any = localStorage.getItem("merchant_compliance_id");

  useEffect(() => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_user_attribute("starts_kyc", true);
    moengage.add_first_name(merchant_data?.firstname);
    moengage.add_last_name(merchant_data?.lastname);
    moengage.add_email(merchant_data?.email);
    moengage.add_mobile(merchant_data?.mobile_number);
    moengage.add_user_name(merchant_data?.email);
    moengage.add_unique_user_id(merchant_data?.email);
    moengage.add_user_attribute("business_name", merchant_data?.business_name);
    moengage.track_event("KYC", {
      merchant_kyc_id: merchantId,
      starts_kyc: true,
      platform: deviceInfo?.userAgent,
    });
  }, [
    deviceInfo?.userAgent,
    merchantId,
    merchant_data?.business_name,
    merchant_data?.email,
    merchant_data?.firstname,
    merchant_data?.lastname,
    merchant_data?.mobile_number,
  ]);

  return (
    <>
      <Kycheader />
      {mode === "internal.test" && (
        <>
          <Nav
            variant="underline"
            className="justify-content-center d-none"
            activeKey={activeMenu}
          >
            {activeMenu === "unregistered" && (
              <Nav.Item>
                <Nav.Link
                  eventKey={"unregistered"}
                  className={
                    activeMenu === "unregistered" ? "active-tab" : "text-muted"
                  }
                  onClick={() => {
                    if (merchant_data.business_category === "Unregistered") {
                      navigate(
                        `/merchant/${PATH_NAMES.kyc}/unregistered_business/business_details`
                      );
                    }
                  }}
                >
                  Unregistered Business
                </Nav.Link>
              </Nav.Item>
            )}
            {activeMenu === "registered" && (
              <Nav.Item>
                <Nav.Link
                  eventKey={"registered"}
                  className={
                    activeMenu === "registered"
                      ? "active-tab"
                      : "text-muted text"
                  }
                  onClick={() => {
                    if (merchant_data.business_category === "Registered") {
                      navigate(
                        `/merchant/${PATH_NAMES.kyc}/registered_business/business_details`
                      );
                    }
                  }}
                >
                  Registered Business
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </>
      )}
      <div className="d-sm-block d-md-block d-lg-none">
        <div className="navbar-expand-lg navbar-vertical mb-1 mb-lg-5">
          <div className="d-grid">
            <button
              type="button"
              className="navbar-toggler btn btn-white mb-3"
              data-bs-toggle="collapse"
              data-bs-target="#navbarVerticalNavMenu"
              aria-label="Toggle navigation"
              aria-expanded="false"
              aria-controls="navbarVerticalNavMenu"
              ref={buttonRef}
            >
              <span className="d-flex justify-content-between align-items-center">
                <span
                  className="text-dark fw-bold"
                  style={{ fontSize: "12px" }}
                >
                  Menu
                </span>

                <span className="navbar-toggler-default">
                  <i className="bi-list"></i>
                </span>

                <span className="navbar-toggler-toggled">
                  <i className="bi-x"></i>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <KycBody
        activeMenu={activeMenu}
        activeKey={activeKey}
        children={children}
        activeTab={activeTab}
        isFilled={isFilled}
      />
    </>
  );
}

export default Kyc;
