import moengage from "@moengage/web-sdk";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SpinnerComponent from "../../../component/spinner/Spinner";
import { PATH_NAMES } from "../../../constants/index.constant";
import { encryptKyc, signatureHarsh } from "../../../helpers/index.helper";
import { useSession } from "../../../hooks/auth/auth.hook";
import useUserDeviceInfo from "../../../hooks/device.hook";
import {
  useBuisnessCompliance,
  useCheckBuisnessRequirements,
} from "../../../hooks/kyc/kyc.hook";
import API_SERVICE from "../../../services/services";
import { BUISNESS_TYPE, KYC_LEVELS } from "../../../utils/enums";
import { sessionInterface } from "../../../utils/interfaces";
import Kyc from "../Kyc";
import BuisnessUnregisteredFields from "./BuisnessUnregisteredFields";

function BuisnessDetailsUnregistered(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { sessionData } = useSession();
  const { compliance } = useBuisnessCompliance();
  const { deviceInfo } = useUserDeviceInfo();
  const merchantData: sessionInterface = sessionData?.data?.data;

  const merchantId: any = localStorage.getItem("merchant_compliance_id");

  const message = merchantId && JSON.stringify({ id: merchantId });

  const x_signature = encryptKyc(message, signatureHarsh);

  const BUSINESS_TYPE: string = BUISNESS_TYPE.UN_REGISTERED;

  const { requiredData } = useCheckBuisnessRequirements(
    BUSINESS_TYPE,
    merchantId,
    x_signature
  );
  const requirementsData: any = requiredData?.data?.data;
  const general: any = requirementsData?.general;
  const levelOne: any = requirementsData?.level_one;
  const levelTwo: any = requirementsData?.level_two;
  const levelThree: any = requirementsData?.level_three;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    business_type: BUISNESS_TYPE.UN_REGISTERED,
    business_name: merchantData?.business_name,
    business_sector: general?.business_sector,
    business_description: general?.business_description,
    use_case: general?.use_case || ["", ""],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const Submit = async (url?: string, proceed?: boolean) => {
    setIsLoading(true);
    const payloadSign =
      proceed === true
        ? encryptKyc(
            JSON.stringify({
              ...formData,
              proceed: true,
              merchant_id: merchantId,
              merchant_email: merchantData?.email,
            }),
            signatureHarsh
          )
        : encryptKyc(
            JSON.stringify({
              ...formData,
              merchant_id: merchantId,
              merchant_email: merchantData?.email,
            }),
            signatureHarsh
          );

    const payload =
      proceed === true
        ? {
            KYC_TYPE: BUISNESS_TYPE.UN_REGISTERED,
            KYC_DATA: {
              ...formData,
              proceed: true,
              merchant_id: merchantId,
              merchant_email: merchantData?.email,
            },
            KYC_MERCHANT_ID: merchantId,
            KYC_LEVEL: KYC_LEVELS.GENERAL,
            x_signature: payloadSign,
          }
        : {
            KYC_TYPE: BUISNESS_TYPE.UN_REGISTERED,
            KYC_DATA: {
              ...formData,
              merchant_id: merchantId,
              merchant_email: merchantData?.email,
            },
            KYC_MERCHANT_ID: merchantId,
            KYC_LEVEL: KYC_LEVELS.GENERAL,
            x_signature: payloadSign,
          };
    try {
      const response = await compliance.mutateAsync(payload);
      if (response?.status === 200) {
        queryClient.refetchQueries();
        queryClient.fetchQuery(
          [
            "business-requirements-check",
            merchantId,
            BUISNESS_TYPE.UN_REGISTERED,
            x_signature,
          ],
          () =>
            API_SERVICE._checkBuisnessComplianceRequirements(
              BUISNESS_TYPE.UN_REGISTERED,
              `${merchantId}`,
              x_signature
            )
        );
        setIsLoading(false);
        navigate(`${url}`);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
    setIsLoading(false);
  };

  const isValidPayload = (payload: any) => {
    for (const key in payload) {
      const value = payload[key];
      if (typeof value === "string" && value.trim() === "") {
        return false;
      }
      if (
        Array.isArray(value) &&
        value.some((item) => typeof item === "string" && item.trim() === "")
      ) {
        return false;
      }
    }

    return true;
  };

  const isFilled = isValidPayload(formData);

  const ValidateBeforeSubmit = (url?: string, proceed?: boolean) => {
    const isValid = isValidPayload(formData);
    if (isValid === true) {
      Submit(url, proceed);
    } else {
      toast.error("Kindly fill all required fields");
    }
  };

  useEffect(() => {
    queryClient.refetchQueries();
    queryClient.fetchQuery(
      [
        "business-requirements-check",
        merchantId,
        BUISNESS_TYPE.REGISTERED,
        x_signature,
      ],
      () =>
        API_SERVICE._checkBuisnessComplianceRequirements(
          BUISNESS_TYPE.REGISTERED,
          `${merchantId}`,
          x_signature
        )
    );
    if (general) {
      setFormData({
        business_type: BUISNESS_TYPE.UN_REGISTERED,
        business_name: merchantData?.business_name,
        business_sector: general?.business_sector,
        business_description: general?.business_description,
        use_case: general?.use_case || ["", ""],
      });
    }
  }, [
    general,
    merchantData?.business_name,
    merchantId,
    queryClient,
    x_signature,
  ]);

  const SaveEvent = (eventType: string) => {
    moengage.add_user_attribute("merchant_kyc_id", merchantId);
    moengage.add_user_attribute("starts_kyc", true);
    moengage.add_first_name(merchantData?.firstname);
    moengage.add_last_name(merchantData?.lastname);
    moengage.add_email(merchantData?.email);
    moengage.add_mobile(merchantData?.mobile_number);
    moengage.add_user_name(merchantData?.email);
    moengage.add_unique_user_id(merchantData?.email);
    moengage.add_user_attribute("business_name", merchantData?.business_name);
    moengage.track_event(eventType, {
      merchant_kyc_id: merchantId,
      business_details_saved: true,
      platform: deviceInfo?.userAgent,
    });
  };

  return (
    <Kyc
      activeTab="business_details"
      activeMenu="unregistered"
      isFilled={{ general: isFilled === true ? "isFilled" : "isNotFilled" }}
    >
      <div
        id="basicVerStepDetails"
        className="card"
        style={{ minHeight: "15rem" }}
      >
        <div className="card kyc-card-top">
          <div className="row align-items-start mb-0 pb-2 mt-2">
            <div className="col-sm mb-2 mb-sm-0">
              <h2 className="page-header-title text-white">Business Details</h2>
              <p
                className="page-header-text text-white pb-2"
                style={{ fontSize: "13px" }}
              >
                We would like to gather some basic information about your
                business
              </p>
            </div>

            <div className="col-sm-auto">
              {levelOne?.submit === true ||
              levelTwo?.submit === true ||
              levelThree?.submit === true ? (
                <>
                  {/* <button type="button" className="btn  kyc-save-btn" hidden>
                    Request Edit
                  </button> */}
                </>
              ) : (
                <button
                  type="button"
                  className="btn  kyc-save-btn"
                  onClick={() => {
                    Submit("");
                    SaveEvent("SAVE_UNREGISTERED_BUSINESS_KYC_DETAILS");
                  }}
                >
                  Save changes{" "}
                  {isLoading && (
                    <>
                      &nbsp;{" "}
                      <span className="align-middle">
                        <SpinnerComponent size="sm" variant="dark" />
                      </span>
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="card-body active">
          {general ? (
            <BuisnessUnregisteredFields
              handleChange={handleChange}
              formValues={formData}
              data={{ ...general, business_name: merchantData?.business_name }}
              isDisabled={
                levelOne?.submit === true ||
                levelTwo?.submit === true ||
                levelThree?.submit === true
                  ? true
                  : false
              }
            />
          ) : (
            <BuisnessUnregisteredFields
              handleChange={handleChange}
              formValues={formData}
              data={{ ...general, business_name: merchantData?.business_name }}
              isDisabled={
                levelOne?.submit === true ||
                levelTwo?.submit === true ||
                levelThree?.submit === true
                  ? true
                  : false
              }
            />
          )}
          {/* LG SCREENS */}
          {levelOne?.submit === true ||
          levelTwo?.submit === true ||
          levelThree?.submit === true ? null : (
            <>
              <div className="d-none d-md-block d-lg-block pb-3">
                <div className="d-flex align-items-center justify-content-end mt-auto">
                  <button
                    type="button"
                    className="btn kyc-save-btn"
                    onClick={() => {
                      ValidateBeforeSubmit(
                        `/merchant/${PATH_NAMES.kyc}/unregistered_business/mandatory_details`,
                        true
                      );
                      SaveEvent("PROCEED_UNREGISTERED_BUSINESS_KYC_DETAILS");
                    }}
                  >
                    Proceed <i className="bi bi-arrow-right small"></i>
                  </button>
                </div>
              </div>
              {/* SM SCREENS */}
              <div className="d-sm-block d-md-none d-lg-none">
                <div className="d-flex align-items-center justify-content-end mt-auto">
                  <button
                    type="button"
                    className="btn kyc-save-btn"
                    onClick={() => {
                      ValidateBeforeSubmit(
                        `/merchant/${PATH_NAMES.kyc}/unregistered_business/mandatory_details`,
                        true
                      );
                      SaveEvent("PROCEED_UNREGISTERED_BUSINESS_KYC_DETAILS");
                    }}
                  >
                    Proceed{" "}
                    {isLoading ? (
                      <>
                        &nbsp;
                        <span className="align-middle">
                          <SpinnerComponent size="sm" variant="dark" />
                        </span>
                      </>
                    ) : (
                      <>
                        &nbsp;
                        <i className="bi bi-arrow-right small"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Kyc>
  );
}

export default BuisnessDetailsUnregistered;
