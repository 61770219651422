import moengage from "@moengage/web-sdk";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import CopyButton from "../../component/Copy";
import PaginationComponent from "../../component/pagination/pagination";
import { useCashiers } from "../../hooks/cashier/index.hook";
import useDebounce from "../../hooks/debounce.hook";
import API_SERVICE from "../../services/services";

function CashierSm(): JSX.Element {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [show, setShow] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<number>(0);
  const [query, setQuery] = useState<string>("");

  const { data, refetch, isFetching } = useCashiers(activeItem, query);

  const cashierData = data?.data?.data?.items;
  const totalItems = data?.data?.data?.totalPages;

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };

  const debouncedInputValue = useDebounce<string>(query, 2000);

  useEffect(() => {
    if (debouncedInputValue !== "") {
      queryClient.fetchQuery(
        ["cashiers", activeItem, debouncedInputValue],
        () => API_SERVICE._getAllCashiers(activeItem, debouncedInputValue)
      );
    }
  }, [activeItem, debouncedInputValue, queryClient]);

  return (
    <div className="flex flex-column">
      <div className="page-header">
        <div className="row align-items-center mb-3">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Cashier</h1>
          </div>

          <div className="col-sm-auto">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                navigate(`/merchant/business/cashier/create_cashier`);
              }}
            >
              <i className="bi-people-fille me-1"></i> Create cashier
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row gap-3 mb-3">
        <input
          type="text"
          className="js-form-search form-control border border-secondary icon w-75"
          placeholder="Search..."
          //   onChange={handleInputChange}
        />
      </div>

      <div className="mt-3">
        <ul className="list-group">
          {isFetching ? (
            <li className="mb-2">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                return <Skeleton height={30} />;
              })}
            </li>
          ) : (
            <>
              {cashierData?.map((txn_data: any, index: any) => {
                return (
                  <>
                    <li
                      className={`${
                        index === 0 || index === cashierData?.length - 1
                          ? "list-group-item"
                          : "list-group-item"
                      } d-flex flex-row justify-content-between align-items-start card mb-3`}
                      onClick={() => {
                        navigate(`/merchant/business/cashier/${txn_data.id}`, {
                          state: { ...txn_data },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="ms-2 me-auto d-flex flex-column">
                        <div className="fw-semibold text-truncate">
                          {txn_data?.cashier_name}
                        </div>
                        <small>
                          {txn_data?.firstname} {txn_data?.lastname}
                        </small>
                        <small>
                          {txn_data?.account_number}&emsp;
                          <CopyButton text={txn_data?.account_number} />
                        </small>
                      </div>

                      {txn_data?.active === true && (
                        <span className="badge bg-soft-success text-success">
                          <span className="legend-indicator bg-success"></span>
                          ACTIVE
                        </span>
                      )}

                      {txn_data?.active === false && (
                        <span className="badge bg-soft-danger text-danger ms-2">
                          {" "}
                          <span className="legend-indicator bg-danger"></span>{" "}
                          Inactive{" "}
                        </span>
                      )}
                    </li>
                  </>
                );
              })}
            </>
          )}
        </ul>

        <div className="col-sm-auto">
          <div className="d-flex justify-content-center justify-content-sm-end">
            {totalItems > 1 && (
              <PaginationComponent
                totalItems={totalItems}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashierSm;
