import { AxiosResponse } from "axios";
import { UseQueryOptions } from "react-query";
import { toast } from "react-toastify";

/**
 * Initializes the query options.
 *
 * @template T - The type of data.
 * @returns {Object} - The initialized query options.
 * @property {number} staleTime - The duration in milliseconds until the data is considered stale. Default: 100000.
 * @property {boolean} keepPreviousData - Determines whether to keep previous data while fetching new data. Default: true.
 * @property {(data: AxiosResponse<any, any>) => AxiosResponse<any, any>)} onSuccess - The function to be called on successful data retrieval. It takes the retrieved data as an argument and returns the transformed data. Default: (data) => data.
 * @property {(error: unknown) => unknown} onError - The function to be called on error. It takes the error object as an argument and returns the transformed error object. Default: (error) => error.
 */

export const initQueryOptions = (otherProps?: UseQueryOptions): object => {
  return {
    staleTime: 100000,
    keepPreviousData: true,
    onSuccess: (data: AxiosResponse<any, any>) => data,
    onError: (error: unknown) => error,
    ...otherProps,
  };
};

/**
 * Parses the Axios response and handles the success and error cases.
 *
 * @param {Function} customOperation - Custom operation to be executed on success.
 * @returns {object} Object containing the onSuccess and onError functions.
 */
export const parseResponse = (customOperation?: Function): object => {
  return {
    /**
     * Handles the success case of the Axios response.
     *
     * @param {AxiosResponse} data - The Axios response object.
     */
    onSuccess: (data: AxiosResponse<any, any>) => {
      customOperation && customOperation(data?.data?.data);
      toast.success(data?.data?.message ?? data?.data?.data);
    },
    /**
     * Handles the error case of the Axios response.
     *
     * @param {any} error - The error object from the Axios response.
     */
    onError: (error: any) => {
      toast.error(error?.response?.data?.data);
    },
  };
};
