import moengage from "@moengage/web-sdk";
import { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import useUserDeviceInfo from "../../hooks/device.hook";
import { useCancelPaymentLink } from "../../hooks/payment-link/index.hook";
import { Button } from "../button";
import MyModal from "../modal/Modal";
import SpinnerComponent from "../spinner/Spinner";

function DeleteLinkModal({ id }: { id?: any | string }) {
  const queryClient = useQueryClient();
  const { deviceInfo } = useUserDeviceInfo();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const { cancel_link } = useCancelPaymentLink();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const handleButtonRef = (buttonRef: React.RefObject<HTMLButtonElement>) => {
    if (buttonRef.current) {
      buttonRef.current?.click();
    }
  };

  const handleCancelLink = () => {
    setLoading(true);
    cancel_link
      .mutateAsync(id)
      .then(() => {
        setLoading(false);
        moengage.track_event("CANCEL_PAYMENT_LINK", {
          paymentlink_id: id,
          succes_status: true,
          platform: deviceInfo?.userAgent,
        });
      })
      .catch((e) => {
        setLoading(false);
        return e;
      })
      .finally(() => {
        setLoading(false);
        queryClient.refetchQueries();
        navigate(`/merchant/business/pay-link`);
        buttonRef.current?.click();
      });
  };

  return (
    <MyModal
      title="Cancel link"
      id="delete-link-modal"
      onButtonRef={handleButtonRef}
    >
      <p className="text-center mx-auto fw-bold fs-6" style={{ maxWidth: 211 }}>
        Are sure you want to cancel this payment link
      </p>
      <Button
        variant="danger"
        className="mt-3"
        fullwidth
        onClick={handleCancelLink}
      >
        Cancel Link <i className="bi bi-arrow-up-right"></i>{" "}
        {loading && (
          <>
            &nbsp;
            <SpinnerComponent size="sm" />
          </>
        )}
      </Button>
    </MyModal>
  );
}

export default DeleteLinkModal;
